import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

export const pages = (type?: string): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: type === widgets.types.file ?
          [
            generateWidget({
              type: widgets.types.file,
              // data: '<h1>HTML content</h1>'
            }),
            generateWidget({
              type:  widgets.types.pageCommentsWidget,
              // data: '<h1>HTML content</h1>'
            }),
          ] :
          [
            generateWidget({
              type: typeof (type) === 'string' ? type : widgets.types.wiki,
              // data: '<h1>HTML content</h1>'
            }),
            generateWidget({
              type:  widgets.types.pageCommentsWidget,
              // data: '<h1>HTML content</h1>'
            }),
          ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}