import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import * as ACT from '../../../actions';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { i18n, Translate } from 'localization';
import { announcementinfo } from 'utils/src/requests/requests.news';
import { checkResponseStatus } from 'utils/src';

const handleContextActionAddToAnnouncement = function* handleContextActionAddToAnnouncement({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action.includes('addToAnnouncement')) {
        try {

            let text = i18n.t('pryaniky.post.actions.confirm.' + action);

            if(action.endsWith('.on')) {
                const response = yield* call(announcementinfo);
    
                if(checkResponseStatus(response)) {
                    if(!response.data.canAdd) {
                        text = i18n.t('pryaniky.news.announcement.validate', { count: response.data.limit }) + ' ' + i18n.t('pryaniky.news.announcement.validate_text');
                    }
                }
            }

            const answer = yield* call(confirmProise, {
                text
            })

            if (answer === 'cancle') {
                yield* put(ACT.contextActionCancle({
                    id,
                    action
                }))
                return;
            }

            yield* put(ACT.addToAnnouncement({ id, actionId: action }))

        } catch (error) {

        }
    }
    yield;

    return 0;
}

export default handleContextActionAddToAnnouncement