import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
// import { mapDispatchToProps } from "../../redux/connector";
import { store } from 'redux/store';
import { Analyzer } from 'uielements/src/AudioPlayer/Analyzer/AudioPlayer-Analyzer';
import { AudioPlayer } from 'uielements/src/AudioPlayer/AudioPlayer';
import { PopupListItem } from 'uielements/src/AudioPlayer/PopupListItem/AudioPlayer-PopupListItem';
import { AudioPlayList } from 'uielements/src/AudioPlayList/AudioPlayList';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import { ChatCounter } from '../Chat/ChatCounter/ChatCounter';
import { ChatList } from '../Chat/ChatList/ChatList';
import { NotificationCouner } from '../NotificationCouner/NotificationCouner';
import { Suggesters } from '../Suggesters/Suggesters';
import {
    cnHeader,
    HeaderStateProps,
    IHeaderDispathProps,
    IHeaderOwnProps,
    IHeaderProps,
    IHeaderState,
    mapDispatchToProps,
    mapStateToProps,
} from './Header.index';
import './Header.scss';
// import { menuHidden } from 'redux/actions/Menu';
import MeetingView from 'blocks/MeetingView';
import { NetworkSelector } from 'blocks/NetworkSelector/NetworkSelector';
import { VirtUserSelector } from 'blocks/VirtUserSelector/VirtUserSelector';
import { IStateType as IState } from 'redux/store';
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu';
import createModal from 'uielements/src/Dialog/VideoDilog/VideoDialog';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import { IEditType } from 'utils/src/CommonRedux/base/reducer';
import { IEnvironmentContex, withEnvironmentContext } from 'utils/src/EnvironmentContex';
import { useLocalStorage } from 'utils/src/hooks';
import { videoUrls } from 'utils/src/videoUrls';
import { i18n, Translate } from 'localization';
import { Notifications } from '../Notifications/Notifications';

import { Popover } from 'muicomponents/src/Popover';

const editTypes: IEditType[] = ['menu', 'edit'];

const SnowControl: React.FC<{}> = () => {
    const [state, setState] = useLocalStorage('show_snow', true);
    return (
        <Button
            className={cnHeader('Button', { _rightSide: true })}
            onClick={() => setState(!state)}
            children={
                <Tooltip className={'InfoIcon'} title={state ? 'Выключить снег' : 'Включить снег'}>
                    <span>
                        {state ? (
                            <Icon icon="snowflakes" className={cnHeader('Button-Icon', {})} />
                        ) : (
                            <Icon icon="sun" className={cnHeader('Button-Icon', {})} />
                        )}
                    </span>
                </Tooltip>
            }
            theme="unstyled"
        />
    );
};

const PopoverWithButton: React.FC<{
    ButtonProps: React.ComponentProps<typeof Button>;
    didMount?: () => void;
    isOpen?: () => void;
}> = ({ ButtonProps, didMount, isOpen, children }) => {
    React.useEffect(() => {
        if (didMount) didMount();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (!!anchorEl && isOpen) isOpen();
    }, [anchorEl]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button aria-describedby={id} theme="unstyled" onClick={handleClick} {...ButtonProps} />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        maxHeight: 'calc(100% - 90px)',
                        width: 'calc(100% - 32px)',
                        maxWidth: '500px',
                    },
                }}
            >
                {children}
            </Popover>
        </>
    );
};

export class HeaderPresenter extends React.PureComponent<IHeaderProps & IEnvironmentContex, IHeaderState> {
    public el?: HTMLElement | null;

    constructor(props: IHeaderProps & IEnvironmentContex) {
        super(props);
        this.state = {
            showSearch: false,
        };
    }

    private getNotificationCount = () =>
        utils.API.notifications
            .getCount()
            .r.then((d) => d && store.dispatch({ type: actions.SET_NOTIFICATION_COUNT, payload: d.data }));
    private resetNotificationCount = () =>
        utils.API.notifications.resetCount().r.then(() => store.dispatch({ type: actions.RESET_NOTIFICATION }));

    private setReadedAll = (event?: any) => {
        const { set_readed_all } = this.props;
        if (event) event.nativeEvent.stopImmediatePropagation();
        set_readed_all();
    };

    public render() {
        //mc('dreamteam.chat.pryaniky.com', true, { token: 'VEYoDPr16RBITJvCad_FHZEN2R2wMOVSDL3yX544J2P', userId: 'F7MxNeGPPrhZAgnKq' });
        const TagName = this.props.tag ? this.props.tag : 'header';

        const {
            edit,
            isAdmin,
            networks,
            virtUsers,
            chatEnable,
            isEditable,
            homePage,
            titleLogo,
            showLogoInHeader = false,
            menuHide,
            leftMenuSetReducerField,
            notificationCount,
            setActiveTab,
            filters,
        } = this.props;

        let previewLogo = undefined;
        if (window.location.href.indexOf('stylePreview=true') !== -1) {
            const dt = localStorage.getItem('stylePreview');
            if (dt) {
                const { mobileImageUrl } = JSON.parse(dt) as any;
                previewLogo = mobileImageUrl;
            }
        }

        return (
            <TagName ref={(el) => (this.el = el)} className={cnHeader({}, [this.props.className])}>
                <Button
                    id={'DASHUPMENUSCROLLER'}
                    onClick={() => {
                        leftMenuSetReducerField({ field: 'hide', value: !menuHide });
                        leftMenuSetReducerField({ field: 'opened', value: menuHide });
                        if (menuHide) {
                            leftMenuSetReducerField({ field: 'bars', value: ['main'] });
                        }
                    }}
                    className={cnHeader('Button', { menuToggle: true })}
                    children={<Icon icon="bars" className={cnHeader('Button-Icon')} />}
                    theme="unstyled"
                />

                {showLogoInHeader && (
                    <Button
                        className={cnHeader('TitleButton')}
                        type="rlink"
                        href={homePage || '/dash'}
                        theme="unstyled"
                    >
                        {/* mobileImageUrl */}
                        <img
                            className={cnHeader('TitleLogo')}
                            src={previewLogo || titleLogo?.replace(/\/\//g, '/').replace(':/', '://')}
                        />
                    </Button>
                )}

                <div className={cnHeader('Inner')}>
                    <Button
                        id={'DASHUPMENUHOME'}
                        className={cnHeader('Button', { leftSide: true })}
                        type="rlink"
                        href={homePage || '/dash'}
                        children={
                            <Tooltip className={'InfoIcon'} title={i18n.t('mainPage')}>
                                <span>
                                    {' '}
                                    <Icon icon="home" className={cnHeader('Button-Icon', { home: true })} />
                                </span>
                            </Tooltip>
                        }
                        theme="unstyled"
                    />

                    <PopoverWithButton
                        didMount={this.getNotificationCount}
                        isOpen={this.resetNotificationCount}
                        ButtonProps={{
                            className: cnHeader('Button', { leftSide: true, notificationCount: true }, [
                                notificationCount !== 0 ? 'Space' : '',
                            ]),
                            children: (
                                <>
                                    <NotificationCouner className={cnHeader('NotificationCouner')} />
                                    <Tooltip className={'InfoIcon'} title={i18n.t('notifications')}>
                                        <span>
                                            {' '}
                                            <Icon icon="bell" className={cnHeader('Button-Icon', { bell: true })} />
                                        </span>
                                    </Tooltip>
                                </>
                            ),
                        }}
                    >
                        <div className={cnHeader('PupupLayout-Notifications')}>
                            <Notifications />
                        </div>
                    </PopoverWithButton>

                    {chatEnable && (
                        <PopoverWithButton
                            ButtonProps={{
                                className: cnHeader('Button', { leftSide: true }),
                                children: (
                                    <>
                                        <ChatCounter className={cnHeader('NotificationCouner')} />
                                        <Tooltip className={'InfoIcon'} title={i18n.t('chat')}>
                                            <span>
                                                {' '}
                                                <Icon
                                                    icon="message"
                                                    className={cnHeader('Button-Icon', { comments: true })}
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            }}
                        >
                            <div className={cnHeader('PupupLayout-Notifications')}>
                                <ChatList />
                            </div>
                            <div className="ChatList_Buttons">
                                <Button className="Button-LikeRead" theme="unstyled" type="rlink" href="/messages">
                                    {i18n.t('pryaniky.header.open.chat')}
                                </Button>
                            </div>
                        </PopoverWithButton>
                    )}

                    {networks.length <= 1 && virtUsers.length <= 0 ? null : (
                        <PopoverWithButton
                            ButtonProps={{
                                className: cnHeader('Button', { leftSide: true }),
                                children: (
                                    <>
                                        <Tooltip
                                            className={'InfoIcon'}
                                            title={
                                                networks.length > 1 && virtUsers.length > 0
                                                    ? i18n.t('changeVirtUserNetwork')
                                                    : networks.length < 2 && virtUsers.length > 0
                                                    ? i18n.t('changeVirtUser')
                                                    : networks.length > 1 &&
                                                      virtUsers.length <= 0 &&
                                                      i18n.t('changeNetwork')
                                            }
                                        >
                                            <span>
                                                {' '}
                                                <Icon
                                                    icon="chart-network"
                                                    className={cnHeader('Button-Icon', { comments: true })}
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            }}
                        >
                            <div className={cnHeader('PupupLayout-Notifications')}>
                                <VirtUserSelector />
                                {networks.length > 1 && (
                                    <div>
                                        <h4 style={{ padding: '0 16px' }}>{i18n.t('pryaniky.header.network')}</h4>
                                        <NetworkSelector />
                                    </div>
                                )}
                            </div>
                        </PopoverWithButton>
                    )}

                    {/* <SuggesterQuick onTextChange={this.props.getQuery} setActiveTab={setActiveTab} filters={filters} /> */}
                    <Suggesters className={cnHeader('SearchInput', { shown: this.state.showSearch })} />

                    <AudioPlayList name={'header list'} listAudio={this.props.audioPlayList}>
                        {({ trackList, endSignal, playSignal }) => (
                            <React.Fragment>
                                {trackList.map((file) => (
                                    <AudioPlayer
                                        key={file.id}
                                        end={endSignal}
                                        play={playSignal}
                                        file={file}
                                        fileUrl={file.originalUrl}
                                    />
                                ))}

                                {trackList.length > 0 && (
                                    <PopupMenu
                                        className={cnHeader('Button', { rightSide: true })}
                                        allowInsideClick={true}
                                        horizontal={'auto'}
                                        btnContent={
                                            <AudioPlayer>
                                                {({ isPaused }) =>
                                                    !isPaused() ? (
                                                        <Analyzer />
                                                    ) : (
                                                        <Icon icon="play" className={cnHeader('Button-Icon')} />
                                                    )
                                                }
                                            </AudioPlayer>
                                        }
                                    >
                                        <div className={cnHeader('PupupLayout')}>
                                            {trackList.map((file: any) => (
                                                <AudioPlayer key={file.id} file={file} fileUrl={file.originalUrl}>
                                                    {(params: any) => (
                                                        <PopupListItem {...params} className={cnHeader('PlayerItem')} />
                                                    )}
                                                </AudioPlayer>
                                            ))}
                                        </div>
                                    </PopupMenu>
                                )}
                            </React.Fragment>
                        )}
                    </AudioPlayList>

                    <MeetingView />

                    {(window as any).SNOW && <SnowControl />}

                    <Button
                        onClick={this.searchToggle}
                        className={cnHeader('Button', { searchToggle: true, rightSide: true })}
                        children={<Icon icon="search" className={cnHeader('Button-Icon', { search: true })} />}
                        theme="unstyled"
                    />

                    {((this.props.lifeCycleStructureFromServer && isEditable) || isAdmin) &&
                        this.props.activePage !== 'statistics' &&
                        this.props.activePage !== 'appsDebug' &&
                        !this.props.isMobileOS &&
                        this.renderEditSwitcher()}
                    {this.props.children}
                </div>
            </TagName>
        );
    }

    /**
     * @deprecated
     * @returns
     */
    // private toggleMenu = () => this.props.menuHidden();

    private searchToggle = () => {
        this.setState((p) => ({ showSearch: !p.showSearch }));
    };

    private editToggle = (ev: React.SyntheticEvent<HTMLButtonElement>) =>
        this.props.changeVm('', { edit: !this.props.edit, editType: '', saving: ev.currentTarget.name === 'save' });

    /**
     * rendering common edit switcher
     */
    // private renderEditSwitcher = () => {
    //   const { edit, isAdmin, networks, chatEnable, set_readed_all, isEditable, setEditType } = this.props;
    //   return (
    //     <React.Fragment>
    //       <Button
    //         onClick={this.editToggle}
    //         name="save"
    //         className={cnHeader("Button", { editToggle: true, rightSide: true })}
    //         children={<HelpTooltip hideDelay={300} showDelay={150} help={i18n.t('settings')}>
    //           <Icon icon={edit ? "check" : "cogs"} className={cnHeader("Button-Icon", { cogs: true })} />
    //         </HelpTooltip>}
    //         theme="unstyled"
    //       />
    //       {edit && (
    //         <Button
    //           onClick={this.editToggle}
    //           name="unsave"
    //           className={cnHeader("Button", { editToggle: true, rightSide: true })}
    //           children={<Icon icon={"times"} className={cnHeader("Button-Icon")} />}
    //           theme="unstyled"
    //         />
    //       )}
    //     </React.Fragment>
    //   )
    // }

    /**
     * rendering test edit switcher
     */
    private renderEditSwitcher = () => {
        const {
            edit,
            isAdmin,
            networks,
            chatEnable,
            set_readed_all,
            isEditable,
            setEditType,
            menuEdit,
            leftMenuSetEdit,
            leftMenuSetReducerField,
            oldMenuEditState,
            oldMenuEdit,
            oldMenuSetData,
            oldMenuUnsave,
        } = this.props;

        const showModal = (type: string) => {
            if (type === 'menu') {
                createModal({
                    data: {
                        title: 'videohelp',
                        type: 'vimeo',
                        url: videoUrls.menu.edit,
                    },
                }).then(() => console.log('modal closed'));
            }
            if (type === 'edit') {
                createModal({
                    data: {
                        title: 'videohelp',
                        type: 'vimeo',
                        url: videoUrls.menu.widgets,
                    },
                }).then(() => console.log('modal closed'));
            }
        };
        const actions = [
            {
                content: (
                    <div className={cnHeader('Button-EditType-VideoHelp')}>
                        <Button
                            theme={'unstyled'}
                            className={cnHeader('Button-EditType')}
                            onClick={(e) => {
                                this.editToggle(e);
                                oldMenuEdit(false);
                            }}
                            children={i18n.t(`pryaniky.edit.types.edit`)}
                        />
                        <Icon icon="video" onClick={() => showModal('edit')} />
                    </div>
                ),
            },
        ];

        // add button for menu olny if disable mui5 menu and user is admin
        // need only for old menu
        // if(isAdmin) {
        //   actions.push({
        //     content: <div className={cnHeader("Button-EditType-VideoHelp")}>
        //       <Button id={'editMenuButton'} theme={'unstyled'} className={cnHeader("Button-EditType")} onClick={(e) => {
        //         oldMenuEdit(true);
        //         if (edit) this.editToggle(e);
        //       }} children={i18n.t(`pryaniky.edit.types.menu`)} />
        //       <Icon icon='video' onClick={() => showModal("menu")} />
        //     </div>
        //   });
        // }

        const completeEdit = (
            <React.Fragment>
                <Button
                    onClick={(e: any) => {
                        if (edit) this.editToggle(e);
                        if (oldMenuEditState) oldMenuSetData();
                    }}
                    name="save"
                    className={cnHeader('Button', { editToggle: true, rightSide: true })}
                    children={
                        <Tooltip className={'InfoIcon'} title={i18n.t('pryaniky.edit.save')}>
                            <span>
                                <Icon icon={'check'} className={cnHeader('Button-Icon', { cogs: true })} />
                            </span>
                        </Tooltip>
                    }
                    theme="unstyled"
                />

                <Button
                    onClick={(e: any) => {
                        if (edit) this.editToggle(e);
                        if (oldMenuEditState) oldMenuUnsave('restore');
                    }}
                    name="unsave"
                    className={cnHeader('Button', { editToggle: true, rightSide: true })}
                    children={
                        <Tooltip className={'InfoIcon'} title={i18n.t('pryaniky.edit.close')}>
                            <span>
                                <Icon icon={'times'} className={cnHeader('Button-Icon')} />
                            </span>
                        </Tooltip>
                    }
                    theme="unstyled"
                />
            </React.Fragment>
        );

        return edit || oldMenuEditState ? (
            completeEdit
        ) : (
            <ContextMenuComponent
                fixedPosition
                btnRender={(toggleOpen: any, ref: any, isOpen: boolean) => (
                    <Button
                        theme={'unstyled'}
                        forwardedRef={ref}
                        onClick={toggleOpen}
                        // title={i18n.t('pryaniky.edit.title')}
                        className={cnHeader('Button', { rightSide: true, editType: true })}
                    >
                        <Tooltip className={'InfoIcon'} title={i18n.t('pryaniky.edit.title')}>
                            <span>
                                {' '}
                                <Icon icon={'cogs'} className={cnHeader('Button-Icon', { cogs: true })} />
                            </span>
                        </Tooltip>
                    </Button>
                )}
                actions={actions}
            />
        );
        // return (
        //   <React.Fragment>
        //     {
        //       (edit || menuEditState) && (
        //         <React.Fragment>
        //           <Button
        //             onClick={(e: any) => {
        //               if (edit) this.editToggle(e);
        //               if (menuEditState) menuSetData();
        //             }}
        //             name="save"
        //             className={cnHeader("Button", { editToggle: true, rightSide: true })}
        //             children={<Icon icon={"check"} className={cnHeader("Button-Icon", { cogs: true })} />}
        //             theme="unstyled"
        //           />
        //           <Button
        //             onClick={(e: any) => {
        //               if (edit) this.editToggle(e);
        //               if (menuEditState) menuUnsave('restore');
        //             }}
        //             name="unsave"
        //             className={cnHeader("Button", { editToggle: true, rightSide: true })}
        //             children={<Icon icon={"times"} className={cnHeader("Button-Icon")} />}
        //             theme="unstyled"
        //           />
        //         </React.Fragment>
        //       )
        //     }
        //     <PopupMenu
        //       className={cnHeader("Button", { rightSide: true, editType: true })}
        //       title={i18n.t('pryaniky.edit.title')}
        //       btnContent={
        //         <Icon icon={"cogs"} className={cnHeader("Button-Icon", { cogs: true })} />
        //       }>
        //       {
        //         editTypes.map(type => <div className={cnHeader("Button-EditType-VideoHelp")}><Button key={type} noBorder noBorderRadius className={cnHeader("Button-EditType")} onClick={(e) => {
        //           if (type === 'edit') {
        //             this.editToggle(e);
        //             menuEdit(false);
        //           }
        //           if (type === 'menu') {
        //             menuEdit(true);
        //             if (edit) this.editToggle(e);
        //           }
        //         }} children={i18n.t(`pryaniky.edit.types.${type}`)} />
        //           {type === 'menu' && <Icon icon='video' onClick={() => showModal("menu")} />}
        //           {type === 'edit' && <Icon icon='video' onClick={() => showModal("edit")} />}
        //         </div>)
        //       }
        //     </PopupMenu>
        //   </React.Fragment>
        // )
    };
}

export const Header = connect<HeaderStateProps, IHeaderDispathProps, IHeaderOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withEnvironmentContext(HeaderPresenter));
