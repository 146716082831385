import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';

import { cnStageBar, IStageBarProps, IStageBarState } from './NewsView-StageBar.index';
import './NewsView-StageBar.scss';


export class StageBarPresenter extends React.Component<IStageBarProps, IStageBarState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IStageBarProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    // const {} = this.props;
    return (
      <div ref={el => this.el = el} className={cnStageBar({}, [this.props.className])}>
        <div className="StageBar-Data">
          <div className='StageBar-Primary'>
            {this.props.stage}
          </div>
          <div className='StageBar-Second'>{this.props.date}</div>
        </div>
        <div className='StageBar-Content'>{this.props.children}</div>
      </div>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const StageBar = compose(
  // withBemMod(cnStageBar(), {})
)(StageBarPresenter)
  