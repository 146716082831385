import { IActionButtonsProps } from './ActionButtons.index'
import * as React from 'react'
import { Button } from 'uielements/src/Button/Button';
import './ActionButtons.scss';
import { i18n, Translate } from 'localization';

export const ActionButtons: React.FC<IActionButtonsProps> = ({ saveProps = {}, cancelProps = {}, className, save, cancle, saveText, cancleText, children, saveDisabled = false }) => {
    return <div className={'ActionButtons ' + className}>
        {children}
        {save && <Button main disabled={saveDisabled} className={'ActionButtons-SaveBtn'} onClick={save} {...saveProps}>{saveText || <Translate i18nKey={'save'} />}</Button>}
        {cancle && <Button className={'ActionButtons-CancelBtn'} noBackground={true} noBorder={true} onClick={cancle} {...cancelProps}>{cancleText || <Translate i18nKey={'cancel'} />}</Button>}
    </div>
}