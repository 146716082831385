import React, { useState, useEffect, useMemo } from 'react';
import { INotifiSettingsProps, mapStateToProps, mapDispatchToProps, cnNotifiSettings, IParams } from './NotifiSettings.index';
import { connect } from 'react-redux';
import NotificationAvatar from './NotificationAvatar.svg';
import { Trans, withTranslation } from 'react-i18next';
import { i18n, Translate } from 'localization';

import { withRouter } from 'react-router-dom';
import './NotifiSettings.scss';

import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { Tabs, Button } from 'uielements/src';

import { NotifiSettingsTable } from './NotifiSettingsTable/NotifiSettingsTable';
import { NotifiSettingsNeworks } from './NotifiSettingsNeworks/NotifiSettingsNeworks';


const NotifiSettingsPresenter: React.FC<INotifiSettingsProps> = ({
  tag: Tag = 'div',
  className,
  settings,
  usersIds,
  history,
  match,
  groupName,
  networkName,
  authUid,
  isManyNetworks,
  user,
  setNotifiSettings,
  saveNotifiSettings,
  getNotifiTemplates,
  clearNotifiSettings,
  getUser,
  ...props
}) => {

  const [_, update] = useState({});
  const { tab, uid, gid, nid } = match.params;

  // Загрузка данных пользователя, у которого применияется настройки
  // Нужно для сетей
  useEffect(() => {
    getNotifiTemplates();
    getUser(uid);
  }, []);

  useEffect(() => {
    if (tab === 'common') clearNotifiSettings();
  }, [tab])

  const tabs = useMemo(() => [
    { title: i18n.t('pryaniky.notifications.settings.tab.common'), value: 'common' },
    { title: i18n.t(`pryaniky.notifications.settings.tab.${isManyNetworks ? 'networksAndGroups' : 'groups'}`), value: 'networks' },
  ], [isManyNetworks]);

  return (
    <Tag {...props} className={cnNotifiSettings('', [className])}>

      <div className={cnNotifiSettings('Header', ['Widget'])}>

        <div className={cnNotifiSettings('Header-Content')}>
          <div className={cnNotifiSettings('Header-Avatar')}>
            <img src={NotificationAvatar} />
          </div>

          <div className={cnNotifiSettings('Header-Info')}>
            <div className={cnNotifiSettings('Header-Info-Title')}><Translate i18nKey='pryaniky.notifications.settings.title' /></div>
            <span className={cnNotifiSettings('Header-Info-Description')}>
              {uid === authUid ?
                <Translate i18nKey='pryaniky.notifications.settings.description' /> :
                <Translate i18nKey='pryaniky.notifications.settings.description.admin' />
              }
            </span>

            {uid !== authUid && <div className={cnNotifiSettings('Header-Info-Aditional')}>
              <span><Translate i18nKey='pryaniky.notifications.settings.userId' /></span> {uid}
            </div>}
            {tab === 'network' && <div className={cnNotifiSettings('Header-Info-Aditional')}>
              <span><Translate i18nKey='pryaniky.notifications.settings.network' /></span> {networkName || nid}
            </div>}
            {tab === 'group' && <div className={cnNotifiSettings('Header-Info-Aditional')}>
              <span><Translate i18nKey='pryaniky.notifications.settings.,group' /></span> {groupName || gid}
            </div>}

            {!!Object.keys(settings).length &&
              tab !== 'group' &&
              <Checkbox
                value={settings.denyMailMe}
                onChange={(value) => {
                  settings.denyMailMe = value;
                  setNotifiSettings(settings);
                  saveNotifiSettings({ uid });
                  update({});
                }}
                label={i18n.t(`pryaniky.notifications.settings.notSend.${uid === authUid ? 'me' : 'user'}`)}
              />}

            <div><Translate i18nKey='pryaniky.notifications.settings.email' values={{ email: user?.baseData?.eMail || user?.baseData?.externalUserId }} /></div>

          </div>

          
        </div>

        {tab === 'common' || tab === 'networks' ?
          <div className={cnNotifiSettings('Header-Tabs')}>
            <Tabs tabsList={tabs} onChange={(val: any) => history.push(val)} selected={tab} />
          </div> :
          <Button
            className={cnNotifiSettings('Header-Info-Comeback')}
            href={`/notifiSettings/${uid}/networks`}
            theme={'unstyled'}
            type={'rlink'}
          >
            {'< назад'}
          </Button>
        }

      </div>

      <div className={cnNotifiSettings('Content')}>
        {tab !== 'networks' && <NotifiSettingsTable />}
        {tab === 'networks' && <NotifiSettingsNeworks />}
      </div>

    </Tag>
  )
}

export const NotifiSettings = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(NotifiSettingsPresenter)));