import * as React from 'react';
import { connect } from 'react-redux';
import { cnJournal, IJournalProps, mapDispatchToProps, mapStateToProps, IJournalState } from './Journal.index';
import "./Journal.scss";
import { i18n, Translate } from 'localization';
import { Avatar, Button, ListLite, Loading } from "uielements/src";
import * as utils from 'utils/src/utils';
import { PostContentRender } from '../PostContentRender/PostContentRender';
import StubListPlaceholder from '../../assets/svg/listPlaceholder.svg'
import MDRender from 'uielements/src/CommonmarkRender'
import { formatDateSTD } from 'utils/src/utils.dates';

export class JournalPresenter extends React.Component<IJournalProps, IJournalState> {

    public render() {
        const customClassNames = '';
        const { tag: TagName = 'div', children, className = '', data, isFinished, isLoading, context, getJournalDataFromServer, ...props } = this.props;


        if (!data) return null;

        return (
            <TagName {...props} className={cnJournal({}, [customClassNames, className])}>
                {data.length === 0}<div>

                </div>
                <ListLite loadMore={this.getJournal} isLoading={isLoading} isFinished={isFinished}>

                    {data.map((item: any, idx: number) =>
                        <div key={idx} className={cnJournal("ActionBlock")}>
                            <Button theme="unstyled" type="rlink" href={"/user/" + item.userId}>
                                <Avatar imgUrl={item.iconUrl}
                                    imgId={item.userPhotoId} size={56} name={item.displayName}/>
                            </Button>
                            <div className={cnJournal("InfoBlock")}>
                                
                                <Button theme="unstyled" type="rlink" href={"/user/" + item.userId}>
                                    {item.displayName}
                                </Button> 
                                <MDRender source={item.msg}/>
                                {/* <PostContentRender data={item.msg} /> */}
                            </div>
                            <div className={cnJournal("ChangeTime")}>
                                {formatDateSTD(item.newsDate, true)}
                            </div>
                        </div>)}

                </ListLite>
                {isFinished && data.length === 0 && <div className="stub">
                    <img src={StubListPlaceholder} alt="" />
                    <h1> {i18n.t('pryaniky.list.journal.emptyList.title')}</h1>
                    <p>{i18n.t('pryaniky.list.journal.emptyList.text')}</p>
                </div>}

            </TagName>
        )
    }


    private getJournal = () => this.props.getJournalDataFromServer({ objectId: this.props.context.gId })

}

export const Journal = connect(
    mapStateToProps,
    mapDispatchToProps
)(JournalPresenter)
