import React, { useEffect, useState } from 'react';
import { wrapDialogToRedux } from '../../../DialogWrapper';
import { IntercompanyGroupRequestsProps, mapIntercompanyGroupRequestsStateToProps, mapIntercompanyGroupRequestsDispatchToProps, IIntercompanyGroupRequestItemProps, cnIntercompanyGroupRequest, IItemCommentState } from './IntercompanyGroupRequests.index';
import { Button, Icon, Avatar, Loading } from 'uielements/src';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { IGroupIntercompanyRequest } from 'utils/src/requests/models/api.intepcompany';
import { i18n, Translate } from 'localization';
import { DialogComment } from '../../../Commnet/Comment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import './IntercompanyGroupRequests.scss';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const Item: React.FC<IIntercompanyGroupRequestItemProps> = ({ className, dt, action }) => {

  const [open, setOpen] = useState(false);
    const [createTarget, content = null, attendance = null] = dt.comment.split('%COMMENTSPLITTER%');
  // const [createTarget, content, attendance] = dt.comment.split('%COMMENTSPLITTER%');
  const expansionData = { content, attendance };
  // RequestAdded = 0, RequestApproved = 1, RequestDeclined = 2
  return (
    <ExpansionPanel square expanded={open} onChange={() => setOpen(!open)} className={className}>
      <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className={cnIntercompanyGroupRequest('Item-Header')}>
          <div className={cnIntercompanyGroupRequest('Item-Dt')}>
            <Avatar size={40} imgId={dt.group.imgId} imgUrl={dt.group.imgUrl} name={dt.group.name} />
            <Button type={'rlink'} href={'/group/' + dt.group.pkid} theme={'unstyled'} children={dt.group.name} />
          </div>
          <div className={cnIntercompanyGroupRequest('Item-Dt')}>
            <Button type={'rlink'} href={'/user/' + dt.user.id} theme={'unstyled'} children={dt.user.displayName} />
          </div>
          <div className={cnIntercompanyGroupRequest('Item-Dt')}>
            {createTarget}
          </div>
          <div className={cnIntercompanyGroupRequest('Item-Dt')}>
            {
              dt.status === 'RequestAdded' &&
              <>
                <Button main children={<Translate i18nKey={'pryaniky.subnetworks.dialog.item.confirm'} />} onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  action('confirm', dt.group.pkid.toString());
                }} />
                <Button children={<Translate i18nKey={'pryaniky.subnetworks.dialog.item.decline'} />} onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  action('decline', dt.group.pkid.toString());
                }} />
              </>
            }
            {
              dt.status === 'RequestApproved' && 'Утверждена'
            }
            {
              dt.status === 'RequestDeclined' && 'Отменена'
            }
            <Button type='link' theme='unstyled_center' onClick={(e: MouseEvent) => { e.preventDefault() }}>
              {
                !open ?
                  <Translate i18nKey={'pryaniky.subnetworks.dialog.item.more'} />
                  :
                  <Translate i18nKey={'pryaniky.subnetworks.dialog.item.hideMore'} />
              }
            </Button>
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={cnIntercompanyGroupRequest('Item-Details')}>
        <div>
          {
            Object.keys(expansionData).map((key) => {
              const k = key as keyof typeof expansionData;
              return <p><strong>{i18n.t(`pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.${k}`)}:</strong> {expansionData[k]}</p>
            })
          }
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const IntercompanyGroupRequestsPresenter: React.FC<IntercompanyGroupRequestsProps> = ({ handleAccept, handleClose, dialogsGetIntecompanyGroupRequests, confirmIntercompanyGroupRequest, declineIntercompanyGroupRequest, data, ...props }) => {

  const [state, setState] = React.useState()
  const dt = data.list as IGroupIntercompanyRequest[] || [];

  useEffect(() => {
    dialogsGetIntecompanyGroupRequests({ id: props.id || GUID_EMPTY })
  }, [])

  const requestAction = (action: string, groupId: string, comment: string = '') => {

    switch (action) {
      case 'confirm': {
        confirmIntercompanyGroupRequest({ id: props.id || GUID_EMPTY, groupId, comment });
        break;
      }
      case 'decline': {
        declineIntercompanyGroupRequest({ id: props.id || GUID_EMPTY, groupId, comment });
        break;
      }
    }
  }

  // const [isShownComment, setIsShownComment] = React.useState(false);
  // const [dataComment, setDataComment] = React.useState<IItemCommentState>();

  const showCommentDialog = (action: string, groupId: string) => {
    DialogComment({ data: { comment: "" } }).then((data:any) => requestAction(action, groupId, data.comment)).catch(() => { });
  }
  // const hideCommentDialog = () => setIsShownComment(false);
  // const comfirmCommentDialog = (data: any) => requestAction(data.action, data.groupId, data.comment);
 
  return (
    <>
      {/* <Comment
        isShown={isShownComment}
        onClose={hideCommentDialog}
        data={dataComment}
        onConfirm={comfirmCommentDialog}
         /> */}
      <DialogTitle onClose={handleClose}>
        <Translate i18nKey={'pryaniky.subnetworks.list.groups.intercompanyRequests'} />
      </DialogTitle>
      <div>
        <div className={cnIntercompanyGroupRequest('Table-Header')}>
          <div className={cnIntercompanyGroupRequest('Table-Dt')} children={<Translate i18nKey={'pryaniky.subnetworks.dialog.item.groupName'} />} />
          <div className={cnIntercompanyGroupRequest('Table-Dt')} children={<Translate i18nKey={'pryaniky.subnetworks.dialog.item.author'} />} />
          <div className={cnIntercompanyGroupRequest('Table-Dt')} children={<Translate i18nKey={'pryaniky.subnetworks.dialog.placeholder.commentMoveGroup.createTarget'} />} />
          <div className={cnIntercompanyGroupRequest('Table-Dt')} children={<Translate i18nKey={'pryaniky.subnetworks.dialog.item.actions'} />} />
        </div>
        {
          data?.loading && <div className={cnIntercompanyGroupRequest('Loading')}><Loading /></div> || null
        }
        {
          dt && dt.map((req, idx) => {
            return <Item className={cnIntercompanyGroupRequest('Item')} key={req.group.pkid.toString() + idx.toString()} dt={req} action={showCommentDialog} />
          })
        }
      </div>
    </>
  )
}

export const IntercompanyGroupRequests = connect(
  mapIntercompanyGroupRequestsStateToProps,
  mapIntercompanyGroupRequestsDispatchToProps
)(
  IntercompanyGroupRequestsPresenter
)


export const DialogIntercompanyGroupRequests = createModal(IntercompanyGroupRequests, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});
  // export const WidgetsAdd = wrapDialogToRedux<IWidgetsAddProps>(
  //   connect(
  //     () => { },
  //     {
  //       getActiveStructure,
  //       getActiveStructureWidgets
  //     }
  //   )(WidgetsAddPresenter)
  // )
