// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { i18n, Translate } from 'localization';

import { cnStatus, IStatusProps, IStatusState } from './User-Status.index';

import './User-Status.scss';
import { Button } from 'uielements/src/Button/Button';

export class StatusPresenter extends React.Component<IStatusProps, IStatusState> {
  public el: HTMLElement | null;
  public ghost: HTMLElement | null;
  private t = i18n.t;

  constructor(props: IStatusProps) {
    super(props);
    this.functionBind = this.functionBind.bind(this);
    this.functionBind();
    this.state = {
      height: 0,
      value: this.props.data
    };
  }

  public render() {
    return (
      <div ref={el => this.el = el} className={cnStatus({},[this.props.className])}>
        <div style={{position: 'relative'}}>
          <div ref={c => this.ghost = c} className={cnStatus('Ghost')} children={this.state.value} />
          <textarea style={{height: this.state.height > 0 ? this.state.height + 'px' : undefined }} className={cnStatus('Textarea')} rows={1} onChange={this.onChange} value={this.state.value} placeholder={this.t('status')} />
        </div>
        <div className={cnStatus('Buttons')}>
          <Button className={cnStatus('Buttons-Item', {save: true})} onClick={this.save} main={true} children={this.t('save')} />
          <Button className={cnStatus('Buttons-Item', {cancel: true})} onClick={this.close} noBorder={true} noBackground={true} children={this.t('cancel')} />
        </div>
        {this.props.children}
      </div>
    )
  }

  private functionBind() {
    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.close = this.close.bind(this);
  }

  private onChange(ev: React.ChangeEvent<any>) {
    const target = ev.target;
    if(target.value.length <= 140) {
      if(target.value[target.value.length - 1] === '\n') target.value = target.value.substring(0, target.value.length - 1);
      this.setState({value: target.value}, () => {
        this.setHeight();
      });
    }
  }

  private setHeight() {
    if(this.state.value.length > 0 && this.ghost) {
      this.setState({height: this.ghost.clientHeight + 2});
    }
  }
  
  private save() {
    if(this.props.save) this.props.save(this.state.value, this.close);
  }

  private close() {
    const componentWrapper = document.getElementById('StatusEditor');
    if(componentWrapper) ReactDOM.unmountComponentAtNode(componentWrapper);
  }

}


export const Status = StatusPresenter;
  //   compose(
  //     withBemMod(cnStatus(), {})
  //     )(
  //       StatusPresenter
  //   )
  // )
