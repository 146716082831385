import React from 'react';
import { Selectize } from 'uielements/src';
import { ISelectizeItem } from "uielements/src/Selectize/Selectize.index";
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, cnFilters } from '../Filters.index';
import { IFiltersLeaderboardProps, IFiltersLeaderboardState } from './Filters_type_leaderboard.index';
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import * as utils from 'utils/src/utils';


class FiltersTypeLeaderboardPresenter extends React.Component<IFiltersLeaderboardProps, any> {
  public state: any = {
    isClosed: true,
    data: []
  }

  public componentDidMount() {
    this.getCategories();
  }

  private getCategories = () => {
    utils.API.ratings.leaderboard({ rId: this.props.wcontext.rId }).r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        const { rating } = d.data
        this.setState({
          isClosed: d.isShopClosed,
          data: rating ? rating.filters.map((el: any) =>
            ({
              name: el.name, groups: el.groups.map((group: any) =>
                ({ id: group.pkid, title: group.name, type: 'groupPKID', value: group.pkid }))
            })) : []
        })
      }
    })
  }

  private changeCategory = (selected: ISelectizeItem) => {
    if (!this.props.context.filter || this.props.context.filter.id !== selected.id) {
      this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({ ...acc, [conID]: { filter: selected, [selected.type]: selected.value } }), {} as { [s: string]: any }))
    } else if (this.props.context.filter.id === selected.id) {
      this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({ ...acc, [conID]: { filter: null, [selected.type]: '' } }), {} as { [s: string]: any }))
    }
  }

  public render() {
    const { tag: Tag = 'div', context, className } = this.props;
    const { data, isClosed } = this.state;
    if (isClosed || data.length === 0) return null;
    return (
  // @ts-ignore 
      <Tag data={data} tag='div' context={'polls'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
        {data.map((filter: any) => {
          return <>
            <h4 className={cnFilters('Subtitle')} children={filter.name} />
            <Selectize onClick={this.changeCategory} elements={filter.groups} active={context.filter && context.filter.id} /></>
        })}

      </Tag>
    )
  }
}

export const FiltersTypeLeaderboard = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersLeaderboardProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersTypeLeaderboardPresenter)