import * as React from 'react';
// import * as utils from 'utils/src/utils';


import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeAddWidgetProps, IBodyTypeAddWidgetState, cnWidget, IWidgetToSelect } from './Modal-Body_type_addWidget.index';
import './Modal-Body_type_addWidget.scss';
import { widgets, newWidgets, IWidget, generateWidget, IWidgetProps } from 'i.widgets';
import { v1 as uuid } from "uuid";
// import { CheckboxInput } from 'uielements/src';
import { Button } from 'uielements/src/Button/Button';
// import { InputMention } from 'blocks/InputMention/InputMention';
import { WSettings } from 'blocks/WSettings/WSettings';
import { i18n, Translate } from 'localization';
// import { JsonForms } from 'blocks/JsonForms/JsonForms';

const widget_to_select = (schema: string, title: string = ''): IWidgetToSelect => ({
  schema,
  title,
  type: schema,
  id: uuid(),
})

const types_to_select = [
  'timeline',
  'list',
  'shop',
  'orders',
  'info',
  'textblock'
]

export const withBodyTypeAddWidget = withBemMod<any>(
  cnBody(),
  { type: 'addWidget' }, 
  (Presenter) => 
    class extends React.Component<IBodyTypeAddWidgetProps, IBodyTypeAddWidgetState> {

      constructor(props: IBodyTypeAddWidgetProps) {
        super(props);

        this.state = {
          w: types_to_select.map((type) => widget_to_select(type, i18n.t(`pryaniky.widgets.create.${type}.title`)))
        };
      }

      /**
       * onSelectItem
       */
      public onSelectItem = (selectedId: string) => {
        // const selectedId = ev.currentTarget.getAttribute('name');
        let selected: IWidgetToSelect | undefined;
        this.state.w.forEach(widget => {
          if(widget.id === selectedId) {
            widget.checked = !widget.checked;
            selected = widget;
          } else {
            widget.checked = false;
          }
        });
        this.setState({ selected }, () => {
          // const { schema } = this.state.selected as IWidget<any, any>;
          // store.dispatch(Actions.init(structures[schema].data, structures[schema].schema, structures[schema].uischema));
          // this.props.parent.state.data.type = type;
        });
      }

      public render() {
        const { w, type, selected } = this.state;
        return (
          <Presenter {...this.props}>
            <div className={cnWidget('Selector')}>
              {w.map(widget => <Button key={widget.id} className={cnWidget('Selector-Item', {checked: widget.checked})} onClick={this.onSelectItem.bind(null, widget.id)} children={widget.title} />)}
            </div>
          {
            selected &&
            <div className={cnWidget('Settings')}>
              <WSettings selected={selected} onChange={this.onChange} />
            </div>
          }
          </Presenter>
        )
      }
      
      private onChange = (value: {[s: string]: any}) => {
        const { selected } = this.state;
        if (selected) {
          let opts: IWidgetProps = {
            type: '',
          };
          switch (selected.type) {
            case 'list':
              opts = {
                type: widgets.types.list + (
                  value.data.users ? '/users' :
                  value.data.groups ? 
                  (
                    value.data.groupsTree ? 
                    '/groupsTree' : 
                    '/groups'
                  ) :
                  ''
                )
              }
              break;
            case 'shop':
              opts = {
                type: value.data.mini ? widgets.types.shop : widgets.types.list + '/shop',
              }
              break;
            case 'timeline':
              opts = {
                type: widgets.types.timeline,
              }
              break;
            case 'orders':
              opts = {
                type: widgets.types.list + '/orders',
              }
              break;
            case 'info':
              opts = {
                type: widgets.types.info,
              }
              switch (value.data.type) {
                case 'users':
                  if (value.data.selectedUsers[0]) opts.userId = value.data.selectedUsers[0].id
                  break;
                case 'groups':
                  opts.groupId = value.data.selectedGroups.id
                  break;
              }
              break;
            case 'textblock':
              opts = {
                type: widgets.types.markdown,
              }
              break;
            default:
              console.log("Нет такого типа виджетов");
          }
          this.props.parent.state.data = generateWidget(opts);
        }
      }

    }
    // (props) => (
    //   <Presenter {...props}/>
    // )
  
);