import { IStageCommentProps } from './StageComment.index';
import * as React from 'react'
import './StageComment.scss'
import { Textarea } from 'uielements/src/Textarea/Textarea';
import { FieldsHeader } from '../FieldsHeader/FieldsHeader';
import { i18n, Translate } from 'localization';

export const StageComment: React.FC<IStageCommentProps> = ({ value, onChange }) =>
    <div className={'StageComment'}>
        <FieldsHeader>{i18n.t('comment')}:</FieldsHeader>
        <Textarea className={'StageComment-Textarea'} icon='edit' placeholder={i18n.t('comment')} value={value} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { onChange(event.target.value) }} />
    </div>;