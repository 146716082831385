import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';

import { cnHeader, IHeaderProps, IHeaderState } from './Modal-Header.index';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';

import './Modal-Header.scss';

export class HeaderPresenter extends React.Component<IHeaderProps, IHeaderState> {
  public el: HTMLElement | null;

  public render() {
    return (
      <div ref={el => this.el = el} className={cnHeader({border: this.props.border}, [this.props.className])}>
        <h3 className={cnHeader('Text')}>{this.props.text}</h3>
        <Button onClick={this.props.closeFunction} noBorder={true} noBackground={true} className={cnHeader('Close')} children={<Icon icon='times' className={cnHeader('Close-Icon')} />} />
      </div>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Header = HeaderPresenter;