// secretSantaPage
import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

interface IsecretSantaPageList {
    secretSantaPage: string;
    
}

export const secretSantaPage = (): IWidget<IColumn[]> => {
    const ids = guids<IsecretSantaPageList>(['secretSantaPage']);
    return generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
            //   generateWidget({
            //     type: widgets.types.secretSanta,
            //   }),
              
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.secretSantaPage,
                      }),
                    ],
                    styles: {
                      // width: '72%',
                      width: '100%',
                    },
                  }),
                ],
                styles: {
                  width: '100%',
                },
              }),
            ],
          }),
        ],
      });
    }