import React, { useEffect } from 'react';
import { INotifiSettingsGroupsProps, mapStateToProps, mapDispatchToProps, cnNotifiSettingsGroups } from './NotifiSettingsGroups.index';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';
import './NotifiSettingsGroups.scss';

import Dropdown from 'uielements/src/MaterialElements/DropdownWithText/DropdownWithText';
import { Avatar, Button } from 'uielements/src';

const NotifiSettingsGroupsPresenter: React.FC<INotifiSettingsGroupsProps> = ({
  groups,
  networkId,
  match,
  parrentNetworkId,
  templates,
  isSingleNetwork,
  getNotifiSettingsNetworkGroups,
  changeNotifiSettingsGroupTemplate,
  clearNotifiSettings,
}) => {

  const { uid } = match.params;

  useEffect(() => {
    getNotifiSettingsNetworkGroups({ uid, nid: networkId, parentNid: parrentNetworkId });
  }, [])

  if (!Object.keys(groups).length) return null;

  const showTemplates = [
    ...templates.map((value) => ({ ...value, title: i18n.t('pryaniky.notifications.settings.template.' + value.value) })),
    { title: i18n.t('pryaniky.notifications.settings.groups.templates.custom'), value: 'custom' }
  ]

  return (
    <div className={cnNotifiSettingsGroups()}>
      {Object.keys(groups).map((groupId: string) => {
        const group = groups[groupId];
        const selectedTemplateName = showTemplates.find(template => template.value === group.selectedNotifyTemplate)?.title;
        return (
          <div className={cnNotifiSettingsGroups('Item', [`${isSingleNetwork && 'Widget'}`])}>
            <div className={cnNotifiSettingsGroups('Item-Title')}>
              <Avatar size={36} imgUrl={group.imgUrl} name={group.name} />
              <div>{group.name}</div>
            </div>
            <div className={cnNotifiSettingsGroups('Item-Actions')}>
              <Dropdown
                options={templates.map((value) => ({ ...value, title: i18n.t('pryaniky.notifications.settings.template.' + value.value) }))}
                onClick={(data) => changeNotifiSettingsGroupTemplate({ uid, gid: groupId, nid: networkId, selectedTemplateName: data.value })}
                alignItems='center'
                children={selectedTemplateName}
              />
              <Button
                theme='unstyled'
                type='rlink'
                href={`/notifiSettings/${uid}/group/${group.pkid}/${networkId}`}
                onClick={clearNotifiSettings}
              >
                <Translate i18nKey='pryaniky.notifications.settings.groups.checkDetails' />
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const NotifiSettingsGroups = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(NotifiSettingsGroupsPresenter)));