import React, { useState } from 'react';
import { cnGoalItem, IGoalItemProps, IMapStateToProps, IMapDispatchToProps, mapStateToProps, mapDispatchToProps } from './GoalItem.index';
import { i18n, Translate } from 'localization';
import { NodeBulder } from 'uielements/src/CommonmarkRender';
import { DialogHandGoal } from 'blocks/Dialogs/QuestsHandGoal/HandGoalDialog';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { getFormattedCurrencyName } from 'utils.project/utils.project';
import MDNodeBulder from 'uielements/src/CommonmarkRender/MDNodeBulder';
import { connect } from 'react-redux';
import './GoalItem.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import { Icon } from 'uielements/src';
import IconMUI from '@material-ui/core/Icon';

const GoalItemPresenter: React.FC<IGoalItemProps> = ({
  data,
  type,
  questId,
  currencyFormats,
  listId,
  approveQuestGoal,
}) => {

  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const changeGoal = (dialogData: { [s: string]: any }) => {
    approveQuestGoal({
      questId,
      id: dialogData.id,
      comment: dialogData.comment,
      attachments: dialogData.attachments,
      listId
    });
  }

  // const icon = data.isCompleteByMe ? 'check-circle' : data.pushed ? 'clock' : 'circle';
  const icon = data.isCompleteByMe ? <CheckCircleIcon fontSize="small" htmlColor="#89ca5e" /> : data.pushed ? <TimerOutlinedIcon fontSize="small" /> : <Brightness1OutlinedIcon fontSize="small" />;
  let helpText: any = undefined;
  if (data.pushed || data.isCompleteByMe) {
    helpText = data.isCompleteByMe ? Translate.t({ i18nKey: 'pryaniky.widgets.quests.goal.completed' }) : Translate.t({ i18nKey: 'pryaniky.widgets.quests.goal.onApprove' });
  }

  const hasAction = !!((!data.pushed && !data.isCompleteByMe) || helpText);

  return (
    <>
      <div onClick={hasAction ? () => DialogHandGoal({
        goal: data,
        comment: '',
        attachments: [],
        text: helpText,
        description: data.popupdesc
      }).then((goalData: any) => changeGoal(goalData)) : () => { }}
        className={cnGoalItem('', { clicked: hasAction })}
        key={data.id}>
        <IconMUI
          fontSize="small"
          color={"action"}
          className={cnGoalItem('Icon', [type])} >
          {icon}
        </IconMUI>
        {/* <Icon
          icon={icon}
          className={cnGoalItem('Icon', [type])}
        /> */}
        {data.name}
      </div>

      {/* <HandGoalDialog
        data={{ goal: data, comment: '', attachments: [], text: helpText, description: data.popupdesc }}
        isShown={isShowDialog}
        onConfirm={changeGoal}
        onClose={() => setIsShowDialog(false)}
      
      /> */}
    </>

  );
}

export const GoalItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalItemPresenter);
