import React from 'react';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

export const radio = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <UIElements.Radio
    onChange={(ev: any) => {
      if (schema.relations) schema.relations.forEach((relation: string) => props.dispatch(Actions.update(relation, (oldData) => false)))
      props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => ev.currentTarget.checked))
    }}
    checked={props.data[get_variable_name(props.uischema.scope)]}
    text={props.uischema.label} />
}
// end RadioControl