import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { i18n } from 'localization';
import * as React from 'react';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
import { EditorForm } from '../EditorForm/EditorForm';
import { INewsEditPropsType, INewsEditStateType, mapDispatchToProps, mapStateToProps } from './NewsEdit.index';
import './NewsEdit.scss';

import { NodeBulder } from 'uielements/src/CommonmarkRender';

import { toast } from 'react-toastify';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { EditorTypeEdit as Badges } from '../NewsTypes/Badges/Editor/containers/edit/edit';
import { Rule } from '../NewsTypes/Base.validate';
import { EditorTypeEdit as CreativeTasks } from '../NewsTypes/Creativetasks/Editor/containers/edit/edit';
import { EditorTypeEdit as Uevent } from '../NewsTypes/Events/Editor/containers/edit/edit';
import { EditorTypeEdit as Ideas } from '../NewsTypes/Ideas/Editor/containers/edit/edit';
import { EditorTypeEdit as Notices } from '../NewsTypes/Notices/Editor/containers/edit/edit';
import { EditorTypeEdit as Polls } from '../NewsTypes/Polls/Editor/containers/edit/edit';
import { EditorTypeEdit as Thanks } from '../NewsTypes/Thanks/Editor/containers/edit/edit';
import { rawToMd } from '../PryanikyEditor/convertorConfigs';
// import { EditorTypeNews as Achievements } from '../NewsTypes/Achievements/Editor/containers/edit/edit'
import AT from 'News/redux/actionsTypes/News';
import { createDefaultNews, IBaseNews } from 'News/types/baseNews';
import { prepareToServer } from 'uielements/src/FieldRender';
import { ILoadingProps, withIndicator } from 'utils/src/CommonRedux/LoadIndicator/index';
import { formatDateSTD } from 'utils/src/utils.dates';
import { EditorTypeEdit as Feedback } from '../NewsTypes/Feedback/Editor/containers/edit/edit';
import { EditorTypeEdit as Longread } from '../NewsTypes/Longread/Editor/containers/edit/edit';
import { EditorTypeEdit as News } from '../NewsTypes/News/Editor/containers/edit/edit';
import { EditorTypeEdit as Repost } from '../NewsTypes/Repost/Editor/containers/edit/edit';
import { EditorTypeNews as CreateRepost } from '../NewsTypes/Repost/Editor/containers/news/news';
import { EditorTypeUser } from '../NewsTypes/ThanksUser/Editor/containers/user/user';
import { EditorTypeEdit as Workflows } from '../NewsTypes/Workflows/Editor/containers/edit/edit';

const editorAlias: { [s: string]: React.FunctionComponent<any> } = {
    news: News,
    thanks: Thanks,
    polls: Polls,
    creativetasks: CreativeTasks,
    competitions: CreativeTasks,
    badges: Badges,
    notices: Notices,
    ideas: Ideas,
    events: Uevent,
    thankUser: EditorTypeUser,
    repost: Repost,
    createRepost: CreateRepost,
    feedback: Feedback,
    longread: Longread,
    workflow: Workflows,
};

export class NewsEditPresenter extends React.Component<INewsEditPropsType & ILoadingProps, INewsEditStateType> {
    constructor(props: INewsEditPropsType) {
        super(props);
        const currentNews = props.data ? utils.cloneObject(props.data) : props.defaultData || createDefaultNews();
        // text = '# hello, markdown!';
        const NB = new NodeBulder(currentNews.text || '');
        let text = EditorState.createWithContent(convertFromRaw(NB.getDraftRaw()));

        this.state = {
            validFile: true,
            data: { ...currentNews, text, header: utils.removeMdLink(currentNews.header) },
            isValid: false,

            postErrorText: '',
            postErrorCode: 0,
            validationErrors: [],
        };
    }

    componentDidUpdate = (prev: INewsEditPropsType) => {
        if (this.props.updateError?.error_code !== prev.updateError?.error_code) {
            this.setState({
                postErrorCode: this.props.updateError?.error_code || 0,
                postErrorText: this.props.updateError?.error_text || '',
            });
        }
    };

    public render() {
        if (!this.props.type) return <div>error! no type</div>;
        const { data, isValid, validFile, postErrorText, postErrorCode, validationErrors } = this.state;
        const { cancleEdit, type, children, denyMIME, maxFileSize, postTypeParams, userData } = this.props;
        const CurrentType = editorAlias[type] ? editorAlias[type] : editorAlias['news'];
        let disableSend = !isValid;
        disableSend = validFile ? disableSend : !validFile;
        disableSend = postErrorCode === 0 ? disableSend : true;

        const currentDate = new Date();

        const publishDate = data.publishAt ? new Date(data.publishAt) : null;

        const showPublichAt = publishDate && currentDate < publishDate && userData.baseData.isAdmin;
        return (
            <EditorForm
                isSending={this.props.isLoading}
                disableAttach={this.props.disableAttach}
                files={data.attachments || []}
                onChangeFile={this.onChangeFile}
                save={this.sendNews}
                cancle={cancleEdit}
                isValid={disableSend}
                denyMIME={denyMIME}
                maxFileSize={maxFileSize}
                postErrorText={postErrorText}
                postErrorCode={postErrorCode}
                validationErrors={validationErrors}
                onChangePublishAt={showPublichAt ? this.onChangePublishAt : undefined}
                publishAt={data.publishAt}
                saveText={
                    showPublichAt
                        ? i18n.t('pryaniky.createPost.submit.publishAt') + formatDateSTD(data.publishAt, true, true)
                        : data?.achievement?.achievementOnCorrect
                        ? i18n.t('pryaniky.editPost.achievementOnCorrect')
                        : i18n.t('pryaniky.editPost.save')
                }
            >
                <CurrentType typeParams={postTypeParams} onChange={this.onChange} data={data} />
                {children}
            </EditorForm>
        );
    }

    private onChangeFile = (files: any[], validate: boolean) => {
        this.setState((prevState) => ({
            data: { ...prevState.data, attachments: files },
            validFile: validate,
        }));
    };

    private onChangePublishAt = (date: string | null) => {
        this.setState({
            data: {
                ...this.state.data,
                publishAt: date,
            },
            // postErrorCode: 0,
            // postErrorText: ''
        });
        // this.props.pathCreator({
        //     currentNews: {
        //         ...this.props.currentNews,
        //         publishAt: date
        //     }
        // })
    };

    private onChange = (data: IBaseNews, validate: boolean, errors: Rule[] = []) => {
        this.setState({
            data,
            isValid: validate,
            validationErrors: errors,
            // postErrorCode: 0,
            // postErrorText: ''
        });
        this.props.setUpdateError({
            id: data.id,
            error_code: 0,
            error_text: '',
        });
    };

    private sendNews = () => {
        if (!this.state.data) return;
        const text = draftToMarkdown(convertToRaw(this.state.data.text.getCurrentContent()), rawToMd);
        const additionalFieldsValues = prepareToServer(this.state.data.additionalFields?.additionalFieldsValues || []);
        const data = {
            ...this.state.data,
            additionalFields: {
                ...this.state.data.additionalFields,
                additionalFieldsValues,
            },
            text,
        };

        const completed = (success: boolean) => {
            this.setState({
                postErrorCode: this.props.updateError?.error_code,
                postErrorText: this.props.updateError?.error_text,
            });
            if (success) {
                this.props.successMsg && toast.success(this.props.successMsg);
            } else {
                this.props.failMsg && toast.error(this.props.failMsg);
            }
            this.props.compliteEdit(data);
        };

        this.props.create ? this.props.sendNews(data as any, completed) : this.props.updateNews(data as any, completed);

        /*this.props.create ? this.props.createNewsRequest(data,
            () => {
                this.props.successMsg && toast.success(this.props.successMsg)
                this.props.compliteEdit(data)
            },
            (d: any) => {
                this.setState({
                    postErrorCode: d.error_code,
                    postErrorText: d.error_text
                })
                this.props.failMsg && toast.error(this.props.failMsg)
            }
        ) : this.props.updateNewsRequest(data,
            () => {
                this.props.successMsg && toast.success(this.props.successMsg)
                this.props.compliteEdit(data)
            },
            (d: any) => {
                this.setState({
                    postErrorCode: d.error_code,
                    postErrorText: d.error_text
                })
                this.props.failMsg && toast.error(this.props.failMsg)
            }
        )*/
    };
}

export const NewsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withIndicator(NewsEditPresenter, AT.UPDATE_NEWS)());
