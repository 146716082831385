import { GraphicEditor, UsersSuggester } from 'muicomponents/src';
import { DraftInput,  } from 'muicomponents/src/DraftInput/DraftInput';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { CardWrapper, EditorFrame } from './Card.styled';
import { i18n, Translate } from 'localization';
import { union } from 'lodash';
import { useCard } from './Card.hooks';
import { LinearProgressWithLabel } from 'muicomponents/src/LinearProgress';
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton';
import { CanvaEditor } from './Editor/Editor';
import { useSelector } from 'react-redux';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { PrepareErrorText } from 'News/utils/utils.validate';

const rusSusets = ["cyrillic", "cyrillic-ext"];

/**
 * send card to user block
 * @param param0 
 * @returns 
 */
export const Card: FC<{}> = ({

}) => {

    const {
        users,
        setUsers,
        draftState,
        setDraftState,
        newUsers,
        file,
        changeFile,
        fileProgress,
        isFileLoading,
        send,
        sending,
        frameId,
        editorRef,
        errors
    } = useCard();

    const cardsIsEnabled = useSelector(moduleIsEnabled('postcards'))

    const usersRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(!!errors.users) {
            usersRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [errors.users]);

    return (
        <CardWrapper>
            <UsersSuggester
                // ref={usersRef}
                multiple
                value={users as any}
                onChange={( event, value, reason, details ) => {
                    if(reason === 'createOption') {
                        newUsers.current = union([ ...newUsers.current, details?.option.id || '' ]);
                    }
                    if(Array.isArray(value)) setUsers(value);
                }}
                createItems={{
                    allow: true
                }}
                TextFieldProps={{
                    ref: usersRef,
                    label: <Translate i18nKey={'pryaniky.card.users.title'} />,
                    error: !!errors.users,
                    helperText: !!errors.users && PrepareErrorText(errors.users)
                }}
            />
            {
                fileProgress > 0 &&
                <LinearProgressWithLabel value={fileProgress} variant="determinate" />
            }
            <GraphicEditor
                id={frameId}
                ref={editorRef}
                singleTemplate
                avaliableElements={[]}
                fixed
                mode='cards'
                textSubsets={rusSusets}
                onSaveImage={(file) => {
                    if(file instanceof File) {
                        changeFile(file);
                    }
                }}
            />
            <DraftInput
                label={<Translate i18nKey='pryaniky.card.text' />}
                value={draftState}
                onChange={setDraftState}
            />
            <LoadingButton
                fullWidth
                disabled={!!Object.values(errors).filter(Boolean).length}
                variant={'contained'}
                loading={sending}
                onClick={() => send()}
            >
                <Translate i18nKey={'pryaniky.card.send'} />
            </LoadingButton>
        </CardWrapper>
    );
};