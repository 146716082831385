import { Box } from '@material-ui/core';
import { styled } from 'muicomponents/src';

export const LoaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 150,
    height: '100%',
    zIndex: 5,
}) as typeof Box;
