import { IEditorProps, mapEditorStateToProps, IMapEditorStateToProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import { Input, Icon, Button } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { TimePicker2 } from '../../../TimePicker2/TimePicker2';
import { Select } from 'uielements/src/Select/Select';
import { createDurationOptions, findSelected } from '../Utils'
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { CheckboxInput } from 'uielements/src';
import { connect } from 'react-redux';

export const EditorPresenter: React.FC<IEditorProps & IMapEditorStateToProps> = ({
    children,
    onChangeVideoConference,
    allowCreateVideoConference,
    data,
    onChangeLocation,
    durationChange,
    onChangeUsers,
    expiresChange,
    onChangeText,
    onChangeTitle,
    isAdmin
}) => {
    const { users = [], groups = [] } = data
    const usergroups = [...users, ...groups]
    return <div className={'Editor EventEditor'}>
        <Input onChange={onChangeTitle}
            className={'EventEditor-Title'}
            value={data.header}
            icon='flag'
            placeholder={i18n.t('pryaniky.createPost.event.name')} />

        {onChangeUsers && <PrnUserInput
            className={'EventEdit-Users'}
            excludeMe={true}
            placeholder={Translate.t({ i18nKey: 'pryaniky.createPost.event.participants' })}
            excludeUsers={[]}
            onChange={onChangeUsers}
            type={isAdmin ? 'usersgroups' : 'users'}
            value={usergroups} />}

        <div className={'EventEdit-Expires'}>
            <DateTimeInput
                minDate={new Date()}
                className={'EventEdit-ExpiresItem EventEdit-Datapicker'}
                value={new Date(data.uevent.startDateTime)}
                onChange={expiresChange}
                icon={(onClick) => <Button {...{ onClick }} ><Icon icon='calendar' /></Button>}
            />
            <TimePicker2
                className={'EventEdit-ExpiresItem EventEdit-Timepicker'}
                onChange={expiresChange}
                value={new Date(data.uevent.startDateTime)} />
            <Select
                className={'EventEdit-ExpiresItem EventEdit-Select'}
                onChange={durationChange}
                options={createDurationOptions()}
                icon={'clock'}
                selected={findSelected(data.uevent.duration)} />
        </div>

        <Input
            onChange={onChangeLocation}
            value={data.uevent.eventLocation}
            icon='location'
            placeholder={i18n.t('pryaniky.createPost.event.location')} />

        <PryanikyEditor
            onChange={onChangeText}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.event.description')} />

        <div className={'EventEdit-Checkboxes'}>
            {(allowCreateVideoConference && onChangeVideoConference) && <CheckboxInput
                onChange={() => onChangeVideoConference(!(data.uevent.meeting ? data.uevent.meeting.online : false))}
                checked={data.uevent.meeting ? data.uevent.meeting.online : false}
                padding="0"
                children={<HelpTooltip help={i18n.t("Видеоконференция")}>
                    <Icon icon="question-circle"></Icon>
                </HelpTooltip>}
                text={"Видеоконференция"}
            />}
        </div>
        {children}
    </div>
}

export const Editor = connect(mapEditorStateToProps)(EditorPresenter);