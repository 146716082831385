/**
 * @packageDocumentation
 * @module News_type_tasks_Task_type_DocumentReadBlock
 */
import React, { RefObject } from 'react';
import { ITaskDocumentReadBlockProps, TaskDocumentReadBlockState, cnTaskDocumentReadBlock, TaskDocumentReadBlockFileItem } from './TaskDocumentReadBlock.index';
import { Button, Icon } from 'uielements/src';
import { i18n, Translate } from 'localization';
import * as utils from "utils/src/utils";
import './TaskDocumentReadBlock.scss';
import { v1 as uuid } from 'uuid';
import { DReadItem } from "./Item/TaskDocumentReadBlock-Item";
import { toast } from 'react-toastify';

class TaskDocumentReadBlockPresenter extends React.Component<ITaskDocumentReadBlockProps, TaskDocumentReadBlockState> {
  public inputRef: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  public state: TaskDocumentReadBlockState = {
    files: []
  }

  /**
   * loadInit
   */
  public loadInit = () => this.inputRef.current && this.inputRef.current.click();

  /**
   * inputChange - on input file choose
   */
  public inputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const fls: TaskDocumentReadBlockFileItem[] = [];
    e.currentTarget.files && Array.from(e.currentTarget.files).forEach(file => {
      fls.push({
        id: uuid(),
        file,
        name: file.name,
        status: 'progress',
        progress: 0
      })
    })

    this.setState({
      files: [...this.state.files, ...fls],
    }, () => {
      this.state.files.forEach(fl => this.fileLoad(fl))
    })
  }

  /**
   * fileLoadStart - on file load start
   */
  private fileLoadStart = (id: string, event: any, file: any) => this.setState({ files: this.state.files.map(el => el.id === id ? { ...el, status: 'progress' } : el) });

  /**
   * fileLoadError - on file load error
   */
  private flieLoadError = (id: string, event: any, file: any) => this.setState({ files: this.state.files.map(el => el.id === id ? { ...el, status: 'error' } : el) })

  /**
   * fileLoadPropgress - on file load in propgress
   */
  private fileLoadProgress = (id: string, event: any, file: any) => this.setState({ files: this.state.files.map(el => el.id === id ? { ...el, progress: Math.ceil((event.loaded * 100) / event.total) } : el) });

  /**
   * fileLoadFinish - on file load finish
   */
  private fileLoadFinish = (id: string, response: any, file: any) => {
    if (utils.checkResponseStatus(response)) {
      this.setState({ 
        files: this.state.files.map(el => el.id === id ? { ...el, status: 'success', response: response.data[0] } : el),
      }, () => {
        this.props.onChange && this.props.onChange(this.state.files.map(el => el.response));
      });
    } else {
      toast.error('file loading fail');
    }
  }

  /**
   * fileLoad - init load one file
   */
  private fileLoad = (file: TaskDocumentReadBlockFileItem) => {
    utils.API.files.upload(file.file, {
      loadStart: this.fileLoadStart.bind(null, file.id),
      loadError: this.flieLoadError.bind(null, file.id),
      loadProgress: this.fileLoadProgress.bind(null, file.id),
      loadFinish: this.fileLoadFinish.bind(null, file.id)
    });
  }

  /**
   * onFileRemove - remove item from files
   */
  public onFileRemove = (id: string) => this.setState({ 
    files: this.state.files.filter(file => file.id !== id),
  }, () => {
    this.props.onChange && this.props.onChange(this.state.files.map(el => el.response));
  });

  /**
   * render
   */
  public render() {
    return (
      <div className={cnTaskDocumentReadBlock()}>
        <div className={cnTaskDocumentReadBlock('Header')}>
          <div children={i18n.t('pryaniky.createPost.tasks.documentReadBlock.title')} />
          <Button theme='unstyled' className={cnTaskDocumentReadBlock('Remove')} onClick={this.props.onRemove} children={<Icon icon='times' />} />
        </div>
        <input ref={this.inputRef} onChange={this.inputChange} type='file' hidden multiple />
        <Button className={cnTaskDocumentReadBlock('Fileload')} onClick={this.loadInit} children={i18n.t('pryaniky.createPost.tasks.documentReadBlock.load')} />
        {
          this.state.files.map(file => <DReadItem data={file} onRemove={this.onFileRemove} />)
        }
        {/* Icon_engine-warning */}
      </div>
    )
  }
}

export const TaskDocumentReadBlock = TaskDocumentReadBlockPresenter