import noDataImage from 'assets/svg/NoData.svg';
import { i18n, Translate } from 'localization';
import { Box } from 'muicomponents/src';
import ContentView from 'News/containers/ContentView';
import { useTimelineLoader } from 'News/utils/hooks';
import React, { FC, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'uielements/src';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { CustomSettings } from 'utils';
import { useWidgetContext } from 'Widgets_v2/context';
import { MiniTimelineList } from '../../components/MiniTimelineList/MiniTimelineList';
import TimelineHeader from '../../components/TimelineHeader';
import NewsItem from '../NewsItem';
import {
    cnClassName,
    cnClassNameNew,
    cnClassNameNoData,
    mapDispatchToProps,
    mapStateToProps,
    News,
} from './interfaces';
import './style.scss';

const useMoexDesign = CustomSettings.useMoexDesign();

type IPresenterProps = News.NewsSlider.IPresenterProps;
type IOwnProps = News.NewsSlider.IOwnProps;
type IDispatchProps = News.NewsSlider.IDispatchProps;
type IStateProps = News.NewsSlider.IStateProps;
type IState = News.NewsSlider.IState;

export const PostSkeleton = memo(() => (
    <div className="PostSkeleton" style={{ borderRadius: 0, paddingBottom: '8px' }}>
        <div className="PostSkeleton-Header">
            <Skeleton size={'sm'} shape="circle" />
            <div style={{ flexGrow: 1, marginLeft: 12 }}>
                <Skeleton shape="line" style={{ width: '60%' }} />
                <Skeleton size="xs" shape="line" />
            </div>
        </div>
        <Skeleton size="xs" shape="line" />
        <Skeleton size="xs" shape="line" style={{ width: '60%' }} />
        <Skeleton size="xs" shape="line" style={{ width: '80%' }} />
        <Skeleton size="xs" shape="line" style={{ width: '40%' }} />
    </div>
));

export const Presenter: FC<IPresenterProps> = ({
    className,
    isFinished,
    keys,
    params = { newsTypes: 'all' },
    viewType,
    settings,
    active,
    setActiveNews,
    hideNews,
    header,
    context,
    ...props
}) => {
    // return null
    const setActive = (id: string) => setActiveNews(params, id);
    const { sendWidgetSignal } = useWidgetContext();

    if (settings?.viewType) viewType = settings?.viewType;
    if (viewType === 'content' && keys.length && !active) setActive(keys[0]);

    const { isFailed, isLoading, loadMore } = useTimelineLoader(params, keys, undefined, true); //, params.count)

    const currentIndex = keys.indexOf(active);
    useEffect(() => {
        if (keys.length !== 0 && currentIndex !== -1 && currentIndex === keys.length - 1 && !isFinished)
            !isFailed && loadMore();
    }, [currentIndex, keys.length, isLoading]);

    useEffect(() => {
        sendWidgetSignal(`dataUpdate`);
    }, [currentIndex, keys.length]);

    const next = () => {
        const idx = (keys.findIndex((e) => e == active) + 1) % keys.length;
        setActive(keys[idx]);
    };

    const previous = () => {
        let idx = keys.findIndex((e) => e == active) - 1;
        if (idx < 0) idx = keys.length - 1;
        setActive(keys[idx]);
    };
    let disableNavigation = isFinished && keys.length === 0;
    disableNavigation = (isLoading && keys.length === 0) || disableNavigation;
    disableNavigation = (isFinished && keys.length === 1) || disableNavigation;

    if (!context?.hideIfNoData && keys.length === 0)
        return (
            <div className={cnClassNameNoData({}, [className])}>
                <div className={cnClassNameNoData('Header-Title')} children={header} />
                <div className={cnClassNameNoData('Header-noData')}>
                    <Avatar precentage={100} shape={'rectangle'} imgUrl={noDataImage} />
                </div>
                <span className={cnClassNameNoData('Header-Title-Text')}>
                    {i18n.t('pryaniky.widgets.settings.type.birthdays.noData.text')}{' '}
                </span>
            </div>
        );

    if (context?.hideIfNoData && hideNews && keys.length === 0)
        return <div className={useMoexDesign ? cnClassNameNew({}, [className]) : cnClassName({}, [className])}></div>;
    if (context?.hideIfNoData && !hideNews && keys.length === 0) return null;

    return (
        <div className={useMoexDesign ? cnClassNameNew({}, [className]) : cnClassName({}, [className])}>
            <Box className={useMoexDesign ? cnClassNameNew('BackgroundImg') : ''}>
                <TimelineHeader
                    className={useMoexDesign ? cnClassNameNew('Header') : cnClassName('Header')}
                    next={next}
                    previous={previous}
                    value={currentIndex + 1}
                    isLoading={isLoading}
                    disableNavigation={disableNavigation}
                    totalCount={keys.length}
                    splitter={useMoexDesign ? '/' : '-'}
                    sx={
                        useMoexDesign
                            ? {
                                  marginTop: '8px',
                                  paddingLeft: '8px',
                                  paddingRight: '8px',
                                  marginBottom: '2px',

                                  ['& > .NewsTimelineHeader-Title']: {
                                      padding: 0,
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      lineHeight: '28px',
                                  },
                              }
                            : {}
                    }
                >
                    {header}
                </TimelineHeader>
                <MiniTimelineList
                    isFinished={isFinished}
                    isLoading={isLoading}
                    isError={isFailed}
                    loadMore={loadMore}
                    itemsCount={keys.length}
                    loadNode={<PostSkeleton />}
                >
                    {keys[currentIndex] && (
                        <NewsItem
                            className={useMoexDesign ? cnClassNameNew('Item') : cnClassName('Item')}
                            key={keys[currentIndex]}
                            id={keys[currentIndex]}
                            // первым проверяем slider_news_types, если там не нашли, то ищим в news_types в качестве дефолтного.
                            // порядок массива важен, это приоритетность
                            nodeKeys={['slider_news_types', 'news_types']}
                            ViewComponent={ContentView}
                        />
                    )}
                </MiniTimelineList>
            </Box>
        </div>
    );
};

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
