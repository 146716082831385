import React, { FC, useState } from 'react'
import { IProps, mapDispatchToProps, mapStateToProps, cnCreateFolder } from './CreateFolder.index'
import { connect } from 'react-redux';
import './CreateFolder.scss'
import { Button, Icon } from 'uielements/src'
import { i18n, Translate } from 'localization';
import { NameForm } from '../../NameForm/NameForm'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'

export const CreateFolder_: FC<IProps> = ({
    gid,
    className,
    createFolder
}) => {
    const [nameInput, setNameInput] = useState(false)

    const createFolderAction = (folderName: string) => {
        createFolder(gid, folderName)
        setNameInput(false)
    }

    return <div className={cnCreateFolder({}, [className])}>
        {nameInput && <NameForm cancel={() => setNameInput(false)} folderName={'New Folder'} setFolderName={createFolderAction} />}
        {!nameInput && <MuiButton className={cnCreateFolder('Action')} variant={'contained'} color={'primary'} onClick={() => setNameInput(true)}>{i18n.t('pryaniky.files.createFolder')}<Icon icon={'folder-plus'} /></MuiButton>}
    </div>
}

export const CreateFolder = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateFolder_)