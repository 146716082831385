import { i18n, Translate } from 'localization';
import { BaseSuggester as BaseSuggesterC, Box, Divider } from 'muicomponents/src';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { MilitaryTech } from 'muicomponents/src/Icons/Icons';
import { UsersSuggester } from 'muicomponents/src/Suggester/type/UsersSuggester';
import React, { ComponentProps, FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import './AchievementsFor.scss';

import { PrepareErrorText } from 'News/utils/utils.validate';
import { useDidUpdateEffect } from 'utils/src/hooks';

import { red } from '@material-ui/core/colors';
import Tooltip from 'muicomponents/src/Tooltip/Tooltip';
import { TooltipNews } from 'muicomponents/src/TooltipNews/TooltipNews';
import { Typography } from 'muicomponents/src/Typography/Typography';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { memoizedComponent } from 'News/creator/utils.sender';
import MDRender from 'uielements/src/CommonmarkRender';
import { getCurrencyNameCase } from 'utils/src';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { IRequest } from 'utils/src/requests/service';
import { API } from 'utils/src/utils';
import { formatDateNumbers } from 'utils/src/utils.dates';
import { AchievementsValidateRules } from '../Achievements/Achievements.validate';
import { useAchievementsEdit } from './AchievementsFor.hooks';
import { AchiementNotInterested, AchiementOptionBox, AchiementThanksCountBox } from './AchievementsFor.styled';

const prepareI18nKey = (key: string) => `pryaniky.news.create.achievements.${key}`;

const BaseSuggester = memoizedComponent(BaseSuggesterC);
const DraftInput = memoizedComponent(DraftInputC);

// ControlLimitwithErrorInterface
export type ControlLimitwithErrorInterface = {
    data: LimitInterface;
    error_code: number;
    error_text: string;
};

export type LimitInterface = {
    limit: ControlLimitInterface;
    postCount: number;
};

export type ControlLimitInterface = {
    limitBegin: string;
    limitEnd: string;
    limitValue: number;
};

let request: IRequest;

const NewsTypeAchievementsPresenter: FC<{}> = ({}) => {
    const [controlLimit, setControlLimit] = useState<ControlLimitwithErrorInterface | null>(null);
    const { creatorId } = useContext(NewsCreateContext);
    const [loading, setLoading] = useState<boolean>(false);

    const { data, errors, clearEditor, hideSender, achievementsParams, changeNews, changeCreator } =
        useAchievementsEdit(creatorId);
    // console.log('achievementsParams', achievementsParams)
    // console.log('data', data)
    const { text: currentNewsText, draftRawState, achievement } = data;

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const suggesterOptions = useMemo(() => {
        const goals: (Required<NonNullable<typeof achievementsParams>>['achievements'][number]['goals'][number] & {
            group: string;
        })[] = [];
        achievementsParams?.achievements?.forEach((group) => {
            group.goals.forEach((goal) => {
                goals.push({
                    ...goal,
                    group: group.name,
                });
            });
        });
        return goals.map((el) => ({
            ...el,
            displayName: el.name,
        }));
    }, [achievementsParams?.achievements]);

    // для того чтобы не было изменения модели для отправки
    const selectedSuggesterItem = useMemo(() => {
        return suggesterOptions.find((el) => el.id === achievement?.id) || null;
    }, [achievement, suggesterOptions]);

    const renderAchievementsOption: ComponentProps<typeof BaseSuggester>['renderOption'] = (props, option, state) => {
        const optionTyped = option as unknown as typeof suggesterOptions[number];
        return (
            <AchiementOptionBox>
                <Typography
                    variant="body2"
                    sx={{
                        color: !optionTyped.allowReportByUser ? '#0000008a' : '',
                    }}
                >
                    {optionTyped.displayName}
                </Typography>
                {!optionTyped.allowReportByUser && (
                    <Tooltip title={<Translate i18nKey={prepareI18nKey('allowReportByUser')} />}>
                        <AchiementNotInterested />
                    </Tooltip>
                )}
                {/* {
                    (optionTyped.periodLimit && optionTyped.allowReportByUser) && <Tooltip title={
                        <Translate i18nKey={prepareI18nKey('limited')} />
                    }>
                        <AchiementLimited />
                    </Tooltip>
                } */}
            </AchiementOptionBox>
        );
    };

    const changeAchievement: ComponentProps<typeof BaseSuggester>['onChange'] = useCallback(async function (e, value) {
        let selected: any = null;

        if (value) {
            selected = { ...value } as Partial<typeof suggesterOptions[number]>;
            delete selected.displayName;
            delete selected.group;
        }

        if (request) {
            request.ac.abort();
        }
        changeNews({
            achievement: selected,
        });

        setLoading(true);

        let limit = false;

        if (selected) {
            request = API.news.checkLimitRequest(selected.id);
            const responseLimit = await request.r;
            limit = responseLimit.error_code === 5027;
            setControlLimit(responseLimit);
        }
        setLoading(false);

        changeCreator({
            hideSender: selected ? !selected.allowReportByUser || limit : false,
        });
    }, []);

    const { state, draftState, setDraftState } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if (!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw,
        });
    }, [state]);

    useDidUpdateEffect(() => {
        !selectedSuggesterItem && setControlLimit(null);
    }, [selectedSuggesterItem]);

    const draftCommonProps = useDraftEditorStyle();

    let allow = selectedSuggesterItem
        ? selectedSuggesterItem.allowReportByUser && controlLimit?.error_code !== 5027
        : true;

    const currencyNames = useSelector(getCurrencyNames);

    const achievementThanksCountCurrencyText = useMemo(() => {
        if (!achievement) return '';
        const currencyKeyName = getCurrencyNameCase(achievement.thanksCount || 0) as keyof typeof currencyNames;
        return currencyNames[currencyKeyName].toLowerCase();
    }, [achievement]);

    const howManyAchivmentsLeft: number = controlLimit?.data
        ? controlLimit?.data.limit.limitValue - controlLimit?.data.postCount
        : 0;

    const rightFormOfWordAchivements = () => {
        if (howManyAchivmentsLeft) {
            const lastNumberIndex = howManyAchivmentsLeft.toString().length - 1;
            const lastElementToNumber = Number(howManyAchivmentsLeft.toString()[lastNumberIndex]);

            const achivenmentForm =
                howManyAchivmentsLeft >= 10 && howManyAchivmentsLeft <= 20
                    ? 'megaPlural'
                    : lastElementToNumber === 1
                    ? 'singular'
                    : lastElementToNumber > 1 && lastElementToNumber < 5
                    ? 'plural'
                    : lastElementToNumber >= 5 || lastElementToNumber === 0
                    ? 'megaPlural'
                    : 'megaPlural';
            return achivenmentForm;
        } else {
            return 'controlLimit';
        }
    };

    const BaseSuggesterProps = useMemo<Partial<ComponentProps<typeof BaseSuggester>>>(
        () => ({
            groupBy: (option) => (option as unknown as typeof suggesterOptions[number]).group,
            renderOption: (props, option, state) => renderAchievementsOption(props, option, state),
            TextFieldProps: {
                label: <Translate i18nKey={prepareI18nKey('achievement')} />,
                InputProps: {
                    startAdornment: <MilitaryTech />,
                },
                error: Boolean(errors.achievement),
                helperText: Boolean(errors.achievement) && PrepareErrorText(errors.achievement),
            },
            filterOptions: (options, state) =>
                (options as unknown as typeof suggesterOptions).filter((el) =>
                    el.displayName.toLowerCase().includes(state.inputValue.toLowerCase())
                ) as any,
            filterSelectedOptions: true,
        }),
        [errors.achievement]
    );

    const UsersSuggesterProps = useMemo<Partial<ComponentProps<typeof UsersSuggester>>>(
        () => ({
            TextFieldProps: {
                label: <Translate i18nKey={prepareI18nKey('users')} />,
                error: Boolean(errors.users),
                helperText: Boolean(errors.users) && PrepareErrorText(errors.users),
            },
            onChange: (_, users) => {
                if (Array.isArray(users)) {
                    const usersList = users.filter((el) => el.type === 'user');
                    if (!usersList.length || usersList.length < 1) {
                        AchievementsValidateRules.users!.notNull!.value = true;
                    }
                    changeNews({
                        users: (usersList || []) as any,
                    });
                }
            },
        }),
        [errors.users]
    );

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(
        () => ({
            label: <Translate i18nKey={prepareI18nKey('text')} />,
        }),
        []
    );

    return (
        <>
            {achievementsParams && achievementsParams.showTooltip && (
                <TooltipNews newsParams={achievementsParams} title={achievementsParams.tooltipHeader} />
            )}

            <UsersSuggester
                {...UsersSuggesterProps}
                multiple
                value={(data as any).users}
                onChange={(_, u) => {
                    console.log('u', u);
                    changeNews({
                        users: u,
                    } as any);
                }}
            />

            <BaseSuggester
                {...BaseSuggesterProps}
                test-id={'timeline-create-achievements-achievement'}
                options={suggesterOptions as any}
                loading={loading}
                value={selectedSuggesterItem as any}
                onChange={changeAchievement}
            />
            {achievement && !!achievement.thanksCount && (
                <AchiementThanksCountBox>
                    <Translate
                        i18nKey={prepareI18nKey('achievementThanksCount')}
                        values={{
                            count: achievement.thanksCount,
                            currencyText: achievementThanksCountCurrencyText,
                        }}
                    />
                </AchiementThanksCountBox>
            )}

            {controlLimit?.data && (
                // <Typography variant="body2" >
                <Typography variant="body2" sx={{ color: red[500] }}>
                    <Translate
                        i18nKey={prepareI18nKey(
                            controlLimit.error_code === 5027 ? 'controlLimit.over' : rightFormOfWordAchivements()
                        )}
                        values={{
                            postCount: controlLimit?.data.limit.limitValue - controlLimit?.data.postCount,
                            limitBegin: formatDateNumbers(controlLimit?.data.limit.limitBegin),
                            limitEnd: formatDateNumbers(controlLimit?.data.limit.limitEnd),
                            // achivementForm: rightFormOfWordAchivements()
                        }}
                    />
                </Typography>
            )}
            {achievement?.popupdesc && (
                <Box>
                    <MDRender textCut={true} maxCharCount={150} source={achievement.popupdesc} />
                </Box>
            )}
            {!allow && (
                <>
                    <Divider />
                    <Box sx={{ textAlign: 'center' }}>
                        <Translate i18nKey={prepareI18nKey('achievementnotallow')} />
                    </Box>
                </>
            )}
            <DraftInput
                test-id={'timeline-create-achievements-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                value={draftState}
                disableToolbar={!achievementsParams?.allowRichEdit}
                onChange={setDraftState}
                error={Boolean(errors.text)}
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean(errors.text) && PrepareErrorText(errors.text as any)
                }
                sx={{
                    display: allow ? undefined : 'none',
                }}
                clearValue={clearEditor}
            />
        </>
    );
};

export const NewsTypeAchievements = NewsTypeAchievementsPresenter;

export const initNewsAchievementsForCreator = () =>
    ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeAchievements />, 'achievements.moderation');
// export const initNewsAchievementsForCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeAchievements />, 'achievements');
