import React, { FC, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { IProps, mapDispatchToProps, mapStateToProps } from './Item.index'
import { connect } from 'react-redux';
import { OptionContent } from 'uielements/src/Dropdown/Dropdown'
import * as utilsProj from 'utils.project/utils.project';
import { i18n, Translate } from 'localization';
import FileItem from '../FileItem/FileItem'
import FolderItem from '../FolderItem/FolderItem'
import { CustomSettings } from 'utils/src';
import { NewsAttachV3 } from 'utils/src/BaseTypes/attachements.types';
import { AttachementHistoryDialog } from 'blocks/DialogsV2/AttachementHistory/AttachementHistory';
import { FilesAdd } from 'blocks/DialogsV2/Files/FilesAdd';

const VIEW_TYPES = ['ImagePreview', 'VideoPreview', 'DocumentPreview'];

export const Item_: FC<IProps> = ({
    file,
    selected,
    moveFile,
    selectFile,
    deleteFile,
    showControls,
    activeMoveng,
    location,
    showMove,
    viewOpen = () => { },
    renameFolder
}) => {
    const [redirect, setRedirect] = useState<boolean>(false)
    if (redirect) {
        return <Redirect
            to={{
                pathname: location.pathname,
                search: `?folder=${file.name === '..' ? file.parentFolder : file.id}`
            }}
        />
    }
    const onClick = () => setRedirect(true)
    const onSelect = () => selectFile(file.groupId, file.id)
    const onRemane = (name: string) => renameFolder(file.groupId, name, file.id)
    const onDelete = () => {
        utilsProj.confirm({ text: i18n.t('pryaniky.files.item.delete'), onConfirm: () => deleteFile(file.groupId, file.id) })
    }
    const move = () => {
        (activeMoveng && file.isFolder) && moveFile(file.groupId, file.id)
    }

    const actions: any[] = [];

    (file.actions as NewsAttachV3['actions'])?.forEach(el => {
        switch (el.id) {
            case 'delete': {
                actions.push({
                    content: i18n.t('pryaniky.files.item.delete'),//<OptionContent icon={'trash'} text={i18n.t('pryaniky.files.item.delete')}/>,
                    action: 'delete',
                    buttonProps: { onClick: onDelete },
                });
                break;
            };
            case 'download': {
                if (!file.isFolder) {
                    actions.push({
                        content: i18n.t('pryaniky.files.download'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                        action: 'view',
                        buttonProps: { type: 'link', href: file.originalUrl, download: true },
                    });
                }
                break;
            };
            case 'open': {
                if (!file.isFolder) {
                    actions.push({
                        content: i18n.t('pryaniky.files.open'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                        action: 'view',
                        buttonProps: { type: 'link', href: file.originalUrl, download: false },
                    });
                }
                break;
            };
            case 'move': {
                actions.push({
                    content: i18n.t('pryaniky.files.move'),//<OptionContent icon={'arrow-alt-right'} text={i18n.t('pryaniky.files.move')} />,
                    action: 'move',
                    buttonProps: { onClick: () => { !selected && onSelect();showMove()} },
                });
                break;
            };
            case 'preview': {
                if (VIEW_TYPES.includes(file.previewAction)) {
                    actions.push({
                        content: i18n.t('pryaniky.files.view'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                        action: 'view',
                        buttonProps: { onClick: viewOpen },
                    });
                }
                break;
            };
            case 'upload': {
                if(!file.isFolder) {
                    actions.push({
                        content: i18n.t('pryaniky.files.item.upload'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
                        action: 'view',
                        buttonProps: { onClick: () => FilesAdd({ fileId: file.id }).then(() => {}).catch(() => {}) },
                    });
                }
                break;
            };
            case 'editinoffice': {
                if(!file.isFolder && file.editUrl) {
                    actions.push({
                        content: i18n.t('pryaniky.files.item.editInOffice'),
                        action: 'editInOffice',
                        buttonProps: { type: 'link', href: file.editUrl, download: true },
                    });
                }
                break;
            };
            case 'editinofficeonline': {
                if(!file.isFolder) {
                    actions.push({
                        content: i18n.t('pryaniky.files.item.editInOfficeOnline'),
                        action: 'editInOffice',
                        buttonProps: { type: 'link', href: el.data, target: '_blank' },
                    });
                }
                break;
            }
        }
    });
    if (!file.isFolder && file.version > 0) {
        actions.push({
            content: i18n.t('pryaniky.file.history'),//<OptionContent icon="eye" text={i18n.t('pryaniky.files.view')} />,
            action: 'history',
            buttonProps: { onClick: () => AttachementHistoryDialog({ fileId: file.id }).then().catch() },
        });
    }

    if (file.isFolder)
        return <FolderItem
            actions={actions}
            activeMoveng={activeMoveng}
            date={file.date}
            fid={file.id}
            isBack={file.isBack}
            move={move}
            name={file.name}
            onSelect={onSelect}
            selected={selected}
            onClick={onClick}
            onRemane={onRemane}
            showControls={showControls}
        />

    return <FileItem
        actions={actions}
        date={file.date}
        displayUserName={file.displayUserName}
        fid={file.id}
        name={file.name}
        onSelect={onSelect}
        previewUrl={file.previewUrl}
        selected={selected}
        urlname={file.urlname}
        showControls={showControls}
    />
}

export const Item = connect(
    mapStateToProps, mapDispatchToProps
)(withRouter(Item_))