import React from 'react';
import { i18n, Translate } from 'localization';
import { createDefaultPostNews } from '../../baseNews';
import { NEvents } from './Events.interface';
import { eventDurationsVariants } from 'utils/src/constants.prn';

const createTitle = (val: number, i18nKey: string) => {
    return (
        <>
            {val} <Translate i18nKey={i18nKey} count={val < 2 && val % 1 > 0 ? 2 : Math.floor(val)} />
        </>
    );
};

export const durationOptions = eventDurationsVariants.map((value) => {
    const e = { value, title: <></> };
    if (e.value < 60) e.title = createTitle(value, 'pryaniky.inputs.time.minute');
    else if (e.value >= 60 && e.value <= 720) {
        const value = e.value / 60;
        e.title = createTitle(value, 'pryaniky.inputs.time.hour');
    } else if (e.value > 720) e.title = <Translate i18nKey={'pryaniky.inputs.time.allDay'} />;
    return e;
});

const baseExpires = new Date().toISOString();

export const baseMeeting: NEvents.Create['uevent']['meeting'] = {
    domain: '',
    meetId: '',
    online: false,
    url: '',
};

export const baseUevent: NEvents.Create['uevent'] = {
    calendar: null,
    duration: 15,
    eventLocation: '',
    eventMaybePartCount: 0,
    eventNotGoersCount: 0,
    eventWaitListCount: 0,
    eventParticipants: [],
    eventParticipantsCount: 0,
    eventStatus: 'NotStarted',
    meeting: baseMeeting,
    startDateTime: baseExpires,
    title: '',
    disallowParticipate: false,
    enableWaitList: false,
    maxParticipantsCount: null,
};

export const baseEvents: NEvents.Create = {
    ...createDefaultPostNews(),
    newstype: 23,
    componentRenderName: 'events',
    expires: baseExpires,
    uevent: baseUevent,
};
