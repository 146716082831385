import React, { ReactText, FC, useState, useMemo, useCallback, ComponentProps, useEffect } from 'react';
import { connect } from 'react-redux';
import { i18n, Translate } from 'localization';
import './Item.scss';
import { DialogWikiEdit } from 'blocks/Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { IWikiItemProps, mapStateToProps, mapDispatchToProps, cnItem } from './Item.index';
import { ContextMenu } from '../../ContextMenu/ContextMenu';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Box } from 'muicomponents/src/Box/Box';
import {
    WikiImageCardButton,
    WikiCardAvatar,
    WikiNameButton,
    WikiNameBox,
    ActionBox,
    ActionContetBox,
    ItemContentBox,
    WikiDescriptionTypography,
    WikilItemDescription,
    WikiItemCard,
} from '../style';
import { Chip } from 'muicomponents/src/Chip/Chip';
import { ChatBubbleOutline, ChatBubble, RemoveRedEye } from 'muicomponents/src/Icons';
import { FavoriteIcon, LikeIcon, Typography } from 'muicomponents/src';
import { likeWikiRequest } from 'redux/actions/WikiList';
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { useDispatch } from 'react-redux';
import { formatDateSTD } from 'utils/src/utils.dates';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { CustomSettings, PageTypes } from 'utils/src';
import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import { getPageBaseType } from 'blocks/WikiListNew/utils/Wikilist.utils';
import {
    useWikilistItemBaseImageByType,
    useWikilistItemFileTypeIcon,
    useWikilistItemIconByType,
} from '../Items..hooks';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

export const WikiItemPresenter: FC<IWikiItemProps> = ({
    tag: TagName = 'div',
    className = '',
    data,
    changeItem,
    showControls,
    context,
    updateItemList,
    isEditable,
    parentFolderModerators,
    parentFolderViewers,
    onItemLinkClick,
    ...props
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const outLink =
        (data.url.indexOf('http') === 0 || data.url.indexOf('//') === 0) &&
        !data.url.includes(window.location.hostname);
    const [liked, setLiked] = useState(data?.newsLiked);
    const [likeCountNumber, setLikeCountNumber] = useState(data?.newsLikeCount);

    const dispatch = useDispatch();

    const changeLike = (likeCount: number, liked: boolean) => {
        dispatch(likeWikiRequest(data?.newsId, likeCount, liked));
    };

    const changeLikeCount = () => {
        setLiked(!liked);
        setLikeCountNumber(!liked ? likeCountNumber + 1 : likeCountNumber - 1);
    };

    const onOpen = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            likersModal({
                newsId: data?.newsId,
            });
        },
        [data?.newsId]
    );

    let href = outLink || data.url[0] === '/' ? data.url : '/' + data.url;

    if (!outLink && data.url.includes(window.location.hostname))
        href = href.replace(
            href.substr(0, href.indexOf(window.location.hostname) + window.location.hostname.length),
            ''
        );

    const noImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null;

    const pageBaseType = getPageBaseType(data.type);

    const nameComponent = useMemo(() => {
        return (
            <WikiNameButton
                className={cnItem(`WikiName ${data.type === 'url' ? 'CustomStyle primaryColor3-text ' : ''}`)}
                href={href}
                onClick={onItemLinkClick}
            >
                <Tooltip title={data.title} overflowOnly disableInteractive>
                    <WikiNameBox sx={{ WebkitLineClamp: data.description.length === 0 ? 2 : undefined }}>
                        {data.title}
                    </WikiNameBox>
                </Tooltip>
            </WikiNameButton>
        );
    }, [data.type, data.title, onItemLinkClick]);

    const descComponent = useMemo(() => {
        return (
            <Tooltip title={data.description} overflowOnly disableInteractive>
                <WikiDescriptionTypography
                    className={cnItem('CardArticleItem-Content-Description')}
                    variant="body2"
                    sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                >
                    <WikilItemDescription source={data.description || ''} type={'inline'} />
                </WikiDescriptionTypography>
            </Tooltip>
        );
    }, [data.description]);

    const componentLike = useMemo(() => {
        return (
            <ActionContetBox className={cnItem('CardArticleItem-Content-ActionBox-Like primaryColor3-borderColor')}>
                <LikeIcon
                    color="disabled"
                    on={liked}
                    sx={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        marginRight: !liked || likeCountNumber > 0 ? '5px' : 0,
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        changeLike(data?.newsLikeCount, liked);
                        changeLikeCount();
                    }}
                />
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.26)',
                        opacity: likeCountNumber === 0 ? '0' : '1',
                        paddingTop: '2px',
                        cursor: likeCountNumber > 0 ? 'pointer' : undefined,
                    }}
                    variant="caption"
                    onClick={onOpen}
                >
                    {likeCountNumber}
                </Typography>
            </ActionContetBox>
        );
    }, [likeCountNumber, liked]);

    const favoriteWikiPage = (data: any) => {
        utils.API.pages.favoritePage(data.id, !data.isFavorites).r.then((response) => {
            if (!response) toast.error(<Translate i18nKey={'pryaniky.toast.error.server'} />);
            else if (response.error_code === 0) {
                toast.success(
                    <Translate
                        i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`}
                    />
                );
                updateItemList(data, { ...data, isFavorites: !data.isFavorites });
            }
        });
    };

    const FileTypeIcon = useWikilistItemFileTypeIcon(data.type, data.title, data.icon);

    const pageBaseImage = useWikilistItemBaseImageByType(data.type);

    const itemIcon = useWikilistItemIconByType(data.type);

    return (
        <WikiItemCard className={cnItem({}, [className])}>
            {showControls || (isEditable && data.editable) ? (
                <div className={cnItem('Actions_Hedaer')}>
                    <ContextMenu
                        {...props}
                        data={data}
                        context={context}
                        updateItemList={updateItemList}
                        isAdmin={showControls || isEditable}
                        onEdit={() =>
                            DialogWikiEdit({
                                onConfirm: changeItem,
                                updateItemList,
                                context: context,
                                isShown: showDialog,
                                isAdmin: showControls,
                                onClose: () => setShowDialog(false),
                                parentFolderModerators: parentFolderModerators,
                                parentFolderViewers: parentFolderViewers,
                                data: data,
                                id: data.id,
                            })
                                .then(() => {})
                                .catch(() => {})
                        }
                    />
                </div>
            ) : undefined}

            {useFavoriteWikiItems && (
                <Box
                    sx={{
                        height: '36px',
                        width: '36px',
                        background: '#fff',
                        borderRadius: '50%',
                        opacity: data.isFavorites ? 1 : 0,
                    }}
                    className={cnItem(`Actions_Favorite`)}
                >
                    <Tooltip
                        title={
                            <Translate
                                i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorites' : 'toFavorites'}`}
                            />
                        }
                    >
                        <span>
                            <IconButton
                                color={'primary'}
                                sx={{
                                    padding: '5px',
                                    '&:hover': {
                                        background: '#fff',
                                    },
                                }}
                                onClick={() => favoriteWikiPage(data)}
                            >
                                <FavoriteIcon on={data.isFavorites} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            )}

            {pageBaseType !== PageTypes.file ? (
                <WikiImageCardButton // тип папка\страница\ссылка
                    className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                    href={href}
                    onClick={onItemLinkClick}
                >
                    <WikiCardAvatar src={noImg ? pageBaseImage : data.titleImageUrl} />
                    {data.isDraft && (
                        <Chip
                            label={<Translate i18nKey={`pryaniky.wikilist.item.isDraft`} />}
                            className={cnItem('IsDraft')}
                            variant="filled"
                            sx={{
                                position: 'absolute',
                                color: '#fff',
                                background: 'rgba(0, 102, 204, 0.5)',
                                bottom: '12px',
                                left: '8px',
                                padding: '3px 10px',
                                height: '24px',
                                '.MuiChip-label': {
                                    textTransform: 'initial',
                                    padding: 0,
                                },
                            }}
                        />
                    )}
                    {data.isArchived && (
                        <Chip
                            label={<Translate i18nKey={`pryaniky.wikilist.item.isArchived`} />}
                            className={cnItem('IsArchived')}
                            variant="filled"
                            sx={{
                                position: 'absolute',
                                color: '#fff',
                                background: '#757575',
                                bottom: '12px',
                                left: '8px',
                                padding: '3px 10px',
                                height: '24px',
                                '.MuiChip-label': {
                                    textTransform: 'initial',
                                    padding: 0,
                                },
                            }}
                        />
                    )}
                </WikiImageCardButton>
            ) : (
                <WikiImageCardButton // тип файл
                    className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                    href={href}
                    onClick={onItemLinkClick}
                    sx={{
                        width: '100%',
                        height: '188px',
                        backgroundImage: noImg ? `url(${FileTypeIcon})` : `url(${data.titleImageUrl})`,
                        backgroundSize: noImg ? '90px' : 'cover',
                        backgroundPosition: noImg ? 'center' : 'inherit',
                        backgroundRepeat: noImg ? 'no-repeat' : 'inherit',
                        backgroundColor: noImg ? '#f5f5f5' : '#fff',
                        '&:hover': {
                            backgroundImage: noImg
                                ? `url(${FileTypeIcon})!important`
                                : `url(${data.titleImageUrl})!important`,
                            backgroundSize: noImg ? '90px!important' : 'cover!important',
                            backgroundPosition: noImg ? 'center!important' : 'inherit!important',
                            backgroundRepeat: noImg ? 'no-repeat!important' : 'inherit!important',
                            backgroundColor: noImg ? '#f5f5f5!important' : '#fff!important',
                        },
                    }}
                >
                    {data.isArchived && (
                        <Chip
                            label={<Translate i18nKey={`pryaniky.wikilist.item.isArchived`} />}
                            className={cnItem('IsArchived')}
                            variant="filled"
                            sx={{
                                position: 'absolute',
                                color: '#fff',
                                background: '#757575',
                                bottom: '12px',
                                left: '8px',
                                padding: '3px 10px',
                                height: '24px',
                                '.MuiChip-label': {
                                    textTransform: 'initial',
                                    padding: 0,
                                },
                            }}
                        />
                    )}
                </WikiImageCardButton>
            )}

            <ItemContentBox className={cnItem('Content')}>
                <Box className={cnItem('CreationDate')} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {itemIcon}
                    <Typography variant={'body2'} color="rgba(0, 0, 0, 0.38)">
                        <Translate
                            i18nKey="pryaniky.wikilist.item.created.new"
                            values={{
                                creationDate: formatDateSTD(data.creationDate, false),
                                changeDate: formatDateSTD(data.changeDate, false),
                            }}
                        />
                    </Typography>
                </Box>

                {nameComponent}

                {descComponent}

                {pageBaseType !== PageTypes.wikilist && (
                    <ActionBox className={cnItem('CardArticleItem-Content-ActionBox')}>
                        {componentLike}

                        {pageBaseType !== 'url' && data.allowComments && (
                            <ActionContetBox
                                className={cnItem('CardArticleItem-Content-ActionBox-Chat primaryColor3-borderColor')}
                            >
                                {data?.newsCommented ? (
                                    <ChatBubble
                                        color={'disabled'}
                                        sx={{
                                            fontSize: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                ) : (
                                    <ChatBubbleOutline
                                        color={'disabled'}
                                        sx={{
                                            fontSize: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                )}
                                <Typography
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.26)',
                                        opacity: data?.newsCommentsCount === 0 ? '0' : '1',
                                        paddingTop: '2px',
                                    }}
                                    variant="caption"
                                >
                                    {data?.newsCommentsCount}
                                </Typography>
                            </ActionContetBox>
                        )}

                        <ActionContetBox
                            className={cnItem('CardArticleItem-Content-ActionBox-Chat primaryColor3-borderColor')}
                        >
                            <RemoveRedEye
                                color={'disabled'}
                                sx={{
                                    fontSize: '16px',
                                    marginRight: '5px',
                                }}
                            />
                            <Typography
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.26)',
                                    opacity: data?.newsViewedCount === 0 ? '0' : '1',
                                    paddingTop: '2px',
                                }}
                                variant="caption"
                            >
                                {data?.newsViewedCount}
                            </Typography>
                        </ActionContetBox>
                    </ActionBox>
                )}
            </ItemContentBox>
        </WikiItemCard>
    );
};
export const Item = connect(mapStateToProps, mapDispatchToProps)(WikiItemPresenter);
