import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box';
import { HelpOutline, SyncLockOutlined } from 'muicomponents/src/Icons';
import { green, red, grey } from '@material-ui/core/colors';
import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import { Typography } from 'muicomponents/src';
import { listItemButtonClasses } from 'muicomponents/src/ListItem';

export const AchiementThanksCountBox = styled(Box)({
    fontSize: 16,
    lineHeight: '24px'
}) as typeof Box;

export const AchiementOptionBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px"
}) as typeof Box;

export const AchiementNotInterested = styled(HelpOutline)({
    fontSize: 18  
});

export const AchiementLimited = styled(SyncLockOutlined)({
    color: red[500],
    fontSize: 18
});
