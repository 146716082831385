import React, { FC, useContext, useRef, useMemo, ComponentProps } from 'react';
import { cnNewsTypeCreativeTasks } from './CreativeTasks.index';
import './CreativeTasks.scss';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { TextField as TextFieldC } from 'muicomponents/src/TextField';
import {
    Box,
    styled
} from 'muicomponents/src';

import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';

import { DatePicker as DatePickerC } from 'muicomponents/src/DatePicker';
import { i18n, Translate } from 'localization';

import { GiveMoney } from 'muicomponents/src/Icons';
import { useCreativeTasksEdit } from './CreativeTasks.hooks';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import moment from 'moment';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { TooltipNews } from 'muicomponents/src/TooltipNews/TooltipNews';
import { memoizedComponent } from 'News/creator/utils.sender';
import { NumberTextField as NumberTextFieldC } from 'muicomponents/src/TextField/TextField';
import { baseCreativeTasks } from './CreativeTasks.constants';

const prepareI18nKey = (key: string) => `pryaniky.news.create.creativetasks.${key}`;

const TextField = memoizedComponent(TextFieldC);
const NumberTextField = memoizedComponent(NumberTextFieldC);
const DatePicker = memoizedComponent(DatePickerC);
const DraftInput = memoizedComponent(DraftInputC);

const StyledBox = styled(Box)({
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    // TODO replace to class variables
    '& > .MuiFormControl-root': {
        flex: 1,
        minWidth: 250
    }
});

const NewsTypeCreativeTasksPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        renderParams,
        errors,
        clearEditor,
        changeNews
    } = useCreativeTasksEdit(creatorId);

    const {
        header,
        text: currentNewsText,
        draftRawState,
        expires,
        thanksCount,
        // creativeTask = baseCreativeTasks.creativeTask
    } = data;

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;
    
    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    const draftCommonProps = useDraftEditorStyle();

    const headerProps = useMemo<ComponentProps<typeof TextField>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('header')} />,
        onChange: (e) => {
            changeNews({ header: e.target.value });
        },
    }), []);

    const thanksCountProps = useMemo<ComponentProps<typeof NumberTextField>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('thanksCount')} />,
        onChange: (event) => {
            const thanksCount = event.target.value;
            changeNews({
                thanksCount,
                creativeTask: {
                    fund: thanksCount
                }
            });
        },
        InputProps: {
            startAdornment: <GiveMoney />
        }
    }), []);

    const expiresProps = useMemo<Pick<ComponentProps<typeof DatePicker>, 'TextFieldProps' | 'onChange'>>(() => ({
        TextFieldProps: {
            label: <Translate i18nKey={prepareI18nKey('expires')} />,
            error: Boolean(errors.expires),
            helperText: Boolean(errors.expires) && PrepareErrorText(errors.expires)
        },
        onChange: (iso, date) => {
            // const currentDate = moment();
            // const isoWithCurrentMinuteStart = iso ? moment(iso).hours(currentDate.hours()).minutes(currentDate.minutes()).toISOString() : null;
            const isoWithCurrentMinuteStart = iso ? moment(iso).toISOString() : null;
            changeNews({
                expires: isoWithCurrentMinuteStart,
                creativeTask: {
                    endDateTime: isoWithCurrentMinuteStart || ''
                }
            });
        }
    }), [errors.expires]);

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    return (
        <>
         {renderParams && renderParams.showTooltip &&
                <TooltipNews newsParams={renderParams} title={renderParams.tooltipHeader} />
            }
            <TextField
                test-id={'timeline-create-creativetasks-header'}
                {...headerProps}
                fullWidth
                value={header}
                error={Boolean(errors.header)}
                helperText={Boolean(errors.header) && PrepareErrorText(errors.header)}
            />
            <StyledBox>
                <NumberTextField
                    test-id={'timeline-create-creativetasks-thanksCount'}
                    {...thanksCountProps}
                    value={thanksCount || ''}
                    hideApperance
                    error={Boolean(errors.thanksCount)}
                    helperText={Boolean(errors.thanksCount) && PrepareErrorText(errors.thanksCount)}
                />
                <DatePicker
                    test-id={'timeline-create-creativetasks-expires'}
                    {...expiresProps}
                    value={expires || null}
                    disablePast
                    type={'datetime'}
                />
            </StyledBox>
            <DraftInput
                test-id={'timeline-create-creativetasks-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                disableToolbar={!renderParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={Boolean(errors.text)}
                helperText={Boolean(errors.text) && PrepareErrorText(errors.text as any)}
                clearValue={clearEditor}
            />
        </>
    );
};

export const NewsTypeCreativeTasks = NewsTypeCreativeTasksPresenter;

export const initNewsCreativeTasksCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeCreativeTasks />, 'creativetasks')