import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { ISearchMuiProps, mapDispatchToProps, mapStateToProps, TListContext } from './SearchMui.index';
import { List as UsersSearchList } from 'blocks/ListMui/_type/users/List_users';
import { List as GroupsSearchList } from 'blocks/ListMui/_type/groups/List_groups';
import { List as WikiSearchList } from 'blocks/ListMui/_type/wiki/List_wiki';
import { List as TagsSearchList } from 'blocks/ListMui/_type/tags/List_tags';
import { List as FilesSearchList } from 'blocks/ListMui/_type/files/List_files';
import { List as CoursesSearchList } from 'blocks/ListMui/_type/courses/List_courses';
import TimelineSearch from 'News/containers/TimelineSearch/component';
import { i18n, Translate } from 'localization';
import { SearchContentBox, SearchTabsBox, SearchWrapper, MarginForBox } from './SearchMui.styled';
import { SearchList } from './SearchList/SearchList';
import { SearchTabs } from './SearchTabs/SearchTabs';
import { useSearchParams, useQueuedState } from 'utils/src/hooks'
import { getSearchI18nKeys, minSearchSymblosCount } from './SearchMui.utils';
import { TValue } from 'utils/src';
import { ListPlaceholder } from 'muicomponents/src/ListPlaceholder/ListPlaceholder';
import { NothingFoundText } from 'blocks/ListMui/_type/NotingFoundText';
import { useParams } from 'react-router';
import { TSearchValue } from 'redux/search/interfaces';
import { prepareFilterTypeByExtendedTypes } from 'muicomponents/src/SuggesterQuick/SuggesterQuick.utils';
import { getAdditionalFilterByName } from 'blocks/ListMui';

const SearchMuiPresenter: FC<ISearchMuiProps> = ({
  filters,
  context,
  setAdditionalFilter,
  clearAdditionalFilter
}, props) => {

  const newsFilter = useSelector(getAdditionalFilterByName('news'));

  let { tab: activeTab = 'all' } = useParams<{ tab: TSearchValue | undefined }>();

  const prepairedFilters = useMemo(() => {
    if(activeTab === 'all') {
      return filters;
    }
    return filters.filter(el => el.filterName === activeTab);
  }, [activeTab, filters]);

  const {
    type: renderTab
  } = useMemo(() => {
    const currentFilter = filters.find(el => el.filterName === activeTab);
    return prepareFilterTypeByExtendedTypes(activeTab, currentFilter?.pageType);
  }, [activeTab, filters]);

  const [{ search: searchQuery = '' }, setParams] = useSearchParams() as any as [{ search: string | undefined }, (object: Record<string, any>) => void];
  const [search, setSearch] = useState(searchQuery?.toString() || '');
  // const [search, searchQuery, setSearch] = useDebouncedQueryState('search', 0, '');
  const [listHidden, setListHiddenState, listHiddenRef] = useQueuedState<{ [key: string]: boolean }>({
  });

  const [hasMore, setHasMoreState, hasMoreRef] = useQueuedState<{ [key: string]: { count: number, hasManyResult: boolean } }>({
    course: { count: 0, hasManyResult: false },
    files: { count: 0, hasManyResult: false },
    groups: { count: 0, hasManyResult: false },
    news: { count: 0, hasManyResult: false },
    pages: { count: 0, hasManyResult: false },
    tags: { count: 0, hasManyResult: false },
    users: { count: 0, hasManyResult: false },
  });

  const allListsAreHidded = useMemo(() => {
    return !Object.values(listHidden).includes(false);
  }, [listHidden]);

  const setListHidden = useCallback((listName: keyof typeof listHidden, value: TValue<typeof listHidden>) => {
    if (listHiddenRef.current[listName] === value) return;
    setListHiddenState({
      [listName]: value
    });
  }, []);

  const setHasMore = useCallback((listName: keyof typeof hasMore, value: TValue<typeof hasMore>) => {
    if (hasMoreRef.current[listName] === value) return;
    setHasMoreState({
      [listName]: value
    });
  }, []);

  React.useEffect(() => {
    setSearch(searchQuery?.toString() || '');
  }, [searchQuery]);

  const listContext: TListContext = useMemo(() => {
    return {
      search: searchQuery?.toString() || "",
      network: context.network,
      viewType: 1,
      isSearchPageQuery: true,
      count: activeTab === "all" ? 3 : 10,
    }
  }, [activeTab, renderTab, searchQuery]);

  const HeaderComponent = useMemo(() => {

    return <SearchTabs
      searchQuery={search}
      changeSearchQuery={(value, reason) => {
        if (reason === 'enter') {
          setParams({ search: value });
        } else {
          setSearch(value);
        }
      }}
      activeTab={activeTab}
      setAdditionalFilter={setAdditionalFilter}
      clearAdditionalFilter={clearAdditionalFilter}
    />
  }, [search, activeTab]);

  const hideContentBox = useMemo(() => {
    return searchQuery.length < minSearchSymblosCount;
  }, [searchQuery]);

  return (<MarginForBox >

    {HeaderComponent}

    <SearchWrapper >

      {
        !hideContentBox &&
        <SearchContentBox>
          {
            activeTab === "all" && allListsAreHidded &&
            <ListPlaceholder
              isFinished
              isLoading={false}
              itemsCount={0}
              textEmpty={<NothingFoundText />}
            />
          }
          {
            prepairedFilters.map(filter => {
              const renderType = prepareFilterTypeByExtendedTypes(filter.filterName, filter?.pageType);
              const hideSx = listHidden[filter.filterName] ? { display: activeTab === "all" ? 'none' : "block" } : undefined;
              let viewList = (children: ReactNode) => {
                return <SearchList
                  key={filter.filterName}
                  searchQuery={searchQuery}
                  title={<Translate i18nKey={getSearchI18nKeys(`tab.${renderType.type === 'course' ? `${filter?.filterName}s` : filter?.filterName}`)} defaults={filter?.displayName} />}
                  showAllButton={activeTab === "all"}
                  value={filter.filterName}
                  sx={hideSx}
                  hasMore={activeTab === "all" ? hasMore[filter.filterName] : undefined}
                  children={children}
                />
              };
              let view: ReactNode = null;
              switch (renderType.type) {
                case 'news':
                  view = <TimelineSearch
                    isSearchPage
                    params={{
                      searchText: searchQuery,
                      author: newsFilter?.author?.id,
                      types: newsFilter?.types?.map(((el: any) => el.typeId)) || [],
                      startDate: newsFilter?.startDate,
                      endDate: newsFilter?.endDate,
                      ...listContext
                    }}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isFailed && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    context={{ postOff: true, searchText: listContext.search, ...listContext }}
                    noNewsCreate
                    {...props}
                  />
                  break;
                case 'users':
                  view = <UsersSearchList
                    context={listContext}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isError && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    {...props}
                  />
                  break;
                case 'groups':
                  view = <GroupsSearchList
                    context={listContext}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isError && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    {...props}
                  />
                  break;
                case 'pages':
                  const additionalContext: Partial<TListContext> = {};
                  if(filter.pageType) {
                    additionalContext.contentType = filter.pageType.type;
                  }
                  // console.log(listContext,additionalContext )
                  view = <WikiSearchList
                    context={{
                      ...listContext,
                      ...additionalContext
                    }}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isError && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    {...props}
                  />
                  break;
                case 'files':
                  view = <FilesSearchList
                    context={listContext}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isError && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    {...props}
                  />
                  break;
                case 'tags':
                  view = <TagsSearchList
                    context={listContext}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isError && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    {...props}
                  />
                  break;
                case 'course':
                  view = <CoursesSearchList
                    context={listContext}
                    afterStateChangedCallback={(state) => {
                      setListHidden(filter.filterName, !state.values?.length && !state.isError && !state.isLoading);
                    }}
                    afterGetDataCallback={(state) => {
                      setHasMore(filter.filterName, { count: state?.quantity_search_result?.count || 0, hasManyResult: state?.quantity_search_result?.hasManyResult || false })
                    }}
                    {...props}
                  />
                  break;
              }

              return viewList(view)
            })
          }

        </SearchContentBox>
      }
    </SearchWrapper>
  </MarginForBox>
  )
}

export const SearchMui = connect(mapStateToProps, mapDispatchToProps)(SearchMuiPresenter)