/**
 * именование *.test. принято для файлов юнит-тестов. 
 * для иных файлов исходных котов такое именование использовать нельзя
 *  */

import React, { Dispatch } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { IStateType as IState } from 'redux/store';
import { i18n_set_namespace, i18n_set_language } from 'utils/src/CommonRedux/i18n/actions';
import { i18n, Translate } from 'localization';
import { validate } from 'utils/src/validate';
import { TextError } from 'uielements/src/TextError/TextError';
    
export interface Ii18nTextProps extends Ii18nTextPropsOwnProps, Imapi18nTextStateToProps, Imapi18nTextActionsToProps {}

export interface Ii18nTextPropsOwnProps {

}

export const mapi18nTextStateToProps = (state: IState, props: Ii18nTextPropsOwnProps) => ({
  namespace: state.i18n.namespace
})

export type Imapi18nTextStateToProps = ReturnType<typeof mapi18nTextStateToProps>

export const mapi18nTextActionsToProps = (dispatch: any) => bindActionCreators({
  i18n_set_namespace
}, dispatch);

export type Imapi18nTextActionsToProps = ReturnType<typeof mapi18nTextActionsToProps>

const i18nTextPresenter: React.FC<Ii18nTextProps> = ({ namespace, i18n_set_namespace }) => {

  const changeNs = () => {
    i18n_set_namespace('rftest');
  }

  const [state, setstate] = React.useState<any>({name: '', number: 0, errors: {}});

  const nameChange = (e: any) => setstate({ name: e.currentTarget.value, errors: validate({ name: e.currentTarget.value }, { name: { min: { value: 3 }, max: { value: 5 } } }) })

  const nameChangev2 = (e: any) => setstate({ name: e.currentTarget.value, errors: validate({ name: e.currentTarget.value }, { name: { min: (v: string) => v.length < 5 ? 'херня, делай заного' : undefined } }) })

  return (
    <div>
      <div style={{ marginBottom: '25px' }}>
        <input onChange={nameChange} value={state.name} />
        {state.errors.name && <TextError data={state.errors.name} />}
      </div>
      <div style={{ marginBottom: '25px' }}>
        <input type={'number'} onChange={(e: any) => setstate({ number: Number(e.currentTarget.value), errors: validate({ number: Number(e.currentTarget.value) }) })} value={state.number} />
        {state.errors.number && <TextError data={state.errors.number} />}
      </div>
    </div>
  )
}

export const i18nText = connect(
  mapi18nTextStateToProps,
  mapi18nTextActionsToProps
)(i18nTextPresenter)