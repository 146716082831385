import React, { FC } from 'react';
import { i18n, Translate } from 'localization';
import { NValidate } from 'utils/src/validate_v2';

export const PrepareErrorText = (value?: string | NValidate.Error ) => {

    if(!value) return null;

    if(typeof value === 'string') {
        return (
            <>
            {
                value
            }
            </>
        );
    };

    if(Array.isArray(value)) {
        return (
            <>
            {
                value.map(el => {
                    if(typeof el === 'string') return el;
                    return <Translate i18nKey={el.errorMessageKey} variablesI18nKeys={el.variableKey ? { variable: el.variableKey } : undefined} values={el.variables} />;
                }).join(', ')
            }
            </>
        );
    };

    return (
        <Translate i18nKey={value.errorMessageKey} variablesI18nKeys={value.variableKey ? { variable: value.variableKey } : undefined} values={value.variables} />
    );
};