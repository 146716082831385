import * as React from 'react';
import * as utils from 'utils/src/utils';
import { Input } from 'uielements/src/Input/Input';
// import { Editor } from 'blocks/Editor/Editor';
import { withBemMod } from '@bem-react/core';
import { IBodyProps } from '../NewsEdit-Body.index';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeNoticesProps } from './NewsEdit-Body_type_notices.index';
import './NewsEdit-Body_type_notices.scss';
import { EditorTypeEdit } from '../../../NewsTypes/Notices/Editor/containers/edit/edit'

export const withBodyTypeNotices = withBemMod<IBodyTypeNoticesProps, IBodyProps>(
  cnBody(),
  { type: 'notices' },
  (Presenter) => (
    (props) => {
      const newPost: any = utils.cloneObject(props.post);
      /*const onChangeTitle = (e: any) => {
        newPost.header = e.target.value;
        props.onChangePost(newPost);
      }*/
      return (
        <Presenter {...props}>
          {({ text, onChangeText }) => <EditorTypeEdit data={newPost} onChange={props.onChangePost} />
            /*<React.Fragment>
              <Input
                onChange={onChangeTitle}
                className={cnBody('Input-Title')} 
                value={newPost.header}
                icon='bell'
                placeholder={i18n.t('placeholders.input.name')} />
              <Editor
                onChange={onChangeText}
                value={text}
                className={cnBody('Editor')} 
                placeholder={i18n.t('placeholders.textarea.notices')} />
            </React.Fragment>*/
          }
        </Presenter>
      )
    }
  )
);