import React, { FC, memo } from 'react';
import { IIdeasPropsType, IIdeasStateType, mapDispatchToProps, mapStateToProps, TStateProps } from '../Ideas.index'
import { cnSliderIdeasView } from './index'
import './SliderViewIdeas.scss'
import { withNewsContext } from 'News/contexts/news'
import { connect } from 'react-redux';
import MiniHeader from 'News/containers/MiniHeader'
import { LinkFilter } from 'News/utils/utils'
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import IconMUI from '@material-ui/core/Icon';
import IdeaIcon from './IdeaIcon.svg';
import { i18n, Translate } from 'localization';
import MDRender from 'uielements/src/CommonmarkRender'
import { Button } from 'uielements/src';
import { formatDateNumbers } from 'utils/src/utils.dates';

const SliderViewIdeasPresenter: FC<IIdeasPropsType & TStateProps> = ({
    news,
    newsId
}) => {
    const {
        thanksCount,
        header,
        text
    } = news;
    const { currentStatus } = news.idea;

    async function open() {
        try {
            const result = openNews({ newsId })
        } catch (e) { }
    }
    const lheader = header
    return <div>
        <LinkFilter action={open}>
            <MiniHeader authorOnly onClick={open} className={cnSliderIdeasView('Author')} disableTextCut />
            <div className={cnSliderIdeasView('Header')}>
                <div className={cnSliderIdeasView('HeaderBox')}>
                    {/* <IconMUI> <img src={IdeaIcon} alt="" /> </IconMUI> */}
                    {/* <div className={cnSliderIdeasView('HeaderBox-Img')}>  </div> */}
                    <div className={cnSliderIdeasView('HeaderBox-TextBox')}>
                        <span className={cnSliderIdeasView('HeaderBox-TextBox-Text')}><Translate i18nKey={"pryaniky.news.view.slider.ideas.fond"} /></span>
                        <div className={cnSliderIdeasView('HeaderBox-TextBox-Currency')}>
                            <span>{thanksCount}</span>
                        </div>
                        <span className={cnSliderIdeasView('HeaderBox-TextBox-Text')}> пряников </span>
                    </div>


                </div>
            </div>
            <div className={cnSliderIdeasView('Content')}>
                {lheader.trim() !== '' && <div className={cnSliderIdeasView('Content-Title')}>
                    <MDRender
                        // disableEmojiOne
                        className={cnSliderIdeasView('Content-TitleRender')}
                        type={'inline'}
                        disableShowMoreBtn
                        textCut={false}
                        // maxCharCount={200}
                        source={lheader}
                    />
                </div>}
                <div className={cnSliderIdeasView('Content-NewsText')}>
                    <MDRender
                        disableEmojiOne
                        className={cnSliderIdeasView('Content-Render')}
                        type={'textOnly'}
                        disableShowMoreBtn
                        textCut={true}
                        maxCharCount={350}
                        source={text}
                    />
                </div>
                {currentStatus && <div className={cnSliderIdeasView('Content-Status')}>
                    <div><span ><Translate i18nKey={"pryaniky.timeline.news.status"} /></span> {currentStatus.name}</div>
                    <div>
                        <span>
                            <Translate i18nKey={"pryaniky.timeline.news.changeDate"} />
                        </span>
                        {formatDateNumbers(currentStatus.statusChangeDate, false, false)}
                    </div>
                </div>}
                <div className={cnSliderIdeasView('Content-Footer')}>
                    <Button
                        theme={'unstyled'}
                        className={cnSliderIdeasView('OpenBtn', ['primaryColor3-text'])}
                        onClick={open}><Translate i18nKey={"pryaniky.timeline.news.readfull"} /></Button>
                </div>
            </div>

        </LinkFilter>

    </div>
}
export const SliderViewIdeas = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(SliderViewIdeasPresenter)));