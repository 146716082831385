import * as React from 'react';
import { withBemMod } from '@bem-react/core';
import { i18n, Translate } from 'localization';
import * as utils from 'utils/src/utils'

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeThanksProps, IBodyTypeThanksState, mapStateToProps } from './Modal-Body_type_thanks.index';
import { Input } from 'uielements/src/Input/Input';
import { CheckboxInput } from 'uielements/src';

import './Modal-Body_type_thanks.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'redux/connector';
import { toast } from 'react-toastify';

class NewBodyPresenter extends React.Component<IBodyTypeThanksProps, IBodyTypeThanksState> {
  public el?: HTMLElement | null;
  public t = i18n.t;
  protected mounted = false;

  constructor(props: IBodyTypeThanksProps) {
    super(props);
    this.state = {
      count: this.props.parent.state.data.count || 0,
    };
    this.props.parent.state.data.count = this.state.count;
  }

  public componentDidMount() {
    this.mounted = true;
    if (!this.props.thanksReasons) {
      if (!this.mounted) return;
      utils.API.system.reasons().r.then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          this.props.changeVm('thanksReasons', d.data);
        } else {
          toast.error('something wrong');
        }
      });
      this.props.parent.setState({
        usersMention: true,
        tagsMention: true,
      });
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const customClassNames = '';
    const { className = '', tag: TagName = 'div' } = this.props;
    const numOfUsers = this.props.parent.state.data.selectedUserCount || 0;
    return (
      <TagName ref={el => (this.el = el)} className={cnBody({}, [className, customClassNames])}>
        <div className={cnBody('Part')}>
          <Button
            data-direction="down"
            onClick={this.countChanger}
            className={cnBody('Button', { count: 'down' })}
            main
            children={<Icon icon={'chevron-down'} />}
            disabled={this.state.count === 0}
          />
          <Input className={cnBody('Input')} onChange={this.onInputChange} type="number" value={this.state.count} />
          <Button
            data-direction="up"
            onClick={this.countChanger}
            className={cnBody('Button', { count: 'up' })}
            main
            children={<Icon icon={'chevron-up'} />}
            disabled={(this.state.count + 1) * numOfUsers > this.props.thanksForAll}
          />
        </div>
        <div className={cnBody('Part')}>
          <span className={cnBody('CurrentCount')}>
            {this.t('pryaniky.modals.thanks.give')}:
            <span children={this.state.count * numOfUsers} />
          </span>
          <span className={cnBody('CurrentCount')}>
            {this.t('pryaniky.modal.thanks.left')}:
            <span children={this.props.thanksForAll - this.state.count * numOfUsers} />
          </span>
        </div>
        <div className={cnBody('Part')}>
          {this.props.thanksReasons && this.props.thanksReasons.map((cat, i) => {
            return (
              <div key={i}>
                <div className={cnBody('Reasons-CategoryName')} children={cat.name + ':'} />
                {cat.reasons.map((reason, idx) => {
                  return (
                    <CheckboxInput
                      key={reason.id}
                      onChange={this.reasonSelect}
                      checked={reason.selected}
                      text={reason.name}
                      value={i * 10 + idx}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </TagName>
    );
  }

  private onInputChange = (ev: React.ChangeEvent<any>) => {
    const count = Number(ev.target.value);
    if (count * (this.props.parent.state.data.selectedUserCount || 0) < this.props.thanksForAll) {
      this.setState({ count }, () => {
        this.props.parent.state.data.count = this.state.count;
        this.props.parent.validateData();
      });
    }
  };

  private countChanger = (e: any) => {
    let count = this.state.count;
    if (
      e.currentTarget.dataset.direction === 'up' &&
      (count + 1) * (this.props.parent.state.data.selectedUserCount || 0) <= this.props.thanksForAll
    ) {
      count++;
    } else if (
      e.currentTarget.dataset.direction === 'down' &&
      (count - 1) * (this.props.parent.state.data.selectedUserCount || 0) >= 0
    )
      {count--};
    this.setState({ count }, () => {
      this.props.parent.state.data.count = count;
      this.props.parent.validateData();
    });
  };

  private reasonSelect = (e: any) => {
    const idx: number = +e.currentTarget.value;
    const companyReasons = [...this.props.thanksReasons];
    companyReasons[(idx / 10) | 0].reasons[idx % 10].selected =
      !companyReasons[(idx / 10) | 0].reasons[idx % 10].selected;
    this.props.changeVm('thanksReasons', companyReasons);
    this.props.parent.state.data.companyReasons = companyReasons;
    this.props.parent.validateData();
  };
}

const NewBody = connect(mapStateToProps, mapDispatchToProps({}))(NewBodyPresenter);

export const withBodyTypeThanks = withBemMod<any>(
  cnBody(),
  { type: 'thanks' }, 
  (Presenter) => (
    (props: IBodyTypeThanksProps) => (
      <NewBody {...props}/>
    )
  )
);