import React, { FC, useState, useEffect } from 'react';
import {
    // Dialog,
    // DialogTitle,
    // DialogContent,
    // DialogActions,
    Select,
    Button,
    Icon,
    CheckboxInput,
    Textarea, Spinner
} from 'uielements/src';
import { i18n, Translate } from 'localization';
import { toast } from 'react-toastify';
import './CreateCourse.scss'
import * as utils from 'utils/src/utils'
import { ICreateSessionProps, cnCreateCourse, mapDispatchToProps, mapStateToProps } from './CreateCourse.index'
import { connect } from 'react-redux';
import uuid from 'uuid/v1';
import Editor from '../../LMSTeacherOffice/CourseEditor/Course'
import { courseModelCreator } from 'LMSModule/utils/dataCreators/courseCreator'
import { ImageLoader } from 'blocks/ImageLoader/ImageLoader';
import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import { tU } from 'LMSModule/utils/i18n.adaptor'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { videoUrls } from 'utils/src/videoUrls';
import { VideoButton } from 'uielements/src/Dialog/VideoDilog/VideoButton';

export const CreateCoursePresenter: FC<ICreateSessionProps & any> = ({
    // isShown,
    // onClose,
    createCourse,
    fetchStatus,
    author,
    handleAccept,
    handleClose,
    user
}) => {
    const [course, setCourse] = useState<any>({ ...courseModelCreator(uuid()), authors: [user.baseData] })
    
    const onLoadEnd = (img: any) => setCourse({ ...course, largeImgUrl: img.previewUrl, largeImgId: img.id, imgId: img.id })
    return (
        <React.Fragment>
            {/* <div isShown={isShown} onClose={onClose} className={cnCreateCourse({}, [])}> */}
            <DialogTitle onClose={handleClose}>
                {/* <h4 className="my-1"> */}
                {tU('dialogs.create_course.header.1')}
                {/* </h4> */}
                <VideoButton className={cnCreateCourse("Video")} url={videoUrls.lms.createCourse} type={'vimeo'} title={tU('dialogs.create_course.header.1.help')} />
            </DialogTitle>
            <DialogContent className={cnCreateCourse('')}>
                {fetchStatus === 'PENDING' && <Spinner />}
                <div className={cnCreateCourse('WrapEditor')}>
                    <Editor isCreate course={course} updateCourse={setCourse} />
                </div>
                <ImageLoader className={''} cropper onLoaded={onLoadEnd} title={tU('dialogs.create_course.image.1')} description={i18n.t('pryaniky.text.loadIconWithSize')} />
            </DialogContent>
            <DialogActions
                acceptText={tU('dialogs.create_course.create.1')}
                closeText={i18n.t('close')}
                // isLoading={loading}
                disableAccept={course.name === ''}
                onAccept={() => createCourse(course)}
                onClose={handleClose}
            />
            {/* <DialogActions>
                    <Button main disabled={course.name === ''} onClick={() => createCourse(course)}>{tU('dialogs.create_course.create.1')}</Button>
                <Button onClick={onClose}>
                    {i18n.t('close')}
                </Button>
            </DialogActions> */}
            {/* </div> */}
        </React.Fragment>
    );
};

export const CreateCourseDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCoursePresenter);

export const DialogCreateCourse = createModal(CreateCourseDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: 'transparent',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});