import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from 'muicomponents/src/TextField'
import { i18n, Translate } from 'localization';
import * as utils from 'utils/src/utils';
import { useDebounce } from 'utils/src/hooks';
import { toast } from 'react-toastify';
import "./WikiCreateNew.scss";
import { UsersSuggester, TagsSuggester, PageCollectionSuggester } from 'muicomponents/src/Suggester';
import { pages } from "../../../../pageStructures/pages";
import { wikilist } from "../../../../pageStructures/wikilist";
import uuid from 'uuid/v1';
import { widgets, generateColumn } from 'i.widgets';
import { history } from 'redux/browserHistory';
import { connect } from "react-redux";
import {
    IWikiCreateNewOwnProps,
    IWikiCreateNewProps,
    cnWikiCreateNewDialog,
    IWikiCreateNewDispatchProps,
    IWikiCreateNewStateProps,
    mapWikiCreateNewStateToProps,
    mapWikiCreateNewDispatchToProps,
} from './WikiCreateNew.index'
import { IStateType as IState } from 'redux/store';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { TextError } from 'uielements/src/TextError/TextError';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { useFileUploader } from 'utils/src/hooks'
import { styled } from 'muicomponents/src/mui/system';
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    FilesUploader,
} from 'muicomponents/src';
import { PreviewSelector } from 'muicomponents/src/PreviewSelector/PreviewSelector'
import { ImageCropperDialog } from 'blocks/ImageLoaderWithMui/ImageCropper/ImageCropper'
import { getPageBaseType, getWikiUrl } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { PageTypes } from 'utils/src';
import { GridView, HelpIcon, ViewList } from 'muicomponents/src/Icons';
import { ButtonGroup } from 'muicomponents/src/ButtonGroup';
import { NumberTextField } from 'muicomponents/src/TextField/TextField';
import { useWikiItemViewersTitle } from '../WikiDialogs.utils';

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'
});

/**
 * @todorefactoring this block
 * @param param0 
 * @returns 
 */
export const WikiCreateNewDialogPresenter: React.FC<IWikiCreateNewProps> = ({
    data: ddata,
    parentFolderViewers,
    parentFolderModerators,
    allowComments,
    showInMainManu,
    type,
    handleAccept,
    handleClose,
    changeVm,
    isAdmin,
    onChange = () => { },
    allowFileTypes,
    ...props
}) => {
    const [data, setData] = React.useState<{ [s: string]: any }>({
        ...ddata,
        id: uuid(),
        type: type,
        allowComments: true,
        defaultViewType: 0,
        moderators: parentFolderModerators ? [...parentFolderModerators] : [],
        viewers: parentFolderViewers ? [...parentFolderViewers] : [],
        isInheritingRootRights: ddata.collectionAlias !== "dash" ? true : false
    })
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorLink, setErrorLink] = React.useState(false);
    const [imgChanged, setImgChanged] = React.useState(false);

    const {
        isLoading,
        files,
        onFileChange,
        removeFile,
        dndRef,
        dragEntered,
        dndTriggerRef,
        uploadError,
        inputAccept,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments,
        uploadOnAdd: true,
        maxFilesCount: 1,
        allowTypes: allowFileTypes
    })
    useEffect(() => {
        if (files.length >= 1 && files[0].isUploaded && files[0].response?.data?.[0]) {
            setData({ ...data, file: files[0].response?.data[0] })
            if (data.title === '' || !data.title) {
                setData({ ...data, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        } else {
            setData({ ...data, file: null })
        }
    }, [files])

    const pageType = getPageBaseType(data.type);

    const typePage = pageType === PageTypes.page;
    const typeWikilist = pageType === PageTypes.wikilist;
    const typeUrl = pageType === PageTypes.url;
    const typeFile = pageType === PageTypes.file;

    const sendDataForCreatNewPage = () => {
        setLoading(true)

        let link = getWikiUrl(data as any);

        const structure = pages(typeFile ? widgets.types.file : widgets.types.wiki);
        const wikilistStracture = wikilist()
        const store: { [s: string]: any } = typePage ? { edit: true, editType: 'content' } : { edit: false, editType: '' };
        if (data.selected === 'landing') {
            structure.data = [generateColumn({})];
            store.openedModal = 'layoutSelector';
            store.editType = undefined;
        }

        const dataToCrete = {
            // layout: data.type === "wikilist" ? wikilistStracture : data.commentType === "BottomPanel" ? pageWithComments : structure,
            layout: typeWikilist ? wikilistStracture : structure,
            enableTwoFactorAuth: false,
            context: data.context,
            registerNewWidgets: false,
            description: data.description,
            isTemplate: false,
            id: uuid(),
            type: data.type || PageTypes.page,
            url: link,
            title: data.title,
            moderators: (data.isInheritingRootRights && parentFolderModerators) ? [] : data.moderators,
            tags: data.tags,
            collectionAlias: data.collectionAlias,
            collections: data.collections,
            titleImageId: data.titleImageId,
            isInheritingRootRights: data.collections?.find((el: any) => el.alias === "dash") ? false : data.isInheritingRootRights,
            hasCustomTitleImage: data.titleImageId === null || data.titleImageId === undefined || data.titleImageId === "" ? false : true,
            titleImageUrl: data.titleImageUrl,
            showInMainMenu: data.showInMainMenu,
            allowComments: data.allowComments,
            defaultViewType: data.defaultViewType,
            // positionInCollection: Number(ddata.positionInCollection),
            positionInCollection: Number(data.positionInCollection) ? Number(data.positionInCollection) : 0,
            icon: data.icon,
            viewers: (data.isInheritingRootRights && parentFolderViewers) ? [] : data.viewers,
            file: data.file,
            pageType: data.pageType,
            isDraft: typePage,
            showCategoryAsSelector: data.showCategoryAsSelector
            // commentType: data.commentType


        };
        utils.API.pages.setNew(dataToCrete)
            .r
            .then((response) => {
                if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                else if (response.error_code) {
                    toast.error(response.error_text);
                    setLoading(false)
                }
                else if (response.error_code === 0) {
                    if (typeFile) {
                        history.push(`/pages/files/${data.file.id}`)
                    } else if (typeUrl) {
                        handleAccept(dataToCrete)
                    } else {
                        history.push(link);
                    }
                    !typePage && handleClose();
                    changeVm('', store);
                }

            })
    };

    const onChangeShowInMainMenu = (e: any) => {
        setData({ ...data, showInMainMenu: !data.showInMainMenu })
    }

    const onChangeAllowComments = (e: any) => {
        setData({ ...data, allowComments: !data.allowComments })
    }

    const onChangeShowCategoryAsSelector = (e: any) => {
        setData({ ...data, showCategoryAsSelector: !data.showCategoryAsSelector })
    }

    const onChangeHideFooter = () => {
        setData({ ...data, isHideFooter: !data.isHideFooter })
    };

    const onChangeIsInheritingRootRights = (e: any) => {
        setData({
            ...data,
            isInheritingRootRights: !data.isInheritingRootRights,
            moderators: !data.isInheritingRootRights && parentFolderModerators ? [...parentFolderModerators] : data.moderators,
            viewers: !data.isInheritingRootRights && parentFolderViewers ? [...parentFolderViewers] : data.moderators
        })
    }

    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        !typeUrl && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        setData({ ...data, url: e.currentTarget.value })
        if (e.currentTarget.value === "" || e.currentTarget.value === undefined) {
            setError(true)
        } else {
            setError(false)
        }
    }

    // const hasLink =useMemo(()=>{},[])
    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {
                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setErrorLink(true)
                    toast.error(i18n.t('pryaniky.wikilist.error.url.isExist'))
                } else if (response.data === false) {
                    setErrorLink(false)
                }
            })
    }

    const Addorment = (typePage || typeFile) ? <p className={cnWikiCreateNewDialog("child")}>/pages/</p> : (typePage || typeWikilist) ? <p className={cnWikiCreateNewDialog("child")}>/wikilist/</p> : (ddata.type === "url" || typeUrl) && undefined

    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);

    let disableAccept = data.url === '' || data.url === undefined
    disableAccept = typeFile ? !Boolean(data.file) : disableAccept
    disableAccept = isLoading ? true : disableAccept
    disableAccept = loading ? true : disableAccept

    const dialogTitle = useMemo(() => {
        return <Translate
            i18nKey={'pryaniky.wikilist.create.type'}
            values={{
                type: Translate.t({
                    i18nKey: `pryaniky.wikilist.item.${data.type}.accusative`
                }).toLowerCase()
            }}
        />
    }, [data.type]);
    
    const viewersTitle = useWikiItemViewersTitle(data.type);

    return (
        <>
            <DialogTitle onClose={handleClose}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent className={cnWikiCreateNewDialog("")}>
                <Box className={cnWikiCreateNewDialog("Content")}>
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <TextField
                            name="title"
                            fullWidth
                            value={data.title}
                            className={cnWikiCreateNewDialog("Input")}
                            label={
                                <Translate
                                    i18nKey='pryaniky.wikilist.item.param.title'
                                    values={{
                                        type: Translate.t({
                                            i18nKey: `pryaniky.wikilist.item.${data.type}`,
                                            count: 2
                                        }).toLowerCase()
                                    }}
                                />
                            }
                            autoFocus
                            onChange={(e: any) => {
                                setData({ ...data, title: e.currentTarget.value })
                            }} />
                    </Box>
                     <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <TextField
                            name="description"
                            fullWidth
                            value={data.description}
                            className={cnWikiCreateNewDialog("Input")}
                            label={
                                <Translate i18nKey={`pryaniky.wikilist.item.param.description`} />
                            }
                            sx={{
                                ".MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
                                    minHeight: "41px"
                                }
                            }}
                            onChange={(e: any) => {
                                setData({ ...data, description: e.currentTarget.value })
                            }} />
                    </Box>
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        {
                            data.type !== 'file' && <TextField
                                id="wikilink"
                                name="url"
                                fullWidth
                                value={data.url}
                                className={cnWikiCreateNewDialog(error || errorLink ? "ErrorLink" : "InputLink")}
                                label={
                                    <Translate
                                        i18nKey='pryaniky.wikilist.item.param.url'
                                        values={{
                                            type: Translate.t({
                                                i18nKey: `pryaniky.wikilist.item.${data.type}`,
                                                count: 2
                                            }).toLowerCase()
                                        }}
                                    />
                                }
                                autoFocus={error}
                                onChange={onChangeLinkInput}
                                InputProps={{
                                    startAdornment: Addorment

                                }}
                            />
                        }
                        {error && <TextError data={i18n.t('pryaniky.wikilist.error.url.isEmpty')} />}
                        {errorLink && <TextError data={i18n.t('pryaniky.wikilist.error.url.isExist')} />}
                    </Box>
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        {
                            typeFile && <Box
                                ref={dndTriggerRef}
                                sx={{ position: 'relative' }}
                            >
                                <FilesUploader
                                    files={files}
                                    inputAccept={inputAccept}
                                    multiple={false}
                                    showProgressOnNewFiles
                                    selectButtonProps={{
                                        children: <Translate
                                            i18nKey='pryaniky.wikilist.select.type'
                                            variablesI18nKeys={{
                                                type: 'pryaniky.wikilist.item.file'
                                            }}
                                        />,
                                        variant: 'outlined'
                                    }}
                                    error={Boolean(uploadError)}
                                    helperText={uploadError?.error?.error_text}
                                    disabled={uploadDisabled}
                                    // className={cnWikiCreateNewDialog("FileInput")}
                                    // inputAccept="image/jpeg,image/png"
                                    onFileChange={onFileChange}
                                    removeFile={removeFile}
                                />
                            </Box>
                        }
                    </Box>
                    <Box className={cnWikiCreateNewDialog("InputFolder")}>
                        <PageCollectionSuggester
                            TextFieldProps={{
                                label: <Translate
                                    i18nKey='pryaniky.wikilist.item.param.collections'
                                />,
                                InputProps: {
                                    endAdornment: <Tooltip title={i18n.t('pryaniky.wikilist.item.param.collections.help')} disableInteractive>
                                        <HelpIcon sx={{ marginLeft: "10px" }} color="action" />
                                    </Tooltip>
                                }
                            }}
                            onChange={(event, value) => {
                                if (value) {
                                    setData({ ...data, collections: [value] })
                                } else {
                                    setData({ ...data, collections: [] })
                                }
                            }}
                            className={cnWikiCreateNewDialog("InputFolder-Folder")}
                            value={data?.collections?.length > 0 ? data?.collections?.map((el: any) => ({
                                displayName: el?.name,
                                ...el
                            })) : null}
                        />
                    </Box>
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <TagsSuggester
                            TextFieldProps={{
                                label: <Translate
                                    i18nKey='pryaniky.wikilist.item.param.tags'
                                />,
                            }}
                            multiple
                            onChange={(_, value) => {
                                setData({ ...data, tags: value })
                            }}
                            className={cnWikiCreateNewDialog("InputFolder-Folder")}
                            value={data.tags}
                        />
                    </Box>
                    <Divider variant="inset" sx={{ margin: '14px 0' }} />
                    {
                        data.collectionAlias !== "dash"
                        && isAdmin
                        && <StyledFormControlLabel control={
                                <Checkbox
                                    className={cnWikiCreateNewDialog("CheckboxInput")}
                                    checked={data.isInheritingRootRights}
                                    onChange={onChangeIsInheritingRootRights}
                                />
                            }
                            label={<Translate
                                i18nKey='pryaniky.wikilist.item.param.isInheritingRootRights'
                            />}
                        />
                    }
                    <Box className={cnWikiCreateNewDialog("Content-Item")}>
                        <UsersSuggester
                            TextFieldProps={{
                                label: <Translate
                                    i18nKey='pryaniky.wikilist.item.param.moderators'
                                />,
                            }}
                            value={data.moderators}
                            multiple
                            onChange={(_, value) => {
                                setData({ ...data, moderators: value })
                            }}
                            disabled={data.isInheritingRootRights}
                            requestAdditionalParams={{ excludeMe: false, groupid: ddata.gId }}
                        />
                    </Box>
                    <Box className={cnWikiCreateNewDialog("InputFolder")}>
                        <UsersSuggester
                            TextFieldProps={{
                                label: viewersTitle,
                                InputProps: {
                                    endAdornment: <Tooltip title={i18n.t('pryaniky.wikilist.item.param.viewers.help')} >
                                        <HelpIcon sx={{ marginLeft: "10px" }} color="action" />
                                    </Tooltip>
                                }
                            }}
                            value={data.viewers || []}
                            className={cnWikiCreateNewDialog("InputFolder-Folder")}
                            multiple
                            requestAdditionalParams={{ excludeMe: false }}
                            onChange={(_, viewers) => {
                                setData({ ...data, viewers })
                            }}
                            disabled={data.isInheritingRootRights}
                            isAdmin
                        />
                    </Box>
                    <Divider variant="inset" sx={{ margin: '14px 0' }} />
                    {typeWikilist && <Box className={cnWikiCreateNewDialog("ViewType")}>
                        <h4 style={{ margin: 0 }}><Translate i18nKey={'pryaniky.wikilist.item.param.defaultViewType'} /></h4>
                        <ButtonGroup>
                            <Tooltip title={i18n.t('pryaniky.wikilist.item.param.defaultViewType.grid')} >
                                <Button variant={data.defaultViewType === 0 ? "contained" : "outlined"} onClick={() => {
                                    setData({ ...data, defaultViewType: 0 })
                                }}>
                                    <GridView />
                                </Button>
                            </Tooltip>
                            <Tooltip title={i18n.t('pryaniky.wikilist.item.param.defaultViewType.list')} >
                                <Button variant={data.defaultViewType === 1 ? "contained" : "outlined"} onClick={() => {
                                    setData({ ...data, defaultViewType: 1 })
                                }}>
                                    <ViewList />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Box>}
                    <Box className={cnWikiCreateNewDialog("PositionInCollection")}
                        sx={{
                            marginTop: "8px",
                        }}>
                            <NumberTextField
                                fullWidth
                                hideApperance
                                className={cnWikiCreateNewDialog("PositionInCollection")}
                                label={<Translate i18nKey='pryaniky.wikilist.item.param.positionInCollection' />}
                                value={data.positionInCollection}
                                onChange={(e) => {
                                    setData({ ...data, positionInCollection: e.target.value })
                                }}
                            />
                    </Box>

                    <StyledFormControlLabel
                        control={<Checkbox
                            className={cnWikiCreateNewDialog("CheckboxInput")}
                            checked={data.showInMainMenu}
                            onChange={onChangeShowInMainMenu}
                        />}
                        label={<Translate i18nKey={'pryaniky.wikilist.item.param.showInMainMenu'}/>}
                    />

                    {
                    (typePage || typeFile) && <StyledFormControlLabel
                        control={<Checkbox
                            className={cnWikiCreateNewDialog("CheckboxInput")}
                            checked={data.allowComments}
                            onChange={onChangeAllowComments}
                        />}
                        label={<Translate
                            i18nKey={'pryaniky.wikilist.item.param.allowComments'}
                            values={{
                                type: Translate.t({
                                    i18nKey: `pryaniky.wikilist.item.${data.type}`,
                                    count: 2
                                }).toLowerCase()
                            }}
                        />}
                        />
                    }
                    {typeWikilist &&
                        <StyledFormControlLabel
                            control={
                                <Checkbox
                                    className={cnWikiCreateNewDialog("CheckboxInput")}
                                    checked={data.showCategoryAsSelector}
                                    onChange={onChangeShowCategoryAsSelector}
                                />
                            }
                            label={<Translate i18nKey='pryaniky.wikilist.item.param.showCategoryAsSelector' />}
                        />
                    }
                    <StyledFormControlLabel
                        control={<Checkbox
                            className={cnWikiCreateNewDialog("CheckboxInput")}
                            checked={data.isHideFooter}
                            onChange={onChangeHideFooter}
                        />}
                        label={<Translate i18nKey='pryaniky.wikilist.item.param.isHideFooter' />}
                    />
                    <Divider variant="inset" sx={{ margin: '14px 0' }} />
                    <PreviewSelector
                        {...props}
                        aria-describedby={data.id}
                        className={cnWikiCreateNewDialog({}, [props?.className])}
                        onClick={(e: any) => {
                            ImageCropperDialog({
                                onLoaded: (file: any) => {
                                    setData({ ...data, titleImageId: file.id, titleImageUrl: file.originalUrl })
                                    setImgChanged(true)
                                }
                            })

                        }}
                        previewUrl={data.titleImageUrl}
                        newImg={imgChanged}
                        label={
                            <Translate
                                i18nKey='pryaniky.wikilist.item.param.titleImage'
                                values={{
                                    type: Translate.t({
                                        i18nKey: `pryaniky.wikilist.item.${data.type}`,
                                        count: 2
                                    }).toLowerCase()
                                }}
                            />
                        }
                        helperText={<Translate i18nKey="pryaniky.wikilist.item.param.titleImage.help" />}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                acceptText={<Translate i18nKey='create' />}
                closeText={<Translate i18nKey='cancel' />}
                isLoading={loading || isLoading}
                disableAccept={disableAccept || errorLink}
                onAccept={data.url === '' || data.url === undefined ? () => {
                    typeFile ? sendDataForCreatNewPage() : setError(true);
                } : sendDataForCreatNewPage}
                onClose={handleClose}
            />
        </>
    );
};


export const WikiCreateNewDialog = connect<IWikiCreateNewStateProps, IWikiCreateNewDispatchProps, IWikiCreateNewOwnProps, IState>(
    mapWikiCreateNewStateToProps,
    mapWikiCreateNewDispatchToProps,
)(WikiCreateNewDialogPresenter)

export const DialogWikiCreateNew = createModal(WikiCreateNewDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});













// </DialogContent>


