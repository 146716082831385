import { Characteristics as CharacteristicsTypes } from 'utils/src/requests/models/api.shop'
import { i18n, Translate } from 'localization';

/**
 * проверяет, все ли хар-ки выбраны
 * @param characteristics 
 * @returns false - если выбраны не все, true - если выбраны все
 */
export const isValidCharacteristics = (characteristics: CharacteristicsTypes.IItem[]) => {
    if (characteristics.length === 0) return true
    const selectedArr = characteristics.map((characteristic) => {
        return characteristic.tags.some(tag => tag.isSelected)
    })

    return selectedArr.every(item => item)
}

/**
 * проверяет, не достигнут ли лимит по покупкам
 * @param maxOrdersCount 
 * @param ordersCount 
 * @returns false - если лимит достигнут, true - лимит не достигну
 */
export const isValidLimit = (maxOrdersCount: number, ordersCount: number, maxOrdersPeriod: string) => {
    return (maxOrdersPeriod==='unlimited') || !(maxOrdersCount !== 0 && maxOrdersCount <= ordersCount)
}


type TOrderIsDeniedParams = {
    maxOrdersCount: number,
    ordersCount: number,
    maxOrdersPeriod: string,
    characteristics: CharacteristicsTypes.IItem[],
    allowOrder?: boolean
    errorMessage?: string
}

/**
 * зпрещен ли заказ.
 * false - если разрешен. 
 * string, с сообщением об ошибке - если запрещен
 * 
 * @param param0 {TOrderIsDeniedParams}
 */
export const orderIsDenied = ({ maxOrdersCount, ordersCount, maxOrdersPeriod, characteristics, allowOrder = true, errorMessage = '', }: TOrderIsDeniedParams) => {
    if (!isValidLimit(maxOrdersCount, ordersCount, maxOrdersPeriod)) {
        return `${i18n.t('pryaniky.shop.list.maxOrderCount', { maxOrdersCount })} ${i18n.t(`pryaniky.shop.main.periods.${maxOrdersPeriod}`)}`
    }

    if (!isValidCharacteristics(characteristics)) {
        return i18n.t('pryaniky.shop.ProductCard.need_select_characteristics')
    }

    return allowOrder ? false : i18n.t(`pryaniky.shop.oreder.error.${errorMessage}`)
}
