
import { DialogBody } from 'muicomponents/src/DialogParts';
import { defaultMiddleware } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog';
import React, { FC, ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { checkResponseStatus, ResponseError } from 'utils/src';
import { createModal, IBodyProps } from 'utils/src/DialogCreator'
import { useReffedState, useScrollListState } from 'utils/src/hooks';
import { IGetReceivedPresentsResponse, IGetSendedPresentsResponse, IPresentData } from 'utils/src/requests/models/api.shop';
import { getUserReceivedPresents, getUserSendedPresents } from 'utils/src/requests/requests.shop';
import { PresentsDialogItem } from './Item';

const PresentsDialogPr: FC<IBodyProps<{}> & {
    name: ReactNode,
    userId: string,
    widgetType: string,
    presents: IPresentData[]
}> = ({
    name,
    userId,
    widgetType,
    presents,
    handleClose
}) => {

    const paramsRef = useRef({
        userId,
        widgetType,
        presents,
    });
    paramsRef.current.userId = userId;
    paramsRef.current.widgetType = widgetType;
    paramsRef.current.presents = presents;

    const requestIsFinished = useRef(false);

    const fetchPresents = useCallback(async function(skipCount: number, count: number) {
        let prepSkipCount = skipCount;
        if(skipCount === 0) {
            prepSkipCount = paramsRef.current.presents.length + skipCount;
        }
        try {
            const fetchFunction = paramsRef.current.widgetType === 'sendedGifts' ? getUserSendedPresents : getUserReceivedPresents;
            const response: IGetSendedPresentsResponse | IGetReceivedPresentsResponse = await fetchFunction(
                paramsRef.current.userId,
                true,
                {
                    skipCount: prepSkipCount,
                    count
                }
            );
            if(checkResponseStatus(response)) {
                const presents = response.data?.map((item) => ({
                    ...item,
                    id: item.orderProductId.toString(),
                    userOrder: 'orderFromUser' in item ? item.orderFromUser : item.orderForUser,
                    users: item.users.map((user) => ({
                        ...user,
                        userOrder: 'orderFromUser' in user ? user.orderFromUser : user.orderForUser,
                    })),
                }));
                requestIsFinished.current = response.isFinished || false;
                if(skipCount === 0) {
                    return [...paramsRef.current.presents, ...presents];
                }
                return presents as IPresentData[];
            } else {
                throw new ResponseError("Rpesents data is not valid", response);   
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.warn(error);
            }
            console.error(error);
            return [];
        }
    }, []);

    return (
        <DialogBody
            header={name}
            onClose={handleClose}
            contentProps={{
                sx: {
                    ['& .ItemsListDialog-Head']: {
                        display: 'none'
                    }
                }
            }}
        >
            <ItemsList<IPresentData>
                BodyComponent={ItemsListBody}
                ItemComponent={PresentsDialogItem}
                listHeaderProps={{
                    disableSearch: true
                }}
                itemMiddleware={(props) => {
                    return {
                        ...props,
                        widgetType
                    };
                }}
                listStateHook={({ defaultSelected }, middlewareParams) => {

                    let {
                        isFinished,
                        ...state
                    } = useScrollListState(async function(...args) {
                        return fetchPresents(...args);
                    }, [], {});

                    isFinished = requestIsFinished.current || isFinished;
            
                    return {
                        middlewareParams,
                        state: {
                            isFinished,
                            ...state
                        },
                        defaultSelected,
                        middleware: defaultMiddleware,
                        changeSearch: () => {},
                        onChangeTab: () => {},
                        search: ''
                    }
                }}
            />
        </DialogBody>
    )
};

export const PresentsDialog = createModal(PresentsDialogPr, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            overflowY: 'hidden',
        },
    },
})