import { i18n, Translate } from 'localization';
import { TDialogProps } from 'utils/src/DialogCreator'
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { likers as newsLikers } from 'utils/src/requests/requests.news'
import { likers as replyLikers } from 'utils/src/requests/requests.reply'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'

const createLikersDialog = () => {
  return ({
    newsId,
    replyId
  }: {
    newsId: string;
    replyId?: string;
  }, containerProps?: TDialogProps) =>
    dialog({
      method: async function (skipCount, { count, search }) {
        const response = replyId ?
          await replyLikers({
            nId: newsId,
            rId: replyId as any,
            skipCount,
            count,
            search
          }) :
          await newsLikers({
            nId: newsId,
            skipCount,
            count,
            search
          })
        const result = await response.r as { data: IBaseUser[] } & IBasicResponse
        if (result.error_code !== 0) throw result
        return result.data.map((item) => ({ ...item, url: `/user/${item.id}` }))
      },
      ItemComponent: UsersListItem,
      listBodyProps: {
        header: i18n.t('pryaniky.modal.likers.title')
      }
    }, containerProps)
}
export default createLikersDialog()