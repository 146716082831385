/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  // direction: ,
  elements: [
  ]
}