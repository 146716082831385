import { compose } from '@bem-react/core';
import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';
import { Button } from 'uielements/src/Button/Button';
import { cnServiceActions, IServiceActionsProps, IServiceActionsState } from './Reply-ServiceActions.index';
import './Reply-ServiceActions.scss';
import { Icon } from 'uielements/src/Icon/Icon';
import { toast } from 'react-toastify';

const disabledActions = [
  'translate', 'newslink', '-'
];


export class ServiceActionsPresenter extends React.PureComponent<IServiceActionsProps, IServiceActionsState> {
  // public utils: any = utils;
  private el = React.createRef<HTMLDivElement>();

  constructor(props: IServiceActionsProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  // tslint:disable-next-line
  public action_delete = () => {
    const deleteNews = () => {
      this.props.removeReply(this.props.id);
      this.action(1, true)
        .r
        .then((d: any) => {
          if (d && d.error_code === 0) {
            toast.success(d.error_text);
          } else toast.error('err');
        });
    }
    utilsDep.modal({
      type: 'confirm',
      header: i18n.t('pryaniky.comment.deleteConfirm.title'),
      okBtnAction: deleteNews,
    })
  }

  // tslint:disable-next-line
  public action_edit = () => {
    this.props.enableEdit();
  }

  // tslint:disable-next-line
  public action_reportSpam = () => {
    const reportSpam = () => {
      this.action(2, '').r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });
    }
    utilsDep.modal({
      type: 'confirm',
      header: i18n.t('pryaniky.comment.reportConfirm.title'),
      okBtnAction: reportSpam,
    })
  }

  public render() {
    const { children, className, actions } = this.props;
    return (
      <div ref={this.el} className={cnServiceActions({open: this.state.open}, [className])}>
        <Button onClick={this.toggleOpen} className={'Reply-ServiceActions-OpenBtn'} theme='unstyled'><Icon icon={'ellipsis-h'} /></Button>
        {this.state.open && <ul className='Reply-ServiceActions-List'>
          {
            actions.map((action: string, i: number) => {
              if (disabledActions.indexOf(action) !== -1) return null;
              const forAction: any = this;
              return <li key={i} className='Reply-ServiceActions-Item'>
                <Button className={'service-' + action} onClick={forAction['action_' + action]} theme='unstyled'>{i18n.t('pryaniky.comment.actions.' + action)}</Button>
              </li>
            })
          }
          <li className='Reply-ServiceActions-Item'>
            <Button className={'service-linkToNews'} theme='unstyled' onClick={this.linkToNews}>{i18n.t('pryaniky.comments.actions.link')}</Button>
          </li>
        </ul>}
        {children}
      </div>
    )
  }

  private handleClickOutside = (event: any) => {
    if (this.el.current && !this.el.current.contains(event.target)) {
      this.toggleOpen();
    }
  }

  private toggleOpen = () => {
    if (!this.state.open) {
      document.addEventListener('click', this.handleClickOutside, false);
    } else {
      document.removeEventListener('click', this.handleClickOutside, false);
    }
    this.setState((s: IServiceActionsState) => {
      s.open = !s.open;
      return s;
    })
  }


  private linkToNews = () => {
    utilsDep.modal({
      type: 'linkToNews',
      header: i18n.t('pryaniky.comments.actions.link'),
      url: 'news/' + this.props.id,
      // okBtnAction: ()=>{},
    })
  }

  private action(action: number, data: any) {
    return utils.API.reply.action(this.props.newsID, this.props.id, action, data)
  }

}


export const ServiceActions = compose(
  // withBemMod(cnServiceActions(), {})
)(ServiceActionsPresenter)