import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Masonry, Button, Avatar } from 'uielements/src';
import { Item } from './Item/Courses-Item';
import lms_header from 'assets/jpg/lms_header.jpg';
import { mapStateToProps, cnCourses, ICoursesProps, mapDispatchToProps } from './Courses.index';
import './Courses.scss';
import { PageHeader } from 'blocks/PageHeader/PageHeader';
import { Categories } from '../Categories/Categories'
import queryString from 'query-string';
import { Filters } from '../Filters/Filters'
import { Search } from '../Search/Search'
import { Course } from '../../Skelets/CourseItem/Course'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { ButtonBox } from 'uielements/src/ButtonBox/ButtonBox'
import { tS, Ts } from 'LMSModule/utils/i18n.adaptor'
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import CourseStatusesWidget from '../CourseStatusesWidget'
import { Tabs } from '../../LMSTeacherOffice/Tabs/Tabs'
import { Port } from '../../LMSTeacherOffice/Tabs/Port/Port'
import UnitsComments from '../../LMSTeacherOffice/Comments'
import TrackList from '../TrackList'
import { videoUrls } from 'utils/src/videoUrls';
import {VideoButton} from 'uielements/src/Dialog/VideoDilog/VideoButton';

const LoadButton = withLoadSpin(Button)

const defaultTabs = [
  {
    value: 'courses',
    title: <Ts i18nKey='tabs.courses.1' />,//tS('tabs.courses.1'),
    id: 'courses'
  }, {
    value: 'comments',
    title: <Ts i18nKey='tabs.allcomments.1' />,//tS('tabs.allcomments.1'),
    id: 'comments'
  }, /*{
    value: 'tracks',
    title: 'tracks',//tS('tabs.allcomments.1'),
    id: 'tracks'
  },*/
];


export const CoursesPresenter: React.FC<ICoursesProps & any> = ({ loadCourses, courses, cIds, isLoading, location, sendLikeCourse, isFinished, currentUser }) => {
  const requestParams = queryString.parse(location.search)
  useEffect(() => void loadCourses({ ...requestParams }, true), [location.search]);

  const loadMore = () => loadCourses(requestParams)
  const triggerRef = useInfiniteScroll({
    loadMore,
    isLoading,
    isFinished,
  });

  const Courses = <div className={cnCourses('Section')}>

    <div className={cnCourses('Content')}>
      <div className={cnCourses('SearchBox')}>
        <Search isLoading={isLoading} value={requestParams.search} />
      </div>

      <ListPlaceholder
        className={cnCourses('Placeholder')}
        isFinished={isFinished}
        isLoading={isLoading}
        itemsCount={cIds.length}
        titleEmpty={tS('noCourses.1')}
        textEmpty={requestParams.search
          ? tS('noCoursesForQuery.1', { query: requestParams.search })
          : tS('noCoursesForYou.1')}
        textLoad={tS('loading.1')}
        onlyEmpty
      >
        <Masonry breakPoints={[450]} className={cnCourses('Items')}>
          {cIds.map((cId: any) => (
            cId === 'skelet' ? <Course /> : <Item course={courses[cId]} key={cId} sendLikeCourse={sendLikeCourse} />
          ))}
          {(cIds.length === 0 && isLoading) && (new Array(4)).fill('1').map((v, i) => <Course key={i} />)}
        </Masonry>

        {!isFinished && (
          <LoadButton
            forwardedRef={triggerRef}
            isLoading={isLoading}
            className={cnCourses('Trigger')}
            onClick={isLoading ? void 0 : loadMore}>
            {tS('more.1')}
          </LoadButton>
        )}
      </ListPlaceholder>
    </div>

    <div className={cnCourses('Aside')} >
      <Categories activeId={requestParams.cat} />

      <Filters activeId={requestParams.status} mandatory={requestParams.isMandatory} />
    </div>
  </div>

  const tabs = {
    courses: Courses,
    comments: <UnitsComments type={'user'} />,
    tracks: <TrackList />,
    default: Courses,
  }

  return (
    <div className={cnCourses({}, ['Page'])}>

      <div className={cnCourses('Header')}>

        <div className={cnCourses('HeaderSection')}>
          <Avatar imgUrl={lms_header} size={64} className={cnCourses('HeaderAvatar')} />
          <div className={cnCourses('HeaderInfo')}>
            <h3 className={cnCourses('HeaderTitle')}>{tS('lmsHeader.1')}</h3>
            <VideoButton className={cnCourses('HeaderInfo-VideoHelp')} url={videoUrls.lms.courses} type={'vimeo'} title={tS('TeacherOffice.1.help')} />
            {currentUser.baseData.isAdmin && <ButtonBox orientation={'horizontal'} className={cnCourses('HeaderActions')}>
              <Button type='rlink' href={'/TeacherOffice'} title={tS('TeacherOffice.title.1')} >{tS('TeacherOffice.1')}</Button>
              <Button main type='rlink' href={'/LMS'} title={tS('LMS.title.1')} >{tS('LMS.1')}</Button>
            </ButtonBox>}
          </div>
        </div>

        <div className={cnCourses('HeaderSection')}>
          <div className={cnCourses('HeaderNav')}>
            <div className={cnCourses('Noop')}></div>
            <Tabs tabs={defaultTabs} />
            <div className={cnCourses('Noop')}></div>
          </div>
        </div>
      </div>

      <div className={cnCourses('Section')}>
        <CourseStatusesWidget className={cnCourses('Statuses')} />
      </div>

      <Port tabs={tabs} />

    </div>
  );
};

export const Courses = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesPresenter);
