import React, { FC, useEffect, memo, useCallback } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
// import { useDispatch, useSelector } from 'react-redux';
// import { routerActions } from 'connected-react-router';
import './style.scss'
import { Button, Avatar } from 'uielements/src'
import { i18n, Translate } from 'localization';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { generateLinkData, decodeEntities } from 'utils/src/utils'
type IPresenterProps = News.NewsBannerTypeNews.IPresenterProps





export const Presenter: FC<IPresenterProps> = ({
    banner
}) => {
    useEffect(() => {

    }, [])

    const { href, target, type } = generateLinkData(banner.url || '')

    return <div className={cnClassName({ subtitle: banner.subtitle || '' }, [])}>
        <div className={cnClassName('Header')} >
            <Avatar className={cnClassName('Avatar')} imgUrl={banner.user?.imgUrl} name={banner.user?.displayName} size={40} />
            <div className={cnClassName('Side')}>
                <UserMention className={cnClassName('DisplayName')} id={banner.user?.id} name={banner.user?.displayName} />
                {/* <div className={cnClassName('DisplayName')}>{banner.user?.displayName}</div> */}
                <div className={cnClassName('Type')}>{i18n.t(`pryaniky.post.${banner.subtitle}.title`)}</div>
            </div>
        </div>
        {banner.subtitle !== "badges" &&
            <div className={cnClassName('Content')}>
                {/* {
                    banner.subtitle === 'badges' &&
                    <Avatar className={cnClassName('Avatar')} imgUrl={banner.badgeImgUrl || ''} size={88} />
                } */}
                {banner.title && <div className={cnClassName('Title')}>{decodeEntities(banner.title || '')}</div>}
                <div className={cnClassName('Text')}>{decodeEntities(banner.text || '')}</div>
            </div>}
        {banner.subtitle === "badges" &&
            <div className={cnClassName('Content')}>
                <div className={cnClassName('Text')}>{decodeEntities(banner.text || '')}</div>
                <div className={cnClassName('Content-Badge')}>

                    <Avatar className={cnClassName('Avatar')} imgUrl={banner.badgeImgUrl || ''} size={56} />

                    {banner.title && <div className={cnClassName('Title')}>{decodeEntities(banner.title || '')}</div>}
                </div>
            </div>}
        <div className={cnClassName('Bottom')}>
            <Button noBorder noBackground className={cnClassName('Action', ['primaryColor3-text'])} target={target} type={type} href={href}>{banner.buttonText}</Button>
        </div>
    </div>
}

export const Banner = memo(Presenter);

export default Banner