import { Box, styled } from "muicomponents/src";

enum PresentsItemsStyles {
    gap = 16
}

export const PresentsItemsBox = styled(Box)({
    display: 'flex',
    gap: PresentsItemsStyles.gap,
    gridGap: PresentsItemsStyles.gap,
    flexWrap: 'wrap'
}) as typeof Box;