import React, { FC, useState, useEffect } from 'react';
import { IApproveDialog } from './ApproveDialog.index'
import './ApproveDialog.scss'
import { Dialog, DialogTitle, DialogContent, CheckboxInput, SearchInput, Checkbox, Avatar, Button, Icon } from 'uielements/src';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { toast } from 'react-toastify';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { i18n, Translate } from 'localization';

export const ApproveDialog: FC<IApproveDialog> = ({ onCloseDialog, showDialog, pkid }) => {
    const [usersRequests, setUsersRequests] = useState<any[]>([]);
    const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
    const [approved, setApproved] = useState<string[]>([]);
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        utils.API.groups.getRequestsList(pkid).r.then((d: any) => {
            if (utils.checkResponseStatus(d)) setUsersRequests(d.data)
            else console.error('getRequestsList', d)
        })
    }, [pkid])

    let selectedAll = selectedRequests.length === usersRequests.length - approved.length;
    selectedAll = selectedRequests.length === 0 ? false : selectedAll;

    const selectAll = () => {
        if (selectedAll) setSelectedRequests([]);
        else setSelectedRequests(usersRequests.map((v: any) => v.id).filter((v: string) => !isApproved(v)));
    }

    const approveRequest = (uid: string, approve: boolean) => () => {
        utilsProj.confirm({
            text: approve ? `Поддтвердить` : `Отказать`,
            onConfirm: () => {
                setApproved([...approved, uid])
                utils.API.groups.setRequestApprove(pkid, [uid], approve).r.then((d: any) => {
                    if (utils.checkResponseStatus(d)) {
                        setSelectedRequests(selectedRequests.filter(v => v !== uid))
                        toast.success('Выполнено');
                    } else {
                        setApproved(approved.filter(v => v !== uid))
                        toast.error('Ошибка');
                    }
                })
            }
        })
    }

    const approveAll = (approve: boolean) => () => {
        utilsProj.confirm({
            text: approve ? `Поддтвердить выбранным` : `Отказать выбранным`,
            onConfirm: () => {
                setApproved(selectedRequests)
                utils.API.groups.setRequestApprove(pkid, selectedRequests, approve).r.then((d: any) => {
                    if (utils.checkResponseStatus(d)) {
                        setSelectedRequests([])
                        toast.success('Выполнено');
                    } else {
                        setApproved(approved.filter(v => !isChecked(v)))
                        toast.error('Ошибка');
                    }
                })
            }
        })

    }

    const setChecked = (uid: string) => () => {
        setSelectedRequests((selectedRequests.findIndex((val) => val === uid) !== -1) ? selectedRequests.filter((val) => val !== uid) : [...selectedRequests, uid])
    }

    const isChecked = (uid: string) => selectedRequests.findIndex((val) => val === uid) !== -1

    const isApproved = (uid: string) => approved.findIndex((val) => val === uid) !== -1

    return <Dialog onClose={onCloseDialog} isShown={showDialog}>
        <DialogTitle onClose={onCloseDialog}>
            <SearchInput className={'ApproveDialog-Search'} onChange={setFilter} throttle={300} />

            <div className={'ApproveDialog-SelectBox'}>
                <CheckboxInput
                    className={'ApproveDialog-SelectAll'}
                    checked={selectedAll}
                    onClick={selectAll}
                    text={selectedRequests.length === 0 ? 'Выбрать всех' : `Выбрано ${selectedRequests.length}`} />

                {selectedRequests.length !== 0 && <div className={'ApproveDialog-ApproveActions'}>
                    <Button className={'ApproveDialog-ApproveBtn'} onClick={approveAll(true)}>
                        <Tooltip
                            title={i18n.t('pryaniky.group.ApproveDialog.btn.help.approveUser')} >
                            <Icon icon={'user-plus'} />
                        </Tooltip>
                    </Button>
                    <Button className={'ApproveDialog-ApproveBtn'} onClick={approveAll(false)}>
                        <Tooltip
                            title={i18n.t('pryaniky.group.ApproveDialog.disapproveUser')}>
                            <Icon icon={'user-minus'} />
                        </Tooltip>
                    </Button>
                </div>}
            </div>

        </DialogTitle>
        <DialogContent>
            {usersRequests.filter((val: any) => val.displayName.startsWith(filter.trim())).map((user: any) =>
                <div key={user.id} className={'ApproveDialog-User'} >
                    {!isApproved(user.id) && <Checkbox checked={isChecked(user.id)} onClick={setChecked(user.id)} />}
                    <Avatar
                        className={'ApproveDialog-Avatar'}
                        imgUrl={user.imgUrl}
                        imgId={user.imgId}
                        name={user.displayName}
                        size={56}
                    />
                    <div className={'ApproveDialog-Info'}>
                        <h4 className={'ApproveDialog-Name'}>{user.displayName}</h4>
                        <div>{user.position}</div>
                        <div>{user.division}</div>
                    </div>
                    {!isApproved(user.id) && <div className={'ApproveDialog-Actions'}>
                        <Button className={'ApproveDialog-ApproveBtn'} onClick={approveRequest(user.id, true)}>
                            <Tooltip
                                title={i18n.t('pryaniky.group.ApproveDialog.btn.help.approveUser')} >
                                <Icon icon={'user-plus'} />
                            </Tooltip>
                        </Button>
                        <Button className={'ApproveDialog-ApproveBtn'} onClick={approveRequest(user.id, false)}>
                            <Tooltip
                                title={i18n.t('pryaniky.group.ApproveDialog.disapproveUser')}>
                                <Icon icon={'user-minus'} />
                            </Tooltip>
                        </Button>
                    </div>}
                </div>)}
        </DialogContent>
    </Dialog>
}