import React, { FC, useState, useMemo, useCallback } from 'react'

// import Button from '@material-ui/core/Button';
import * as utils from 'utils/src/utils';
import Popover from '@material-ui/core/Popover';
import { i18n, Translate } from 'localization';
import { Layout } from '../../Layout/InfoBlock-Layout';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { Button as PrnBtn } from 'uielements/src/Button/Button';
import { IUserProps, mapDispatchToProps, mapStateToProps } from './Edit.index'
import { connect } from 'react-redux';
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CollapseBox } from 'uielements/src/CollapseBox/CollapseBox';
import { Icon } from 'uielements/src/Icon/Icon';
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput';
import { Button, CheckboxInput, Loading, Input as PryanikyInput } from 'uielements/src';
import { ChangePassword } from 'blocks/PryanikyForms/ChangePassword/ChangePassword'
// import { сhangeMail } from 'blocks/Dialogs/Users/ChangeMail/ChangeMail'
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import MuiButton from '@material-ui/core/Button';
import './InfoBlock-User_edit_true.scss';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
// import {} from 'date-fns';
import ruLocale from "date-fns/locale/ru";
import { toast } from 'react-toastify';
import FieldsRender from './FieldsRender/FieldsRender';
import { changePasswordDialog } from '../../../Dialogs/ChangePassword/ChangePassword';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ChangeUserNetwork } from 'blocks/Dialogs/Users/ChangeNetwork/ChangeNetwork';
import { TimeZoneSuggester } from 'muicomponents/src/Suggester';
import { DialogCropper } from 'muicomponents/src/CropperDialog/CropperDialog';
import { NValidate, validate_v2 } from 'utils/src';
import { getLocaleDateMask, isValidDate } from 'utils/src/utils.dates';

type TDisabledFields = {
    firstName?: boolean,
    lastName?: boolean,
    middleName?: boolean,
    position?: boolean,
    division?: boolean,
    birthday?: boolean,
    phone?: boolean,
    sex?: boolean,
    language?: boolean,
    showMyNewsByDefault?: boolean,
    eMail?: boolean,
}
const DISABLED_PROFILE_FIELDS: TDisabledFields = {
    firstName: true,
    lastName: true,
    middleName: true,
    position: true,
    division: true,
    birthday: true,
    phone: true,
    sex: true,
    language: true,
    showMyNewsByDefault: true,
    eMail: true,
}

// const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.currentTarget.value;
//     if (!/@.+\./.test(value)) setEmail({ value, valid: false, error: i18n.t('pryaniky.modal.inviteUser.error') });
//     else setEmail({ value, valid: true, error: '' });
// };

// let localRef: any = {};

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

// let localRef: any = {};
function TextMaskCustom(props: TextMaskCustomProps) {
    const [focused, setFocused] = useState(false)
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            {...other}
            ref={(ref: any) => {
                // localRef.inputElement = ref ? ref.inputElement : null;
                inputRef(ref ? ref.inputElement : null);
            }}
            // mask={['+', /[1-9]/, /[0-9 ]/, /[0-9 ]/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            mask={utils.phoneMaskFunc}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },

        formControlPhone: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        formControlBase: ({ breakPoint }: { breakPoint?: boolean }) => ({
            flex: '1 1 100%',
            display: 'flex',
            flexDirection: breakPoint ? 'column' : 'row',
        }),
        formInput: {
            flex: '33%',
        },
        labelPhone: {
            backgroundColor: '#fff', zIndex: 10
        },
        confirmBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid #D7D7D7',
        },
        confirmBtn: {
            marginLeft: '10px',
            marginTop: '20px',
        },
        collapseHeader: {
            display: 'flex',
            cursor: 'pointer',
            background: '#f7f7f7',
            padding: '9px 24px',
            borderBottom: '1px solid #d3d9e0',
            width: '100%',
        },
        collapseHeaderText: {
            margin: '0',
            flex: '1 1',
            textAlign: 'left',
        },
        collapseHeaderIcon: {
            margin: '0',
        },
        formLabel: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 13
        },
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        noEditblFieldTags: {
            width: "fit-content",
            padding: "0 11px",
            margin: "0 5px 0 0",
            background: "#E3E3E3",
            borderRadius: "24px",
        },
        noEditblFieldDivTags: {
            display: "flex",
        },
        inline: {
            display: 'flex',
            alignItems: 'center'
        },
        marginLeft: {
            marginLeft: '12px'
        }
    })
);

let lngChange = false;


const fieldIsDisabled = (user: any, propertyName: string) => {
    if (!user?.profileData?.userInfoFields?.entities?.fields) return false;
    const fields = user.profileData.userInfoFields.entities.fields;
    const fieldId = (user.profileData.userInfoFields.result as string[]).find((id) => fields[id].field.propertyName === propertyName)
    if (!fieldId) return false
    const field = fields[fieldId]
    return field.field.isReadOnly
}

export const UserEdit_: FC<IUserProps> = ({
    user,
    authUser,
    appSettings,
    changeUser,
    userId,
    updateUser,
    editEnd,
    getUser,
    i18n_set_language,
    networksSettings
}) => {

    const breakPoint = useMediaQuery('(max-width:500px)')
    const classes = useStyles({ breakPoint });

    const isFeedbackEnabled = appSettings.enabledModules.feedback;

    // используем момент для форматирования даты по текущей локали
    const dateForFormat = "0001-01-01T00:00:00.000Z" === user?.extData?.birthday ? "1900-01-01T00:00:00.000Z" : (user?.extData?.birthday || "1900-01-01T00:00:00.000Z")
    const format = moment(dateForFormat).format('L');


    // ручную введенную дату парсим сами так как компонент KeyboardDatePicker похоже не умеет работать с другими локалями кроме en
    // маска для момента, чтобы из строки сделать дату ,если пользователь ввел дату руками
    const maskToParseString = useMemo(() => getLocaleDateMask(), []);

    // маска для инпута, если пользователь вводит дату руками
    const charMask = '_';
    const inputMask = useMemo(() => moment().format('L').replace(/\d/g, '_'), []);

    const [helperText, setHelperText] = React.useState<React.ReactNode | null>(null);


    const [showDialog, setShowDialog] = useState(false)

    const disableFields = useCallback<typeof fieldIsDisabled>((...args) => {
        if (authUser?.baseData.isAdmin) return false;
        return fieldIsDisabled(...args);
    }, [authUser?.baseData.isAdmin]);

    if (!user.baseData) {
        getUser(userId)
        return <div>Loading...</div>
    }
    const changeFirstName = (firstName: string) => {
        changeUser(userId, { ...user, profileData: { ...user.profileData, firstName } });
    };

    const changeLastName = (lastName: string) => {
        changeUser(userId, { ...user, profileData: { ...user.profileData, lastName } });
    };

    const changeMiddleName = (middleName: string) => {
        changeUser(userId, { ...user, profileData: { ...user.profileData, middleName } });
    };

    const positionField = user.profileData.userInfoFieldsOriginal.find((el: any) => el.field.propertyName === 'position')
    const allowPositionMultiValues = positionField?.field.allowMultipleValues
    const changePosition = (positionTags?: any) => {
        let tagsArray = positionTags
        if (!allowPositionMultiValues && user.baseData.positionTags.length === 1) {
            tagsArray = [positionTags[positionTags.length - 1]].filter(Boolean)
        }
        changeUser(userId, { ...user, baseData: { ...user.baseData, positionTags: tagsArray, position: null } });
    };

    const divisionField = user.profileData.userInfoFieldsOriginal.find((el: any) => el.field.propertyName === 'division')
    const allowDivisionMultiValues = divisionField?.field.allowMultipleValues
    const changeDevision = (divisionTags?: any) => {
        let tagsArray = divisionTags
        if (!allowPositionMultiValues && user.baseData.divisionTags.length === 1) {
            tagsArray = [divisionTags[divisionTags.length - 1]].filter(Boolean)
        }
        changeUser(userId, { ...user, baseData: { ...user.baseData, divisionTags: tagsArray, division: null } });
    };

    const validationData = {
        positionValidate: user.baseData.positionTags,
        divisionValidate: user.baseData.divisionTags,
    };

    const dataValidateRules: NValidate.ValidateRulesObject<typeof validationData> = {
        positionValidate: {
            max: allowPositionMultiValues ? undefined : {
                errorMessageKey: 'pryaniky.userSettings.list.item.multipleValuesNotAllowed',
                value: 1,
            }
        },
        divisionValidate: {
            max: allowDivisionMultiValues ? undefined : {
                errorMessageKey: 'pryaniky.userSettings.list.item.multipleValuesNotAllowed',
                value: 1,
            }
        },
    };
    const { positionValidate, divisionValidate } = validate_v2(validationData, dataValidateRules);
    const isBtnDisabledByValidate = !!positionValidate?.errorMessageKey || !!divisionValidate?.errorMessageKey

    const changeBirthday = (val: string) => {
        changeUser(userId, { ...user, extData: { ...user.extData, birthday: val } });
    };

    const changeLanguage = (e: any) => {
        lngChange = true;
        changeUser(userId, { ...user, baseData: { ...user.baseData, language: e.target.value } });
        // i18n.changeLanguage(e.target.value, () => changeUser(userId, { ...user, baseData: { ...user.baseData, language: e.target.value } }));
    };

    const changeEmail = (val: string) => {
        changeUser(userId, { ...user, baseData: { ...user.baseData, eMail: val } });
    };

    const changeExtData = (data: Partial<(typeof user)['extData']>) => {
        changeUser(userId, { ...user, extData: { ...user.extData, ...data } });
    };

    const changeShowNews = (e: any) => {
        changeUser(userId, { ...user, profileData: { ...user.profileData, showMyNewsByDefault: !user.profileData.showMyNewsByDefault } });
    };

    const changeSex = (e: any) => {
        changeUser(userId, { ...user, baseData: { ...user.baseData, sex: e.target.value } });
    }

    const changeAdmin = (e: any) => {
        changeUser(userId, {
            ...user,
            baseData: { ...user.baseData, isAdmin: !user.baseData.isAdmin },
            profileData: { ...user.profileData, isAdmin: !user.profileData.isAdmin }
        });
    }

    // const [masked, setMasked] = useState('7(  )    -    ');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setMasked(event.target.value);
        const phone = event.target.value.replace(' ', '').replace(' ', '');
        const sections = phone.replace('+', '').split(/[()]/);
        changeUser(userId, {
            ...user,
            baseData: {
                ...user.baseData, phone
            },
            profileData: {
                ...user.profileData,
                country: sections[0],
                region: sections[1],
                phone: sections[2]
            }
        });
    };
    const onChangeFields = (fieldId: string, property: string, fieldsSet: string) => (value: any) => {
        changeUser(userId, {
            ...user,
            profileData: {
                ...user.profileData,
                [fieldsSet]: {
                    ...user.profileData[fieldsSet],
                    entities: {
                        ...user.profileData[fieldsSet].entities,
                        fields: {
                            ...user.profileData[fieldsSet].entities.fields,
                            [fieldId]: {
                                ...user.profileData[fieldsSet].entities.fields[fieldId],
                                // value: sValue,
                                [property]: value,
                                ...(property === 'tags' ? { value: '' } : {})
                            }
                        }
                    }
                },
                userHeadFields: {
                    ...user.profileData.userHeadFields,
                    entities: {
                        ...user.profileData.userHeadFields.entities,
                        fields: 'fields' in user.profileData.userHeadFields.entities ? {
                            ...user.profileData.userHeadFields.entities.fields,
                            [fieldId]: fieldId in user.profileData.userHeadFields.entities.fields ? {
                                ...user.profileData.userHeadFields.entities.fields[fieldId],
                                // value: sValue,
                                [property]: value,
                                ...(property === 'tags' ? { value: '' } : {})
                            } : undefined
                        } : undefined
                    }
                }
            }
        });
    };

    const imgLoaded = (loadedImg: { [s: string]: any }) => {
        changeUser(userId, {
            ...user,
            baseData: {
                ...user.baseData,
                userLargePhotoUrl: loadedImg.previewUrl,
                largeImgId: loadedImg.id,
                userPhotoUrl: loadedImg.previewUrl
            },
        });
    };

    const changeLng = () => {
        if (lngChange) {
            (window as any).lngChangeToast = toast.info(<Loading text={<Translate i18nKey={'pryaniky.language.translating'} />} />, { autoClose: false });
            i18n_set_language(user.baseData.language);
        }
    }

    const saveData = () => {
        updateUser(userId, user);
        editEnd(changeLng);
    }

    const disabledFields: TDisabledFields = authUser.baseData.isAdmin ? {} : ((window as any).DISABLED_PROFILE_FIELDS || {})
    const languages = appSettings.languages;
    const defaultTimeZone = appSettings.defaultTimeZone;

    return <div>
        <div className="InfoBlock-Inner InfoBlock-UserEdit InfoBlock-Inner_align_top">
            <div className={'InfoBlock-Img InfoBlock-Img_edit'}>
                <ImgLoader type={'profileEdit'} previewUrl={user.baseData.userLargePhotoUrl}
                    onLoadEnd={imgLoaded}
                />
                <Button className={'InfoBlockImg-Button'} onClick={() => {
                    DialogCropper({}).then((value) => {
                        imgLoaded(value);
                    }).catch(() => { })
                }}>
                    <Translate i18nKey={'download'} />
                </Button>
            </div>
            <div className={'InfoBlock-Info'}>
                <div className={classes.root}>
                    <FormControl className={classes.formControlBase}>
                        <TextField
                            // disabled={disabledFields.firstName}
                            disabled={disableFields(user, "displayName")}
                            className={classes.formInput}
                            label={i18n.t('pryaniky.profile.name')}
                            value={user.profileData.firstName}
                            onChange={(e: any) => changeFirstName(e.target.value)}
                        />
                        <TextField
                            // disabled={disabledFields.lastName}
                            disabled={disableFields(user, "displayName")}
                            className={classes.formInput}
                            label={i18n.t('pryaniky.profile.lastName')}
                            value={user.profileData.lastName}
                            onChange={(e: any) => changeLastName(e.target.value)}
                        />
                        <TextField
                            // disabled={disabledFields.middleName}
                            disabled={disableFields(user, "displayName")}
                            className={classes.formInput}
                            label={i18n.t('pryaniky.profile.middleName')}
                            value={user.profileData.middleName}
                            onChange={(e: any) => changeMiddleName(e.target.value)}
                        />
                    </FormControl>

                    <FormControl component="fieldset" className={classes.formControl}>
                        {/* <TextField
                            fullWidth
                            disabled={disabledFields.position}
                            label={i18n.t('pryaniky.profile.position')}
                            value={user.baseData.position}
                            onChange={(e: any) => changePosition(e.target.value)}
                        /> */}

                        <Suggest
                            disabled={disableFields(user, "position")}
                            type='position'
                            multiple
                            label={<Translate i18nKey={'pryaniky.profile.position'} />}
                            value={user.baseData.positionTags}
                            allowCreate={true}
                            onChange={(position: any) => changePosition(position)}
                            helperText={i18n.t(positionValidate?.errorMessageKey as string)}
                        />
                    </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {/* <TextField
                            fullWidth
                            disabled={disabledFields.division}
                            label={i18n.t('pryaniky.profile.division')}
                            value={user.baseData.division}
                            onChange={(e: any) => changeDevision(e.target.value)}
                        /> */}

                        <Suggest
                            type='division'
                            disabled={disableFields(user, "division")}
                            multiple
                            label={<Translate i18nKey={'pryaniky.profile.division'} />}
                            value={user.baseData.divisionTags}
                            allowCreate={true}
                            onChange={(division: any) => changeDevision(division)}
                            helperText={i18n.t(divisionValidate?.errorMessageKey as string)}
                        />

                    </FormControl>

                    {/*<input type='text' value={state.baseData.eMail} onChange={(e: any) => changeEmail(e.target.value)} />*/}

                    <FormControl component="fieldset" className={classes.formControl}>
                        {/* <Datepicker disabledDays={false} onChange={changeBirthday} locale={i18n.language} value={new Date(state.extData.birthday)} /> 
                        <label className={classes.formLabel}>{i18n.t('birthday')}</label>
                        <DateTimeInput
                            className={''}
                            value={user.extData.birthday as any}
                            onChange={changeBirthday}
                            icon={onClick => (
                                <span {...{ onClick }}>
                                    <Icon icon="calendar" />
                                </span>
                            )}
                        />
                        <FormControl key={fieldId} component="fieldset" className={classes.formControl}> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                            <KeyboardDatePicker
                                disabled={disableFields(user, "birthday")}
                                autoOk
                                // disabled={disabledFields.birthday}
                                variant="inline"
                                label={i18n.t('birthday')}
                                // формат отображения даты
                                format={format}
                                // маска ввода для инпута
                                mask={inputMask}
                                // маска для символа при введении руками, определена стандартная, что бы было понятнее как парсить дальше
                                maskChar={charMask}
                                InputAdornmentProps={{ position: "start" }}
                                value={user?.extData?.birthday || dateForFormat}
                                helperText={helperText}
                                error={helperText !== null}
                                minDate={new Date("1900-01-01T00:00:00.000Z")}
                                maxDate={new Date()}
                                onError={(error) => {
                                    if (error !== '') setHelperText(error);
                                }}
                                onChange={(date, value) => {
                                    let isValid = isValidDate(date);
                                    if (!isValid && value && value.indexOf(charMask) == -1) {
                                        // устанавливаем date относительно маски
                                        date = moment(value, maskToParseString).toDate();
                                        isValid = isValidDate(date);
                                    }
                                    if (isValid) {
                                        const offset = (date?.getTimezoneOffset() || 0) / 60;
                                        date?.setHours(date?.getHours() - offset)
                                        if (date?.toISOString()) changeBirthday(date?.toISOString());
                                        setHelperText(null);
                                    } else {
                                        setHelperText(<Translate i18nKey={'pryaniky.datepicker.error'} />);
                                    }
                                }}

                            />
                        </MuiPickersUtilsProvider>
                        {/* </FormControl> */}
                    </FormControl>

                    <FormControl className={classes.formControlPhone}>
                        <InputLabel className={classes.labelPhone} htmlFor="formatted-text-mask-input">{i18n.t('pryaniky.profile.phone')}</InputLabel>
                        <Input
                            disabled={disableFields(user, "phone")}
                            // disabled={disabledFields.phone}
                            value={user.baseData.phone}
                            onChange={handleChange}
                            id="formatted-text-mask-input"
                            inputComponent={TextMaskCustom as any}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        {/* <InputLabel className={classes.labelPhone} htmlFor="formatted-text-mask-input">{i18n.t('pryaniky.profile.userTimeZone')}</InputLabel> */}
                        <TimeZoneSuggester
                            value={user.extData.userTimeZone || defaultTimeZone}
                            onChange={(e, value) => {
                                const prepairedValue = Array.isArray(value) ? value.join(',') : value;

                                if (prepairedValue) changeExtData({ userTimeZone: prepairedValue });
                            }}
                            TextFieldProps={{
                                InputLabelProps: {
                                    shrink: true
                                },
                                variant: 'standard',
                                InputProps: {
                                    startAdornment: null,
                                }
                            }}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="sex">{i18n.t('sex')}</InputLabel>
                        <Select
                            disabled={disableFields(user, "sex")}
                            // disabled={disabledFields.sex}
                            value={user.baseData.sex}
                            onChange={changeSex}
                            inputProps={{
                                name: 'sex',
                                id: 'sex',
                            }}>
                            <MenuItem key={0} value={'f'}>
                                {i18n.t('female')}
                            </MenuItem>
                            <MenuItem key={1} value={'m'}>
                                {i18n.t('male')}
                            </MenuItem>
                        </Select>
                    </FormControl>

                    {/*<FormControl className={classes.formControlBase} >
                    <TextField className={classes.formInput} label='country' value={state.profileData.country} onChange={(e: any) => changeCountry(e.target.value)} />
                    <TextField className={classes.formInput} label='region' value={state.profileData.region} onChange={(e: any) => changeRegion(e.target.value)} />
                    <TextField className={classes.formInput} label='phone' value={state.profileData.phone} onChange={(e: any) => changePhone(e.target.value)} />
            </FormControl>*/}

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="language">{i18n.t('language')}</InputLabel>
                        <Select
                            disabled={disableFields(user, "language")}
                            // disabled={disabledFields.language}
                            value={user.baseData.language}
                            onChange={changeLanguage}
                            inputProps={{
                                name: 'age',
                                id: 'language',
                            }}>
                            {languages.map((lang: string) => <MenuItem key={lang} value={lang}>
                                {i18n.t('pryaniky.languages.' + lang)}
                            </MenuItem>)}
                            {/* <MenuItem key={0} value={'ru'}>
                                {i18n.t('russian')}
                            </MenuItem>
                            <MenuItem key={1} value={'en'}>
                                {i18n.t('english')}
                            </MenuItem> */}
                        </Select>
                    </FormControl>


                    <FormControl component="fieldset" className={classes.formControl}>
                        <TextField
                            disabled={disableFields(user, "email")}
                            fullWidth
                            // disabled={disabledFields.eMail}
                            label={i18n.t('pryaniky.profile.email')}
                            value={user.baseData.eMail}
                            onChange={(e: any) => changeEmail(e.target.value)}
                        />
                    </FormControl>

                    {isFeedbackEnabled && <FormControl component="fieldset" className={classes.formControl}>
                        <TextField fullWidth label={i18n.t('feedback_url')} value={window.location.protocol + '//' + window.location.host + '/feedback/' + user.baseData.id} />
                    </FormControl>}
                    {authUser.baseData.isAdmin && authUser.baseData.id !== user.baseData.id && (
                        <CheckboxInput
                            checked={user.baseData.isAdmin}
                            onChange={changeAdmin}
                            text={i18n.t('pryaniky.profile.isAdmin')}
                            padding="lg"
                        />
                    )}

                    <CheckboxInput
                        disabled={disableFields(user, "showMyNewsByDefault")}
                        // disabled={disabledFields.showMyNewsByDefault}
                        text={i18n.t('pryaniky.profile.alwaysMyNews')}
                        checked={user.profileData.showMyNewsByDefault}
                        onChange={changeShowNews}
                        padding="lg"
                    />

                    <div className={classes.inline}>
                        {/* <ChangePassword userId={user?.baseData?.id || userId} currentUser={authUser} /> */}
                        <Button onClick={() => changePasswordDialog({
                            userId: user?.baseData?.id || userId,
                            currentUser: authUser
                        })}>
                            <Translate i18nKey={'pryaniky.changepassword'} />
                        </Button>

                        {
                            networksSettings?.enableSubNetworks &&
                            <Button onClick={() => ChangeUserNetwork({ id: userId })}>
                                <Translate i18nKey='pryaniky.user.network.button' />
                            </Button>
                        }
                        {authUser.baseData.isAdmin && <Button className={classes.marginLeft} theme='unstyled' type='rlink' href={`/notifiSettings/${user.baseData.id}/common`}>
                            <Translate i18nKey={'pryaniky.profile.notifisettings'} />
                        </Button>}
                    </div>

                    {user.profileData.userInfoFields.result.length !== 0 && <FormControl className={classes.formControl}>
                        <FieldsRender onChangeFields={onChangeFields} userId={userId} user={user} fieldsSet={'userInfoFields'} />

                        {/* <CollapseBox
                            headerRender={(open: boolean) => (
                                <Button className={classes.collapseHeader} noBorderRadius>
                                    <span
                                        className={classes.collapseHeaderText}
                                        children={i18n.t('pryaniky.profile.additionalInfo')}
                                    />
                                    <Icon className={classes.collapseHeaderIcon} icon={open ? 'chevron-down' : 'chevron-left'} />
                                </Button>
                            )}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                                <div className={classes.root}>
                                    {categoryesKeys.map((cat) => {
                                        return <>
                                            {cat && <h4>{cat}</h4>}
                                            {fieldsCategoryes[cat].map(fildsRender('userInfoFields'))}
                                        </>
                                    })}
                                </div>
                            </MuiPickersUtilsProvider>
                        </CollapseBox> */}

                    </FormControl>}
                </div>

                <div className={classes.confirmBox}>
                    <Button
                        id="user-profile-cancleEdit"
                        // theme="unstyled"
                        className={classes.confirmBtn}
                        href={window.location.pathname}
                        type={'rlink'}
                    // onClick={editEnd}
                    >
                        {i18n.t('cancel')}
                    </Button>
                    <Button id="user-profile-saveEdit" className={classes.confirmBtn} main
                        href={window.location.pathname}
                        type={isBtnDisabledByValidate ? undefined : 'rlink'}
                        onClick={saveData}
                        disabled={isBtnDisabledByValidate}
                    >
                        {i18n.t('save')}
                    </Button>
                </div>
            </div>
        </div>
    </div>
}


export const UserEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEdit_)