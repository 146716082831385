import { compose } from '@bem-react/core';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { i18n, Translate } from 'localization';

import { cnHeader, IHeaderProps, IHeaderState } from './NewsView-Header.index';
import { Avatar } from 'uielements/src/Avatar/Avatar';

import './NewsView-Header.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { formatDateSTD } from 'utils/src/utils.dates';

const postPublicationTranslationsMap: Record<string, string> = {
  welcome: 'joined',
  achievements: 'got',
  badges: 'got',
  ideasend: 'completed',
  thanks: 'thanked',
  competitionends: 'completed',
};

const mapPostTypeToTranslationKey = (postType: string): string => {
  switch (postType) {
    case 'news':
      return postType;
    case 'competitionends':
      return 'competition';
    case 'notices':
      return 'announcement';
    default:
      return postType.replace(/s$/, '');
  }
};
export class HeaderPresenter extends React.Component<IHeaderProps, IHeaderState> {
  public el: HTMLElement | null;
  private postType = this.props.data.newsType;

  public render() {
    const customClassNames = 'horizontalPadding';
    const sex = this.props.data.user.sex === 'm' ? '' : ' female';
    return (
      <div ref={el => (this.el = el)} className={cnHeader({}, [customClassNames, this.props.className])}>
        <Button
          theme="unstyled"
          className={cnHeader('UserImg')}
          type="rlink"
          href={'/user/' + this.props.data.user.id}
          children={
            <Avatar
              imgId={this.props.data.user.imgId}
              imgUrl={this.props.data.user.imgURl}
              name={this.props.data.user.displayName}
              size={56}
            />
          }
        />
        <div className={cnHeader('Data')}>
          <div className={cnHeader('Data-Text')}>
            <UserMention
              id={this.props.data.user.id}
              name={this.props.data.user.displayName}
              className={cnHeader('UserName')}
            />
            <span>
              {[this.formatPublishedString(), ' ', this.formatPostTypeString(), ' ', this.formatPollExpirationDate()]}
            </span>
            <ReactMarkdown
              source={
                this.props.data.newsType === 'notices'
                  ? '[' + this.props.data.text + '](/news/' + this.props.data.id + ')'
                  : this.props.data.text
              }
              renderers={{
                root: 'span',
                paragraph: (d: any) => {
                  return d.children;
                },
                link: (d: any) => {
                  const splitedHref = d.href.split('/');
                  let toLink = '';
                  if (splitedHref[0] === 'http:' || splitedHref[0] === 'https:') {
                    splitedHref.forEach((e: any, i: any) => {
                      if (i > 2) toLink += '/' + e;
                    });
                  } else if (splitedHref[0] === '') {
                    splitedHref.forEach((e: any, i: any) => {
                      if (i >= 1) toLink += '/' + e;
                    });
                  } else {
                    toLink = d.href;
                  }
                  if (toLink.indexOf('//') === 0) toLink = toLink.replace('//', '/');
                  if (toLink.toLowerCase().includes('user')) {
                    const uId = splitedHref[splitedHref.length - 1];
                    return <UserMention id={uId} name={d.children} className={cnHeader('UserName')} />;
                  }
                  return (
                    <Button type="link" target="_blank" theme="unstyled" href={toLink}>
                      {d.children}
                    </Button>
                  );
                },
              }}
            />
            {this.props.data.group && this.props.data.group.pkid !== -1 ? (
              <span>
                <Button type="link" target="_blank" href={'/group/' + this.props.data.group.pkid} theme="unstyled">
                  <Icon icon="caret-right" />
                  {' ' + this.props.data.group.name}
                </Button>
              </span>
            ) : (
              undefined
            )}
          </div>
          <div className={cnHeader('Data-Date')}>{formatDateSTD(this.props.data.date, true)}</div>
        </div>
        {this.props.children}
      </div>
    );
  }

  private formatPublishedString = () => {
    const translationKeyAddition = this.props.data.user.sex === 'f' ? ' femin' : '';
    const publishedString = Object.keys(postPublicationTranslationsMap).includes(this.postType)
      ? postPublicationTranslationsMap[this.postType] + translationKeyAddition
      : 'published';
    return ' ' + i18n.t(publishedString);
  };

  private formatPollExpirationDate = () =>
    this.postType === 'polls' &&
    <>
      <Translate i18nKey='poll completion date' />: {formatDateSTD(this.props.data.pollExpires, false)}
    </>

  private formatPostTypeString = () => {
    switch (this.postType) {
      case 'polls':
      case 'news':
      case 'notices':
        return (
          <Button type="link" target="_blank" theme="unstyled" href={'/news/' + this.props.data.id}>
            {i18n.t(mapPostTypeToTranslationKey(this.postType))}
          </Button>
        );
      case 'thanks':
      case 'welcome':
        return;
      default:
        return i18n.t(mapPostTypeToTranslationKey(this.postType));
    }
  };
}

export const Header = compose()(HeaderPresenter);
// withBemMod(cnHeader(), {})
