import React, { FC, useEffect, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LerningTrack
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
// import { HelpTooltip } from 'blocks/HelpTooltip/HelpTooltip'
// import Item from './Item'
// import Item from './Item'
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { withRouter } from 'react-router-dom'
import Item from 'News/containers/NewsItem'
import Header from './Header'
import ItemView from './ItemView'
import { tTr } from 'LMSModule/utils/i18n.adaptor'

import { TrackStatWidget } from '../TrackStatWidget/TrackStatWidget'

type IPresenterProps = LerningTrack.TrackView.IPresenterProps
type IOwnProps = LerningTrack.TrackView.IOwnProps
type IDispatchProps = LerningTrack.TrackView.IDispatchProps
type IStateProps = LerningTrack.TrackView.IStateProps
type IState = LerningTrack.TrackView.IState

const LoadButton = withLoadSpin(Button)


export const Presenter: FC<IPresenterProps> = ({
    className,
    reset,
    values,
    count,
    loadFiles,
    isFinished,
    isLoading,
    keys,
    match
}) => {
    const [activeFiters, setActiveFilter] = useState<string[]>([])

    useEffect(() => {
        reset('files')
    }, [match.params.id, activeFiters])
    const loadMore = () => loadFiles({ tags: [match.params.id], count, skipCount: keys.length, ltFilters: activeFiters })
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    return <div className={cnClassName({}, ['Page', className])}>
        {/* <div className={cnClassName('Aside')}>
            aside
        </div> */}
        <Header activeFiters={activeFiters} setActiveFilter={setActiveFilter} id={match.params.id} className={cnClassName('Header')} />

        <div className={cnClassName('Section')}>
            <div className={cnClassName('SearchBox')}>
                {/* <Search search={''} /> */}
            </div>

            <TrackStatWidget
                className={cnClassName('Stats')}
                tagId={match.params.id}
            />

            <div className={cnClassName('List')}>
                <ListPlaceholder
                    className={cnClassName('Placeholder')}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={keys.length}
                    titleEmpty={tTr('files_list.empty.title')}
                    textEmpty={tTr('files_list.empty.text')}
                    textLoad={tTr('files_list.load.text')}
                >
                    {keys.map(id => <Item className={cnClassName('Item')} id={id} /*ViewComponent={ItemView}*/ />)}
                    {!isFinished && (
                        <LoadButton
                            forwardedRef={triggerRef}
                            isLoading={isLoading}
                            className={cnClassName('Trigger')}
                            onClick={isLoading ? void 0 : loadMore}>
                            {tTr('files_list.more.text')}
                        </LoadButton>
                    )}
                </ListPlaceholder>
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Presenter));
