import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
import { withBodyTypeNews } from './_type/NewsEdit-Body_type_news'
import { withBodyTypeThanks } from './_type/NewsEdit-Body_type_thanks';
import { withBodyTypePolls } from './_type/NewsEdit-Body_type_polls';
import { withBodyTypeBadges } from './_type/NewsEdit-Body_type_badges'
import { withBodyTypeEvents } from './_type/NewsEdit-Body_type_events'
import { withBodyTypeCompetitions } from './_type/NewsEdit-Body_type_competitions'
import { withBodyTypeNotices } from './_type/NewsEdit-Body_type_notices'
import { withBodyTypeIdeas } from './_type/NewsEdit-Body_type_ideas'
import { withBodyTypeFeedback } from './_type/NewsEdit-Body_type_feedback' 
import { mapStateToProps, IBodyStateProps, cnBody, IBodyProps, IBodyState } from './NewsEdit-Body.index';
import './NewsEdit-Body.scss';


export class BodyPresenter extends React.Component<IBodyProps, IBodyState> {
  // public utils: any = utils;
  public static defaultProps = {
    children: (param: any) => <div>Not Edit</div>
  }
  public el: HTMLElement | null;

  constructor(props: IBodyProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '_horizontalMargin';
    const { tag: TagName = 'div', children, className = '', post, onChangeText } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, [customClassNames, className])}>
        {children({
          text: post.text,
          post,
          onChangeText
        })}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Body = connect<IBodyStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(
  withBodyTypeNews,
  withBodyTypeThanks,
  withBodyTypePolls,
  withBodyTypeBadges,
  withBodyTypeEvents,
  withBodyTypeCompetitions,
  withBodyTypeNotices,
  withBodyTypeIdeas,
  withBodyTypeFeedback
)(BodyPresenter))