import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';

import { cnTopBadge, ITopBadgeProps, ITopBadgeState } from './NewsView-TopBadge.index';
import './NewsView-TopBadge.scss';


export class TopBadgePresenter extends React.Component<ITopBadgeProps, ITopBadgeState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  public render() {
    return (
      <div ref={el => this.el = el} className={cnTopBadge({}, [this.props.className] )}>
        <div className="PostTitleIcon-LeftText">{this.props.left}</div>
        <div className='PostTitleIcon-ValueText'>
          <div className='PostTitleIcon-ImgBox'>
            <div className='PostTitleIcon-ValueContent'>{this.props.value}</div>
          </div>
        </div>
        <div className="PostTitleIcon-RightText">{this.props.right}</div>
        {this.props.children}
      </div>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const TopBadge = compose(
  // withBemMod(cnTopBadge(), {})
)(TopBadgePresenter)
