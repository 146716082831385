import React, { FC, useEffect } from 'react';
import { usePageHistory } from './PageHistory.hooks';
import { HistoryBox, HistoryWrapper, BoxHeader, HistoryList } from './PageHistory.styled';
import { ListItemButton } from 'muicomponents/src/ListItem/ListItem';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Divider } from 'muicomponents/src/Divider';
import { Close } from 'muicomponents/src/Icons';
import { i18n, Translate } from 'localization';
import { formatDateSTD } from 'utils/src/utils.dates';
import CommonmarkRender from 'uielements/src/CommonmarkRender';

export const PageHistory: FC<{
    id: string
}> = ({
    id
}) => {

    const {
        showButton,
        hideButton,
        pageUrl,
        versions,
        showList,
        setShowList
    } = usePageHistory(id);

    useEffect(() => {
        versions && showButton();
        return () => {
            hideButton();
        };
    }, [id]);

    if(!versions || !showList) return null;

    return (
        <HistoryWrapper>
            <HistoryBox elevation={3} >
                <BoxHeader>
                    <Translate i18nKey='pryaniky.pages.versions' />
                    <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setShowList(false);
                    }}>
                        <Close />
                    </IconButton>
                </BoxHeader>
                <Divider />
                <HistoryList>
                {
                    versions.map(version => {
                        return (
                            <ListItemButton component={'a'} href={`${pageUrl}?version=${version.id}`} target='_blank' sx={{ justifyContent: 'space-between' }}>
                                <CommonmarkRender source={version.description} />
                                {formatDateSTD(version.createdAt)}
                            </ListItemButton>
                        )
                    })
                }
                </HistoryList>
            </HistoryBox>
        </HistoryWrapper>
    )
};