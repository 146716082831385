import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";


export const learninigBlock = (): IWidget<IColumn[]> => {
  
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [ 
          generateWidget({
            type: widgets.types.users + '/group',
          }),
        ],
        styles: {
          width: '72%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.quiz,
          }),
        ],
        styles: {
          width: '28%',
        },
      }),
    ],
    
  });
}