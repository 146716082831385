import React from 'react';
import { i18n, Translate } from 'localization';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { PrnUserInput } from "../../PrnUserInput/PrnUserInput";
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { InputMention } from '../../InputMention/InputMention';


// NewsInputControl
export const newsInput = (props: any) => {
    if (!props.visible) return null;
    const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
    return <InputMention
        type='news'
        value={props.data[get_variable_name(props.uischema.scope)]}
        placeholder={schema.placeholder || i18n.t('pryaniky.placeholders.select')}
        noMulti={true}
        onChange={(selected: any) => {
            props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected))
        }}
        {...(schema.props || {})} />

}
// end NewsInputControl