import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
    IGetReceivedPresentsResponse,
    IGetSendedPresentsResponse,
    IPresentData,
} from 'utils/src/requests/models/api.shop';
import { PresentItemMui } from './PresentItem/PresentItemMui';
import { cnPresentsNew, IPresentsProps } from './Presents.index';
// import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';
import { i18n, Translate } from 'localization';
import { Box, Button, Skeleton } from 'muicomponents/src';
import { Typography } from 'muicomponents/src/Typography/Typography';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { getUserReceivedPresents, getUserSendedPresents } from 'utils/src/requests/requests.shop';
import './Presents.scss';
import { calculatItemsCountByBoxSize, checkResponseStatus, ResponseError } from 'utils/src';
import { PresentsItemsBox } from './Presents.styled';
import { Avatar, AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { useDebounce, useReffedState } from 'utils/src/hooks';
import { PresentsDialog } from './PresentsDialog';

function getDefaultWidgetType(type: string | undefined): string {
    return type === '' || type === undefined ? 'receivedGifts' : type;
}

/**
 * present widgets  
 * this widget show 2 rows of element  
 * formula 2 * n, where n is number of elements by row
 */
export const Presents = withTranslation('translation')((props: IPresentsProps) => {
    const { tag: Tag = 'div', edit, className, settings } = props;
    const authUser = useSelector(getAuthUser);

    const params: {
        id: string;
        tab: string;
    } = useParams();

    const userId = useMemo(() => params.id || authUser.baseData.id, [params.id || authUser.baseData.id]);
    const userIdRef = useRef(userId);
    userIdRef.current = userId;

    const widgetType = getDefaultWidgetType(settings?.type);
    const widgetTypeRef = useRef(widgetType);
    widgetTypeRef.current = widgetType;

    const [showCount, setShowCount, showCountRef] = useReffedState<number | null>(null);
    const [presents, setPresents, presentsRef] = useReffedState<IPresentData[]>([]);
    const [loading, setLoading] = useState(false);
    const [isFinished, setIsFinished, isFinishedRef] = useReffedState(false);
    const loaderArray = useMemo(() => {
        return new Array(showCount).fill(1)
    }, [showCount]);

    const ref = useRef<HTMLDivElement>(null);

    const getItemsCountForBlock = useCallback(() => {
        if(!ref.current) return 10;
        return calculatItemsCountByBoxSize(ref.current, AvatarSize.xl) * 2;
    }, []);

    const fetchPresents = useCallback(async function() {
        let count = getItemsCountForBlock();
        setShowCount(count);
        if(isFinishedRef.current) return;
        try {
            setLoading(true);
            const fetchFunction = widgetTypeRef.current === 'sendedGifts' ? getUserSendedPresents : getUserReceivedPresents;
            const response: IGetSendedPresentsResponse | IGetReceivedPresentsResponse = await fetchFunction(
                userIdRef.current,
                true,
                {
                    count
                }
            );
            if(checkResponseStatus(response)) {
                const presents = response.data?.map((item) => ({
                    ...item,
                    id: item.orderProductId.toString(),
                    userOrder: 'orderFromUser' in item ? item.orderFromUser : item.orderForUser,
                    users: item.users.map((user) => ({
                        ...user,
                        userOrder: 'orderFromUser' in user ? user.orderFromUser : user.orderForUser,
                    })),
                }));
                setIsFinished(response.isFinished || count > presents.length);
                setPresents(presents);
            } else {
                throw new ResponseError("Rpesents data is not valid", response);   
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.warn(error);
            }
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const debouncedFetchPresents = useDebounce(fetchPresents, 1000);

    useEffect(() => {
        fetchPresents();
    }, [userId, widgetType]);

    const widgetName = useMemo(() => {
        return settings?.name?.trim() ? settings.name : <Translate i18nKey="pryaniky.widgets.presents.title" />;
    }, [settings?.name]);

    const lastWidth = useRef(0);

    const observer = useMemo(() => {
        return new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === ref.current && lastWidth.current !== entry.target.clientWidth) {
                    lastWidth.current = entry.target.clientWidth;
                    const newCount = getItemsCountForBlock();
                    if(!showCountRef.current || showCountRef.current === newCount)
                        return;
                    if(
                        showCountRef.current < newCount
                        && presentsRef.current.length < newCount
                    ) {
                        debouncedFetchPresents();
                    } else {
                        setShowCount(newCount);
                    }
                }
            }
        });
    }, []);

    useLayoutEffect(() => {
        setShowCount(getItemsCountForBlock());
        if (ref.current) {
            observer.observe(ref.current);
        }
    }, []);

    const showAllDialog = useCallback(() => {
        PresentsDialog({
            name: widgetName,
            userId: userIdRef.current, 
            widgetType,
            presents
        }).catch(() => {});
    }, [widgetName, presents, widgetType]);

    if (settings?.hideIfNoData && presents?.length === 0) {
        return null;
    }

    return (
        <Tag {...props} className={cnPresentsNew('', [className])}>
            <Typography fontWeight="700">{widgetName}</Typography>
            <PresentsItemsBox
                ref={ref}
                className={cnPresentsNew(`Items`)}
            >
                {
                    (edit || loading) ? (
                        loaderArray.map(() => {
                            return (
                                <Skeleton variant="circular" animation="wave" >
                                    <Avatar
                                        size={AvatarSize.xl}
                                    />
                                </Skeleton>
                            )
                        })
                    ) : 
                    (presents.length > 0) ? (
                        presents?.map((present, idx) => {
                            if(showCount && idx >= showCount) return null
                            return <PresentItemMui item={present} widgetType={widgetType} />;
                        })
                    ) : (
                        <>
                            <CardMedia
                                component="svg"
                                className={cnPresentsNew('EmptyList-Avatar')}
                                sx={{
                                    width: '100%',
                                }}
                                image={EmptyWidget}
                            />
                            <Typography>
                                <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
                            </Typography>
                        </>
                    )
                }
            </PresentsItemsBox>
            <div>
                {
                    (!loading && (!isFinished || (showCount || 0) < presents.length)) && <Button size='small' onClick={showAllDialog} variant='text'>
                        <Translate i18nKey='show all' />
                    </Button>
                }
            </div>
        </Tag>
    );
});
