import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
import { mapDispatchToProps } from "../../redux/connector";
// import * as widgets from "../../widgetsUtils";

import { cnNewsWidget, INewsWidgetProps, INewsWidgetState, mapActionsToProps, mapStateToProps } from './NewsWidget.index';

import './NewsWidget.scss';
// import { NewsView } from '../NewsView/NewsView';
import NewsItem from 'News/containers/NewsItem'
import { PostSkeleton } from 'blocks/Skeletons/Posts/Post-Skeleton';
// import { toast } from 'react-toastify';
// import { Widgets } from '../Widgets/Widgets';
// import { generateWidget, generateColumn } from 'i.widgets';
// import { Page } from '../Page/Page';
// import { commentsSchema } from 'redux/actions/News';

export class NewsWidgetPresenter extends React.Component<INewsWidgetProps, INewsWidgetState> {
  public el: HTMLElement | null;
  private mounted = false;

  public componentDidMount() {
    this.getNews();
  }
  // public componentWillUnmount() {}
  public componentDidUpdate(prevProps: INewsWidgetProps) {
    if (this.props.newsId !== prevProps.newsId) {
      this.getNews();
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '' } = this.props;
    // const { items, noData } = this.state;
    const additionalProps: { [s: string]: any } = {
      // hide: !this.props.news[this.props.newsId]
    }
    return (
      <TagName {...this.props} {...additionalProps} className={cnNewsWidget({}, [customClassNames, className])}>
        {
          this.props.news
            ?
            <NewsItem id={this.props.newsId} single={true} />
            // <NewsView newsId={this.props.newsId} />
            :
            <PostSkeleton />
        }
      </TagName>
    )
  }


  private getNews = () => {
    if (!this.props.news) {
      if (this.props.newsId)
        this.props.loadNewsById({ id: this.props.newsId })
      else {
        console.warn('this.props.newsId', this.props.newsId)
      }
    }
  }

}


export const NewsWidget = connect(
  mapStateToProps,
  mapDispatchToProps({ ...mapActionsToProps })
)(compose()(NewsWidgetPresenter))