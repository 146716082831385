import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Icon } from 'uielements/src'
import { cnQuizWidget, mapDispatchToProps, cnClassNameNoData, mapStateToProps, IQuizWidget } from './QuizWidget.index'
import { QuizWidgetItem } from './QuizWidgetItem/QuizWidgetItem'
import { i18n, Translate } from 'localization';
import './QuizWidget.scss'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import noDataImage from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { contextAction } from 'News/redux/actions'
import { useWidgetContext } from 'Widgets_v2/context'
import { useDidUpdateEffect } from 'utils/src/hooks'

const QuizWidgetPresenter = (props: IQuizWidget) => {
  const { tag: Tag = 'div', getQuiz, changeActiveQuizIdx, className, activQuizIdx, dataLength, edit, context } = props

  const {
    sendWidgetSignal
  } = useWidgetContext();

  useEffect(() => {
    getQuiz()
  }, [])

  useDidUpdateEffect(() => {
    sendWidgetSignal('dataUpdate');
  }, [dataLength, activQuizIdx]);

  let activeQuiz = activQuizIdx

  if (context?.settings?.hideIfNoData && !edit && !dataLength) return null
  if (!context?.settings?.hideIfNoData && dataLength === 0) return <Tag   {...props} className={cnClassNameNoData({}, [className])}>
    <div className={cnClassNameNoData('Header-Title')} children={i18n.t('pryaniky.quizwidget.title.new')} />
    <div className={cnClassNameNoData('Header-noData')}> <Avatar precentage={100} shape={'rectangle'}
      imgUrl={noDataImage}
    />
    </div>
    <span className={cnClassNameNoData('Header-Title-Text')}> {i18n.t('pryaniky.widgets.settings.type.birthdays.noData.text')} </span>
  </Tag>;

  return (
    <Tag {...props} className={cnQuizWidget({}, [props.className])}>
      <div className={cnQuizWidget('Header')}>
        <span className={cnQuizWidget('Header-Title')}>{i18n.t('pryaniky.quizwidget.title.new')}</span>
        <div className={cnQuizWidget('Navigate')}>
          <div className={cnQuizWidget('Navigate-Item')} onClick={() => activQuizIdx !== 0 && changeActiveQuizIdx(--activeQuiz)}>
            <KeyboardArrowLeftIcon color="primary" />
            {/* <Icon icon='angle-left' /> */}
          </div>
          <span className={cnQuizWidget('Header-Total')}>
            <span>{`${activQuizIdx + 1}`}</span>
            <span className={cnQuizWidget('Header-Total-Length')}>{`/${dataLength}`}</span>
            {/* {`${activQuizIdx + 1}/${dataLength}`} */}
          </span>
          <div className={cnQuizWidget('Navigate-Item')} onClick={() => activQuizIdx !== dataLength - 1 && changeActiveQuizIdx(++activeQuiz)}>
            <KeyboardArrowRightIcon color="primary" />
            {/* <Icon icon='angle-right' /> */}
          </div>
        </div>
      </div>

      <div className={cnQuizWidget('Quiz')}>
        <QuizWidgetItem />
      </div>

    </Tag>
  )
}

export const QuizWidget = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizWidgetPresenter)