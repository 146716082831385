import React from 'react';
import { connect } from 'react-redux';
import { Wiki } from '../Wiki/Wiki';
import { ILicenseProps, mapLicenseActionToProps, cnLicense } from './License.index';
import { CheckboxInput } from 'uielements/src';
import { i18n, Translate } from 'localization';
import './License.scss';

export const LicensePresenter: React.FC<ILicenseProps> = ({ tag: Tag = 'div', widget, edit, settings, updateContext, updateWidget, setStructureFromWidget, removePropFromContext, ...props }) => {

  const [ checked, setChecked ] = React.useState(false);

  const setCheckedContext = (value: boolean) => {
    const key = `license/${widget.id}`;
    updateContext('common', { [key]: value });
  }

  React.useEffect(() => {
    (settings || {}).isRequired && setCheckedContext(checked);
  }, []);

  React.useEffect(() => {
    (settings || {}).isRequired && setCheckedContext(checked);
  }, [checked]);

  React.useEffect(() => {
    !(settings || {}).isRequired && removePropFromContext('common', `license/${widget.id}`);
  }, [(settings || {}).isRequired]);

  const saveData = (d: any) => {
    widget.data = d;
    updateWidget({
      ...widget,
      HTMLWidgetContentEdit: false
    });
  };
  
  const saveWidgetData = () => setStructureFromWidget(widget.id);

  return (
    <Tag {...props} className={cnLicense({}, [props.className])}>
      <Wiki
        className={!widget.data && !edit ? 'NoText' : ''}
        saveData={saveData}
        editToggle={widget.HTMLWidgetContentEdit}
        data={widget.data || undefined}
        saveWidgetData={saveWidgetData}
        updateWidget={updateWidget}
        widget={widget}
      />
      <CheckboxInput
        checked={checked}
        onClick={() => setChecked(!checked)}
      >
        {
          (settings || {}).okText ||
          <Translate i18nKey={'pryaniky.license.confirm'} />
        }
      </CheckboxInput>
    </Tag>
  )
}

export const License = connect(
  undefined,
  mapLicenseActionToProps
)(LicensePresenter);