import { Box, CircularProgress, FileViewerNew, IconButton } from "muicomponents/src";
import { Backdrop } from "muicomponents/src/Backdrop";
import { Paper } from "muicomponents/src/Paper";
import { Close, ChatBubble, ChatBubbleOutline } from "muicomponents/src/Icons";
import { styled } from "muicomponents/src/mui/system";
import React, { ComponentProps, FC, useCallback, forwardRef, useState, useLayoutEffect, useRef, useEffect } from "react";
import { createModal } from "utils/src/DialogCreator";
import { IBodyProps } from 'utils/src/DialogCreator';
import { CommentsRender } from "News/containers/CommentsRender";
import {
  loadNewsById
} from 'News/redux/actions'
import { useDispatch, useSelector } from "react-redux";
import { useIsLoading } from "utils/src/CommonRedux/LoadIndicator";
import { getNewsById } from "News/redux/saga/selectors";
import { HeaderLayout } from "blocks/NewsTypes/common/HeaderLayout/HeaderLayout";
import { Avatar, Button } from "uielements/src";
import { UserMention } from "uielements/src/UserMention/UserMention";
import { createNewsDate } from "News/utils/utils";
import { useResizeObserver } from "muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher";
import { Slide, Collapse, TransitionGroup, duration } from 'muicomponents/src/Transitions/Transitions';
import { useReffedState } from "utils/src/hooks";
import { Tooltip } from "muicomponents/src/Tooltip";
import { i18n, Translate } from 'localization';
import { FilePreviewActionsIconButton } from "muicomponents/src/FileViewerNew/Item/FileViewerItem.styled";
import { addHandler, removeHandler, SignalHandler } from "utils/src/signals";
import { FilePreviewSignal, FilePreviewSignalAction } from "./FilePreview.signal";

const baseCommentsBoxWidth = 326;
let commentsBoxWidth = 326;
const pixelsForCloseButton = 64;

const CloseIconButton = styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    color: '#9E9E9E',
    ['&:hover']: {
        color: '#fff'
    }
}) as typeof IconButton;

const StyledPaper = styled(Paper)({
    height: '100vh'
}) as typeof Paper;

const CommentsBox = styled(Box)({
    height: '100%',
    width: `${commentsBoxWidth}px`,
    display: 'flex',
    flexDirection: 'column',
    ['& .CommentsRender']: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        ['& > div']: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            flex: 1,
        },
        ['& .CommentsLayout']: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            ['& .CommentsLayout-Comments']: {
                flex: 1,
                overflowY: 'auto'
            },
            ['& .CommentsLayout-Form']: {
                marginTop: 'auto'
            }
        }
    },
    ['& .ActionButton .Button:not(.BtnLikeNum)']: {
        visibility: 'hidden',
        width: '24px'
    },
    ['& .ActionButton .Button .Icon']: {
        visibility: 'visible',
    }
}) as typeof Box;

const StyledHeaderLayout = styled(HeaderLayout)({
    padding: '14px 16px',
    borderBottom: '1px solid #0000001F'
}) as typeof HeaderLayout;

let onCloseFunction: {
    handleClose: IBodyProps['handleClose']
}  = {
    handleClose: () => {}
};
const getOnClose = () => {
    return onCloseFunction.handleClose;
};

const CustomPaper = forwardRef<HTMLDivElement, ComponentProps<typeof Paper>>((props) => {
    return (
        <>
            <CloseIconButton onClick={() => getOnClose()()}>
                <Close />
            </CloseIconButton>
            <StyledPaper {...props}>
                {props.children}
            </StyledPaper>
        </>
    );
});

const Comments: FC<{
    newsId: string;
}> = ({
    newsId
}) => {

    const dispatch = useDispatch();

    const loading = useIsLoading([loadNewsById({ id: newsId }).type]);

    const news = useSelector(getNewsById(newsId));

    useLayoutEffect(() => {
        if(newsId && !news)
            dispatch(loadNewsById({
                id: newsId
            }));
    }, [newsId, news]);

    const {
        user,
        date,
        isDraft = false,
        changeDate,
        publishAt
    } = news || {};

    const dateStr = date ? createNewsDate({
        date,
        isDraft,
        changeDate,
        publishAt
    }) : ''

    return (
        <CommentsBox sx={{
            width: `${commentsBoxWidth}px`,
            ...( 
                loading
                ? {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
                : undefined
            )
        }}>
            {
                loading
                ? <CircularProgress />
                : <>
                    {
                        !user
                        ? undefined
                        : <StyledHeaderLayout
                            compact
                            // className={cnClassName('HeaderLayout')}
                            type={'post'}
                            left={
                                user.isDeleted
                                ? <Avatar
                                    responsive
                                    name={user.displayName}
                                    size={40}
                                    />
                                : <Button theme='unstyled' type='rlink' href={'/user/' + user.id} >
                                    <Avatar
                                        responsive
                                        imgUrl={user.imgUrl}
                                        imgId={user.imgId}
                                        name={user.displayName}
                                        size={40}
                                    />
                                </Button>
                            }
                            bottom={dateStr}
                        >
                            {
                                user.isDeleted
                                ? <span>{user.displayName}</span>
                                : <UserMention id={user.id} name={user.displayName} className={('UserName')} />
                            }
                        </StyledHeaderLayout>
                    }
                    <CommentsRender id={newsId} />
                </>
            }
            
        </CommentsBox>
    );
};







const FilePreviewPr: FC<ComponentProps<typeof FileViewerNew> & Pick<IBodyProps, 'handleClose'> & {
    filesNewsIds: {[key: ComponentProps<typeof FileViewerNew>['files'][number]['id']]: string},
}> = ({
    files: filesProps,
    filesNewsIds: filesNewsIdsProps,
    initFileIndex,
    handleClose,
    additionalRightControls,
    ...props
}) => {

    const [files, setFiles] = useState(filesProps);
    const [filesNewsIds, setFilesNewsIds] = useState(filesNewsIdsProps);
    const [isLoading, setIsLoading] = useState(false);

    const updateData = useCallback<SignalHandler>((action, value: { files: typeof files, filesNewsIds: typeof filesNewsIds }) => {
        const typedAction = action as 'loading' | "loaded";
        switch(typedAction) {
            case FilePreviewSignalAction.loading:
                setIsLoading(true);
                break;
            case FilePreviewSignalAction.loaded:
                setIsLoading(false);
                setFiles(value.files);
                setFilesNewsIds(value.filesNewsIds);
                break;
        }
    }, []);

    useLayoutEffect(() => {
        addHandler(FilePreviewSignal, updateData);
    }, []);

    useEffect(() => {
        return () => {
            removeHandler(FilePreviewSignal, updateData);
        }
    }, [])

    useEffect(() => {
        return () => {
            commentsBoxWidth = baseCommentsBoxWidth;
        };
    }, []);

    onCloseFunction.handleClose = handleClose;

    const [currentItemNewsId, setCurrentItemNewsId] = useState(filesNewsIds[files[initFileIndex || 0].id]);

    const [isMoblie, setIsMobile] = useState(false);
    const manuallyToggled = useRef(false);
    const [collapsedIn, setCollapsedIn, collapsedInRef] = useReffedState(true);

    const resizeHandler = useCallback<Parameters<typeof useResizeObserver>['0']>((entry, elem) => {
        if(!elem) return;
        if(elem.clientWidth < (commentsBoxWidth * 2)) {
            if(collapsedInRef.current && !manuallyToggled.current) {
                setCollapsedIn(false);
            }
            setIsMobile(true);
        } else {
            if(elem.clientWidth >= ((commentsBoxWidth * 2) + commentsBoxWidth)) {
                if(!collapsedInRef.current) setCollapsedIn(true);
                manuallyToggled.current = false;
                setIsMobile(false);
            }
        }
    }, []);

    const resizeRef = useResizeObserver(resizeHandler);

    const toggleIsCollapsed = useCallback(() => {
        manuallyToggled.current = true;
        setCollapsedIn(!collapsedInRef.current);
    }, []);

    const additionalRightControlsHandler = useCallback((item: (typeof files)[number]) => {
        const view = additionalRightControls?.(item);
        return <>
            {view}
            {
                isMoblie && currentItemNewsId
                && <Tooltip title={<Translate i18nKey="comments" />} disableInteractive>
                    <FilePreviewActionsIconButton
                        className={'Comments'}
                        onClick={toggleIsCollapsed}
                    >
                        {
                            collapsedIn
                            ? <ChatBubble />
                            : <ChatBubbleOutline />
                        }
                        
                    </FilePreviewActionsIconButton>
                </Tooltip>}
        </>
    }, [currentItemNewsId, additionalRightControls, isMoblie, collapsedIn]);

    useLayoutEffect(() => {
        if(!resizeRef.current?.parentElement) return ;
        if(resizeRef.current.parentElement.clientWidth < commentsBoxWidth + pixelsForCloseButton) {
            commentsBoxWidth = commentsBoxWidth + (resizeRef.current.parentElement.clientWidth - (commentsBoxWidth + pixelsForCloseButton));
        }
    }, []);

    return (
        <>
            <Box
                ref={resizeRef}
                sx={{
                    width: currentItemNewsId ? `calc(100% - ${!collapsedIn ? 0 : commentsBoxWidth}px)` : '100%',
                    transition: `width ${duration.standard}ms`
                }}
            >
                <FileViewerNew
                    {...props}
                    files={files}
                    isLoading={isLoading}
                    initFileIndex={initFileIndex}
                    onActiveChange={(file, index, ...args) => {
                        setCurrentItemNewsId(filesNewsIds[files[index || 0].id])
                        props.onActiveChange?.(file, index, ...args);
                    }}
                    additionalRightControls={additionalRightControlsHandler}
                />
            </Box>
            <Collapse
                in={collapsedIn}
                orientation="horizontal"
            >
            {
                currentItemNewsId &&
                <Comments newsId={currentItemNewsId}/>
            }
            </Collapse>
        </>
    );
};

export const FilePreview = createModal(FilePreviewPr, {
    maxWidth: false,
    fullWidth: true,
    fullHeight: true,
    PaperComponent: CustomPaper,
    PaperProps: {
        sx: {
            flexDirection: 'row',
            maxWidth: '1920px'
        }
    }
});