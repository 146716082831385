import React from 'react'
import './QuizPassing.scss'
import { cnQuizPassing, IQuizPassing, mapStateToProps, mapDispatchToProps } from './QuizPassing.index'
import { Button } from 'uielements/src'
// import { PostContentRender } from '../../PostContentRender/PostContentRender'
import MDRender from 'uielements/src/CommonmarkRender'
import { connect } from 'react-redux'
import { i18n, Translate } from 'localization';
import { AnswerItem } from './AnswerItem/AnswerItem'
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton';


const QuizPassingPresenter = ({
  data,
  activeQuizIdx,
  totalQuestions,
  nextActiveQuestion,
  answersIds,
  sendAnswer,
  answered,
  quizId,
  setFinishedQiuz,
  highlightCorrectness,
  textColor,
  setServerAnswered
}: IQuizPassing) => {


  // const [serverAnswered, setServerAnswered]=React.useState(false)

  const onSendAnswer = () => {
    if (highlightCorrectness) {
      sendAnswer(quizId)
    } else {
      sendAnswer(quizId)
      activeQuizIdx + 1 !== totalQuestions ? nextActiveQuestion() : setFinishedQiuz()
    }
  }

  return (
    <div className={cnQuizPassing()}>
      <div className={cnQuizPassing('Question')}>
        <div>{activeQuizIdx + 1}/{totalQuestions}</div>
        <span style={{ color: textColor }}>
          <MDRender source={data.text || ''} textCut={false} />
        </span>
        {data.qtype === 'Multi' && <div className={cnQuizPassing('Question-Multi')}>{i18n.t('pryaniky.quiz.manyAnswers')}</div>}
      </div>

      <div className={cnQuizPassing('Answers')}>
        {data.answers.map((item: any, idx: number) => {
          return (
            <AnswerItem item={item} qtype={data.qtype} highlightCorrectness={highlightCorrectness} />
          )
        })}
      </div>

      <div className={cnQuizPassing('Buttons')}>
        {!answered && <Button
          type='button'
          main
          onClick={() => onSendAnswer()}
          disabled={!answersIds.length}
        >
          {i18n.t('pryaniky.quiz.toAnswer')}
        </Button>}
       {highlightCorrectness && answered && <LoadingButton
                    variant={'contained'}
                    loading={!setServerAnswered}
                    disabled={!answered && true}
                    onClick={() => activeQuizIdx + 1 !== totalQuestions ? nextActiveQuestion() : setFinishedQiuz()}
                >
                     {activeQuizIdx + 1 !== totalQuestions ? i18n.t('pryaniky.quiz.next') : i18n.t('pryaniky.quiz.end')}
                </LoadingButton>}
        {/* {highlightCorrectness && answered && <Button
          type='button'
          main
          disabled={(!answered && true) || !setServerAnswered}
          noBorder={false}
          onClick={() => activeQuizIdx + 1 !== totalQuestions ? nextActiveQuestion() : setFinishedQiuz()}
        >
          {activeQuizIdx + 1 !== totalQuestions ? i18n.t('pryaniky.quiz.next') : i18n.t('pryaniky.quiz.end')}
        </Button>} */}
      </div>
    </div>
  )
}

export const QuizPassing = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizPassingPresenter)