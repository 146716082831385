import { ILongreadPropsType, mapStateToProps, TStateProps } from './Longread.index'
import React, { FC, memo, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import './Longread.scss'
import { withNewsContext } from 'News/contexts/news'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import TextRender from 'News/containers/TextRender'
import ContentLayout from 'News/components/ContentLayout'
import { ViewComponent, IAdditionalField, prepareFromServer, prepareToServer } from 'uielements/src/FieldRender'
import { Button } from 'uielements/src'
import { i18n, Translate } from 'localization';
import MDRender from 'uielements/src/CommonmarkRender'

export const LongreadPresenter: FC<ILongreadPropsType & TStateProps> = ({ additionalFields, isSingle, text }) => {
    const [full, setFull] = useState(isSingle)
    const fields = useMemo(() => prepareFromServer(additionalFields?.additionalFieldsValues || []), [])
    const changeFull = useCallback(() => setFull(!full), [full])
    return <NewsView className={'type-5'}>
        <ContentLayout>
            {full ? <ViewComponent fields={fields} /> : <MDRender textCut={false} source={text} />}

            <Button main onClick={changeFull}>{i18n.t(full ? 'pryaniky.post.longread.showFull' : 'pryaniky.post.longread.showShort')}</Button>
        </ContentLayout>
    </NewsView>
}

export const Longread = withNewsContext<ILongreadPropsType>(connect(
    mapStateToProps
)(memo(LongreadPresenter)), ['newsId', 'isSingle']);


export const initLongread = () => ComponentInjector.getInstance().addNode('news_types', <Longread />, 'longread')