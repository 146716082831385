import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import { Icon, HelpTooltip } from 'uielements/src'
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import FormControl from '@material-ui/core/FormControl';
import { i18n, Translate } from 'localization';

type IPresenterProps = News.MentionDrop.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    type = 'users',
    label = 'Select user',
    value = null,
    onChange
}) => {
    return <PopupMenu
        allowInsideClick
        className={cnClassName({}, [className])}
        horizontal={'auto'}
        btnContent={
            <HelpTooltip
                showOnHowerHelp
                hideDelay={300}
                showDelay={150}
                inlineBlock
                className={cnClassName('Helptooltip')}
                help={i18n.t('pryaniky.createPost.actions.mention')}
            >
                <Icon icon="at-sign" className={cnClassName("Button-Icon", { bell: true })} />
            </HelpTooltip>
        }
    >
        <FormControl className={cnClassName('Form')}>
            <Autocomplete
                multiple={false}
                fullWidth
                excludeMe={false}
                type={type}
                label={label}
                value={value}
                onChange={onChange}
            />
        </FormControl>
    </PopupMenu>
}

export default memo(Presenter);
