import React from 'react';
import { connect } from 'react-redux'
import { IEditPositionsProps, mapEditPositionsStateToProps, mapEditPositionsDispatchToProps, cnEditPositions, IPositionElement } from './EditPositions.index'
import { Icon, Avatar, Button } from 'uielements/src';
import './EditPositions.scss'
import { InputMention } from '../../../../blocks/InputMention/InputMention';
import { i18n, Translate } from 'localization';
import Dropdown from 'uielements/src/MaterialElements/Dropdown/Dropdown';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import InputMaterial from 'uielements/src/MaterialElements/Input/Input';
import { newPositions } from '../../../../redux/sagas/orgchart/reducer'
import { v1 as uuid } from 'uuid'
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { Attachments } from 'uielements/src/MaterialElements/Attachments/Attachments';
import { Attachments as AttachmentsView } from 'uielements/src/Attachments/Attachments';
import VacancyOpenNoUser from 'assets/svg/VacancyOpenNoUser.svg'
// import { IPositionElement } from '../../../../../../utils/src/requests/models/admin.orgchart'
const noopArray: any[] = []
const noopFn = () => { }

let positionType = [
    { title: i18n.t('pryaniky.orgchart.positionType.fullTimeEmployee'), value: 'fullTimeEmployee', },
    { title: i18n.t('pryaniky.orgchart.positionType.halfTimeWorker'), value: 'halfTimeWorker', },
    { title: i18n.t('pryaniky.orgchart.positionType.temporaryWorker'), value: 'temporaryWorker', },
    { title: i18n.t('pryaniky.orgchart.positionType.sickLeave'), value: 'sickLeave', },
    { title: i18n.t('pryaniky.orgchart.positionType.decree'), value: 'decree', },
    { title: i18n.t('pryaniky.orgchart.positionType.trainee'), value: 'trainee', },
]

const vacanyId = uuid();
const EditPositionsPresenter: React.FC<IEditPositionsProps> = ({
    // unitData,
    postionData,
    isShown,
    onClose,
    // postionIds,
    unitId,
    posId,
    addLeader,
    leaderAddVacancy,

    changePositionDataOrgchart,
    addNewPositions,
    saveEditNewPositions,
    addNewLeaderPositions,
    saveLeaderEditNewPositions,
    newPositionDataKill
}) => {
    const [showVacancyAdd, setShowVacancyAdd] = React.useState(false)
    // const [newPosition, setNewPosition] = React.useState({ ...newPositions,  unitId, id: uuid(), isVacancyOpen: leaderAddVacancy ? true : false, vacancy: { ...newPositions.vacancy, id: vacanyId } })
    const [newPosition, setNewPosition] = React.useState({ ...newPositions, id: uuid(), unitId, isVacancyOpen: leaderAddVacancy ? true : false, vacancy: { ...newPositions.vacancy, id: vacanyId } })
    const [positions, setPositions] = React.useState<IPositionElement[]>([])




    const handleCancel = () => {
        onClose();
    };

    const handelOpen = () => {
        setShowVacancyAdd(!showVacancyAdd);
    }

    const sendPosition = () => {
        if (addLeader) {
            if (positions.length > 0) {
                positions.map((pos: any) => addNewLeaderPositions({ unitId, rootPositions: pos, rootPositionId: pos.id } as any))
            }
            if (positions.length === 0) {
                addNewLeaderPositions({ unitId, rootPositions: newPosition, rootPositionId: newPosition.id } as any);
            }
        }
        //  addNewLeaderPositions({ unitId, rootPositions: newPosition, rootPositionId: newPosition.id } as any);
        if (!addLeader) {
            if (positions.length > 0) {
                positions.map((pos: any) => addNewPositions({ unitId, position: pos, positionId: pos.id } as any))
            }
            if (positions.length === 0) {
                // setNewPosition({...newPositions, id: uuid()})
                addNewPositions({ unitId, position: newPosition, positionId: newPosition.id } as any);
            }

        }


        onClose();
    }

    return (
        posId && postionData ? <div className={cnEditPositions("")}>
            <div className={cnEditPositions('Position')}>
                <InputMention
                    onChange={(value: any) => changePositionDataOrgchart({ ...postionData, id: posId, employee: value || null })}
                    className={cnEditPositions("Input")}
                    value={postionData.employee || {}}
                    noMulti
                    type="users"
                    icon="user"
                    placeholder={i18n.t("pryaniky.orgchart.vacancy.choose.user")}
                />
            </div>
            <div className={cnEditPositions('Position')}>
                <div className={cnEditPositions('Position-Type')}>
                    <Dropdown

                        label={i18n.t('pryaniky.orgchart.positionType.title')}
                        value={postionData.type.name}
                        onChange={(value: string) => changePositionDataOrgchart({ ...postionData, id: posId, type: { name: value, description: value } })}
                        items={positionType}

                    />

                </div>
                <div className={cnEditPositions('Position-Description')}>
                    {postionData.type.description === "fullTimeEmployee" && <span>{i18n.t('pryaniky.orgchart.positionType.description.fullTimeEmployee')} </span>}
                    {postionData.type.description === "halfTimeWorker" && <span>{i18n.t('pryaniky.orgchart.positionType.description.halfTimeWorker')} </span>}
                    {postionData.type.description === "temporaryWorker" && <span>{i18n.t('pryaniky.orgchart.positionType.description.temporaryWorker')} </span>}
                    {postionData.type.description === "sickLeave" && <span>{i18n.t('pryaniky.orgchart.positionType.description.sickLeave')} </span>}
                    {postionData.type.description === "decree" && <span>{i18n.t('pryaniky.orgchart.positionType.description.decree')} </span>}
                    {postionData.type.description === "trainee" && <span>{i18n.t('pryaniky.orgchart.positionType.description.trainee')} </span>}
                </div>

            </div>


            <Checkbox
                className={cnEditPositions("AddPosition-VacancyCheckbox")}
                value={postionData.isVacancyOpen}
                onChange={(isVacancyOpen) => changePositionDataOrgchart({ ...postionData, id: posId, isVacancyOpen })}
                label={i18n.t('pryaniky.orgchart.vacancy.open')} />


            {postionData.isVacancyOpen && <div className={cnEditPositions('Vacancy')}>

                <InputMaterial
                    className={cnEditPositions("Vacancy-Name")}
                    value={postionData.vacancy?.description}
                    onChange={description => changePositionDataOrgchart({ ...postionData, vacancy: { ...postionData.vacancy, description } } as any)}
                    placeholder={i18n.t('pryaniky.orgchart.Vacancy.name')}
                />
                <div className={cnEditPositions('UploadVacancy')}>
                    <Attachments
                        className={cnEditPositions('UploadVacancy-UploadButton')}
                        onLoad={(file: any) => {
                            file[0]?.id && changePositionDataOrgchart({ ...postionData, id: posId, vacancy: { ...postionData.vacancy, attachments: file } } as any)
                        }}
                        showPrewiew={false}
                        // showLoading={loading}
                        buttonText={<Icon icon='clip' />}

                    />


                    {postionData.vacancy?.attachments && <AttachmentsView className={cnEditPositions('Attachments', ['horizontalPadding'])}
                        files={postionData.vacancy?.attachments ? postionData.vacancy.attachments : noopArray}
                        onDelete={(vacancy: any) => changePositionDataOrgchart({ ...postionData, id: posId, vacancy: { ...vacancy, attachments: [] } })}
                        state='create' />}
                </div>
            </div>
            }
            <div className={cnEditPositions("AddPosition-Actions")}>
                <Button className={cnEditPositions("AddPosition-Actions-CancelBTN")} onClick={handleCancel} noBorder noBackground padding="lg">
                    {i18n.t("pryaniky.orgchart.vacancy.cancel")}
                </Button>
                <Button main className={cnEditPositions("AddPosition-Actions-SaveBTN")} onClick={() => {
                    addLeader ? saveLeaderEditNewPositions({ rootPositions: postionData, rootPositionId: postionData.id }) :
                        saveEditNewPositions({ position: postionData, positionId: postionData.id })
                    onClose();
                }} padding="md" >
                    {i18n.t("pryaniky.orgchart.vacancy.add")}
                </Button>

            </div>
        </div> :
            <div className={cnEditPositions("")}>

                <div className={cnEditPositions('Position')}>

                    {!leaderAddVacancy && <InputMention
                        onChange={(value: any) => {
                            setPositions(value.map((user: any) => ({ ...newPosition, employee: user, id: uuid() })))
                            setNewPosition({ ...newPosition, employee: value })
                        }}
                        className={cnEditPositions("Input")}
                        value={positions.map((pos: any) => pos.employee) || []}
                        // noMulti
                        type="users"
                        icon="user"
                        placeholder={i18n.t("pryaniky.orgchart.vacancy.choose.user")}
                    />}
                    <div className={cnEditPositions('Position-Type')}>
                        <Dropdown

                            label={i18n.t('pryaniky.orgchart.positionType.title')}
                            value={newPosition.type.name}
                            onChange={(value: string) => {
                                setPositions(positions.map((pos: any) => ({ ...pos, type: { name: value, description: value } })))
                                setNewPosition({ ...newPosition, type: { name: value, description: value } })
                            }}
                            items={positionType}

                        />

                    </div>
                    <div className={cnEditPositions('Position-Description')}>
                        {newPosition.type.description === "fullTimeEmployee" && <span>{i18n.t('pryaniky.orgchart.positionType.description.fullTimeEmployee')} </span>}
                        {newPosition.type.description === "halfTimeWorker" && <span>{i18n.t('pryaniky.orgchart.positionType.description.halfTimeWorker')} </span>}
                        {newPosition.type.description === "temporaryWorker" && <span>{i18n.t('pryaniky.orgchart.positionType.description.temporaryWorker')} </span>}
                        {newPosition.type.description === "sickLeave" && <span>{i18n.t('pryaniky.orgchart.positionType.description.sickLeave')} </span>}
                        {newPosition.type.description === "decree" && <span>{i18n.t('pryaniky.orgchart.positionType.description.decree')} </span>}
                        {newPosition.type.description === "trainee" && <span>{i18n.t('pryaniky.orgchart.positionType.description.trainee')} </span>}
                    </div>
                </div>


                <Checkbox
                    className={cnEditPositions("AddPosition-VacancyCheckbox")}
                    value={newPosition.isVacancyOpen}
                    onChange={(isVacancyOpen) => {
                        setPositions(positions.map((pos: any) => ({ ...pos, isVacancyOpen: isVacancyOpen })))
                        setNewPosition({ ...newPosition, isVacancyOpen })
                    }}
                    label={i18n.t('pryaniky.orgchart.vacancy.open')} />

                {newPosition.isVacancyOpen &&
                    <div className={cnEditPositions('Vacancy')}>
                        <InputMaterial
                            className={cnEditPositions('Vacancy-Name')}
                            value={newPosition.vacancy.description}
                            onChange={description => {
                                setPositions(positions.map((pos: any) => ({ ...pos, vacancy: { ...pos.vacancy, description } })));
                                setNewPosition({ ...newPosition, vacancy: { ...newPosition.vacancy, description } } as any)
                            }}
                            placeholder={i18n.t('pryaniky.orgchart.Vacancy.name')}
                        />
                        <div className={cnEditPositions('UploadVacancy')}>
                            <Attachments
                                className={cnEditPositions('UploadVacancy-UploadButton')}
                                onLoad={(file: any) => {
                                    file[0]?.id && setPositions(positions.map((pos: any) => ({ ...pos, vacancy: { ...pos.vacancy, attachments: file } || null })));
                                    file[0]?.id && setNewPosition({ ...newPosition, vacancy: { ...newPosition.vacancy, attachments: file } || null })
                                }}
                                showPrewiew={false}
                                // showLoading={loading}
                                buttonText={i18n.t('pryaniky.orgchart.uploudVacancy')}
                            // buttonText={leaderAddVacancy ? i18n.t('pryaniky.orgchart.uploudVacancy') : <Icon icon='clip' />}

                            />


                            {newPosition.vacancy?.attachments && <AttachmentsView className={cnEditPositions('Attachments', ['horizontalPadding'])}
                                files={newPosition.vacancy?.attachments ? newPosition.vacancy.attachments : noopArray}
                                onDelete={(vacancy: any) => {
                                    setPositions(positions.map((pos: any) => ({ ...pos, vacancy: { ...pos.vacancy, attachments: [] } })));
                                    setNewPosition({ ...newPosition, vacancy: { ...newPosition.vacancy, attachments: [] } })
                                }}
                                state='create' />}
                        </div>
                    </div>
                }
                <div className={cnEditPositions("AddPosition-Actions")}>
                    <Button className={cnEditPositions("AddPosition-Actions-CancelBTN")} onClick={handleCancel} noBorder noBackground padding="lg">
                        {i18n.t("pryaniky.orgchart.addpositions.actions.cancel")}
                    </Button>
                    <Button main className={cnEditPositions("AddPosition-Actions-SaveBTN")} onClick={sendPosition}
                        padding="md" >
                        {i18n.t("pryaniky.orgchart.vacancy.add")}
                    </Button>
                </div>
            </div>

    )
}

export const EditPositions = connect(
    mapEditPositionsStateToProps,
    mapEditPositionsDispatchToProps
)(EditPositionsPresenter)