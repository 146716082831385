import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { i18n, Translate } from 'localization';
import { Selectize, Button, Select } from 'uielements/src'
import { InputMention } from 'blocks/InputMention/InputMention'
import './Filters_type_workflows.scss'
import { } from 'query-string'
import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter } from './Filters_type_workflows.index'
import { Toggle } from 'uielements/src/Toggle/Toggle'
import { cnFilters } from '../Filters.index'
import { getAllStatuses } from 'utils/src/requests/requests.news.workflows';


const defaultMention = {
  tag: undefined,
  author: undefined,
  user: undefined
}

const Presenter: React.FC<IFiltersProps> = ({ context, changeContextv3, ...props }) => {
  
  const isPreview = window.location.href.indexOf('settingsPreview=true') !== -1;
  const Tag = props.tag || 'div'
  const isKanban = context.timelineType === 'kanban';
  const contextVariables = props.relations;

  const clearContext = () => {
    setInputMention(defaultMention);
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { tagIds: undefined, userIds: undefined, authorIds: undefined, moderation: undefined }
      return acc
    }, {} as { [s: string]: any }))
  }

  const [inputMention, setInputMention] = useState(defaultMention)

  const [sortData, setSortData] = useState(
    {
      name: '',
      elements: [
        {
          id: '111',
          title: i18n.t('pryaniky.filter.sort.lastcommentdate'),
          type: 'order',
          value: 'lastcommentdate',
          sort: "down",
        },
        {
          id: '222',
          title: i18n.t('pryaniky.filter.sort.publicationdate'),
          type: 'order',
          value: 'publicationdate',
          sort: "down",
        },
        {
          id: '333',
          title: i18n.t('pryaniky.filter.sort.statuschangedate'),
          type: 'order',
          value: 'statuschangedate',
          sort: "down",
        },
        {
          id: '555',
          title: i18n.t('pryaniky.filter.sort.likescount'),
          type: 'order',
          value: 'likescount',
          sort: "down",
        },
      ],
      sortBy: [
        {
          id: '1',
          type: 'sort',
          title: i18n.t('pryaniky.filter.display.desc'),
          value: 'desc',
        },
        {
          id: '2',
          type: 'sort',
          title: i18n.t('pryaniky.filter.display.asc'),
          value: 'asc',
        },
      ],
    },
  )

  useEffect(() => {
    if(isKanban) clearContext();
  }, [isKanban])

  const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    if (context.workflowId && !statuses.length) {
      getAllStatuses(context.workflowId)
        .then((res) => setStatuses(res.data?.map((el: any) => ({
          id: el.id,
          title: el.name,
          value: el.id,
        }))))
    }
  }, [context.workflowId])

  const [stateSelected, setStateSelected] = useState(
    {
      id: '222',
      title: i18n.t('pryaniky.filter.sort.publicationdate'),
      type: 'order',
      value: 'publicationdate',
    })
  
  if (isKanban || isPreview) return null;

  const handleChangeStatuses = (selected: any) => {
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = {
        statusGuidIds: (context.statusGuidIds || []).includes(selected.id) ?  
        context.statusGuidIds.filter((item: string) => item !== selected.id) :
        [...(context.statusGuidIds || []), selected.id]
      }
      return acc
    }, {} as { [s: string]: any }))
  }

  const checkedHandler = (type: 'moderation', data: boolean) => {
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: !data }
      return acc
    }, {} as { [s: string]: any }))
  }

  const handleChangeInputMention = (type: string, data: any) => {  
    const key = type.slice(0, type.length - 3) // 3 - Ids
    setInputMention({ ...inputMention, [key]: data });
    const dataIds = data && data?.map((item: any) => item.id)

    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: dataIds }
      return acc
    }, {} as { [s: string]: any }))
  }

  const changeSort = (selected: any)=>{
    setSortData({
      ...sortData,
      elements: sortData.elements.map((el) => el.id === selected.id ? { ...el, sort: selected.sort === 'down' ? 'up' : 'down' } : el)
    });

    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { sort: selected.sort === 'down' ?  'asc':'desc'}
      return acc
    }, {} as { [s: string]: any }))
  }

  const handleChangeSort = (selected: any) => {
    setStateSelected(selected)
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [selected.type]: selected.value, ...(selected.sort ? { sort: selected.sort === 'up' ? 'asc':'desc'} : {}) }
      return acc
    }, {} as { [s: string]: any }))
  }
  

  return (
    <>
      {/* @ts-ignore */}
      <Tag data={sortData} tag='div' context={'workflows'} relations={[]} {...props} className={cnFilters({}, [props.className])} >

        <h3 className={cnFilter('Title', ['BottomLine'])}>{i18n.t('pryaniky.filter.sort.title')}</h3>
        <Selectize onSortClick={changeSort}  onClick={handleChangeSort} active={stateSelected.id} name={sortData.name} elements={sortData.elements || []}  ></Selectize>

      </Tag>
      {/* @ts-ignore */}
      <Tag data={{}} tag='div' context={'ideas'} relations={[]} {...props} className={cnFilters({}, [props.className])} >
        <h3 className={cnFilter('Title', ['BottomLine'])}>{i18n.t('pryaniky.post.workflows.filters.title')}</h3>

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.post.workflows.filters.statuses')}</h4>
        <Selectize
          onClick={handleChangeStatuses}
          active={context.statusGuidIds}
          elements={statuses}
          multiple
        />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.post.workflows.filters.tags')}</h4>
        <InputMention className={cnFilter('Tags', ['BottomLine'])} type='tags' placeholder={i18n.t('pryaniky.filter.tags.placeholder')} onChange={handleChangeInputMention.bind(null, 'tagIds')} value={inputMention.tag} />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.post.workflows.filters.responsible')}</h4>
        <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} excludeMe={false} onChange={handleChangeInputMention.bind(null, 'userIds')} value={inputMention.user} />

        <h4 className={cnFilter('Subtitle')}>{i18n.t('pryaniky.post.workflows.filters.author')}</h4>
        <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={i18n.t('pryaniky.filter.colleague')} onChange={handleChangeInputMention.bind(null, 'authorIds')} value={inputMention.author} />

        <div className={cnFilter('Toggle')}>
          <h4>{i18n.t('pryaniky.filter.moderation')}</h4>
          <Toggle checked={context.moderation} onChange={() => checkedHandler('moderation', context.moderation)} />
        </div>

      </Tag>
    </>
  )
}

export const FiltersWorkflows = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(Presenter)
