import { ITasksEditorProps, ITasksEditorState } from './Editor.index'
import { cnTasksEditor } from './Editor.index';
import * as React from 'react'
import './Editor.scss';
// import { TextareaMentionsEditor as TextareaMentions } from '../../../TextareaMentions/TextareaMentions';
import { Input, Icon, Button } from 'uielements/src';
import { i18n, Translate } from 'localization';
// import { Datepicker } from '../../../Datepicker/Datepicker';
import { TimePicker2 } from '../../../TimePicker2/TimePicker2';
import { Select } from 'uielements/src/Select/Select';
// import { createDurationOptions, findSelected } from '../Utils'
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { DateTimeInput } from 'blocks/PryanikyForms/DateTimeInput/DateTimeInput'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
import { ITask } from '../Tasks.type';
import { CUSTOM_FIELDS_TYPES_NAMES, customFieldsTypesNamesNumbersDiff } from 'utils/src/constants.prn';
import { ImageLoader } from 'blocks/ImageLoader/ImageLoader';
import { TaskDocumentReadBlock } from '../TaskDocumentReadBlock/TaskDocumentReadBlock';

export const Editor: React.FC<ITasksEditorProps> = ({ children, data, onChangeTitle, onChangeText, changeAnswerText, addNewAnswer, removeAnswer, toggleRequireAttach, toggleRequireComment, onChangeUsers, onChangeFiledFiles }) => {
  const [initAdd, setInitAdd] = React.useState(false);
  return <div className={'Editor TasksEditor'}>
    <Input onChange={onChangeTitle}
      className={'TasksEditor-Title'}
      value={data.header}
      icon='flag'
      placeholder={i18n.t('pryaniky.createPost.tasks.name')} />
    <PryanikyEditor
      // getClassRef={refGetter}
      //ref={forwardRef as any}
      // users={true}
      // tags={true}
      onChange={onChangeText}
      // className={''}
      // icon={'edit'}
      value={data.text}
      disableToolbar={true}
      placeholder={i18n.t('pryaniky.createPost.tasks.description')} />
    {<PrnUserInput
      className={'EventEdit-Users'}
      excludeMe={true}
      placeholder={i18n.t('pryaniky.createPost.tasks.selectResponsible')}
      excludeUsers={[]}
      onChange={onChangeUsers}
      value={data.users || []} />}
    {
      data.forms.map((item, idx) => {
        switch(item.fieldType) {
          case CUSTOM_FIELDS_TYPES_NAMES.TaskCheckBlock || customFieldsTypesNamesNumbersDiff.TaskCheckBlock: {
            return (
              <Input
                key={item.id}
                placeholder={i18n.t('pryaniky.createPost.tasks.task')}
                className={'TasksEditor-TaskInput NewsEdit-Body_type_polls-Task-Answer-Input'}
                onChange={(e: any) => changeAnswerText(idx, e)}
                // icon={icon(item.)}
                value={item.name}>
                <Button
                  className={'Button_requireAttach'}
                  onClick={() => toggleRequireAttach(idx)}
                  title={i18n.t(`pryaniky.createPost.tasks.task.requireAttachement${item.requireAttach ? '.false' : ''}`)}
                  theme={'unstyled'}
                  main={item.requireAttach}
                  children={<Icon icon="clip" />}
                />
                <Button
                  className={'Button_requireComment'}
                  onClick={() => toggleRequireComment(idx)}
                  title={i18n.t(`pryaniky.createPost.tasks.task.requireComment${item.requireComment ? '.false' : ''}`)}
                  theme={'unstyled'}
                  main={item.requireComment}
                  children={<Icon icon="comments" />}
                />
                <Button
                  className={'Button_removeTask'}
                  onClick={() => removeAnswer(idx)}
                  theme={'unstyled'}
                  children={<Icon icon="times" />}
                />
              </Input>
            )
          }
          case CUSTOM_FIELDS_TYPES_NAMES.DocumentReadBlock || customFieldsTypesNamesNumbersDiff.DocumentReadBlock: {
            return (
              <TaskDocumentReadBlock key={item.id} onChange={(files: any[]) => onChangeFiledFiles(idx, files)} onRemove={() => removeAnswer(idx)} />
            )
          }
          default:
            return null;
        }
      })
    }
    {/* {data.task.tasks.map((task: ITask, i: number) => {
      const icon = (index: number) => <span className="Input-Icon">{index + 1}.</span>;
      return (
        <Input
          key={i}
          placeholder={i18n.t('pryaniky.createPost.tasks.task')}
          className={'TasksEditor-TaskInput NewsEdit-Body_type_polls-Task-Answer-Input'}
          onChange={(e: any) => changeAnswerText(i, e)}
          icon={icon(i)}
          value={task.text}>
          <Button
            className={'Button_requireAttach'}
            onClick={() => toggleRequireAttach(i)}
            title={i18n.t(`pryaniky.createPost.tasks.task.requireAttachement${task.requireAttach ? '.false' : ''}`)}
            theme={'unstyled'}
            main={task.requireAttach}
            children={<Icon icon="clip" />}
          />
          <Button
            className={'Button_requireComment'}
            onClick={() => toggleRequireComment(i)}
            title={i18n.t(`pryaniky.createPost.tasks.task.requireComment${task.requireComment ? '.false' : ''}`)}
            theme={'unstyled'}
            main={task.requireComment}
            children={<Icon icon="comments" />}
          />
          <Button
            className={'Button_removeTask'}
            onClick={() => removeAnswer(i)}
            theme={'unstyled'}
            children={<Icon icon="times" />}
          />
        </Input>
      );
    })} */}
    <div className={cnTasksEditor('FormSelector', { inited: initAdd })}>
      <Button className={cnTasksEditor('FormSelector-Button')} onClick={() => {
        setInitAdd(false)
        addNewAnswer(CUSTOM_FIELDS_TYPES_NAMES.TaskCheckBlock)
      }} children={i18n.t('pryaniky.createPost.tasks.taskCheckBlock')} />
      <Button className={cnTasksEditor('FormSelector-Button')} onClick={() => {
        setInitAdd(false)
        addNewAnswer(CUSTOM_FIELDS_TYPES_NAMES.DocumentReadBlock)
      }} children={i18n.t('pryaniky.createPost.tasks.documentReadBlock')} />
    </div>
    <Button
      className={cnTasksEditor('AddNewTask')}
      onClick={() => setInitAdd(!initAdd)}
      theme={'unstyled'}
      children={
        <React.Fragment>
          <Icon icon="plus" />
          {i18n.t('pryaniky.createPost.tasks.addTask')}
        </React.Fragment>
      }
    />
    {children}
  </div>
}