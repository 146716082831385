import actions from 'redux/actionsTypes/Search';

export type TSearchValue =   'users' | 'news' | 'groups' | 'pages' | 'files' | 'tags' | 'courses' | 'all'

export interface ISearchState {
  query: string
  tabs: ITab[]
  activeTab: TSearchValue
}

interface ITab {
  title: string
  value: TSearchValue
}

const defaultSearch: ISearchState = {
  query: '',
  activeTab: 'all',
  tabs: [
    {
      title: 'all',
      value: 'all'
    },
    {
      title: 'colleagues',
      value: 'users'
    },
    {
      title: 'news',
      value: 'news'
    },
    {
      title: 'groups',
      value: 'groups'
    },
    {
      title: 'pages',
      value: 'pages'
    },
    {
      title: 'files',
      value: 'files'
    },
  ]
};

export function searchReducer(state: ISearchState = defaultSearch, action: any) {
  switch (action.type) {
    case actions.GET_QUERY:
      return {
        ...state,
        query: action.payload,
      }
    case actions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      }

    default:
      return state
  }
}