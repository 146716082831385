import { Page } from "blocks/Page/Page";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { cnUser, UserPageProps } from "./User.index";
import { i18n, Translate } from 'localization';
import { pageNames } from "redux/pageNames";

export const UserNew: FC<UserPageProps> = ({
  children
}) => {

  const {
    id
  } = useParams<{
    id: string,
    tab: string | undefined
  }>();

  const authUser = useSelector(getCurrentUser);

  return (
    <>
      <Page
        className={cnUser({id})}
        page={{
          name: pageNames.userNew + '.' + id,
          title: authUser.baseData.id === id ? i18n.t('me') : i18n.t('colleague'),
          params: { id: id, authUser: authUser }
        }} />
      {children}
    </>
  )
};