import React from 'react';
import { ISwipeMenu, cnSwipeMenu } from './SwipeMenu.index';
import './SwipeMenu.scss';

export const SwipeMenu: React.FC<ISwipeMenu.Props> = ({
  active,
  count,
  onChange,
  className
}) => {
  if(count <= 1) return null;
  return (
    <div className={cnSwipeMenu({}, [className])}>
      {
        new Array(count).fill(1).map((_, idx) => {
          const isActive = idx === active;
          return <div className={cnSwipeMenu('Item', [isActive ? 'Button_main' : ''])} onClick={() => onChange(idx)} />
        })
      }
    </div>
  )
}