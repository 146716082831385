import React, { FC, RefForwardingComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cnPagesMenuMuiBar } from '../PagesMenuMuiBar.index'
import { mapWikiInfoStateToProps, mapWikiInfoDispatchToProps, IPageInfoProps } from './MenuInfoItem.index'
import ReactSortable from 'react-sortablejs';
import { Button } from 'muicomponents/src/Button/Button';
import { Avatar } from 'uielements/src/index';
import * as utils from 'utils/src/utils';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { i18n, Translate } from 'localization';
import { bindActionCreators } from 'redux';
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { Box } from 'muicomponents/src/Box/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import { Actions } from '../../../../NewsView/Actions/Actions';
import { Like } from '../../../../NewsView/Actions/Like/Like'
import { likeWikiRequest } from "redux/actions/WikiList";
import { connect } from 'react-redux';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { Typography } from 'muicomponents/src';
import { formatDateSTD } from 'utils/src/utils.dates';


const MenuInfoItemPresenter: FC<IPageInfoProps> = ({
    pageInfo,
    likeWikiRequest,
}, ref) => {

    const userBox = (item: any, avatar: boolean) => <Box className={cnPagesMenuMuiBar('MenuItem-Content-AvatarBox')}>
        {avatar && <Avatar className={cnPagesMenuMuiBar('Image-Icon')} size={40} imgUrl={item.imgUrl} imgId={item.imgId} name={item.displayName} />}
        <Box className={cnPagesMenuMuiBar('MenuItem-Content-Item-Name')}>
            <Button sx={{ textTransform: "inherit", padding: 0, justifyContent: "left" }} variant='text' href={`/user/${item.id}`}>
                {item.displayName}
            </Button>
            {(item.position !== '' && avatar) && <span>
                {item.position}
            </span>}
        </Box>
    </Box>

    return (
        // TODO change to box
        <Box className={cnPagesMenuMuiBar('MenuItem-Content')}>
            <Box className={cnPagesMenuMuiBar('MenuItem-Content-Item')}>
                <div className={cnPagesMenuMuiBar('MenuItem-Content-Title')}> <Translate i18nKey={'pryaniky.wikipage.menu.info.main'} /></div>
                <Box>
                    <Box className={cnPagesMenuMuiBar('MenuItem-Content-Item-DateBox')}>
                        <CalendarTodayIcon color="action" />
                        <Box>
                            <Typography variant={"body2"} ml={"16px"}>
                                <Translate i18nKey={"pryaniky.wikilist.item.published"} values={{ date: formatDateSTD(pageInfo?.creationDate, false) }} />
                            </Typography>
                            {pageInfo?.authors.map((item: any) => userBox(item, false))}
                        </Box>
                    </Box>
                    <Box className={cnPagesMenuMuiBar('MenuItem-Content-Item-DateBox')}>
                        <EditIcon color="action" />
                        <Box>
                            <Typography variant={"body2"} ml={"16px"}>
                                <Translate i18nKey={"pryaniky.wikilist.item.changed.old"} values={{ date: formatDateSTD(pageInfo?.changeDate, false) }} />
                            </Typography>
                            {(pageInfo?.versions?.length > 0 && pageInfo?.versions[0].changedByUser) && userBox(pageInfo?.versions[0].changedByUser, false)}
                        </Box>
                    </Box>

                </Box>

            </Box>

            {pageInfo?.authors?.length > 0 && <Box className={cnPagesMenuMuiBar('MenuItem-Content-Item')}>
                <div className={cnPagesMenuMuiBar('MenuItem-Content-Title')}><Translate i18nKey={'pryaniky.wikipage.menu.info.authors'} /></div>

                {pageInfo?.authors.map((item: any) => userBox(item, true))}
            </Box>}

            {pageInfo?.moderators.length > 0 && <Box className={cnPagesMenuMuiBar('MenuItem-Content-Item')}>
                <div className={cnPagesMenuMuiBar('MenuItem-Content-Title')}><Translate i18nKey={'pryaniky.wikipage.menu.info.moderators'} /></div>
                {pageInfo?.moderators.map((item: any) => userBox(item, true))}
            </Box>}


        </Box>
    )
}

// export const MenuInfoItem = React.forwardRef(MenuInfoItemPresenter);

export const MenuInfoItem = connect(
    mapWikiInfoStateToProps,
    mapWikiInfoDispatchToProps
)(MenuInfoItemPresenter)
