import { Translate } from 'localization';
import moment from 'moment';
import { Button, Typography } from 'muicomponents/src';
import React, { FC } from 'react';
import { SCalendar } from 'utils/src';
import BaseRequests from 'utils/src/requests/requests.base';
import { DayCellColors } from '../UsersEvents.constants';
import { useUsersEventsContext } from '../UsersEvents.context';
import { cnUsersEvents } from '../UsersEvents.index';
import { UsersEventesHeadProps } from './UsersEventesHead.index';
import { BlockBox, BlocksBox, CalendarItemBox, CalendarItemsBox, ColorCircle } from './UsersEventesHead.styled';

const CelandarItem: FC<{ calendar: SCalendar }> = ({ calendar }) => {
    return (
        <CalendarItemBox>
            <ColorCircle
                sx={{
                    background: calendar.color,
                }}
            />
            <Typography variant="body2" component={'span'}>
                {calendar.displayName}
            </Typography>
        </CalendarItemBox>
    );
};

export const UsersEventesHead: FC<UsersEventesHeadProps> = ({ dates }) => {
    const blockContext = useUsersEventsContext();

    const start = moment(dates[0]).format('YYYY-MM-DD');
    const end = moment(dates[1]).format('YYYY-MM-DD');
    const mcid = blockContext.settings.generatedCalerdarSettings.calendarId;

    return (
        <BlocksBox className={cnUsersEvents('Blocks')}>
            {blockContext.settings.headBlocks.map((block, idx) => {
                const downloadLink = BaseRequests.getUrl(
                    '/ru/Data/v3/report/vacationschedule',
                    {
                        start,
                        end,
                        mcid,
                        cids: block.calendars,
                    },
                    undefined,
                    undefined,
                    true
                );

                return (
                    <BlockBox className={cnUsersEvents('Block')} key={block.id}>
                        {block.title && <Typography variant="body1">{block.title}</Typography>}
                        <CalendarItemsBox>
                            {block.calendars.map((cId) => {
                                if (!blockContext.calendars[cId]) return null;
                                return <CelandarItem calendar={blockContext.calendars[cId]} />;
                            })}
                        </CalendarItemsBox>
                        {!!block.button && (
                            <div>
                                <Button variant="contained" href={downloadLink}>
                                    {block.button.text}
                                </Button>
                            </div>
                        )}
                    </BlockBox>
                );
            })}
            <BlockBox>
                <Typography variant="body1">
                    <Translate i18nKey="pryaniky.usersevents.header.otherMarkers" />
                </Typography>
                <CalendarItemsBox>
                    <CelandarItem
                        calendar={
                            {
                                color: blockContext.settings.generatedCalerdarSettings.headerTodayColor,
                                displayName: <Translate i18nKey="pryaniky.usersevents.header.today" />,
                            } as any
                        }
                    />
                    <CelandarItem
                        calendar={
                            {
                                color: blockContext.settings.generatedCalerdarSettings.headerWeekendColor,
                                displayName: <Translate i18nKey="pryaniky.usersevents.header.weekends" />,
                            } as any
                        }
                    />
                    {!!blockContext.settings.generatedCalerdarSettings.calendarId && (
                        <CelandarItem
                            calendar={{
                                ...blockContext.calendars[blockContext.settings.generatedCalerdarSettings.calendarId],
                                color: DayCellColors.weekend,
                            }}
                        />
                    )}
                </CalendarItemsBox>
            </BlockBox>
        </BlocksBox>
    );
};
