import React, { FC, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { EditorState } from 'draft-js';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { answerValidator, fieldWithError } from '../unitValidation'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import { tC } from 'LMSModule/utils/i18n.adaptor'
import DeleteButton from '../../../components/DeleteButton'
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

// const TextFieldHelped = fieldWithHelp(TextField)
const FormControlHelped = fieldWithHelp(FormControl)
const DragHandle = SortableHandle(() => <IconButton><DragIndicatorIcon /></IconButton>);
import { DraftInput, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput'

export const Presenter: FC<IPresenterProps> = ({
    className,
    answer,
    updateAnswer,
    deleteAnswer,
    qid,
    uid,
    changeRadioAnswer,
    toggleCheckAnswer,
    radio = false,
    setAnswerError,
    errors
}) => {
    const a = answer;

    // const { draftState, setDraftState } = useDraftEdit('')

    const updateA = (payload: any) => updateAnswer(payload, uid)

    const changeCheck = (property: string) => () => {
        updateA({ ...a, [property]: !a[property] })
    }
    const changeCheckAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleCheckAnswer({ qid, aid: (event.target as HTMLInputElement).value, uid })
    }
    const changeText = (property: string) => (e: any) => {
        setAnswerError(uid, qid, a.id, answerValidator(property, e.target.value))
        updateA({ ...a, [property]: e.target.value })
    }
    const changeDraft = (property: string) => (state: EditorState) => {
        setAnswerError(uid, qid, a.id, answerValidator(property, state))
        updateA({ ...a, [property]: state })
    }
    const changeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeRadioAnswer({ qid, aid: (event.target as HTMLInputElement).value, uid })
    }
    const onDelete = () => deleteAnswer(answer.id)
    return <div className={cnClassName({}, [className])}>
        <FormControl fullWidth>
            <DraftInput
                disableToolbar
                onChange={changeDraft('draftState')}
                value={a.draftState}
                variant={'standard'}
                error={fieldWithError(errors['draftState'])}
                placeholder={tT('answer_edit.placeholder.1')}
                endAdornment={
                    <InputAdornment position="end">
                        <DeleteButton onConfirm={onDelete} tooltip={tC('pressAgain.1')} />
                        <DragHandle />
                    </InputAdornment>
                }
                startAdornment={
                    <InputAdornment position="start">
                        {radio ?
                            <Radio
                                title={a.isRightAnswer ? tT('answer_edit.isRightAnswer.1') : tT('answer_edit.isRightAnswer.2')}
                                color="primary"
                                checked={a.isRightAnswer}
                                onChange={changeRadio}
                                value={answer.id}
                                name="isRightAnswer"
                            /> :
                            <Checkbox
                                title={a.isRightAnswer ? tT('answer_edit.isRightAnswer.1') : tT('answer_edit.isRightAnswer.2')}
                                checked={a.isRightAnswer}
                                onChange={changeCheckAnswer}
                                value={answer.id}
                                name="isRightAnswer"
                                color="primary"
                            />}
                    </InputAdornment>
                }
            />
            {/* <Input
                value={a.text}
                error={fieldWithError(errors['text'])}
                onChange={changeText('text')}
                placeholder={tT('answer_edit.placeholder.1')}
                endAdornment={
                    <InputAdornment position="end">
                        <DeleteButton onConfirm={onDelete} tooltip={tC('pressAgain.1')} />
                        <DragHandle />
                    </InputAdornment>
                }
                startAdornment={
                    <InputAdornment position="start">
                        {radio ?
                            <Radio
                                title={a.isRightAnswer ? tT('answer_edit.isRightAnswer.1') : tT('answer_edit.isRightAnswer.2')}
                                color="primary"
                                checked={a.isRightAnswer}
                                onChange={changeRadio}
                                value={answer.id}
                                name="isRightAnswer"
                            /> :
                            <Checkbox
                                title={a.isRightAnswer ? tT('answer_edit.isRightAnswer.1') : tT('answer_edit.isRightAnswer.2')}
                                checked={a.isRightAnswer}
                                onChange={changeCheckAnswer}
                                value={answer.id}
                                name="isRightAnswer"
                                color="primary"
                            />}
                    </InputAdornment>
                }
            /> */}
        </FormControl>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
