import { useChangeCreator } from "News/creator/hooks";
import { newsCreatorItemNewsChange } from "News/creator/redux/actions.newsCreator";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "News/creator/redux/selectors.newsCreate";
import { NewsCreateReducer } from "News/creator/redux/types.newsCreator";
import { useDispatch, useSelector } from "react-redux";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
// import {} from ''
export const useWorkflowsEdit = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const data = useSelector(getNewsCreateItemNewsFields(['header', 'text', 'additionalFields'])(id));

    
    const creatorFields = useSelector(getNewsCreateItemFields(['errors', 'clearEditor', 'selectedNews', 'formSettings'])(id));
// console.log('creatorFields', creatorFields)
    const postType = useSelector(getAllowPostTypeById(creatorFields.selectedNews || '', -1, creatorFields.selectedNews || ''));

    const changeCreator = useChangeCreator(id);

    const changeNews = (news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    };

    return {
        data,
        postType,
        ...creatorFields,
        changeNews,
        changeCreator
    };
};