import React, { FC, useState, useEffect } from "react";
import { ComponentInjector } from "utils/src/ComponentInjector";
import { WidgetDialogBox } from "./UsersCardWidget.styled";
import {
  IWidgetSettingsProps,
  ISettingsItem,
  cnUsersCardWidgetSetting,
} from "./UsersCardWidget.index";
import { TextField } from "muicomponents/src/TextField/TextField";
import { UploadButton } from "muicomponents/src/UploadButton/UploadButton";
import { useFileUploader } from "utils/src/hooks";
import { Button } from "muicomponents/src/Button/Button";
import Switch from "@mui/material/Switch";
import uuid from "uuid";
import FormControlLabel from "@mui/material/FormControlLabel";
import { isObject } from "lodash";
import { IconButton } from "muicomponents/src/IconButton/IconButton";
import Delete from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { i18n, Translate } from "localization";
import { UsersSuggester } from "muicomponents/src";

const Item = ({
  settings,
  onChange,
  onEnebled,
  onDelete,
}: {
  settings: ISettingsItem;
  onChange: (settnigs: ISettingsItem) => void;
  onEnebled: (enabled: boolean) => void;
  onDelete: () => void;
}) => {
  const [state, setState] = useState<typeof settings>(settings || {});

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <Stack direction={"row"} gap={2} alignItems="flex-start">
      <Stack gap={2} flex="1" className={cnUsersCardWidgetSetting("Stack")}>
        <UsersSuggester
          TextFieldProps={{
            label: i18n.t("pryaniky.UsersCardWidget.settings.user"),
          }}
          value={state?.user}
          onChange={(e, value) => setState((s) => ({ ...s, user: value }))}
        />

        <TextField
          className={cnUsersCardWidgetSetting("title")}
          label={i18n.t("pryaniky.UsersCardWidget.settings.title")}
          value={settings?.title}
          onChange={(e) => setState((s) => ({ ...s, title: e.target.value }))}
        />

        <TextField
          className={cnUsersCardWidgetSetting("Info")}
          label={i18n.t("pryaniky.UsersCardWidget.settings.info")}
          value={settings?.info}
          onChange={(e) => setState((s) => ({ ...s, info: e.target.value }))}
        />
      </Stack>
      <Tooltip title={i18n.t("pryaniky.UsersCardWidget.settings.delete")}>
        <IconButton onClick={onDelete}>
          <Delete color="error" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export const WidgetSettingsDialog: FC<IWidgetSettingsProps> = ({
  onChange,
  //   data,
  settings,
  setValid,
}) => {
  const [state, setState] = useState<ISettingsItem[]>(() => {
    let items = settings?.items || [];
    return items;
  });

  useEffect(() => {
    onChange({
      settings: { items: state },
    });
    // const allTexts = state.every((item) => !!item.text);
    // const allFiles = state.every((item) => !!item.file);
    setValid(true); //(allTexts && allFiles);
  }, [state]);
  // console.log('WidgetSettingsDialog, state', state, settings)
  return (
    <WidgetDialogBox>
      <Stack divider={<Divider />} gap={2}>
        <TextField
          label={i18n.t("pryaniky.ContentCardWidget.settings.widgetTitle")}
          value={settings?.title}
          onChange={(e) =>
            onChange({
              settings: { ...settings, title: e.target.value },
            })
          }
        />

        {state.map((item) => (
          <Item
            key={item.id}
            onDelete={() => setState((p) => p.filter((v) => v.id !== item.id))}
            onEnebled={(enabled) =>
              setState((p) =>
                p.map((v) =>
                  v.id === item.id
                    ? { ...v, enabled }
                    : { ...v, enabled: false }
                )
              )
            }
            onChange={(s) =>
              setState((p) => p.map((v) => (v.id === item.id ? s : v)))
            }
            settings={item}
          />
        ))}

        <Button
          onClick={() =>
            setState((s) => [...s, { id: uuid(), enabled: false }])
          }
        >
          {i18n.t("pryaniky.UsersCardWidget.settings.add_item")}
        </Button>
      </Stack>
    </WidgetDialogBox>
  );
};

export const initWidgetAddition = () =>
  ComponentInjector.getInstance().addComponent(
    "WAddition",
    WidgetSettingsDialog,
    "pryaniky/UsersCardWidget"
  );
export const initWidgetSettings = () =>
  ComponentInjector.getInstance().addComponent(
    "WSettings",
    WidgetSettingsDialog,
    "pryaniky/UsersCardWidget"
  );
