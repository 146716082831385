import React, { FC, useState } from 'react'
import { Button, Icon } from 'uielements/src'
import FolderIcon from './Folder.svg'
import BackIcon from './FolderBack.svg'
import { cn } from '@bem-react/classname';
import Layout from '../ItemLayout/ItemLayout'
import { NameForm } from '../NameForm/NameForm'
import { OptionContent } from 'uielements/src/Dropdown/Dropdown'
import { IDropdownOption } from 'uielements/src/Dropdown/Dropdown.index'
import { i18n, Translate } from 'localization';
import './FolderItem.scss'
export const cnFolderItem = cn('FolderItem');

export interface IPresenterProps {
    showControls?: boolean;
    isBack: boolean;
    name: string;
    onClick?: () => void;
    clickItem?: () => void;
    onRemane?: (val: string) => void
    onSelect: () => void;
    selected: boolean;
    activeMoveng: boolean;
    date: string;
    move: () => void;
    fid: string;
    actions: any[];
}

const Presenter: FC<IPresenterProps> = ({
    showControls = false,
    isBack,
    name,
    onClick = () => { },
    onSelect,
    selected,
    activeMoveng,
    date,
    move,
    onRemane = () => { },
    fid,
    actions,
    clickItem
}) => {
    const [rename, setRename] = useState<boolean>(false)
    const folderActioons = [...actions]
    if (!isBack) folderActioons.push({
        content: i18n.t('pryaniky.files.rename'),//<OptionContent icon={'edit'} text={i18n.t('pryaniky.files.rename')} />,
        action: 'rename',
        buttonProps: { onClick: () => setRename(true) },
    })

    const setFolderName = (name: string) => {
        setRename(false)
        onRemane(name)
    }

    return <Layout
        // clickItem={clickItem}
        clickItem={onClick}
        className={cnFolderItem({}, [])}
        showActions={!isBack && showControls}
        primaryAction={<Button target={'_blank'} theme={'unstyled_center'} className={cnFolderItem('ActionBtn')}>
            <Icon icon={'folder'} />
        </Button>}
        actions={folderActioons}
        dataNode={(activeMoveng && !selected) && <Button theme={'unstyled_center'} className={cnFolderItem('Btn')} onClick={move}><Icon icon={'arrow-alt-right'} />{i18n.t('pryaniky.files.move')}</Button>}
        dateNode={!isBack && <div>{(new Date(date)).toLocaleString()}</div>}
        icon={isBack ? BackIcon : FolderIcon}
        nameNode={rename ? <NameForm cancel={() => setRename(false)} folderName={name} setFolderName={setFolderName} /> : <Button className={cnFolderItem('Btn')} theme={'unstyled'} onClick={onClick}>{name}</Button>}
        onSelect={onSelect}
        selected={selected}
        showChekbox={!isBack && showControls}

    />
}
export default Presenter