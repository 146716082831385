import { TableVirtualized } from "muicomponents/src/TableVirtualized";
import { ComponentProps } from "react";

 

export const weekendIndexes = [6, 0];

export enum TitleFormats {
    month = 'MMMM',
    yearMonth = 'YYYY MMMM',
};

export const calendarsArray = new Array(2).fill(0);

export enum DayHeaderColors {
    today = '#C8DF77',
    weekend = '#FFCDCE'
};

export enum DayCellColors {
    weekend = '#E0E0E0'
};


export const tableConstantProps: Partial<ComponentProps<typeof TableVirtualized>> = {
    fixedColumnCount: 3,
    fixedRowCount: 1,
    estimatedRowSize: 69,
    // estimatedRowSize: 30,
    estimatedColumnSize: 900
};

export const lineClampStyles = {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
};

export const mainBottomSpaceHight = 15;