import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';

import { mapDispatchToProps } from 'redux/connector';

import { mapStateToProps, mapActionsToProps, IListState, cnList } from './../../List.index';
import { IListGroupsDefaultProps, IListTypeGroupsProps } from './List_type_groups.index';

import './List_type_groups_tree.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { Header } from '../../Header/List-Header';
import { ListLite, Avatar, Button, Icon, Loading } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Item } from '../..//Item/List-Item';
import { updateContext } from 'redux/actions/Widgets';

import { ListGroupsPresenter } from './List_type_groups';

export class ListGroupsTreePresenter extends ListGroupsPresenter {
  public static defaultProps: IListGroupsDefaultProps = {
    requestOptions: {
      search: '',
      extended: true,
      groupListFilter: 'all',
      uid: '',
    },
  };

  public type = 'groups';

  public classMods = {
    type_groups: 'tree'
  };

  public rootLevelElCounts = 0;

  constructor(props: IListTypeGroupsProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  };

  public componentDidMount() {
    this.mounted = true;
    // const initialFilter = this.props.widget.groupTypes || 'all'
    // this.props.updateContext('common', {
    //   filter: {
    //     id: initialFilter,
    //     type: 'type',
    //     value: initialFilter,
    //   },
    // });
    this.getData();
  };

  // public search = (text: string) => {
  //   this.searchString = text;
  //   this.setState(
  //     {
  //       hideData: text.length !== 0,
  //       clearData: text.length !== 0,
  //       filteredData: [],
  //       isFinished: false,
  //     },
  //     this.getData
  //   );
  // };

  public getData = (pkid?: string, sublevel?: number) => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getGroupsTree, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading) return;
    const opts = Object.assign({}, requestOptions);
    if (context.gId && !pkid) pkid = context.gId.toString();
    // if (context.filter) {
    //   if (context.filter.type === 'type') opts.groupListFilter = context.filter.value;
    // }
    // if (context.uId) {
    //   opts.uid = context.uId;
    //   opts.groupListFilter = 'my';
    // }
    if (!pkid && !sublevel && this.searchString && this.searchString !== '') opts.search = this.searchString;
    toggle({
      id: this.listId, 
      data: { 
        isLoading: true, 
        isFinished: false 
      }
    });
    if (clearData) clearDataFromList({ id, data: null });

    const aopts: any = { 
      pkid,
      sublevel
    }

    getGroupsTree.call(this, id, aopts, opts);
  };

  public prepareData(el: any) {
    super.prepareData(el);
    if (!el.tree_view) el.tree_view = {};
    if (el.treeData) {
      el.view_data.parentGroupId = el.treeData.parentGroupId;
      el.view_data.sublevel = el.treeData.sublevel;
    }
    if (!this.searchString || this.searchString === '') el.view_data.info[0][2] = <React.Fragment>
      {
        el.childGroupCount > 0 &&
        <Button
          className={'List-Item_group-Button_action List-Item_group-Button_subgroups'}
          noPadding
          onClick={() => { 
            if (!el.tree_view.sublevelShow) {
              this.getData(el.pkid, el.treeData && el.treeData.sublevel + 1 || 1);
              el.tree_view.sublevelLoading = true;
              el.tree_view.sublevelShow = true;
              this.prepareData(el);
              this.props.changeListItem({
                id: this.listId, 
                data: {
                  item: el.id,
                  data: el,
                }
              });
            } else {
              el.tree_view.sublevelLoading = false;
              el.tree_view.sublevelLoaded = false;
              el.tree_view.sublevelShow = false;
              this.prepareData(el);
              this.props.removeSubitemsFromList({ id: this.listId, data: el.id });
            }
          }}
          children={
            <React.Fragment>
              <Icon icon={el.tree_view.sublevelLoading ? 'clock' : !el.tree_view.sublevelLoaded ? 'chevron-right' : 'chevron-down'} />
              {i18n.t('subgroups') + ': ' + el.childGroupCount}
            </React.Fragment>
          }
        />
      }
      {el.view_data.info[0][2]}
    </React.Fragment>;
  }

  // public renderChildren = () => {
  //   // const { isFinished, isLoading, hideData } = this.state;
  //   const { data, isFinished, isLoading, hideData } = this.props;
  //   // const data: any[] = this.state[this.dataVariable];
  //   return (
  //     <React.Fragment>
  //       <Header
  //         className={'rounded-top'}
  //         type={'common'}
  //         search={{
  //           placeholder: i18n.t('pryaniky.list.groups.search') + ' TREEEEEEEEEE',
  //           onChange: this.search,
  //         }}
  //       />
  //       <ListLite
  //         className={'rounded-bottom'}
  //         isFinished={isFinished}
  //         isLoading={isLoading}
  //         loadMore={!hideData ? this.getData : utils.noop}>
  //         {data.map(el => (
  //           <Item
  //             lId={this.listId}
  //             iId={el}
  //             type={'common'}
  //             key={el}
  //             data_key={el}
  //           />
  //         ))}
  //       </ListLite>
  //     </React.Fragment>
  //   );
  // };
}

export const List = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
  // { updateContext }
)(ListGroupsTreePresenter);
