import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { DSSetItem } from 'redux/dataStorage/actions';
import { getVacanciesSettingsModule } from '../redux/module.vacancies';
import { Vacancy } from '../Vacancy/Vacancy';
import { emptyVacancy } from '../redux/constants.vacancies';
import { Page } from 'blocks/Page/Page';
import { pageNames } from 'redux/pageNames';

const VacancyPagePr: FC<{}> = ({

}) => {

    return (
        <Page page={{ name: pageNames.vacancy }} />
    )
};

export const VacancyPage: FC<{}> = ({

}) => {
    return (
        <DynamicModuleLoader modules={[getVacanciesSettingsModule()]}>
            <VacancyPagePr />
        </DynamicModuleLoader>
    )
};