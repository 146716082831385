import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageProps } from "./Page.index";
// old redux actions
import { getStructure, setStructure, backup, updateContext, setData } from 'redux/actions/Widgets';
// news redux actions
import { getPage, preparePageName, prepareStructureToRedux } from 'Widgets_v2/api';
import { useAppSelector } from "redux/hooks";
import { i18n, Translate } from 'localization';
import { getStoreField } from "utils/src/CommonRedux/base/selectors";
import { getWidgetById, getWidgetStoreObjectById } from "Widgets_v2/redux/selectors";
import { widgetsUpdateData } from "Widgets_v2/redux/actions";
import { toggleIsEditable } from "utils/src/CommonRedux/base/actions";

export const usePage = (props: PageProps['page'], cb?: () => void) => {

    const [ loading, setLoading ] = useState(false);

    const nameRef = useRef(preparePageName(props.name));
    nameRef.current = preparePageName(props.name);

    const dataOld = useAppSelector((state) => {
        const structure = state.widgets.pages[state.widgets.structures[nameRef.current]];
        return {
            edit: state.store.edit,
            saving: state.store.saving,
            loading: state.widgets.togglers.loading,
            structure,
            layout: structure ? state.widgets.widgets[structure.layout] : undefined,
            title: state.appSettings && state.appSettings.title !== '' ? state.appSettings.title : i18n.t('project name'),
            context: state.widgets.contexts.common
        }
    });

    const data = useAppSelector((state) => {
        const pageId = getWidgetStoreObjectById('structures', nameRef.current)(state);
        const structure = getWidgetStoreObjectById('pages', pageId)(state);
        const layout = getWidgetById<'layout' | 'layout/horizontal' | 'grid'>(structure?.layout)(state);
        return {
            edit: getStoreField('edit')(state),
            structure: structure as typeof structure | undefined,
            layout: layout as typeof layout | undefined,
            context: getWidgetStoreObjectById('contexts', 'common')(state)
        };
    });

    const dispatch = useDispatch();

    useEffect(() => {

        const getAndDispatchPage = async function() {
            setLoading(true);

            

            const responseData = await getPage(nameRef.current, props.params, cb);
            const prepairedData = prepareStructureToRedux(responseData);

            // set data to old reducer
            // this part is tepmorary
            dispatch(setData({
                contexts: prepairedData.contexts,
                widgets: prepairedData.widgets,
                pages: {
                    [prepairedData.structure]: prepairedData.page
                },
                structures: {
                    [nameRef.current]: prepairedData.structure
                }
            }));
            // end of temporary part

            dispatch(widgetsUpdateData({
                structures: {
                    [nameRef.current]: prepairedData.structure
                },
                pages: {
                    [prepairedData.structure]: prepairedData.page
                },
                widgets: prepairedData.widgets,
                contexts: prepairedData.contexts,
                active: nameRef.current
            }));
            dispatch(toggleIsEditable(prepairedData.page.isEditable));

            setLoading(false);
        };
        
        getAndDispatchPage();

        return () => {
            
        };
    }, []);



    return {
        loading,
        data,
        dataOld
    }

};