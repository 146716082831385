import React, { FC } from 'react'
import { IBadgeProps } from './Badge.index'
import { Tooltip, Button, Avatar, Icon } from 'uielements/src';
import { Bable } from 'uielements/src/Bable/Bable'
import { BadgeTooltip } from 'uielements/src/BadgeTooltip/BadgeTooltip'
import * as utils from 'utils.project/utils.project'
import './Badge.scss'
import { i18n, Translate } from 'localization';

/*****
 * @deprecated
 * 
 * не используется.
 * используется из BadgeIcon из uielements
 * 
 * 
 * 
 * 

 */
export const Badge: FC<IBadgeProps> = ({ badge, removeBadge }) => {
    const [showPopup, setShowPopup] = React.useState(false);
    const popup = React.useRef<HTMLDivElement>(null);

    const togglePopup = () => {
        setShowPopup(!showPopup)
    }

    const onRemove = (e: any) => {
        utils.confirm({
            text: i18n.t('pryaniky.badge.widget.delete.text', { name: badge.name }),
            onConfirm: () => removeBadge && removeBadge(badge.badgeUid),
            title: i18n.t('pryaniky.badge.widget.delete.title')
        })
    }
    return <div
        className={'BadgeItem'}>
        <Button
            theme={'unstyled'}
            className={'BadgeItem-Link'}
        >
            <div className={'BadgeItem-ImgBox'}>
                <Button
                    theme={'unstyled'}
                    type={'rlink'}
                    href={'/badge/' + badge.badgeUid}
                    className={'BadgeItem-ImgLink'}
                    onMouseEnter={() => setShowPopup(true)}
                    onMouseLeave={() => setShowPopup(false)}
                >
                    <Avatar ref={popup} shape={'square'} className={'BadgeItem-Img'} imgUrl={badge.badgeImgUrl} imgId={badge.imgId} name={badge.name} size={92} />
                </Button>
                {removeBadge && <Bable className={'BadgeItem-RemoveBadge'}>
                    <Button
                        theme={'unstyled'}
                        className={'BadgeItem-Remove'}
                        onClick={onRemove}
                    ><Icon icon={'times'} /></Button>
                </Bable>}
                {(badge.count > 1 && <Bable className={'BadgeItem-Count'}>{badge.count}</Bable>)}
            </div>

            {showPopup && (
                <Tooltip anchor={popup}>
                    <BadgeTooltip badgeImgUrl={badge.badgeImgUrl} imgId={badge.imgId} name={badge.name} description={badge.description} />
                </Tooltip>
            )}

        </Button>
        <Button
            theme={'unstyled'}
            type={'rlink'}
            href={'/badge/' + badge.badgeUid}
            className={'BadgeItem-Name'}
        >{badge.name}</Button>
    </div>
};
