import React, { FC, useMemo } from 'react';
import { i18n, Translate } from 'localization';
import { useSelector } from 'react-redux';
import { getCurrencyNameCase } from 'utils/src/utils';
import { formatDateSTD } from 'utils/src/utils.dates';
import { getCurrencyNames } from 'utils/src/CommonRedux/base/selectors';
import { GiveMoney } from 'muicomponents/src/Icons';
import { Box, styled, Typography } from 'muicomponents/src';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SavingsIcon from '@mui/icons-material/Savings';
import { Link } from 'muicomponents/src/Link/Link';
import { Itexts, TimelineItemNewProps } from '../CurrencyTimelineNew.index';
import { TimelineContent, TimelineSeparator } from '@material-ui/lab';
import { TimelineConnector, TimelineDot, TimelineItem } from 'muicomponents/src/MuiTimeline/MuiTimeline';

const FlatTimelineDot = styled(TimelineDot)({
    boxShadow: 'none',
    background: 'white',
}) as typeof TimelineDot;

export const TimelineItemNew: FC<TimelineItemNewProps> = ({ userSex, data }) => {
    const {
        isDeleted,
        value,
        transferDate,
        transactionModelType,
        transactionType,
        productData,
        newsItem,
        counterNews,
    } = data;

    const currencyNames = useSelector(getCurrencyNames);

    const newsHeaderRegExp = /.*?\[([^)]*)\].*/m;
    const achievementRegExp = /\*\*(.*)\*\*/;

    const depositTexts: Itexts = useMemo(() => {
        switch (transactionModelType) {
            case 'DepositClosed': {
                return {
                    header: 'pryaniky.wallets.timeline.close.force',
                    text: 'pryaniky.wallets.timeline.close.text',
                    icon: <AccountBalanceWalletIcon color="secondary" />,
                };
            }
            case 'DepositPayout': {
                return {
                    header: 'pryaniky.wallets.timeline.close.periorend',
                    text: 'pryaniky.wallets.timeline.close.text',
                    icon: <AccountBalanceWalletIcon color="secondary" />,
                };
            }
            case 'Deposit': {
                return {
                    header: 'pryaniky.wallets.timeline.open',
                    text: 'pryaniky.wallets.timeline.open.text',
                    rightSide: true,
                    icon: <AccountBalanceWalletIcon color="secondary" />,
                };
            }
            default: {
                return {
                    header: '',
                    text: '',
                };
            }
        }
    }, [transactionModelType]);

    const commonTexts: Itexts = useMemo(() => {
        switch (transactionType) {
            case 'Spent': {
                let text = '';
                let textLink = '';
                let headerLink = `${window.location.origin}/shop/orders` || '';
                if (transactionModelType === 'ShopItem' && productData) {
                    text = productData.name;
                    textLink = `/shop/product/${productData.pkid}`;
                }
                return {
                    header: `pryaniky.timeline.spent.${userSex}`,
                    headerLink,
                    text,
                    textLink,
                    rightSide: true,
                    icon: <AccountBalanceWalletIcon color="secondary" />,
                };
            }
            case 'Given': {
                let text = '';
                let headerLink = newsItem?.permaLink || '',
                    header,
                    ablativeCurrency;
                if (newsItem?.componentRenderName === 'thanks') {
                    header = `pryaniky.timeline.thanked.${userSex}`;
                    text =
                        counterNews && newsItem.thanks?.participants[counterNews]
                            ? newsItem.thanks.participants[counterNews].displayName
                            : newsItem.header.match(newsHeaderRegExp)?.[1] || '';
                    ablativeCurrency =
                        value > 1
                            ? currencyNames.currencyNameAblativePlural
                            : currencyNames.currencyNameAblativeSingular;
                } else if (newsItem?.componentRenderName === 'creativetasks') {
                    text = `${i18n.t('competitionend')}: ${newsItem.creativeTask.title}`;
                } else if (newsItem?.componentRenderName === 'ideas') {
                    text =
                        `${i18n.t('pryaniky.activityLog.filter.Idea')}: ${
                            newsItem?.header.match(newsHeaderRegExp)?.[1]
                        }` || '';
                } else if (
                    newsItem?.componentRenderName === 'news' &&
                    transactionModelType === 'Reply' &&
                    newsItem?.comments?.length
                ) {
                    text = newsItem.comments[0].header?.match(newsHeaderRegExp)?.[1] || '';
                } else if (newsItem?.componentRenderName === 'news') {
                    text = newsItem.header.match(newsHeaderRegExp)?.[1] || newsItem.user.displayName || '';
                }
                return {
                    header: header || `pryaniky.timeline.given.${userSex}`,
                    headerLink,
                    text,
                    rightSide: true,
                    icon: <GiveMoney color="action" />,
                    ablativeCurrency,
                };
            }
            case 'Earned': {
                let text = '';
                let headerLink = newsItem?.permaLink || '',
                    header;
                if (newsItem?.componentRenderName === 'thanks') {
                    header = userSex === 'f' ? `got femin_capitalised` : 'got_capitalised';
                    text = `${i18n.t('pryaniky.widgets.presents.from')}: ${newsItem.user.displayName}`;
                } else if (newsItem?.componentRenderName === 'badges') {
                    text = `${i18n.t('pryaniky.title.badge')}: ${newsItem.header.match(achievementRegExp)?.[1]}` || '';
                } else if (newsItem?.componentRenderName === 'quizresult' && newsItem.quizes) {
                    let quizName = newsItem.quizes[0].name || '';
                    text = `${i18n.t('pryaniky.widgets.create.name.pryaniky/quiz')}: ${quizName}` || '';
                } else if (transactionModelType === 'CancelOrder') {
                    // вот тут проверить - возврат за покупку
                    text = 'pryaniky.timeline.return';
                } else if (newsItem?.componentRenderName === 'ideas') {
                    text =
                        `${i18n.t('pryaniky.activityLog.filter.Idea')}: ${
                            newsItem?.header.match(newsHeaderRegExp)?.[1]
                        }` || '';
                } else if (newsItem?.componentRenderName === 'achievements') {
                    text =
                        `${i18n.t('pryaniky.games.game.module.title.achievements')}: ${
                            newsItem.systemNewsActionHtml?.match(achievementRegExp)?.[1]
                        }` || '';
                } else if (newsItem?.componentRenderName === 'creativetasks') {
                    text = `${i18n.t('competitionend')}: ${newsItem.creativeTask.title}`;
                } else if (newsItem?.componentRenderName === 'news') {
                    text = newsItem.header.match(newsHeaderRegExp)?.[1] || newsItem.user.displayName || '';
                }
                return {
                    header: header || `pryaniky.timeline.earned.${userSex}`,
                    headerLink,
                    text,
                    rightSide: false,
                    icon: <SavingsIcon color="success" />,
                };
            }
            default: {
                return {
                    header: '',
                    text: '',
                };
            }
        }
    }, [transactionType]);

    let texts = transactionModelType === 'Deposit' ? depositTexts : commonTexts;

    const child = (
        <Box lineHeight="20px" height={'64px'}>
            <Typography variant="body1" color="primary">
                <Link href={texts.headerLink}>
                    <Translate i18nKey={texts.header} />
                    &nbsp;
                    {value}{' '}
                    {texts.ablativeCurrency ||
                        currencyNames[getCurrencyNameCase(value, true) as keyof typeof currencyNames].toLowerCase()}
                </Link>
            </Typography>
            <Box>
                <Typography variant="caption" color="GrayText" lineHeight="20px">
                    {formatDateSTD(transferDate, true)}
                </Typography>
            </Box>
            <Box>
                <Link to={texts.textLink}>
                    <Typography variant="caption" color="GrayText" lineHeight="20px">
                        <Translate i18nKey={texts.text} />
                    </Typography>
                </Link>
            </Box>
        </Box>
    );

    const separator = (
        <TimelineSeparator>
            <FlatTimelineDot>{texts.icon}</FlatTimelineDot>
            <TimelineConnector sx={{ height: '35px', mt: '18px' }} />
        </TimelineSeparator>
    );

    if (isDeleted) return null;

    if (!texts.rightSide) {
        return (
            <TimelineItem position="left" sx={{ textAlign: 'right' }}>
                {separator}
                <TimelineContent>{child}</TimelineContent>
            </TimelineItem>
        );
    }

    return (
        <TimelineItem position="right">
            {separator}
            <TimelineContent>{child}</TimelineContent>
        </TimelineItem>
    );
};
