import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import { i18n, Translate } from 'localization';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API } from 'utils/src/utils'
import { toggleAction } from '../../utils'

const handleContextActionDisableComments = function* handleContextActionDisableComments({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action.includes('disableComments')) {
        try {
            const answer = yield* call(confirmProise, {
                text: i18n.t('pryaniky.post.actions.confirm.' + action)
            })

            if (answer === 'cancle') {
                yield* put(ACT.contextActionCancle({
                    id,
                    action
                }))
                return;
            }

            const news = yield* select(SEL.getNewsById(id))

            const actions = toggleAction(news.actions, action)

            const request = yield* call(API.news.action, id, 23, news.allowReply)
            // @ts-ignore
            const result = yield request.r
            if (result.error_code === 0) {
                yield* put(ACT.pathNews({
                    id,
                    allowReply: !news.allowReply,
                    actions
                }))
            }
            else toast.error(result.error_text);

        } catch (error) {
            console.error('dismis', error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionDisableComments