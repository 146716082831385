/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    items: {
      type: types.array
    }
  }
}
