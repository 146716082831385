import React, { FC } from 'react';
import './Selector.scss'
import { Button } from 'uielements/src'
import { stringInArray } from '../Suggesters.utils'
import { i18n, Translate } from 'localization';

export const Selector: FC<any> = ({ activeTypes, toggleType, counts }) => {
    const toggle = (type: string) => () => toggleType(type)
    return <div className={'Selector'}>
        <Button
            theme={'unstyled_center'}
            className={'Selector-Button' + (!stringInArray(activeTypes, 'users') ? ' Selector-Button_active' : '')}
            onClick={toggle('users')}
            >
            <Translate i18nKey={'colleagues'} /> {counts.users !== 0 && `(${counts.users})`}
        </Button>
        <Button
            theme={'unstyled_center'}
            className={'Selector-Button' + (!stringInArray(activeTypes, 'groups') ? ' Selector-Button_active' : '')}
            onClick={toggle('groups')}
            >
            <Translate i18nKey={'groups'} /> {counts.groups !== 0 && `(${counts.groups})`}
        </Button>
        <Button
            theme={'unstyled_center'}
            className={'Selector-Button' + (!stringInArray(activeTypes, 'tags') ? ' Selector-Button_active' : '')}
            onClick={toggle('tags')}
            >
            <Translate i18nKey={'tags'} /> {counts.tags !== 0 && `(${counts.tags})`}
        </Button>
        <Button
            theme={'unstyled_center'}
            className={'Selector-Button' + (!stringInArray(activeTypes, 'news') ? ' Selector-Button_active' : '')}
            onClick={toggle('news')}
            >
            <Translate i18nKey={'news'} /> {counts.news !== 0 && `(${counts.news})`}
        </Button>
        <Button
            theme={'unstyled_center'}
            className={'Selector-Button' + (!stringInArray(activeTypes, 'pages') ? ' Selector-Button_active' : '')}
            onClick={toggle('pages')}
            >
            <Translate i18nKey={'pages'} /> {counts.pages !== 0 && `(${counts.pages})`}
        </Button>
    </div>
}