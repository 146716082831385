/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
import { JFdictionary } from "../dictionary";

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    hideIfNoData: {
      type: types.boolean
    },
    showDesc: {
      type: types.boolean
    },
  }
}
