import React, { FC, useRef, memo, useState, useCallback } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    KPI
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import Row from '../../components/Row'
import { /*Button,*/ Icon/*, ButtonBox*/ } from 'uielements/src'
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'uielements/src/MaterialElements/DatePicker/DatePicker'
// import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import FormControl from '@material-ui/core/FormControl';
import { i18n, Translate } from 'localization';
import { useChangeItem } from 'KPIModule/utils/hooks'
import { isValidDate } from 'utils/src/utils.dates'
import { Button } from 'muicomponents/src/Button/Button'
import withConfirmDialog from 'muicomponents/src/Button/HOCs/withConfirmDialog'
import ButtonGroup from '@mui/material/ButtonGroup';

const ConfirmButton = withConfirmDialog(Button)

type IPresenterProps = KPI.Item.IPresenterProps
type IOwnProps = KPI.Item.IOwnProps
type IDispatchProps = KPI.Item.IDispatchProps
type IStateProps = KPI.Item.IStateProps
type IState = KPI.Item.IState



export const Presenter: FC<IPresenterProps> = ({
    children,
    dueDate,
    goal,
    id,
    isComplete,
    name,
    userId
}) => {
    const {
        // item,
        onComplete: onCopliteEdit,
        setState,
        state
    } = useChangeItem(id, userId)



    const onChangeString = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const onChangeBool = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState({
            ...state,
            [event.target.name]: checked
        })
    }

    const onChangeDate = (date: Date) => {
        if (isValidDate(date))
            setState({
                ...state,
                dueDate: date.toISOString()
            })
    }

    const onEditComplete = () => {
        onCopliteEdit(true)
    }

    const onEditCancle = () => {
        onCopliteEdit(false)
    }

    return <Row
        className={cnClassName({}, [])}
        col1={
            isComplete.isVisible &&
            <FormControl fullWidth>
                <Checkbox
                    disabled={!isComplete.isEditable}
                    checked={state.isComplete}
                    name="isComplete"
                    color="primary"
                    onChange={onChangeBool}
                />
            </FormControl>}
        col2={
            name.isVisible &&
            <FormControl fullWidth>
                <TextField
                    multiline
                    disabled={!name.isEditable}
                    label={i18n.t("pryaniky.kpi.edit.name.label.1")}
                    name={'name'}
                    InputProps={{ inputProps: { minlength: 1, maxlength: 100 } }}
                    value={state.name}
                    onChange={onChangeString}
                />
            </FormControl>}
        col3={
            goal.isVisible &&
            <FormControl fullWidth>
                <TextField
                    multiline
                    disabled={!goal.isEditable}
                    label={i18n.t("pryaniky.kpi.edit.goal.label.1")}
                    name={'goal'}
                    value={state.goal}
                    onChange={onChangeString}
                />
            </FormControl>}
        col4={
            dueDate.isVisible &&
            <FormControl fullWidth>
                <DatePicker
                    disabled={!dueDate.isEditable}
                    onChange={onChangeDate}
                    value={state.dueDate}
                    label={i18n.t("pryaniky.kpi.edit.dueDate.label.1")} />
            </FormControl>}
        col5={
            <div className={cnClassName('Actions')}>
                <ButtonGroup variant="contained" className={cnClassName('ButtonBox')}>
                    <ConfirmButton
                        variant={'contained'}
                        title={i18n.t("pryaniky.kpi.button.save.title.1")}
                        confirmBtnText={i18n.t("pryaniky.kpi.confirm.button.saveedit.1")}
                        confirmTitle={i18n.t("pryaniky.kpi.confirm.title.saveedit.1")}
                        onConfirm={onEditComplete}>
                        <Icon icon={'save'} />
                    </ConfirmButton>
                    <ConfirmButton
                        variant={'text'}
                        title={i18n.t("pryaniky.kpi.button.rejectedit.title.1")}
                        confirmBtnText={i18n.t("pryaniky.kpi.confirm.button.rejectedit.1")}
                        confirmTitle={i18n.t("pryaniky.kpi.confirm.title.rejectedit.1")}
                        onConfirm={onEditCancle}>
                        <Icon icon={'times'} />
                    </ConfirmButton>
                </ButtonGroup>
            </div>
        }
    />
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
