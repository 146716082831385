import { keys, pick } from 'lodash';
import React, { ComponentType, FC, createContext, memo, useContext } from 'react';
import { IReplyModel, createDefaultReply } from '../types/reply';
export interface ReplyContextProps<N = IReplyModel> {
    newsId: string;
    replyId: string;
    reply: N;
    replyEdit: boolean;
    setEdit: (edit: boolean) => void;
    avatarSize: number;
    withRating: boolean;
}

const defaultContext: ReplyContextProps = {
    newsId: '',
    replyId: '',
    reply: createDefaultReply(),
    replyEdit: false,
    setEdit: (edit: boolean) => {},
    avatarSize: 40,
    withRating: false,
};

export const ReplyContext = createContext<ReplyContextProps>(defaultContext);

export function withReplyContext<P extends ReplyContextProps>(
    Component: ComponentType<P>,
    deps: (keyof ReplyContextProps)[] = keys(defaultContext) as any
) {
    const Wrapped: FC<Omit<P, keyof ReplyContextProps>> = (props) => {
        const value = pick(useContext(ReplyContext), deps);
        return <Component {...{ ...(props as any), ...value }} />;
    };
    return memo(Wrapped);
}
