import { connect } from 'react-redux';
import React, { FC } from 'react';
import { mapDispatchToProps } from "../../redux/connector";
import { cnDash, IDashProps, IDashState } from './Dash.index';
import { updateContext } from 'redux/actions/Widgets';

import './Dash.scss';
import { Page } from '../Page/Page';
import { i18n, Translate } from 'localization';
import { pageNames } from 'redux/pageNames';

const DashPr: FC<IDashProps> = () => {
  return (
    <Page 
      className={cnDash()}
      page={{
        name: pageNames.dash,
        title: i18n.t('dash')
      }} />
  );
};

export const Dash = connect(
  null,
  mapDispatchToProps(
    { updateContext }
  )
  )(DashPr)