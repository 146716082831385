import React, { useState } from 'react';
import { i18n, Translate } from 'localization';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { ImageLoader } from '../../ImageLoader/ImageLoader';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { emptyItem, ISliderLinkItem } from "blocks/WSettings/structures/sliderlink/uischema";
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import ReactSortable from 'react-sortablejs';
import { keyBy } from 'lodash';
import { IconButton } from 'muicomponents/src';
import { DragHandle } from 'muicomponents/src/Icons';

type ItemData = ISliderLinkItem & { id: string };

const convertToItemData = (item: ISliderLinkItem, idx: number): ItemData => ({
  ...item,
  id: `${item.imgId}/${idx}`
});

export const slider = (props: any) => {
  const [disableDZ, setDisableDZ] = useState(false);
  if (!props.visible) return null;
  const aspect = ((props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements as ISliderLinkItem[]).filter((el) => !el.deleted).reduce((acc, cur, idx) => idx === 0 ? cur.imgId : acc, GUID_EMPTY) !== GUID_EMPTY && props.data[get_variable_name(props.uischema.scope)].aspect || 0;
  const width = ((props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements as ISliderLinkItem[]).filter((el) => !el.deleted).reduce((acc, cur, idx) => idx === 0 ? cur.imgId : acc, GUID_EMPTY) !== GUID_EMPTY && props.data[get_variable_name(props.uischema.scope)].width || false;
  const height = !width ? 50 : undefined;
  const data = (props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements.map(convertToItemData);
  return (
    <div className={'SliderSettings'}>

      <ReactSortable
        className={'SliderSettingsSortable'}
        options={{
          handle: '.SliderSettingsSortable-Handle',
          animation: 200
        }}
        onChange={(newItems: string[]) => {
          props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
            const dict = keyBy((oldData.elements || []).map(convertToItemData), 'id');
            oldData.elements = newItems.map(id => {
              const item = dict[id];
              delete item.id;
              return item;
            });
            console.log('oldData.elements', oldData.elements);
            return { ...oldData };
          }));
        }}
      >
        {
          data.map((el: ItemData, idx: number) => {
            if (el.deleted) return null;
            return <div data-id={el.id} className={'SliderItem'}>
              <IconButton
                disableRipple
                className={'SliderSettingsSortable-Handle'}
              >
                <DragHandle />
              </IconButton>
              <ImageLoader
                squarePreview
                cropper
                cropperProps={{
                  aspect: aspect || false,
                  width: width || 50,
                  height
                  // heigth: heigth,
                }}
                startPreview={el.imgUrl}
                onLoadStart={(file: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                  oldData.aspect = file.aspect || 0;
                  oldData.width = file.width || 0;
                  // oldData.heigth = file.heigth || 0;
                  return { ...oldData };
                }))}
                onLoaded={(rez: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                  oldData.elements[idx].imgName = rez.name;
                  oldData.elements[idx].imgId = rez.id;
                  oldData.elements[idx].imgUrl = rez.originalUrl;
                  return { ...oldData };
                }))}
                children={
                  <>
                    <div children={`${i18n.t('pryaniky.widgets.settings.text.imageName')}: ${el.imgName}`} />
                    <UIElements.Input icon={'link'} onChange={(ev: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                      oldData.elements[idx].href = ev.currentTarget.value
                      return { ...oldData };
                    }))} value={el.href} placeholder={i18n.t('pryaniky.widgets.settings.placeholder.href')} />

                    <Checkbox
                      className={'SliderItem-Checkbox'}
                      onChange={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                        oldData.elements[idx].inactive = !el.inactive
                        return { ...oldData };
                      }))}
                      label={i18n.t('pryaniky.widgets.settings.placeholder.inactive')}
                      value={!!el.inactive}
                      style={{ margin: 0 }}
                    />

                    <Checkbox
                      className={'SliderItem-Checkbox'}
                      onChange={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                        oldData.elements[idx].openInNewTab = !el.openInNewTab
                        return { ...oldData };
                      }))}
                      label={i18n.t('pryaniky.widgets.settings.placeholder.openInNewTab')}
                      value={el.openInNewTab}
                      style={{ margin: 0 }}
                    />
                  </>
                } />
              <UIElements.Button
                className={'SliderItem-Button_remove'}
                onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                  // oldData.splice(idx, 1);
                  oldData.elements[idx].deleted = true;
                  return { ...oldData }
                }))}
                children={
                  <UIElements.Icon icon={'times'} />
                } />
            </div>
          })
        }
      </ReactSortable>
      <UIElements.Button
        className={'SliderItem-Button_add'}
        onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          oldData.elements.push({
            ...emptyItem,
            imgId: emptyItem.imgId
          });
          return { ...oldData }
        }))}
        children={
          i18n.t('pryaniky.widgets.settings.slider.addItem')
        } />
    </div>
  );
  return (
    <>
      {
        (props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements.map((el: ISliderLinkItem, idx: number) => {
          if (el.deleted) return null;
          return <div className={'SliderItem'}>
            <ImageLoader
              squarePreview
              cropper
              cropperProps={{
                aspect: aspect || false,
                width: width || 50,
                height
                // heigth: heigth,
              }}
              startPreview={el.imgUrl}
              onLoadStart={(file: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                oldData.aspect = file.aspect || 0;
                oldData.width = file.width || 0;
                // oldData.heigth = file.heigth || 0;
                return { ...oldData };
              }))}
              onLoaded={(rez: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                oldData.elements[idx].imgName = rez.name;
                oldData.elements[idx].imgId = rez.id;
                oldData.elements[idx].imgUrl = rez.originalUrl;
                return { ...oldData };
              }))}
              children={
                <>
                  <div children={`${i18n.t('pryaniky.widgets.settings.text.imageName')}: ${el.imgName}`} />
                  <UIElements.Input icon={'link'} onChange={(ev: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                    oldData.elements[idx].href = ev.currentTarget.value
                    return { ...oldData };
                  }))} value={el.href} placeholder={i18n.t('pryaniky.widgets.settings.placeholder.href')} />

                  <Checkbox
                    className={'SliderItem-Checkbox'}
                    onChange={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                      oldData.elements[idx].openInNewTab = !el.openInNewTab
                      return { ...oldData };
                    }))}
                    label={i18n.t('pryaniky.widgets.settings.placeholder.openInNewTab')}
                    value={el.openInNewTab}
                    style={{ margin: 0 }}
                  />
                </>
              } />
            <UIElements.Button
              className={'SliderItem-Button_remove'}
              onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                // oldData.splice(idx, 1);
                oldData.elements[idx].deleted = true;
                return { ...oldData }
              }))}
              children={
                <UIElements.Icon icon={'times'} />
              } />
          </div>
        })
      }
      <UIElements.Button
        className={'SliderItem-Button_add'}
        onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          oldData.elements.push(emptyItem);
          return { ...oldData }
        }))}
        children={
          i18n.t('pryaniky.widgets.settings.slider.addItem')
        } />
    </>
  )
}
// end SliderControl