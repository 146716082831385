import { IIdeaFieldProps } from '../FieldProps';
import React, { useState} from 'react';
import { useStyles } from 'blocks/InfoBlock/Group/_edit/InfoBlock-Group_edit_true.style';
import { InputMention } from 'blocks/InputMention/InputMention';
import FormControl from '@material-ui/core/FormControl';
import { cn } from "@bem-react/classname";
import './Tags.scss'
import { i18n, Translate } from 'localization';
import { IInputMentionProps} from '../../../../../blocks/InputMention/InputMention.index'

export const cnIdeaField = cn("IdeaField");

export const Tags: React.FC<IIdeaFieldProps> = ( { 
    field,
    onChange,
    expiresChange

} ) => {
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });
    const classes = useStyles();

    const onEdit = ( selectedTag: {[s: string]: any}) => {

        field.tags = selectedTag;
        if (field.field.isMandatory && field.tags === undefined) setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
        else setText({ value: field.value, valid: true, error: '' });
        if (onChange) onChange(field);
    }

    return (
        <FormControl component="fieldset" className={classes.formControl}>
          <InputMention 
                invalid={!text.valid} 
                errorMessage={text.error} 
                required={field.field.isMandatory}
                onChange={onEdit}
                type={'tags'}
                className={cnIdeaField("Input")}
                icon={'hash-tag'} 
                value={field.tags}
                placeholder={field.field.name || field.field.description}
                additionalRequestOpts={{
                    col: "ideas",
                    fieldId: field.fieldId
                }} />
                
        </FormControl>
   );
}