import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { v1 as uuid } from 'uuid';
import { mapDispatchToProps } from "../../redux/connector";
import { widgets } from "i.widgets";
import initFunctions from 'pIntegratingFunctions';
import * as widgetsUtils from "widgetsUtils";

import { cnWDialog, IWDialogProps, IWDialogState, mapStateToProps, mapActionsToPropsPage } from './WDialog.index';
// import { IWidgetProps } from '../Widget/Widget.index';

import './WDialog.scss';
import { IWidget } from "i.widgets";
import { store } from 'redux/store';
import { basename } from 'utils/src/utils';
import { map } from 'react-sortable-tree';
import { openShopMakeOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import { modalChangeDate } from 'redux/actions/Modals';

// when rendering these pages component wouldn't ask server for widgets
// const ignoredPages = ['groups', 'users', 'badge', 'tag', 'news', 'rating', 'shop', 'orders']

const pageName = 'widgets';

export class IWDialogPresenter extends React.Component<IWDialogProps, IWDialogState> {
  public el: HTMLElement | null;
  private dialog_type: string;
  private frame_id: string;
  private from_frame_id: string;

  constructor(props: IWDialogProps) {
    super(props);
    this.state = {
      isShow: false,
    };
    this.dialog_type = this.urlSearchObj.dialog_type;
    this.frame_id = this.urlSearchObj.frame_id;
    this.from_frame_id = this.urlSearchObj.from_frame_id;

    const fid = uuid();
    window.PryanikyRpc.init().functions[fid] = (data: any) => store.dispatch(modalChangeDate({ id: this.frame_id, data }));
    window.PryanikyRpc.message.post({ name: 'get', type: 'modal_data', to_frame: this.frame_id, function_id: fid }, 'top');
  }

  private get urlSearchObj() {
    return this.props.location.search.substring(1).split('&').reduce((acc, cur) => {
      const cur_data = cur.split('=');
      acc[cur_data[0]] = cur_data[1];
      return acc;
    }, {} as any)
  }

  public componentDidMount() {
    window.PryanikyRpc.message.post({ name: 'modal', type: 'loaded', frame_id: this.frame_id }, 'top');
    setTimeout(() => this.setState({ isShow: true }), 10);
  }

  public render() {
    switch (this.dialog_type) {
      case 'MakeOrderDialog': {
        return <div className={cnWDialog({ id: this.frame_id })}>
          {openShopMakeOrder({}).then((value)=>this.onConfirm(value)).catch(()=>{})}
          {/* <MakeOrderDialog
            id={this.frame_id}
            data={{}}
            isShown={this.state.isShow}
            onConfirm={this.onConfirm}
            onClose={this.onClose}
          /> */}
        </div>
      }
      default:
        return (
          <div className={cnWDialog({ id: this.frame_id })}>
            {'DIALOG'}
          </div>
        )
    }
  }

  private onConfirm = (...args: any) => {
    const { from_frame_id, frame_id } = this;
    window.PryanikyRpc.message.post({ name: 'modal', type: 'confirm', to_frame: from_frame_id, frame_id, data: args }, 'top');
    this.onClose();
  } 

  private onClose = () => {
    const { frame_id } = this;
    this.setState({ isShow: false });
    // window.PryanikyRpc.message.post({ name: 'modal', type: 'confirm', to_frame: from_frame_id, frame_id, data: args }, 'top');
    setTimeout(() => window.PryanikyRpc.message.post({ name: 'modal', type: 'remove', frame_id }, 'top'), 300);
  } 

}

export const WDialog = connect(
  mapStateToProps,
  mapActionsToPropsPage
)(IWDialogPresenter)
