import React, { FC, useState, useEffect, useRef, ComponentProps } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { SettingsRemindFillProfileWidget } from '../RemindFillProfileWidget.index';
import {
    NSettingsfillProfileWidgetDialog,
    cnSettingsfillProfileWidgetDialog
} from './SettingsRemindWidgetDialog.index'

import {
    Button,
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    PageCollectionSuggester
} from 'muicomponents/src';
import { i18n, Translate } from 'localization';
import { NumberTextField } from 'muicomponents/src/TextField/TextField'
import { WidgetDialogBox, FormControlLabelStyled } from '../RemindFillProfileWidget.styled'
import { useDidUpdateEffect } from 'utils/src/hooks';
import { useFieldHelperText } from 'muicomponents/src/FieldRender/FieldRender.hooks';
import { FormControl } from 'muicomponents/src/FormControl';
import { PreviewSelector } from 'muicomponents/src/PreviewSelector/PreviewSelector'
import { ImageCropperDialog } from 'blocks/ImageLoaderWithMui/ImageCropper/ImageCropper'

export const FillProfileWidgetDialog: FC<NSettingsfillProfileWidgetDialog.Props> = ({
    onChange,
    data
}) => {

    const [state, setState] = useState<SettingsRemindFillProfileWidget.Settings>(data || {  titleImageId: "", titleImageUrl: "" });
    const [imgChanged, setImgChanged] = React.useState(false);

    useEffect(() => {
        onChange({
            // дата для обновления при изменении настроек
            data: {
                titleImageId: state.titleImageId,
                titleImageUrl: state.titleImageUrl
            },
            // это при добавллении виджет
            settings: {
                titleImageId: state.titleImageId,
                titleImageUrl: state.titleImageUrl
            }
        })

    }, [state]);

    const changeHideIfNoData = () => setState({ ...state, hideIfNoData: !state.hideIfNoData })

    return (<WidgetDialogBox className={cnSettingsfillProfileWidgetDialog("")}>


        <PreviewSelector
            sx={{
                justifyContent: "start!important",
                ".PreviewSelector-HelperText": {
                    textTransform: "initial!important"
                }
            }}
            // aria-describedby={actualData1.id}
            className={cnSettingsfillProfileWidgetDialog("PreviewSelector")}
            onClick={(e: any) => {

                ImageCropperDialog({
                    // editOpen: isEdit,
                    onLoaded: (file: any) => {
                        setState({ ...state, titleImageId: file.id, titleImageUrl: file.originalUrl })
                        setImgChanged(true)
                    }
                })

            }}
            previewUrl={state.titleImageUrl}
            newImg={imgChanged}
            label={<Translate i18nKey='pryaniky.widgets.settings.type.FillProfileWidget.label' />}
            helperText={<Translate i18nKey='pryaniky.widgets.settings.type.FillProfileWidget.help' />}
        />
      


    </WidgetDialogBox>)
}
export const FillProfileWidgetDialogPresenterWithContext: FC<ComponentProps<typeof FillProfileWidgetDialog>> = ({
    ...props
}) => {

    return (

        <FillProfileWidgetDialog {...props} />
    );
};


export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', FillProfileWidgetDialogPresenterWithContext, 'pryaniky/remindFillProfile')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', FillProfileWidgetDialogPresenterWithContext, 'pryaniky/remindFillProfile')

