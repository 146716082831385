import React, { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import { Button } from "uielements/src";
import './Timer.scss';
import { i18n, Translate } from 'localization';

export const cnTimer = cn("Timer");

export interface ITimerProps {
    onClick: () => void
}

// добавляем 0 перед однозначиными числами
// превращаем число в строку, так как для числа нет метода .length
const padTime = (time: any) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = (time: any) => {
    // Конвертирует секунды в минуты
    const minutes = Math.floor(time / 60);

    // показывает сколько секунда осталось после конвертации в минуты
    const seconds = time % 60;

    //Возвращает строку в формате mm:ss
    return `${minutes}:${padTime(seconds)}`;
};

export const Timer: FC<ITimerProps> = ({ onClick }) => {
    const [counter, setCounter] = React.useState(150);
    React.useEffect(() => {
        let timer: any;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(c => c - 1), 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter]);

    const newTimer = () => {
        setCounter(150);
        onClick();
    }
    return (
        <div className={cnTimer("")}>
            {counter === 0 ? <>
                <p>{i18n.t("pryaniky.widgets.mobileApp.login.time.out")}</p>
                <Button main onClick={newTimer}> {i18n.t("pryaniky.widgets.mobileApp.login.getNewCode")}</Button>
            </> :
                <span>{i18n.t("pryaniky.widgets.mobileApp.login.set.timer")}{format(counter)}</span>}
        </div>
    );
}

export default Timer;