import { i18n, Translate } from 'localization';
import { Box, CommentDialog, Typography } from 'muicomponents/src';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { Link } from 'muicomponents/src/Link';
import React, { ComponentProps, FC, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { toast } from 'react-toastify';
import { useDSItem } from 'redux/dataStorage/hooks';
import { checkResponseStatus, mainUrls, ResponseError } from 'utils/src';
import { UserVacancyStatus, Vacancy } from 'utils/src/BaseTypes/vacancy';
import { moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { vacancyUserRequestAction } from 'utils/src/requests/admin.vacancies';
import { MatchBlock } from '../../MatchBlock';

const Text: FC<{
    textKey: string;
}> = ({ textKey, children }) => {
    return (
        <Typography fontSize={'small'}>
            <Translate i18nKey={textKey} />
            :&nbsp;
            {children}
        </Typography>
    );
};

const allowedApplyActions: (keyof typeof UserVacancyStatus)[] = ['InvitedToInterview', 'CancelledByHR'];

const allowedInvitedToInterviewActions: (keyof typeof UserVacancyStatus)[] = ['Completed', 'CancelledByHR'];

export const VacancyRequestedUsersListItem: FC<{
    vacancyId: Vacancy['id'];
    id: Vacancy['requests'][number]['id'];
}> = ({ vacancyId, id }) => {
    const { item: data, changeItem } = useDSItem('vacanciesRequests', id);
    const dataRef = useRef(data);
    dataRef.current = data;

    const comments = useMemo(() => {
        if (!data) return null;
        return (
            <Box>
                {data.comment && <Text textKey="pryaniky.vacancy.request.comment">{data.comment}</Text>}
                {data.hrNote && <Text textKey="pryaniky.vacancy.request.hrNote">{data.hrNote}</Text>}
                {data?.file && (
                    <Text textKey="pryaniky.vacancy.request.file">
                        <Link href={data?.file.originalUrl}>{data?.file.name}</Link>
                    </Text>
                )}
            </Box>
        );
    }, [data?.comment, data?.hrNote, data?.file]);

    const isAllowedEndorsements = useSelector(moduleIsEnabled('endorsements'));

    const options = useMemo<ComponentProps<typeof UsersListItem>['options']>(() => {
        if (!data) return undefined;
        let actions: typeof allowedApplyActions = [];
        switch (data?.status) {
            case 'Apply':
                actions = allowedApplyActions;
                break;
            case 'InvitedToInterview':
                actions = allowedInvitedToInterviewActions;
                break;
        }
        if (!actions.length) return undefined;
        return actions.map((el) => {
            return {
                content: <Translate i18nKey={`pryaniky.vacancy.user.action.${el}`} />,
                id: el,
            };
        });
    }, [data?.status]);

    const sendAction = useCallback(
        async function (action: NonNullable<ComponentProps<typeof UsersListItem>['options']>[number]) {
            if (!dataRef.current) return;
            try {
                const { comment } = await CommentDialog({
                    header: <Translate i18nKey={'pryaniky.vacancy.request.comment.header'} />,
                    acceptButtonText: <Translate i18nKey={'send'} />,
                });
                const response = await vacancyUserRequestAction({
                    id: vacancyId,
                    action: action.id,
                    model: {
                        ...dataRef.current,
                        status: action.id,
                        hrNote: comment,
                    },
                });
                if (checkResponseStatus(response)) {
                    toast.success(<Translate i18nKey={'pryaniky.vacancy.request.changestatus.success'} />);
                    changeItem({
                        status: action.id as typeof dataRef.current.status,
                        hrNote: comment,
                    });
                } else {
                    throw new ResponseError('ChangeStatusError');
                }
            } catch (e) {
                if (e instanceof ResponseError) {
                    toast.error(<Translate i18nKey={'pryaniky.vacancy.request.changestatus.error'} />);
                } else {
                    console.error('change sattus error', e);
                }
            } finally {
            }
        },
        [vacancyId]
    );

    const status = useMemo(() => {
        if (!data || !data.status || data.status === 'NotSet') return null;
        return (
            <Box>
                <Text textKey="pryaniky.vacancy.request.status">
                    <b>
                        <Translate i18nKey={`pryaniky.vacancy.user.status.${data.status}`} />
                    </b>
                </Text>
            </Box>
        );
    }, [data?.status]);

    if (!data) return null;

    return (
        <UsersListItem<any>
            item={{
                ...data.user,
                url: generatePath(mainUrls.user.id, { id: data.user.id }),
            }}
            onClickAction={(action) => {
                sendAction(action);
            }}
            options={options}
            actions={{
                appendItems: () => {},
                findItem: () => {},
                prependItems: () => {},
                removeItems: () => {},
                updateItem: () => {},
            }}
        >
            {isAllowedEndorsements && (
                <MatchBlock matchPercent={data.user.matchPercentage} skills={data.user.skillMatchTags || []} />
            )}

            {comments}
            {status}
        </UsersListItem>
    );
};
