import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import { Input } from 'uielements/src/Input/Input';
import { i18n, Translate } from 'localization';
// import { Editor as EditorInput } from '../../../Editor/Editor';
import { PryanikyEditorContexted as PryanikyEditor} from '../../../PryanikyEditor/_PryanikyEditor'

export const Editor: React.FC<IEditorProps> = ({ children, data, onChangeTitle, onChangeText }) => {

    return <div className={'Editor EditorNotices'}>
        <Input
            onChange={onChangeTitle}
            className={'EditorNotices-Title'}
            value={data.header}
            icon='bell'
            placeholder={i18n.t('pryaniky.createPost.announcement.name')} />
        <PryanikyEditor value={data.text} onChange={onChangeText} placeholder={i18n.t('pryaniky.createPost.announcement.text')}/>
        {/*<EditorInput
            onChange={onChangeText}
            value={data.text}
            // className={cnBody('Editor')}
        placeholder={i18n.t('pryaniky.createPost.announcement.text')} />*/}

        {children}
    </div>
}
