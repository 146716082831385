import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';

import { cnBody, IBodyProps, IBodyState } from './Modal-Body.index';
import { withBodyTypeMenuItemChange } from "./_type/Modal-Body_type_menuItemChange";
import { withBodyTypeThanks } from './_type/Modal-Body_type_thanks';
import { withBodyTypeAchievement } from "./_type/Modal-Body_type_achievement";
import { withBodyTypeCreateGroup } from './_type/Modal-Body_type_createGroup';
import { withBodyTypeLinkToNews } from './_type/Modal-Body_type_linkToNews';
import { withBodyTypeConfirm } from './_type/Modal-Body_type_confirm';
import { withBodyTypeGroupList } from './_type/Modal-Body_type_groupList';

import './Modal-Body.scss';
import { withBodyTypeImageCrop } from './_type/Modal-Body_type_imageCrop';
import { withBodyTypeLayoutGrid } from './_type/Modal-Body_type_layoutGrid';
import { withBodyTypeLayoutWidth } from './_type/Modal-Body_type_layoutWidth';
import { withBodyTypeSelectWidget } from './_type/Modal-Body_type_selectWidget';
import { withBodyTypeLikers } from './_type/Modal-Body_type_likers';
import { withBodyTypePollAnswerParticipants } from './_type/Modal-Body_type_pollAnswerParticipantsProps';
import { withBodyTypeAddWidget } from './_type/Modal-Body_type_addWidget';

export class BodyPresenter extends React.Component<IBodyProps, IBodyState> {
  public el: HTMLElement | null;

  public render() {
    const customClassNames = '';
    const {tag: TagName = 'div', className = ''} = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnBody({ hi: true }, [className, customClassNames])}>
        {this.props.children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Body = compose(
  // withBemMod(cnBody(), {}),
  withBodyTypeMenuItemChange,
  withBodyTypeThanks,
  withBodyTypeAchievement,
  withBodyTypeCreateGroup,
  withBodyTypeImageCrop,
  withBodyTypeLinkToNews,
  withBodyTypeConfirm,
  withBodyTypeGroupList,
  withBodyTypeLayoutGrid,
  withBodyTypeLayoutWidth,
  withBodyTypeSelectWidget,
  withBodyTypeLikers,
  withBodyTypePollAnswerParticipants,
  withBodyTypeAddWidget
)(BodyPresenter)
