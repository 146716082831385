import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeUsersProps } from './Widget_type_users.index';
import './Widget_type_users.scss';
import { widgets } from "i.widgets";
import { default as WidgetPresenter } from '../Widget';

const types = {
  common: require('blocks/UsersWidget/UsersWidget').UsersWidget,
  subscribers: require('blocks/UsersWidget/_type/UsersWidget_type_subscribers').UsersSubscribersWidget,
  subscriptions: require('blocks/UsersWidget/_type/UsersWidget_type_subscriptions').UsersSubscriptionsWidget,
  participants: require('blocks/UsersWidget/_type/UsersWidget_type_participants').UsersParticipantsWidget,
  gadmins: require('blocks/UsersWidget/_type/UsersWidget_type_gadmins').UsersParticipantsWidget,
  group: require('blocks/UsersWidget/_type/UsersWidget_type_group').UsersGroupWidget,
  hierarchyTop: require('blocks/UsersWidget/_type/UsersWidget_type_hierarchy').UsersHierarchyWidgetTop,
  hierarchyDown: require('blocks/UsersWidget/_type/UsersWidget_type_hierarchy').UsersHierarchyWidgetDown,
  hierarchy: require('blocks/UsersWidget/_type/UsersWidget_type_hierarchy').UsersHierarchyWidgetDown,
  list: require('blocks/UsersWidget/_type/UsersWidget_type_list').UsersListWidget,
}

const type = 'users';

export default class WidgetTypeUsersPresenter extends WidgetPresenter<IWidgetTypeUsersProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} className={cnWidget({type, subtype})} context={this.props.wcontext} settings={this.props.widget.settings} data={this.props.widget.data} contexts={this.relations.concat(this.props.widget.id)} tag={widgets.components.common} />
  }

}