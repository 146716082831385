import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { Select } from 'uielements/src/Select/Select';
import { IOption } from 'uielements/src/Select/Select.index';
import { cn } from "@bem-react/classname";
import './Single.scss';
import { i18n, Translate } from 'localization';

export const cnIdeaField = cn("IdeaField");
export const Single: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });

    // const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     field.onblur = () => {
    //         if (field.field.isMandatory && field.value === '') setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
    //         else setText({ value: field.value, valid: true, error: '' });
    //     }
    // }
    const onSelect = (selected: IOption) => {
        field.value = selected ? selected.value : '';
        if (field.field.isMandatory && field.value === "" ) setText({value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
        else setText({ value: field.value, valid:true, error: '' });
        onChange(field);
    }
    const options: IOption[] = field.field.answers.map(answer => ({ value: answer.id, title: answer.text }));
    const selected: IOption | undefined = options.find(o => o.value === field.value);
    return <Select  invalid={!text.valid} errorMessage={text.error} required={field.field.isMandatory}  className={cnIdeaField("Single")} placeholder={field.field.name || field.field.description} options={options} onChange={onSelect} selected={selected} />;
}