import { NothingFoundText } from 'blocks/ListMui/_type/NotingFoundText';
import { Translate } from 'localization';
import searchPlaceholder from 'muicomponents/src/assets/svg/searchPlaceholder.svg';
import { Button } from 'muicomponents/src/Button/Button';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
import { ListPlaceholder } from 'muicomponents/src/ListPlaceholder/ListPlaceholder';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ListLite } from 'uielements';
import { adminUrls } from 'utils/src';
import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';
import { appGeneratePath } from 'utils/src/utils.path';
import { VacanciesListItemWrapper } from '../ListItem/VacanciesListItem';
import { getVacanciesReducerFields } from '../redux/selectors.vacancies';
import { useVacanciesList } from './VacanciesList.hooks';
import { cnVacanciesList } from './VacanciesList.Index';
import {
    VacanciesCheckButtonSelectorBox,
    VacanciesEmptyListTextBox,
    VacanciesHeaderBox,
    VacanciesHeaderItemBox,
    VacanciesListBox,
    VacanciesSearchInput,
} from './VacanciesList.styled';

export const VacanciesList: FC<{}> = ({}) => {
    const { list, vacancyFilters, error, loading, isFinished, loadMore, search, setSearch, filter, setFilter } =
        useVacanciesList();

    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const isFullAccess = useSelector(getVacanciesReducerFields('fullAccess')).fullAccess;
    const isEmptyList = list.length === 0;
    const filterOptions = useMemo(() => {
        return vacancyFilters.map((e) => {
            return {
                value: e,
                label: Translate.t({ i18nKey: `pryaniky.vacancies.list.filter.${e}` }),
            };
        });
    }, [vacancyFilters]);

    return (
        <>
            <VacanciesHeaderBox className={cnVacanciesList('Header')}>
                <VacanciesHeaderItemBox>
                    <VacanciesSearchInput
                        label={<Translate i18nKey={'search'} />}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <VacanciesCheckButtonSelectorBox>
                        <CheckButtonSelector
                            value={filter}
                            onChange={(newValue) => {
                                setFilter(newValue as typeof filter);
                            }}
                            options={filterOptions}
                        />
                    </VacanciesCheckButtonSelectorBox>

                    {(isAdmin || isFullAccess) && (
                        <Button variant="contained" href={appGeneratePath(`/administration${adminUrls.vacancy.root}`)}>
                            <Translate i18nKey="pryaniky.vacancies.list.create" />
                        </Button>
                    )}
                </VacanciesHeaderItemBox>
            </VacanciesHeaderBox>
            <VacanciesListBox className={cnVacanciesList('Header')}>
                <ListLite isFinished={isFinished} isLoading={loading} loadMore={loadMore}>
                    {list.map((id, index) => {
                        return (
                            <VacanciesListItemWrapper
                                key={id}
                                id={id}
                                isAdmin={isAdmin}
                                isAdminPanel={false}
                                isLastItem={index === list.length - 1}
                            />
                        );
                    })}
                    {isEmptyList && !loading && (
                        <VacanciesEmptyListTextBox>
                            <ListPlaceholder
                                className="Placeholder"
                                isFinished={isFinished}
                                isLoading={loading}
                                itemsCount={list.length}
                                titleEmpty={''}
                                textEmpty={<NothingFoundText />}
                                imgEmpty={searchPlaceholder}
                            />
                        </VacanciesEmptyListTextBox>
                    )}
                </ListLite>
            </VacanciesListBox>
        </>
    );
};
