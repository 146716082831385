import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { Textarea } from 'uielements/src/Textarea/Textarea';
import './Textarea.scss'
import { cn } from "@bem-react/classname";
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src';
import { i18n, Translate } from 'localization';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const cnIdeaField = cn("IdeaField");

export const TextArea: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const [text, setText] = React.useState({ value: '', valid: true, error: i18n.t('') });
    // const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     event.target.onblur = () => {
    //         if (field.field.isMandatory && field.value === '') setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
    //         else setText({ value: field.value, valid: true, error: '' });
    //     }
    // }
    const onEdit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        field.value = event.target.value;
        event.target.onfocus = () => {
            setText({ value: field.value, valid: true, error: '' });
        }
        event.target.onblur = () => {
            if (field.field.isMandatory && field.value === '') setText({ value: field.value, valid: false, error: i18n.t('поле не должно быть пустым') });
            else setText({ value: field.value, valid: true, error: '' });
        }
        onChange(field);
    }

    return (
        <Textarea className={cnIdeaField("Textarea")} icon='edit' invalid={!text.valid} errorMessage={text.error} required={field.field.isMandatory} placeholder={field.field.name || field.field.description} value={field.value} onChange={onEdit} >
            {/* <HelpTooltip className={cnIdeaField("HelpTooltip")} help={field.field.description}><Icon icon={'question-circle'} /></HelpTooltip>  */}
            {field.field.description ?
                // <HelpTooltip className={cnIdeaField("HelpTooltip")} help={field.field.description}>
                <Tooltip
                    className={cnIdeaField("HelpTooltip")}
                    // className={'InfoIcon'}
                    title={field.field.description}
                >
                    <span><Icon icon={'question-circle'} /></span>
                </Tooltip> : undefined}
        </Textarea>
    );
}