import { IChangeStateRenderProps } from './ChangeStateRender.index';
import * as React from 'react'
import './ChangeStateRender.scss'
import { DistributeRender } from '../DistributeRender/DistributeRender'
import { Comment, CommentWithAdditional } from '../StageChangeForm/StageChangeForm'
import { i18n, Translate } from 'localization';

export const ChangeStateRender: React.FC<IChangeStateRenderProps> = ({
    ideaId,
    status,
    save,
    cancle,
    fund,
    distributeMultiplicator,
    isFundDistributed
}) => {

    let content = <div>Load</div>;

    const onSave = () => save(status);

    if (status.isDistributeFund && !isFundDistributed) {
        content = <DistributeRender distributeMultiplicator={distributeMultiplicator} fund={fund} ideaId={ideaId} statusId={status.id} save={onSave} cancle={cancle} />;
    } else if (status.isExpertEvaluation) {
        content = <Comment ideaId={ideaId} status={status} save={onSave} cancle={cancle} />;
    } else {
        content = <CommentWithAdditional ideaId={ideaId} status={status} save={onSave} cancle={cancle} />;
    }
    return <div className={'ChangeStateRender'} style={{ width: "450px", maxWidth: "450px" }}>
        <h3 className={'ChangeStateRender-Header'}>{i18n.t('pryaniky.idea.change.state')}: {status.name}</h3>
        {content}
    </div>;
}