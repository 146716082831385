import React, { FC, ComponentProps, useRef, useMemo } from "react";
import { Height as HeightIcon, DropHeight as DropHeightIcon } from 'muicomponents/src/Icons';
import { RowSizeChanger } from '../RowSizeChanger/RowSizeChanger';
import ReactSortable from 'react-sortablejs';
import { NRow } from './Section.WidgetLayoutHorizontal.index';
import { BoxRow, RowActions, StyledAddWidgetButton, PositionGrid } from './Section.WidgetLayoutHorizontal.styled';
import { DeleteForever, Add, PlaylistAdd, ViewWeek } from 'muicomponents/src/Icons';
import { useDidUpdateEffect } from "utils/src/hooks";
import { useGlobalStoreForWidget, useWidgetDrag } from "Widgets_v2/hooks";
import { i18n, Translate } from 'localization';
import { DialogWidgetAdd } from "blocks/Dialogs/Widgets/AddNew/WidgetsAddNew";
import { actionsBlockDataId, sizingByCount } from 'Widgets_v2/constants';
import { Position } from '../Position';
import { useEnvironmentContex } from "utils/src/EnvironmentContex";

const maxWidgetsByRow = 4;

const Section: FC<ComponentProps<typeof BoxRow> & {
    component: any;
    componentProps: {[s: string]: any};
}> = ({
    componentProps,
    ...props
}) => {
    return (
        <BoxRow {...props} {...componentProps} />
    );
};

export const Row: FC<NRow.Props> = ({
    widgetId,
    row,
    addNextColumn,
    removeColumn,
    addWidgetHandler,
    section,
    sectionBaseHeight,
    changeSection,
    changeSectionBaseHeight,
    onDraged,
    children,
    isLastSection,
    ...props
}) => {

    const sortableRef = useRef<any>({});

    const {
        edit
    } = useGlobalStoreForWidget();

    useDidUpdateEffect(() => {
        if(!sortableRef.current) return;
        // change disabled option at sortablejs to init DND
        Object.keys(sortableRef.current).map((key: any) => {
            if(sortableRef.current) sortableRef.current.option('disabled', !edit);
        });
    }, [edit]);

    const {
        id
    } = row;

    const userCanAddWidget = useMemo(() => section.length < maxWidgetsByRow, [section.length]);

    const changeWidgetsView = () => RowSizeChanger({
        items: section,
    }).then(d => {
        changeSection(id, d.changed);
    });

    const setHeigth = () => {
        if(!sortableRef.current) return;
        const maxHeight = Math.max(...Array.from(sortableRef.current.el?.children || []).map((el: any) => el.clientHeight - parseFloat(window.getComputedStyle(el).paddingTop)));
        changeSectionBaseHeight(id, maxHeight);
    };

    const dropHeigth = () => {
        changeSectionBaseHeight(id, undefined);
    };

    const removePosition = (idx: number) => () => {
        changeSection(id, section.filter((_, i) => i !== idx).map((el, _, arr) => ({ ...el, size: sizingByCount[arr.length] })));
    }

    const {
        onDragStart,
        onDragEnd,
        onDragged,
        onWidgetAdd
    } = useWidgetDrag(widgetId);

    const {
        isMobileOS
    } = useEnvironmentContex();

    return (
        <>
            {
                edit &&
                <RowActions
                    variant='outlined'
                    data-id={actionsBlockDataId}
                >
                    <Translate i18nKey={'title:pryaniky.widget.add'}>
                        <StyledAddWidgetButton
                            fullWidth
                            disabled={!userCanAddWidget}
                            onClick={() => DialogWidgetAdd({ columnId: id }).then((newWidget) => addWidgetHandler(newWidget, section.length))}
                        >
                            <Add />
                        </StyledAddWidgetButton>
                    </Translate>
                    <Translate i18nKey={'title:pryaniky.widget.line.setHeight'}>
                        <StyledAddWidgetButton
                            name={id}
                            onClick={setHeigth}
                        >
                            <HeightIcon />
                        </StyledAddWidgetButton>
                    </Translate>
                    <Translate i18nKey={'title:pryaniky.widget.line.dropHeight'}>
                        <StyledAddWidgetButton
                            name={id}
                            onClick={dropHeigth}
                        >
                            <DropHeightIcon />
                        </StyledAddWidgetButton>
                    </Translate>
                    {
                        ![1, 4].includes(row.items.length) &&
                        <Translate i18nKey={'title:pryaniky.widget.line.change'}>
                            <StyledAddWidgetButton
                                name={id}
                                onClick={changeWidgetsView}
                            >
                                <ViewWeek />
                            </StyledAddWidgetButton>
                        </Translate>    
                    }                
                    <Translate i18nKey={'title:pryaniky.widget.line.add'}>
                        <StyledAddWidgetButton
                            name={id}
                            onClick={addNextColumn}
                            // className={cnWidget('Column-RemoveWidget')}
                        >
                            <PlaylistAdd />
                        </StyledAddWidgetButton>
                    </Translate>
                    <Translate i18nKey={'title:pryaniky.widget.line.remove'}>
                        <StyledAddWidgetButton
                            name={id}
                            onClick={removeColumn}
                        >
                            <DeleteForever />
                        </StyledAddWidgetButton>
                    </Translate>
                </RowActions>
            }
            <Section
                id={id}
                className={'Column'}
                container
                spacing={3}
                component={ReactSortable}
                componentProps={{
                    ref: (ref: any) => {
                        // define ref to change desabled state because in option on props change not work
                        sortableRef.current = ref?.sortable;
                    },
                    options: {
                        group: id,
                        direction: 'horizontal',
                        onStart: onDragStart,
                        onEnd: onDragEnd,
                        onAdd: onWidgetAdd,
                        dataIdAttr: 'grid-id',
                        disabled: !edit
                    },
                    onChange: onDraged
                }}
                sx={{
                    minHeight: !section?.length ? '36px' : undefined
                }}
            >
                {
                    section?.map((el, idx, arr) => {
                        return (
                            <PositionGrid
                                item
                                key={el.id}
                                xs={isMobileOS ? 12 : el.size || sizingByCount[arr.length]}
                                grid-id={el.id}
                            >
                                <Position
                                    sectionId={id}
                                    widgetId={el.widgetId}
                                    collapsedSize={isMobileOS ? 'auto' : sectionBaseHeight}
                                    addWidgetHandler={(newWidget) => addWidgetHandler(newWidget, idx)}
                                    remove={removePosition(idx)}
                                    disableCollapse={isMobileOS ? true : isLastSection}
                                />
                            </PositionGrid>
                        );
                    })
                }
            </Section>
        </>
    )
};