import React, { FC, useState, useMemo } from 'react';
import * as utils from "utils/src/utils";
import { i18n, Translate } from 'localization';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
import {
  // CheckboxInput,
  Dialog,
  // DialogTitle,
  Input,
  Textarea,
  DialogText,
  // DialogActions,
  Button,
  cnDialog,
} from 'uielements/src';
import { InputMention } from 'blocks/InputMention/InputMention';
import { ImageLoader } from 'blocks/ImageLoader/ImageLoader';
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';
import { ICreateGroupDialogProps, ICreateGroupDialogOwnProps, ICreateGroupDialogState, cnCreateGroup } from './GroupCreate.index';
import { connect } from 'react-redux';
import './GroupCreate.scss';
import { history } from 'redux/browserHistory';
import urls from 'routes/urls';
import { toast } from 'react-toastify';
import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import { excludeFilter } from 'blocks/PrnUserInput/PrnUserInput';
import RadioGroup from 'uielements/src/MaterialElements/RadioGroup';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { getNewPage, mainUrls, PageTypes, ResponseError } from 'utils/src';
import { generatePath } from 'react-router';
import pages from 'pageStructures';
import { getWikilistSettings } from 'blocks/WikiListNew/List_type_wiki.utils';
import { getTabSelectedFolderByAlias } from 'blocks/Dialogs/Widgets/TabsSettingsMui/TabsSettings.utils';
import { wikilistAliasPatterns } from 'utils/src/constants.prn';

class SetNewGroupSctructureResponseError extends ResponseError {
  
}

class CreateNewGroupResponseError extends ResponseError {

}

const GroupCreatePresenter: FC<ICreateGroupDialogProps> = ({
  handleAccept,
  handleClose
}) => {
  const [state, setState] = useState<ICreateGroupDialogState>({
    creating: false,
    name: '',
    description: '',
    // parentGroup: any,
    isHidden: false,
    isClosed: false,
    img: undefined,
    parentGroup: undefined,
    createCalendarByDefault: false
  })

  const onConfirm = async function () {
    if (state.creating) return;
    try {
      setState({ ...state, creating: true });
      const opts = {
        name: state.name,
        displayName: state.name,
        description: state.description,
        imgId198x198: state.img && state.img.id,
        RoorIdGroup: state.parentGroup ? state.parentGroup.pkid : -1,
        isHidden: state.isHidden,
        isArchived: false,
        isDeleted: false,
        isSystemGroup: false,
        childGroupCount: 0,
        createCalendarByDefault: state.createCalendarByDefault,
        visibilityType: +!state.isClosed//закрытая 1/ открытая 0
      }
      const createResponse: any = await utils.API.groups.create(opts).r;
      if (utils.checkResponseStatus(createResponse)) {
        const groupId = createResponse.data.pkid || createResponse.data.PKID;
        const newPageForGroup: ReturnType<typeof getNewPage> = {
          ...getNewPage(),
          type: PageTypes.group,
          url: generatePath(mainUrls.group.id, { id: groupId }),
          layout: pages.group({
            tabs: {
              wikilist: getWikilistSettings({
                folder: getTabSelectedFolderByAlias(wikilistAliasPatterns.replacePatternParamsByMatch(wikilistAliasPatterns.group.wikilist, { id: groupId }))
              }),
              imageGallery: getWikilistSettings({
                folder: getTabSelectedFolderByAlias(wikilistAliasPatterns.replacePatternParamsByMatch(wikilistAliasPatterns.group.imageGallery, { id: groupId }))
              }),
              videoGallery: getWikilistSettings({
                folder: getTabSelectedFolderByAlias(wikilistAliasPatterns.replacePatternParamsByMatch(wikilistAliasPatterns.group.videoGallery, { id: groupId }))
              })
            }
          }) as any
        };
        const setBaseScructureResponse = await utils.API.pages.set(newPageForGroup).r;
        if(utils.checkResponseStatus(setBaseScructureResponse)) {
          history.push(newPageForGroup.url);
          handleClose && handleClose();
        } else {
          throw new SetNewGroupSctructureResponseError('Set group scructure error', { error_code: setBaseScructureResponse.error_code, error_text: setBaseScructureResponse.error_text });
        }
      } else {
        throw new CreateNewGroupResponseError('Create new group error', { error_code: createResponse.error_code, error_text: createResponse.error_text });
      }
    } catch (e) {
      if(
        e instanceof CreateNewGroupResponseError
        || e instanceof SetNewGroupSctructureResponseError
      ) {
        toast.error(e.serverData.error_text);
      } else {
        throw e;
      }
    } finally {
      setState({ ...state, creating: false });
    }
  }

  const rewardTypes = useMemo(() => [
    { title: <Translate i18nKey='open_group' />, value: "open" },
    { title: <Translate i18nKey='close_group' />, value: "close" },
  ], [])
  // const stringValueChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => setState({ [e.currentTarget.name]: e.currentTarget.value });

  const booleanValueChange = (type: string) => setState({ ...state, isClosed: type === "open" ? true : false });

  const imgLoaded = (img: { [s: string]: any }) => setState({ ...state, img });
  
  return (
    <React.Fragment>
      <DialogTitle onClose={handleClose}>{i18n.t('pryaniky.modal.groupCreate.title')}</DialogTitle>
      <DialogContent className={cnCreateGroup('')}>
        <Input
          className={cnCreateGroup('BorderSplitter')}
          name={'name'}
          onChange={(e: any) => setState({ ...state, name: e.currentTarget.value })}
          icon={'font'}
          value={state.name}
          placeholder={i18n.t('pryaniky.modal.groupCreate.name')} />
        <Textarea className={cnCreateGroup('BorderSplitter')}
          name={'description'}
          onChange={(e: any) => setState({ ...state, description: e.currentTarget.value })}
          icon={'edit'}
          value={state.description}
          placeholder={i18n.t('pryaniky.modal.groupCreate.description')} />

        <ImageLoader
          className={cnCreateGroup('BorderSplitter')}
          cropper
          onLoaded={imgLoaded}
          title={i18n.t('pryaniky.modal.groupCreate.icon')}
          description={i18n.t('pryaniky.text.loadIconWithSize')} />
        <InputMention
          className={cnCreateGroup('BorderSplitter')}
          type='groups'
          additionalRequestOpts={{ excludeSystemGroups: 'true' }}
          icon={'user-group'}
          value={state.parentGroup}
          placeholder={i18n.t('pryaniky.modal.groupCreate.parent')}
          noMulti={true}
          onChange={(parentGroup: any) => setState({ ...state, parentGroup })} />
        <div className={cnCreateGroup('VisibilityChange')}>
          <RadioGroup
            items={rewardTypes}
            onChange={booleanValueChange}
            value={state.isClosed ? "open" : "close"}
            // label={<Translate i18nKey='pryaniky.referral.settings.rewards' />}
            className={cnCreateGroup('RewardType')}
          />
          {/* <Radio
          checked={!state.isClosed}
          name={'isClosed'}
          onChange={booleanValueChange}
          text={i18n.t('open_group')} />
        <Radio
          checked={state.isClosed}
          name={'isClosed'}
          onChange={booleanValueChange}
          text={i18n.t('close_group')} /> */}
        </div>

        <Checkbox
          value={state.createCalendarByDefault}
          className="CheckboxInput"
          // title={'isHidden'}
          onChange={(e: any) => setState({ ...state, createCalendarByDefault: !state.createCalendarByDefault })}
          label={i18n.t('pryaniky.modal.groupCreate.createCalendarByDefault')} />

        <Checkbox
          value={state.isHidden}
          className="CheckboxInput"
          // title={'isHidden'}
          onChange={(e: any) => setState({ ...state, isHidden: !state.isHidden })}
          label={i18n.t('pryaniky.modal.groupCreate.hidden')} />
      </DialogContent>
      <DialogActions
        acceptText={state.creating ? i18n.t('creating') + '...' : i18n.t('create')}
        closeText={i18n.t('pryaniky.shop.cancel')}
        disableAccept={state.name === ''}
        onAccept={onConfirm}
        onClose={handleClose} />
    </React.Fragment>
  );
}

export const GroupCreate = connect(
  undefined,
  undefined
)(GroupCreatePresenter)


export const DialogGroupCreate = createModal(GroupCreate, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});

// class GroupCreatePresenter extends React.Component<ICreateGroupDialogProps, ICreateGroupDialogState> {

//   constructor(props: ICreateGroupDialogProps) {
//     super(props);
//     this.state = {
//       creating: false,
//       name: '',
//       description: '',
//       // parentGroup: any,
//       isHidden: false,
//       isClosed: false,
//       img: undefined,
//       parentGroup: undefined
//     }
//   }

//   public render() {
//     const { state, props, onConfirm } = this;
//     const { handleClose } = props;
//     return (
//       <React.Fragment>
//         <DialogTitle onClose={handleClose}>{i18n.t('pryaniky.modal.groupCreate.title')}</DialogTitle>
//         <Input className={cnCreateGroup('BorderSplitter')} name={'name'} onChange={this.stringValueChange} icon={'font'} value={state.name} placeholder={i18n.t('pryaniky.modal.groupCreate.name')} />
//         <Textarea className={cnCreateGroup('BorderSplitter')} name={'description'} onChange={this.stringValueChange} icon={'edit'} value={state.description} placeholder={i18n.t('pryaniky.modal.groupCreate.description')} />
//         <ImageLoader className={cnCreateGroup('BorderSplitter')} cropper onLoaded={this.imgLoaded} title={i18n.t('pryaniky.modal.groupCreate.icon')} description={i18n.t('pryaniky.text.loadIconWithSize')} />
//         <InputMention className={cnCreateGroup('BorderSplitter')} type='groups' additionalRequestOpts={{ excludeSystemGroups: 'true' }} icon={'user-group'} value={state.parentGroup} placeholder={i18n.t('pryaniky.modal.groupCreate.parent')} noMulti={true} onChange={this.parentGroupSelect} />
//         <div className={cnCreateGroup('VisibilityChange')}>
//           <Radio checked={!state.isClosed} name={'isClosed'} onChange={this.booleanValueChange} text={i18n.t('open_group')} />
//           <Radio checked={state.isClosed} name={'isClosed'} onChange={this.booleanValueChange} text={i18n.t('close_group')} />
//         </div>
//         <CheckboxInput checked={state.isHidden} name={'isHidden'} onChange={this.booleanValueChange} text={i18n.t('pryaniky.modal.groupCreate.hidden')} />
//         <DialogActions
//           acceptText={state.creating ? i18n.t('creating') + '...' : i18n.t('create')}
//           closeText={i18n.t('pryaniky.shop.cancel')}
//           disableAccept={state.name === ''}
//           onAccept={onConfirm}
//           onClose={handleClose} />

//         {/* <DialogActions>
//           <Button main onClick={onConfirm} padding="lg">
//             {
//               state.creating ?
//                 i18n.t('creating') + '...'
//               :
//                 i18n.t('create')
//             }
//           </Button>
//           <Button onClick={onClose} padding="lg">
//             {i18n.t('pryaniky.shop.cancel')}
//           </Button>
//         </DialogActions> */}
//       </React.Fragment>
//     )
//   }

  // private stringValueChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ [e.currentTarget.name]: e.currentTarget.value });

  // private booleanValueChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ [e.currentTarget.name]: !this.state[e.currentTarget.name] });

  // private imgLoaded = (img: { [s: string]: any }) => this.setState({ img });

  // private parentGroupSelect = (parentGroup: { [s: string]: any }) => this.setState({ parentGroup });

  // private onConfirm = () => {
  //   if (this.state.creating) return;
  //   this.setState({ creating: true });
  //   const opts = {
  //     name: this.state.name,
  //     displayName: this.state.name,
  //     description: this.state.description,
  //     imgId198x198: this.state.img && this.state.img.id,
  //     RoorIdGroup: this.state.parentGroup ? this.state.parentGroup.pkid : -1,
  //     isHidden: this.state.isHidden,
  //     isArchived: false,
  //     isDeleted: false,
  //     isSystemGroup: false,
  //     childGroupCount: 0,
  //     visibilityType: +this.state.isClosed
  //   }
  //   utils.API.groups.create(opts).r.then((d: any) => {
  //     this.setState({ creating: false });
  //     if (utils.checkResponseStatus(d)) {
  //       history.push('/group/' + (d.data.pkid || d.data.PKID));
  //       this.props.handleClose &&  this.props.handleClose();
  //     } else {
  //       toast.error(d.error_text);
  //     }
  //   });
  // }
// }

// // export const GroupCreate = wrapDialogToRedux<ICreateGroupDialogProps>(GroupCreatePresenter)
