import * as React from 'react';
import * as utils from 'utils/src/utils';
import { cnWidget } from '../Widget.index';
import { IWidgetTypeTabsControlProps } from './Widget_type_tabsControl.index';
import './Widget_type_users.scss';
import { widgets } from "../../../i.widgets";
import { default as WidgetPresenter } from '../Widget';
import { License } from '../../License/License';
import './Widget_type_license.scss';

const type = 'license';

export default class WidgetTypeLicensePresenter extends WidgetPresenter<IWidgetTypeTabsControlProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    return (
      <License {...this.props} className={cnWidget({type, subtype})} settings={this.props.widget.settings as any} tag={widgets.components.common} />
    ) 
  }

}