import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import { Avatar } from 'uielements/src/Avatar/Avatar'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { i18n, Translate } from 'localization';

type IPresenterProps = News.ActionHeader.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    actionHeader
}) => {
    const { text, type, user, textKey } = actionHeader

    return <div className={cnClassName({}, [className])}>
        <Avatar className={cnClassName('Avatar')} size={40} name={user.displayName} imgUrl={user.imgUrl} imgId={user.imgId} />
        <div className={cnClassName('Description')}>
            <UserMention className={cnClassName('Mention')} id={user.id} name={user.displayName} />
            <span className={cnClassName('Text')}>{text || (textKey && i18n.t('pryaniky.' + textKey + '_' + user.sex))}</span>
        </div>
    </div >
}

export default memo(Presenter);
