import { i18n, Translate } from 'localization';
import { Box, styled } from "muicomponents/src";
import { FilterAlt } from "muicomponents/src/Icons";
import { InfinityList } from "muicomponents/src/InfinityList";
import { CheckButtonSelector } from "muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { SearchInput } from "muicomponents/src/SearchInput/SearchInput";
import React, { FC, useMemo } from "react";
import { useParams } from "react-router";
import { UserVacancyStatus } from "utils/src/BaseTypes/vacancy";
import { VacancyProps } from "../../Vacancy.index";
import { useRequestedVacancyUserList } from "./VacancyRequestedUsers.hooks";
import { VacancyRequestedUsersListItem } from "./VacancyRequestedUsersListItem";

const UsersListItemStyled = styled(UsersListItem)({
    padding: 0,
    background: 'transparent',
    border: 0,
    borderRadius: 0,
    boxShadow: 'none'
}) as typeof UsersListItem;

const HeaderBox = styled(Box)({
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8
}) as typeof Box;

export const VacancyRequestedUsers: FC<VacancyProps> = ({
    id: paramId
}) => {
    
    let {
        id
    } = useParams<{id: string}>();

    if(paramId) id = paramId;

    const {
        list,
        getList,
        error,
        finished,
        loading,
        search,
        changeSearch,
        filter,
        setFilter
    } = useRequestedVacancyUserList(id);

    const filterOptions = useMemo(() => {
        return Object.typedKeys(UserVacancyStatus).filter(e => isNaN(Number(e)) && e !== 'NotSet').map(e => {
            return {
                value: e,
                label: Translate.t({  i18nKey: `pryaniky.vacancy.user.status.${e}`}),
            }
        });
    }, []);

    return (
        <>
            <InfinityList
                header={
                    <HeaderBox>
                        <SearchInput
                            label={<Translate i18nKey='search' />}
                            value={search}
                            onChange={(e) => changeSearch(e.target.value)}
                            size={'small'}
                            loading={loading}
                        />
                        {/* <CheckButtonSelector
                            icon={<FilterAlt />}
                            value={filter}
                            onChange={(newValue) => {
                                setFilter(newValue as typeof filter);
                            }}
                            options={filterOptions}
                            defaultText={Translate.t({ i18nKey: "pryaniky.vacancy.requests.filter.status" })}
                        /> */}
                    </HeaderBox>
                }
                isFinished={finished}
                isLoading={loading}
                isError={error}
                itemsCount={list.length}
                loadMore={getList}
                textEmpty={<Translate i18nKey={`pryaniky.vacancy.requests${!!search || filter !== 'NotSet' ? '.filtered' : ''}.empty`} />}
            >
                {
                    list.map(requestId => {
                        return (
                            <VacancyRequestedUsersListItem id={requestId} vacancyId={id} />
                        );
                    })
                }
            </InfinityList>
        </>
    );
};