import { Chip } from 'muicomponents/src';
import { avatarGroupClasses } from 'muicomponents/src/AvatarGroup/AvatarGroup.index';
import { Box } from 'muicomponents/src/Box/Box';
import { styled } from 'muicomponents/src/mui/system';
import { ComponentProps } from 'react';

export const HistoryWrapper = styled(Box)({
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    width: 1170,
    padding: '16px 56px',
    boxSizing: 'border-box',
}) as typeof Box;

export const SkillsItemBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginBottom: 6,
}) as typeof Box;

export const SkillsItemTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
}) as typeof Box;

export const SkillsItemTitleChip = styled(Chip)({
    cursor: 'pointer',
    color: '#fff',
}) as typeof Chip;

export const SkillsInfoIconBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
}) as typeof Box;

interface SkillsItemUserProps extends ComponentProps<typeof Box> {
    del: boolean;
}

export const SkillsItemUser = styled(Box)<SkillsItemUserProps>(({ del }) => ({
    width: !del ? '65%' : '100%',
    '.MuiContainer-disableGutters': {
        justifyContent: 'flex-end',
        display: 'flex',
    },
    [`.${avatarGroupClasses.root}`]: { justifyContent: 'end' },
}));

export const SkillsItemAlertContent = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
}) as typeof Box;

export const SkillsItemAction = styled(Box)({
    width: '5%',
}) as typeof Box;
