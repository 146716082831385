

import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import * as ACT from '../../../actions';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { DialogRejectAchivment } from 'blocks/Dialogs/News/RejectAchivment/RejectAchivment'

const handleContextActionRejectAchievement = function* handleContextActionRejectAchievement({ payload }: AT.AContextAction) {
    const { action, id } = payload

    if (action === 'rejectAchievement') {
        try {
            // const result ={comment:"acmacmacm"}
            const result = yield* call(DialogRejectAchivment, {})
            // yield* put(ACT.moveToGroup({ newsId: id, group: result.group }))
            yield* put(ACT.rejectAchievement({ id, comment: result.comment, needSendNotification:result.needSendNotification }))

        } catch (error) {
            console.error('dismis', error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionRejectAchievement