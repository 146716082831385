import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

export const settingsDesign = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.code,
            settings: {
              language: 'css',
              variable: 'customCss'
            }
          }),
          generateWidget({
            type: widgets.types.code,
            settings: {
              language: 'javascript',
              variable: 'customJs'
            }
          })
        ],
        styles: {
          width: "100%"
        }
      })
    ],
  });
}