import { connect } from 'react-redux';
import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { Avatar } from 'uielements/src/index';
// import * as utils from 'src/utils/utils';
import {Button} from 'uielements/src/index'
import { Bable } from '../../Bable/Bable'
import {withRouter} from 'react-router-dom'
import { mapStateToProps, IChatListStateProps, cnChatList, IChatListProps, IChatListState, mapDispatchToProps, IDispatchProps } from './ChatList.index';
import './ChatList.scss';

const tsToTime = (ts: number) => {
  const date = new Date(ts);
  const hours = date.getHours();
  const minutes = "0" + date.getMinutes();
  return hours + ':' + minutes.substr(-2);
}

const createAvatarUrl = (chatUrl: string) => (avatarUrl: string) => chatUrl + avatarUrl



const ChatListMessage: React.FC<any> = ({ lastMessage }) =>
  <div className={'ChatList-Message'}>{lastMessage && (<React.Fragment><span className={'ChatList-Uname'}>{lastMessage.u.name}</span>: <span>{lastMessage.msg}</span></React.Fragment>)}</div>

const ChatListStatus: React.FC<any> = ({ channelId = '', users, currentUid }) => {

  const uid = channelId.replace(currentUid, '')
  const status = (uid && users[uid]) ? users[uid].status : 'offline';
  return <div className={'ChatList-Status ChatList-Status_' + status} />
}

const ChatListTypeP: React.FC<any> = ({ }) =>
  <svg className="ChatList-TypeP" viewBox="0 0 20 20" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path d="M5 8h10v9H5zm2-3c0-1.657 1.347-3 3-3 1.657 0 3 1.347 3 3v3H7V5z" stroke="currentColor" stroke-width="1.5" fill="none"></path>
  </svg>

const ChatListTypeC: React.FC<any> = ({ }) =>
  <svg className="ChatList-TypeC" viewBox="0 0 20 20" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path d="M7.5 4.167v11.666m5-11.666v11.666M4.167 7.5h11.666m-11.666 5h11.666" stroke="currentColor" stroke-width="1.5" fill="none" stroke-linecap="square"></path>
  </svg>

const ChatListType: React.FC<any> = ({ lastMessage, type, users, channelId, currentUid }) =>
  <div className={'ChatList-Type'}>
    {type === 'p' && <ChatListTypeP />}
    {/* {type === 'd' && <ChatListStatus {...{ lastMessage, users, channelId, currentUid }} />} */}
    {type === 'c' && <ChatListTypeC />}
  </div>

const ChatListTitle: React.FC<any> = ({ name, ts, channelId, type, users, currentUid, unread = 0 }) => (
  <div className={"ChatList-Title"}>
    <ChatListType {...{ channelId, currentUid, type, users }} />
    <div className={type === "p" || type === "c" ? "ChatList-Cname" : "ChatList-Sname"}>
      {name}
      <div className={"ChatList-Count"}>{unread !== 0 && <Bable>{unread}</Bable>}</div>
    </div>

    <div className={"ChatList-Mtime"}>{tsToTime(ts)}</div>
  </div>
);

export class ChatListPresenter extends React.Component<IChatListProps, IChatListState> {
  public componentDidMount() {
    this.props.mute();
  }

  private itemClick = (url: string) => () => {
    this.props.history.push('/messages' + url);
  }

  public render() {
    const customClassNames = '';
    const { children, className = '', list, chatUrl, users, currentUid } = this.props;
    const avatarUrl = createAvatarUrl(chatUrl)
    return (
      <ul className={cnChatList({}, [customClassNames, className])}>
        {list.map((item: any) => (
          <li
            onClick={this.itemClick(item.url)}
            className={cnChatList("Item", [item.alert ? "unread" : "read"])}
            key={item.id}
          >
            <div className={cnChatList("Avatar" + "Block")}>
              <Avatar className={cnChatList("Avatar")} name={item.fname} imgUrl={avatarUrl(item.avatar)} size={56} />
              {item.type === "d" && (
                <ChatListStatus {...{ lastMessage: item.lastMessage, users, channelId: item.id, currentUid }} />
              )}
            </div>
            <div className={cnChatList("Content")}>
              <ChatListTitle
                unread={item.unread}
                name={item.fname}
                ts={item.ts}
                channelId={item.id}
                currentUid={currentUid}
                type={item.type}
                users={users}
              />
              <ChatListMessage lastMessage={item.lastMessage} />
            </div>
          </li>
        ))}
        {children}
      </ul>
    );
  }
}



export const ChatList = withRouter(connect<IChatListStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)((ChatListPresenter)))