import React, { ReactText } from 'react';
import { cn } from '@bem-react/classname';
// import { ICourse } from '../../mock';
// import { Button } from 'uielements/src/Button/Button';
import { Button } from 'uielements/src/Button/Button';
// import { Admin } from '../../Parts/Admin/Admin';
import { Deadlines } from '../../../../components/Deadlines/Deadlines';
import { Progress } from '../../../../components/Progress/Progress';
import { AuthorsList } from '../../../../components/AuthorsList/AuthorsList';
import './Item.scss';
import { LikeAction } from '../../../LikeAction/LikeAction'
import { tS } from 'LMSModule/utils/i18n.adaptor'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { Avatar, ProgressBar } from 'uielements/src';

const cnItem = cn('CoursesItem');
const zeroId = "00000000-0000-0000-0000-000000000000";

export const Item: React.FC<any> = ({ className, course, sendLikeCourse, mini = false }) => {
    // const session = course.currentSession.id//course.courseSessionId === zeroId ? (course.courseSessions[0] ? course.normalSessions[course.courseSessions[0]] : null) : course.normalSessions[course.courseSessionId]
    const unitCount = course.completedUnitsCount//course.courseUnits.length
    // const unitCount = course.unitsCount//course.courseUnits.length

    const srt =(course.completedUnitsCount / course.unitsCount) * 100;
    return (
        <div className={cnItem("Content")}>
            <div className={cnItem("ImadgeBox")}>
                {course.largeImgUrl &&
                    <Avatar precentage={100} shape={'rectangle'} className={cnItem('ImadgeBox-Image')} imgUrl={course.largeImgUrl} />}
                {/* <img src={course.largeImgUrl} className={cnItem('Image')} /> */}
                <p className={cnItem('ImadgeBox-Title')}>{course.name}</p>
            </div>

            {unitCount !== 0 && <div className={cnItem('UnitCount')}>
                <span className={cnItem('UnitCount-Complited')}>{tS('units.1', { count: unitCount })}</span>
                <span className={cnItem('UnitCount-Total')}>{"/" + course.unitsCount}</span>
            </div>}
            {/* {session && <Deadlines session={session} className={cnItem('Deadlines')} />} */}

            {course.unitsCount !== 0 && <ProgressBar
                className={cnItem('Progress')}
                percent={(course.completedUnitsCount / course.unitsCount) * 100}
                // className={cnItem('Progress-Line')}
            />}
            {/* {course.unitsCount !== 0 && <Progress className={cnItem('Progress')} currentStep={course.completedUnitsCount} totalSteps={course.unitsCount} />} */}
            <MuiButton
                className={cnItem('Start')}
                variant={'contained'}
                color={'primary'}
                to={`/LMS/${course.id}`}>
                {tS('open.1v2')}
            </MuiButton>

            {/* <Button className={cnItem('Start')} href={ } type="rlink" main padding="md">
                {tS('open.1')}
            </Button> */}
        </div>
    );
};