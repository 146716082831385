import React from 'react';
import { iconsPack } from './icons';
import {
  DialogContent,
  Button,
  Icon
} from 'uielements/src';
import { createModal, IBodyProps, TransitionComponent } from 'utils/src/DialogCreator';
import { i18n, Translate } from 'localization';
import { cn } from '@bem-react/classname';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'

export const cnIconsSelector = cn('IconsSelector');

interface IIconSelectModalProps extends IBodyProps<string> {
  selected: string
}

const ItemChangePresenter: React.FC<IIconSelectModalProps> = ({ handleClose, handleAccept, selected }) => {

  return <div className={cnIconsSelector({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose}>
      {/* <h4 className="my-1"> */}
        <Translate i18nKey={`pryaniky.menu.modal.item.icon`} />:
      {/* </h4> */}
    </DialogTitle>
    <DialogContent className={cnIconsSelector('Body')}>
      {
        iconsPack.map((ic, idx) =>
          <Button onClick={() => handleAccept(ic)}>
            <Icon key={idx} icon={ic} />
          </Button>
        )
      }
    </DialogContent>
  </div>
}

export const iconSelectModal = createModal(ItemChangePresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});