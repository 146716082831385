import { connect } from 'react-redux'
import React, { useState } from 'react'
import { Selectize, Button } from 'uielements/src'
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
import './Filters_type_events.scss'
import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter } from './Filters_type_events.index'
import { Toggle } from 'uielements/src/Toggle/Toggle'
import { i18n, Translate } from 'localization';
import { cnFilters } from '../Filters.index'

const FiltersEventsPresenter: React.FC<IFiltersProps> = (props) => {

  const Tag = props.tag || 'div'

  const data =
    {
      name: i18n.t('pryaniky.filter.events.stages'),
      elements: [
        {
          id: '0',
          title: i18n.t('Все'),
          type: 'status',
          value: undefined,
        },
        {
          id: '1',
          title: i18n.t('pryaniky.filter.events.going'),
          type: 'status',
          value: 'going',
        },
        {
          id: '2',
          title: i18n.t('pryaniky.filter.events.maybe'),
          type: 'status',
          value: 'maybe',
        },
        {
          id: '3',
          title: i18n.t('pryaniky.filter.events.no'),
          type: 'status',
          value: 'no',
        }
      ],
    }

  const [checked, setChecked] = useState(
    {
      moderation: false,
    }
  )

  const handleChange = (selected: ISelectizeItem) => {
    const { filter } = props.context
    const same = filter && filter.id === selected.id;
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { filter: !same ? selected : undefined, [selected.type]: !same ? selected.value : undefined } 
      return acc
    }, {} as { [s: string]: any }))
  }

  const checkedHandler = (type: string, data: boolean) => {
    switch (type) {
      case 'moderation':
        setChecked({
          ...checked,
          moderation: !data
        })
      default:
        break
    }
    props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: !data }
      return acc
    }, {} as { [s: string]: any }))
  }

  const contextVariables = props.relations || ['common']

  return (
  // @ts-ignore 
    <Tag data={data} tag='div' context={'events'} relations={[]} {...props} className={cnFilters({}, [props.className])} >
      <h3 className={cnFilter('Title', ['BottomLine'])}>{i18n.t('pryaniky.filter.events.title')}</h3>
      <Selectize onClick={handleChange} active={props.context && props.context.filter ? props.context.filter.id : undefined} name={data.name} elements={data.elements || []} />
    
      <div className={cnFilter('Toggle')}>
        <h4>{i18n.t('pryaniky.filter.events.onModerate')}</h4>
        <Toggle checked={checked.moderation} onChange={checkedHandler.bind(null, 'moderation', checked.moderation)} />
      </div>

    </Tag>
  )
}

export const FiltersEvents = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersEventsPresenter)