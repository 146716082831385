import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    IPresenterProps
} from './interfaces'
import './style.scss'
import { tC, Tc } from 'LMSModule/utils/i18n.adaptor'
import { skipType } from 'LMSModule/utils/common.utils'
import moment from 'moment';
import { i18n, Translate } from 'localization';

const scoreCountModeEnum: any = {
    0: <Tc i18nKey='scoreCountMode.0.1' />,//tC('scoreCountMode.0.1'),
    1: <Tc i18nKey='scoreCountMode.1.1' />,//tC('scoreCountMode.1.1'),
    3: <Tc i18nKey='scoreCountMode.3.1' />,//tC('scoreCountMode.3.1'),
}

// const skipType: { [k: number]: React.ReactNode } = {
//     0: <Tc i18nKey='skipType.0.1' />,//tC('skipType.0.1'),
//     1: <Tc i18nKey='skipType.1.1' />,//tC('skipType.1.1'),
//     2: <Tc i18nKey='skipType.2.1' />,//tC('skipType.2.1'),
// }

export const Presenter: FC<IPresenterProps> = ({
    className,
    scoreCountMode,
    passingMinScore,
    attemptDate,
    attemptsCount,
    maxAttemptsCount,
    questionsCount,
    isStart = false,
    passingScoreSuccess = 'wait',
    attemptsSuccess = true,
    unitSkipType,
    unitType = 'test'
}) => {

    const attemptDateView = useMemo(() => {
        if(!attemptDate) return null;
        return moment(attemptDate).format(`L [${i18n.t('at')}] LT`)
    }, [attemptDate]);

    return <ul className={cnClassName({}, [className])}>
        {attemptDateView && <li className={cnClassName('PropertiesItem')}>
            <div className={cnClassName('PropertiesIcon', { info: true })}></div>
            <div className={cnClassName('PropertiesContent')}>
                <div className={cnClassName('PropertiesTitle')}>{tC('attemptDate')}</div>
                <div className={cnClassName('PropertiesData')}>{attemptDateView}</div>
            </div>
        </li>}
        {unitSkipType !== undefined && <li className={cnClassName('PropertiesItem')}>
            <div className={cnClassName('PropertiesIcon', { info: true })}></div>
            <div className={cnClassName('PropertiesContent')}>
                <div className={cnClassName('PropertiesTitle')}>{unitType === 'test' ? tC('skipType.1') : tC('skipType.task.1')}</div>
                <div className={cnClassName('PropertiesData')}>{skipType[unitSkipType]}</div>
            </div>
        </li>}
        {scoreCountMode !== undefined && <li className={cnClassName('PropertiesItem')}>
            <div className={cnClassName('PropertiesIcon', { info: true })}></div>
            <div className={cnClassName('PropertiesContent')}>
                <div className={cnClassName('PropertiesTitle')}>{tC('scoreCountMode.1')}</div>
                <div className={cnClassName('PropertiesData')}>{scoreCountModeEnum[scoreCountMode]}</div>
            </div>
        </li>}
        {questionsCount !== undefined && <li className={cnClassName('PropertiesItem')}>
            <div className={cnClassName('PropertiesIcon', { info: true })}></div>
            <div className={cnClassName('PropertiesContent')}>
                <div className={cnClassName('PropertiesTitle')}>{tC('questionsCount.1')}</div>
                <div className={cnClassName('PropertiesData')}>{questionsCount}</div>
            </div>
        </li>}
        {passingMinScore !== undefined && <li className={cnClassName('PropertiesItem')}>
            <div className={cnClassName('PropertiesIcon', { wait: passingScoreSuccess === 'wait', failure: passingScoreSuccess === 'fail', success: passingScoreSuccess === 'success', info: isStart })}></div>
            <div className={cnClassName('PropertiesContent')}>
                <div className={cnClassName('PropertiesTitle')}>{tC('passingMinScore.1')}</div>
                <div className={cnClassName('PropertiesData')}>{passingMinScore}</div>
            </div>
        </li>}
        {(attemptsCount !== undefined && maxAttemptsCount !== undefined) && <li className={cnClassName('PropertiesItem')}>
            <div className={cnClassName('PropertiesIcon', { failure: !attemptsSuccess, success: attemptsSuccess, info: isStart })}></div>
            <div className={cnClassName('PropertiesContent')}>
                <div className={cnClassName('PropertiesTitle')}>{tC('attemptsCount.1')}</div>
                <div className={cnClassName('PropertiesData')}>{attemptsCount}/{maxAttemptsCount}</div>
            </div>
        </li>}
    </ul>
}

export default memo(Presenter);
