import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Masonry, Button } from 'uielements/src';
import { Item } from '../../List/Item/Courses-Item';
import { mapStateToProps, ICoursesStateProps, cnCourses, ICoursesProps, mapDispatchToProps } from './CoursesMy.index';
import './CoursesMy.scss';
import { tS } from 'LMSModule/utils/i18n.adaptor'

export const CoursesMyPresenter: React.FC<ICoursesProps & any> = ({
    className,
    loadCourses,
    courses,
    cIds,
    isLoading,
    location,
    sendLikeCourse,
    isFinished,
    isEnabled = false,
    params = {},
    ...data
}) => {
    const prms = { count: 3, isMandatory: true, Shuffle: true, status: 'NotStarted', ...params }
    useEffect(() => {
        isEnabled && loadCourses(prms, true)
    }, []);

    if (cIds.length === 0 || cIds[0] === 'skelet') return null;
    return (
        <div className={cnCourses({}, [className])}>
            <h3 className={cnCourses('Title')}>{tS('courses.1')}</h3>
            {cIds.slice(0, prms.count).map((cId: any) => (
                <Item
                    className={cnCourses('Item')} mini={true} course={courses[cId]} key={cId} sendLikeCourse={sendLikeCourse} />
            ))}
            {(!isFinished || cIds.length > prms.count) && <Button
                main
                type={'rlink'}
                href={'/lms?isMandatory=true'}
                className={cnCourses('MoreBtn')}>{tS('viewAll.1')}</Button>}
        </div>
    );
};

export const CoursesMy = connect(
    mapStateToProps,
    mapDispatchToProps
)(CoursesMyPresenter);
