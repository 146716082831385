import { ISurveyState, } from './interfaces'
import {
  CHANGE_ACTIVE_SURVEY_IDX,
  SET_ACTIVE_QUESTION,
  // NEXT_ACTIVE_QUESTION,
  RESET_SURVEY,
  SET_ANSWER_ID,
  SET_OTHER_ANSWER_ID,
  SET_FINISHED_SURVEY,
  SET_IS_ANSWERED,
  SET_IS_SHOW_RESULT,
  SET_LOADING_SURVEY,
  SET_OPEN_SURVEY,
  SET_RIGHT_ANSWER_COUNT,
  SET_STARTED_SURVEY,
  SET_SURVEY,
  SET_USER_RESULT,
  IS_LOADING_SURVEYS, SURVEY_SET_SURVEYS_PASSING_ANSWERS_IDS,
  SURVEY_SET_SURVEYS_PASSING_RESULTS,
  SET_ROW_ANSWERS,
  SURVEY_SET_STATE_BY_KEY,
  SURVEY_CHANGE_STATE_BY_KEY,
  SURVEY_SET_ANSWER
  // SET_ANSWER_TEXT_ID
} from './actions.types'
import { GUID_EMPTY } from 'utils/src/constants.prn';

import { defaultSurvey } from './constants'
import { ISurveysReducerActions } from './actions.interfaces'
import { consoleWidget } from 'services/dev.cookies'

export function surveyReducer(state = defaultSurvey, action: ISurveysReducerActions): ISurveyState {
  switch (action.type) {
    case SET_SURVEY:
      return {
        ...state,
        surveyWidget: {
          ...state.surveyWidget,
          surveyData: action.payload,
        }
      }
    case IS_LOADING_SURVEYS:
      return {
        ...state,
        surveyWidget: {
          ...state.surveyWidget,
          isLoadingSurveys: action.payload,
        }
      }
    case CHANGE_ACTIVE_SURVEY_IDX:
      return {
        ...state,
        surveyWidget: {
          ...state.surveyWidget,
          activeSurveyIdx: action.payload,
        }
      }
    case SET_OPEN_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          openSurveyData: action.payload,
        }
      }
    case SET_STARTED_SURVEY: {
      const activeQuestionId = state.survey.activeQuistionId || state.survey.openSurveyData.questions[0]?.id || '';
      const question = state.survey.openSurveyData.questions.find((el: any) => el.id === activeQuestionId);
      // const question = state.survey.openSurveyData.questions[state.survey.activeQuistionIdx]

      return {
        ...state,
        survey: {
          ...state.survey,
          activeQuistionId: activeQuestionId,
          started: action.payload,
          rows: question.qtype === "MatrixSingle" ? question.rows : [],
        }
      }
    }
    case SET_ACTIVE_QUESTION: {
      const question = state.survey.openSurveyData.questions.find((el: any) => el.id === action.payload);
      if (!question) return state;
      return {
        ...state,
        survey: {
          ...state.survey,
          activeQuistionId: action.payload,
          answersIds: [],
          answered: false,
          rows: question.qtype === "MatrixSingle" ? question.rows : [],
          numericAnswer: "",
          answerDate: null,
          textAnswers: "",
          attachments: [],
          isFree: false
        }
      }
    }
    // case NEXT_ACTIVE_QUESTION: {
    //   const activeQuestionId = state.survey.activeQuistionId || state.survey.openSurveyData.questions[0]?.id || '';
    //   const question = state.survey.openSurveyData.questions.find((el: any) => el.id === activeQuestionId);
    //   // const question = state.survey.openSurveyData.questions[state.survey.activeQuistionIdx+1];

    //   return {
    //     ...state,
    //     survey: {
    //       ...state.survey,
    //       // activeQuistionIdx: ++state.survey.activeQuistionIdx,
    //       answersIds: [],
    //       answered: false,
    //       rows: question.qtype === "MatrixSingle" ? question.rows : [],
    //       numericAnswer: "",
    //       answerDate: null,
    //       textAnswers: "",
    //       attachments: [],
    //     }
    //   }
    // }
    case SET_ANSWER_ID: {
      const { id, single, rowId, text, file, numericAnswer, answerDate, isFree } = action.payload;
      const answersIds = state.survey.answersIds;
      const question = state.survey.openSurveyData.questions?.find((el: any) => el.id === state.survey.activeQuistionId);

      const answersQuestionHasIsFreeAnswer = question?.answers?.find((el: any) => el.isFree);

      const isFreeAnswerCheked = answersIds?.includes(answersQuestionHasIsFreeAnswer?.id) || false

      return {
        ...state,
        survey: {
          ...state.survey,
          answersIds: single ? [id] : answersIds.includes(id) ? answersIds.filter(answerId => answerId !== id) : [...answersIds, id],
          textAnswers: isFreeAnswerCheked ? (text || state.survey.textAnswers) : (text || ""),
          attachments: file || [],
          numericAnswer: numericAnswer ? numericAnswer : "",
          answerDate: answerDate ? answerDate : null,
          isFree: isFreeAnswerCheked ? isFree || state.survey.isFree : isFree
        }

      }
    }
    case SET_OTHER_ANSWER_ID: {
      const { id, text } = action.payload;
      return {
        ...state,
        survey: {
          ...state.survey,
          textAnswers: text || "",

        }

      }
    }

    case SET_ROW_ANSWERS:
      const { answeredRow } = action.payload
      const selectedAnswers = answeredRow.filter(el => el.selectedAnswer !== GUID_EMPTY)
      const activeQuestionId = state.survey.activeQuistionId || state.survey.openSurveyData.questions[0]?.id || '';
      const question = state.survey.openSurveyData.questions.find((el: any) => el.id === activeQuestionId);
      // const question = state.survey.openSurveyData.questions[state.survey.activeQuistionIdx]

      return {
        ...state,
        survey: {
          ...state.survey,
          rows: answeredRow,
          validMatrix: !question.allowSkip ? true : answeredRow.filter(el => el.selectedAnswer !== GUID_EMPTY).length === answeredRow.length

        }
      }
    case SET_IS_ANSWERED:
      return {
        ...state,
        survey: {
          ...state.survey,
          answered: action.payload,
        }
      }
    case SET_FINISHED_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          finished: true,
          started: false,

        }
      }
    // case SET_RIGHT_ANSWER_COUNT:
    //   return {
    //     ...state,
    //     survey: {
    //       ...state.survey,
    //       rightAnswerCount: state.survey.rightAnswerCount + 1
    //     }
    //   }
    case SET_LOADING_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          loading: action.payload
        }
      }
    case SET_USER_RESULT:
      return {
        ...state,
        survey: {
          ...state.survey,
          result: action.payload
        }
      }
    case SET_IS_SHOW_RESULT:
      return {
        ...state,
        survey: {
          ...state.survey,
          isShowResult: action.payload
        }
      }
    case RESET_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          activeSurveyIdx: 0,
          activeQuistionId: '',
          // activeQuistionIdx: 0,
          started: false,
          answersIds: [],
          answered: false,
          finished: false,
          isShowResult: false,
          result: []
        },
      }
    case SURVEY_SET_SURVEYS_PASSING_RESULTS: {
      return {
        ...state,
        survey: {
          ...state.survey,
          questionsResults: action.payload
        }

      }
    }
    case SURVEY_SET_SURVEYS_PASSING_ANSWERS_IDS: {
      const { questionId, answersIds } = action.payload;
      return {
        ...state,
        survey: {
          ...state.survey,
          questionsResults: {
            ...state.survey.questionsResults,
            questions: {
              ...state.survey.questionsResults.questions,
              [questionId]: {
                ...state.survey.questionsResults.questions[questionId],
                newAnswersIds: answersIds
              }
            }
          }
        }

      }
    }

    case SURVEY_SET_STATE_BY_KEY: {
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    };

    case SURVEY_CHANGE_STATE_BY_KEY: {
      const currentState = state[action.payload.key];
      if(typeof currentState !== 'object') {
        return {
          ...state,
          [action.payload.key]: action.payload.value
        };
      }
      return {
        ...state,
        [action.payload.key]: {
          ...currentState,
          ...action.payload.value as typeof currentState
        }
      };
    };

    case SURVEY_SET_ANSWER: {
      return {
        ...state,
        surveyPassingAnswers: {
          ...state.surveyPassingAnswers,
          [action.payload.questionId]: action.payload.answerValue
        }
      }
    };

    default:
      return state
  }
}