
import { cnPresentItemMui, LightMentionTooltipStyled } from 'blocks/PresentsNew/PresentItem/PresentItemMui';
import { StyledBadge, TooltipTitleBox } from 'blocks/PresentsNew/PresentItem/PresentItemMui.styled';
import { Box, Divider, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { TListItemComponentProps } from 'muicomponents/src/ItemsListDialog/BaseListItem/BaseListItem';
import { IItemProps } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import React, { FC, ReactNode, useMemo } from 'react';
import { IBodyProps } from 'utils/src/DialogCreator'
import { IPresentData } from 'utils/src/requests/models/api.shop';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn';
import { AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { PresentDialogItemBox } from './PresentsDialogItem.styled';
import { Link } from 'muicomponents/src/Link';
import { generatePath } from 'react-router';
import { mainUrls } from 'utils/src';
import { LanguageContext, Translate } from 'localization';
import { formatDateSTD } from 'utils/src/utils.dates';

const getI18NKey = (postfix: string) => {
    return `pryaniky.widgets.presents.${postfix}`;
};

export const PresentsDialogItem: FC<IItemProps<IPresentData>> = ({
    item,
    ...props
}) => {

    const { widgetType } = props as IItemProps<IPresentData> & {
        widgetType: string
    };

    const isReceivedGifts = widgetType === 'receivedGifts';
    const presentUserI18key = useMemo(
        () => (isReceivedGifts ? { action: getI18NKey('sender') } : { action: getI18NKey('recipient') }),
        [isReceivedGifts]
    );

    return (
        <>
            <LightMentionTooltipStyled
                title={
                    <Box
                        className={cnPresentItemMui('PresentItemMuiPopoverBox')}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            margin: '24px',
                            maxHeight: '300px',
                            overflowY: 'auto'
                        }}
                    >
                        {item?.users?.map((el) => {
                            return (
                                <Box sx={{ marginRight: '19px', maxWidth: '512px' }}>
                                    <TooltipTitleBox
                                        className={cnPresentItemMui('PresentItemMuiPopoverBox-TooltipTitleBox')}
                                        sx={{ justifyContent: 'left' }}
                                    >
                                        <Avatar src={el.userOrder.imgUrl} sx={{ mr: 1 }} />
                                        <Box
                                            className={cnPresentItemMui(
                                                'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox'
                                            )}
                                        >
                                            <Box sx={{ ['> *']: { display: 'inline' } }}>
                                                <Typography
                                                    color="ActiveCaption"
                                                    variant="body2"
                                                    className={cnPresentItemMui(
                                                        'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-DisplayName'
                                                    )}
                                                >
                                                    <Link href={generatePath(mainUrls.user.id, { id: el.userOrder.id })}>
                                                        {el.userOrder.displayName}
                                                    </Link>
                                                </Typography>
                                                &nbsp;
                                                <Typography
                                                    color="GrayText"
                                                    variant="body2"
                                                    className={cnPresentItemMui(
                                                        'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-Localization'
                                                    )}
                                                >
                                                    <Translate
                                                        values={{
                                                            text: Translate.t({
                                                                i18nKey: presentUserI18key.action,
                                                                context:
                                                                    el.userOrder.sex === 'f'
                                                                        ? LanguageContext.female
                                                                        : LanguageContext.male,
                                                            }),
                                                        }}
                                                        i18nKey={getI18NKey('text')}
                                                    />
                                                </Typography>
                                                &nbsp;
                                                <Typography
                                                    color="InfoText"
                                                    variant="body2"
                                                    fontWeight={'600'}
                                                    className={cnPresentItemMui(
                                                        'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-OrderProductName'
                                                    )}
                                                >
                                                    {item.orderProductName}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                color="GrayText"
                                                variant="body2"
                                                className={cnPresentItemMui(
                                                    'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-Date'
                                                )}
                                            >
                                                {formatDateSTD(el.date, false)}
                                            </Typography>
                                        </Box>
                                    </TooltipTitleBox>
                                    <Typography
                                        variant="body2"
                                        color="InfoText"
                                        className={cnPresentItemMui(
                                            'PresentItemMuiPopoverBox-TooltipTitleBox-HeaderBox-userComment'
                                        )}
                                    >
                                        {el.userComment}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                }
            >
                <PresentDialogItemBox>
                    <StyledBadge
                        className={cnPresentItemMui('PresentItemMuiStyledBadge')}
                        badgeContent={item.count}
                        color="primary"
                        invisible={item.count === 1}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Avatar
                            className={cnPresentItemMui('PresentItemMuiStyledBadge-Avatar')}
                            src={item.imgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)}
                            alt={item.orderProductName}
                            size={AvatarSize.xl}
                        />
                    </StyledBadge>
                    {item.orderProductName}
                </PresentDialogItemBox>
            </LightMentionTooltipStyled>
            <Divider orientation='horizontal' />
        </>
    )
};