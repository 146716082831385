import { connect } from 'react-redux';
import React, { FC, useEffect, useState, useRef } from 'react';
import { i18n, Translate } from 'localization';
import { Avatar, SearchInput, Button } from 'uielements/src'
import { Icon, Button as PryanikyButton, SplitButton } from 'uielements/src';
import { Layout } from '../Layout/InfoBlock-Layout';
import { mapStateToProps, IGroupStateProps, cnGroup, IGroupProps, mapDispatchToProps } from './_InfoBlock-Group.index';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import openApproveDialog from 'blocks/Dialogs/Group/ApproveDialog/ApproveDialog'
import Skelet from '../Skelet'
import { getGroupActionText } from 'redux/sagas/Groups/utils'
import './InfoBlock-Group.scss';
import MDRender from 'uielements/src/CommonmarkRender'
import { Button as MuiButton } from 'muicomponents/src/Button/Button'

const createNotificationsOptions = (selectedNotifyTemplate: string, nitifiAction: (value: string) => () => void) => {
    return [
        {
            title: i18n.t('pryaniky.notifications.settings.template.none'),//'Отключить все',
            buttonProps: { onClick: nitifiAction('none') },
            isActive: selectedNotifyTemplate === 'none'
        }, {
            title: i18n.t('pryaniky.notifications.settings.template.common'),//'Как в ленте',
            buttonProps: { onClick: nitifiAction('common') },
            isActive: selectedNotifyTemplate === 'common'
        }, {
            title: i18n.t('pryaniky.notifications.settings.template.all'),//'Включить все',
            buttonProps: { onClick: nitifiAction('all') },
            isActive: selectedNotifyTemplate === 'all'
        }
    ]
}

/**
 * запоминает предыдущие значение
 * @param value 
 */
const usePrevious = (value: any): any => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const GroupPresenter: React.FC<IGroupProps> = ({
    className,
    groupId,
    group = {},
    loadGroup,
    checkGroupContext,
    authUser,
    appId,
    changeMyInGroup,
    setNotificationTemplate,
    changeLocation,
    getStructure
}) => {

    useEffect(() => {
        if(!group.pkid) {
            loadGroup(groupId)
        } else {
            checkGroupContext(groupId)
        }
    }, [])
    const prevStatus = usePrevious(group.isMeInGroup)
    useEffect(() => {
        (group.pkid && prevStatus !== undefined) && getStructure(`group.${group.pkid}`, { noLoader: true })
    }, [group.isMeInGroup])
    const [showDialog, setShowDialog] = useState(false);

    const onCloseDialog = () => setShowDialog(false);
    // const openDialog = () => setShowDialog(true);

    if (!group.pkid) return (<div className={cnGroup({}, [className])}><Skelet /></div>)

    const handleAction = () => {
        if (group.isMeInGroup) {
            utilsProj.confirm({
                text: i18n.t('pryaniky.confirm.leaveGroup', { name: group.name }),
                onConfirm: () => {
                    changeMyInGroup(groupId)
                }
            });
        } else {
            changeMyInGroup(groupId);
        }
    };

    const nitifiAction = (template: string) => () => {
        setNotificationTemplate(groupId, template)
    }

    const notifiOptions = createNotificationsOptions(group.selectedNotifyTemplate, nitifiAction)

    const actionButtonText = i18n.t(getGroupActionText(group, authUser.baseData.isAdmin))

    const openDialog = () => {
        openApproveDialog({
            gropId: group.pkid
        })
    }

    return (
        <div className={cnGroup({}, [className])}>
            <Layout
                // editBtn={
                // }
                left={
                    <>
                        <div className={'InfoBlock-Img'}>
                            <Avatar
                                className={cnGroup('Img')}
                                imgUrl={!utils.isEmptyGuid(group.imgId198x198) ? group.imgUrl : undefined}
                                name={group.name}
                                size={180}
                                noBorderRadius
                            />
                        </div>
                        {
                            !group.disableChangeJoinStatus &&
                            !group.isOfficialGroup && (
                                <MuiButton
                                    className={cnGroup('ActionButton')}
                                    id="group-action-btn"
                                    onClick={handleAction}
                                    variant={group.isMeInGroup ? 'outlined' : 'contained'}
                                    color={'primary'}
                                    // main={!group.isMeInGroup}
                                    disabled={group.visibilityType === 1 && group.isReqeustAccessSent}>
                                    {actionButtonText}
                                </MuiButton>)
                        }
                    </>
                }
                right={
                    <div className={cnGroup('Data')}>
                        <div className={cnGroup('DataName')}>
                            {group.name}
                            {group.showGroupInSubnetworks && <Icon className={cnGroup('NetworkIcon')} icon={'chart-network'} />}
                        </div>
                        {group.isOfficialGroup && (
                            <h5 className={cnGroup('Label', ['my-0'])}>{i18n.t('pryaniky.group.official')}</h5>
                        )}
                        <div className={cnGroup('DataDescription', ['my-1']) + ' ' + cnGroup('GrayText')}>
                            <MDRender textCut={false} source={group.description} />
                            {/* {group.description} */}
                        </div>
                        <div className={cnGroup('Actions')}>
                            {
                                group.isGroupAdmin && (
                                    <Button
                                        tag={'a'}
                                        theme={'unstyled_center'}
                                        id="toggle-group-edit"
                                        onClick={() => changeLocation('?groupEdit=true')}
                                    >
                                        <Translate i18nKey={'pryaniky.group.edit'} />
                                    </Button>
                                )
                            }
                            {
                                !group.disableChangeJoinStatus &&
                                <>
                                    {
                                        group.isMeInGroup &&
                                        <>
                                            <div className={cnGroup('Actions-Splitter')} />
                                            <PryanikyButton
                                                theme={'unstyled_center'}
                                                type={'rlink'}
                                                href={`/notifiSettings/${authUser.baseData?.id}/group/${group.pkid}/${appId}`}
                                            // href={`/notifications/settings?gid=${group.pkid}&section=groups`}
                                            >
                                                <Translate i18nKey={'pryaniky.group.notifications'} />
                                            </PryanikyButton>
                                        </>
                                    }
                                    {
                                        (group.requestsCount > 0 && group.isGroupAdmin) &&
                                        <>
                                            <div className={cnGroup('Actions-Splitter')} />
                                            <PryanikyButton
                                                tag={'a'}
                                                theme={'unstyled_center'}
                                                onClick={openDialog}
                                            >
                                                <Translate i18nKey={'pryaniky.group.requests'} values={{ count: group.requestsCount }} />
                                            </PryanikyButton>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        {
                            !group.isMeInGroup && group.visibilityType === 1 &&
                            <div className={cnGroup('InfoText')}>
                                {
                                    group.isOfficialGroup ?
                                        i18n.t('pryaniky.group.info.isOfficial') :
                                        i18n.t('pryaniky.group.info.isClosed')
                                }
                            </div>
                        }

                    </div>
                }
            />
            {/* <ApproveDialog
                onCloseDialog={onCloseDialog}
                showDialog={showDialog} pkid={group.pkid} /> */}
        </div>
    );
};

export const Group = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupPresenter);
