import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API } from 'utils/src/utils'
import moveToGroupModal from 'blocks/Dialogs/News/MoveToGroup'

const handleContextActionMove = function* handleContextActionMove({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'moveToGroup') {
        try {
            const result = yield* call(moveToGroupModal, {})
            yield* put(ACT.moveToGroup({ newsId: id, group: result.group }))

        } catch (error) {
            console.error('dismis', error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionMove