import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import { i18n, Translate } from 'localization';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API, basename } from 'utils/src/utils'

const handleContextActionCopylink = function* handleContextActionAddToAnnouncement({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'copylink') {
        try {
            // const dummy = document.createElement('input');
            // const location = window.location.href;
            // dummy.value = location.substr(0, location.indexOf(window.location.pathname)) + basename + '/news/' + id;
            // document.body.appendChild(dummy);
            // dummy.select();
            // document.execCommand('copy');
            // dummy.remove();

            const location = window.location.href;
            const value = location.substr(0, location.indexOf(window.location.pathname)) + basename + '/news/' + id;
            navigator.clipboard.writeText(value)

            toast.success(i18n.t('pryaniky.toast.success.copyLink'));

        } catch (error) {

        }
    }
    yield;

    return 0;
}

export default handleContextActionCopylink