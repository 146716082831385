import { connect } from 'react-redux';
// import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps } from '../../redux/connector';
// import { generateWidget } from 'widgetsUtils';

import { cnRating, IRatingProps, IRatingState, mapStateToProps, mapActionsToProps } from './Rating.index';
// import { IListData } from '../List/List.index';
// import { ILeaderboardResponse, ILeaderboardRequestOpts } from 'utils/src/requests/models/api.ratings';

// import { Widgets } from '../Widgets/Widgets';
// import { Item } from './Item/Rating-Item';
// import { toast } from 'react-toastify';

import './Rating.scss';
// import { PageHeader } from '../PageHeader/PageHeader';
// import { Skelet } from '../Skelet/Skelet';
import { Page } from '../Page/Page';
import { pageNames } from 'redux/pageNames';
// import { generateColumn, generateWidget } from 'i.widgets';

export class RatingPresenter extends React.Component<IRatingProps, IRatingState> {
  constructor(props: IRatingProps) {
    super(props);
    this.state = {
      // loading: false,
      // fullList: false,
      // users: [],
      // usersReversed: true,
      // rating: null,
      // searchQuery: '',
    };
    // this.props.context.common = {
    //   rId: props.match.params.id,
    // }
  }

  public componentDidMount() {
    // if (this.props.setContext) this.props.setContext({
    //   paths: ['common'],
    //   innerPath: 'rId',
    //   value: this.props.match.params.id
    // })
    // this.generateWidgets();
    // this.loadData();
  }

  public componentWillUnmount() {
    // if (this.props.setContext) this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {}
    // })
  }

  public render() {
    // const list = {
    //   header: {
    //     search: {
    //       placeholder: i18n.t('placeholders.search').toString(),
    //       function: this.handleSearchRequest,
    //     },
    //   },
    //   elements: this.state.users,
    //   item: {
    //     component: Item,
    //     props: ['pos'],
    //   },
    //   loadMore: this.loadData,
    //   fullList: this.state.fullList,
    //   loading: this.state.loading,
    // };
    // const filter ={component:  () => <div onClick={this.handleReverseRequest}>filter</div> };
    const { children } = this.props;

    return (
      <React.Fragment>
    {/* {
      this.redirect && this.redirect !== '' && <Redirect to={this.redirectTo()} />
    } */}
      <Page 
        className={cnRating({id: this.props.match.params.id})}
        page={{
          name: pageNames.rating + '.' + this.props.match.params.id
        }} />
        {children}
      </React.Fragment>
    );
  }

}

export const Rating = connect(
    mapStateToProps,
    mapDispatchToProps({ ...mapActionsToProps })
  )(
    RatingPresenter
  )
