/**
 * @packageDocumentation
 * @module Widget_Settings_ratingmini
 */
import { JFdictionary } from "../dictionary";
import { i18n, Translate } from 'localization';

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    rating: {
      type: types.object,
      placeholder: i18n.t('pryaniky.widgets.settings.placeholder.rating'),
      require: true
    },
    hideIfNoData: {
      type: types.boolean
    },
 
  }
}
