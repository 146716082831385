import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps } from "../../redux/connector";
import { MentionsInput, Mention } from 'react-mentions';
import { i18n, Translate } from 'localization';

import {
  cnTextareaMentions,
  ITextareaMentionsProps,
  ITextareaMentionsState,
  mapStateToProps,
} from './TextareaMentions.index';
import { Icon } from 'uielements/src/Icon/Icon';

import './TextareaMentions.scss';
import { toast } from 'react-toastify';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { withEditorContext } from '../EditorForm/EditorForm';
export class TextareaMentionsPresenter extends React.Component<ITextareaMentionsProps, ITextareaMentionsState> {
  public utils = utils;
  public el: HTMLElement | null;
  public mentions: any = {};

  public state = {
    loading: false
  }

  public render() {
    const { icon, className, users, tags, isAdmin, children, ...inputProps } = this.props;
    delete (inputProps as any).onChange;
    const mentions: any[] = [];
    if(users) {
      let markup = '[__display__](/user/__id__)';
      if(isAdmin) {
        markup = '[__display__](/usersgroups/__id__)';
      }
      mentions.push(<Mention
        key={'m1'}
        markup={markup}
        trigger='@'
        data={this.createSearchHandler(isAdmin ? 'usersgroups' : 'users')}
        appendSpaceOnAdd={true}
        renderSuggestion={this.renderSuggestion}
      />);
    }
    if(tags) {
      mentions.push(<Mention
        key={'m2'}
        markup='[__display__](/tag/__id__)'
        trigger='#'
        data={this.createSearchHandler('tags')}
        appendSpaceOnAdd={true}
        renderSuggestion={this.renderSuggestion}
      />);
    }
    if(isAdmin) {
      mentions.push(<Mention
        key={'m4'}
        markup={'[__display__](/user/__id__)'}
        trigger='****^%^%$$^@#$#v1'
        data={this.createSearchHandler('users')}
        appendSpaceOnAdd={true}
        renderSuggestion={this.renderSuggestion}
      />);
    }
    mentions.push(<Mention
      key={'m3'}
      markup='[__display__](/group/__id__)'
      trigger='****^%^%$$^@#$#v2'
      data={this.createSearchHandler('groups')}
      appendSpaceOnAdd={true}
      renderSuggestion={this.renderSuggestion}
    />);
    return (
      <div ref={el => this.el = el} className={cnTextareaMentions({noIcon: !icon}, [className])}>
      {
        typeof(icon) === 'string' ?
        <Icon icon={icon} className={cnTextareaMentions('Icon')} /> :
        icon
      }
        <MentionsInput
          className={cnTextareaMentions('Inner')}
  // @ts-ignore
          onChange={this.onChange}
          {...inputProps}
        >
          {mentions}
        </MentionsInput>
        {children}
      </div>
    )
  }

  private onChange = (event: any, newValue: any, newPlainTextValue: any, mentions: any) => {
    let needTrim = false;
    if(
      newValue.indexOf('[@@placeholder@@](/') !== -1 ||
      newValue.indexOf('[@@loading@@](/') !== -1
      ) {
        needTrim = true;
      }
    newValue = newValue
      .replace(/\[@@placeholder@@\]\((\/usersgroups\/|\/user\|\/group\/|\/tag\/)@@placeholder@@\)/gi, '')
      .replace(/\[@@loading@@\]\((\/usersgroups\/|\/user\|\/group\/|\/tag\/)@@loading@@\)/gi, '')
      .replace(/\]\(\/usersgroups/gi, '](');
    if(needTrim) newValue = newValue.trim();
    this.props.onChange(newValue);
  }

  private renderSuggestionView = (data: any) => {
    return (
      <div className={'Mention-Suggester' + (!data.imgUrl ? ' Mention-Suggester_noImg' : '')}>
      {
        data.imgUrl &&
        <Avatar 
          size={32}
          imgUrl={data.imgUrl.indexOf('/transparent/cccccc/user') === -1 && data.imgUrl !== 'none' ? data.imgUrl : undefined} 
          name={data.imgUrl === 'none' ? '#' : data.displayName} />
      }
        {data.displayName}
      </div>
    )
  }

  private renderSuggestion = (suggestion: any, search: any, highlightedDisplay: any) => this.renderSuggestionView(suggestion.data);

  private createSearchHandler = (type: string) => (search: any, callback: any) => {
    // this.setState({loading: true});
    if(search !== '') {
      callback([{id: '@@loading@@', display: '@@loading@@', data: {displayName: i18n.t('texts.loading')}}]);
      this.utils.API.suggesters.search(type, search)
      .r
      .then((d: any) => {
        if(this.utils.checkResponseStatus(d)) {
          callback(d.data.map((e: any) => {
            if(type === 'tags') {e.imgUrl = 'none'}
            let id = e.id;
            if(type === 'usersgroups') {
              if(e.isGroup) id = 'group/' + e.id;
              if(!e.isGroup) id = 'user/' + e.id;
            }
            return {
              id,
              display: e.displayName,
              data: e
            }
          }))
        } else {
          toast.error('something wrong');
        }
      });
    } else {
      callback([{id: '@@placeholder@@', display: '@@placeholder@@', data: {displayName: i18n.t('texts.mention_placeholder')}}]);
    }
  }

}



export const TextareaMentions = connect(
  mapStateToProps,
  mapDispatchToProps({})
  )(TextareaMentionsPresenter)

export const TextareaMentionsEditor = withEditorContext(TextareaMentions)
  //   compose(
  //     withBemMod(cnTextareaMentions(), {})
  //     )(
  //       TextareaMentionsPresenter
  //   )
  // )
