import React, { FC, useMemo, useState } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { i18n, Translate } from 'localization';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import * as utils from 'utils/src/utils';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        formControlPhone: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        labelPhone: {
            backgroundColor: '#fff', zIndex: 10
        },
    })
);


interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

// let localRef: any = {};
function TextMaskCustom(props: TextMaskCustomProps) {
    const [focused, setFocused] = useState(false)
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            {...other}
            ref={(ref: any) => {
                // localRef.inputElement = ref ? ref.inputElement : null;
                inputRef(ref ? ref.inputElement : null);
            }}
            // mask={['+', /[1-9]/, /[0-9 ]/, /[0-9 ]/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            mask={utils.phoneMaskFunc}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

export const String: FC<any> = ({ field, fieldId, value, onChangeFields, fieldsSet }) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControlPhone}  id={fieldId}>
            <InputLabel className={classes.labelPhone} htmlFor="formatted-text-mask-input">
                <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
            </InputLabel>
            <Input
                disabled={field.isReadOnly}
                value={value}
                type="string"
                onChange={e => onChangeFields(fieldId, 'value', fieldsSet)(e.currentTarget.value)}
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom as any}
            />
        </FormControl>
    );

}

export default String