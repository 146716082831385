import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    News,
    mapStateToProps,
    mapDispatchToProps
} from './interfaces'
import { useDispatch, useSelector, connect } from 'react-redux';
import './style.scss'
import TypeGroup from '../types/Group'
import TypeNews from '../types/News'
import TypeOther from '../types/Others'
import { BannerType } from '../types/Banner';
import { elemInViewport } from 'utils/src/utils';
import { pushBannerAction } from 'utils/src/requests/requests.banners'
import { Button, Icon } from 'uielements';
import { useDebounce } from 'utils/src/hooks';
import * as utilsProj from 'utils.project/utils.project';
import { i18n, Translate } from 'localization';


type IPresenterProps = News.NewsBannerItem.IPresenterProps

const aliases = {
    group: TypeGroup,
	other: TypeOther,
    news: TypeNews,
    banner: BannerType
}

export const Presenter: FC<IPresenterProps> = ({
    className,
    banner,
    readed,
    newsBannersSetReaded,
    newsBannersSetClosed
}) => {

    const { id } = banner;

    useEffect(() => {

    }, [])

    const ref = React.createRef<HTMLDivElement>();

    const setReaded = useDebounce(() => {
        ref.current && !readed && elemInViewport(ref.current) && newsBannersSetReaded({ id })
    }, 500, [readed]);

    useEffect(() => {
        setReaded();
    }, []);

    useEffect(() => {
        if(!readed) document.addEventListener('scroll', setReaded);
        else {
            pushBannerAction({ id, action: 'View' });
        }
        return () => {
            document.removeEventListener('scroll', setReaded);
        };
    }, [readed]);

    const onClick = () => {
        pushBannerAction({ id, action: 'Click' });
    }

    const setClosed = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        utilsProj.confirm({
            title: Translate.t({ i18nKey: 'pryaniky.advertisements.item.banner.action.hide'}),
            onConfirm: () => {
                newsBannersSetClosed({ id });
                pushBannerAction({ id, action: 'Hide' });
            }
        });
    }

    const Component = aliases[banner.type] ?? aliases['other']

    return <div ref={ref} onClick={onClick} className={cnClassName({}, [className])}>
        <Button className={cnClassName('Close')} noPadding onClick={setClosed}>
            <Icon icon='times' />
        </Button>
        <Component banner={banner} />
    </div>
}

export const Banner = connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));

export default Banner