import { i18n, Translate } from 'localization';
import { TOptions } from 'i18next';
import React, { ComponentProps, FC } from 'react';

type ITranslateOwnProps = ComponentProps<typeof Translate>

/**
 * students texts
 */
export const tS = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.LMS.student.${key}`, options)

export const Ts: FC<ITranslateOwnProps> = ({ i18nKey, ...props }) => <Translate {...props} i18nKey={`pryaniky.LMS.student.${i18nKey}`} />

/**
 * common texts
 */
export const tC = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.LMS.common.${key}`, options)

export const Tc: FC<ITranslateOwnProps> = ({ i18nKey, ...props }) => <Translate {...props} i18nKey={`pryaniky.LMS.common.${i18nKey}`} />

/**
 * utils texts
 */
export const tU = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.LMS.utils.${key}`, options)

export const Us: FC<ITranslateOwnProps> = ({ i18nKey, ...props }) => <Translate {...props} i18nKey={`pryaniky.LMS.utils.${i18nKey}`} />

/**
 * teacher texts
 */
export const tT = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.LMS.teacher.${key}`, options)

export const Tt: FC<ITranslateOwnProps> = ({ i18nKey, ...props }) => <Translate {...props} i18nKey={`pryaniky.LMS.teacher.${i18nKey}`} />


/**
 * tracks texts
 */
export const tTr = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.LMS.tracks.${key}`, options)

export const TTr: FC<ITranslateOwnProps> = ({ i18nKey, ...props }) => <Translate {...props} i18nKey={`pryaniky.LMS.tracks.${i18nKey}`} />

/**
 * statistics texts
 */
export const tSt = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.LMS.statistics.full.${key}`, options)

export const TSt: FC<ITranslateOwnProps> = ({ i18nKey, ...props }) => <Translate {...props} i18nKey={`pryaniky.LMS.statistics.full.${i18nKey}`} />