import React, { ComponentProps } from "react";
import { cn } from '@bem-react/classname';
import { withWidget } from '../Widget';
import { IWidgetTypeLayoutGridProps } from './Widget_type_grid.index';
import './Widget_type_grid.scss';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { cnWidget } from "../Widget.index";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { prepareWidget } from '../../../i.widgets';
import { clone } from "utils/src/utils";
import { WidgetsAdd } from '../../Dialogs/Widgets/Add/WidgetsAdd';
import { DialogWidgetAdd } from '../../Dialogs/Widgets/AddNew/WidgetsAddNew';
import { i18n, Translate } from 'localization';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { ComponentsProps } from "@material-ui/core/styles/props";

const GridLayout = WidthProvider(Responsive);

const cnGrid = cn('Grid');

const type = 'grid';

const addDataHeight = 1;

// флаг для отмены страндартного изменения структуры при условии что нет layout для теукщего breakpoint
let changeLayoutFlag = true;

const WidgetTypeGridPresenter: React.FC<IWidgetTypeLayoutGridProps> = ({
  widget,
  widgets,
  edit,
  changeWidgetSettings,
  addWidget,
  ...props
}) => {

  const { settings } = widget;

  const [currentBreakpoint, setCurrentBreakpoint] = React.useState('lg');

  const layouts = settings?.layouts;

  const breakpoints = settings?.breakpoints || { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };

  const cols = settings?.cols || { lg: 12, md: 10, sm: 8, xs: 1, xxs: 1 };

  const [showAdd, setShowAdd] = React.useState(false);

  const addWidgetHandler = (newWidget: { [s: string]: any }) => {
    newWidget.addToGrid = {
      ...newWidget.addToGrid,
      layoutItem: {
        ...newWidget.addToGrid.layoutItem,
        i: newWidget.id,
        w: cols[currentBreakpoint]
      }
    };
    newWidget.relations = [widget.id, newWidget.id];
    addWidget(newWidget);
  }

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  /**
   * изменяем активный breakpoint
   * @param breakpoint 
   * @param colls 
   */
  const onBreakpointChange: ComponentProps<typeof GridLayout>['onBreakpointChange'] = (breakpoint, colls) => {
    setCurrentBreakpoint(breakpoint);
  }

  /**
   * иземенение лайаута
   * @param layout 
   * @param allLayouts 
   */
  const onLayoutChange: ComponentProps<typeof GridLayout>['onLayoutChange'] = (layout, layouts) => {
    changeWidgetSettings({
      wId: widget.id,
      settings: {
        layouts
      }
    });
  }

  return (
    <div className={cnGrid('Wrapper', { edit })}>
      {
        edit &&
        <>
          <Button
            onClick={() => DialogWidgetAdd({
              widgetId:widget.id,
              addToGrid: {
                breakpoint: currentBreakpoint,
                layoutItem: { i: 'newWidgetIdFilledOnConfirm', x: 0, y: 0, w: 1, h: 15 }
              },
            }).then(addWidgetHandler)}
            title={Translate.t({ i18nKey: 'pryaniky.widgets.add.widget' })}
            className={cnGrid('Adder')}
            children={<Icon icon="plus" />}
          />
          {/* <WidgetsAdd
            data={{
              addToGrid: {
                breakpoint: currentBreakpoint,
                layoutItem: { i: 'newWidgetIdFilledOnConfirm', x: 0, y: 0, w: 1, h: 15 }
              },
            }}
            isShown={showAdd}
            onConfirm={addWidgetHandler}
            onClose={() => setShowAdd(false)}
          /> */}
        </>
      }
      <GridLayout
        className={cnGrid({ edit })}
        layouts={layouts}
        isDraggable={edit}
        isResizable={edit}
        breakpoints={breakpoints}
        cols={cols}
        rowHeight={5}
        margin={[24, 24]}
        autoSize={true}
        onResizeStart={(layout, oldItem, newItem, placeholder, event, element) => { event.stopPropagation() }}
        onResize={(layout, oldItem, newItem, placeholder, event, element) => { event.stopPropagation() }}
        onDragStart={(layout, oldItem, newItem, placeholder, event, element) => { event.stopPropagation() }}
        onDrag={(layout, oldItem, newItem, placeholder, event, element) => { event.stopPropagation() }}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
      >
        {
          (widget.data || [])[0]?.items?.map((el: string) => {
            if (!el || !widgets[el]) return null;
            return <div key={el} className={cnGrid('Item')}>
              {prepareWidget(widgets[el])}
            </div>
          }).filter((el: any) => el)
        }
      </GridLayout>
    </div>
  )
}

const WidgetTypeGridWithRedux = WidgetTypeGridPresenter;

export const WidgetTypeGrid = withWidget(WidgetTypeGridWithRedux, cnWidget({ type }));

export default WidgetTypeGrid;