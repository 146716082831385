import React, { useState, useEffect } from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
// import { Button } from 'uielements/src/Button/Button';
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput';
import { withBemMod } from '@bem-react/core';
import { Layout } from '../../Layout/InfoBlock-Layout';
import { cnGroup } from '../InfoBlock-Group.index';
import { IGroupEditTrueProps } from './InfoBlock-Group_edit_true.index';
import './InfoBlock-Group_edit_true.scss';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { i18n, Translate } from 'localization';
import { InputMention } from 'blocks/InputMention/InputMention';
import { ImgLoader } from 'blocks/ImgLoader/ImgLoader';
import Button from '@material-ui/core/Button';
import { myTheme, useStyles } from './InfoBlock-Group_edit_true.style'
import { Select as PrnSelect } from 'blocks/PryanikyForms'
import { Icon } from 'uielements/src'
import { renderChipDND } from 'blocks/PryanikyForms/renderers'
import { BadgeSelector } from 'uielements/src/BadgeSelector/BadgeSelector'
import { defaultBadge } from 'blocks/NewsTypes/Badges/Badges.type'
import { Bable } from 'blocks/Bable/Bable'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { toast } from 'react-toastify';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { DialogComments as MoveGroupToAllNetworkDialog } from 'blocks/Dialogs/Comments/Comments';

// import { Badges } from 'blocks/NewsTypes/Badges/Badges';
// const classes: any = {};
export const withGroupEditTrue = withBemMod<any>(
  cnGroup(),
  { edit: 'true' },
  (Presenter) => (
    ({ data, ...props }: IGroupEditTrueProps) => {
      const classes = useStyles();

      const [requestAllNetwrksDialog, setRequestAllNetwrksDialog] = useState(false);

      const [autoInclude, setAutoInclude] = useState<any[]>([])
      const [badges, setBadges] = useState<any[]>([])
      useEffect(() => {
        utils.API.badges.getBadgeList().r.then((d: any) => {
          if (utils.checkResponseStatus(d)) {
            setBadges([{ defaultBadge, displayName: 'Удалить бейдж' }, ...d.data])
          }
        })
      }, [])

      const [state, setState] = useState<any>(null);
      if (!state) {
        utils.API.groups.settings(props.groupId)
          .r
          .then((d: any) => {
            // if (utils.checkResponseStatus(d)) {
            setState(d.data);
            // } else {
            // toast.error('something wrong')
            // }
          });
        return (<div>load</div>);
      }

      const changeDisplayName = (val: string) => {
        state.name = val;
        setState({ ...state });
      }

      const changeDescription = (val: string) => {
        state.description = val;
        setState({ ...state });
      }

      const changePeopleAutoIncludeExpression = (val: string) => {
        state.peopleAutoIncludeExpression = val
        setState({ ...state });
      }

      const testAutoInclude = () => {
        utils.API.users.list({ checkexpression: true, autoinclude: state.peopleAutoIncludeExpression }).r.then((d: any) => {
          if (utils.checkResponseStatus(d)) {
            setAutoInclude(d.data)
          }
        })
      }

      const includeUsers = () => {
        utils.API.users.list({ checkexpression: false, autoinclude: state.peopleAutoIncludeExpression }).r.then((d: any) => {
          if (utils.checkResponseStatus(d)) {
            // setAutoInclude(d.data)
            toast.success('Пользователи добавлены!')
          }
        })
      }

      const changeVisibilityType = (event: any) => {
        state.visibilityType = event.target.value;
        setState({ ...state });
      }

      const handleIsHidden = () => {
        state.isHidden = !state.isHidden;
        setState({ ...state });
      }

      const handleIsContentDisabled = () => {
        state.isContentDisabled = !state.isContentDisabled;
        setState({ ...state });
      }

      const changeChatChannelName = (val: string) => {
        state.chatChannelName = val;
        setState({ ...state });
      }

      const handleChatEnabled = () => {
        state.chatEnabled = !state.chatEnabled;
        setState({ ...state });
      }

      const handleIsSystemGroup = () => {
        state.isSystemGroup = !state.isSystemGroup;
        setState({ ...state });
      }

      const handleIsOfficialGroup = () => {
        state.isOfficialGroup = !state.isOfficialGroup;
        setState({ ...state });
      }

      const applyNotificationsTemplate = () => {
        utils.API.groups.applyNotificationsTemplate(props.groupId, state.defaultNotifyTemplate)
          .r
          .then((d: any) => {
            if (utils.checkResponseStatus(d)) {
              toast.success('Пользователи добавлены!')
            }
          })
      }

      const handleRemoveLinkedBadge = () => {
        state.removeLinkedBadgeOnLeave = !state.removeLinkedBadgeOnLeave;
        setState({ ...state });
      }

      const _handleGroupNewsTypes = (data: any) => {
        state.groupNewsTypes = data;
        setState({ ...state });
      }

      const changeNotifyTemplate = (event: any) => {
        state.defaultNotifyTemplate = event.target.value
        setState({ ...state })
      }

      const handleMoveNewsType = (dragIndex: any, hoverIndex: any, dragItem: string) => {
        let r = [...state.groupNewsTypes]

        r.splice(dragIndex, 1)
        r.splice(hoverIndex, 0, state.groupNewsTypes[dragIndex])

        state.groupNewsTypes = r

        setState({ ...state });
      }

      const onChangeParent = (d: any) => {
        if (d) state.rootGroup = d;
        else state.rootGroup = null;
        setState({ ...state });
      }

      const handleIsModeratedGroup = () => {
        state.isModeratedGroup = !state.isModeratedGroup;
        setState({ ...state });
      }

      const handleShowGroupInSubnetworks = () => {
        state.showGroupInSubnetworks = !state.showGroupInSubnetworks;
        setState({ ...state });
      }

      const changeBadge = (val: any) => {
        state.badge = val;
        setState({ ...state });
      }

      const onChangeModerators = (d: any) => {
        state.moderators = d//d.map((v: any) => v.data);
        setState({ ...state });
      }

      const imgLoaded = (loadedImg: { [s: string]: any }) => {
        state.imgId198x198 = loadedImg.id;
        state.imgId = loadedImg.id;
        state.imgUrl198x198 = loadedImg.originalUrl;
        state.imgUrl = loadedImg.originalUrl;
        setState({ ...state });
      }


      const saveData = () => {
        utils.API.groups.saveSettings(props.groupId, state)
        props.setData({ ...data, ...state, isMeInGroup: data.isMeInGroup })
        props.editEnd();
      }
      return (
        <Presenter {...props} data={data}>{(d: any) => <Layout
          left={
            <div className={'InfoBlock-Img'}>
              <ImgLoader
                type={'profileEdit'}
                previewUrl={state.imgUrl198x198}
                onLoadEnd={imgLoaded} />
            </div>
          }
          right={
            <div className={cnGroup('Data')}>
              <MuiThemeProvider theme={myTheme}>
                <div className={classes.root}>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                    <TextField fullWidth
                      label={i18n.t('pryaniky.modal.groupCreate.name')}
                      // inputProps={{ className: classes.formControlName }}
                      // variant={'outlined'}
                      className={classes.formControlName}
                      value={state.name}
                      onChange={(e: any) => changeDisplayName(e.target.value)} />
                  </FormControl>
                  <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                    <TextField fullWidth
                      multiline={true}
                      label={i18n.t('description')}
                      value={state.description}
                      onChange={(e: any) => changeDescription(e.target.value)} />
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                    <RadioGroup
                      row={true}
                      className={classes.formControlVisibilityType}
                      name="visibilityType"
                      value={state.visibilityType.toString()}
                      onChange={changeVisibilityType}
                    >
                      <FormControlLabel value={'0'} control={<Radio color="default" />} label={i18n.t('public group')} />
                      <FormControlLabel value={'1'} control={<Radio color="default" />} label={i18n.t('private group')} />
                    </RadioGroup>
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.isHidden}
                      onChange={handleIsHidden}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={i18n.t('visibli_only_for_members')} />
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.isContentDisabled}
                      onChange={handleIsContentDisabled}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={'Запретить контент в группе'} />
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.chatEnabled}
                      onChange={handleChatEnabled}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={i18n.t('chat_enable')} />
                    {state.chatEnabled && <TextField disabled={!state.chatEnabled} fullWidth
                      label={i18n.t('chat_chanel_name')}
                      value={state.chatChannelName} onChange={(e: any) => changeChatChannelName(e.target.value)} />}
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.isSystemGroup}
                      onChange={handleIsSystemGroup}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={i18n.t('system group')} />
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.isOfficialGroup}
                      onChange={handleIsOfficialGroup}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={i18n.t('official group')} />
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    {/* <InputLabel htmlFor="select-multiple-checkbox">{i18n.t('news_types')}</InputLabel> */}
                    <label className={classes.formLabel}>{i18n.t('news_types')}</label>
                    <PrnSelect
                      placeholder={i18n.t('news_types')}
                      openToggler={(open: boolean, setOpen: any) => <span onClick={() => setOpen(!open)}><Icon icon={'chevron-down'} /></span>}
                      onChange={_handleGroupNewsTypes}
                      disasbleFilter={true}
                      className={cnGroup('GroupNewsTypes')}
                      closeOnSelect={false}
                      excludeFilter={(values: any) => values}
                      value={state.groupNewsTypes}
                      variants={props.store.postTypes.map((val: any) => val.oldType)}
                      itemChip={renderChipDND(handleMoveNewsType)}
                      items={(values: any, onClick: (item: any) => () => void, focusIndex: number = 0, selected: any[] = []) =>
                        values.map((value: any, i: any) =>
                          <div className={cnGroup('GroupNewsTypesItem') + (i === focusIndex ? ' focused_item' : '')} onClick={onClick(value)}>
                            <Checkbox
                              checked={selected.includes(value)}
                              onChange={(e: any) => { onClick(value); e.stopPropagation() }}
                              color="default"
                              inputProps={{
                                'aria-label': 'checkbox with default color',
                              }}
                            />
                            <span>{i18n.t(value)}</span>
                          </div>)}
                    />
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <label className={classes.formLabel}>{i18n.t('parent_group')}</label>
                    <InputMention onChange={onChangeParent}
                      noMulti={true}
                      type={'groups'}
                      additionalRequestOpts={{ excludeSystemGroups: 'true' }}
                      className={classes.formControl}
                      // className={this.props.className} 
                      // icon={'user-add'} 
                      value={state.rootGroup}
                      placeholder={i18n.t('parent_group')} />

                  </FormControl>
                  <h4>{i18n.t('pryaniky.group.settings.optional')}</h4>
                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>

                    <Select
                      value={state.defaultNotifyTemplate}
                      onChange={changeNotifyTemplate}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={(selected) => <div className={classes.typesSelect}>{i18n.t('pryaniky.notifications.settings.template.' + selected as string)}</div>}
                    >
                      {["all", "common", "none"].map((name: any) => (
                        <MenuItem key={name} value={name}>
                          <ListItemText primary={i18n.t('pryaniky.notifications.settings.template.' + name)} />
                        </MenuItem>
                      ))}
                    </Select>

                    <div className={classes.peopleAutoIncludeActions}>
                      <Button id='group-edit-saveEdit'
                        className={classes.peopleAutoIncludeBtn}
                        color="primary"
                        variant="contained"
                        onClick={applyNotificationsTemplate}>{i18n.t('pryaniky.group.settings.applyNotificationsTemplate')}</Button>
                    </div>
                  </FormControl>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.isModeratedGroup}
                      onChange={handleIsModeratedGroup}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={i18n.t('moderating_in_group')} />
                  </FormControl>

                  {state.isModeratedGroup && <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                    <PrnUserInput
                      hideDialog
                      onChange={onChangeModerators}
                      placeholder={i18n.t('pryaniky.group.settings.addModerators')}
                      value={state.moderators}
                      className={cnGroup('ModeratorSelect')}
                      excludeUsers={state.moderators} />
                  </FormControl>}

                  {
                    state.showGroupInSubnetworks && i18n.t('pryaniky.subnetworks.intercompanyRequestAprooved')
                  }

                  {
                    (!props.appSettings.parentNetwork || props.appSettings.parentNetwork === null) &&
                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                      <FormControlLabel control={<Checkbox
                        checked={state.showGroupInSubnetworks}
                        onChange={handleShowGroupInSubnetworks}
                        color="default"
                        inputProps={{
                          'aria-label': 'checkbox with default color',
                        }}
                      />} label={i18n.t('pryaniky.group.settings.showGroupInSubnetworks')} />
                    </FormControl>
                  }

                  {
                    (props.appSettings.parentNetwork) &&
                    <FormControl margin="normal" component="fieldset" className={classes.formControl}>
                      {/* <MoveGroupToAllNetworkDialog
                        isShown={requestAllNetwrksDialog}
                        onClose={() => setRequestAllNetwrksDialog(false)}
                        onConfirm={(data) => utils.API.groups.makeGroupIntercompany(state.pkid, `${data.createTarget || ''}%COMMENTSPLITTER%${data.content || ''}%COMMENTSPLITTER%${data.attendance || ''}`)
                          .r
                          .then(d => utils.checkResponseStatus(d) && toast.success(i18n.t('pryaniky.subnetworks.intercompanyRequestSended')))
                        }
                        data={{}}
                      /> */}
                      {
                        state.showGroupInSubnetworks || (state.groupTransferRequest && (state.groupTransferRequest.status.toString() === '1' || state.groupTransferRequest.status.toString() === 'RequestApproved')) && i18n.t('pryaniky.subnetworks.intercompanyRequestAprooved')
                      }
                      {
                        (
                          // RequestAdded = 0, RequestApproved = 1, RequestDeclined = 2
                          !state.showGroupInSubnetworks &&
                          (
                            !state.groupTransferRequest ||
                            (state.groupTransferRequest && (state.groupTransferRequest.status.toString() === '2' || state.groupTransferRequest.status.toString() === 'RequestDeclined'))
                          )
                        ) &&
                        <>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => MoveGroupToAllNetworkDialog({}).then((data: any) => utils.API.groups.makeGroupIntercompany(state.pkid, `${data.createTarget || ''}%COMMENTSPLITTER%${data.content || ''}%COMMENTSPLITTER%${data.attendance || ''}`)
                              .r
                              .then(d => utils.checkResponseStatus(d) && toast.success(i18n.t('pryaniky.subnetworks.intercompanyRequestSended')))).catch(() => { })}
                            children={i18n.t('pryaniky.subnetworks.intercompanyRequestSend')} />
                          {/* <Button color="primary" variant="contained" onClick={() => setRequestAllNetwrksDialog(true)} children={i18n.t('pryaniky.subnetworks.intercompanyRequestSend')} /> */}
                          {
                            state.groupTransferRequest && (
                              <div>
                                {i18n.t('pryaniky.subnetworks.intercompanyRequestDeclined')}
                                <div>
                                  {`${i18n.t('pryaniky.subnetworks.intercompanyRequestDeclined.adminComment')}: ${state.groupTransferRequest.adminComment}`}
                                </div>
                              </div>
                            )
                          }
                        </>
                      }
                      {
                        !state.showGroupInSubnetworks &&
                        (
                          state.groupTransferRequest && (state.groupTransferRequest.status.toString() === '0' || state.groupTransferRequest.status.toString() === 'RequestAdded')
                        ) &&
                        <>
                          <Button color="primary" variant="contained" onClick={() => {
                            utilsProj.confirm({
                              text: i18n.t("pryaniky.subnetworks.intercompanyRequestCancel.confirm"),
                              onConfirm: () =>
                                utils.API.groups.cancelRequestGroupIntercompany(state.pkid, i18n.t('pryaniky.subnetworks.intercompanyRequestCancelComment'))
                                  .r
                                  .then(d => utils.checkResponseStatus(d) && toast.success(i18n.t("pryaniky.subnetworks.intercompanyRequestCanceled")))
                            });
                          }} children={i18n.t('pryaniky.subnetworks.intercompanyRequestCancel')} />
                          <div children={i18n.t('pryaniky.subnetworks.intercompanyRequestOnModeration')} />
                        </>
                      }
                    </FormControl>
                  }

                  <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                    <TextField fullWidth
                      multiline={true}
                      label={i18n.t('pryaniky.group.settings.autoInclude')}
                      value={state.peopleAutoIncludeExpression}
                      onChange={(e: any) => changePeopleAutoIncludeExpression(e.target.value)} />
                    <div className={classes.peopleAutoIncludeActions}>
                      {(state.peopleAutoIncludeExpression && '' !== state.peopleAutoIncludeExpression)
                        && <Button id='group-edit-saveEdit'
                          className={classes.peopleAutoIncludeBtn}
                          color="primary"
                          variant="contained"
                          onClick={testAutoInclude}>{i18n.t('pryaniky.group.settings.autoInclude.test')}</Button>}

                      {(state.peopleAutoIncludeExpression && '' !== state.peopleAutoIncludeExpression)
                        && <Button id='group-edit-saveEdit'
                          className={classes.peopleAutoIncludeBtn}
                          color="primary"
                          variant="contained"
                          onClick={includeUsers}>{i18n.t('pryaniky.group.settings.autoInclude.add')}</Button>}
                    </div>


                  </FormControl>

                  <div className={'AutoIncludeList'}>
                    {autoInclude.map((val: any) => <Bable className={'AutoIncludeUser'}>
                      <UserMention className={'UserMention-displayName'} name={val.displayName} id={val.id} noLink={true} />
                    </Bable>)}
                  </div>

                  <FormControl margin="normal" component="fieldset" className={classes.formControl} >
                    <BadgeSelector selectedBadge={state.badge || defaultBadge} badgeList={{ badgeRenderParams: { badges } }} onChange={changeBadge} />
                  </FormControl>

                  {state.badge && <FormControl component="fieldset" className={classes.formControl}>
                    <FormControlLabel control={<Checkbox
                      checked={state.removeLinkedBadgeOnLeave}
                      onChange={handleRemoveLinkedBadge}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />} label={'Cнимать бейдж при выходе из группы'} />
                  </FormControl>}


                </div>
                <div className={classes.confirmBox}>
                  <Button id='group-edit-cancleEdit' className={classes.confirmBtn} onClick={props.editEnd}>{i18n.t('cancel')}</Button>
                  <Button id='group-edit-saveEdit' className={classes.confirmBtn} color="primary" variant="contained" onClick={saveData}>{i18n.t('save')}</Button>
                </div>
              </MuiThemeProvider>
            </div>

          } />}</Presenter>
      )
    }
  )
);