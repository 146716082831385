import React, { FC, useMemo } from 'react';
import { useWallet, useWallets } from './WalletsNew.hooks';
import * as MuiIcons from 'muicomponents/src/Icons';
import { Button } from 'muicomponents/src/Button/Button';
import { ImageIcon } from 'muicomponents/src/Icons/ImageIcon';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { AddCurrency, CloseDeposit } from './AddCurrency/AddCurrency';
import { i18n, Translate } from 'localization';
import moment from 'moment';
import SavingsIcon from '@mui/icons-material/Savings';
import { Box, Paper, Typography } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import { IconButton } from 'muicomponents/src/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { EmptyWalletInfoBox, FirstInfoBox, LightTooltip, OpenDepositButton, WalletInfoBox } from './WalletsNew.styled';
import { Popper } from '@material-ui/core';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';


export const Wallet: FC<{
    /**
     * user id
     */
    uid: string;
    /**
     * wallet id to render
     */
    walletId: NonNullable<ReturnType<typeof useWallets>['wallets']>[number];
}> = ({
    uid,
    walletId
}) => {

        const {
            wallet,
            allowActions,
            mythanksCount,
            changeCurrencyAtWallet
        } = useWallet(uid, walletId);

        const icon = useMemo(() => {
            if (!wallet) return
            if (wallet.iconUrl) return <ImageIcon url={wallet.iconUrl} />;
            const MuiIcon = MuiIcons[wallet.icon as keyof typeof MuiIcons];
            if (MuiIcon) return <Avatar sx={{ mr: 1, backgroundColor: wallet?.value ? '#FFB400' : undefined }} >
                <MuiIcon />
            </Avatar>
            return <Avatar sx={{ mr: 1, backgroundColor: wallet?.value ? '#FFB400' : undefined }} >
                <SavingsIcon color={'action'} />
            </Avatar>
        }, [wallet?.icon, wallet?.iconUrl, wallet?.value])

        const data = useMemo(() => {
            if (!allowActions) {
                return (
                    <EmptyWalletInfoBox>
                        {icon}
                        <Box>
                            <Typography variant='body2'>{wallet?.name}</Typography>
                            <Typography variant='subtitle2' fontWeight='700' >{wallet?.value}</Typography>
                        </Box>
                    </EmptyWalletInfoBox>
                )
            };
            switch (wallet?.type) {
                case 'deposit': {
                    if (wallet.value === 0) {
                        return (
                            <LightTooltip
                                title={!wallet.isActive ? <Translate i18nKey={'pryaniky.wallets.deposit.open.disabled'} /> : ''}
                            >
                                <>
                                    <EmptyWalletInfoBox>
                                        {icon}
                                        <Box>
                                            <Typography variant='body2'>{wallet.name}</Typography>
                                            <Typography variant='subtitle2' fontWeight='700' >0</Typography>
                                        </Box>
                                    </EmptyWalletInfoBox>
                                    <OpenDepositButton
                                        fullWidth
                                        variant="outlined"
                                        disabled={mythanksCount === 0 || !wallet.isActive}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!wallet.isActive) return;
                                            AddCurrency({ uid, walletId })
                                        }}
                                    >
                                        <Typography>
                                            {i18n.t('pryaniky.wallets.deposit.open')}
                                        </Typography>
                                    </OpenDepositButton>
                                </>
                            </LightTooltip>
                        )
                    } else {
                        return (
                            <>
                                <WalletInfoBox>
                                    <FirstInfoBox>
                                        {icon}
                                        <Box>
                                            <Typography variant='body2'>{wallet.name}</Typography>
                                            <Typography variant='subtitle2' fontWeight='700' >{wallet.value}</Typography>
                                        </Box>
                                    </FirstInfoBox>
                                    <LightTooltip                                    
                                        title={
                                            <Button 
                                                variant='outlined'
                                                size='small'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    CloseDeposit({ uid, walletId });
                                                }}
                                            >
                                                {i18n.t('pryaniky.wallets.deposit.close')}
                                            </Button>
                                        } >
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                CloseDeposit({ uid, walletId });
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </LightTooltip>
                                </WalletInfoBox>

                            </>
                        )
                    }
                };
                case 'external': {
                    return (
                        <Typography>{wallet.value}</Typography>
                    )
                };
                default: {
                    return (
                        <Box onClick={(e) => {
                            e.stopPropagation();
                            AddCurrency({ uid, walletId });
                        }}>{wallet?.value}</Box>
                    )
                };
            };
        }, [wallet?.type, wallet?.value, allowActions]);

        const helpTooltip = useMemo(() => {
            switch (wallet?.type) {
                case 'deposit': {
                    if (wallet.value !== 0) {
                        const date = moment(wallet.expires).format('L');
                        return <WalletInfoBox>
                            <Typography variant='body2'>
                                <Translate
                                    i18nKey={'pryaniky.wallets.deposit.info'}
                                    values={{
                                        walletName: wallet.name,
                                        percentage: wallet.percentage || '',
                                        date: date
                                    }}
                                />
                            </Typography>
                        </WalletInfoBox>
                    }
                    return ''
                };
                case 'external': {
                    return (
                        <WalletInfoBox>
                            <Typography variant='body2'>{wallet.name}</Typography>
                        </WalletInfoBox>
                    )
                };
                default: {
                    return ''
                };
            };
        }, [wallet?.type, wallet?.value, wallet?.info, icon]);

        if (!wallet) return null;

        return <Box>
            <LightTooltip
                title={
                    helpTooltip
                }
                placement='top-start'
            >
                <Box
                    onClick={() => wallet?.actionUrl && window.open(wallet?.actionUrl, '_blank')}
                    sx={{
                        cursor: wallet?.actionUrl ? 'pointer' : undefined,
                    }}
                >
                    {data}
                </Box>
            </LightTooltip>
        </Box>
    };

export const WalletsMui5: FC<{
    /**
     * user id
     */
    uid: string
}> = ({
    uid
}) => {

        const {
            wallets
        } = useWallets(uid);
        
        if (!wallets || !wallets.length) return null;

        return (
            <PaperBox variant='elevation'>
                {
                    wallets.map((walletId, idx) => {
                        return (
                            <Wallet key={walletId} uid={uid} walletId={walletId} />
                        )
                    })
                }
            </PaperBox>
        )
    };