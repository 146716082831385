/**
 * @packageDocumentation
 * @module List_type_users_selector
 */
import React, { FC, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IDispatchProps, mapDispatchToProps } from 'redux/connector';
import * as utils from 'utils/src/utils';

import { IListProps, IListStateProps, mapActionsToProps, mapStateToProps } from './../../List.index';
import { avalibleFilters, TItemType } from './List_type_users.index';

import { сhangeMail } from 'blocks/Dialogs/Users/ChangeMail/ChangeMail';
import { ChangeUserNetwork } from 'blocks/Dialogs/Users/ChangeNetwork/ChangeNetwork';
import { dialogUserInvite } from 'blocks/Dialogs/Users/UserInviteDialog';
import { i18n, Translate } from 'localization';
import { toast } from 'react-toastify';
import { IStateType as IState } from 'redux/store';
import { changePasswordDialog } from '../../../Dialogs/ChangePassword/ChangePassword';
import { commentDialog } from '../../../Dialogs/Comment/Comment';

import { SOCOrgchartUnitsTreeManager } from 'blocks/Filters/_type/Filters_type_socogchartunits/Filters_type_socogchartunits.hooks';
import { FiltersSOCOrgchartUnitsProps } from 'blocks/Filters/_type/Filters_type_socogchartunits/Filters_type_socogchartunits.index';
import { push } from 'connected-react-router';
import { confirm } from 'muicomponents/src/Confirm/Confirm';
import { mapFromUserList } from 'muicomponents/src/FieldRender/mapUserListFields';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { ItemsList } from 'muicomponents/src/ItemsListDialog/ItemsListDialog';
import { ListItemBodyElementClickActionType } from 'muicomponents/src/ItemsListDialog/ItemsListDialog.index';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { IOption, SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { ISplitButtonOption } from 'muicomponents/src/SplitButton/SplitButton.index';
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index';
import { UserAdditionalFieldType } from 'utils/src';
import { useDidUpdateEffect, useLazyQueryEx, useScrollListState, useSearchParams } from 'utils/src/hooks';
import { getList } from 'utils/src/requests/admin.registrations';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { list } from 'utils/src/requests/requests.users';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { v1 as uuid } from 'uuid';
import { useContextFiltersChange } from '../../../Filters/Filters.hooks';
import { allUserFiltersKeys, UserAddittionalContent, UserSettedAdditionalFilters } from './List_type_users.utils';

const useGetRequersParams = (context: any, requestOptions: any) => {
    const opts = useMemo(() => {
        const opts = Object.assign({}, requestOptions);
        Object.keys(context).forEach((cKey: string) => {
            if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
            if (cKey === 'tId') opts['showcontext'] = 'bytag';
            if (cKey === 'units' && typeof context[cKey] === 'string') opts[avalibleFilters[cKey]] = [context[cKey]];
        });
        return opts;
    }, [context, requestOptions]);
    return opts;
};

const addAccessRequestFields = (item: any) => {
    return {
        id: uuid(),
        title: '',
        description: '',
        isCollapsed: false,
        fields: [
            {
                value: '',
                data: i18n.t(`pryaniky.registration.item.status.${item.requestStatus}`),
                type: 'String',
                displayName: i18n.t(`status`),
                name: i18n.t(`status`),
                id: uuid(),
                isMandatory: false,
                description: '',
                displayMode: '',
                isBaseField: false,
                isHidden: false,
                isReadOnly: false,
                maxLength: 0,
                position: 0,
                selectOnlyFromCollection: false,
            },
            {
                value: '',
                data: item.timeStamp,
                type: 'DateTime',
                displayName: i18n.t(`pryaniky.registration.requestTime`),
                name: i18n.t(`pryaniky.registration.requestTime`),
                id: uuid(),
                isMandatory: false,
                description: '',
                displayMode: '',
                isBaseField: false,
                isHidden: false,
                isReadOnly: false,
                maxLength: 0,
                position: 0,
                selectOnlyFromCollection: false,
            },
        ],
    };
};

// async function actionPromise(id: string, userId: string[], comment?: string) {
//   const response = await utils.API.users.action(id, userId, comment)
//   return await response.r
// }

async function inviteNotInitialized() {
    try {
        const response = await confirm({
            text: i18n.t('pryaniky.list.users.confirm.inviteNotInitialized'),
        });
        if ('confirm' === response) {
            const response = await utils.API.users.inviteNotInitialized().r;
            if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
            else if (response.error_code) toast.error(response.error_text);
            else {
                toast.success(i18n.t(`pryaniky.list.users.actions.inviteNotInitialized.success`));
            }
        }
    } catch (error) {
        console.error('inviteNotInitialized', error);
    }
}

const InvaiteButton: FC<{ context: any; wcontext: any }> = ({ context, wcontext }) => {
    async function onClick() {
        try {
            await dialogUserInvite({});
        } catch (error) {}
    }

    let actionsOptions: IOption[] = [];
    const type = context.type || wcontext.usersCatFilter;
    if (type === 'notinitialized') {
        actionsOptions.push({
            content: <Translate i18nKey={'pryaniky.list.users.actions.inviteAll'} />,
            id: 'inviteAll',
        });
        actionsOptions.reverse();
    }
    return (
        <SplitButton
            sx={{ marginLeft: '8px' }}
            color="primary"
            variant="contained"
            handleMenuItemClick={(_, item) => {
                switch (item.id) {
                    case 'inviteAll':
                        inviteNotInitialized();
                        break;

                    default:
                        break;
                }
            }}
            options={actionsOptions}
            buttonProps={{
                children: i18n.t('pryaniky.list.users.actions.invite'),
                onClick,
            }}
        />
    );
};

export const N_ListGroupUsersSelectorPresenter: FC<any> = ({
    context,
    wcontext,
    isGroupAdmin,
    authUser,
    uIsAdmin,
    requestOptions,
    ...props
}) => {
    const [{ search: defaultSearchParameter, ...searchParams }, updateSearchParams] =
        useSearchParams<{ search: string }>();
    const searchParamsRef = useRef(searchParams);
    searchParamsRef.current = searchParams;

    const contextUpdate = useContextFiltersChange<FiltersSOCOrgchartUnitsProps['context']>(
        props.widget?.relations || []
    );

    const isRegistrationsRequests = context.arfilter;
    const dispatch = useDispatch();

    const oneContextFilterIsEnabled = useMemo(() => {
        return allUserFiltersKeys.filter((el) => el !== 'units').reduce((a, key) => a || !!context[key], false);
    }, [context]);

    const showAdditionalFilters = useMemo(() => {
        return (
            oneContextFilterIsEnabled || (!context[WidgetsTypes['filters/socorgchartunits']] && !!context.units?.length)
        );
    }, [oneContextFilterIsEnabled, context]);

    return (
        <ItemsList<TItemType>
            id={props.id}
            injectionId={props.id}
            BodyComponent={ItemsListBody}
            listStateHook={({ defaultSelected }, middlewareParams) => {
                const { search, debouncedSearch, changeSearch, tabsValue, onChangeTab } = useDefaultStaticStates(
                    middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id,
                    defaultSearchParameter
                ); // состояние для поиска и табов

                useDidUpdateEffect(() => {
                    defaultSearchParameter &&
                        updateSearchParams({
                            ...searchParamsRef.current,
                        });
                }, [defaultSearchParameter, debouncedSearch]);

                const [totalUserCount, setTotalUserCount] = useState(0);

                //какой то апрув пользователей, не знаю что значит
                const { result: requestActionRezult, send: sendRequestAction } = useLazyQueryEx(
                    utils.API.administration.registrations.requestAction,
                    { allowUndefinedData: true }
                );
                const { result: requestMultipleActionRezult, send: sendRequestMultipleAction } = useLazyQueryEx(
                    utils.API.administration.registrations.requestMultipleAction,
                    { allowUndefinedData: true }
                );

                //действия над пользователем
                const { send: sendAction } = useLazyQueryEx(utils.API.users.usersAction, { allowUndefinedData: true });

                //преоброзование параметров для запроса
                const requestParams = useGetRequersParams(context, requestOptions);

                // получаем список пользователей и достаём запиханное туда колличество пользователей
                const state = useScrollListState(
                    async function (skipCount, count) {
                        if (requestParams.units?.length === 1) {
                            const [unitId] = requestParams.units;
                            const newUnits =
                                await SOCOrgchartUnitsTreeManager.inst.getTreeItemIdWithChildrenIdsByItemId(unitId);
                            requestParams.units = newUnits;
                        }
                        const response =
                            requestParams.catFilter === 'accessrequests'
                                ? await getList(skipCount, count, {
                                      search: debouncedSearch,
                                      gid: context.groupId,
                                      extended: true,
                                      ...requestParams,
                                  })
                                : await (
                                      await list({
                                          skipCount,
                                          count,
                                          search: debouncedSearch,
                                          gid: context.groupId,
                                          extended: true,
                                          ...requestParams,
                                      })
                                  ).r;
                        const result = (await response) as any as {
                            data: (IBaseUser & { fields?: any; actions: string[] | null })[];
                            totalUserCount: number;
                            companyUsersCount: number;
                        } & IBasicResponse;

                        if (result.error_code !== 0) throw result;
                        setTotalUserCount(
                            result.totalUserCount === -1 ? result.companyUsersCount : result.totalUserCount
                        );
                        return result.data.map((item) => ({
                            ...item,
                            url: `/user/${item.id}`,
                            actions:
                                requestParams.catFilter === 'accessrequests'
                                    ? ([
                                          requestParams.filter !== 'Approved' && 'accessrequest.approve',
                                          requestParams.filter !== 'Canceled' && 'accessrequest.disapprove',
                                      ].filter(Boolean) as string[])
                                    : item.actions || [],
                            fields:
                                requestParams.catFilter === 'accessrequests'
                                    ? [addAccessRequestFields(item)]
                                    : mapFromUserList(item.fields || []),
                        })) as TItemType[];
                    },
                    [debouncedSearch, requestParams],
                    {
                        selectableSingle: middlewareParams.selectableSingle,
                    }
                );

                return {
                    middlewareParams: {
                        ...middlewareParams,
                        listHeaderProps: {
                            ...middlewareParams.listHeaderProps,
                            summaryText: totalUserCount ? 'Пользователей — ' + totalUserCount : undefined,
                            options: [
                                {
                                    content: <Translate i18nKey={'pryaniky.list.users.actions.reinvite'} />,
                                    id: 'reinvite',
                                },
                                {
                                    content: (
                                        <Translate
                                            i18nKey={'pryaniky.list.users.actions.delete'}
                                            count={state.select.selected.length}
                                        />
                                    ),
                                    id: 'delete',
                                },
                                ...(isRegistrationsRequests
                                    ? ([
                                          requestParams.filter !== 'Approved' && {
                                              content: (
                                                  <Translate
                                                      i18nKey={'pryaniky.list.users.actions.accessrequest.approve'}
                                                  />
                                              ),
                                              id: 'accessrequest.approve',
                                          },
                                          requestParams.filter !== 'Canceled' && {
                                              content: (
                                                  <Translate
                                                      i18nKey={'pryaniky.list.users.actions.accessrequest.disapprove'}
                                                  />
                                              ),
                                              id: 'accessrequest.disapprove',
                                          },
                                      ].filter(Boolean) as ISplitButtonOption[])
                                    : []),
                            ],
                        },
                        itemClickAction: async function (option, item, actions) {
                            try {
                                const [actionID, actionState] = option.id.split('.');
                                switch (actionID) {
                                    case 'achievements': // переход к достижениям пользователя
                                        dispatch(push(`/user/${item.id}/currencyTimeline`));
                                        break;
                                    case 'edit': // перейти к редактированию пользователя
                                        // dispatch(push({ pathname: '/profileEdit/' + item.id }))
                                        dispatch(push({ pathname: '/user/' + item.id, search: '?profileEdit=true' }));
                                        break;
                                    case 'renameUser':
                                        {
                                            // смена емаила
                                            try {
                                                const result = await сhangeMail({
                                                    user: item,
                                                });
                                                actions.updateItem(item, result);
                                            } catch (error) {}
                                        }
                                        break;
                                    case 'accessrequest':
                                        {
                                            // какой то запрос доступа, не знаю что это значит, просто перенес
                                            const { comment } = await commentDialog({
                                                okBntText: (
                                                    <Translate
                                                        i18nKey={
                                                            'pryaniky.list.users.actions.accessrequest.disapproveRequest'
                                                        }
                                                    />
                                                ),
                                            });
                                            const approve = actionState === 'approve';
                                            await sendRequestAction(approve, item.id, comment);
                                            console.log('requestActionRezult', requestActionRezult);
                                            if (
                                                (context.arfilter === 'Canceled' && approve) ||
                                                (context.arfilter === 'Approved' && !approve) ||
                                                context.arfilter !== 'All'
                                            ) {
                                                actions.removeItems([item]);
                                            } else {
                                                toast.success(
                                                    i18n.t('pryaniky.list.users.actions.accessrequest.approved')
                                                );
                                                actions.updateItem(item, {
                                                    ...item,
                                                    actions: item.actions.map((act) =>
                                                        act === option.id
                                                            ? option.id === 'accessrequest.disapprove'
                                                                ? 'accessrequest.approve'
                                                                : 'accessrequest.disapprove'
                                                            : act
                                                    ),
                                                });
                                            }
                                        }
                                        break;
                                    case 'managenetworks': // какое то управление подсетями
                                        await ChangeUserNetwork({ id: item.id });
                                        break;
                                    case 'changePassword': // смена пароля пользователю
                                        await changePasswordDialog({
                                            userId: item.id,
                                            currentUser: authUser,
                                        });
                                        break;
                                    case 'shopAccessibility':
                                        {
                                            // доступ к покупкам
                                            const { comment, sendNotify } = await commentDialog({
                                                action: option.id,
                                                okBntText: (
                                                    <Translate
                                                        i18nKey={
                                                            'pryaniky.list.users.actions.accessrequest.disapproveRequest'
                                                        }
                                                    />
                                                ),
                                            });
                                            await sendAction(option.id, {
                                                users: [item.id],
                                                notifyComment: comment,
                                                sendNotify,
                                            });
                                            actions.updateItem(item, {
                                                ...item,
                                                actions: item.actions.map((act) =>
                                                    act === option.id
                                                        ? option.id === 'shopAccessibility.off'
                                                            ? 'shopAccessibility.on'
                                                            : 'shopAccessibility.off'
                                                        : act
                                                ),
                                            });
                                        }
                                        break;

                                    default:
                                        {
                                            // в дефолтной секции все пойдут через диалог с подтверждением и комментарием
                                            const { comment } = await commentDialog({
                                                title: i18n.t(`pryaniky.list.users.confirm.${option.id}`, { count: 1 }),
                                            });
                                            await sendAction(option.id, {
                                                users: [item.id],
                                                comment,
                                            });
                                            console.log(actionID);
                                            switch (actionID) {
                                                case 'delete': // удаление пользователя
                                                    actions.removeItems([item]);
                                                    break;
                                                case 'setadmin':
                                                    {
                                                        // назначение админом портала
                                                        actions.updateItem(item, {
                                                            ...item,
                                                            actions: item.actions.map((act) =>
                                                                act === option.id
                                                                    ? option.id === 'setadmin.off'
                                                                        ? 'setadmin.on'
                                                                        : 'setadmin.off'
                                                                    : act
                                                            ),
                                                        });
                                                    }
                                                    break;
                                                case 'enableModerationForAllPublications':
                                                    {
                                                        // разрешить модерацию публикаций портала
                                                        actions.updateItem(item, {
                                                            ...item,
                                                            actions: item.actions.map((act) =>
                                                                act === option.id
                                                                    ? option.id ===
                                                                      'enableModerationForAllPublications.off'
                                                                        ? 'enableModerationForAllPublications.on'
                                                                        : 'enableModerationForAllPublications.off'
                                                                    : act
                                                            ),
                                                        });
                                                    }
                                                    break;
                                                case 'BlockUser':
                                                    {
                                                        // блокировка юзера
                                                        actions.updateItem(item, {
                                                            ...item,
                                                            actions: item.actions.map((act) =>
                                                                act === option.id
                                                                    ? option.id === 'BlockUser.off'
                                                                        ? 'BlockUser.on'
                                                                        : 'BlockUser.off'
                                                                    : act
                                                            ),
                                                        });
                                                    }
                                                    break;

                                                default:
                                                    // всё остальное без какой либо обработки данных в состояние
                                                    break;
                                            }
                                        }
                                        break;
                                }
                            } catch (error: any) {
                                // т.е. если это не отклонение диалога
                                const [actionID, actionState] = option.id.split('.');
                                switch (actionID) {
                                    case 'accessrequest':
                                        if (error.error_code === 2002) {
                                            toast.error(error.error_text);
                                        }
                                        break;
                                }
                                if (error?.action !== 1) {
                                    console.error('ERROR: ', error);
                                }
                            }
                        },
                        itemMiddleware: (props) => {
                            return {
                                ...props,
                                bodyElemClick: (event, item, action) => {
                                    switch (action.type) {
                                        case ListItemBodyElementClickActionType.userOrgChart:
                                            event.preventDefault();
                                            contextUpdate({
                                                units: [action.data.id],
                                            });
                                            updateSearchParams({
                                                ...searchParamsRef.current,
                                                units: [action.data.id],
                                            });
                                            break;
                                        case ListItemBodyElementClickActionType.userFieldClick:
                                            switch (action.data.field.type) {
                                                case UserAdditionalFieldType.tags:
                                                    if (!action.data.fieldDataItem) return;
                                                    event.preventDefault();
                                                    contextUpdate({
                                                        tId: action.data.fieldDataItem.id,
                                                    });
                                                    updateSearchParams({
                                                        ...searchParamsRef.current,
                                                        tId: action.data.fieldDataItem.id,
                                                    });
                                                    break;
                                            }
                                            break;
                                    }
                                },
                                additionalContent: <UserAddittionalContent {...props} />,
                                options: props.item.actions.map((action) => ({
                                    content: i18n.t('pryaniky.list.users.actions.' + action, { count: 1 }),
                                    id: action,
                                })),
                            };
                        },
                        selectedActionClick: async function (option, select, actions) {
                            const [actionID, actionState] = option.id.split('.');
                            try {
                                switch (actionID) {
                                    case 'delete':
                                        {
                                            // удаление пользователей
                                            const { comment } = await commentDialog({
                                                title: i18n.t(`pryaniky.list.users.confirm.${option.id}`, {
                                                    count: select.selected.length,
                                                }),
                                            });
                                            await sendAction(option.id, {
                                                users: select.selected.map((v) => v.id),
                                                comment,
                                            });
                                            actions.removeItems(select.selected);
                                        }
                                        break;

                                    case 'accessrequest':
                                        {
                                            // какой то запрос доступа, не знаю что это значит, просто перенес
                                            const approve = actionState === 'approve';
                                            const response = await confirm({
                                                text: i18n.t(
                                                    `pryaniky.toast.success.accessrequest.info.${
                                                        approve ? 'approve' : 'disapprove'
                                                    }.confirm`
                                                ),
                                            });
                                            if ('confirm' === response) {
                                                await sendRequestMultipleAction(
                                                    approve,
                                                    select.selected.map((v) => v.id)
                                                );
                                                console.log('requestMultipleActionRezult', requestMultipleActionRezult);
                                                actions.removeItems(select.selected);

                                                toast.info(
                                                    Translate.t({
                                                        i18nKey: `pryaniky.toast.success.accessrequest.info.${
                                                            approve ? 'approve' : 'disapprove'
                                                        }`,
                                                        values: {
                                                            count: select.selected.length,
                                                            maxCount: select.selected.length,
                                                        },
                                                    })
                                                );
                                            }
                                        }
                                        break;

                                    default:
                                        {
                                            // прочие действия, все с конфирмом
                                            const response = await confirm({
                                                text: i18n.t('pryaniky.list.users.confirm.' + option.id, {
                                                    count: select.selected.length,
                                                }),
                                            });
                                            if ('confirm' === response) {
                                                await sendAction(option.id, {
                                                    users: select.selected.map((v) => v.id),
                                                });
                                            }
                                        }
                                        break;
                                }
                                toast.success(
                                    i18n.t(`pryaniky.list.users.actions.${option.id}.success`, {
                                        count: select.selected.length,
                                    })
                                );
                            } catch (error: any) {
                                if (error?.action !== 1) {
                                    // т.е. если это не отклонение диалога
                                    toast.error(i18n.t('pryaniky.toast.error.server'));
                                    console.error('ERROR: ', error);
                                }
                            }
                        },
                    },
                    state,
                    defaultSelected,
                    search,
                    tabsValue,
                    changeSearch,
                    onChangeTab,
                    middleware: defaultMiddleware,
                };
            }}
            selectable
            ItemComponent={UsersListItem}
            listHeaderProps={{
                SearchActions: !context.tId && <InvaiteButton context={context} wcontext={wcontext} />,
                topContent: showAdditionalFilters && (
                    <UserSettedAdditionalFilters
                        units={!context[WidgetsTypes['filters/socorgchartunits']] ? context.units : []}
                        changeUnits={(units) => {
                            contextUpdate({
                                units,
                            });
                            updateSearchParams({
                                ...searchParamsRef.current,
                                units,
                            });
                        }}
                        dropAllFilters={
                            oneContextFilterIsEnabled || !context[WidgetsTypes['filters/socorgchartunits']]
                                ? () => {
                                      contextUpdate(
                                          allUserFiltersKeys.reduce((a, key) => ({ ...a, [key]: undefined }), {})
                                      );
                                      updateSearchParams({});
                                  }
                                : undefined
                        }
                    />
                ),
            }}
        />
    );
};

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
    mapStateToProps,
    mapDispatchToProps(mapActionsToProps)
)(N_ListGroupUsersSelectorPresenter);
