import React, { FC, useEffect } from 'react'
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { GroupListItem } from 'muicomponents/src/ItemsListDialog/GroupListItem/GroupListItem';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { connect, useSelector } from 'react-redux';
import { mapStateToProps, mapDispatchToProps, TListStateProps, TMapDispatchProps, IListProps, getQuerySearchSelector } from '../../index';
import { IStateType as IState } from 'redux/store';
import { useDidUpdateEffect, useScrollListState, useSearchParams } from 'utils/src/hooks';
import { search as list } from 'utils/src/requests/requests.suggesters';
import { TGroupsListResponse } from './List_groups.index';
import { i18n, Translate } from 'localization';
import { NothingFoundText } from '../NotingFoundText'


let groupsSearchRequest: ReturnType<typeof list>;

/**
 * список работает тольок на странице поиска
 * обратите внимание на использование useSearchParams
 */
export const ListGroupsPresenter: FC<IListProps> = ({ context, afterStateChangedCallback, afterGetDataCallback }) => {
  return (
    <ItemsList
      BodyComponent={ItemsListBody}
      ItemComponent={GroupListItem}
      listHeaderProps={{ disableSearch: true, disableActionsButton: true }}
      listBodyProps={{ joinBody: true, disableBorder: true, disablePadding: true }}
      
      listComponentProps={{
        textEmpty: !context.search ? <Translate i18nKey='pryaniky.timeline.notFound.no.searchQuery.text' /> : <NothingFoundText />,
      }}
      listStateHook={({ defaultSelected }, middlewareParams) => {
        const {
          changeSearch,
          onChangeTab
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        // данный параметр изменяется только на странице поиска
        const [{ search: querySearch = '' }] = useSearchParams() as any as [{ search: string | undefined }];
        
        // получаем список групп
        const state = useScrollListState(async function (skipCount, count) {

          if (!querySearch) return [];

          if(groupsSearchRequest) groupsSearchRequest.ac.abort();

          groupsSearchRequest = list('groups', querySearch, context.groupId, undefined, {
            skipCount,
            count: context.count || count,
            isSearchPageQuery:true
            // isQuickSearch: true 
          })

          const result = await groupsSearchRequest.r as any as TGroupsListResponse;

          if (result.error_code !== 0) throw result;

          afterGetDataCallback?.(result)

          return result.data;
        }, [querySearch], {
          selectableSingle: middlewareParams.selectableSingle
        }, true)

        useDidUpdateEffect(() => {
          afterStateChangedCallback?.(state);
        }, [state]);

        return {
          middlewareParams: {
            ...middlewareParams,
            itemMiddleware(props) {
              return {
                ...props,
                // contentCenter: true,
                // avatarWidth: 64
              }
            }
          },
          state,
          defaultSelected,
          middleware: defaultMiddleware,
          search: querySearch,
          onChangeTab,
          changeSearch
        }
      }}
    />
  )
}

export const List = connect<TListStateProps, TMapDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(ListGroupsPresenter);