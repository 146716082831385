import React, { FC, useEffect } from "react";
import { i18n, Translate } from 'localization';
import { Box } from 'muicomponents/src'

export const NothingFoundText = () => {
    return (<>
        <Box><Translate i18nKey={'pryaniky.default.list.nothingFound.title'} /></Box>
        <Box><Translate i18nKey={'pryaniky.default.list.nothingFound.text'} /></Box>
    </>
    )
}