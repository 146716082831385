/*
















НЕ ИСПОЛЬЗУЕТСЯ!






























*/





























import React, { FC, useEffect, useMemo, useState } from 'react';
import * as utils from "utils/src/utils";
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import {
  Textarea,
  DialogText,
  Button,
  cnDialog,
  Loading,
} from 'uielements/src';
import { InputMention } from 'blocks/InputMention/InputMention';
import { toast } from 'react-toastify';
import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import { IMakeOrderDialogStateProps, IMakeOrderDialogProps, IMakeOrderDialogOwnProps, mapStateToProps } from './MakeOrder.index';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { checkResponseStatus } from 'utils/src';
import { FieldsEditor, isEmptyMandatory } from 'muicomponents/src/FieldRender';
import { styled } from 'muicomponents/src/mui/system';
import { getProductRequest } from 'utils/src/requests/requests.shop';

const StyledFieldsEditor = styled(FieldsEditor)({
  padding: 16
});

export const MakeOrderDialogPresenter: FC<IMakeOrderDialogProps> = ({
  data,
  additionalInformationBeforeOrder,
  handleAccept,
  handleClose
}) => {
  const [state, setState] = useState({ ...data });
  const [errors, setErrors] = useState<any>();

  const [isLoadingAdditionalFields, setIsLoadingAdditionalFields] = useState(false);

  useEffect(() => {
    async function get() {
      setIsLoadingAdditionalFields(true);
      const response = await getProductRequest(state.id)
      if (checkResponseStatus(response)) {
        setState({
          ...state,
          additionalFields: response.data.additionalFields
        })
      } else {
        toast.error(`Error to get order form for shop item ${state.id}`);
      }
      setIsLoadingAdditionalFields(false);
    };
    if (state.additionalFields && !state.additionalFields.additionalFields) {
      get();
    }
    // return data.additionalFields?.additionalFields;
  }, [state]);

  const showAdditionalInformation = !data.doNotShowAdditionalInformation && (additionalInformationBeforeOrder&& additionalInformationBeforeOrder.length > 0) 

  return (
    <>
      <DialogTitle onClose={handleClose}>{i18n.t('pryaniky.shop.dialogTitle')}{showAdditionalInformation && ' *'}</DialogTitle>
      {
        isLoadingAdditionalFields && <Loading />
      }
      {
        state.additionalFields?.additionalFields
          ? <StyledFieldsEditor
            fields={(state.additionalFields?.additionalFields || []).map((category: any, idx: number) => {
              return {
                ...category,
                fields: category.fields.filter((el: any) => !el.defaultFieldName)
              }
            })}
            onError={(errors) => {
              setErrors(errors)
            }}
            errors={errors}
            onChange={(fields, newErrors) => {
              setState({
                ...state,
                additionalFields: {
                  ...state.additionalFields,
                  additionalFields: fields
                }
              });
            }}
          />
          : <Textarea
            placeholder={i18n.t('pryaniky.shop.comment')}
            icon="edit"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setState({ ...state, comment: e.target.value })}
            className={cnDialog('Textarea')}
          />
      }
      {!isLoadingAdditionalFields && data.isGift && (
        <div>
          <DialogText>{i18n.t('pryaniky.shop.for', { text: data.name })}</DialogText>
          <InputMention
            onChange={(presentFor) => setState({ ...state, presentFor })}
            value={state?.presentFor}
            type="users"
            icon="user-add"
            noMulti
            placeholder={i18n.t('pryaniky.shop.selectFor')}
          />
        </div>
      )}
      {/* {!data.doNotShowAdditionalInformation && <div className={cnDialog('AdditionalInformation')}> С суммы подарков, превышающей 4000 руб. в год, удерживается НДФЛ. Оформляя заказ, Вы соглашаетесь с условиями удержания </div>} */}
      {showAdditionalInformation && <div className={cnDialog('AdditionalInformation')}> {additionalInformationBeforeOrder}</div>}

      <DialogActions
        disableAccept={isEmptyMandatory(state.additionalFields?.additionalFields || [])}
        acceptText={i18n.t('pryaniky.shop.order')}
        closeText={i18n.t('pryaniky.shop.cancel')}
        onAccept={() => handleAccept(state)}
        onClose={handleClose} />
      {/* <Button main onClick={() => onConfirm && onConfirm(data)} padding="lg">
        {i18n.t('pryaniky.shop.order')}
      </Button>
      <Button onClick={onClose} padding="lg">
        {i18n.t('pryaniky.shop.cancel')}
      </Button>
    </DialogActions> */}
    </>
  )
}



export const MakeOrderDialog = connect<IMakeOrderDialogStateProps, IDispatchProps, IMakeOrderDialogOwnProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(MakeOrderDialogPresenter)


export const _DialogMakeOrder = createModal(MakeOrderDialog, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});
// export class MakeOrderDialogPresenter extends React.Component<IMakeOrderDialogProps, IMakeOrderDialogState> {

//   constructor(props: IMakeOrderDialogProps) {
//     super(props);
//     this.state = {
//       comment: '',
//     };
//   }

//   public render() {
//     // сonst { props, onConfirm } = this;
//     const { props, state, onCommentChange, onForChange } = this;
//     const { , data, onConfirm } = props;
//     return (
//       <>
//         <DialogTitle onClose={onClose}>{i18n.t('pryaniky.shop.dialogTitle')}</DialogTitle>
//         <Textarea
//           placeholder={i18n.t('pryaniky.shop.comment')}
//           icon="edit"
//           onChange={onCommentChange}
//           className={cnDialog('Textarea')}
//         />
//         {data.isGift && (
//           <>
//             <DialogText>{i18n.t('pryaniky.shop.for', { text: data.name })}</DialogText>
//             <InputMention
//               onChange={onForChange}
//               value={data?.presentFor}
//               type="users"
//               icon="user-add"
//               noMulti
//               placeholder={i18n.t('pryaniky.shop.selectFor')}
//             />
//           </>
//         )}
//         <DialogActions
//         acceptText= {i18n.t('pryaniky.shop.order')}
//         closeText={i18n.t('pryaniky.shop.cancel')}
//         onAccept={() => onConfirm && onConfirm(data)}
//         onClose={onClose}/>
//           {/* <Button main onClick={() => onConfirm && onConfirm(data)} padding="lg">
//             {i18n.t('pryaniky.shop.order')}
//           </Button>
//           <Button onClick={onClose} padding="lg">
//             {i18n.t('pryaniky.shop.cancel')}
//           </Button>
//         </DialogActions> */}
//       </>
//     )
//   }

//   private onCommentChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => store.dispatch(modalChangeDate({ id: this.props.id || '', data: { comment: ev.target.value } }))
//   // this.setState({comment: ev.target.value});

//   private onForChange = (presentFor: any) => store.dispatch(modalChangeDate({id: this.props.id || '', data: { presentFor }}))
//   // this.setState({ presentFor });
// }