import React, { FC, useState, useEffect } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { WidgetDialogBox } from './WidgetSettingsDialog.styled'
import { IWidgetSettingsProps, ISettingsItem } from './WidgetSettingsDialog.index'
import { TextField } from 'muicomponents/src/TextField/TextField'
import { UploadButton } from 'muicomponents/src/UploadButton/UploadButton'
import { useFileUploader } from 'utils/src/hooks'
import { Button } from 'muicomponents/src/Button/Button'
import Switch from '@mui/material/Switch';
import uuid from 'uuid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isObject } from 'lodash';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import Delete from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Tooltip, { } from '@mui/material/Tooltip';
import { i18n, Translate } from 'localization';

const StyledImg = styled('img')({
    width: '36.5px',
    height: '36.5px',
})

const Item = ({
    settings,
    onChange,
    onEnebled,
    onDelete
}: {
    settings: ISettingsItem
    onChange: (settnigs: ISettingsItem) => void
    onEnebled: (enabled: boolean) => void
    onDelete: () => void,
}) => {
    const [state, setState] = useState<typeof settings>(settings || {});

    const {
        isLoading,
        onFileChange,
    } = useFileUploader({
        allowTypes: ['image/jpeg', 'image/png', 'image/gif'],
        uploadOnAdd: true,
        maxFilesCount: 1,
        callbacks: {
            loadFinish(item, response) {
                if (response?.error_code === 0) {
                    setState((s) => ({ ...s, file: response.data[0] }))
                }
            },
        }
    })

    useEffect(() => {
        onChange(state)
    }, [state]);

    return <Stack direction={'row'} gap={2} alignItems='flex-start'>
        <Stack gap={2} flex='1'>
            <TextField
                required
                label={i18n.t('pryaniky.FloatMessageWidget.settings.message')}
                value={state?.text}
                onChange={(e) => setState((s) => ({ ...s, text: e.target.value }))}
            />
            <TextField
                label={i18n.t('pryaniky.FloatMessageWidget.settings.link')}
                value={state?.url}
                onChange={(e) => setState((s) => ({ ...s, url: e.target.value }))}
            />

            <Stack gap={2} direction='row'>
                <StyledImg src={state?.file?.previewUrl} />

                <UploadButton inputId={`file-${state.id}`} loading={isLoading} onChangeInput={onFileChange}>{i18n.t('pryaniky.FloatMessageWidget.settings.upload_image')}</UploadButton>
            </Stack>

            <FormControlLabel control={<Switch
                checked={settings.enabled}
                onChange={(e) => onEnebled(e.target.checked)}
            />} label={i18n.t('pryaniky.FloatMessageWidget.settings.enabled')} />
        </Stack>
        <Tooltip title={i18n.t('pryaniky.FloatMessageWidget.settings.delete')}>
            <IconButton onClick={onDelete}><Delete color='error' /></IconButton>
        </Tooltip>
    </Stack>
}

export const WidgetSettingsDialog: FC<IWidgetSettingsProps> = ({
    onChange,
    data,
    settings,
    setValid
}) => {

    const [state, setState] = useState<ISettingsItem[]>(() => {
        let items = settings?.items || []
        return items
    });

    useEffect(() => {
        onChange({
            settings: { items: state }
        })
        const allTexts = state.every(item => !!item.text)
        const allFiles = state.every(item => !!item.file)
        setValid(allTexts && allFiles)
    }, [state]);
    // console.log('WidgetSettingsDialog, state', state, settings)
    return (<WidgetDialogBox>
        <Stack divider={<Divider />} gap={2}>
            {state.map(item => <Item
                key={item.id}
                onDelete={() => setState((p) => p.filter(v => v.id !== item.id))}
                onEnebled={(enabled) => setState((p) => p.map(v => v.id === item.id ? { ...v, enabled } : { ...v, enabled: false }))}
                onChange={(s) => setState((p) => p.map(v => v.id === item.id ? s : v))}
                settings={item} />)}

            <Button onClick={() => setState((s) => [...s, { id: uuid(), enabled: false }])}>{i18n.t('pryaniky.FloatMessageWidget.settings.add_item')}</Button>
        </Stack>
    </WidgetDialogBox >)
}



export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', WidgetSettingsDialog, 'pryaniky/FloatMessageWidget')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', WidgetSettingsDialog, 'pryaniky/FloatMessageWidget')