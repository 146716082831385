import { IBodyProps } from 'utils/src/DialogCreator';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { i18n, Translate } from 'localization';
import { cn } from '@bem-react/classname';
import { useWallet } from '../Wallets.hooks';
import moment, { Moment } from 'moment';
import { Button } from 'muicomponents/src/Button/Button';
import { ButtonGroup } from 'muicomponents/src/ButtonGroup';
import { useDepositSettings } from './AddCurrency.hooks';
import { TextField } from 'muicomponents/src/TextField';
import { Slider } from 'muicomponents/src/Slider';
import { FormControl } from 'muicomponents/src/FormControl';
import { styled } from 'muicomponents/src/mui/system';
import { validate_v2, ValidateRulesObject, NValidate } from 'utils/src/validate_v2';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';
import { Loading } from 'uielements';
import { useSelector } from 'react-redux';
import { getCurrencyNameByCount } from 'utils/src/CommonRedux/base/selectors';
import { closeDeposit, openDeposit } from 'utils/src/requests/admin.wallets';
import { checkResponseStatus } from 'utils/src';
import { toast } from 'react-toastify';
import { pick } from 'lodash';

export const cnAddCurrencyDialog = cn('AddCurrencyDialog');

// 1. Валюты в кошельке недостаточно изначально:
// Всегда, когда у пользователя меньше валюты чем мин порог в кошельке "могу потратить", нужно писать "у вас недостаточно средств"
// 2. Валюты в кошельке достаточно 
// при введенном маленьком количестве(меньше минимума) писать : " Введенная сумма меньше минимальной суммы вклада"
// при введенном больше чем максимум "Максимальная сумма для вклада ХХ"

const validateRules: ValidateRulesObject<NAddCurrency.Value> = {
    count: {
        min: {
            errorMessageKey: 'pryaniky.deposit.error.minValue'
        },
        max: {
            errorMessageKey: 'pryaniky.deposit.error.maxValue'
        }
    }
};

const StyledDialogContent = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    gap: 16
});

export namespace NAddCurrency {

    export type Value = {
        /**
         * currency count value
         */
        count: number;

        /**
         * period currency at days
         */
        period: number;

        /**
         * period currency at days
         */
        percentage?: number;
    }

    export interface Props extends IBodyProps<Value> {
        uid: string;
        walletId: NonNullable<ReturnType<typeof useWallet>['wallet']>['id'];
    };
};

export const AddCurrencyPr: FC<NAddCurrency.Props> = ({
    uid,
    walletId,
    handleAccept,
    handleClose
}) => {

    const currentDate = useMemo(() => moment(), []);

    const [ actionAdd, setActionAdd ] = useState(true);

    const {
        wallet,
        mythanksCount,
        changeCurrencyAtWallet
    } = useWallet(uid, walletId);

    const {
        loading,
        settings,
        currencyNameMinValue
    } = useDepositSettings();

    useEffect(() => {
        validateRules.count!.min!.value = settings?.minValue || 0;
        const maxValueSettings = settings?.maxValue || 0;
        let maxValueUser = mythanksCount;
        if(!actionAdd) maxValueUser = wallet?.value || 0;
        if(maxValueUser < (validateRules.count?.min?.value || 0)) {
            validateRules.count!.min!.errorMessageKey = 'pryaniky.deposit.error.insufficientMoney';
            validateRules.count!.max!.errorMessageKey = 'pryaniky.deposit.error.insufficientMoney';
        } else {
            validateRules.count!.min!.errorMessageKey = 'pryaniky.deposit.error.minValue';
            validateRules.count!.max!.errorMessageKey = 'pryaniky.deposit.error.maxValue';
        }
        validateRules.count!.max!.value = maxValueSettings > maxValueUser ? maxValueUser : maxValueSettings || maxValueUser || 0;
        // validateRules.count!.max!.value = settings?.maxValue || actionAdd ? mythanksCount : wallet?.value || 0;
    }, [settings?.minValue, settings?.maxValue, mythanksCount, actionAdd]);

    const titleI18nKey = useMemo(() => {
        switch (wallet?.type) {
            case 'deposit':
                return 'pryaniky.wallets.deposit.open';
            default:
                return 'pryaniky.wallets.currency.change';
        }
    }, [wallet?.type]);

    const [ monthsCount, setMonthsCount ] = useState((settings?.conditions || []).map(el => el.perionInMonths)[0]);

    useDidUpdateEffect(() => {
        setMonthsCount((settings?.conditions || []).map(el => el.perionInMonths)[0]);
    }, [settings?.conditions]);

    const [ endDate, setEndDate ] = useState<Moment | null>(null);

    const [ state, setState ] = useState<NAddCurrency.Value>({
        count: 0,
        period: 0
    });

    useEffect(() => {
        const endDate = currentDate.clone();
        endDate.add(monthsCount, 'M');
        setEndDate(endDate);
        setState({
            ...state,
            period: monthsCount
        });
    }, [monthsCount]);

    const ref = useRef(false);

    useDidUpdateEffect(() => {
        if(!ref.current) {
            ref.current = true;
            return;
        }
        let maxValueUser = mythanksCount;
        if(maxValueUser < (settings?.minValue || 0) || maxValueUser < state.count) {
            validateRules.count!.min!.errorMessageKey = 'pryaniky.deposit.error.insufficientMoney';
        }
        else {
            validateRules.count!.min!.errorMessageKey = 'pryaniky.deposit.error.minValue';
        }
        setErrors(validate_v2(state, validateRules));
    }, [state.count, settings?.minValue, mythanksCount]);
    
    const [ errors, setErrors ] = useState<NValidate.ValidateResponse<NAddCurrency.Value>>({
        count: undefined,
        period: undefined
    });

    const helperText = useMemo(() => {
        if(wallet?.type === 'deposit') {
            return <><Translate i18nKey={'pryaniky.wallets.currency.helperText.deposit.minValue'} count={settings?.minValue} values={{ variable: currencyNameMinValue }} /></>
        }
        return null;
    }, [settings?.minValue, wallet?.type]);

    const currencyNameCount = useSelector(getCurrencyNameByCount(state.count));

    const precents = useMemo(() => {
        return settings?.conditions.find(el => el.perionInMonths === monthsCount)?.percentage || 0;
    }, [monthsCount, settings?.conditions]);

    const rezultCurrency = useMemo(() => {
        // Х+(Х*(кол-во.мес/12)*ставка:100)
        // return Math.round(state.count + ((state.count * (monthsCount / 12) * precent) / 100));
        return Math.round(state.count + ((state.count * precents) / 100));
    }, [state.count, precents]);

    const rezultCurrencyNameCount = useSelector(getCurrencyNameByCount(rezultCurrency));

    const [acceptLoading, setAcceptLoading] = useState(false);

    const disableAcceptButton = !state.count || !state.period;

    if(loading || !settings) {
        return (
            <div className={cnAddCurrencyDialog()}>
                <DialogTitle
                    onClose={handleClose}
                >
                    <Translate i18nKey={titleI18nKey} />
                </DialogTitle>
                <StyledDialogContent className={cnAddCurrencyDialog('Content')}>
                    <Loading text={<Translate i18nKey={'pryaniky.wallets.settings.loading'} />} />
                </StyledDialogContent>
                <DialogActions
                    className={cnAddCurrencyDialog('Actions')}
                    onClose={handleClose}
                    closeText={<Translate i18nKey={`cancel`} />}
                >
                </DialogActions>
            </div>
        );
    };

    return (
        <div className={cnAddCurrencyDialog()}>
            <DialogTitle
                onClose={handleClose}
            >
                <Translate i18nKey={titleI18nKey} />
            </DialogTitle>
            <StyledDialogContent className={cnAddCurrencyDialog('Content')}>
                {
                    wallet?.type !== 'deposit' &&
                    <ButtonGroup>
                        <Button variant={actionAdd ? 'contained' : undefined} onClick={() => setActionAdd(true)}> 
                            <Translate i18nKey={'pryaniky.wallets.currency.add'} />
                        </Button>
                        <Button variant={!actionAdd ? 'contained' : undefined} onClick={() => setActionAdd(false)}>
                            <Translate i18nKey={'pryaniky.wallets.currency.remove'} />
                        </Button>
                    </ButtonGroup>
                }
                <FormControl
                    fullWidth
                >
                    <TextField
                        error={Boolean(errors.count)}
                        label={<Translate i18nKey={`pryaniky.wallets.currency.${actionAdd ? 'add' : 'remove'}.count`} />}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            setState({
                                ...state,
                                count: Number(value)
                            });
                        }}                    
                        value={state.count || ''}
                        helperText={
                            <>
                                {
                                    Boolean(helperText) &&
                                    <div>{helperText}</div>
                                }
                                {Boolean(errors.count) && PrepareErrorText(errors.count)}
                            </>
                        }
                    />
                </FormControl>
                {
                    wallet?.type === 'deposit' &&
                    <>
                        <ButtonGroup>
                            {
                                (settings?.conditions || []).map(condition => {
                                    const numberMonth = Number(condition.perionInMonths);
                                    if(isNaN(numberMonth)) return null;
                                    const month = condition.perionInMonths;
                                    return (
                                        <Button variant={monthsCount === month ? 'contained' : undefined} onClick={() => setMonthsCount(month)}>
                                            <Translate i18nKey={`pryaniky.wallets.period`} count={numberMonth || 0} />
                                        </Button>
                                    )
                                })
                            }
                        </ButtonGroup>
                        {
                            !!state.period && endDate && 
                            <Translate
                                i18nKey={`pryaniky.wallets.currency.deposit.selectedtext`}
                                values={{
                                    date: endDate.format('L'),
                                    precents
                                }}
                            />
                        }
                        {
                            state.count > 0 && monthsCount &&
                            <div>
                                <Translate
                                    i18nKey={'pryaniky.wallets.currency.deposit.rezult'}
                                    count={state.count}
                                    values={{
                                        variable: currencyNameCount,
                                        rezult: rezultCurrency,
                                        rezultVariable: rezultCurrencyNameCount
                                    }}
                                />
                            </div>
                        }
                    </>
                }
            </StyledDialogContent>
            <DialogActions
                className={cnAddCurrencyDialog('Actions', [ wallet?.type === 'deposit' ? 'OpenDepositActions' : '' ] )}
                isLoading={acceptLoading}
                disableAccept={Boolean(Object.values(errors).filter(e => e).length) || disableAcceptButton}
                onAccept={() => {
                    if(wallet?.type === 'deposit') {
                        setAcceptLoading(true);
                        openDeposit(state.count, state.period)
                        .then((depositIsOpened) => {
                            if(!checkResponseStatus(depositIsOpened)) {
                                toast.error('open deposit error');
                                setAcceptLoading(false);
                                return;
                            };
                            setAcceptLoading(false);
                            changeCurrencyAtWallet(actionAdd ? state.count : -state.count, endDate?.toISOString(), precents);
                            handleAccept(state);
                        });
                    } else {
                        changeCurrencyAtWallet(actionAdd ? state.count : -state.count);
                        handleAccept(state);
                    }
                }}
                acceptText={<Translate i18nKey={wallet?.type === 'deposit' ? 'pryaniky.wallets.deposit.open' : `save`} />}
                onClose={handleClose}
                closeText={<Translate i18nKey={`cancel`} />}
            >
            </DialogActions>
        </div>
    );
};

const CloseDepositPr: FC<NAddCurrency.Props> = ({
    handleAccept,
    handleClose,
    uid,
    walletId
}) => {

    const {
        wallet,
        changeCurrencyAtWallet
    } = useWallet(uid, walletId);

    const [acceptLoading, setAcceptLoading] = useState(false);

    return (
        <div className={cnAddCurrencyDialog()}>
            <DialogTitle
                onClose={handleClose}
            >
                <Translate i18nKey={'pryaniky.wallets.currency.deposit.close.title'} />
            </DialogTitle>
            <StyledDialogContent className={cnAddCurrencyDialog('Content')}>
                <Translate i18nKey={'pryaniky.wallets.currency.deposit.close.info'} />
            </StyledDialogContent>
            <DialogActions
                isLoading={acceptLoading}
                className={cnAddCurrencyDialog('Actions', ['CloseDepositActions'])}
                onAccept={() => {
                    setAcceptLoading(true);
                    closeDeposit().then((depositIsClosed) => {
                        if(!checkResponseStatus(depositIsClosed)) {
                            toast.error('close deposit error');
                            setAcceptLoading(false);
                            return;
                        };
                        setAcceptLoading(false);
                        changeCurrencyAtWallet(-(wallet?.value || 0));
                        handleClose();
                    });
                }}
                acceptText={<Translate i18nKey={`pryaniky.wallets.deposit.close`} />}
                onClose={handleClose}
                closeText={<Translate i18nKey={`cancel`} />}
            >
            </DialogActions>
        </div>
    );
};

export const AddCurrency = createModal(AddCurrencyPr, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});

export const CloseDeposit = createModal(CloseDepositPr, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});