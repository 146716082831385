import { IActionButtonsProps } from './ActionButtonsNew.index'
import * as React from 'react'
import { Button } from 'uielements/src/Button/Button';
import { Button as MuiButton } from 'muicomponents/src/Button/Button';
import './ActionButtonsNew.scss';
import { i18n, Translate } from 'localization';

export const ActionButtonsNew: React.FC<IActionButtonsProps> = ({ saveProps = {}, cancelProps = {}, className, save, cancle, saveText, cancleText, children, saveDisabled = false }) => {
    return <div className={'ActionButtons ' + className}>
        {children}
        {cancle && <MuiButton variant='text'  className={'ActionButtons-CancelBtn'} noBackground={true} noBorder={true} onClick={cancle} {...cancelProps}>{cancleText || <Translate i18nKey={'cancel'} />}</MuiButton>}

        {save && <MuiButton variant='contained' disabled={saveDisabled} className={'ActionButtons-SaveBtn'} onClick={save} {...saveProps}>{saveText || <Translate i18nKey={'save'} />}</MuiButton>}
        {/* {cancle && <MuiButton variant='outlined'  className={'ActionButtonsNew-CancelBtn'} noBackground={true} noBorder={true} onClick={cancle} {...cancelProps}>{cancleText || <Translate i18nKey={'cancel'} />}</MuiButton>} */}
    </div>
}