import { IThanksPropsType, mapStateToProps, TStateProps } from './Thanks.index'
import React, { FC, memo } from 'react';
import { connect, useSelector } from 'react-redux';
import './Thanks.scss'
import { withNewsContext, useNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import ContentView from 'News/containers/ContentView'
import {
    getNewsParamsById
} from 'News/redux/saga/selectors'
import { getFemin } from '../NewsUtils'
import { i18n, Translate } from 'localization';
import { capitalize } from 'utils/src/utils'
export const ThanksPresenter: FC<IThanksPropsType & TStateProps> = ({ }) => {
    return <NewsView className={'type-4'}>
        <ContentLayout>
            <TextRender />
        </ContentLayout>
    </NewsView>
}


export const Thanks = withNewsContext<IThanksPropsType>(connect(
    mapStateToProps
)(memo(ThanksPresenter)), []);

export const SliderThank: FC<{}> = ({ }) => {
    const { newsId } = useNewsContext()
    const { header, user } = useSelector(getNewsParamsById(newsId, 'header', 'user'))
    const headerMdText = capitalize(`${i18n.t(`thanked${getFemin(user.sex)}`)} ${header}`)
    return <ContentView headerMdText={headerMdText} />
}

export const initThanks = () => {
    ComponentInjector.getInstance().addNode('news_types', <Thanks />, 'thanks')
    ComponentInjector.getInstance().addNode('slider_news_types', <SliderThank />, 'thanks')
}