import React, { useState, useEffect, useMemo } from 'react';
import { i18n, Translate } from 'localization';
import { toast } from 'react-toastify';
import "./EditWiki.scss";
import { connect } from "react-redux";
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import {
    IWikiEditOwnProps,
    IWikiEditProps,
    cnWikiEdit,
    mapWikiEditDispatchToProps,
    mapWikiEditStateToProps,
    IWikiEditDispatchProps,
    IWikiEditStateProps,
} from './EditWiki.index'
import { IStateType as IState } from 'redux/store';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import { TextError } from 'uielements/src/TextError/TextError';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { useFileUploader } from 'utils/src/hooks'
import { styled } from 'muicomponents/src/mui/system';
import { UsersSuggester, TagsSuggester, PageCollectionSuggester } from 'muicomponents/src/Suggester';
import { TextField } from 'muicomponents/src/TextField'
import { Select } from 'muicomponents/src/Select/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from 'muicomponents/src/FormControl/FormControl'
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    FilesUploader,
} from 'muicomponents/src';
import { GridView, HelpIcon, ViewList } from 'muicomponents/src/Icons'
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'utils/src/hooks';
import { LinearProgress } from 'muicomponents/src/LinearProgress/LinearProgress'
import { PreviewSelector } from 'muicomponents/src/PreviewSelector/PreviewSelector'
import { ImageCropperDialog } from 'blocks/ImageLoaderWithMui/ImageCropper/ImageCropper'
import { getPageBaseType, getWikiUrl } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { PageTypes } from 'utils/src';
import { useWikiItemViewersTitle } from 'blocks/Dialogs/WikiCreateNew/WikiDialogs.utils';
import { ButtonGroup } from 'muicomponents/src/ButtonGroup';
import { NumberTextField } from 'muicomponents/src/TextField/TextField';

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'

});

const statusOption = [
    { value: 'published', title: Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status.published' }), },
    { value: 'isDraft', title: Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status.isDraft' }) },
    { value: 'archived', title: i18n.t('pryaniky.wikilist.item.param.status.isArchived'), },
];

export const WikiEditPresenter: React.FC<IWikiEditProps> = ({
    data: pdata,
    context,
    closeWikiEdit,
    isEdit,
    id,
    isWkilistModerator,
    ...props
}) => {

    const [loading, setLoading] = useState(false);
    const [actualData1, setActualData1] = useState<typeof pdata | null>(null);
    const [error, setError] = useState(false);
    const [imgChanged, setImgChanged] = React.useState(false);
    const [urlBeforChange, setUrlBeforChange] = React.useState<string>("");
    const [moderators, setModerators] = React.useState<any[]>([]);
    const [viewers, setViewers] = React.useState<any[]>([]);

    // const typePage = actualData1 && actualData1.type === "page"
    // const typeWikilist = actualData1 && actualData1.type === "wikilist";
    // const typeUrl = actualData1 && actualData1.type === "url";
    // const typeFile = actualData1 && actualData1.type === "file";

    const pageType = getPageBaseType(actualData1?.type);

    const typePage = pageType === PageTypes.page;
    const typeWikilist = pageType === PageTypes.wikilist;
    const typeUrl = pageType === PageTypes.url;
    const typeFile = pageType === PageTypes.file;

    useEffect(() => {
        getActualData(id)

    }, []);


    const {
        isLoading,
        files,
        onFileChange,
        removeFile,
        dndRef,
        dragEntered,
        setFiles,
        dndTriggerRef,
        uploadError,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments,
        uploadOnAdd: true,
        maxFilesCount: 1
    })
    useEffect(() => {
        if (actualData1?.file) {
            setFiles([actualData1?.file])
        }
    }, [actualData1?.file])
    useEffect(() => {
        if (actualData1 && (files.length >= 1) && files[0].isUploaded) {
            setActualData1({ ...actualData1, file: files[0].response?.data[0] })
            if (actualData1.title === '' || !actualData1.title) {
                setActualData1({ ...actualData1, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        }
    }, [files])

    // if (actualData1) {

    //     // (typePage || actualData1.type === "") && setActualData1({ ...actualData1, url: actualData1.url.indexOf("/pages/") !== -1 ? actualData1.url.replace("/pages/", "") : actualData1.url.replace("pages/", "") })
    //     typeWikilist && setActualData1({ ...actualData1, url: actualData1.url.indexOf("/wikilist/") !== -1 ? actualData1.url.replace("/wikilist/", "") : actualData1.url.replace("wikilist/", "") })
    // }
    // const [data, setData] = React.useState<{ [s: string]: any }>(pdata)

    const handleCancel = () => {
        // handleClose();
    };

    const getActualData = (id: string) => {
        return (
            utils.API.pages.getByIdSkipLayout(id, { collection: context.collectionAlias ? context.collectionAlias : "dash" })
                .r
                .then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code === 0) {

                        const pageType = getPageBaseType(response.data.type);

                        const typePage = pageType === PageTypes.page;
                        const typeWikilist = pageType === PageTypes.wikilist;
                        const typeUrl = pageType === PageTypes.url;
                        const typeFile = pageType === PageTypes.file;

                        setActualData1(response.data);
                        setModerators([...response.data?.moderators])
                        setViewers([...response.data?.viewers])
                        
                        const preparedUrl = typePage
                            ? (
                                response.data.url.indexOf("/pages/") !== -1
                                ? response.data.url.replace("/pages/", "")
                                : response.data.url.replace("pages/", "")
                            )
                            : (
                                response.data.url.indexOf("/wikilist/") !== -1
                                ? response.data.url.replace("/wikilist/", "")
                                : response.data.url.replace("wikilist/", "")
                            );

                        setUrlBeforChange(preparedUrl);

                        if(typePage || typeWikilist) {
                            setActualData1({
                                ...response.data,
                                url: preparedUrl
                            });
                        }
                    }
                }
                )
        )

    }
    const deleteWikiPage = () => {
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.delete"),
            onConfirm: () =>

                utils.API.pages.deletePage(id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.deleted"));
                        }
                    })
        });
        // handleClose();
    }

    const history = useHistory();

    const sendDataForEditPage = (isArchived?: boolean) => {
        if (actualData1) {
            setLoading(true);
            // const pageUrl = actualData1.url.indexOf("/pages/") === -1 || actualData1.url.indexOf("pages/") === -1 ? "/pages/" + actualData1.url : actualData1.url;
            // const wikilistUrl = "/wikilist/" + actualData1.url;
            // const absoluteUrl = actualData1.url;
            let link = getWikiUrl({
                ...actualData1,
                file: files[0] || null
                } as any);

            utilsProj.confirm({
                text: i18n.t("pryaniky.modal.wikipage.confirm.edit"),

                onConfirm: () => {

                    return utils.API.pages.setSettings({
                        ...actualData1,
                        collectionAlias: context.collectionAlias ? context.collectionAlias : "dash",
                        id: actualData1.id,
                        url: link,//typePage ? pageUrl : typeWikilist ? wikilistUrl : typeUrl ? absoluteUrl : pageUrl,
                        title: actualData1.title,
                        description: actualData1.description,
                        // moderators: actualData1.moderators,
                        moderators: actualData1.isInheritingRootRights ? [] : actualData1.moderators,
                        tags: actualData1.tags,
                        collections: actualData1.collections,
                        type: actualData1.type || PageTypes.page,
                        titleImageId: actualData1.titleImageId,
                        hasСustomTitleImage: actualData1.titleImageId === null || actualData1.titleImageId === undefined || actualData1.titleImageId === "" ? false : true,
                        titleImageUrl: actualData1.titleImageUrl,
                        showInMainMenu: actualData1.showInMainMenu,
                        allowComments: actualData1.allowComments,
                        defaultViewType: actualData1.defaultViewType ? actualData1.defaultViewType : 0,
                        isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived,
                        positionInCollection: Number(actualData1.positionInCollection),
                        viewers: actualData1.isInheritingRootRights ? [] : actualData1.viewers,
                        file: actualData1.file,
                        isDraft: actualData1.isDraft,
                        isInheritingRootRights: actualData1.isInheritingRootRights

                    })
                        .r
                        .then((response) => {

                            if (!response) {
                                toast.error(i18n.t('pryaniky.toast.error.server'));
                            }
                            else if (response.error_code === 0) {
                                toast.success(i18n.t("edited.success"));
                                setActualData1({ ...actualData1, isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived });

                                setLoading(false);

                                if (pdata?.url !== link && (actualData1.type === PageTypes.page || actualData1.type === PageTypes.file || !actualData1.type)) {
                                    history.push(link);
                                }

                            }
                        });

                },
                onCancel: () => {
                    setLoading(false)
                },
            });
        }

    }
    const onChangeShowInMainMenu = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, showInMainMenu: !actualData1.showInMainMenu })
    }
    const onChangIsInheritingRootRights = (e: any) => {
        if (actualData1) {
            setActualData1({ ...actualData1, isInheritingRootRights: !actualData1.isInheritingRootRights })

        }
    }

    const onChangeHideFooter = () => {
        if (actualData1) setActualData1({ ...actualData1, isHideFooter: !actualData1.isHideFooter })
    };

    const onChangeAllowComments = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, allowComments: !actualData1.allowComments })
    }

    const archivedPage = () => {
        if (actualData1) sendDataForEditPage(!actualData1.isArchived)
    }

    const deleteOptions = [
        {
            title: i18n.t("pryaniky.modal.wikipage.delete"),
            buttonProps: {
                onClick: deleteWikiPage,
            },
        },
        {
            title: i18n.t(`pryaniky.modal.wikipage.${actualData1 && actualData1.isArchived ? 'unarchive' : 'archive'}`),
            buttonProps: {
                onClick: archivedPage,
            },
        },
    ]
    const Addorment = (typePage || typeFile) ? <p className={cnWikiEdit("child")}>/pages/</p> : (typePage || typeWikilist) ? <p className={cnWikiEdit("child")}>/wikilist/</p> : (typeUrl) && undefined

    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        (!typeUrl && urlBeforChange !== e.currentTarget.value) && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);

        // !typeUrl && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        actualData1 && setActualData1({ ...actualData1, url: e.currentTarget.value })
    }

    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {

                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setError(true)
                    toast.error(i18n.t('pryaniky.wikilist.error.url.isExist'))
                } else if (response.data === false) {
                    setError(false)
                }
            })
    }

    const selectStatus = useMemo(() => {
        if (actualData1) {
            return <FormControl className={cnWikiEdit("Content-ItemSelect")} sx={{ margin: "8px 0 0 0", width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">{Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status' })}</InputLabel>

                <Select
                    labelId="demo-simple-select-helper-label"
                    options={statusOption}
                    label={Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status' })}
                    value={statusOption.find((el) => actualData1.isDraft ? el.value === "isDraft" : actualData1.isArchived ? el.value === "archived" : el.value === "published")?.value}
                    onChange={(event) => {
                        setActualData1({ ...actualData1, isDraft: event.target.value === "isDraft", isArchived: event.target.value === "archived" })
                    }}
                    MenuProps={{ disablePortal: true }}
                />
            </FormControl>
            
        }
    }, [actualData1])

    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);
    
    const viewersTitle = useWikiItemViewersTitle(actualData1?.type);

    return (
        <>
            {actualData1 ? <Box sx={{
                position: "relative",
                overflow: "hidden",
                height: "100%",
                borderRadius: "8px",
            }}>
                <Box sx={{
                    position: "absolute",
                    inset: "0px",
                    overflow: "scroll",
                    marginRight: "-17px",
                    marginBottom: "-17px",
                }}>
                    <Box className={cnWikiEdit("")}>
                        <Box className={cnWikiEdit("Content-Item")}>
                            <TextField
                                name="title"
                                fullWidth
                                value={actualData1.title}
                                autoFocus
                                className={cnWikiEdit("Input")}
                                label={
                                    <Translate
                                        i18nKey='pryaniky.wikilist.item.param.title'
                                        values={{
                                            type: Translate.t({
                                                i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                                count: 2
                                            }).toLowerCase()
                                        }}
                                    />
                                }
                                onChange={(e: any) => setActualData1({ ...actualData1, title: e.currentTarget.value })} />
                        </Box>
                        {typePage && selectStatus}
                         <Box className={cnWikiEdit("Content-Item")}>
                            <TextField
                                name="description"
                                fullWidth
                                variant='outlined'
                                value={actualData1.description}
                                className={cnWikiEdit("Input")}
                                label={
                                    <Translate i18nKey={`pryaniky.wikilist.item.param.description`} />
                                }
                                multiline
                                sx={{
                                    ".MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
                                        minHeight: "41px"
                                    }
                                }}
                                onChange={(e: any) => {
                                    setActualData1({ ...actualData1, description: e.currentTarget.value })
                                }} />
                        </Box>
                        <Box className={cnWikiEdit("Content-Item")}>
                            {
                                !typeFile && <TextField
                                    id="wikilink"
                                    name="url"
                                    fullWidth
                                    value={actualData1.url}
                                    className={cnWikiEdit(error ? "ErrorLink" : "InputLink")}
                                    autoFocus={error}
                                    error={error || actualData1.url === ""}
                                    helperText={error ? i18n.t('pryaniky.wikilist.error.url.isExist') :
                                        actualData1.url === "" ? i18n.t('pryaniky.wikilist.error.url.isEmpty') : ""}
                                    label={
                                        <Translate
                                            i18nKey='pryaniky.wikilist.item.param.url'
                                            values={{
                                                type: Translate.t({
                                                    i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                                    count: 2
                                                }).toLowerCase()
                                            }}
                                        />
                                    }
                                    onChange={(e: any) => onChangeLinkInput(e)}
                                    InputProps={{
                                        startAdornment: Addorment

                                    }}
                                />

                            }
                        </Box>
                        <Box className={cnWikiEdit("Content-Item")}>
                            {
                                typeFile && <Box
                                    ref={dndTriggerRef}
                                    sx={{ position: 'relative' }}
                                >
                                    <FilesUploader
                                        files={files}
                                        multiple={false}
                                        showProgressOnNewFiles
                                        selectButtonProps={{
                                            children: <Translate
                                                i18nKey='pryaniky.wikilist.select.type'
                                                variablesI18nKeys={{
                                                    type: 'pryaniky.wikilist.item.file'
                                                }}
                                            />,
                                            variant: 'outlined'
                                        }}
                                        error={Boolean(uploadError)}
                                        helperText={uploadError?.error?.error_text}
                                        disabled={uploadDisabled}
                                        // className={cnWikiEdit("FileInput")}
                                        // inputAccept="image/jpeg,image/png"
                                        onFileChange={onFileChange}
                                        removeFile={removeFile}
                                    />
                                </Box>
                            }
                        </Box>
                        <Box className={cnWikiEdit("InputFolder")}>
                            <PageCollectionSuggester
                                TextFieldProps={{
                                    label: <Translate
                                        i18nKey='pryaniky.wikilist.item.param.collections'
                                    />,
                                    InputProps: {
                                        endAdornment: <Tooltip title={i18n.t('pryaniky.wikilist.item.param.collections.help')} disableInteractive>
                                            <HelpIcon sx={{ marginLeft: "10px" }} color="action" />
                                        </Tooltip>
                                    }
                                }}
                                noAdornment
                                onChange={(event, value) => {
                                    if (value) {
                                        setActualData1({ ...actualData1, collections: [value] as any })
                                    } else {
                                        setActualData1({ ...actualData1, collections: [] })
                                    }
                                }}
                                className={cnWikiEdit("InputFolder-Folder")}
                                value={(actualData1?.collections?.length && actualData1?.collections?.length > 0) ? actualData1?.collections?.map((el: any) => ({
                                    displayName: el?.name,
                                    ...el
                                })) : null}
                            />
                        </Box>
                        <Box className={cnWikiEdit("Content-Item")}>
                            <TagsSuggester
                                TextFieldProps={{
                                    label: <Translate
                                        i18nKey='pryaniky.wikilist.item.param.tags'
                                    />,
                                }}
                                multiple
                                noAdornment
                                onChange={(_, value) => {
                                    setActualData1({ ...actualData1, tags: value as any })
                                }}
                                className={cnWikiEdit("InputFolder-Folder")}
                                value={actualData1.tags}
                            />
                        </Box>
                        <Divider variant="inset" sx={{ margin: '14px 0' }} />
                        {actualData1?.collections && actualData1?.collections[0]?.alias !== "dash" && isWkilistModerator &&
                            <StyledFormControlLabel
                                control={
                                    <Checkbox
                                        className={cnWikiEdit("CheckboxInput")}
                                        checked={actualData1?.isInheritingRootRights}
                                        onChange={onChangIsInheritingRootRights}
                                    />
                                }
                                label={<Translate
                                    i18nKey='pryaniky.wikilist.item.param.isInheritingRootRights'
                                />}
                            />
                        }

                        <Box className={cnWikiEdit("Content-Item")}>
                            <UsersSuggester
                                TextFieldProps={{
                                    label: <Translate
                                        i18nKey='pryaniky.wikilist.item.param.moderators'
                                    />,
                                }}
                                value={actualData1?.isInheritingRootRights ? moderators : actualData1.moderators}
                                disabled={!isWkilistModerator ? true : (isWkilistModerator && actualData1?.isInheritingRootRights) ? true : false}
                                multiple
                                noAdornment
                                onChange={(_, value) => {
                                    setActualData1({ ...actualData1, moderators: value as any })

                                }}
                                requestAdditionalParams={{
                                    excludeMe: false,
                                    groupid: pdata.gId || actualData1.context?.groupId
                                }}
                            />

                        </Box>
                        <Box className={cnWikiEdit("InputFolder")}>
                            <UsersSuggester
                                TextFieldProps={{
                                    label: viewersTitle,
                                    InputProps: {
                                        endAdornment: <Tooltip title={i18n.t('pryaniky.wikilist.item.param.viewers.help')} >
                                            <HelpIcon sx={{ marginLeft: "10px" }} color="action" />
                                        </Tooltip>
                                    },
                                }}
                                value={actualData1?.isInheritingRootRights ? viewers : actualData1.viewers || []}
                                className={cnWikiEdit("InputFolder-Folder")}
                                multiple
                                noAdornment
                                disabled={!isWkilistModerator ? true : (isWkilistModerator && actualData1?.isInheritingRootRights) ? true : false}
                                requestAdditionalParams={{
                                    excludeMe: false,
                                    groupid: pdata.gId || actualData1.context?.groupId
                                }}
                                onChange={(_, viewers) => {
                                    setActualData1({ ...actualData1, viewers: viewers as any })
                                }}
                                isAdmin
                            />
                        </Box>
                        <Divider variant="inset" sx={{ margin: '14px 0' }} />
                        {typeWikilist && <Box className={cnWikiEdit("ViewType")}>
                            <h4><Translate i18nKey={'pryaniky.wikilist.item.param.defaultViewType'} /></h4>
                            <ButtonGroup>
                                <Tooltip title={i18n.t('pryaniky.wikilist.item.param.defaultViewType.grid')} >
                                    <Button variant={actualData1.defaultViewType === 0 ? "contained" : "outlined"} onClick={() => {
                                        setActualData1({ ...actualData1, defaultViewType: 0 })
                                    }}>
                                        <GridView />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={i18n.t('pryaniky.wikilist.item.param.defaultViewType.list')} >
                                    <Button variant={actualData1.defaultViewType === 1 ? "contained" : "outlined"} onClick={() => {
                                        setActualData1({ ...actualData1, defaultViewType: 1 })
                                    }}>
                                        <ViewList />
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </Box>}
                        <Box className={cnWikiEdit("PositionInCollection")}
                            sx={{
                                marginTop: "8px",
                            }}>
                            <NumberTextField
                                fullWidth
                                hideApperance
                                className={cnWikiEdit("PositionInCollection")}
                                label={<Translate i18nKey='pryaniky.wikilist.item.param.positionInCollection' />}
                                value={actualData1.positionInCollection}
                                onChange={(e) => {
                                    setActualData1({ ...actualData1, positionInCollection: e.currentTarget.value })
                                }}
                            />
                        </Box>
                        <StyledFormControlLabel
                            control={
                                <Checkbox
                                    className={cnWikiEdit("CheckboxInput")}
                                    checked={actualData1.showInMainMenu}
                                    onChange={onChangeShowInMainMenu}
                                />
                            }
                            label={<Translate i18nKey={'pryaniky.wikilist.item.param.showInMainMenu'}/>}
                        />

                        {(typePage || typeFile) && <StyledFormControlLabel
                            control={
                                <Checkbox
                                    className={cnWikiEdit("CheckboxInput")}
                                    checked={actualData1.allowComments}
                                    onChange={onChangeAllowComments}
                                />
                            }
                            label={<Translate
                                i18nKey={'pryaniky.wikilist.item.param.allowComments'}
                                values={{
                                    type: Translate.t({
                                        i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                        count: 2
                                    }).toLowerCase()
                                }}
                            />}
                            />
                        }

                        <StyledFormControlLabel
                            control={
                                <Checkbox
                                    className={cnWikiEdit("CheckboxInput")}
                                    checked={actualData1.isHideFooter}
                                    onChange={onChangeHideFooter}
                                />
                            }
                            label={<Translate i18nKey='pryaniky.wikilist.item.param.isHideFooter' />}
                        />
                        <Divider variant="inset" sx={{ margin: '14px 0' }} />
                        <PreviewSelector
                            {...props}
                            aria-describedby={id}
                            className={cnWikiEdit({}, [props?.className])}
                            onClick={(e: any) => {
                                isEdit(true)
                                ImageCropperDialog({
                                    editOpen: isEdit,
                                    onLoaded: (file: any) => {
                                        setActualData1({ ...actualData1, titleImageId: file.id, titleImageUrl: file.originalUrl }),
                                            isEdit(false)
                                        setImgChanged(true)
                                    },
                                })

                            }}
                            previewUrl={actualData1.titleImageUrl}
                            newImg={imgChanged}
                            label={
                                <Translate
                                    i18nKey='pryaniky.wikilist.item.param.titleImage'
                                    values={{
                                        type: Translate.t({
                                            i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                            count: 2
                                        }).toLowerCase()
                                    }}
                                />
                            }
                            helperText={<Translate i18nKey="pryaniky.wikilist.item.param.titleImage.help" />}
                        />
                    </Box>
                </Box>
            </Box> :
                <Box className={cnWikiEdit("Loading")}>
                    <Translate i18nKey='pryaniky.data.actual.loading' />
                    <LinearProgress />
                </Box>
            }
            <DialogActions
                acceptText={i18n.t("pryaniky.saveChanges")}
                closeText={i18n.t("cancel")}
                onAccept={() => sendDataForEditPage()}
                disableAccept={actualData1 && (actualData1.url === '' || actualData1.url === undefined) || isLoading || error}
                isLoading={loading || isLoading}
                onClose={() => closeWikiEdit()} />


        </>
    );

};

export const WikiEdit = connect<IWikiEditStateProps, IWikiEditDispatchProps, IWikiEditOwnProps, IState>(
    mapWikiEditStateToProps,
    mapWikiEditDispatchToProps
)(WikiEditPresenter)




