import { takeLeading, takeEvery, select, put, putResolve, call, fork } from 'redux-saga/effects';
import { LEFTMENU_GET_DATA, LEFTMENU_RESET_DATA, LEFTMENU_SET_DATA, menuInitData, menuGetData, menuUnsave, menuUpdateStructure, menuUpdateItems } from './actions';
import { IMenu, IMenuActions } from './interfaces';
import { sagas as menuRequests } from 'utils/src/requests/requests.menu';
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { IMenuAPI, ItemRenderType } from 'utils/src/requests/models/api.menu';
import { checkResponseStatus } from 'utils/src/utils';
import { baseMenuItem } from './constants';
import { getMenuItems, getMenuStrusture } from './selectors';
import { toast } from 'react-toastify';
import { i18n, Translate } from 'localization';
import { saving } from './../../../utils.project/toasts';
import { CacheHelper } from 'utils/src/CacheHelper';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { flatMapDeep, union } from 'lodash';
const cacheKey = 'menu';

function* menuGet(action: IMenuActions.GetData) {
  try {
    const getStructure = (path: string[]) => (els: IMenuAPI.Item[]): IMenu.Structure => els.map(el => ({
      id: el.id,
      blockType: el.blockType,
      childrens: el.children ? getStructure([...path, el.id])(el.children) : null
    }));
    const getItems = (a: IMenu.Items, el: IMenuAPI.Item): IMenu.Items => ({
      ...a,
      [el.id]: (Object.keys(el).filter(e => e !== 'children') as (keyof typeof el)[]).reduce((ac, c) => ({ ...ac, [c]: el[c] }), baseMenuItem),
      ...(el.children ? el.children.reduce(getItems, {} as IMenu.Items) : {})
    })
    try {
      const fromCache: IMenuActions.InitDataPayload = yield call(CacheHelper.get, 'menu', cacheKey);
      if (fromCache) yield put(menuInitData(fromCache));
    } catch (error) {
      console.warn('get menu from chache error: ', error);
    }

    const response: IResponseWithData<IMenuAPI.Item[]> = yield call(menuRequests.get);
    if (checkResponseStatus(response)) {
      const data = {
        structure: getStructure([])(response.data),
        items: response.data.reduce(getItems, {} as IMenu.Items)
      };
      yield putResolve(menuInitData(data));
      yield put(menuUnsave('set'));
      try {
        yield call(CacheHelper.set, 'menu', cacheKey, data);
      } catch (error) {
        console.warn('set menu to chache error: ', error);
      }
      yield fork(changeActive, window.location.pathname);
    } else {
      toast.error(Translate.t({ i18nKey: 'pryaniky.toast.error' }));
      throw new Error("get menu response is not ok");
    }
    return 0;
  } catch (error) {
    console.error('get menu error: ', error);
  }
}

function* menuSet(action: IMenuActions.SetData) {
  try {
    const tst = toast.info(saving(Translate.t({ i18nKey: 'saving' })), { autoClose: false });
    const structure: IMenu.Structure = yield select(getMenuStrusture);
    const items: IMenu.Items = yield select(getMenuItems);
    const fillItems = (el: IMenu.StructureItem): IMenuAPI.Item => ({
      ...items[el.id],
      children: el.childrens?.map(fillItems) || null
    });
    const response: ReturnType<typeof menuRequests.set> = yield call(menuRequests.set, structure.map(fillItems));
    if (checkResponseStatus(response)) {
      yield put(menuUnsave('set'));
      toast.update(tst, {
        type: 'success',
        render: Translate.t({ i18nKey: 'pryaniky.toast.success.menuSaved' }),
        autoClose: 3000
      });
      yield call(CacheHelper.set, 'menu', cacheKey, { structure, items });
    } else {
      toast.update(tst, {
        type: 'error',
        render: Translate.t({ i18nKey: 'pryaniky.toast.error' }),
        autoClose: 3000
      });
    }
  } catch (error) {
    console.error('set menu error: ', error);
  }
}

function* menuReset(action: IMenuActions.ResetData) {
  try {
    const response: ReturnType<typeof menuRequests.reset> = yield call(menuRequests.reset);
    if (checkResponseStatus(response)) {
      yield put(menuGetData());
      toast.success(Translate.t({ i18nKey: 'pryaniky.toast.success.menuReset' }));
    } else {
      toast.error(Translate.t({ i18nKey: 'pryaniky.toast.error' }));
    }
  } catch (error) {
    console.error('reset menu error: ', error);
  }
}

const updateStructures = (structure: IMenu.StructureItem[], items: IMenu.Item[]): IMenu.StructureItem[] => {
  return structure.map(value => {
    const childrens = value.childrens ? updateStructures(value.childrens, items) : value.childrens
    const active = Boolean(items.find(v => value.id === v.id)) || Boolean((childrens || []).find(v => v.active));
    return {
      ...value,
      active,
      childrens
    }
  })
}

function* changeActive(pathname: string) {
  try {
    const itemsMap: ReturnType<typeof getMenuItems> = yield select(getMenuItems)
    const structure: ReturnType<typeof getMenuStrusture> = yield select(getMenuStrusture)
    const items = Object.values(itemsMap)
    const activeItems = items.filter(item => item.url === pathname && item.renderType === ItemRenderType.link)
    const updatedStructures = updateStructures(structure, activeItems)
    yield put(menuUpdateStructure(updatedStructures))
    yield fork(changeNotViewedCount);
  } catch (error) {

  }
}

function* changeNotViewedCount() {
  try {
    const itemsMap: ReturnType<typeof getMenuItems> = yield select(getMenuItems);
    const structure: ReturnType<typeof getMenuStrusture> = yield select(getMenuStrusture);
    // get all active items
    const activeItems = flatMapDeep(structure.filter(el => el.active).map(el => [ el.id, el.childrens?.filter(it => it.active).map(it => it.id) || [] ]));
    // get items to drop count because this item is active
    const itemsToDropCount = Object.values(itemsMap).filter(el => el.renderType === ItemRenderType.link && el.notViewedCount > 0 && activeItems.includes(el.id)).map(el => el.id);
    // get items with count without excluded items to drop
    const itemsWithCount = Object.values(itemsMap).filter(el => el.renderType === ItemRenderType.link && !itemsToDropCount.includes(el.id) && el.notViewedCount > 0);
    // get parent items to set count because childen items have count 
    const itemsCountParrentsIds = union(itemsWithCount.filter(el => el.parentId !== GUID_EMPTY).map(el => el.parentId));
    // get parrent ids to drop count
    const parentsToDropCount = Object.values(itemsMap).filter(el => el.renderType === ItemRenderType.dropdown && !itemsCountParrentsIds.includes(el.id)).map(el => el.id);
    yield put(menuUpdateItems([
      ...itemsToDropCount.map(id => ({ ...itemsMap[id], notViewedCount: 0 })),
      ...itemsCountParrentsIds.map(id => ({ ...itemsMap[id], notViewedCount: 1 })),
      ...parentsToDropCount.map(id => ({ ...itemsMap[id], notViewedCount: 0 }))
    ]));
  } catch (error) {
    console.error('change notViewedCount error: ', error);
  }
}

function* locationChanged(action: any) {
  try {
    yield fork(changeActive, action.payload.location.pathname);
  } catch (error) {

  }
}

export function* menuSaga() {
  yield takeLeading(LEFTMENU_GET_DATA, menuGet);
  yield takeLeading(LEFTMENU_SET_DATA, menuSet);
  yield takeLeading(LEFTMENU_RESET_DATA, menuReset);
  yield takeLeading("@@router/LOCATION_CHANGE", locationChanged);
};