import React, { FC, useState, useEffect} from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { SettingsCalendarMiniWidget } from '../CalendarMini.index';
import {
    cnCalendarMiniSettingsDialog,
    NCalendarMiniSettingsDialog
} from './CalendarMiniSettingsDialog.index'
import {
    CalendarsSuggester,
} from 'muicomponents/src';
import { WidgetDialogBox } from './CalendarMiniSettingsDialog.styled'



export const CalendarMiniSettingsDialog: FC<NCalendarMiniSettingsDialog.Props> = ({
    onChange,
    data
}) => {

    const [state, setState] = useState<NonNullable<SettingsCalendarMiniWidget.Props['settings']>>(data || { calendar: undefined,  });

    useEffect(() => {
        onChange({
            // дата для обновления при изменении настроек
            data: {
                calendar: state.calendar,
            },
            // это при добавллении виджет
            settings: {
                calendar: state.calendar,
            
            }
        })
        
    }, [state]);

    return (<WidgetDialogBox className={cnCalendarMiniSettingsDialog("")}>
        <CalendarsSuggester
            // {...calendarStaticProps}
            showWorkflow
            onChange={(e, value) => {
                setState({ ...state, calendar: value as typeof state.calendar})
            }}
            multiple
            test-id={'mini-calendar-widget'}
            value={state.calendar as any || undefined}
        />
    </WidgetDialogBox>)
}



export const initWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', CalendarMiniSettingsDialog, 'pryaniky/calendarmini')
export const initWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', CalendarMiniSettingsDialog, 'pryaniky/calendarmini')



export const initCalendarWidgetAddition = () => ComponentInjector.getInstance().addComponent('WAddition', CalendarMiniSettingsDialog, 'pryaniky/calendarWidget')
export const initCalendarWidgetSettings = () => ComponentInjector.getInstance().addComponent('WSettings', CalendarMiniSettingsDialog, 'pryaniky/calendarWidget')