import React, { Component, PureComponent } from 'react';
import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import { EditorState, RichUtils, Modifier, getDefaultKeyBinding, genKey } from 'draft-js';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
// import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createImagePlugin from 'uielements/src/PryanikyEditorV1/ImagePlugin'
import createResizeablePlugin from './draft-js-resizeable-plugin/src'
import createFocusPlugin from 'draft-js-focus-plugin'
import createEmojiPlugin from 'uielements/src/PryanikyEditorV1/draft-js-emoji-plugin/src'
import createDividerPlugin from 'draft-js-divider-plugin';
import { ImageAdd } from 'uielements/src/PryanikyEditorV1/AddImage/AddImage'
import createLinkPlugin from 'uielements/src/PryanikyEditorV1/draft-js-anchor-plugin/src';
import createListPlugin from "uielements/src/PryanikyEditorV1/draft-js-list-plugin";
import createCtrlEnter from 'uielements/src/PryanikyEditorV1/CtrlEnterSend';
import createVideoPlugin from './VideoPlugin'
import AddVideo from 'uielements/src/PryanikyEditorV1/VideoAdd'
import ColorPicker from './ColorPicker'
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import { BlockStyleControls } from 'uielements/src/PryanikyEditorV1/BlockStyleControls/BlockStyleControls';
import { MentionSuggestionsPresenter } from './MentionSuggestions/MentionSuggestions'
import { TagSuggestionsPresenter } from './TagSuggestions/TagSuggestions'
import createMentionPlugin from 'uielements/src/PryanikyEditorV1/MentionPlugin'
import createSelectMentionPlugin from './MentionSelectPlugin'
import 'draft-js-emoji-plugin/lib/plugin.css'
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import './PryanikyEditor.css'
import {
    IPryanikyEditorProps, mapStateToProps, mapDispatchToProps
} from './PryanikyEditor.index'
import { Icon, HelpTooltip } from 'uielements/src'
import { withEditorContext } from '../EditorForm/EditorForm'
import { ListControls } from './ListControls/ListControls'
// import { AlignControls } from './AlignControls/AlignControls'
import { Map } from 'immutable'
import createImgAltPlugin from './ImgAltPlugin'
import * as utils from 'utils/src/utils'
import { connect } from 'react-redux';
import createLinkifyPlugin from 'uielements/src/PryanikyEditorV1/draft-js-linkify-plugin/src';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    CodeButton,
    CodeBlockButton
} from 'uielements/src/PryanikyEditorV1/draft-js-buttons/src';
import { TOptions } from 'i18next';
import { i18n, Translate } from 'localization';
import { editOnPaste, editOnPasteWithLock } from 'uielements/src/PryanikyEditorV1/onPaste'
import { createKeyBinding } from 'uielements/src/PryanikyEditorV1/KeyBinding'
import createAlignmentPlugin from 'uielements/src/PryanikyEditorV1/draft-js-alignment-plugin/src';
import { getSelectedBlockType, getSelectedAtomicType, getSelectedBlock } from 'uielements/src/PryanikyEditorV1/utils'
import { AlignControls } from 'uielements/src/PryanikyEditorV1/AlignControls/AlignControls'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import CompoundDecorator from 'uielements/src/PryanikyEditorV1/CompoundDecorator';

const tt = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.editor.common.tools.tooltip.${key}`, options)

export const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;

const alignmentStyle = {
    alignmentToolStyles: {
        alignmentTool: 'alignmentTool',
        style: {}
    },
    buttonStyles: {
        button: 'button',
        active: '_active'
    }
}
const inlineToolbarStyle: any = {
    toolbarStyles: {
        toolbar: 'inlineToolbar'
    },
    buttonStyles: {
        button: 'button',
        active: '_active'
    }
}

const BlockquoteButton = createInlineStyleButton({
    style: 'blockquote',
    children: <i className="Icon icon-quote" />
})

function isAnyPartOfElementInViewport(el: any) {

    const rect = el.getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
}

const blockRenderMap = Map({
    'atomic': {
        element: 'div',
        wrapper: <div />
    }
});

export const styleWholeSelectedBlocksModifier = (editorState: any, style: any, removeStyles: any[] = []) => {

    let currentContent = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    let selectionIsBackward = selection.getIsBackward();

    let changes = {
        anchorOffset: 0,
        focusOffset: focusBlock.getLength()
    }

    if (selectionIsBackward) {
        changes = {
            focusOffset: 0,
            anchorOffset: anchorBlock.getLength()
        }
    }
    let selectWholeBlocks = selection.merge(changes)
    let modifiedContent = Modifier.applyInlineStyle(currentContent, selectWholeBlocks, style);
    let finalContent = removeStyles.reduce(function (content, style) {
        return Modifier.removeInlineStyle(content, selectWholeBlocks, style);
    }, modifiedContent);
    return EditorState.push(editorState, finalContent, 'change-inline-style');
}

export const getBlockAlignment = (block: any) => {
    let style = 'left';
    block.findStyleRanges(function (e: any) {
        if (e.hasStyle('center')) style = 'center';
        if (e.hasStyle('right')) style = 'right';
        if (e.hasStyle('justify')) style = 'justify';
    })
    return style;
}


function customStyleFn(style: any): any {
    const styleNames = style.toJS();

    return styleNames.reduce((styles: any, styleName: string) => {
        if (styleName.startsWith('COLORED_')) {

            styles.color = styleName.replace('COLORED_', '')
            // styles.textAlign = 'center'//styleName.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
        }
        if (styleName === 'CODE') {
            styles.backgroundColor = '#f8f8f8';
            styles.borderRadius = '2px';
            styles.border = '1px solid #ccc';
            styles.color = '#333';
            styles.overflow = 'visible';
            styles.display = 'inline';
            styles.whiteSpace = 'pre-wrap';
            styles.marginBottom = '5px';
            styles.marginTop = '5px';
            styles.padding = '.05rem .2rem';
        }
        if (styleName === 'blockquote') {
            styles.borderLeft = '4px solid #ccc';
            styles.marginBottom = '5px';
            styles.marginTop = '5px';
            styles.paddingLeft = '16px';
        }
        return styles;
    }, {});
}
; (window as any).currentActiveEditorId = undefined
export class PryanikyEditor extends PureComponent<IPryanikyEditorProps> {
    public static defaultProps = {
        onFocus: () => { },
        onBlur: () => { }
    }

    private editor: any;
    private plugins: any[];
    private emojiPlugin: any;
    private dividerPlugin: any;
    private linkPlugin: any;
    private toolbarPlugin: any;
    private inlineToolbarPlugin: any;
    private alignmentPlugin: any;
    private imagePlugin: any;
    private videoPlugin: any;
    private userMentionPlugin: any;
    private tagMentionPlugin: any;
    private selectMentionPlugin: any;
    private imgAltPlugin: any;

    private containerRef: any;
    private cRef: React.RefObject<any> = React.createRef<any>();

    private lock = false;
    
    
    private isFirst: boolean = true;

    private editorId = genKey()
    private compoundDecorator: any;

    private onInputChange = utils.debounce((editorState: any) => this.censureCheck(editorState), 1000);

    state = {
        hasFocus: false,
        hasInit: false,
        open: false,
        isInit: false,
        initalState: this.props.value || EditorState.createEmpty(),
        autoSelectOpen: false,
        tagOpen: false
    }

    constructor(props: IPryanikyEditorProps) {
        super(props)
            ; (window as any).currentActiveEditorId = undefined;
        this.emojiPlugin = createEmojiPlugin({
            imagePath: '/public/emojione/png/64/',
            imageType: 'png',
            selectButtonContent: <Icon icon={'laugh'} />
        })
        const focusPlugin = createFocusPlugin()
        const resizeablePlugin = createResizeablePlugin()//props.enabledAnvanced ? createResizeablePlugin() : createDefaultResizeablePlugin()
        this.toolbarPlugin = createToolbarPlugin({
            theme: {
                buttonStyles: {
                    buttonWrapper: ``,
                    button: `PrnEditor-StyleButton`,
                    active: `PrnEditor-activeButton`
                }, toolbarStyles: {}
            }
        });

        this.alignmentPlugin = createAlignmentPlugin({ theme: alignmentStyle });

        const linkifyPlugin = createLinkifyPlugin();
        this.linkPlugin = createLinkPlugin({});

        this.inlineToolbarPlugin = createInlineToolbarPlugin({ theme: inlineToolbarStyle });

        this.selectMentionPlugin = createSelectMentionPlugin(
            {
                setOpen: this.changeAutoSelectOpen,
                onChangeState: this.onChange
            });

        this.imgAltPlugin = createImgAltPlugin({})

        const decorator = composeDecorators(
        )
        this.dividerPlugin = createDividerPlugin({ decorator });

        this.videoPlugin = createVideoPlugin({
            onChange: this.onChange,
            decorator: composeDecorators(
                // resizeablePlugin.decorator,
                focusPlugin.decorator,
            )
        });

        this.imagePlugin = createImagePlugin({
            decorator: composeDecorators(
                resizeablePlugin.decorator,
                this.alignmentPlugin.decorator,
                focusPlugin.decorator,
                // this.imgAltPlugin.decorator,
                // this.linkPlugin.decorator
                // dndPlugin.decorator
            ),
            saveLock: () => { },
            disablePastedFiles: this.props.disableToolbar,
            enableRemoveBackspace: true,
            enableRemoveDelete: true
        })


        this.userMentionPlugin = createMentionPlugin({ containerRef: this.cRef, onOpenChange: this.onOpenChange, supportWhitespace: true });
        this.tagMentionPlugin = createMentionPlugin({ containerRef: this.cRef, mentionPrefix: '#', mentionTrigger: '#', supportWhitespace: true, onOpenChange: this.changeTagOpen });
        // const { types } = videoPlugin;

        const ctrlEnter = createCtrlEnter({
            ctrlEnterCb: props.onComplete || (() => () => { })
        });

        const keyBindings = createKeyBinding({})

        // const listPlugin = createListPlugin();


        this.plugins = [
            // dragNDropFileUploadPlugin,
            // blockDndPlugin,
            this.videoPlugin,
            this.linkPlugin,
            linkifyPlugin,
            focusPlugin,
            resizeablePlugin,
            this.alignmentPlugin,
            // this.tablePlugin,
            this.imagePlugin,
            this.toolbarPlugin,
            this.emojiPlugin,
            this.dividerPlugin,
            this.tagMentionPlugin,
            this.userMentionPlugin,
            this.inlineToolbarPlugin,
            this.selectMentionPlugin,
            this.imgAltPlugin,
            ctrlEnter,
            keyBindings,
            // listPlugin,
        ];

        this.compoundDecorator = CompoundDecorator(this.plugins, false)
    }

    private censureCheck = (editorState: any) => {
        const text = editorState.getCurrentContent().getPlainText();
        if (text.trim() === '' || this.lock) return;
        this.lock = true;
        utils.API.news.censureCheck({ text }).r.then((d: any) => {
            this.lock = false;
            if (d && d.data) {
                this.props.setError && this.props.setError(d.data.preventSend, d.data.message)
            }

        })
    }

    public insertText = (text: string) => {
        const editorState = this.props.value || this.state.initalState;
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const nextContentState = Modifier.insertText(contentState, selection, text);
        // let nextEditorState = EditorState.createEmpty();
        const nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'insert-fragment'
        );
        this.props.onChange(EditorState.forceSelection(
            nextEditorState,
            nextContentState.getSelectionAfter()
        ));
    }

    public componentWillUnmount = () => {
        ; (window as any).currentActiveEditorId = undefined;
    }

    public componentDidMount = () => {

        if (this.props.autoFocus && this.props.autoFocus()) this.focus();
        const { EmojiSelect } = this.emojiPlugin;
        const { SearchAction } = this.selectMentionPlugin;
        this.props.setActionBtn([

            <Tooltip title={tt('insert_emoji')} >
                <span><EmojiSelect /></span>
            </Tooltip>,
            <SearchAction />
        ])
    }

    private onChange = (editorState: any) => {
        this.props.enableCensure && !this.lock && this.onInputChange(editorState)
        this.props.onChange(editorState)
    };

    public componentDidUpdate = () => {
        // this.lock && this.editor.focus();
        // this.lock = false;
    }

    private onOpenChange = (open: any) => {
        this.setState({ open })
    }

    private changeAutoSelectOpen = (autoSelectOpen: boolean) => this.setState({ autoSelectOpen });
    private changeTagOpen = (tagOpen: boolean) => this.setState({ tagOpen });

    public isFocus = () => this.state.hasFocus;

    public focus = () => {
        ; (window as any).currentActiveEditorId = this.editorId
        if (this.cRef.current && !utils.elemInViewport(this.cRef.current)) {
            const rect = this.cRef.current.getBoundingClientRect();
            window.scrollTo(0, (window.pageYOffset + rect.top) - (window.innerHeight / 2));
        }
        if (this.state.hasInit) {
            this.editor.focus();
        }
        this.setState({ hasFocus: true, hasInit: true, isInit: true }, () => {
            this.props.onFocus && this.props.onFocus();
        });
        // this.lock = false;
        if (this.containerRef && !isAnyPartOfElementInViewport(this.containerRef)) {
            this.containerRef && this.containerRef.scrollIntoView({ block: "center", behavior: "smooth" })
        }
    };

    public hasText = () => {
        const state = this.props.value || this.state.initalState
        const contentState = state.getCurrentContent();
        return contentState.hasText()
    }

    public toggleBlockType = (blockType: any) => {
        this.onChange(
            RichUtils.toggleBlockType(
                this.props.value || this.state.initalState,
                blockType
            )
        );
    }

    public toggleInlineStyle = (style: string) => {
        const editorState = this.props.value || this.state.initalState;
        const currentStyle = editorState.getCurrentInlineStyle();
        const selection = editorState.getSelection();
        let nextContentState = editorState.getCurrentContent();
        currentStyle.toArray().forEach((val: string) => {
            if (val.startsWith('COLORED_')) {

                nextContentState = Modifier.removeInlineStyle(
                    nextContentState,
                    selection,
                    val
                )
            }
        })
        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );
        this.onChange(
            RichUtils.toggleInlineStyle(
                nextEditorState,
                style
            )
        );
    }

    public myBlockStyleFn = (block: any): any => {
        const type = block.getType();
        /*if (type === 'textCenter') {
            return 'textCenter';
        }*/
        const state = this.props.value || this.state.initalState
        let alignment = getBlockAlignment(block);
        if (!block.getText()) {
            let previousBlock = state.getCurrentContent().getBlockBefore(block.getKey());
            if (previousBlock) {
                alignment = getBlockAlignment(previousBlock);
            }
        }
        if (type === 'blockquote') {
            return (`PrnEdit-blockquote alignment--${alignment}`)
        }
        return `alignment--${alignment}`
    }

    private getEditorState = () => this.props.value
    private onTab = (e: any) => {
        //prevent cursor from selecting the next interactive element
        e.preventDefault()

        // assign a constant for the new editorState
        // const newState = RichUtils.onTab(e, this.getEditorState(), 4)
        const editorState = this.getEditorState()
        const block = getSelectedBlock(editorState)
        const blockKey = block.getKey();
        const depth = block.getDepth();
        const newBlock = block.set('depth', depth < 1 ? depth + 1 : 0);
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();
        const newBlockMap = blockMap.set(blockKey, newBlock);
        const newState = EditorState.push(
            editorState,
            contentState.merge({ blockMap: newBlockMap }),
            'adjust-depth'
        );
        // if a new editor state exists, set editor state to new state
        // and return 'handled', otherwise return 'not-handled
        if (newState) {
            this.onChange(newState)
            return 'handled'
        } else {
            return 'not-handled'
        }
    }
    // handleKeyCommand = (command: any, editorState: any, ts: any) => {
    //     const newState = RichUtils.handleKeyCommand(editorState, command);
    //     if (newState) {
    //         this.onChange(newState);
    //         return 'handled';
    //     }
    //     return 'not-handled';
    // }

    // mapKeyToEditorCommand = (e: any) => {
    //     if (e.keyCode === 9 /* TAB */) {
    //         const newEditorState = RichUtils.onTab(
    //             e,
    //             this.props.value,
    //             4, /* maxDepth */
    //         );
    //         if (newEditorState !== this.props.value) {
    //             this.onChange(newEditorState);
    //         }
    //         return null;
    //     }
    //     return getDefaultKeyBinding(e);
    // }

    public render() {
        const { EmojiSuggestions } = this.emojiPlugin;
        const { DividerButton } = this.dividerPlugin;
        const { LinkButton } = this.linkPlugin;
        const { Toolbar } = this.toolbarPlugin;
        const { InlineToolbar } = this.inlineToolbarPlugin;
        const { AlignmentTool } = this.alignmentPlugin;
        const { MentionSelectSuggesion } = this.selectMentionPlugin
        const { ImgAltTool } = this.imgAltPlugin
        // const { AddTableButton } = this.tablePlugin
        let className = '';
        const contentState = this.props.value.getCurrentContent();

        const pasteProp = !isIE ? ({
            // onPaste: editOnPaste,
            onPaste: editOnPasteWithLock(this.props.locker || ((lock) => console.log('lock', lock)))
        } as any
        ) : ({} as any)

        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' PryanilyEditor-hidePlaceholder';
            }
        }
        if (this.isFocus()) className += ' PryanilyEditor-hidePlaceholder';
        return (

            <div ref={(e: any) => { this.containerRef = e }} className={'PryanikyEditor' + (this.props.noIcon ? ' PryanikyEditor_noIcon' : '')}>
                {(!this.props.disableToolbar) && <Toolbar>
                    {
                        // may be use React.Fragment instead of div to improve perfomance after React 16
                        (externalProps: any) => {
                            const type = getSelectedBlockType(this.getEditorState())
                            const enityType = getSelectedAtomicType(this.getEditorState())
                            return (
                                <div style={{ padding: '0 13px', borderBottom: '1px solid #D3D9E0', display: 'flex', flexWrap: 'wrap' }}>
                                    {
                                        enityType === 'IMAGE' && <AlignmentTool />
                                    }
                                    <BlockStyleControls
                                        title={tt('headers')}
                                        getEditorState={this.getEditorState}
                                        onToggle={this.toggleBlockType}
                                    />
                                    <BoldButton
                                        {...externalProps}
                                        title={tt('bold')}
                                        editorState={this.props.value}
                                        setEditorState={this.onChange}
                                    />
                                    <ItalicButton
                                        {...externalProps}
                                        title={tt('italic')}
                                        editorState={this.props.value}
                                        setEditorState={this.onChange}
                                    />
                                    <UnderlineButton
                                        {...externalProps}
                                        title={tt('underline')}
                                        editorState={this.props.value}
                                        setEditorState={this.onChange}
                                    />
                                    <CodeButton
                                        {...externalProps}
                                        title={tt('line_code')}
                                        editorState={this.props.value}
                                        setEditorState={this.onChange}
                                    />
                                    {/* <CodeBlockButton
                                        {...externalProps}
                                        title={tt('blockcode')}
                                        getEditorState={this.getEditorState}
                                        setEditorState={this.props.onChange}
                                    /> */}
                                    {/*<Separator {...externalProps} />*/}
                                    <ListControls
                                        title={tt('list')}
                                        externalProps={externalProps}
                                    />
                                    {/* <BlockquoteButton
                                        title={tt('blockquote')}
                                        {...externalProps}
                                    /> */}
                                    {/* <CodeBlockButton  {...externalProps} /> */}
                                    {/*<DividerButton {...externalProps} />*/}
                                    {/* <AddTableButton {...externalProps} /> */}
                                    <ImageAdd {...externalProps}
                                        editorRef={{ current: this.editor }}
                                        disableLink={true}
                                        disableTitle={true}
                                        title={tt('insert_img')}
                                        editorState={this.props.value}
                                        onChange={this.onChange}
                                        modifier={this.imagePlugin.addImage}
                                    />
                                    <AddVideo {...externalProps}
                                        title={tt('insert_video')}
                                        maxFileSize={this.props.maxFileSize}
                                        editorState={this.props.value}
                                        onChange={this.onChange}
                                        modifier={this.videoPlugin.addVideo}
                                    />
                                    {(window.PRN_SERVICE.dev_features) && <AlignControls
                                        title={tt('align')}
                                        getEditorState={() => this.props.value}
                                        setEditorState={this.onChange}
                                    />}
                                    {this.props.enabledAnvanced && <>
                                        {/* <AlignControls
                                            title={tt('align')}
                                            editorState={this.props.value}
                                            onToggle={this.onChange} /> */}
                                        <ColorPicker {...externalProps} onToggle={this.toggleInlineStyle} editorState={this.props.value} />
                                    </>}

                                    {this.props.additionalControls}
                                </div>
                            )
                        }
                    }
                </Toolbar>}

                <div  {...{ translate: "no" } as any} ref={this.cRef} data-editorId={this.editorId} onClick={this.focus} className={'prn-editor-container' + className}>
                    <Editor

                        {...pasteProp}// старая либа, про пропсы не знает...

                        onFocus={() => {
                            ; (window as any).currentActiveEditorId = this.editorId
                            this.setState({ hasFocus: true, isInit: true }, () => { this.props.onFocus(); })
                        }}
                        onBlur={() => { this.setState({ hasFocus: false }, () => { this.props.onBlur(); }) }}
                        placeholder={this.props.placeholder}
                        // editorState={this.state.isInit ? this.props.value : this.state.initalState}
                        editorState={this.props.value}
                        // editorState={(window as any).currentActiveEditorId === this.editorId ? this.props.value : EditorState.createWithContent(this.props.value.getCurrentContent(), this.props.value.getDecorator())}
                        // editorState={(!(window as any).currentActiveEditorId || (window as any).currentActiveEditorId === this.editorId) ? this.props.value : EditorState.createEmpty(this.props.value.getDecorator())}
                        // onChange={this.state.hasFocus ? this.onChange : () => { }}
                        onChange={this.onChange}
                        plugins={this.plugins}
                        customStyleFn={customStyleFn}
                        blockStyleFn={this.myBlockStyleFn}
                        // readOnly={this.props.readOnly}
                        readOnly={
                            this.props.readOnly || !(!(window as any).currentActiveEditorId || (window as any).currentActiveEditorId === this.editorId)
                        }
                        // handleKeyCommand={this.handleKeyCommand}
                        // keyBindingFn={this.mapKeyToEditorCommand}
                        // blockRenderMap={blockRenderMap}
                        // customStyleMap={styleMap}
                        onTab={this.onTab}
                        ref={(element) => { this.editor = element; (this.isFocus() && element) && element.focus() }}
                    />
                    <InlineToolbar>
                        {
                            // may be use React.Fragment instead of div to improve perfomance after React 16
                            (externalProps: any) => (
                                <div>
                                    <LinkButton {...externalProps} />
                                </div>
                            )
                        }
                    </InlineToolbar>
                    <EmojiSuggestions />
                    <MentionSelectSuggesion open={this.state.autoSelectOpen} />
                    <MentionSuggestionsPresenter
                        plugin={this.userMentionPlugin}
                        open={this.state.open}
                        setOpen={this.changeAutoSelectOpen}
                        onChange={this.onOpenChange} />
                    <TagSuggestionsPresenter plugin={this.tagMentionPlugin} open={this.state.tagOpen} />

                    {

                        this.props.enabledAnvanced && <>

                            <ImgAltTool />
                        </>
                    }
                </div>
            </div>
        );
    }
}


export const PryanikyEditorContexted = connect(
    mapStateToProps,
    mapDispatchToProps
)(withEditorContext(PryanikyEditor));
// export const PryanikyEditorContexted = withEditorContext(PryanikyEditor)