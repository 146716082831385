import React from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { ILeftMenuItemChangeProps, cnLeftMenuItemChange } from './ItemChange.index';
import { clone } from 'utils/src/utils';
import {
  // DialogTitle,
  // DialogActions,
  // DialogContent,
  Select,
  Icon
} from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { Checkbox } from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { IMenu } from 'blocks/LeftMenu/redux/interfaces';
import { IMenuAPI, ItemRenderType } from 'utils/src/requests/models/api.menu';
import { iconSelectModal } from './IconsSelector/IconsSelector';
import './ItemChange.scss';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'

const ItemChangePresenter: React.FC<ILeftMenuItemChangeProps> = ({ handleClose, handleAccept, data: dt, type }) => {

  const [data, setData] = React.useState(clone(dt));

  const dataChange = (value: Partial<typeof data>) => setData({ ...data, ...value });

  const renderTypeData: IMenuAPI.ItemRenderTypes[] = [ItemRenderType.dropdown, ItemRenderType.link];

  const prepareSelectedData = (i: IMenuAPI.ItemRenderTypes) => ({ value: i, title: Translate.t({ i18nKey: `pryaniky.menu.modal.add.type.${i}` }) });

  const selectIcon = () => {
    iconSelectModal({
      selected: data.icon,
    }).then(icon => {
      dataChange({ icon })
    })
  }

  return <div className={cnLeftMenuItemChange({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose}>
      {/* <h4 className="my-1"> */}
        <Translate i18nKey={`pryaniky.menu.modal.${type}.title`} />
      {/* </h4> */}
    </DialogTitle>
    {/* <DialogContent className={cnLeftMenuItemChange('Body')}> */}
    <DialogContent className={cnLeftMenuItemChange('')}>
      <div className={cnLeftMenuItemChange('Item')}>
        <Input
          value={data.title}
          placeholder={Translate.t({ i18nKey: 'pryaniky.menu.modal.change.itemName' })}
          onChange={(value) => dataChange({ title: value })}
          onBlur={() => dataChange({ title: data.title.trim() })}
        />
      </div>

      <div className={cnLeftMenuItemChange('Item')}>
        {
          type === 'add' &&
          <Select
            className={cnLeftMenuItemChange('Select')}
            selected={prepareSelectedData(data.renderType)}
            placeholder={Translate.t({ i18nKey: 'pryaniky.menu.modal.change.type' })}
            options={renderTypeData.map(prepareSelectedData)}
            onChange={({ value }: any) => dataChange({ renderType: value })}
            noDefault={true}
          />
        }
      </div>

      <div className={cnLeftMenuItemChange('Item')}>
        {
          data.renderType === ItemRenderType.link &&
          <Input
            value={data.url}
            placeholder={Translate.t({ i18nKey: 'pryaniky.menu.modal.change.link' })}
            onChange={(url) => dataChange({ url })}
            onBlur={() => dataChange({ url: data.url.trim() })}
          />
        }
      </div>

      <div className={cnLeftMenuItemChange('Icons')}>
        <Translate i18nKey={'pryaniky.menu.modal.change.icon'} />: <Button color={'primary'} variant={'outlined'} onClick={selectIcon}>
          <Icon icon={data.icon} />
        </Button>
      </div>
      <div className={cnLeftMenuItemChange('Item')}>
        <Suggest
          value={data.groups}
          type='groups'
          multiple={true}
          label={Translate.t({ i18nKey: 'pryaniky.menu.modal.change.groups' })}
          onChange={(groups: any) => dataChange({ groups })}
        />
      </div>

      <div className={cnLeftMenuItemChange('Item')}>
        <Checkbox
          onChange={(showOnMobile) => dataChange({ showOnMobile })}
          value={data.showOnMobile}
          label={
            <Translate i18nKey='pryaniky.menu.modal.change.showOnMobile' />
          }
        />
      </div>

      <div className={cnLeftMenuItemChange('Item')}>
        <Checkbox
          onChange={(openInNewTab) => dataChange({ openInNewTab })}
          value={data.openInNewTab}
          label={
            <Translate i18nKey='pryaniky.menu.modal.change.openInNewTab' />
          }
        />
      </div>

    </DialogContent>
    <DialogActions
      acceptText={i18n.t('save')}
      closeText={i18n.t('pryaniky.cancel.dialog')}
      onAccept={() => handleAccept(data)}
      onClose={handleClose} />
    {/* <Button color='primary' variant={'contained'} onClick={() => handleAccept(data)}>
        <Translate i18nKey='save' />
      </Button>
      <Button color='primary' onClick={handleClose}>
        <Translate i18nKey='cancel' />
      </Button>
    </DialogActions> */}
  </div>
}

export const itemChangeModal = createModal(ItemChangePresenter, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});