import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

export const journal = (): IWidget<IColumn[]> => {
    return generateWidget({
        type: widgets.types.layout,
        data: [
            generateColumn({
                items: [
                    generateWidget({
                        type: widgets.types.journal,
                    }),
                ],
                styles: {
                    width: '72%',
                },
            }),
        ],
    });
}