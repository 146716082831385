import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
// import { TextareaMentionsEditor } from '../../../TextareaMentions/TextareaMentions'
import { i18n, Translate } from 'localization';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'

export const Editor: React.FC<IEditorProps> = ({ children, data, onChangeText, ...props }) => {
    return <div className={'Editor EditorFeedback'}>
        <PryanikyEditor
            // getClassRef={refGetter}
            //ref={forwardRef as any}
            // users={true}
            // tags={true}
            onChange={onChangeText}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.news.text')} 
            />
        {children}
    </div>
}