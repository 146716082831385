import React from 'react';
import { i18n, Translate } from 'localization';
import { ICurrencyTimelineContext } from 'redux/currencyTimeline/interfaces';
import { Checkbox } from 'muicomponents/src';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import { FormLabel } from 'muicomponents/src/FormControl';


interface ITimelineFiltersProps {
  changeCurrTimelineContext: (payload: object) => void
  userSex: string
  context: ICurrencyTimelineContext
}

export const TimelineFilters: React.FC<ITimelineFiltersProps> = ({
  changeCurrTimelineContext,
  userSex,
  context }) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeCurrTimelineContext({ [e.currentTarget.name]: !context[e.currentTarget.name as keyof typeof context] })
  }

  return <>
    <PaperBox>

      <FormLabel>
        <Checkbox
          name='earned'
          value={context.earned}
          onChange={e => handleChange(e)}
          checked={context.earned}
          size='small'
        />
        <Translate i18nKey={`pryaniky.timeline.earned.${userSex}`} />
      </FormLabel>

      <FormLabel>
        <Checkbox
          name='spent'
          checked={context.spent}
          value={context.spent}
          size='small'
          onChange={e => handleChange(e)}
        />
        <Translate i18nKey={`pryaniky.timeline.spent.${userSex}`} />
      </FormLabel>

      <FormLabel>
        <Checkbox
          name='given'
          checked={context.given}
          onChange={e => handleChange(e)}
          value={context.given}
          size='small'
        />
        <Translate i18nKey={`pryaniky.timeline.given.${userSex}`} />
      </FormLabel>

    </PaperBox>

  </>
}
