import { connect } from 'react-redux';
import React, { FC, useEffect } from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { mapDispatchToProps } from '../../redux/connector';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pageComponents from '../../pageComponents';
import { cnLayout, ILayoutProps, ILayoutState, mapStateToProps, LayoutActionsToProps } from './Layout.index';
import { Login } from '../Login/Login';
import { Background } from '../Background/Background';
import { Header } from '../Header/Header';
import { Header as HeaderMui } from '../HeaderMui/Header';
import { Stub, CustomScrollbars } from 'uielements/src';
import { ProjectLogo } from '../ProjectLogo/ProjectLogo';
import { PwdForgot as Forgot } from '../PwdForgot/PwdForgot';
import { PwdReset as Reset } from '../PwdReset/PwdReset';
import './Layout.scss';
import { routes } from 'routes/routes';
import { Srtest } from 'blocks/Srtest/Srtest';
import { NotFound } from 'blocks/NotFound/NotFound';
import { ChatService } from '../../services/chat/ChatService';
import { i18n, Translate } from 'localization';
import { ListRound } from 'blocks/ListRound/ListRound';
import { history } from 'redux/browserHistory';
import { basename, isMobile, isTablet, getPlatform } from 'utils/src/utils';
import { v1 as uuid } from 'uuid';
import { MESSAGES_TYPES } from 'post.messages.types';
import { WikiTP } from 'blocks/WikiTP/WikiTP';
import { CodeTest } from 'blocks/_CodeTest/CodeTest';
import { Page } from 'blocks/Page/Page';
import { LearningBlock } from 'blocks/_LearningBlock/LearningBlock';
// import { WindowEvents, WINDOW_EVTS } from 'utils/src.project/events.prn';
import { denormalize, normalize, schema } from 'normalizr';
import { _getCookie } from 'utils/src/debug';
// import { TopButton } from 'blocks/Widget/_type/Widget_type_topbutton';
import { AdditionalMenu } from '../AdditionalMenu/AdditionalMenu';
import { windowFocus, windowBlur } from 'utils/src/CommonRedux/base/actions';

import { CacheHelper } from 'utils/src/CacheHelper'
import urls from 'routes/urls';
import { Logout } from 'blocks/Logout/Logout';
import { integrationModes } from 'utils/src/constants.prn';
// import { i18nText } from '../_i18nTest/i18n.test'; //именование *.test. принято для файлов юнит-тестов. 
import { LayoutLoading } from '../LayoutLoading/LayoutLoading';
import { generateCssFromMainColor, getLocationStateFrom, isAllowOpenNewsDialog } from 'utils.project/utils.project';
import { SortablePage } from '../_Sortable/Sortable';
import ErrorBoundary from 'utils/src/ErrorBoundary';
import queryString from 'query-string';
import { matchPath } from "react-router";
import SupportBar from 'blocks/SupportBar';
import { myTimelineFilter } from 'utils/src/constants.prn'
import { SnowFlakes } from 'blocks/SnowFlakes/SnowFlakes';
import { NewsDialogComponent } from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import { EnvironmentContexWrapper, useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { LeftMenu } from 'blocks/LeftMenu/LeftMenu';
import { LeftMenu as LeftMenuMui } from 'blocks/LeftMenuMui/LeftMenu';
import { IStateType } from 'redux/store';
import { DebugAuth } from './DebugAuth/DebugAuth';
import { ExternalActions } from 'blocks/ExternalActions/ExternalActions';
import { testCrumbs } from 'blocks/WikiListNew/hooks'
import { useHistory } from "react-router";
import InputBaseMui4 from '@material-ui/core/InputBase';

const paramToDeleteAllCookie = 'clearAllCookies';
const paramToEtrace = 'etrace';
const loadingRedirectIgnore = ['login', 'logout', 'forgot', 'resetpassword', 'registration'];
const removeFromSearchToRedirect = [paramToDeleteAllCookie];

let timer: any;

const mapReduceredLeftMenuStateToProps = (state: IStateType) => ({
  openedMenu: state.store.appDesign?.showMenuOpened
})

const ReduceredLeftMenuPr: FC<ReturnType<typeof mapReduceredLeftMenuStateToProps>> = ({
  openedMenu
}) => {
  const history = useHistory();

  if (history.location.pathname !== "/wikilist" &&
    !history.location.pathname.startsWith('/wikilist') &&
    !history.location.pathname.startsWith('/pages')) {
    testCrumbs(undefined)
  }
  return (
    <LeftMenuMui
      stayOpened={openedMenu}
    />
  )
};

const ReduceredLeftMenu = connect(mapReduceredLeftMenuStateToProps)(ReduceredLeftMenuPr);







export class LayoutPresenter extends React.Component<ILayoutProps, ILayoutState> {
  public utils = utils;
  private notReSetRedirectCookie: boolean = false;
  private redirect: string;
  private redirectAfterLogin: string;
  private platform: string;
  private mobile: boolean;
  private tablet: boolean;
  private isInFrame =
    window.location.pathname.includes('/wv/') ||
    window.location.pathname.includes('/widgets/') ||
    window.location.pathname.includes('/Widgets/');
  private isWebView =
    window.location.pathname.includes('/wv/');
  // private i18n: any;

  constructor(props: ILayoutProps) {
    super(props);
    // document.title = i18n.t('project name');
    if (props.location.search.substr(1).split(/[&=]/).includes(paramToDeleteAllCookie)) utils.deleteAllCookies(); // history.push('/login');
    const etrace = window.location.search.substr(1).split(/[&]/).find(e => e.includes(paramToEtrace))?.replace(`${paramToEtrace}=`, '');
    if (etrace) {
      utils.setCookie({ name: paramToEtrace, value: etrace, props: etrace !== '1' ? { expires: new Date(0).toUTCString() } : undefined })
    };
    props.i18n_check_language_namespace();

    this.checkAuth();
    if (utils.isAuthInQueryStringv2()) {
      const queryparams = utils.getQueryStringAuthv2();
      const { DEV_companyName, companyName, authUid, authAt } = queryparams;
      if (DEV_companyName) utils.setCookie({ name: 'DEV_companyName', value: DEV_companyName });
      // document.cookie = `DEV_companyName=${DEV_companyName}; path=/;samesite=None; secure`;
      // if (companyName) utils.setCookie({ name: 'companyName', value: companyName });
      // document.cookie = `companyName=${companyName}; path=/;samesite=None; secure`;
      utils.setCookie({ name: 'authUid', value: authUid });
      utils.setCookie({ name: 'authAt', value: authAt });
      utils.setCookie({ name: '.PRYANIKYAC', value: queryparams['.PRYANIKYAC'] });
      // document.cookie = `authUid=${authUid}; path=/;samesite=None; secure`;
      // document.cookie = `authAt=${authAt}; path=/;samesite=None; secure`;
      // document.cookie = `.PRYANIKYAC=${queryparams['.PRYANIKYAC']}; path=/;samesite=None; secure`;

      window.location.href = window.location.protocol + '//' + window.location.host + '/'
    }
    const pathname = props.location.pathname;
    this.checkRedirect(pathname);

    if (pathname !== '/' && !loadingRedirectIgnore.includes(pathname.substring(1).split('/')[0])) {
      this.redirectAfterLogin = pathname + this.searchToRedirect + props.location.hash;
    }
    // else {
    //   this.redirectAfterLogin = '/dash';
    // }

    // const userAgent = navigator.userAgent;
    this.mobile = isMobile()///(android)|(iphone)/i.test(userAgent);
    this.tablet = isTablet();
    this.platform = getPlatform()
    // if (userAgent.match(/macintosh/i)) {
    //   this.platform = 'Mac';
    // } else if (userAgent.match(/windows/i)) {
    //   this.platform = 'Windows';
    // } else if (userAgent.match(/linux/i)) {
    //   this.platform = 'Linux';
    // }
    const integatedMode = utils.getCookie('integrationMode');
    if (integatedMode === integrationModes.sharepoint && (window.self !== window.top || window.location.pathname.indexOf('/integrate') !== -1)) {
      const scriptv2 = document.createElement('script');
      // scriptv2.src = 'http://localhost:9999/pryaniky.rpc.js'
      // scriptv2.setAttribute('src', (window.location.hostname.indexOf('localhost') === -1 && window.location.pathname.indexOf('/NewDesign') !== -1 ? '/NewDesign' : '') + '/integration.js');
      // scriptv2.setAttribute('src', basename + '/integration.js');
      document.head.append(scriptv2);
    }
    this.props.layoutInit()
    const searchString = queryString.parse(this.props.location.search);
    if(typeof searchString.units === 'string') searchString.units = [searchString.units];
    this.props.updateContext('common', searchString);
    // this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: utils.searchObject(this.props.location.search),
    // })
    // out url change listner
    if ((window as any).onurlchange) this.props.history.listen((window as any).onurlchange);
  }

  private get defaultRedirect() {
    return this.props.homePage?.trim() || '/dash';
  }

  private get notfoundRedirect() {
    // return "/404?path=" + window.location.pathname
    return window.location.pathname === '/' ? this.defaultRedirect :  "/404?path=" + window.location.pathname
  }

  private get searchToRedirect() {
    let { search } = this.props.location;
    removeFromSearchToRedirect.map(rem => {
      if (search.includes(`?${rem}=`) || search.includes(`&${rem}=`))
        return search.substring(search.indexOf(rem), search.indexOf('&', search.indexOf(rem)) > 0 ? search.indexOf('&', search.indexOf(rem)) + 1 : search.length); // get search rem value, include '&' char if exist
      return '';
    }).forEach(remVal => {
      search = search.replace(remVal, '').replace(/&$/, '');
    })
    return search === '?' ? '' : search;
  }

  public componentDidMount() {
    const { analytics } = this.props;
    this.login();

    // window.addEventListener(WINDOW_EVTS.tab_focus, () => {
    //   this.props.dispatch(windowFocus())
    // })

    // window.addEventListener(WINDOW_EVTS.tab_blur, () => {
    //   this.props.dispatch(windowBlur())
    // })

    this.props.layoutMount()
    // if (analytics.social || analytics.lms) {
    //   window.onfocus = () => {
    //     WindowEvents.push(WINDOW_EVTS.tab_focus, {})
    //   }
    //   window.onblur = () => {
    //     WindowEvents.push(WINDOW_EVTS.tab_blur, {})
    //   }
    // }

  }

  public componentDidUpdate(pp: ILayoutProps) {
    const { analytics, homePage } = this.props;
    if (homePage !== pp.homePage) {
      const pathname = this.props.location.pathname;
      this.checkRedirect(pathname);
    }
  }

  public render() {
    const customClassNames = this.platform + ' ';
    // ждём кэш, не показываем спинер
    //INIT_DATA_RECEIVED_CACHE = false данные из кэша не получены
    //INIT_DATA_RECEIVED_CACHE_FAIL = false ошибки чтения кэша нету
    //loading = true загрузка ещё висит
    if (!this.props.lifeCycol.INIT_DATA_RECEIVED_CACHE && this.props.loading && !this.props.lifeCycol.INIT_DATA_RECEIVED_CACHE_FAIL) {
      return null
    }
    // показываем спинер
    //INIT_DATA_RECEIVED_CACHE = false данные из кэша не получены
    //INIT_DATA_RECEIVED_CACHE_FAIL = false получение из 
    //loading = true загрузка ещё висит
    if (!this.props.lifeCycol.INIT_DATA_RECEIVED_CACHE && this.props.loading && this.props.lifeCycol.INIT_DATA_RECEIVED_CACHE_FAIL) {
      return <LayoutLoading />;
    }
    if (!this.props.authUserNew && !this.props.lifeCycol.INIT_DATA_RECEIVED_CACHE) {
      if (!this.props.auth && window.location.hostname.indexOf('localhost') !== -1) {
        return (
          <DebugAuth />
        )
      }
      return <LayoutLoading />;
    }
    if (!this.props.auth) {
      return <LayoutLoading />;
      // return (
      //   <div className={cnLayout({}, [customClassNames])}>
      //     {/* <pre>
      //       {JSON.stringify(utils.getAuthCookies(), null, 2)}
      //     </pre> */}
      //     <ToastContainer />
      //     <Background />
      //     <Route path={'/logout'} render={pageComponents.pathRenderer(Redirect, { to: '/login' })} />
      //     <Route path={'/login'} render={pageComponents.pathRenderer(Login, { setBaseData: this.setBaseData })} />
      //     <Route path={'/forgot'} render={pageComponents.pathRenderer(Forgot)} />
      //     <Route path={'/resetpassword/:password/:uId'} render={pageComponents.pathRenderer(Reset)} />
      //   </div>
      // );
    }
    // если не в настройка флаг о мультисетях а в урле нет basename, то редиректим на тотже урл но с /sites/{companyName}
    if (this.props.onPremisesMultitenancy && window.location.pathname.indexOf('/sites/') === -1) {
      window.location.assign('/sites/' + this.props.authUserNew?.baseData?.baseNetwork?.name + window.location.pathname);
      return <></>;
    }
    if (this.redirect) {
      this.props.history.replace(this.redirectTo());
      return <></>;
    }
    // <Redirect to={this.redirectTo()} push={true} />;

    const from = getLocationStateFrom(this.props.location.state)
    const allowOpenNews = isAllowOpenNewsDialog(this.props.location.pathname, this.props.location.state)


    const switchLocation = allowOpenNews ? from : this.props.location
    
    const noMenu = this.mobile || this.tablet;
    const openedMenu = !noMenu && this.props.openedMenu;


    return (<ErrorBoundary global={true}>
      <EnvironmentContexWrapper
        isMobileOS={this.mobile}
        isTablet={this.tablet}
        isIFrame={this.isInFrame}
        osType={this.platform}
        isWebView={this.isWebView}
      >
        <InputBaseMui4 hidden style={{ display: 'none' }} />
        <div>
          {(window as any).SNOW && <SnowFlakes />}
          {window.PRN_SERVICE.support_tools && <SupportBar />}
          <ExternalActions />
          {/* <button onClick={this.changeLng}>
          set en lng
        </button> */}
          {/* <CustomScrollbars style={{ width: "100vw", height: "100vh" }}> */}
          <div
            id={cnLayout()}
            className={cnLayout(
              {
                wv: this.isInFrame,
                mobile: this.mobile,
                tablet: this.tablet,
                auth: true,
                openedMenu,
                main: true,
                noMenu,
                isAdmin: this.props.authUserNew?.baseData?.isAdmin
              },
              [customClassNames, 'CustomStyle']
            )}
          >
            <ToastContainer />
            {/* <pre>
              {JSON.stringify(utils.getAuthCookies(), null, 2)}
            </pre> */}
            {/* {this.redirectAfterLogin && this.redirectAfterLogin !== "/" && <Redirect to={this.redirectTo(true)} />} */}
            {!this.isInFrame && (
              <>
                <Route path={"(" + pageComponents.getPagesRegExp("background") + ")"} component={Background} />
                <Route path={"(" + pageComponents.getPagesRegExp("header") + ")"} component={HeaderMui} />
                <Route path={"(" + pageComponents.getPagesRegExp("menu") + ")"} component={ReduceredLeftMenu} />
                {/* <Route path={"(" + pageComponents.getPagesRegExp("menu") + ")"} component={ReduceredLeftMenu} /> */}
                {/* <Route path={'(' + pageComponents.getPagesRegExp('projectHeader') + ')'} component={ProjectLogo} /> */}
              </>
            )}

            <Switch location={switchLocation}>
              {routes.filter(r => this.props.authUserNew.baseData.isAdmin || !(r.accessOnlyAdmin || false)).map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    {...route.props}
                    render={pageComponents.pathRenderer(route.item.c, route.item.props)}
                  />
                );
              })}
              <Route path={urls.logout} render={pageComponents.pathRenderer(Logout, { cb: () => { this.redirectAfterLogin = '/dash' } })} />

              {/* start testing components */}
              <Route path={"/srtest"} render={pageComponents.pathRenderer(Srtest)} />
              <Route path={"/wikitp"} render={pageComponents.pathRenderer(WikiTP)} />
              <Route path={"/test_code"} render={pageComponents.pathRenderer(CodeTest)} />
              {/* <Route path={'/i18ntest'} render={pageComponents.pathRenderer(i18nText)} /> */}
              <Route path={'/sortable'} render={pageComponents.pathRenderer(SortablePage)} />
              <Route
                path="/test"
                render={() => (
                  <ListRound
                    cut
                    className="Test-List"
                    count={16}
                    size={56}
                    items={Array.from({ length: 40 }, (_, id) => ({
                      id,
                      imgUrl: "https://via.placeholder.com/100",
                      title: "Placeholder"
                    }))}
                  />
                )}
              />

              <Route path={"/learningblock"} render={pageComponents.pathRenderer(LearningBlock)} />
              {/* end testing components */}
              <Redirect to={this.notfoundRedirect} push={false} />
            </Switch>

            {allowOpenNews && <Route path={urls.news} children={<NewsDialogComponent from={from} />} />}

            <div id="AppFooter" />
            <div id="LayoutRightMenu"></div>
            <div id="rootModal" />
            <div id="Dialog-root" />
            <div id="RootModal-l1" />
            {/* <TopButton /> */}
            <AdditionalMenu />
          </div>
          {/* </CustomScrollbars> */}
        </div>
      </EnvironmentContexWrapper>
    </ErrorBoundary>);
  }

  private get urlSearchObj() {
    return this.props.location.search.substring(1).split('&').reduce((acc, cur) => {
      const cur_data = cur.split('=');
      acc[cur_data[0]] = cur_data[1];
      return acc;
    }, {} as any)
  }

  private login = () => {
    // const { authAt, authUid, companyName } = utils.getAuthCookies();
    const { authAt, authUid } = utils.getAuthCookies();
    const integatedMode = utils.getCookie('integrationMode');
    if (authAt && authUid && window.self !== window.top && integatedMode === integrationModes.sharepoint) {
      timer = setInterval(() => {
        if (window.PryanikyRpc) {
          clearInterval(timer);
          const fid = uuid();
          const initFunc = (rpcInit: boolean) => {
            if (!rpcInit) {
              this.setBaseData();
            } else {
              const fid2 = uuid();
              window.PryanikyRpc.init().functions[fid2] = this.setBaseStore;
              window.PryanikyRpc.message.post({ name: MESSAGES_TYPES.get, type: 'base_store', to_frame: this.urlSearchObj.frame_id, function_id: fid2 });
            }
          };
          window.PryanikyRpc.init().functions[fid] = initFunc;
          window.PryanikyRpc.message.post({ name: MESSAGES_TYPES.get, type: 'init', to_frame: this.urlSearchObj.frame_id, function_id: fid });
        }
      }, 100);
    }
    else
      if (authAt && authUid) {
        // if (
        //   window.location.hostname.indexOf('localhost') === -1 &&
        //   window.location.hostname.indexOf('azurewebsites.net') === -1 &&
        //   this.props.auth 
        //   // &&
        //   // window.location.hostname !== companyName + '.app.pryaniky.com'
        // ) {
        //   utils.unsetCookies();
        //   const pryanac = utils.getCookie('.PRYANIKYAC');
        //   // window.location.assign(
        //   //   `//${companyName}.app.pryaniky.com/login?auth=${companyName};${authUid};${authAt}&.PRYANIKYAC=${pryanac}`
        //   // );
        // }
        this.setBaseData();
      } else {
        this.props.changeVm('loading', false);
        if (window.location.hostname.indexOf('localhost') === -1) {
          if (this.redirectAfterLogin && this.redirectAfterLogin !== '/') utils.setCookie({ name: 'reload-redirect', value: this.redirectAfterLogin });
          window.location.assign(basename.replace('/wv', '').replace('/newdesign', '') + '/login');
        }
        // if (window.location.hostname.indexOf('localhost') !== -1) {
        //   const cn = prompt('введитe имя компании для авторизации');
        //   window.location.assign(`https://${cn}.prerelease.pryaniky.com/login?debugAuthRedirect=http://${window.location.host}${this.redirectAfterLogin || ''}`);
        // } else {
        //   if (this.redirectAfterLogin && this.redirectAfterLogin !== '/') utils.setCookie({ name: 'reload-redirect', value: this.redirectAfterLogin });
        //   // document.cookie = `reload-redirect=${this.redirectAfterLogin}; path=/;samesite=None; secure`;
        //   window.location.assign(basename.replace('/wv', '').replace('/newdesign', '') + '/login');
        // }
      }
  };

  private setBaseStore = (data: any) => {
    this.props.setFilters((data.appSettings?.searchFilters as any || []).filter((filter: any) => filter.showAtSearchPage));
    this.props.changeVm('', data);
  }

  /**
   * выполняется после получения пользователя и настроек компании.
   */
  private afterInitApp = () => {
    ChatService.init();

    // this.props.getUserHieararchyFromServer({ skipCount: 0, count: 30 })

    // this.props.getAllowGroupsTransfer();
  }

  private setBaseDataWork = (d: any, callback?: () => void) => {
    // const authUser = {
    //   baseData: d.data.baseData,
    //   extData: d.data.extData,
    //   profileData: d.data.profileData,
    // };



    /**костыль для firstlogon */
    const userId = d.data.baseData.id;
    const fl: string = utils.getCookie(`firstLogon${userId}`);
    if (fl !== null && fl === 'false' && d.data.baseData.isFirstLogon) d.data.baseData.isFirstLogon = false;

    const value = {
      // authUser,1
      appSettings: d.data.appSettings,
      appDesign: d.data.appDesign,
      chatSettings: d.data.chat,
      disabledThanks: false,
      //  this.utils.getCookie('companyName').toLowerCase() === 'dns',
      auth: true,
      loading: false,
    };
    utilsProj.setCustomDesign(d.data.appDesign);

    if (window.location.href.indexOf('stylePreview=true') !== -1) {

      let dt = localStorage.getItem('stylePreview');
      if (dt) {
        const stylePreviewData = JSON.parse(dt) as any;

        const { primaryColor1, primaryColor2, primaryColor3, additionalColor1, additionalColor2, backgroundColor, backgroundImageUrl, favIconImageUrl } = stylePreviewData;
        let cssText = stylePreviewData.customCSS?.replace(/\\r+/g, '')?.replace(/\\n+/g, '');
        cssText += ' ' + generateCssFromMainColor({ primaryColor1, primaryColor2, primaryColor3, additionalColor1, additionalColor2, backgroundColor, backgroundImageUrl });
        const styleTagId = 'stylePreview';
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.textContent = cssText;
        style.id = styleTagId;
        const old = document.getElementById(styleTagId);
        if (old) old.remove();
        document.body.append(style);

        // set favicon preview
        const link: any = document.querySelector("link[rel~='icon']");
        link.href = favIconImageUrl;
      }
    } else {
      let dt = localStorage.getItem('stylePreview');
      if (dt) localStorage.removeItem('stylePreview');
    }

    // this.setCustomDesign(d.data.appDesign);
    // this.redirectAfterLogin = this.redirectAfterLogin || '/dash';

    this.props.loadingSetStatus('localization');
    this.props.i18n_set_language(d.data.baseData.language);

    this.props.setAuthUser(d.data.baseData.id);

    const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
    const normalizedUserInfoFields = normalize(d.data.profileData.userInfoFields, [field]);
    d.data.profileData.userInfoFieldsOriginal = d.data.profileData.userInfoFields;
    d.data.profileData.userInfoFields = normalizedUserInfoFields;
    const normalizedUserHeadFields = normalize(d.data.profileData.userHeadFields, [field]);
    d.data.profileData.userHeadFieldsOriginal = d.data.profileData.userHeadFields;
    d.data.profileData.userHeadFields = normalizedUserHeadFields;
    const normalizedUserListFields = normalize(d.data.baseData.userListFields, [field]);
    d.data.baseData.userListFieldsOriginal = d.data.baseData.userListFields;
    d.data.baseData.userListFields = normalizedUserListFields;

    // this.props.loadCompanyPostTypes();

    if (
      d.data.profileData.showMyNewsByDefault &&
      (
        !this.props.commonContext.newsTypes ||
        this.props.commonContext.newsTypes === 'all'
      )
    )
      this.props.updateContext('common', {
        filter: [{ ...myTimelineFilter, title: i18n.t('pryaniky.filter.feed.myfeed') }],
        newsTypes: "myfeed"
      });



    // this.props.loadVirtUsers();

    this.props.changeUser(d.data.baseData.id, {
      baseData: d.data.baseData,
      extData: d.data.extData,
      profileData: d.data.profileData,
    });

    if (d.data.baseData.isFirstLogon && value.appSettings.enabledModules.welcome) {
      this.redirect = '/welcome';
      this.props.history.push('/welcome');
    }

    this.setBaseStore(value);
    // this.props.changeVm('', value);
    this.afterInitApp()

    const integatedMode = utils.getCookie('integrationMode');
    if (window.self !== window.top && integatedMode === integrationModes.sharepoint) {
      timer = setInterval(() => {
        if (window.PryanikyRpc) {
          clearInterval(timer);
          window.PryanikyRpc.message.post({ name: MESSAGES_TYPES.initBaseStore, data: value }, 'top');
          window.PryanikyRpc.message.post({ name: 'init' }, 'top');
        }
      }, 100);
    }
    if (callback) callback();
    this.props.initCompleted()
    this.props.loadingSetStatus('');
  }
  private setBaseData = (callback?: () => void) => {
    this.props.initBegin()
    this.props.loadingSetStatus('user');
    let ignoreCache = false;
    CacheHelper.get('initData', `${this.utils.getCookie('authUid')}?fullData=true?appSettings=true`)
      .then((value) => {
        if (value && !ignoreCache) {
          this.props.initFromCache()
          this.setBaseDataWork(utils.clone(value), callback)
        } else {
          this.props.initFromCacheFail()
        }
      })
      .catch(e => {
        console.warn('CacheHelper, read initData:', e)
        this.props.initFromCacheFail()
      })

    utils.API.users.byId(this.utils.getCookie('authUid'), { fullData: true, appSettings: true/*, include: 'lms' */ }).r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        ignoreCache = true;

        const serviceSessionId = d.data.extData.serviceSessionId
        if (utils.isEmptyGuid(serviceSessionId)) {
          utils.deleteAllCookies();
        }

        CacheHelper.set('initData', `${this.utils.getCookie('authUid')}?fullData=true?appSettings=true`, utils.clone(d))
          .then((value) => console.log(value))
          .catch(e => console.warn('CacheHelper, write initData:', e))
        this.props.initFromServer()
        this.setBaseDataWork(d, callback)
      } else {
        // если пользователь не найден и есть базовая подсеть авторизованного пользователя, тьо переадресуем его туда
        if (d.error_code === 1000 && d.data && typeof d.data === 'string') window.location.assign(`/sites/${d.data}/dash`)
      }
    });
  };

  private redirectTo = (afterLogin?: boolean) => {
    const url = afterLogin ? this.redirectAfterLogin : this.redirect;
    afterLogin ? (this.redirectAfterLogin = '') : (this.redirect = '');
    return url as string;
  };

  private checkRedirect = (pathname: string) => {
    const hasAuthCookies = Object.values(utils.getAuthCookies()).filter(Boolean).length === 2;
    if (hasAuthCookies) {
      if (pathname === '/login' || pathname === '/') this.redirect = this.defaultRedirect;
    } else if (!loadingRedirectIgnore.includes(pathname.substring(1).split('/')[0])) {
      this.redirect = '/login';
    }
    if (
      hasAuthCookies &&
      utils.getCookie('reload-redirect') &&
      utils.getCookie('reload-redirect').toString() !== 'undefined' &&
      utils.getCookie('reload-redirect').toString() !== ''
    ) {
      this.redirect = utils.getCookie('reload-redirect');
      utils.setCookie({ name: 'reload-redirect', value: '', props: { expires: new Date(0) } });
      this.notReSetRedirectCookie = true;
    }
  };

  private checkAuth = () => {
    const authMatch = window.location.search.match(/auth=([^&]+)&*/);
    if (authMatch) {
      const auth = authMatch[1].split(';').map(decodeURIComponent);
      utils.setCookie({ name: 'authAt', value: auth[0] });
      utils.setCookie({ name: 'authUid', value: auth[1] });
      // utils.setCookie({ name: 'companyName', value: auth[2] });
      // document.cookie = 'authAt=' + auth[0] + '; path=/; samesite=None; secure';
      // document.cookie = 'authUid=' + auth[1] + '; path=/; samesite=None; secure';
      // document.cookie = 'companyName=' + auth[2] + '; path=/; samesite=None; secure';
    }
  };
}

export const Layout = connect(
  mapStateToProps,
  mapDispatchToProps(LayoutActionsToProps)
)(LayoutPresenter);
