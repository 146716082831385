import React, { FC, PropsWithChildren, useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { SSurveyQuestion } from "utils/src/BaseTypes/survey";
import { cnSurveyPassing } from "../SurveyPassing.index";
import { QuestionBox, QuestionDescription, QuestionMultiInfo, QuestionTitle, RequiredBox } from "./SurveyQuestion.styled";
import MDRender from 'uielements/src/CommonmarkRender';
import { i18n, Translate } from 'localization';
import { useAnswer } from "../AnswerItem/AnswerItem.index";

import { useSelector } from "react-redux";
import { getSurveyStateByKey } from "redux/sagas/Surveys/selectors";
import { validateAnswers } from "redux/sagas/Surveys/utils.surveys";
import { AlertMessage } from "muicomponents/src/AlertMessage/AlertMessage";

const useQuestionError = (question: SSurveyQuestion) => {

    const data = useSelector(getSurveyStateByKey('surveyPassingAnswers', 'firstQuestionError'));

    const hasError = useMemo(() => {
        if(!data.firstQuestionError) return false;
        const isValid = validateAnswers(question, data.surveyPassingAnswers);
        return !isValid;
    }, [data.firstQuestionError, data.surveyPassingAnswers[question.id], question.id]);

    return {
        scrollToBox: data.firstQuestionError === question.id,
        showError: !!data.firstQuestionError && hasError
    };
};

export const SurveyQuestion: FC<PropsWithChildren<{
    question: SSurveyQuestion
}>> = ({
    question,
    children
}) => {

    const isMandatory = question.allowSkip;

    const {
        answersIds,
        numericAnswer,
        text,
        setAnswer
    } = useAnswer(question.id);
    
    const passDefaultValueIsMandatory = useCallback(() => {
        if(isMandatory) {
            switch(question.qtype) {
                case 'Slider': 
                    if(question.sliderSymbols !== 'stars') {
                        const answer = question.answers[0];
                        setAnswer({
                            answersIds: [answer.id],
                            numericAnswer: answer.numericMin
                        });
                    }
                    break;
                case 'Sorting':
                    setAnswer({
                        answersIds: question.answers.map(el => el.id)
                    });
                    break;
            }
        }
    }, [isMandatory, question.qtype]);

    useLayoutEffect(() => {
        passDefaultValueIsMandatory();
    }, []);

    const {
        scrollToBox,
        showError
    } = useQuestionError(question);

    const boxRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if(scrollToBox) {
            boxRef.current?.scrollIntoView({ block: 'center', behavior: "smooth" });
        }
    }, [scrollToBox]);

    return (
        <QuestionBox ref={boxRef} className={cnSurveyPassing('Question')}>
            <QuestionTitle className={cnSurveyPassing('Question-Title')} variant="h6">
                <span>
                    {question.position + 1}.&nbsp;
                    <MDRender source={question.text || ''} textCut={false} type={'inline'} />
                </span>
                {
                    isMandatory &&
                    <RequiredBox>
                        *
                    </RequiredBox>
                }
            </QuestionTitle>
            {
                question.description &&
                <QuestionDescription className={cnSurveyPassing('Question-Text')}>
                    <MDRender source={question.description || ''} textCut={false} />
                </QuestionDescription>
            }
            {
                question.qtype === 'Multi' &&
                <QuestionMultiInfo className={cnSurveyPassing('Question-Multi')}>
                    <Translate i18nKey={'pryaniky.survey.manyAnswers'} values={{ minAnswerVariants: question.minAnswerVariants, maxAnswerVariants: question.maxAnswerVariants }} />
                </QuestionMultiInfo>
            }
            {children}
            {
                showError &&
                <AlertMessage variant="outlined" severity="error"><Translate i18nKey={'pryaniky.survey.question.isRequired'} /></AlertMessage>
            }
        </QuestionBox>
    );
};