import { connect } from 'react-redux';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { IShopItem } from 'utils/src/requests/models/api.shop';
import { HelpTooltip } from 'uielements/src';
import { i18n, Translate } from 'localization';
import {
  mapStateToProps,
  IShopWidgetStateProps,
  cnShopWidget,
  IShopWidgetProps,
  IShopWidgetState,
} from './Shop-Widget.index';
import './Shop-Widget.scss';
import { toast } from 'react-toastify';
import { ProductSkeleton } from 'blocks/Skeletons/Shop/Product-Skeleton';
import './Shop-Widget.scss';
import { currentUserMinusMyThanksCount } from 'utils/src/CommonRedux/users/actions'
import { bindActionCreators, Dispatch, Action } from 'redux';
import OrderBlock from '../OrderBlock'
import { countMissingThanks, getPriceData } from '../utils'
import { Link } from 'muicomponents/src/Link/Link'
import { Button } from 'muicomponents/src/Button/Button'
import { Avatar } from 'uielements/src'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import IconMUI from '@material-ui/core/Icon';
import noData from 'assets/svg/NoData.svg'
import { GUID_EMPTY } from 'utils/src/constants.prn';
const createEmptyProduct = (): IShopItem => ({
  id: -1,
  availableCount: 0,
  categories: [],
  delivery: "",
  desc: "",
  images: [],
  imgUrls: [],
  mainImgId: GUID_EMPTY,
  mainImgUrl: '',
  isGift: false,
  externalServiceType: 'None',
  isUnlimitedProduct: false,
  isVirtualProduct: false,
  multiOrderItem: false,
  maxItemsInOrder: 99,
  name: "",
  thanksPrice: 0,
  thanksPriceTxt: "",
  maxVirtCurrencyPayment: -1,
  minVirtCurrencyPayment: -1,
  isActive: true,
  characteristics: [],
  additionalFields: null
})

type IDispatchProps = ReturnType<typeof mapDispatchToProps>

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: any) => bindActionCreators({
  currentUserMinusMyThanksCount
}, dispatch)

export class ShopWidgetPresenter extends React.Component<IShopWidgetProps & IDispatchProps, IShopWidgetState> {
  public el: Element | Text | null;
  public state: IShopWidgetState = {
    products: [],
    loading: false,
    comment: '',
    showModal: false,
    selectedProduct: createEmptyProduct(),
    isHidePricesAndButtonBuy: false,
    isHideButtonBuy: false,
    maxOrdersCount: 0,
    maxOrdersPeriod: '',
    ordersCount: 0,
    shopClosedText: ""
  };
  protected mounted: boolean = false;

  public componentDidMount() {
    this.mounted = true;
    this.setState({ loading: true });
    utils.API.shop.getProducts({ mainPage: true, count: 5 }).r.then((response) => {

      this.setState({ loading: false })
      if (!this.mounted) return;
      if (utils.checkResponseStatus(response)) {
        this.setState({
          products: response.data,
          isHidePricesAndButtonBuy: response.isHidePricesAndButtonBuy,
          isHideButtonBuy: response.isHideButtonBuy,
          maxOrdersCount: response.maxOrdersCount,
          maxOrdersPeriod: response.maxOrdersPeriod,
          ordersCount: response.ordersCount,
          shopClosedText: response.shopClosedText
        });
      }
    });
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render() {
    if (this.state.loading)
      return Array.from({ length: 6 }, (_, i) => <ProductSkeleton className={cnShopWidget('Item')} key={i} />);


    const { tag: TagName = 'div', className = '', myThanksCount, currencyFormats, settings, edit  } = this.props;
    const {
      isHidePricesAndButtonBuy,
      isHideButtonBuy,
      maxOrdersCount,
      maxOrdersPeriod,
      ordersCount,
      shopClosedText
    } = this.state;
    if (edit && !this.state.products.length ) return <TagName {...this.props} hide={false} className={cnShopWidget("Item", [className])}></TagName>;
    if (settings?.hideIfNoData && !this.state.products.length) return null;
    if (!settings?.hideIfNoData && !this.state.products.length) return <TagName {...this.props} hide={false} className={cnShopWidget("Item", [className])}>
      <div className={cnShopWidget('Header-Title')}>
        {i18n.t('pryaniky.widgets.settings.type.shop.noData.title')}
      </div>
      <Avatar precentage={100} shape={'rectangle'}
        imgUrl={noData}
      />
      <span className={cnShopWidget('Header-Title-Text')}>{shopClosedText !== "" ? shopClosedText : i18n.t('pryaniky.widgets.settings.type.shop.noData.text')}</span>
    </TagName>;
    return (

      <TagName {...this.props} hide={false} className={cnShopWidget({}, [className])}>
        {/* <        MakeOrderDialog
          data={this.state.selectedProduct}
          isShown={this.state.showModal}
          onConfirm={this.orderItem}
          onClose={this.closeModal}
        /> */}
        {this.state.products.map(product => {
          const { id, characteristics, thanksPrice, thanksPriceTxt } = product


          const missingThanks = countMissingThanks(thanksPrice, myThanksCount);
          const { price, priceCurrency } = getPriceData(thanksPriceTxt)

          return (
            <div key={product.id} className={cnShopWidget('Item')}>
              <Link to={`/shop/product/${product.id}`} className={cnShopWidget('Header')}>
                <h4 className={cnShopWidget('Header')}>{product.name}</h4>
              </Link>

              <Link to={`/shop/product/${product.id}`} className={cnShopWidget('Img')}>
                <Avatar precentage={100} shape={'rectangle'}
                  imgUrl={product.mainImgUrl}
                />
                {/* <img className={cnShopWidget('Img')} src={product.mainImgUrl} alt={product.name} /> */}


                {/* <div className={cnShopWidget('Info')}>
                <Link to={`/shop/product/${product.id}`} className={cnShopWidget('Header')}>
                  <h4 className={cnShopWidget('Header')}>{product.name}</h4>
                </Link> */}
                {/* <div className={cnShopWidget('Description')} dangerouslySetInnerHTML={{ __html: product.desc }} /> */}
                {/* </div>  */}

                {!isHidePricesAndButtonBuy && <div className={cnShopWidget('Price')}>
                  <IconMUI color={"action"} className={cnShopWidget('Icon')} > <AccountBalanceWalletIcon color={"action"} /> </IconMUI>
                  <div className={cnShopWidget('Price-PriceText')}>
                    <span >{i18n.t('pryaniky.shop.price')}</span>
                    <span>
                      <b className={cnShopWidget('PriceValue')}>
                        {price}
                        {' ' + priceCurrency}
                      </b>
                    </span>
                  </div>
                </div>}
              </Link>
              {/* {!isHideButtonBuy && <div className={cnShopWidget('Footer', { missing: missingThanks > 0 })}> */}
              {/* <OrderBlock
                  {...{
                    characteristics,
                    currencyFormats,
                    id,
                    maxOrdersCount,
                    maxOrdersPeriod,
                    myThanksCount,
                    ordersCount,
                    showDialog: () => DialogMakeOrder({ data: this.state.selectedProduct }).then((value) => this.orderItem(value)).catch(() => { }),
                    // showDialog: this.selectItem(product),
                    thanksPrice,
                    disableOrder: characteristics.length === 0,
                  }}
                /> */}
              {/* {missingThanks > 0 ? (
                  <>
                    {i18n.t('pryaniky.shop.missing')}
                    <b>{missingThanks}</b>
                    {i18n.t('pryaniky.shop.count', {
                      count: missingThanks,
                      single: this.props.currencyFormats.currencyNameGenitiveSingular,
                      plural: this.props.currencyFormats.currencyNameGenitivePlural,
                    })}
                  </>
                ) : (
                    <HelpTooltip help={isNotCanBuy ? `${i18n.t('pryaniky.shop.list.maxOrderCount', { maxOrdersCount })} ${i18n.t(`pryaniky.shop.main.periods.${maxOrdersPeriod}`)}` : ''}>
                      <Button className={cnShopWidget('Order')} disabled={isNotCanBuy} padding="lg" main={true} onClick={this.selectItem(product)}>
                        {i18n.t('pryaniky.shop.order')}
                      </Button>
                    </HelpTooltip>
                  )} */}
              {/* </div>} */}
            </div>
          );
        })}
        <Button to={'/shop'} variant={'contained'} className={cnShopWidget('ToShop', ['rounded'])} children={i18n.t('pryaniky.shop.viewallproducts')} />
      </TagName>
    );
  }
  // private selectItem = (selectedProduct: IShopItem) => () =>
  //   this.setState({ showModal: true, selectedProduct, comment: '', presentFor: void 0, });
  // private handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
  //   this.setState({ comment: e.currentTarget.value });
  // private handleForChange = (presentFor: any) => this.setState({ presentFor });
  // private closeModal = () => this.setState({ showModal: false });

  private orderItem = (data: any) => {
    // if (!this.state.selectedProduct) return;
    // this.setState({ showModal: false });
    // const { id, thanksPrice } = this.state.selectedProduct;
    const opts = {
      // presentFor: data.presentFor && data.presentFor.id,
      presentFor: data.presentFor ? [{ id: data.presentFor.id }] : undefined,
      comment: data.comment,
      additionalFields: data.additionalFields
    };
    utils.API.shop.order(data.id, opts).r.then((response: IBasicResponse) => {
      if (utils.checkResponseStatus(response)) {
        // this.props.changeVm('authUser.extData.mythanksCount', this.props.myThanksCount - thanksPrice);
        this.props.currentUserMinusMyThanksCount(data.thanksPrice)
        this.setState({ presentFor: void 0, comment: '', selectedProduct: createEmptyProduct(), showModal: false });
        toast.success(i18n.t('pryaniky.shop.order.success'));
      } else {
        toast.error(response.error_text);
      }
    });
  };
}

export const ShopWidget = connect<IShopWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(ShopWidgetPresenter);
