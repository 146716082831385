import { v1 as uuid } from "uuid";
import { achievements } from './achievements';
import { badge } from './badge';
import { badges } from './badges';
import { board } from './board';
import { calendar } from './calendar';
import { common } from './common';
import { competitions } from './competitions';
import { creativetasks } from './creativetasks';
import { dash } from './dash';
import { dashboard } from './dashboard';
import { events } from './events';
import { external } from './external';
import { forbidden2005 } from './forbidden2005';
import { forbidden2007 } from './forbidden2007';
import { group } from './group';
import { groups } from './groups';
import { ideas } from './ideas';
import { journal } from './journal';
import { lms } from './lms';
import { news } from './news';
import { notices } from './notices';
import { notifiSettings } from './notifiSettings';
import { orders } from './orders';
import { pageGrid } from './pageGrid';
import { pageHeader } from './pageHeaders';
import { pages } from './pages';
import { polls } from './polls';
import { quests } from './quests';
import { questsmoderate } from './questsmoderate';
import { rating } from './rating';
import { secretSantaPage } from './secretSantaPage';
import { settingsDesign } from './settingsDesign';
import { shop } from './shop';
import { shopMui } from './shopMui';
import { statistics } from './statistics';
import { tag } from './tag';
import { tagBlock } from './tagBlock';
import { user } from './user';
// redesigned user page
import { userNew } from './userNew';
import { users } from './users';
import { vacancies } from './vacancies';
import { vacancy } from './vacancy';
import { welcome } from './welcome';
import { widgets_horizontal_v2 } from './widgets_horizontal_v2';
import { widgets_v2 } from './widgets_v2';
import { widgets } from './widgets';
import { wikilist } from './wikilist';
import { workflows } from './workflows';
import { tasks } from './tasks';

// pages for develop
import { appsDebug } from './_appsDebug';

// page for tests
import { wikiTP } from "./_wikiTP";
import { learninigBlock } from './_learninigBlock';
import { widgetdebug } from "./_widgetdebug";
import { widgetresponsive } from "./_widgetresponsive";


export const guids = <T>(keys: Array<keyof T>): T => keys.reduce((acc, cur) => ({ ...acc, [cur]: uuid() }), {} as T);

const pagesStructures = {
  widgetdebug,
  widgetresponsive,
  appsDebug,
  common,
  dash,
  group,
  groups,
  user: userNew,
  useralfa: userNew,
  userbeta: userNew,
  users,
  badge,
  rating,
  tag,
  shop,
  shopMui,
  orders,
  pages,
  lms,
  news,
  competitions,
  ideas,
  widgets,
  wikiTP,
  learninigBlock,
  events,
  polls,
  creativetasks,
  questsmoderate,
  calendar,
  settingsDesign,
  notices,
  wikilist,
  board,
  journal,
  notifiSettings,
  welcome,
  quests,
  workflows,
  dashboard,
  secretSantaPage,
  statistics,
  badges,
  widgets_v2222: widgets_v2,
  widgets_horizontal_v22: widgets_horizontal_v2,
  forbidden2005,
  forbidden2007,
  vacancies,
  vacancy,
  external,
  achievements, 
  tagBlock,
  pageGrid,
  tasks
}

type IpagesStructures = typeof pagesStructures;

interface IPagesStructures extends IpagesStructures {
  [s: string]: any;
}

export default pagesStructures as IPagesStructures