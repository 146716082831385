import * as React from 'react';
import { withBemMod } from '@bem-react/core';
import { i18n, Translate } from 'localization';
import { cnBody } from '../Modal-Body.index';
import { IBodyTypeAchievementProps, IBodyTypeAchievementState } from './Modal-Body_type_achievement.index';
// import { Input } from 'uielements/src/Input/Input';
import { CheckboxInput } from 'uielements/src';
import { SearchInput } from 'uielements/src';
// import { Button } from 'uielements/src/Button/Button';
// import { Icon } from 'uielements/src/Icon/Icon';

import './Modal-Body_type_achievement.scss';

class NewBody extends React.Component<IBodyTypeAchievementProps, IBodyTypeAchievementState> {
  public el: HTMLElement | null;

  constructor(props: IBodyTypeAchievementProps) {
    super(props);
    this.functionBind = this.functionBind.bind(this);
    this.functionBind();
    this.state = {
      selected: this.props.parent.state.data.selected || undefined,
      achievements: this.props.parent.state.data.achievements
    };
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', className = '' } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, [className])}>
        <SearchInput onChange={this.search} placeholder={i18n.t('pryaniky.modal.achievement.name')} />
        <div className={cnBody('Part')}>
        {
          this.state.achievements.map((achievement: {[s: string]: any}) => {
            return (
              <div key={achievement.id}>
              {
                !achievement.hide && <div className={cnBody('Achievement')} children={achievement.name + ':'} />
              }
              {/* {
                !achievement.hide && <CheckboxInput className={cnBody('Achievement')}onChange={this.goalSelect.bind(this, achievement.id)} checked={achievement.selected} text={achievement.name + ' ' + (achievement.bonus > 0 ? '+' + achievement.bonus : '')} />
              }                 */}
                {
                  achievement.goals.map((goal: {[s: string]: any}) => {
                    if(goal.hide) return undefined;
                    return <CheckboxInput className={cnBody('Goal')} key={goal.id} onChange={this.goalSelect.bind(this, achievement.id, goal.id)} checked={goal.selected} text={goal.name + ' ' + (goal.bonus > 0 ? '+' + goal.bonus : '')} />
                  })
                }
              </div>
            )
          })
        }
        </div>
      </TagName>
    )
  }

  private functionBind() {
    this.search = this.search.bind(this);
    this.goalSelect = this.goalSelect.bind(this);
  }

  private search(value: string) {
    this.state.achievements.forEach((achievement: {[s: string]: any}) => {
      let countHideGoals = 0;
      achievement.goals.forEach((goal: {[s: string]: any}) => {
        if(goal.name.toLowerCase().indexOf(value.toLowerCase()) === -1) {
          goal.hide = true;
          countHideGoals += 1;
        } else {
          goal.hide = false;
        }
        if(value === '') goal.hide = false;
      });
      if(countHideGoals === achievement.goals.length) {
        achievement.hide = true;
      } else {
        achievement.hide = false;
      }
    });
    this.setState({achievements: this.state.achievements});
  }

  private goalSelect(achievementId: number, goalId?: number) {
    if(typeof(goalId) !== 'number') {
      this.state.achievements.forEach((achievement: {[s: string]: any}) => {
        if(achievement.selected && achievement.id !== achievementId) achievement.selected = !achievement.selected;
        if(achievement.id === achievementId) achievement.selected = !achievement.selected;
        achievement.goals.forEach((goal: {[s: string]: any}) => {
          goal.selected = false;
        });
      });
    } else {
      this.state.achievements.forEach((achievement: {[s: string]: any}) => {
        achievement.selected = false;
        if(achievement.id === achievementId) {
          achievement.goals.forEach((goal: {[s: string]: any}) => {
            if(goal.selected && goal.id !== goalId) goal.selected = !goal.selected;
            if(goal.id === goalId) goal.selected = !goal.selected;
          });
        }
      });
    }
    this.setState({achievements: this.state.achievements}, () => {
      this.props.parent.state.data.achievements = this.state.achievements;
      this.props.parent.validateData();
    });
  }

}

export const withBodyTypeAchievement = withBemMod<any>(
  cnBody(),
  { type: 'achievement' }, 
  (Presenter) => (
    (props: IBodyTypeAchievementProps) => (
      <NewBody {...props} />
      // <Presenter {...props}/>
    )
  )
);