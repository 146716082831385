import { IPollsPropsType, IPollsStateType, mapStateToProps, mapDispatchToProps, TStateProps } from './Polls.index';
import React, { FC } from 'react';
import * as utils from "utils/src/utils";
import { connect } from 'react-redux';
import './Polls.scss';
import { Answer } from './Answer/Answer';
import { FreeAnswer } from './FreeAnswer/FreeAnswer';
import { AnswerList } from './AnswerList/AnswerList';
import { Answer as AnswerType } from './Polls.types';
import { i18n, Translate } from 'localization';
import { NewAnswerForm } from './NewAnswerForm/NewAnswerForm';
import { answerChange } from './Utils';
import { Button } from 'uielements/src'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentView from 'News/containers/ContentView'
import ContentLayout from 'News/components/ContentLayout'
import { SliderViewPolls } from './SliderViewPolls/SliderViewPolls'
import { formatDateSTD } from 'utils/src/utils.dates';

export class PollsPresenter extends React.PureComponent<IPollsPropsType & TStateProps, IPollsStateType>  {

    constructor(props: IPollsPropsType & TStateProps) {
        super(props)

        const { fromCache = false, poll } = props;
        this.state = {
            completed: fromCache ? true : poll.answers.some((c: any) => c.answeredByMe === true)
        }
    }

    componentDidUpdate(prevProps: IPollsPropsType & TStateProps) {
        const data = this.props;
        if (prevProps.fromCache && !data.fromCache) {
            this.setState({ completed: data.poll.answers.some((c: any) => c.answeredByMe === true) })
        }
    }

    public render() {
        const { id, poll, fromCache } = this.props
        const { answers, maxCount, showParticipants, expires, freeAnswer, answerVariants, showResultsBeforeVote, disableRevote } = poll;
        const { completed } = this.state

        let answerAllow = (new Date(expires)).getTime() < (new Date()).getTime();
        const showDeadline = answerAllow
        answerAllow = disableRevote ? (showDeadline ? true : completed) : answerAllow
        const showComplete = disableRevote && !answerAllow && answers.some((c: any) => c.answeredByMe === true)

        return (
            <NewsView className={'type-7'}>
                {showDeadline && <h4 className={'Poll-ExpiresEnd'}>{i18n.t('pryaniky.post.polls.end')}</h4>}
                {(completed && disableRevote && !fromCache) && <h4 className={'Poll-ExpiresEnd'}>{i18n.t('pryaniky.post.polls.completed')}</h4>}


                <ContentLayout>
                    <TextRender />

                    <p className={'Poll-ExpiresEndDate'}>{i18n.t('pryaniky.post.polls.end.date')} {formatDateSTD(expires, true)}</p>
                </ContentLayout>

                <AnswerList
                    answers={answers}
                    additional={
                        <>
                            {freeAnswer && !answerAllow ? <FreeAnswer>
                                {(close) => <NewAnswerForm onAccepted={this.addNewAnswer} onCancle={close} />}
                            </FreeAnswer> : undefined}
                            {showComplete && <div className={'Poll-CompleteBox'}>
                                <Button className={'Poll-Complete'} main onClick={this.completeVotes}>
                                    {i18n.t('pryaniky.post.polls.completeText')}
                                </Button>
                            </div>}
                        </>
                    }>
                    {(answer) => <Answer
                        answerVariants={answerVariants}
                        key={answer.id}
                        pollId={id}
                        onChange={this.onChangeAnswer}
                        answer={answer}
                        maxCount={maxCount}
                        answerAllow={answerAllow}
                        showParticipants={showParticipants}
                        showResults={answers.reduce((acc: boolean, curr: any) => acc ? acc : curr.answeredByMe, false)}
                        showResultsBeforeVote={ showResultsBeforeVote}
                    />}
                </AnswerList>

            </NewsView>
        )
    }

    private addNewAnswer = (answer: AnswerType) => {
        const { poll } = this.props
        this.changeVotes(answer, [...poll.answers, answer]);
    }
    private onChangeAnswer = (answer: AnswerType) => {
        const { poll } = this.props;
        this.changeVotes(answer, poll.answers);
    }


    private completeVotes = () => {
        const { poll, id } = this.props
        this.setState({ completed: true })
        this.props.changeAnswer({
            id,
            answers: poll.answers,
            maxCount: poll.maxCount
        })
    }

    private changeVotes = (answer: AnswerType, nanswers: AnswerType[]) => {
        const { poll, id } = this.props

        const { answers, maxCount } = answerChange(answer, poll, nanswers, this.props.userData.baseData)

        if (poll.disableRevote) {
            this.props.pathNews({
                id,
                poll: {
                    ...poll,
                    answers: answers,
                    maxCount
                }
            })
        }
        else {
            this.props.changeAnswer({
                id,
                answers: answers,
                maxCount
            })
        }
    }
}

export const Polls = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(PollsPresenter));


export const SliderPoll: FC<{}> = ({ }) => <ContentView openBtnText={i18n.t('pryaniky.timeline.news.poll.votefull')} />

export const initPolls = () => {
    ComponentInjector.getInstance().addNode('news_types', <Polls />, 'polls')
    ComponentInjector.getInstance().addNode('slider_news_types', <SliderViewPolls />, 'polls')
}