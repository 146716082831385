import { IAnswerProps } from './Answer.index'
import React, { memo, useCallback, useMemo } from 'react'
import './Answer.scss';
import { CheckboxInput, Radio } from 'uielements/src';
import { UserList } from 'uielements/src/UserList/UserList'
import { AnswerLayout } from '../AnswerLayout/AnswerLayout'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import openPollParticipants from 'blocks/Dialogs/News/PollParticipants/PollParticipants'

export const Answer: React.FC<IAnswerProps> = memo(({ answer, maxCount, pollId, showResults,showResultsBeforeVote, showParticipants, answerAllow, onChange, answerVariants }) => {
    const matches = useMediaQuery('(max-width:576px)');
    const calcAnswerWidth = (answer.count * 100) / maxCount + '%';
    const showAnswerWidth = (answerAllow || showResults )|| showResultsBeforeVote===false ? calcAnswerWidth : "0";
    const answerClick = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        !answerAllow && onChange(answer);
    }, [answer, answerAllow])

    const actionParticipants = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        openPollParticipants({
            answerId: answer.id,
            newsId: pollId,
            answerText: answer.text
        })

    }, [answer.id, pollId])
    const participants = useMemo(() => [...answer.participants].reverse(), [answer.participants])
    const maxVisible = (matches && (answer.count > 1)) ? 0 : 1
    return <AnswerLayout width={showAnswerWidth } onClick={answerClick}>
        {answerVariants !== 1 ?
            <CheckboxInput text={answer.text} checked={answer.answeredByMe}
                disabled={answerAllow}
                className="AnswersCheckbox"
            /> :
            <Radio text={answer.text} checked={answer.answeredByMe}
                disabled={answerAllow}
                className="AnswersCheckbox"
            />}
        { (answerAllow || showResults )||showResultsBeforeVote===false  ?
            <> {showParticipants ? <UserList
                className="AnswerspaPticipants"
                cut={false}
                action={answer.count > 1}
                activeAction={false}
                maxVisible={maxVisible}
                actionCallback={actionParticipants}
                actionBtnContent={<span className="AnswersValue">+{answer.count - maxVisible}</span>}
                data={participants} /> : <span>{answer.count}</span>} </> :  undefined}
    </AnswerLayout>
})