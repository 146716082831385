import React from 'react';
import { ICommentDialogProps, cnCommentDialog } from './Comment.index';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import Input from 'uielements/src/MaterialElements/Input/Input';
import {
  Select,
  Icon
} from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import './Comment.scss';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox'
import { TextField } from 'muicomponents/src/TextField/TextField'
import { FormControlLabel } from 'muicomponents/src/FormControl';
import { styled } from 'muicomponents/src/mui/system';
import * as utils from 'utils/src/utils';

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: 0,
  marginRight: 0,
  width: '100%'
});

const CommentDialogPresenter: React.FC<ICommentDialogProps> = ({
  okBntText,
  cnclBntText,
  action,
  title = <Translate i18nKey={`pryaniky.dialog.comment.title`} />,
  handleAccept,
  handleClose
}) => {

  const [comment, setComment] = React.useState('');
  const [sendNotify, setSendNotify] = React.useState(false);
  const [error, setError] = React.useState(false);
  const shopAccessibilityRequest = (comment: string, sendNotify: boolean) => {

  }
  return (
    <div className={cnCommentDialog()}>
      <DialogTitle onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent className={cnCommentDialog("Body")}>

        {action?.includes("shopAccessibility") && <StyledFormControlLabel
          control={
            <Checkbox
              checked={sendNotify}
              onChange={(e) => setSendNotify(!sendNotify)}
              sx={{ padding: "0 9px 0 0" }}
            />
          }
          label={<Translate i18nKey='pryaniky.dialog.comment.shopAccessibility' />}
        />}

        {/* <Input
          multiline
          onChange={value => setComment(value)}
          value={comment}
          disable={action?.includes("shopAccessibility") && sendNotify === false}
          placeholder={<Translate i18nKey={'pryaniky.dialog.comment.input.placeholder'} />}
        /> */}
        <TextField
          fullWidth
          error={error}
          value={comment}
          disabled={action?.includes("shopAccessibility") && sendNotify === false}
          onChange={(e) => {
            setComment(e.target.value)
            setError(sendNotify&&comment.length === 0)
          }}
          label={<Translate i18nKey={'pryaniky.dialog.comment.input.placeholder'} />}
          sx={{ marginTop: "9px" }}
        />
        {/* {error && <div className={cnCommentDialog("Error")}>  <Translate i18nKey={"pryaniky.validate.notNull"} /></div>} */}
      </DialogContent>
      <DialogActions
        acceptText={action?.includes("shopAccessibility") ? i18n.t('pryaniky.dialog.comment.shopAccessibility.approve') : i18n.t('send')}
        closeText={i18n.t('pryaniky.cancel.dialog')}
        // disableAccept={action?.includes("shopAccessibility") && comment.length === 0}
        onAccept={() => !action?.includes("shopAccessibility") ? handleAccept({ comment }) : (comment.length === 0 && sendNotify) ? setError(true) : handleAccept({ comment, sendNotify })}
        onClose={handleClose} />
      {/* <DialogActions>
        <Button color='primary' variant={'contained'} onClick={() => handleAccept(comment)}>
          {
          okBntText || <Translate i18nKey='send' />
          }
        </Button>
        <Button color='primary' onClick={handleClose}>
          {
          cnclBntText || <Translate i18nKey='cancel' />
          }
        </Button>
      </DialogActions> */}
    </div>
  )
}

export const commentDialog = createModal(CommentDialogPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});