import React from 'react';
import { i18n, Translate } from 'localization';
import { InputMention } from '../../InputMention/InputMention';
import RadioGroup from 'uielements/src/MaterialElements/RadioGroup';
import Suggest from 'uielements/src/MaterialElements/Autocomplete';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import { CheckboxInput } from 'uielements/src';
import { Actions } from '@jsonforms/core';
import { get_variable_name, get_variable_schema_data } from './utils';
import { useLocation } from 'react-router';

// TagsCollectionsInputControl

const items = [
  { title: i18n.t('pryaniky.widgets.tagsCloud.color.black'), value: 'black' },
  { title: i18n.t('pryaniky.widgets.tagsCloud.color.multi'), value: 'colors' },
  { title: i18n.t('pryaniky.widgets.tagsCloud.color.corp'), value: 'corp' },
]

export const tagsColleactionsInput = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  const data = props.data[get_variable_name(props.uischema.scope)];

  const location = useLocation();

  const isGroup = location.pathname.includes('group');

  return <div style={{ padding: '24px' }}>

    {isGroup && <Checkbox
      onChange={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
        oldData.isGroupTags = !data.isGroupTags;
        return { ...oldData };
      }))}
      label={i18n.t('Использовать теги из группы')}
      value={data.isGroupTags}
    />}

    <div className="TagsCloud-Tags">
      <Suggest
        type='collections'
        value={data.collection}
        label={schema.placeholder || i18n.t('pryaniky.widgets.tagsCloud.collection')}
        additionalRequestOpts={{ type: 'all', excludeShop: true }}
        multiple={false}
        onChange={(selected: any) => {
          props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
            oldData.collection = selected;
            return { ...oldData };
          }))
        }}
        {...(schema.props || {})}
      />
    </div>

    

    <RadioGroup
      value={data.color}
      items={items}
      onChange={value => {
        props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          oldData.color = value;
          return { ...oldData };
        }))
      }}
      label={i18n.t('pryaniky.widgets.tagsCloud.color')}
      size={'small'}
    />
  </div>
}
// end TagsCollectionsInputControl