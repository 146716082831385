import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";

import { mapStateToProps, ILayoutStateProps, cnLayout, ILayoutProps, ILayoutState } from './InfoBlock-Layout.index';
import './InfoBlock-Layout.scss';


export class LayoutPresenter extends React.Component<ILayoutProps, ILayoutState> {
  public static defaultProps = {
    editBtn: undefined
  }
  constructor(props: ILayoutProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const { children, className = '', left, right, editBtn } = this.props;
    return (
      <div className={cnLayout({}, [customClassNames, className])}>
        <div className={cnLayout('Left')}>{left}</div>
        <div className={cnLayout('Right')}>{right}</div>
        {editBtn && <div className={cnLayout('EditBtn')}>{editBtn}</div>}
        {children}
      </div>
    )
  }

}


export const Layout = connect<ILayoutStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(LayoutPresenter)
//   compose(
//   withBemMod(cnLayout(), {})
// )(LayoutPresenter))
