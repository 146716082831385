import { put, takeLeading, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import {
  GET_NOTIFI_SETTINGS,
  setNotifiSettings,
  SAVE_NOTIFI_SETTINGS,
  setNotifiSettingsNetworks,
  GET_NOTIFI_TEMPLATES,
  setNotifiTemplates,
  GET_NOTIFI_SETTINGS_NETWORK_GROUPS,
  changeNotifiSettingsNetworkData,
  CHANGE_NOTIFI_SETTINGS_NETWORK_TEMPLATE,
  CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE
} from './actions';

import {
  IGetNotifiSettings,
  ISaveNotifiSettings,
  IGetNotifiSettingsNetworkGroups,
  IChangeNotifiSettingsNetworkTemplate,
  IChangeNotifiSettingsGroupTemplate
} from './actions.interfaces';

import { } from './interfaces';

import {
  INotifiSettingsResponse,
  IGetNotifiTemplatesResponse,
  INetworkItem
} from 'utils/src/requests/models/api.notifications';

import {
  getNotifiSettingsRequest,
  saveNotifiSettingsRequest,
  getNotifiTemplatesRequest,
  changeNetworkTemolateRequest,
  changeGroupTemplateRequest
} from 'utils/src/requests/requests.notifications.settings';

import { getNotifiSettingsSelector, getNotifiSettingsNetworks } from './selectors';
import { checkResponseStatus, getErrorText } from 'utils/src/utils';
import { toast } from 'react-toastify';
import { i18n, Translate } from 'localization';
import { IBasicResponse, IResponseWithData } from 'utils/src/requests/models/api.base';
import { getGroupsByUser } from 'utils/src/requests/requests.groups'; 


function* getNotifiSettings(action: IGetNotifiSettings) {
  try {
    const { uid, gid, nid } = action.payload;
    const response: INotifiSettingsResponse = yield* call(getNotifiSettingsRequest, uid, gid, nid);

    if (checkResponseStatus(response)) {
      yield put(setNotifiSettings(response.data));
      const networks: INetworkItem[] = response.data.networks;
      const isNetworksExists = !!Object.keys(yield* select(getNotifiSettingsNetworks)).length;
      if (!isNetworksExists) {
        const payloadNetworks: any = {};
        networks.forEach(network => {
          payloadNetworks[network.id] = { ...network, expanded: false, groups: {} };
        })
        yield put(setNotifiSettingsNetworks(payloadNetworks));
      }
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('get notifi settings from server error', e);
  }
}

function* saveNotifiSettingsSaga(action: ISaveNotifiSettings) {
  try {
    const { uid, gid, nid } = action.payload;
    const data = yield* select(getNotifiSettingsSelector);
    const response: IBasicResponse = yield* call(saveNotifiSettingsRequest, data, uid, gid, nid);

    if (checkResponseStatus(response)) {
      toast.success(i18n.t('pryaniky.administration.toast.success'));
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('save notifi settings error', e);
  }
}

function* getNotifiSettingsNetworkGroups(action: IGetNotifiSettingsNetworkGroups) {
  try {
    const { uid, nid, parentNid } = action.payload;
    const response: IResponseWithData = yield* call(getGroupsByUser, uid, 'my', { network: nid, includeUserSettings: true, loadUsers: true });
    if (checkResponseStatus(response)) {
      let data = response.data;
      if (nid !== parentNid) data = data.filter((group: any) => !group.showGroupInSubnetworks);
      const formattedData: any = {};
      data.forEach((el: any) => {
        formattedData[el.pkid] = el
      })
      yield put(changeNotifiSettingsNetworkData({ nid, data: { groups: formattedData } }));
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('get notifi network groups error');
  }
}

function* getNotifiTemplates() {
  try {
    const response: IGetNotifiTemplatesResponse = yield* call(getNotifiTemplatesRequest);
    if (checkResponseStatus(response)) {
      const formatedTemplates = response.data.map(template => ({ title: template.displayName, value: template.name, g: template.g }));
      yield put(setNotifiTemplates(formatedTemplates));
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('get notifi templates error', e);
  }
}

function* changeNotifiSettingsNetworkTemplate(action: IChangeNotifiSettingsNetworkTemplate) {
  try {
    const { uid, nid, data } = action.payload;
    const response = yield* call(changeNetworkTemolateRequest, uid, nid, data);
    if (checkResponseStatus(response)) {
      yield put(changeNotifiSettingsNetworkData({ nid, data: { notificationTemplate: data.value } })); 
      toast.success(i18n.t('pryaniky.administration.toast.success'));
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('change notifi settings network template error', e);
  }
}

function* changeNotifiSettingsGroupTemplate(action: IChangeNotifiSettingsGroupTemplate) {
  try {
    const { uid, gid, selectedTemplateName } = action.payload;
    const response: IBasicResponse = yield* call(changeGroupTemplateRequest, uid, gid, selectedTemplateName);
    if (checkResponseStatus(response)) {
      toast.success(i18n.t('pryaniky.administration.toast.success'));
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('change group template error', e);
  }
}


const notifiSettings = function* notifiSettings() {
  yield takeLatest(GET_NOTIFI_SETTINGS, getNotifiSettings);
  yield takeLeading(SAVE_NOTIFI_SETTINGS, saveNotifiSettingsSaga);
  yield takeLeading(GET_NOTIFI_TEMPLATES, getNotifiTemplates);
  yield takeEvery(GET_NOTIFI_SETTINGS_NETWORK_GROUPS, getNotifiSettingsNetworkGroups);
  yield takeLatest(CHANGE_NOTIFI_SETTINGS_NETWORK_TEMPLATE, changeNotifiSettingsNetworkTemplate);
  yield takeLatest(CHANGE_NOTIFI_SETTINGS_GROUP_TEMPLATE, changeNotifiSettingsGroupTemplate);
}

export default notifiSettings;
