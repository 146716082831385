import { connect } from 'react-redux';
import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { Bable } from '../Bable/Bable';
// import * as utils from 'src/utils/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";

import { mapStateToProps, INotificationCounerStateProps, cnNotificationCouner, INotificationCounerProps, INotificationCounerState } from './NotificationCouner.index';
import './NotificationCouner.scss';
import { store } from 'redux/store';


export class NotificationCounerPresenter extends React.Component<INotificationCounerProps, INotificationCounerState> {
  // public utils: any = utils;
  public el: Element | Text | null;

  // constructor(props: INotificationCounerProps) {
  //   super(props);
  //   this.state = {};
  // }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', notificationCount } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnNotificationCouner({}, [customClassNames, className])}>
        {notificationCount !== 0 && <Bable>{notificationCount}</Bable>}
        {children}
      </TagName>
    )
  }
}


export const NotificationCouner = connect<INotificationCounerStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(compose(
  // withBemMod(cnNotificationCouner(), {})
)(NotificationCounerPresenter))