import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { v1 as uuid } from 'uuid';

export const widgetresponsive = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.presents,
            settings: {
              "name": "Отправленные подарки",
              "type": "sendedGifts",
              "hideIfNoData": false
            }
          })
        ],
        styles: {
          width: '100%',
        },
      }),
      // generateColumn({
      //   items: [
      //     generateWidget({
      //       type: widgets.types.presents,
      //       settings: {
      //         "name": "Полученные подарки",
      //         "type": "receivedGifts",
      //         "hideIfNoData": false
      //       }
      //     })
      //   ],
      //   styles: {
      //     width: '50%',
      //   },
      // })
    ],
    // settings: {
    //   breakpoints: { xs: 480 },
    //   cols: { xs: 12 },
    //   layouts: {
    //     xs: [
    //       { i: ids.users1, x: 0, y: 0, w: 12, h: 10 },
    //     ]
    //   }
    // }
  });
}