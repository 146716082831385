import React, { FC, memo, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from 'News/contexts/news'

import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit'
import { i18n, Translate } from 'localization';
import NewsHeader from 'News/containers/NewsHeader'
import NewsActions from 'News/containers/NewsActions'
import NewsComments from 'News/containers/NewsComments'
// import ActionHeader from 'News/containers/components/ActionHeader'


type IPresenterProps = News.ItemView.IPresenterProps
type IOwnProps = News.ItemView.IOwnProps
type IDispatchProps = News.ItemView.IDispatchProps
type IStateProps = News.ItemView.IStateProps
type IState = News.ItemView.IState

const noopFn = () => { }
const noopArray: any[] = []

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    edit,
    toggleEdit,
    attachments,
    id,
    linkPreviewImgUrl,
    systemNewsActionHtml,
    newstype,
    actionHeader,
    componentRenderName
}) => {

    let head = actionHeader;
    if(!head) head = {} as any;

    const { text, type, user = {}, textKey }: any = head;

    // const cancleEdit = useCallback(() => toggleEdit(false), [])

    // const editType = componentRenderName === "workflow" ? "workflow" : utils.newNewsType(newstype)

    const TextRenderer = useMemo(() => {
        if(!text && !textKey) return null;
        return <span className={cnClassName('Text')}>{text || (textKey && i18n.t('pryaniky.' + textKey + '_' + user.sex))}</span>
    }, []);

    return <div className={cnClassName({ }, [className])}>
        <div className={'NewsLayout'}>
            
            <NewsHeader TextRender={TextRenderer} />

            <div className={cnClassName('Content')}>
                {children}
            </div>

            {/* {edit
                ? <NewsEdit cancleEdit={cancleEdit} compliteEdit={noopFn} newsId={id} type={editType}/>
                : <div className={cnClassName('Content')}>
                    {children}
                </div>
            } */}

            {!edit && <LinkLayout linkPreviewImgUrl={linkPreviewImgUrl} systemNewsActionHtml={systemNewsActionHtml} />}

            {!edit && <Preview className={cnClassName('Preview', ['horizontalPadding'])} newsid={id} files={attachments ? attachments : noopArray} />}

            {!edit && <Attachments className={cnClassName('Attachments', ['horizontalPadding'])} files={attachments ? attachments : noopArray} onDelete={noopFn} state='view' />}

            <NewsActions />
        </div>
        <NewsComments />
    </div>
}

export default withNewsContext(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)));
