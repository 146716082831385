import React, { useRef, useCallback, FC } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { LineInput } from './LineInput/LineInput';
import { ItemChip } from './ItemChip/ItemChip'
import { XYCoord } from 'dnd-core'
import { i18n, Translate } from 'localization';

export const renderFilter = (
    inputRef: React.RefObject<HTMLInputElement>,
    filterValue: string,
    filterChange: (value: string) => void,
    onFocus: () => void,
    onBlur: () => void
) => {
    const onClick = (e: any) => {
        e.stopPropagation()
    }
    return <LineInput {...{ onFocus, onBlur, onClick }} ref={inputRef} onChange={(e) => filterChange(e.target.value)} value={filterValue} />
}

export const renderToggler = (open: boolean, setOpen: any) =>
    <span onClick={() => setOpen(!open)}>{open ? 'o' : 'c'}</span>

export const renderChip = (item: any, remove: any) => {
    return <ItemChip remove={remove}>{item.content}</ItemChip>
}


export const DNDChip: FC<any> = ({ label, index, id, moveCard, remove }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [, drop] = useDrop({
        accept: 'card',
        hover(item: any, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current!.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex, label)

            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <div ref={ref} style={{ opacity, borderRadius: '15px' }}>{renderChip({ content: i18n.t(label) }, remove)}</div>
    )
}

export const renderItem = (values: any, onClick: (item: any) => () => void, focusIndex: number, selected: any[] = [], disabledVariants: any[] = []) =>
    values.map((value: any, i: any) =>
        <div className={i === focusIndex ? 'focused_item' : ''} onMouseDown={onClick(value)}>{value.content}</div>)

export const renderChipDND = (moveCard: any, labelGetter: (vlaue: any) => string = (item) => item) => (item: any, remove: any, index: number = 0) => {
    const label = labelGetter(item)
    return <DNDChip key={item} id={item} moveCard={moveCard} label={label} index={index} remove={remove} />
}