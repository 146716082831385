import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { deleteReply } from '../../redux/actions';

import {} from 'News/redux/actions';
import { ReplyContextProps } from '../../contexts/reply';
import {} from '../../redux/reducer/types';
import {} from '../../redux/saga/selectors';
///////////
// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace News {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ReplyService {
        export type IPresenterProps = IOwnProps & IStateProps & IDispatchProps & ReplyContextProps;

        export interface IState {}

        export interface IOwnProps extends IClassNameProps {
            setEditableAfterLoadFullReply?: () => void;
        }

        export interface IStateProps {}

        export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
    }
}
////////////
type IOwnProps = News.ReplyService.IOwnProps;

export const mapStateToProps = (state: any, props: IOwnProps) => ({});

export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: IOwnProps) =>
    bindActionCreators(
        {
            deleteReply,
        },
        dispatch
    );

////////////

export const cnClassName = cn('ReplyService');
