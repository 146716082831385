import { connect } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import { Avatar, SearchInput, Button } from 'uielements/src'
import { Comments } from '../Comments/Comments';
// import { Thread } from '../Parts/Thread/Thread'
import { UnitsList } from '../Parts/UnitsList/UnitsList'
import { CourseHeader } from '../../components/CourseHeader/CourseHeader'
import { mapStateToProps, ICourseStateProps, cnCourse, ICourseProps, mapDispatchToProps } from './Course.index';
import './Course.scss';
import { SessionList } from '../SessionList/SessionList'
// import CourseTabs, { ITabType } from '../../components/Tabs'
import { withPreloader, withError } from '../Preloader/Preloader'
// import { Course as Skelete } from '../../Skelets/Course/Course'
import { SessionList as SessionListSkelete } from '../../Skelets/SessionList/SessionList'
import { AnswerList } from '../../LMSTeacherOffice/AnswerList/AnswerList'
import { Port } from '../../LMSTeacherOffice/Tabs/Port/Port'
import { Sessions } from '../../LMSTeacherOffice/Moderation/Filters/Sessions/Sessions'
import { Status } from '../../LMSTeacherOffice/Moderation/Filters/Status/Status'
import { Questions } from '../../LMSTeacherOffice/Moderation/Filters/Questions/Questions'
// import { Users } from '../../LMSTeacherOffice/Moderation/Filters/Users/Users'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string';
import UnitsComments from '../../LMSTeacherOffice/Comments'
import { Course as Studying } from '../Single/Course'
import { Results } from '../Results/Results'

import { tS, Ts } from 'LMSModule/utils/i18n.adaptor'

const zeroId = "00000000-0000-0000-0000-000000000000";

const defaultTabs = [
    {
        value: 'tasks',
        title: <Ts i18nKey='tabs.tasks.1' />,//tS('tabs.tasks.1'),
        id: 'tasks'
    }, {
        value: 'discussion',
        title: <Ts i18nKey='tabs.discussion.1' />,//tS('tabs.discussion.1'),
        id: 'discussion'
    }, {
        value: 'unitscomments',
        title: <Ts i18nKey='tabs.unitscomments.1' />,//tS('tabs.unitscomments.1'),
        id: 'unitscomments'
    }, {
        value: 'answers',
        title: <Ts i18nKey='tabs.answers.1' />,//tS('tabs.answers.1'),
        id: 'answers'
    },
];

// const SESSIONS_TABS = [
//     { value: 'active', content: tS('active.1') },
//     { value: 'archieved', content: tS('archieved.1') },
// ]

const generateUnitsString = (courseUnits: any[]) => {
    const testCount = courseUnits.reduce((acc: number, cur: any) => acc + (cur.unitType === 'test' ? 1 : 0), 0)
    const lessonCount = courseUnits.length - testCount

    const countsArr: string[] = []
    if (lessonCount !== 0) countsArr.push(tS('lessons.1', { count: lessonCount }))
    if (testCount !== 0) countsArr.push(tS('tests.1', { count: testCount }))
    return countsArr.join(', ')
}

export const TasksPresenter: FC<any> = ({ course, lastViewedUnit }) => {
    const [filter, setFilter] = useState<string>('')
    const changeFilter = (value: string) => {
        setFilter(value)
    }
    const units = course.courseUnits?.filter((unit: any) => unit.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) || []

    const countsStr = generateUnitsString(course.courseUnits || [])

    return <div className={cnCourse('Content')}>

        <div className={cnCourse('Threads')}>
            <div className={cnCourse('SessionsHeader')}>
                <h3 className={cnCourse('ThreadsTitle')}>{tS('sessions.1')}</h3>
                {/* <CourseTabs className={cnCourse('SessionsTabs')} onChange={selectSessionTab} selected={sessionTab} tabs={SESSIONS_TABS} /> */}
            </div>
            {course.full ?
                <SessionList course={course} className={cnCourse('ThreadsList')} /> :
                <SessionListSkelete className={cnCourse('ThreadsList')} />}
        </div>

        {/* <CourseTabs className={cnCourse('Tabs')} onChange={selectTab} selected={tab} tabs={TABS} /> */}


        <div className={cnCourse('Search')}>
            <SearchInput throttle={200} className={cnCourse('SearchInput')} onChange={changeFilter} />
        </div>

        {course.full && <div className={cnCourse('Units')}>
            <h3 className={cnCourse('UnitsCount')}>{countsStr}</h3>
            <UnitsList units={units} lastViewedUnit={lastViewedUnit} cid={course.id} />
        </div>}
    </div>
}

export const AnswersPresenter: FC<any> = ({
    sessionsIds,
    normalSessions,
    questionsIds,
    questions,
    userId,
    courseId,
    location,
    rPush
}) => {
    const requestParams = queryString.parse(location.search)
    const [filters, setFilters] = useState<any>({
        'filter.sessionIds': [],
        'filter.filterByUserIds': [userId],
        'filter.filterByQuestionIds': [],
        'filter.isNeedModeration': true,
        'filter.filterByStatus': 'All'
    })
    useEffect(() => {
        setFilters({
            ...filters,
            'filter.sessionIds': requestParams['filter.sessionIds'] || [],
            'filter.filterByQuestionIds': requestParams['filter.filterByQuestionIds'] || [],
            'filter.isNeedModeration': requestParams['filter.isNeedModeration'] || true,
            'filter.filterByStatus': requestParams['filter.filterByStatus'] || 'All',
        })
    }, [location.search])
    const setModerationFilters = (value: any) => {
        const paramsStr = queryString.stringify({
            ...requestParams,
            ...value
        })
        rPush(`?${paramsStr}`)
    }
    return <div className={cnCourse('AnswersList')}>
        <div className={cnCourse('AnswersList-Aside')}>
            <Sessions {...{ setModerationFilters, sessionsIds, normalSessions, currentFilters: filters['filter.sessionIds'] }} cid={courseId} />
            <Status {...{ setModerationFilters, currentFilters: filters['filter.filterByStatus'] }} />
            <Questions {...{ setModerationFilters, questionsIds, questions, currentFilters: filters['filter.filterByQuestionIds'] }} />
        </div>

        <div className={cnCourse('AnswersList-Section')}>
            <AnswerList hideModeration={true} listContainer={courseId} courseId={courseId} filters={filters} />
        </div>
    </div>
}

const Answers = withRouter(AnswersPresenter)

export const CoursePresenter: React.FC<ICourseProps & any> = ({
    tag: Tag = 'article',
    rPush,
    lastViewedUnit,
    course,
    match,
    currentUser,
    sessions,
    questionsIds,
    questions,
    completedSession
}) => {
    let lastCompletedSid: string | undefined = undefined
    if (completedSession.length !== 0)
        lastCompletedSid = completedSession[0].id

    const viewes = {
        default: <TasksPresenter {...{ course, lastViewedUnit }} />,
        tasks: <TasksPresenter {...{ course, lastViewedUnit }} />,
        discussion: <div className={cnCourse('Content')}>
            <div className={cnCourse('CommentsHeader')}>
                {tS('discussion.1')}
            </div>
            <Comments nid={course.newsUid} className={cnCourse('Comments')} />
        </div>,
        answers: <Answers
            rPush={rPush}
            questionsIds={questionsIds}
            userId={currentUser.baseData.id}
            questions={questions}
            courseId={course.id}
            listContainer={course.id}
            sessionsIds={course.courseSessions}
            normalSessions={sessions} />,
        studying: <Studying course={course} />,
        results: <Results course={course} defaultSid={lastCompletedSid} />,
        unitscomments: <UnitsComments cid={course.id} course={course} type={'user'} />
    }

    const tabs = [...defaultTabs]
    if (course.courseSessionId !== zeroId) {
        tabs.push({
            value: 'studying',
            title: <Ts i18nKey='tabs.studying.1' />,//tS('tabs.studying.1'),
            id: 'studying'
        })
    } else if (lastCompletedSid) {
        tabs.push({
            value: 'results',
            title: <Ts i18nKey='tabs.results.1' />,//tS('tabs.results.1'),
            id: 'results'
        })
    }

    const allowEdit = currentUser.baseData.isAdmin || course.authors.map((u: any) => u.id).includes(currentUser.baseData.id)
    return (
        <Tag className={cnCourse({}, ['Page'])}>
            <CourseHeader className={cnCourse('Header')} course={course} allowEdit={allowEdit} enableTabs={true} tabs={tabs} defaultVal={'tasks'} />

            <Port tabs={viewes} />
        </Tag>
    );
};

const CourseWithError = withError(CoursePresenter, ['Page'], 'course')
const CoursePresenterWithLoader = withPreloader(CourseWithError, ['course', 'lastViewedUnit'], ['Page'])

const CourseLoader: FC<any> = (props) => {
    const { course, match, loadCourse, loadUnitsLogs, sessionsLogs, openCourse } = props
    useEffect(() => {
        openCourse(match.params.id);
        (!course || !course.full) && loadCourse(match.params.id);
    }, [match.params.id]);
    const sessionLog = course ? sessionsLogs[course.courseSessionId] : null
    let lastViewedUnit = zeroId;
    if (course && sessionLog)
        lastViewedUnit = course.courseSessionId !== zeroId ? sessionLog.log.lastViewedUnit : zeroId
    return <CoursePresenterWithLoader {...props} lastViewedUnit={lastViewedUnit} />
}


export const Course = connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseLoader);
