import React, { FC, useCallback, Fragment } from 'react'
import './SurveyPassing.scss'
import { cnSurveyPassing, ISurveyPassing, mapStateToProps, mapDispatchToProps, SurveyPassingProps } from './SurveyPassing.index'
// import { Button } from 'uielements/src'
// import { PostContentRender } from '../../PostContentRender/PostContentRender'
import MDRender from 'uielements/src/CommonmarkRender'
import { connect } from 'react-redux'
import { AnswerItem } from './AnswerItem/AnswerItem'
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { BooleanLiteral } from 'typescript'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AnswerTypeSort } from './AnswerTypeSort/AnswerTypeSort'
import { Box, Divider } from 'muicomponents/src'
import {
    ErrorOutline
} from 'muicomponents/src/Icons/Icons';
import { SortableElement, SortableHandle, SortableContainer } from 'react-sortable-hoc';
import { arrayMove } from 'utils/src/utils';
import { Matrix } from 'muicomponents/src/Matrix'
import { keyBy } from 'lodash';
import { useSurveyPassing } from './SurveyPassing.hooks';
import { SurveyPage } from './Page';
import { SurveyQuestion } from './Question';
import { AnswersBox } from './SurveyPassing.styled'
import { SSurveyQuestion } from 'utils/src/BaseTypes/survey'
import { GUID_EMPTY } from 'utils/src/constants.prn'



const SurveyPassingPr: FC<SurveyPassingProps> = ({
    preview
}) => {

    const {
        page,
        answers,
        setAnswer
    } = useSurveyPassing();

    const prepareMatrixData = useCallback((question: SSurveyQuestion, rows?: SSurveyQuestion['rows']) => {
        return {
            header: question.answers.map(answer => {
                return {
                    id: answer.id,
                    title: answer.text,
                    description: answer.description
                };
            }),
            rows: (rows || question.rows || []).map(row => {
                return {
                    id: row.id,
                    title: row.text,
                    value: row.selectedAnswer
                };
            })
        }
    }, []);

    if (!page) return null;

    /**
     * @todo move sorting and matrix to other component
     */
    return (
        <SurveyPage
            preview={preview}
        >
            {
                page.questions.map((question, idx) => {
                    if (question.qtype === "MatrixSingle") {
                        const currentAnswer = answers[question.id]?.rows;
                        const {
                            header,
                            rows
                        } = prepareMatrixData(question, currentAnswer);
                        return (
                            <Fragment key={question.id}>
                                <Divider />
                                <SurveyQuestion
                                    question={question}
                                >
                                    <Box sx={{
                                        overflowX: "auto",
                                        overflowY: "auto",
                                        flex: "1 1",
                                        // marginRight: "-17px",
                                    }}>
                                        <Matrix
                                            rows={rows}
                                            header={header}
                                            surveyMatrix={true}
                                            onChange={(rez) => {
                                                setAnswer(question.id)({
                                                    rows: rez.map(el => ({
                                                        id: el.id,
                                                        text: el.title as string,
                                                        selectedAnswer: el.value || GUID_EMPTY
                                                    }))
                                                });
                                            }}
                                        />
                                    </Box>
                                </SurveyQuestion>
                            </Fragment>
                        );
                    }
                    if (question.qtype === "Sorting") {
                        const currentAnswer = answers[question.id]?.answersIds || question.answers.map(el => el.id);
                        const answersDict = question.answers.reduce((a, c) => {
                            return { ...a, [c.id]: c };
                        }, {} as { [key: typeof question.answers[number]['id']]: typeof question.answers[number] });
                        return (
                            <Fragment key={question.id}>
                                <Divider />
                                <SurveyQuestion
                                    question={question}
                                >
                                    <SortableAnswers
                                        className={cnSurveyPassing('Answers-SortingItem')}
                                        onSortEnd={({ oldIndex, newIndex }: { [s: string]: number }) => {
                                            setAnswer(question.id)({
                                                answersIds: arrayMove(currentAnswer, oldIndex, newIndex)
                                            })
                                        }}
                                        useDragHandle
                                        lockAxis='y'
                                        useWindowAsScrollContainer
                                        shouldCancelStart={() => { }}
                                    >
                                        {
                                            currentAnswer.map((id: any, idx: any) => <AnswerTypeSort
                                                item={answersDict[id]}
                                                key={id}
                                                index={idx}
                                                qtype={question.qtype}
                                            />)
                                        }
                                    </SortableAnswers>
                                </SurveyQuestion>
                            </Fragment>
                        );
                    };
                    const currentAnswer = answers[question.id]?.answersIds || [];
                    return (
                        <Fragment key={question.id}>
                            <Divider />
                            <SurveyQuestion
                                question={question}
                            >
                                <AnswersBox className={cnSurveyPassing('Answers')}>
                                    {question.answers.map((answer: any, idx: number) => {
                                        return (
                                            <AnswerItem
                                                key={answer.id}
                                                item={answer}
                                                qtype={question.qtype}
                                                questionId={question.id}
                                                sliderSymbols={question.sliderSymbols}
                                                disableMoreAnswers={question.qtype === "Multi" && question.maxAnswerVariants === currentAnswer.length}
                                                allowAttachments={question.allowAttachments}
                                            />
                                        )
                                    })}
                                </AnswersBox>
                            </SurveyQuestion>
                        </Fragment>
                    );
                })
            }
        </SurveyPage>
    );
};

export const SurveyPassing = SurveyPassingPr;




const SortableAnswers = SortableContainer(({ children }: any) => <div>{children}</div>);

// const SurveyPassingPresenter = ({
//     data,
//     questionSortingData,
//     activeSurveyIdx,
//     totalQuestions,
//     // nextActiveQuestion,
//     answersIds,
//     sendAnswer,
//     answered,
//     surveyId,
//     preview,
//     rows,
//     validMatrix,
//     setFinishedSurvey,
//     setIsShowResult,
//     setAnswerId,
//     setSurveyAnswersIds,
//     setRowAnswers,
//     // setRowAnswerId,
//     highlightCorrectness,
//     textColor
// }: ISurveyPassing) => {

//     const [showComment, setShowComment] = React.useState<boolean>(false)
//     // const showComment = React.useRef<boolean>(false)
//     const isAnswerComment = data.answers.map((el: any) => el.description).findIndex((el: any) => el !== "") !== -1;
//     const onSendAnswer = (isSkipped: boolean) => {
//         // if (activeSurveyIdx + 1 !== totalQuestions && (data.qtype === 'Single' || data.qtype === 'Multi') && isAnswerComment) {
//         //     sendAnswer({ id: surveyId, preview })
//         //     // setShowComment(true)
//         //     // showComment.current = true;
//         //     // if( activeSurveyIdx + 1 === totalQuestions)setIsShowResult(true)
//         // } else if 
//         sendAnswer({ id: surveyId, preview, isSkipped })
//         // if (activeSurveyIdx + 1 === totalQuestions && (data.qtype === 'Single' || data.qtype === 'Multi')) {
//         //     // setShowComment(true)
//         //     // showComment.current = true;
//         //     setIsShowResult(true)
//         // } else {
//         //     // setShowComment(false)
//         //     activeSurveyIdx + 1 !== totalQuestions ? nextActiveQuestion() : setFinishedSurvey()

//         // }
//     }


//     // const forvardAction = () => {
//     //     // showComment.current = false;
//     //     // setShowComment(false)

//     //     if (activeSurveyIdx + 1 !== totalQuestions) {
//     //         nextActiveQuestion()

//     //     } else {
//     //         setFinishedSurvey()
//     //         setIsShowResult(true)
//     //     }
//     // }


//     const onSortEnd = ({ oldIndex, newIndex }: { [s: string]: number }) => {
//         if (oldIndex !== newIndex)
//             setSurveyAnswersIds({ questionId: data.id, answersIds: arrayMove(questionSortingData.newAnswersIds, oldIndex, newIndex) });
//     }
//     // const prepareRows = data.rows.map((el: any) => ({ ...el, title: el.text }))
//     // const prepareAnswers = data.answers.map((el: any) => ({ ...el, title: el.text }))

//     const header: React.ComponentProps<typeof Matrix>['header'] = React.useMemo(() => {
//         return data.answers?.map((header: any) => {
//             return {
//                 id: header.id,
//                 title: header.text
//             }
//         }) || [];
//     }, [data.answers]);

//     const preparedRows: React.ComponentProps<typeof Matrix>['rows'] = React.useMemo(() => {
//         return rows?.map((row: any) => {
//             return {
//                 id: row.id,
//                 title: row.text,
//                 value: row.selectedAnswer
//             }
//         }) || [];
//     }, [rows]);


//     const onChangeMatrix: React.ComponentProps<typeof Matrix>['onChange'] = React.useCallback((rows) => {
//         const rowsObject = keyBy(rows, 'id');
//         const value = rows?.map((row: any) => {
//             if (row.selectedAnswer !== rowsObject[row.id].value) {
//                 return {
//                     ...row,
//                     text: rowsObject[row.id].title,
//                     selectedAnswer: rowsObject[row.id].value || ''
//                 }
//             }
//             return row
//         });
//         setRowAnswers({ answeredRow: value.map((el: any) => ({ id: el.id, text: el.text, selectedAnswer: el.selectedAnswer })) })

//     }, []);
//     return (
//         <div className={cnSurveyPassing()}>
//             <div className={cnSurveyPassing('Question')}>
//                 {/* <div>{activeSurveyIdx + 1}/{totalQuestions}</div> */}
//                 <span className={cnSurveyPassing('Question-Title')}>
//                     <span style={{ marginRight: "5px" }}>{((data.position + 1) || (activeSurveyIdx + 1)) + ". "}</span><MDRender source={data.text || ''} textCut={false} />
//                 </span>
//                 <span className={cnSurveyPassing('Question-Text')}>
//                     <MDRender source={data.description || ''} textCut={false} />
//                 </span>
//                 {data.qtype === 'Multi' && <div className={cnSurveyPassing('Question-Multi')}>
//                     <Translate i18nKey={'pryaniky.survey.manyAnswers'} values={{ minAnswerVariants: data.minAnswerVariants, maxAnswerVariants: data.maxAnswerVariants }} /></div>}
//                 {/* {data.qtype === 'Multi' && <div className={cnSurveyPassing('Question-Multi')}>{i18n.t('pryaniky.survey.manyAnswers')}</div>} */}
//             </div>

//             {data.qtype !== "MatrixSingle" && data.qtype !== "Sorting" && <div className={cnSurveyPassing('Answers')}>
//                 {data.answers.map((item: any, idx: number) => {
//                     return (
//                         <AnswerItem
//                             item={item}
//                             qtype={data.qtype}
//                             questionId={''}
//                             sliderSymbols={data.sliderSymbols}
//                             // sliderSymbols={"stars"}
//                             disableMoreAnswers={data.qtype === "Multi" && data.maxAnswerVariants === answersIds.length}
//                             allowAttachments={data.allowAttachments}
//                         // highlightCorrectness={showComment} 
//                         />
//                     )
//                 })}
//             </div>}
//             {data.qtype === "Sorting" && <div className={cnSurveyPassing('Answers')}>
//                 <SortableAnswers
//                     className={cnSurveyPassing('Answers-SortingItem')}
//                     onSortEnd={onSortEnd}
//                     useDragHandle
//                     lockAxis='y'
//                     useWindowAsScrollContainer
//                     shouldCancelStart={() => { }}>

//                     {questionSortingData.newAnswersIds.map((id: any, idx: any) => <AnswerTypeSort
//                         item={{} as any}
//                         qtype={data.qtype}
//                         index={idx}
//                         key={id}
//                     // questionId={data.id}
//                     // answerId={id}

//                     // highlightCorrectness={showComment} 
//                     />)}

//                 </SortableAnswers>



//             </div>}

//             {data.qtype === "MatrixSingle" && data.rows?.length > 0 && <Box sx={{
//                 overflowX: "auto",
//                 overflowY: "auto",
//                 flex: "1 1",
//                 width: "100%"

//             }}
//                 className={cnSurveyPassing('MatrixBox')}>
//                 <Matrix
//                     rows={preparedRows}
//                     header={header}

//                     onChange={(el) => {
//                         onChangeMatrix(el)
//                     }}
//                 />
//             </Box>

//             }

//             <div className={cnSurveyPassing('Warning')}>
//                 <ErrorOutline sx={{ fontSize: 18.33 }} color="primary" />
//                 <span>{i18n.t('pryaniky.survey.answer.item.warning')}</span>

//             </div>

//             <div className={cnSurveyPassing('Buttons')}>
//                 {/* {!answered && <Button */}
//                 {<Button
//                     color='primary'
//                     disabled={data.qtype === 'Sorting'
//                         ? !questionSortingData.newAnswersIds.length
//                         :
//                         data.qtype === 'MatrixSingle'
//                             ? !validMatrix
//                             :
//                             data.qtype === 'Single'
//                                 ? !answersIds.length :
//                                 (
//                                     data.qtype === 'Text' ||
//                                     data.qtype === 'Time' ||
//                                     data.qtype === 'Date' ||
//                                     data.qtype === 'Numeric' ||
//                                     data.qtype === 'Slider'
//                                 ) ? !answersIds.length : answersIds.length < data.minAnswerVariants}
//                     // data.qtype === 'Slider') ? !answersIds.length : answersIds.length < 2}
//                     variant={'contained'}
//                     onClick={() => onSendAnswer(false)}

//                 >
//                     {activeSurveyIdx + 1 !== totalQuestions || (data.qtype === 'Single' || data.qtype === 'Multi') ? i18n.t('pryaniky.quiz.toAnswer') : i18n.t('pryaniky.quiz.end')}
//                     {/* {i18n.t('pryaniky.quiz.toAnswer')} */}

//                 </Button>}

//                 {/* {(data.qtype === 'Single' || data.qtype === 'Multi') && answered && <Button
//                     color='primary'
//                     variant={'contained'}

//                     disabled={!answered && true}
//                     onClick={() => forvardAction()}
//                 >
//                     {activeSurveyIdx + 1 !== totalQuestions ? i18n.t('pryaniky.quiz.next') : i18n.t('pryaniky.quiz.end')}
//                 </Button>} */}

//                 {(!data.allowSkip) && <Button
//                     color='primary'
//                     variant={'outlined'}
//                     onClick={() => {
//                         onSendAnswer(true)
//                         // if (activeSurveyIdx + 1 !== totalQuestions) {
//                         //     // nextActiveQuestion()
//                         // } else {
//                         //     // при последнем вопросе для завершения опроса все равно отправляем ответ, что бы получить результат(т.к бэк присылает дефолтный результат в случае 
//                         //     // если в админке это поле не было заполнено)
//                         //     setFinishedSurvey()
//                         //     setIsShowResult(true)
//                         // }
//                     }
//                     }
//                 >
//                     {activeSurveyIdx + 1 !== totalQuestions ? i18n.t('pryaniky.survey.next') : i18n.t('pryaniky.survey.skipAndEnd')}

//                 </Button>}
//             </div>
//         </div>
//     )
// }

// export const SurveyPassingOld = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(SurveyPassingPresenter)