import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { cnTagsCloud, ITagsCloudProps, mapStateToProps } from './TagsCloud.index';
import { getTagsRequest } from 'utils/src/requests/requests.tagscloud';
import ReactWordcloud from "react-wordcloud";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import './TagsCloud.scss';
import { i18n, Translate } from 'localization';

const options: any = {
  deterministic: true,
  enableTooltip: false,
  transitionDuration: 0,
  enableOptimizations: true,
  padding: 1,
  rotations: 0,
  rotationAngles: [0, 90],
  scale: "log",
  spiral: "archimedean",
  fontSizes: [24, 34],
};

const Presenter: React.FC<ITagsCloudProps> = (props) => {

  const { tag: Tag = 'div', data, edit, context } = props;

  const history = useHistory();

  const groupId = (context.gId && data.isGroupTags) ? context.gId : undefined;

  const getTags = useCallback(() => {
    getTagsRequest([data.collection?.id?.toString()], groupId).r.then(res => {
      setTags(res.data?.map(el => ({ ...el, text: el.tagName, value: el.publicationsCount > 4 ? el.publicationsCount : el.publicationsCount + 1 })));
    });
  }, [data.collection?.id])

  const callbacks = {
    onWordClick: (tag: any) => history.push(`/tag/${tag.tagId}`)
  }

  const corpColors = useMemo(() => {
    const { primaryColor1, primaryColor2, primaryColor3, additionalColor1, additionalColor2 } = props.styleSettings;
    return primaryColor1 ? [primaryColor1, primaryColor2, primaryColor3, additionalColor1, additionalColor2] : [];
  }, [props.styleSettings.primaryColor1]);

  if (data.color === 'black') options.colors = [];
  if (data.color === 'corp') options.colors = corpColors;

  const [tags, setTags] = useState<any[]>([]);

  useEffect(() => {
    getTags();
  }, [])

  if (!tags?.length) return <Tag {...props} className={cnTagsCloud()} />;

  return (
    <Tag {...props} className={cnTagsCloud({}, [props.className])}>
      <div className={cnTagsCloud('Header')}>
        <h4 className={cnTagsCloud('Header-Title')}>{i18n.t('pryaniky.widget.tags.title')}</h4>
      </div>
      {!edit && <ReactWordcloud
        words={tags}
        options={options}
        callbacks={callbacks}
      />}
    </Tag>
  )
}


export const TagsCloud = connect(mapStateToProps, undefined)(React.memo(Presenter));