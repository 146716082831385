import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import { AchievementsSelector } from './AchievementsSelector/AchievementsSelector'
import { i18n, Translate } from 'localization';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'

export const Editor: React.FC<IEditorProps> = ({ children, data, onChangeText, onChangeAchievement, achievementList }) => {

    let allow = data.achievement.id !== -1
    allow = allow ? data.achievement.allowReportByUser : true

    return <div className={'Editor EditorAchievements'}>

        {onChangeAchievement && <AchievementsSelector onChange={onChangeAchievement} achievementList={achievementList} selectedAchievement={data.achievement} />}


        {allow ? <PryanikyEditor
            // getClassRef={refGetter}
            //ref={forwardRef as any}
            // users={true}
            // tags={true}
            onChange={onChangeText}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.achievement.description')} /> : <div className={'EditorAchievements-Disabled'}>{i18n.t('pryaniky.createPost.achievement.allowReportByUser')}</div>}

        
        {children}
    </div>
}
