import React from 'react';
import { ILeftMenuItemLabelProps, cnLeftMenuItem } from './../../LeftMenuItem.index';
import { LeftMenuItemLabel } from './../label';
import { i18n, Translate } from 'localization';
import { getI18nKey } from './../utils';
import { Button, Icon, Avatar } from 'uielements/src';
import { basename, getCookie } from 'utils/src/utils';
import { childrenFixIconBlocks } from './../../../redux/constants';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export const LeftMenuItemLinkLabel: React.FC<ILeftMenuItemLabelProps> = ({
  data,
  parentData,
  authUserData,
  edit,
  ...otherProps
}) => {

  let Tag: React.ComponentType<any> | string = Button;
  let href = data.url;
  const props: { [s: string]: any } = {
    ...otherProps
  };
  props.theme = 'unstyled';
  const domain = window.location.hostname;
  if (href && href.indexOf(domain) !== -1) href = href.substring(href.indexOf(domain) + domain.length);
  if (!href) {
  } else
  if (href.startsWith('/sites/') && !href.includes(basename)) {
    props.type = 'link';
    props.href = href;
  } else
  // first symbol is / and next text is maillo: then first letter must be removed
  if(href[0] === '/' && href.indexOf('mailto:') === 1) {
    props.type = 'link';
    props.href = href.substring(1);
  } else
  if (href[0] === '/' && href[1] !== '/' && href.indexOf('NewAdmin/') === -1 && href.indexOf('adminonly=true') === -1 && href.indexOf('administration') === -1) {
    props.type = 'rlink';
    if (href === '/user' || href === '/notifications-settings') href = href + '/' + authUserData.id;
    props.href = href;
  }
  else {
    props.type = 'link';
    if (href.indexOf('administration') !== -1 && href.indexOf(basename) === -1) href = href.replace('/administration', `${basename}/administration`);
    props.href = href;
  }

  if (data.openInNewTab) props.target = "_blank";

  if (edit) Tag = 'div';

  return (
    // <Translate i18nKey={`title:${data.url === '/shop' ? data.title : getI18nKey(data)}`} defaults={data.title}>
      <LeftMenuItemLabel tag={Tag} renderType={data.renderType} blockType={data.blockType} notViewedCount={data.notViewedCount} {...props}>
        {
          data.blockType === 'user' ?
          <Tooltip title={authUserData.displayName}>
            <span>
              <Avatar size={48} imgUrl={authUserData.userPhotoUrl} imgId={authUserData.imgId} name={authUserData.displayName} />
            </span>
          </Tooltip>
          :
          <Icon icon={data.icon || (childrenFixIconBlocks.includes(parentData?.blockType) && parentData?.icon) || 'info'} />
        }
        <div className={cnLeftMenuItem('Name')}>
          {
            data.blockType === 'user' ? authUserData.displayName
            :
            data.url === '/shop' ? data.title
            :
            <Translate i18nKey={getI18nKey(data)} defaults={data.title}/>
          }
        </div>
      </LeftMenuItemLabel>
    // </Translate>
  )
}