import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { TextareaMentions } from 'blocks/TextareaMentions/TextareaMentions';
import { withBemMod } from '@bem-react/core';
import { IBodyProps } from '../NewsEdit-Body.index';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeThanksProps } from './NewsEdit-Body_type_thanks.index';
import './NewsEdit-Body_type_thanks.scss';
import {EditorTypeEdit} from '../../../NewsTypes/Thanks/Editor/containers/edit/edit'

export const withBodyTypeThanks = withBemMod<IBodyTypeThanksProps, IBodyProps>(
  cnBody(),
  { type: 'thanks' },
  (Presenter) => (
    (props) => {
      return (
        <Presenter {...props}>
          {({ text, onChangeText }) =>
            <EditorTypeEdit data={props.post} onChange={props.onChangePost}/>
            /*<TextareaMentions
              // getClassRef={this.getTextareaMentions} 
              users={true}
              tags={true}
              onChange={onChangeText}
              // className={this.props.className} 
              icon={'edit'}
              value={text}
            placeholder={i18n.t('placeholders.textarea.news')} />*/
          }
        </Presenter>
      )
    }
  )
);