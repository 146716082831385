import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'src/utils/utils';
import {Avatar} from 'uielements/src/index'

import { IChatToastProps, cnChatToast } from './ChatToast.index';
import './ChatToast.scss';


export class ChatToastPresenter extends React.Component<IChatToastProps> {
  // public utils: any = utils;
  public el: Element | Text | null;

  constructor(props: IChatToastProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', avatar,msg,title } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnChatToast({}, [customClassNames, className])}>
        <div><Avatar imgUrl={avatar} name={title}/></div>
        <div>{title}</div>
        <div>{msg}</div>
        {children}
      </TagName>
    )
  }

}


export const ChatToast = compose(
  // withBemMod(cnChatToast(), {})
  
)(ChatToastPresenter)