import React, { FC, useMemo } from 'react'
import { CollapseBox } from 'uielements/src/CollapseBox/CollapseBox';
import { Button, CheckboxInput, Loading, Input as PryanikyInput, Icon } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import CatRender from './CatRender/CatRender'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        confirmBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid #D7D7D7',
        },
        confirmBtn: {
            marginLeft: '10px',
            marginTop: '20px',
        },
        collapseHeader: {
            display: 'flex',
            cursor: 'pointer',
            background: '#f7f7f7',
            padding: '9px 24px',
            borderBottom: '1px solid #d3d9e0',
            width: '100%',
        },
        collapseHeaderText: {
            margin: '0',
            flex: '1 1',
            textAlign: 'left',
        },
        collapseHeaderIcon: {
            margin: '0',
        },
    })
);

export const FieldsRender: FC<any> = ({ userId, user, fieldsSet, onChangeFields }) => {
    const classes = useStyles();

    const fieldsCategoryes = useMemo(() => !user.profileData ? {} : (user.profileData[fieldsSet].result as string[]).reduce<any>((a: any, c: string) => {
        const cat = user.profileData[fieldsSet].entities.fields[c].field.category
        a[cat] = [...(a[cat] || []), c]
        return a
    }, {}), [userId, user])
    const categoryesKeys = Object.keys(fieldsCategoryes).sort().filter((cat: string) => cat !== 'default.fields');

    return <div>
        {/* <CollapseBox
        headerRender={(open: boolean) => (
            <Button className={classes.collapseHeader} noBorderRadius>
                <span
                    className={classes.collapseHeaderText}
                    children={i18n.t('pryaniky.profile.additionalInfo')}
                />
                <Icon className={classes.collapseHeaderIcon} icon={open ? 'chevron-down' : 'chevron-left'} />
            </Button>
        )}> */}
        <h4>{i18n.t('pryaniky.profile.additionalInfo')}</h4>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <div className={classes.root}>
                {categoryesKeys.map((cat) => <CatRender
                    cat={cat}
                    onChangeFields={onChangeFields}
                    fieldsCategoryes={fieldsCategoryes}
                    fieldsSet={fieldsSet}
                    user={user}
                />)}
            </div>
        </MuiPickersUtilsProvider>
        {/* </CollapseBox> */}
    </div>
}

export default FieldsRender