import { i18n, Translate } from 'localization';
import { TDialogProps } from 'utils/src/DialogCreator'
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { getRequestsList } from 'utils/src/requests/requests.groups'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { approveRequests } from 'redux/actions/Groups'
import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'


const displayNameFilter = (filter: string) =>
    (val: any) =>
        val.displayName.toLowerCase().split(' ').some((str: string) =>
            str.startsWith(filter.trim().toLowerCase()))


const createApproveDialog = () => {
    return ({
        gropId
    }: {
        gropId: string | number;
    }, containerProps?: TDialogProps) =>
        dialog({
            method: async function (skipCount, { count, search }) {
                const response = await getRequestsList(gropId)
                const result = await response.r as { data: IBaseUser[] } & IBasicResponse
                if (result.error_code !== 0) throw result
                return result.data.filter(displayNameFilter(search))
            },
            middleware: ({ listHeaderProps, ...props }) => {
                const { dispatchActionParam, isLoading } = useLazyExecutSaga(
                    () => approveRequests(gropId, props.select.selected, false),
                    [gropId])
                return {
                    ...props,
                    primaryActionClick: (select, actions) => {
                        actions.removeItems(select.selected)
                        dispatchActionParam(approveRequests(gropId, select.selected.map(value => value.id), true))
                    },
                    selectedActionClick(option, select, actions) {
                        if (option.id === 'disapprove') {
                            actions.removeItems(select.selected)
                            dispatchActionParam(approveRequests(gropId, select.selected.map(value => value.id), false))
                        }
                    },
                    listHeaderProps: {
                        ...listHeaderProps,
                        disableActionsButton: isLoading
                    }

                }
            },
            itemMiddleware: (props) => {
                const { dispatchActionParam, isLoading } = useLazyExecutSaga(
                    () => approveRequests(gropId, [props.item.id], false),
                    [gropId])
                return {
                    ...props,
                    actionButtonClick: () => {
                        props.actions.removeItems([props.item])
                        dispatchActionParam(approveRequests(gropId, [props.item.id], true))
                    },
                    onClickAction: (option, item, actions) => {
                        if (option.id === 'disapprove') {
                            actions.removeItems([item])
                            dispatchActionParam(approveRequests(gropId, [item.id], false))
                        }
                    },
                    actionButtonProps: {
                        children: i18n.t('pryaniky.group.ApproveDialog.btn.help.approveUser')
                    },
                    inProgress: isLoading
                }
            },
            selectable: true,
            listHeaderProps: {
                actionButtonProps: { children: i18n.t('pryaniky.group.ApproveDialog.btn.help.approveAllSelected') },
                options: [
                    { content: i18n.t('pryaniky.group.ApproveDialog.btn.help.disapproveAllSelected'), id: 'disapprove' },
                ]
            },
            itemOptions: [
                {
                    content: i18n.t('pryaniky.group.ApproveDialog.btn.help.disapproveUser'),
                    id: 'disapprove'
                }
            ],
            ItemComponent: UsersListItem,
        }, containerProps)
}

export default createApproveDialog()