import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    KPI
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import Table from '../../components/Table'
import Item from '../Item'
import ListPlaceholder from 'LMSModule/components/ListPlaceholder'
import { i18n, Translate } from 'localization';
import { /*Button,*/ Icon } from 'uielements/src'
import { Button } from 'muicomponents/src/Button/Button'
type IPresenterProps = KPI.Main.IPresenterProps
type IOwnProps = KPI.Main.IOwnProps
type IDispatchProps = KPI.Main.IDispatchProps
type IStateProps = KPI.Main.IStateProps
type IState = KPI.Main.IState

export const Presenter: FC<IPresenterProps> = ({
    children,
    loadKPIForUser,
    userId,
    keys,
    values,
    isLoading,
    createKPIItem,
    allowAdd
}) => {

    useEffect(() => {
        loadKPIForUser({ userId })
    }, [])

    return <div className={cnClassName({}, [])}>
        {allowAdd && <div>
            <Button
                variant={'text'}
                title={i18n.t("pryaniky.kpi.create.title.1")}
                // theme={'unstyled_center'}
                onClick={() => createKPIItem(userId)}>
                <Icon icon={'plus'} />
            </Button>
        </div>}
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={!isLoading}
            isLoading={isLoading}
            itemsCount={keys.length}
            titleEmpty={i18n.t("pryaniky.kpi.placeholder.empty.title.1")}
            textEmpty={
                allowAdd &&
                <Button
                    title={i18n.t("pryaniky.kpi.create.title.1")}
                    onClick={() => createKPIItem(userId)}>{i18n.t("pryaniky.kpi.placeholder.epmty.create.1")}</Button>
            }
            textLoad={i18n.t("pryaniky.kpi.placeholder.load.text.1")} >
            <Table>
                {keys.map(id => <Item id={values[id].id} key={values[id].id} userId={userId} />)}
            </Table>
        </ListPlaceholder>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
