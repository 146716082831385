import * as React from 'react';
import { i18n, Translate } from 'localization';
import { mapStateToProps, IPagesNewStateProps, cnPagesNew, IPagesNewProps, IPagesNewState, PagesNewActionsToProps } from '../PagesNew/PagesNew.index';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs/Breadcrumbs';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import { Box, Typography } from 'muicomponents/src'
import { AdditionalBoxeis } from "../PagesNew/styled"
import { ChevronRight, VisibilityOff, Archive } from 'muicomponents/src/Icons'
import { formatDateSTD } from 'utils/src/utils.dates';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';


export const PageBreadcrumbs = ({ dt, isDraftState, matchId, hideDate }: any) => {
    if (dt?.breadCrumbs) {
      const createDateView = formatDateSTD(dt.creationDate);
      const editedDateView = dt.changeDate ? formatDateSTD(dt.changeDate) : '';
      return <AdditionalBoxeis sx={{ marginTop: isDraftState || dt?.isArchived ? "48px" : 0 }} className={cnPagesNew('Breadcrumbs', { id: matchId })} >
        <Breadcrumbs
          sx={{ marginBottom: "0px" }}
          maxItems={5}
          separator={<ChevronRight fontSize="small" />}
          aria-label="breadcrumb">
          {dt?.breadCrumbs?.map((item: any, idx: number) => <MuiButton
            key={item.id}
            sx={{ textTransform: "inherit", fontSize: "16px" }}
            disabled={idx === dt?.breadCrumbs?.length - 1}
  
            href={item.alias}>
            {/* href={item.alias.startsWith("group/") ? "/" + item.alias + "/wikilist" : item.alias === "dash" ? "/wikilist" : "/wikilist/" + item.alias}> */}
            {item.alias === "/wikilist/dash" && item.name === "dash" ? i18n.t("pryaniky.list.wiki.dash") : item.name?.length > 0 ? item.name : dt?.title}
          </MuiButton>)}
        </Breadcrumbs>
        {!hideDate && <Tooltip title={<Translate i18nKey="pryaniky.wikilist.item.created.tooltip" values={{
          date: createDateView,
          additionalData: editedDateView ? Translate.t({ i18nKey: 'pryaniky.wikilist.item.edited.tooltip', values: { date: editedDateView } }) : undefined
        }} />}>
          <Typography variant="caption">
            <Translate i18nKey={"pryaniky.wikilist.item.created"} values={{ date: createDateView }} />
          </Typography>
        </Tooltip>}
      </AdditionalBoxeis>
    } else return null
  }
  