import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import * as ACT from '../../../actions';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { i18n, Translate } from 'localization';
import { API } from 'utils/src/utils'
import { toast } from 'react-toastify';

const handleContextActionCancelThanks = function* handleContextActionCancelThanks({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'cancelThanks') {
        try {
            const answer = yield* call(confirmProise, {
                text: i18n.t('pryaniky.post.actions.confirm.cancelThanks')
            })

            if (answer === 'cancle') {
                yield* put(ACT.contextActionCancle({
                    id,
                    action
                }))
                return;
            }

            const request = yield* call(API.news.action, id, 14, '')
            // @ts-ignore
            const result = yield request.r
            if (result.error_code === 0) {
                yield* put(ACT.unsetNews({ id }))
            }
            else toast.error(result.error_text);
        } catch (error) {

        }
    }
    yield;

    return 0;
}

export default handleContextActionCancelThanks