/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";
// import { store } from "redux/store";
import { Actions } from '@jsonforms/core';
import defaultData from './data'
import uischema from './uischema'

const { types } = JFdictionary



const schema = {
  type: types.object,
  properties: {
    list: {
      type: types.array
    }
  }
}




export default schema