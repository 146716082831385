import React, { FC, useState, useEffect } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { WidgetDialogBox } from './AlertWidgetSettingsDialog.styled'
import { IAlertWidgetSettingsProps } from './AlertWidgetSettingsDialog.index'
import { DatePicker } from 'muicomponents/src/DatePicker/DatePicker'
import Stack from '@mui/material/Stack';
import Tooltip, { } from '@mui/material/Tooltip';
import { i18n, Translate } from 'localization';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import Close from '@mui/icons-material/Close';

export const AlertWidgetSettingsDialog: FC<IAlertWidgetSettingsProps> = ({
    onChange,
    data,
    settings
}) => {
    const [state, setState] = useState<typeof settings>(settings || {});
    console.log('settings', settings)

    const changeShow = (showDateTime: string | null) => setState({
        ...state,
        showDateTime
    })
    const changeHide = (hideDateTime: string | null) => setState({
        ...state,
        hideDateTime
    })
    useEffect(() => {
        onChange({
            settings: state,
        })

    }, [state]);
    // showDateTime
    // hideDateTime
    return (<WidgetDialogBox>
        <Stack gap={2} direction='row' alignItems={'center'}>
            <Stack gap={2} flex='1'>
                <DatePicker
                    label={i18n.t('pryaniky.widget_visible.settings.showDateTime')}
                    type='datetime'
                    value={state?.showDateTime || null}
                    onChange={(value) => changeShow(value)}
                    TextFieldProps={{
                        InputProps: {
                            endAdornment: <Tooltip title={i18n.t('pryaniky.widget_visible.settings.clean')}>
                                <IconButton onClick={() => changeShow(null)}><Close color='error' /></IconButton>
                            </Tooltip>
                        }
                    }}
                />
            </Stack>
        </Stack>
        <Stack gap={2} direction='row' alignItems={'center'}>
            <Stack gap={2} flex='1'>
                <DatePicker
                    label={i18n.t('pryaniky.widget_visible.settings.hideDateTime')}
                    type='datetime'
                    value={state?.hideDateTime || null}
                    onChange={(value) => changeHide(value)}
                    TextFieldProps={{
                        InputProps: {
                            endAdornment: <Tooltip title={i18n.t('pryaniky.widget_visible.settings.clean')}>
                                <IconButton onClick={() => changeHide(null)}><Close color='error' /></IconButton>
                            </Tooltip>
                        }
                    }}
                />
            </Stack>
        </Stack>
    </WidgetDialogBox>)
}



export const initWidgetAddition = () => {
    ComponentInjector.getInstance().addComponent('WAddition', AlertWidgetSettingsDialog, 'pryaniky/AlertWidget')
    ComponentInjector.getInstance().addComponent('WAddition', AlertWidgetSettingsDialog, 'pryaniky/wiki')
    ComponentInjector.getInstance().addComponent('WAddition', AlertWidgetSettingsDialog, 'pryaniky/html')
}
export const initWidgetSettings = () => {
    ComponentInjector.getInstance().addComponent('WSettings', AlertWidgetSettingsDialog, 'pryaniky/AlertWidget')
    ComponentInjector.getInstance().addComponent('WSettings', AlertWidgetSettingsDialog, 'pryaniky/wiki')
    ComponentInjector.getInstance().addComponent('WSettings', AlertWidgetSettingsDialog, 'pryaniky/html')
}
