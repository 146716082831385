import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ITagProps, TMapStateToProps, mapStateToProps, ITagOwnProps, TMapDispatchToProps, mapDispatchToProps, } from './InfoBlock-TagBlock.index';
import { compose } from '@bem-react/core';
import * as utils from 'utils/src/utils'
import MDRender from 'uielements/src/CommonmarkRender';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from 'muicomponents/src/Icons';
import { DraftInput, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { styled } from 'muicomponents/src';
import { i18n, Translate } from 'localization';
import { editCreateTag } from 'utils/src/requests/admin.tags';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import { TagBlockHeaderText } from './InfoBlock-TagBlock.styled';
import { IStateType } from 'redux/store';

const StyledEditBox = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0
});

export const TagInfoBlock: React.FC<ITagProps> = ({ tId, isEditable, isAdmin }) => {

  const [tag, setTag] = useState<any>(null);
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);

  const {
    state,
    draftState,
    setDraftState
  } = useDraftEdit(tag?.description || '', !edit, [edit, tag?.description])

  useEffect(() => {
    utils.API.suggesters.search('tags', tId).r.then((d) => {
      if (utils.checkResponseStatus(d)) {
        if (d.data[0]) setTag(d.data[0])
      }
    })
  }, [tId]);

  return (
    <div>
      {
        (isAdmin || isEditable) &&
        <StyledEditBox>
          {
            !edit
              ?
              <IconButton
                onClick={() => setEdit(true)}
                size={'small'}
              >
                <EditIcon fontSize={'small'} />
              </IconButton>
              :
              <>
                <IconButton
                  size={'small'}
                  onClick={async function () {
                    setSaving(true);
                    const newTag = {
                      ...tag,
                      description: state.text,
                      tagName: tag?.displayName || ""
                    };
                    const response = await editCreateTag(newTag);
                    if (utils.checkResponseStatus(response)) {
                      setTag(newTag);
                      setEdit(false)
                    } else {
                      toast.error(<Translate i18nKey='error' />);
                    }
                    setSaving(false);
                  }}
                >
                  {
                    saving
                      ? <CircularProgress size={20} />
                      : <CheckIcon fontSize={'small'} />
                  }
                </IconButton>
                <IconButton
                  size={'small'}
                  onClick={() => setEdit(false)}
                >
                  <CloseIcon fontSize={'small'} />
                </IconButton>
              </>
          }
        </StyledEditBox>
      }

      <TagBlockHeaderText variant='h6'>
        <Translate i18nKey={'pryaniky.tags.search.foundBy'} /> <span style={{ color: '#000' }}>{tag && tag.displayName}</span>
      </TagBlockHeaderText>

      {
        tag &&
        (
          edit
            ? <DraftInput label={<Translate i18nKey='description' />} value={draftState} onChange={setDraftState} />
            : (tag.description && tag.description !== '0') && <MDRender textCut={true} maxCharCount={250} source={tag.description} />
        )
      }
    </div>
  );
};

export const TagBlock = connect<TMapStateToProps, TMapDispatchToProps, ITagOwnProps, IStateType>(
  mapStateToProps,
  mapDispatchToProps
)(compose(
)(TagInfoBlock))