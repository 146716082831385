import React from 'react';
import { EventProps } from 'react-big-calendar';
import './CustomEvent.scss';
import { CustomEventBox, CustomEventLink } from './CustomEvent.styled';
import { BirthdayEvent } from './types/BirthdayEvent/BirthdayEvent';
import { CommonEvent } from './types/CommonEvent/CommonEvent';
import { LmsEvent } from './types/LmsEvent/LmsEvent';
import { WorkflowsEvent } from './types/WorkflowsEvent/WorkflowsEvent';

const eventsTypesMap = {
    events: CommonEvent,
    birthday: BirthdayEvent,
    lms: LmsEvent,
    workflow: WorkflowsEvent,
    // wikipagenews: CommonEvent
};

export const CustomEvent: React.FC<EventProps> = (props: any) => {
    const event = props.event as any;
    let href = event.url;

    const EventRender =
        event.componentRenderName in eventsTypesMap
            ? (eventsTypesMap as any)[event.componentRenderName]
            : () => <>type error: {event.componentRenderName}</>;
    return (
        <CustomEventLink to={href} styledEventColor={event.eventColor}>
            <CustomEventBox styledBorderColor={event.eventBorderColor} styledEventColor={event.eventColor}>
                <EventRender event={event} />
            </CustomEventBox>
        </CustomEventLink>
    );
};
