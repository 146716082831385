import * as React from 'react';
import * as utils from 'utils/src/utils';

import { EditorTypeEdit } from '../../../NewsTypes/Badges/Editor/containers/edit/edit'
import { withBemMod } from '@bem-react/core';

import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeBadgesProps } from './NewsEdit-Body_type_badges.index';
import './NewsEdit-Body_type_badges.scss';
import { IBodyProps } from '../NewsEdit-Body.index';
import { TextareaMentions } from 'blocks/TextareaMentions/TextareaMentions';

export const withBodyTypeBadges = withBemMod<IBodyTypeBadgesProps, IBodyProps>(
  cnBody(),
  { type: 'badges' }, 
  (Presenter) => (
    (props) => {
      const newPost: any = utils.cloneObject(props.post);
    return (
      <Presenter {...props}>
        {({ text, onChangeText }) => <EditorTypeEdit data={newPost} onChange={props.onChangePost} />
          /*<TextareaMentions
            // getClassRef={this.getTextareaMentions} 
            users={true}
            tags={true}
            onChange={onChangeText}
            // className={this.props.className} 
            icon={'edit'}
            value={text}
            placeholder={i18n.t('placeholders.textarea.news')} />*/
        }
      </Presenter>
    )
      }
  )
);