import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
// import { v1 as uuid } from "uuid";
// import { IPriorityNavTab } from "blocks/PriorityNav/PriorityNav.index";
// import { updateRequestOptions } from "../../redux/actions/News";
import { mapStateToProps, IUserStateProps, cnUser, IUserProps, IUserState, mapActionsToProps } from './User.index';
import './User.scss';
// import { toast } from 'react-toastify';
import { IWidget } from 'i.widgets';
import { Page } from '../Page/Page';
import { Redirect } from 'react-router';
import { i18n, Translate } from 'localization';
import { setContext } from 'redux/actions/Widgets';
import { PreviewParams } from "utils/src/PreviewParams";
import { pageNames } from 'redux/pageNames';

export class UserPresenter extends React.Component<IUserProps, IUserState> {
  public utils: any = utils;
  public el: HTMLElement | null;
  public redirect: string;
  public tabWidget: IWidget;

  public componentDidMount() {
    // чтобы при переходе на '/user' без id параметра редиректило на страницу авторизованного пользователя    
    if (!this.props.match.params.id) {
      this.props.history.replace(`/user/${this.props.authUser.baseData.id}`)
    }
    // if (this.props.setContext) this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {
    //     tab: this.props.match.params.tab,
    //     uId: this.props.match.params.id,
    //     mythanksCount: -1,
    //     thanksForAll: -1
    //   }
    // })
  }

  public componentWillUnmount() {
    // if (this.props.setContext) this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: {}
    // })
  }

  public componentDidUpdate(pp: IUserProps) {
    // if (!this.utils.compareData(this.props.match.params.id, pp.match.params.id)) {
    //   if (this.props.setContext) {
    //     this.props.updateContext('common', {
    //       uId: this.props.match.params.id,
    //       mythanksCount: -1,
    //       thanksForAll: -1
    //     })
    //   }
    // }
  }

  public render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {
          this.redirect && this.redirect !== '' && <Redirect to={this.redirectTo()} />
        }
        <Page
          className={cnUser({ id: this.props.match.params.id })}
          page={{
            name: pageNames.user + '.' + this.props.match.params.id,
            title: this.props.authUser.baseData.id === this.props.match.params.id ? i18n.t('me') : i18n.t('colleague'),
            params: { id: this.props.match.params.id, authUser: this.props.authUser }
          }} />
        {children}
      </React.Fragment>
    )
  }

  public redirectTo = () => {
    const url = this.redirect;
    this.redirect = '';
    return url;
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

  // private preparePage = (props: IUserProps) => {
  // this.props.changeVm('structure.' + pageName + '.' + props.match.params.id, pages[pageName]({id: props.match.params.id, authUser: props.authUser}));
  // }
}


export const User = connect<IUserStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({ ...mapActionsToProps })
)(UserPresenter)
//   compose(
//   withBemMod(cnUser(), {})
// )(UserPresenter))
