import React, { useEffect, useState } from 'react';
import './Widget_type_hierarchyNew.scss';
import * as utils from 'utils/src/utils';
import { useSelector } from 'react-redux';
import { i18n, Translate } from 'localization';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Paper, Typography } from 'muicomponents/src';
import { AvatarsTooltipList } from 'muicomponents/src/AvatarsTooltipList/AvatarsTooltipList';
import { IHierarchyInfo, IUsersHierarchyWidgetProps, cnHierarchyWidgetNew } from './Widget_type_hierarchyNew.index';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';
import * as utilsProj from 'utils.project/utils.project';
import { Structure } from './Components/Structure';
import { ContentItemsList } from './Components/ContentItemList';
import { mainUrls } from 'utils';
import { generatePath } from 'react-router';
import { openDialogShowAll } from './Components/DialogBodyShowAll';
import { useReffedState } from 'utils/src/hooks';

export const HierarchyWidgetNew = ({ ...props }: IUsersHierarchyWidgetProps) => {
    const { context, settings, tag: TagName = 'div' } = props;
    const authUser: any = useSelector(getAuthUser);

    const uId = context.uId || authUser.profileData.userId;
    const headOrSub = settings?.headusers ? 'headusers' : 'subusers';
    const head = settings?.headusers;
    const hierarchyName = settings?.hierarchy.name;

    const countOfVisibleItemsInWidget =
        settings?.itemCountView && settings?.hierarcheListView ? +settings?.itemCountView : 20;

    const [items, setItems, itemsRef] = useReffedState<any[]>([]);
    const [title, setTitle] = useState(i18n.t('pryaniky.user.settings.hierarchy.' + (head ? 'Head' : 'Sub')));
    const [loading, setLoading] = useState<boolean>(false);
    const [hierarchyInfoState, setHierarchyInfoState] = useState<IHierarchyInfo>({});
    const [isFirst, setIsFirst] = useState<boolean>(true);

    const onChangeItems = (v: any[]) => {
        hierarchyInfoState &&
            setHierarchyInfoState((prev) => {
                return {
                    ...prev,
                    totalUserCount:
                        (prev.totalUserCount || itemsRef.current.length) + (v.length - itemsRef.current.length),
                };
            });
        setItems(v);
    };

    const getData = (
        uId: string,
        skipCount: number = 0,
        count: number = 20,
        setLoading: (v: boolean) => void,
        setData?: (v: any) => void,
        isRestartItems: boolean = false
    ) => {
        settings?.hierarchy && setLoading(true);
        utils.API.hierarchy
            .getHierarchyByUserForWidget(skipCount, count, {
                selectedHeadUser: uId,
                hierarchyName,
                catfilter: headOrSub,
            })
            .r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    const { companyUsersCount, totalUserCount, isFinished } = d;
                    setHierarchyInfoState({
                        companyUsersCount,
                        totalUserCount,
                        isFinished,
                    });
                    const usersArr = d.data.map((user: { [s: string]: any }) => {
                        user.title = user.displayName;
                        user.url = generatePath(mainUrls.user.id, { id: user.id });
                        return user;
                    });
                    setItems((prev) => (isRestartItems ? usersArr : [...prev, ...usersArr]));
                    setData && setData({ ...d, data: usersArr });
                    if (head) {
                        setTitle(
                            settings?.hierarchy.headname
                                ? settings?.hierarchy.headname
                                : i18n.t('pryaniky.hierarchy.head')
                        );
                    } else {
                        setTitle(
                            settings?.hierarchy.subname
                                ? settings?.hierarchy.subname
                                : i18n.t('pryaniky.hierarchy.bottom')
                        );
                    }
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setIsFirst(true);
        getData(
            uId,
            0,
            countOfVisibleItemsInWidget,
            setLoading,
            () => {
                setIsFirst(false);
            },
            true
        );
        return () => {
            setIsFirst(false);
        };
    }, [uId]);

    const onClickShowMore = () => {
        openDialogShowAll({
            loadMore: (skipCount, setLoading, setData) => {
                getData(uId, skipCount, 20, setLoading, setData);
            },
            hierarchyName,
            onDelete,
            items,
            defaultIsFinished: hierarchyInfoState.isFinished,
            setItems: onChangeItems,
            title,
            ...props,
        });
    };
    const onDelete = (item: any, setData?: (v: any) => void) => {
        const topId = head ? item.id : context.uId || context.currentUserId;
        const bottomId = head ? context.uId || context.currentUserId : item.id;
        utilsProj.confirm({
            text: i18n.t('pryaniky.hierarchy.confirm.delete'),
            onConfirm: () => {
                utils.API.hierarchy.changeHierarchyAction(settings?.hierarchy.name, topId, bottomId, false);
                setItems((prev) => prev.filter((val: any) => val.id !== item.id));
                setData && setData(item);
                hierarchyInfoState &&
                    setHierarchyInfoState((prev) => {
                        return {
                            ...prev,
                            totalUserCount: prev?.totalUserCount && prev.totalUserCount - 1,
                        };
                    });
                return true;
            },
        });
    };

    useEffect(() => {
        if (
            typeof hierarchyInfoState.totalUserCount === 'number' &&
            hierarchyInfoState.totalUserCount > items.length &&
            items.length < countOfVisibleItemsInWidget &&
            settings?.hierarcheListView &&
            !isFirst
        ) {
            getData(uId, items.length, countOfVisibleItemsInWidget - items.length, setLoading);
        }
    }, [hierarchyInfoState.totalUserCount, items, countOfVisibleItemsInWidget, uId]);

    if (!settings || !hierarchyName) return null;
    if (loading) return <WidgetTypeSkeletonMUI />;
    if ((!items || !items.length) && !settings?.hideIfNoData && !authUser.baseData.isAdmin)
        return (
            <TagName {...props} className={cnHierarchyWidgetNew({})}>
                <PaperBox sx={{ margin: 0 }}>
                    <Typography fontWeight="700" className={cnHierarchyWidgetNew('Title')}>
                        {title}
                    </Typography>
                    <CardMedia
                        component="svg"
                        className={cnHierarchyWidgetNew('EmptyList-Avatar')}
                        sx={{
                            width: '100%',
                            height: '200px',
                            marginBottom: '12px',
                        }}
                        image={EmptyWidget}
                    />
                    <Typography>
                        <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
                    </Typography>
                </PaperBox>
            </TagName>
        );
    if ((!items || !items.length) && settings?.hideIfNoData && !authUser.baseData.isAdmin)
        return (
            <TagName
                {...{ ...props, isHidden: items.length === 0 || !items.length }}
                className={cnHierarchyWidgetNew({})}
            ></TagName>
        );
    return (
        <TagName {...props} className={cnHierarchyWidgetNew({})}>
            <Paper sx={{ padding: '24px' }}>
                <Structure {...props} items={items} setItems={onChangeItems} title={title}>
                    {items.length > 0 ? (
                        settings.hierarcheListView ? (
                            <ContentItemsList
                                {...props}
                                items={items}
                                onDelete={onDelete}
                                countOfVisibleItemsInWidget={countOfVisibleItemsInWidget}
                                totalCount={hierarchyInfoState.totalUserCount}
                                onClickShowMore={onClickShowMore}
                            />
                        ) : (
                            <AvatarsTooltipList
                                items={items}
                                count={6}
                                size={40}
                                onDelete={authUser.baseData.isAdmin && hierarchyName ? onDelete : undefined}
                                totalCount={hierarchyInfoState?.totalUserCount}
                                onShowAll={onClickShowMore}
                                loadMore={(skipCount, count) => {
                                    getData(uId, skipCount, count, setLoading);
                                }}
                            />
                        )
                    ) : (
                        <Translate i18nKey={'pryaniky.list.wiki.emptyList.title'} />
                    )}
                </Structure>
            </Paper>
        </TagName>
    );
};
