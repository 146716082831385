

import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import * as ACT from '../../../actions';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import {DialogSubmitForCorrection} from 'blocks/Dialogs/News/SubmitForCorrection/SubmitForCorrection'

const handleContextActionSubmitForCorrection = function* handleContextActionSubmitForCorrection({ payload }: AT.AContextAction) {
    const { action, id } = payload

    if (action === 'submitForCorrection') {
        try {
            // const result ={comment:"acmacmacm"}
            const result =yield* call(DialogSubmitForCorrection,{})
            // yield* put(ACT.moveToGroup({ newsId: id, group: result.group }))
            yield* put(ACT.submitForCorrection({ id, needSendNotification: result.needSendNotification, comment: result.comment }))

        } catch (error) {
            console.error('dismis', error)
        }
    }
    yield;

    return 0;
}

export default handleContextActionSubmitForCorrection