import { IExpertEvalutionResultsNewProps, cnExpertEvalutionResults } from './ExpertEvalutionResultsNew.index';
import * as React from 'react'
import './ExpertEvalutionResultsNew.scss'
// import { expertResults } from '../IdeasSides';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { UserList } from 'uielements/src/UserList/UserList';
import { IdeaFieldView } from '../Fields/view/Field';
import { normalize, schema } from 'normalizr';
import { cloneObject } from 'utils/src/utils';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import Tooltip from 'muicomponents/src/Tooltip/Tooltip';
import { StickyTable } from 'uielements/src/StickyTable/StickyTable';
import { ActionButtons } from '../../../ActionButtons/ActionButtons';
import { FieldsHeader } from '../FieldsHeader/FieldsHeader';
import { i18n, Translate } from 'localization';
import { Icon } from 'uielements/src';
import { confirm } from 'utils.project/utils.project';
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { setExperts } from '../redux/actions'
import { useLazyQuery } from 'utils/src/hooks'
import {
    API
} from 'utils/src/utils'
import { ActionButtonsNew } from '../../../ActionButtonsNew/ActionButtonsNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createTheme } from '@mui/material/styles';
import { createModal, useDialogContext, TransitionComponent } from 'utils/src/DialogCreator';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import { CircularProgress } from '@material-ui/core';
import { Button } from 'muicomponents/src/Button/Button'
// import { Button } from "uielements/src/Button/Button";

const normalizeData = (data: any) => {
    const clone = cloneObject(data);
    const field = new schema.Entity('fields', { field: {} }, { idAttribute: (value) => value.field.id });
    const categorys = new schema.Entity('categorys', { fields: [field] }, { idAttribute: 'category' });
    const fieldsSchema = new schema.Object({ fields: new schema.Array(categorys) });

    const normalizedData = normalize(clone, fieldsSchema);
    return normalizedData;
}



const createTableModel = (value: any, deleteExpert: any, showDeleteExpers: boolean) => {
    const rowHeaders: React.ReactNode[] = [];
    const experts = value.result.expertises.map((el: any) => el.user)

    const tRows: any = [];
    const colHeader = value.result.expertises.map((val: any) => {
        return <div className="ExpertAvatar">

            <UserMention className="Expert" key={val.user.id} id={val.user.id} name={val.user.displayName} avatar={{ size: 40, imgUrl: val.user.userPhotoUrl }} />
            {showDeleteExpers && <div className="ExpertDelete" onClick={() => deleteExpert(experts, val.user.id)}><Icon icon='cross-mark' /></div>}
        </div>
    })
    tRows.push({
        cells: value.result.expertises.map((v: any, i: number) => {
            return <div>{v.expStatus === "completed" ? <CheckCircleIcon sx={{ color: '#68dd00' }} /> : "—"}</div>
        })
    });
    rowHeaders.push(<h4 className="AgreedToRate">Согласились оценить</h4>);
    value.result.fields.forEach((val: any) => {
        val !== '' && rowHeaders.push(<h4 className="CategoryName">{val}</h4>);//
        val !== '' && tRows.push({ cells: [] });

        value.entities.categorys[val].fields.forEach((c: any) => {
            const currentField = value.entities.fields[c];
            if (currentField.field.fieldType === "MatrixOnePerRow") {

            } else {
                rowHeaders.push(
                    <Tooltip placement='left' title={currentField.field.description}>
                        <div>{currentField.field.name}</div>
                    </Tooltip>
                )
            }

            if (currentField.field.fieldType === "MatrixOnePerRow") {

                currentField.field.rows.forEach((row: any, rowIndex: number) => {

                    rowHeaders.push(row.name);
                    const matrixRows = {
                        cells: value.result.expertises.map((v: any, i: number) => {
                            return currentField.expertAnswers[rowIndex][i] !== '-' ? <div className='MatrixAnswer'>
                                <Tooltip placement='left' title={currentField.field.answers[currentField.expertAnswers[rowIndex][i]].text}>
                                    <span>{currentField.field.answers[currentField.expertAnswers[rowIndex][i]].text}
                                    </span>
                                </Tooltip>
                            </div> : '—';
                        })
                    };

                    tRows.push(matrixRows);
                })
            }
            else {
                const fieldsN: any = {};
                fieldsN.answers = currentField.expertAnswers;
                fieldsN.values = currentField.expertAnswers;
                fieldsN.value = currentField.expertAnswers;
                fieldsN.field = currentField.field;

                switch (currentField.field.fieldType) {
                    case "ResponsibleForStatusUser":
                    case "OfferResponsibleForStatusUsers":
                        const userObj = {
                            cells: currentField.expertAnswers.map((v: any, i: number) => {
                                return currentField.expertAnswers[i].length !== 0 ? <UserList cut={false} action={false} key={i} activeAction={false} data={currentField.expertAnswers[i]} /> : '—';

                            })
                        };
                        tRows.push(userObj);
                        break;
                    case "Tags":
                        const tagsObj = {
                            cells: currentField.expertAnswers.map((v: any, i: number) => {

                                return currentField.expertAnswers[i].length !== 0 ? <div className="Tag">{currentField.expertAnswers[i].map((val: any) => (
                                    <Button
                                        sx={{
                                            maxWidth: "150px",
                                            justifyContent: "left",
                                            padding: "4px",
                                            "span": {
                                                maxWidth: "150px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }

                                        }}
                                        variant='text'
                                        href={"tag/" + val.id}>
                                        {/* <div className="TagLable"> */}
                                        <span>{"#" + val.displayName}</span>
                                        {/* </div> */}

                                    </Button>
                                ))}</div> : '—';

                            })
                        };
                        tRows.push(tagsObj);
                        break;
                    case "String":
                        // case "Textarea":
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                fieldsN.value = currentField.expertAnswers[i].join() || ''
                                fieldsN.values = currentField.expertAnswers.length > i ? currentField.expertAnswers[i] : [];
                                const newFie = { ...fieldsN };

                                return <div className="TextField">{newFie.value.indexOf(" ", 0) > 15 || (newFie.value.length > 15 && newFie.value.indexOf(" ", 0) === -1) ?
                                    <Tooltip placement='left' title={newFie.value}>
                                        <span>
                                            {newFie.value.slice(0, 15) + "..."}
                                        </span>
                                    </Tooltip> :
                                    newFie.value.length === 0 ? <>—</> : newFie.value}</div>
                            })
                        });

                        break;
                    case "Textarea":
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                fieldsN.value = currentField.expertAnswers[i].join() || ''
                                fieldsN.values = currentField.expertAnswers.length > i ? currentField.expertAnswers[i] : [];
                                const newFie = { ...fieldsN };
                                if (newFie.value.length > 0) {
                                    return <IdeaFieldView field={newFie} key={i} />
                                } else {
                                    return <>—</>
                                }

                            })
                        });

                        break;
                    case "Single":
                        const single = {
                            cells: currentField.expertAnswers.map((v: any, i: number) => {
                                const findAnswered = fieldsN.answers[i].findIndex((el: any) => el === "True")

                                if (findAnswered !== -1) {

                                    const newFie = { ...fieldsN.field.answers[findAnswered], selected: true };

                                    return <div className="SingleTypeFieldAnswer">{newFie.text}</div>
                                } else {
                                    return <>—</>
                                }
                            })
                        };
                        tRows.push(single);
                        break;

                    case "Multi":
                        const multy = {
                            cells: currentField.expertAnswers.map((v: any, i: number) => {
                                const findAnswered = fieldsN.answers[i].map((el: any, index: number) => {

                                    if (el === "True") {
                                        return { ...fieldsN.field.answers[index], selected: true }

                                    } else if (el === "False") return el
                                })

                                return findAnswered.map((el: any) => {
                                    if (el !== "False") {
                                        return <div className="SingleTypeFieldAnswer">{el.text}</div>
                                    }
                                })
                            })
                        };
                        tRows.push(multy);
                        break;

                    default:
                        tRows.push({
                            cells: value.result.expertises.map((v: any, i: number) => {
                                fieldsN.value = currentField.expertAnswers.length > i ? (fieldsN.answers[i][0] ? fieldsN.answers[i][0] : '') : '';
                                fieldsN.values = currentField.expertAnswers.length > i ? currentField.expertAnswers[i] : [];
                                const newFie = { ...fieldsN };
                                return <IdeaFieldView field={newFie} key={i} />
                            })
                        });
                }

            }
        })
    })
    return { rows: tRows, rowHeaders, colHeader };
}

export const ExpertEvalutionResultsNewPresenter: React.FC<IExpertEvalutionResultsNewProps> = ({ ideaId, statusID, showDeleteExpers, handleAccept, handleClose, anchor }) => {
    const { open } = useDialogContext();

    React.useEffect(() => {
        if (open) query()

    }, []);

    const [
        query,
        isLoading,
        result,
        isError,
        count,
        setResult,
        // errorInfo,
        // errorStr
    ] = useLazyQuery(async function () {
        return await (await API.news.ideas.getExpertiseResult2(ideaId, statusID)).r
    }, (response) => {
        if (response.error_code === 0) {
            return createTableModel(normalizeData((response as any).data), deleteExpert, showDeleteExpers)
            // return createTableModel(normalizeData((wtf as any).data), deleteExpert, showDeleteExpers)
        } else throw new Error('error_load_results')
    })



    const { dispatchActionParam } = useLazyExecutSaga(() => setExperts({
        ideaId,
        experts: [],
        newExperts: []
    }), [ideaId])
    const [deleteloading, setDeleteLoading] = React.useState<boolean>(false)
    const deleteExpert = (experts: any[], userId: string) => {
        const newExperts = experts.filter((el) => el.id !== userId);
        confirm({
            text: 'Удалить эксперта?',
            onConfirm: () => {
                setDeleteLoading(true)
                setTimeout(() => {
                    setDeleteLoading(false)
                    query()
                }, 3000);

                dispatchActionParam({
                    payload: {
                        ideaId,
                        experts,
                        newExperts
                    }
                })
            }
        })
    }

    return (<>
        <DialogTitle onClose={handleClose}>
            {i18n.t('pryaniky.idea.evalution.results')}

        </DialogTitle>

        <DialogContent className={cnExpertEvalutionResults("Content")}>

            {isLoading || deleteloading ?
                <div className={cnExpertEvalutionResults('Content-Loading')}><CircularProgress /></div> :
                result && <StickyTable rowsHeaders={result.rowHeaders} columnsHeaders={result.colHeader} rows={result.rows} />}


        </DialogContent>
        {/* <DialogContent className={cnExpertEvalutionResults("Content")}>

            {isLoading &&
                <div className={cnExpertEvalutionResults('Content-Loading')}><CircularProgress /></div>
            }
            {result && <StickyTable rowsHeaders={result.rowHeaders} columnsHeaders={result.colHeader} rows={result.rows} />}


        </DialogContent> */}

        <DialogActions
            closeText={i18n.t("cancel")}
            onClose={handleClose}
        />
    </>
    );

}


export const ExpertEvalutionResultsNew = ExpertEvalutionResultsNewPresenter;

export const DialogExpertEvalutionResultsNew = createModal(ExpertEvalutionResultsNew, {
    maxWidth: 'lg',

    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});