import React, { FC, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
// import fieldWithHelp from 'uielements/src/MaterialElements/fieldWithHelp'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';

import DeleteButton from '../../../components/DeleteButton'
// const TextFieldHelped = fieldWithHelp(TextField)


export const Presenter: FC<IPresenterProps> = ({ 
    className, 
}) => {
    return <div className={cnClassName({}, [className])}>

    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
