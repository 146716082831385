import React, { FC, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { withNewsContext } from '../../contexts/news'
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import { compact } from 'lodash'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'
import { i18n, Translate } from 'localization';

type IPresenterProps = News.ContextMenu.IPresenterProps
type IOwnProps = News.ContextMenu.IOwnProps
type IDispatchProps = News.ContextMenu.IDispatchProps
type IStateProps = News.ContextMenu.IStateProps
type IState = News.ContextMenu.IState


const disabledActions: string[] = [
    'invest', 'thanksInComment', 'newslink'
];

export const Presenter: FC<IPresenterProps> = ({
    id,
    actions,
    getNode,
    children,
    contextAction,
    showAlways,
    className,
    edit,
    disableEdit = false
}) => {
    const clickAction = (action: string) => contextAction({ action, id })
    const notShow = compact([
        ...disabledActions, 
        (edit ? 'edit' : ''),
        (disableEdit ? 'edit' : '')
    ])
    const actionsWithContent = actions.filter(action => !notShow.includes(action)).map(action => ({ 
        content: i18n.t('pryaniky.post.actions.' + action),
        action
    }))
    return <ContextMenuComponent
        className={cnClassName({ showAlways }, [className])}
        actions={actionsWithContent}
        onClick={clickAction}
        renderReplacer={getNode}
    />
}

export default withNewsContext(withComponentEnjector(connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter)), ['news_service_actions']));
