import React, { FC, ComponentProps, useState, useMemo } from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { cnLeftMenuItemChange, NLeftMenuItemChange } from './LeftMenuItemChange.index';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { TextField } from 'muicomponents/src/TextField';
import { FormControl, FormControlLabel, InputLabel } from 'muicomponents/src/FormControl';
import { GroupsSuggester, IconsSuggester } from 'muicomponents/src/Suggester';
import { Select } from 'muicomponents/src/Select/Select';
import { Button } from 'muicomponents/src/Button/Button';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox';
import { i18n, Translate } from 'localization';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { styled } from 'muicomponents/src/mui/system';
import { Link } from 'muicomponents/src/Icons/Icons';
import { iconsPack } from './icons';
import { isOldIcon } from 'blocks/LeftMenuMui/LeftMenu.hooks';
import { StyledOldIcon } from '../../LeftMenu.styled';
import { IconButton } from 'muicomponents/src';
import { Close as CloseIcon } from 'muicomponents/src/Icons';
import { leftMenuDefaultBlockType } from 'blocks/LeftMenuMui/redux/constants';
import { useSelector } from 'react-redux';
import { getLeftMenuReducerField } from 'blocks/LeftMenuMui/redux/selectors';
import { ItemBlockType, ItemRenderType } from 'utils/src/requests/models/api.menu';

const useChange = (startedData: NLeftMenuItemChange.Props['data']) => {

    const [data, setData] = useState<typeof startedData>(JSON.parse(JSON.stringify(startedData)));
    const favouriteIsCreated = useSelector(getLeftMenuReducerField('favouriteItemId'));

    const changeData = (changed: Partial<typeof startedData>) => {
        setData({
            ...data,
            ...changed
        });
    };

    useDidUpdateEffect(() => {
        setData(startedData);
    }, [JSON.stringify(startedData)]);

    return {
        data,
        favouriteIsCreated: !!favouriteIsCreated,
        changeData
    };
};

const StyledDialogContent = styled(DialogContent)({
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
});

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'
});


const LeftMenuItemChangePresenter: FC<NLeftMenuItemChange.Props> = ({
    type,
    data: propsData,
    handleClose,
    handleAccept
}) => {

    const {
        data,
        favouriteIsCreated,
        changeData
    } = useChange(propsData);

    const blockTypeOptions = useMemo<(typeof data.blockType)[]>(() => [ ItemBlockType.groups, ItemBlockType.tags, ItemBlockType.apps, ItemBlockType.wiki, ItemBlockType.shop, ItemBlockType.favourites ]  , []);
    
    const blockTypeValue = useMemo<typeof data.blockType | ''>(() => {
        if(!blockTypeOptions.includes(data.blockType)) return '';
        return data.blockType;
    }, [blockTypeOptions, data.blockType]);

    const blockTypeDataOptions = useMemo(() => {
        return blockTypeOptions.filter(el => el !== ItemBlockType.favourites || (!favouriteIsCreated || data.blockType === ItemBlockType.favourites)).map(key => ({ value: key, title: <Translate i18nKey={`pryaniky.leftMenu.item.blockType.${key}`} /> }))
    }, [blockTypeOptions, favouriteIsCreated, data.blockType]);
    
    const renderTypeData = useMemo<(typeof data.renderType)[]>(() => [ ItemRenderType.dropdown, ItemRenderType.link ], []);

    const renderTypeDataOptions = useMemo(() => {
        return renderTypeData.map(key => ({ value: key, title: <Translate i18nKey={`pryaniky.leftMenu.modal.add.type.${key}`} /> }))
    }, [renderTypeData]);

    const selectedIcon = useMemo(() => {
        if(!data.icon) return null;
        // check if first letter is upper? if true then think icon id old and make additional option
        if(isOldIcon(data.icon)) {
            if(!iconsPack.includes(data.icon)) iconsPack.push(data.icon);
            return {
                displayName: data.icon,
                id: data.icon,
                imgId: '',
                imgUrl: <StyledOldIcon className={`Icon Icon_${data.icon}`}/>,
                type: 'additional'
            } as NonNullable<ComponentProps<typeof IconsSuggester>['additionalOptions']>[number]
        };
        const name = data.icon.replace(/Outlined|Rounded|Sharp|TwoTone/g, '');
        return {
            displayName: name,
            id: name,
            imgId: '',
            imgUrl: '',
            type: ''
        } as NonNullable<ComponentProps<typeof IconsSuggester>['options']>[number]
    }, [data.icon]);

    const additionalIcons = useMemo(() => {
        return iconsPack.sort().filter(Boolean).map((name) => ({
            displayName: name,
            id: name,
            imgId: '',
            imgUrl: <StyledOldIcon className={`Icon Icon_${name}`}/>,
            type: 'additional'
        }) as NonNullable<ComponentProps<typeof IconsSuggester>['additionalOptions']>[number]);
    }, [data.icon]);

    return (
        <div className={cnLeftMenuItemChange()}>
            <DialogTitle
                onClose={handleClose}
            >
                <Translate i18nKey={`pryaniky.leftMenu.modal.${type}.title`} />
            </DialogTitle>
            <StyledDialogContent>
                <TextField
                    fullWidth
                    value={data.title}
                    label={<Translate i18nKey={`pryaniky.leftMenu.modal.change.itemName`} />}
                    onChange={(e) => changeData({ title: e.target.value })}
                    onBlur={() => changeData({ title: data.title.trim() }) }
                />
                <FormControl>
                    <InputLabel htmlFor="change-blockType-label"><Translate i18nKey={`pryaniky.leftMenu.modal.change.blockType`} /></InputLabel>
                    <Select
                        labelId="change-blockType-label"
                        options={blockTypeDataOptions}
                        value={blockTypeValue}
                        label={<Translate i18nKey={`pryaniky.leftMenu.modal.change.blockType`} />}
                        onChange={(event) => changeData({
                            blockType: event.target.value as typeof data.blockType,
                            renderType: ItemRenderType.dropdown,
                            isAllowAddToFavorites: false
                        })}
                        sx={{
                            ['&:not(:hover) .SelectClearValue']: {
                                display: 'none'
                            }
                        }}
                        endAdornment={
                            <IconButton className='SelectClearValue' sx={{ marginRight: '8px' }} onClick={() => changeData({ blockType: leftMenuDefaultBlockType })}>
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                </FormControl>
                {
                    type === 'add' &&
                    <FormControl>
                        <InputLabel htmlFor="change-type-label"><Translate i18nKey={`pryaniky.leftMenu.modal.change.type`} /></InputLabel>
                        <Select
                            labelId="change-type-label"
                            disabled={!!blockTypeValue}
                            options={renderTypeDataOptions}
                            value={data.renderType}
                            label={<Translate i18nKey={`pryaniky.leftMenu.modal.change.type`} />}
                            onChange={(event) => changeData({
                                renderType: event.target.value as typeof data.renderType,
                                isAllowAddToFavorites: event.target.value === 'dropdown'
                            })}
                        />
                    </FormControl>
                }
                {
                  data.renderType === 'link' &&
                  <TextField
                    fullWidth
                    value={data.url}
                    label={<Translate i18nKey={`pryaniky.leftMenu.modal.change.link`} />}
                    onChange={(e) => changeData({ url: e.target.value })}
                    onBlur={() => changeData({ url: data.url.trim() }) }
                    InputProps={{
                        startAdornment: <Link />
                    }}
                  />
                }
                <GroupsSuggester
                    TextFieldProps={{
                        fullWidth: true,
                        label: <Translate i18nKey={'pryaniky.leftMenu.modal.change.groups'} />,
                        helperText: <Translate i18nKey={'pryaniky.leftMenu.modal.change.groups.helperText'} />
                    }}
                    multiple
                    // TODO check types
                    value={data.groups as any}
                    // TODO check types
                    onChange={(e, groups) => changeData({ groups: groups as any })}
                />
                <IconsSuggester
                    TextFieldProps={{
                        fullWidth: true,
                        label: <Translate i18nKey={'pryaniky.leftMenu.modal.change.icon'} />,
                        helperText: <Translate i18nKey={'pryaniky.leftMenu.modal.change.icon.helperText'} />
                    }}
                    additionalOptions={additionalIcons}
                    additionalOptionsAdd={'prepend'}
                    value={selectedIcon}
                    // TODO check types
                    onChange={(e, selected) => {
                        const icon = selected as Extract<ComponentProps<typeof IconsSuggester>['value'], { id: string }> | undefined;
                        changeData({ icon: icon?.id });
                    }}
                />
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            checked={data.showOnMobile}
                            onChange={(e) => changeData({ showOnMobile: e.target.checked })}
                        />
                    }
                    label={<Translate i18nKey='pryaniky.leftMenu.modal.change.showOnMobile' />}
                />
                {
                    data.renderType === 'link' &&
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                checked={data.openInNewTab}
                                onChange={(e) => changeData({ openInNewTab: e.target.checked })}
                            />
                        }
                        label={<Translate i18nKey='pryaniky.leftMenu.modal.change.openInNewTab' />}
                    />
                }
            </StyledDialogContent>
            <DialogActions
                onAccept={() => {
                    handleAccept(data);
                }}
                acceptText={<Translate i18nKey={`save`} />}
                onClose={handleClose}
                closeText={<Translate i18nKey={`cancel`} />}
            >
            </DialogActions>
        </div>
    )
};



export const LeftMenuItemChange = createModal(LeftMenuItemChangePresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});