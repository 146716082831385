import { IParticipantsBoxProps } from './ParticipantsBox.index'
import * as React from 'react'
import './ParticipantsBox.scss';
import { Icon } from 'uielements/src/Icon/Icon'
import { UserList } from 'uielements/src/UserList/UserList';
import { AdditionalBox } from '../../common/AdditionalBox/AdditionalBox';
import { i18n, Translate } from 'localization';
import { toggleUserInArray, userInArray } from '../../NewsUtils';
import { toggleParticipants, toggleHeads } from '../redux/actions'
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'

import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'

type TDisplayNameFilter<T extends { displayName: string } = { displayName: string }> = (filter: string) => (val: T) => boolean
const displayNameFilter: TDisplayNameFilter = (filter) =>
    (val) =>
        val.displayName.toLowerCase().split(' ').some((str) =>
            str.startsWith(filter.trim().toLowerCase()))


const createInvestorsDialog = () => {
    return ({
        participants
    }: {
        participants: any[],
    }) =>
        dialog({
            method: async function (skipCount, { count, search }) {
                return participants.filter(displayNameFilter(search)).slice(skipCount, skipCount + count).map((item: any) => ({ ...item, url: `/user/${item.id}` }))
            },
            ItemComponent: UsersListItem,
            listBodyProps: {
                header: i18n.t('pryaniky.idea.investors'),
            }
        })
}
const investorsDialog = createInvestorsDialog()
export const ParticipantsBox: React.FC<IParticipantsBoxProps> = ({
    changeResponsibleForStatusUser,
    responsibleForStatusUser,
    ideaHeads,
    ideaInvestors,
    ideaParticipants,
    currentUser,
    ideaId,
    ...props
}) => {
    const isHead = userInArray(ideaHeads, currentUser.id);
    const isParticipant = userInArray(ideaParticipants, currentUser.id);
    const { dispatchAction: executTogglePart, isLoading: isLoadingTogglePart } = useLazyExecutSaga(() => toggleParticipants({ ideaId, set: isParticipant }), [ideaId, isParticipant])
    const { dispatchAction: executToggleHead, isLoading: isLoadingToggleHead } = useLazyExecutSaga(() => toggleHeads({ ideaId, set: isHead }), [ideaId, isHead])

    const actionBtn = (state: boolean) => state ? <Icon icon="plus" /> : <Icon icon="minus" />;
    const investorsActionsActive = (ideaInvestors.length - 10) > 0
    return <div className={'ParticipantsBox'} {...props}>

        {!!responsibleForStatusUser.length && <AdditionalBox headerText={i18n.t("pryaniky.idea.responsible.for.status.user")}>
            <UserList cut={true} action={false} activeAction={false} data={responsibleForStatusUser} />
        </AdditionalBox>}

        {/* <UserList cut={false} action={false} activeAction={false} data={field.users} /> */}
        {ideaInvestors.length > 0 && <AdditionalBox headerText={i18n.t('pryaniky.idea.investors')}>
            <UserList cut={false} action={investorsActionsActive} maxVisible={10} actionCallback={() => {
                investorsDialog({ participants: ideaInvestors }).catch(() => { }).catch(() => { })
            }} activeAction={investorsActionsActive} data={ideaInvestors} actionBtnContent={<>+{ideaInvestors.length - 10}</>} />
        </AdditionalBox>}

        <AdditionalBox headerText={ideaParticipants.length ? i18n.t('pryaniky.idea.participants') : i18n.t('pryaniky.idea.no.participants')}>
            <UserList
                cut={false}
                actionDisabled={isLoadingTogglePart}
                action={true}
                activeAction={true}
                actionCallback={executTogglePart}
                data={ideaParticipants}
                actionBtnContent={actionBtn(isParticipant)} />
        </AdditionalBox>

        <AdditionalBox headerText={ideaHeads.length ? i18n.t('pryaniky.idea.heads') : i18n.t('pryaniky.idea.no.heads')}>
            <UserList
                cut={false}
                action={true}
                actionDisabled={isLoadingToggleHead}
                activeAction={true}
                actionCallback={executToggleHead}
                data={ideaHeads}
                actionBtnContent={actionBtn(isHead)} />
        </AdditionalBox>

    </div>
}