import React, { useState, FC, useMemo } from 'react'
import { CalendarEventLayout } from 'muicomponents/src/CalendarEventLayout/CalendarEventLayout'
import { formatDateSTD } from 'utils/src/utils.dates'
import { i18n, Translate } from 'localization';
import { IUser } from '../../EventItem.index'
import { addUserToArray, removeUserFromArray } from 'blocks/NewsTypes/NewsUtils'
import { participantAction } from 'blocks/NewsTypes/Events/redux/actions'
import { goingStatuses } from 'blocks/NewsTypes/Events/redux/actions.d'
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'

export const CommonEvent: FC<any> = ({
    event,
    updateCalendarEvent,
    user
}) => {
    const { dispatchActionParam, isLoading } = useLazyExecutSaga(() => participantAction({ id: event.newsUid, status: 'going' }), [])
    const changeStatus = (status: string) => {
        const oldLength = event.data.eventParticipants.length
        const cUser: IUser = {
            id: user.id,
            displayName: user.displayName,
            status: user.status,
            imgUrl: user.userPhotoUrl
        };

        const needRecalcCount = event.data.eventStatus === 'going';

        updateCalendarEvent({
            id: event.id,
            eventStatus: status,
            data: {
                ...event.data,
                eventStatus: status,
                eventParticipantsCount: status === 'going' ? oldLength + 1 : needRecalcCount ? oldLength - 1 : oldLength,
                eventParticipants: status === 'going' ? addUserToArray(event.data.eventParticipants, cUser) : removeUserFromArray(event.data.eventParticipants, cUser),
            },

        })
        dispatchActionParam({ payload: { id: event.newsUid, status: status as goingStatuses } })
    }
    
    const disabledGoingButton = useMemo(() => {
        if(event.data.maxParticipantsCount !== null) {
            let value = event.data.maxParticipantsCount - event.data.eventParticipantsCount;
            if(value < 0) value = 0;
            return !Boolean(value);
        }
        return false;
    }, [event.data.maxParticipantsCount, event.data.eventParticipantsCount, event.data.eventStatus]);

    const menuOptions = [
        { content: <Translate i18nKey={disabledGoingButton ? 'pryaniky.post.event.allPlacesIsFull' : 'going'} />, id: 'going', disabled: disabledGoingButton },
        { content: <Translate i18nKey={'maybe'} />, id: 'maybe' },
        { content: <Translate i18nKey={'no'} />, id: 'no' },
    ]
    const selectedMenuOptions = menuOptions.find((v) => v.id === event.data.eventStatus)

    const details = useMemo(() => {
        const detailsArray = [
            { keyData: i18n.t('pryaniky.calendar.item.CommonEvent.start'), value: formatDateSTD(event.start, true) },
            { keyData: i18n.t('pryaniky.calendar.item.CommonEvent.end'), value: formatDateSTD(event.end, true) },
            { keyData: i18n.t('location'), value: event.data.eventLocation }
        ]
        if(event.data.maxParticipantsCount !== null) {
            let value = event.data.maxParticipantsCount - event.data.eventParticipantsCount;
            if(value < 0) value = 0;
            detailsArray.push({ keyData: i18n.t('pryaniky.calendar.item.CommonEvent.maxParticipantsCount'), value: value.toString() });
        }
        return detailsArray;
    }, [event.start, event.end, event.data.eventLocation, event.data.maxParticipantsCount, event.data.eventParticipantsCount]);

    return <CalendarEventLayout
        headerText={event.title || 'Event'}
        link={event.url}
        users={event.data.eventParticipants}
        statusText={event.state === 'Completed' && <Translate i18nKey={'event_end'} />}
        menuOptions={menuOptions}
        selectedMenuOptions={selectedMenuOptions}
        enableAction={event.state !== 'Completed'}
        handleMenuItemClick={(e, item) => changeStatus(item.id)}
        disabledMenu={isLoading}
        menuIsLoading={isLoading}
        details={details.filter((v) => Boolean(v.value))}
    />
}