import React, { FC } from 'react'
import { IActionProps } from './Action.index'
import { Button, Icon, HelpTooltip } from 'uielements/src'
import { TOptions } from 'i18next';
import { i18n, Translate } from 'localization';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

const tt = (key: string, options: TOptions | string = {}) => i18n.t(`pryaniky.editor.common.tools.tooltip.${key}`, options)

export const Action: FC<IActionProps> = ({ onClick }) => {
    return (<Button theme={'unstyled_center'} onClick={onClick}>
        <Tooltip title={tt('search_mention')} >
            <span><Icon icon={'user-tag'} /></span>
        </Tooltip>
    </Button>
    )
}