import React, { FC, useEffect } from 'react'
import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { ItemsListBody } from 'muicomponents/src/ItemsListDialog/ItemsListBody/ItemsListBody';
import { FileListItem } from 'muicomponents/src/ItemsListDialog/FileListItem/FileListItem';
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { connect, useSelector } from 'react-redux';
import { mapStateToProps, mapDispatchToProps, TListStateProps, TMapDispatchProps, IListProps, getQuerySearchSelector, getFileFilter, getAdditionalFilterByName } from '../../index';
import { IStateType as IState } from 'redux/store';
import { useDidUpdateEffect, useScrollListState, useSearchParams } from 'utils/src/hooks';
import { search as list } from 'utils/src/requests/requests.suggesters';
import { TFilesListResponse } from './List_files.index'
import { i18n, Translate } from 'localization';
import { NothingFoundText } from '../NotingFoundText'


let filesSearchRequest: ReturnType<typeof list>;

/**
 * список работает тольок на странице поиска
 * обратите внимание на использование useSearchParams
 */
export const ListFilesPresenter: FC<IListProps> = ({ context, afterStateChangedCallback, afterGetDataCallback }) => {
  return (
    <ItemsList
      BodyComponent={ItemsListBody}
      ItemComponent={FileListItem}
      listHeaderProps={{ disableSearch: true, disableActionsButton: true }}
      listBodyProps={{ joinBody: true, disableBorder: true, disablePadding: true }}
      listComponentProps={{
        textEmpty: !context.search ? <Translate i18nKey='pryaniky.timeline.notFound.no.searchQuery.text' /> : <NothingFoundText />,
      }}
      listStateHook={({ defaultSelected }, middlewareParams) => {
        const {
          changeSearch,
          onChangeTab
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        // данный параметр изменяется только на странице поиска
        const [{ search: querySearch = '' }] = useSearchParams() as any as [{ search: string | undefined }];

        const fileFilter = useSelector(getAdditionalFilterByName('files'));
        // получаем список файлов 
        const state = useScrollListState(async function (skipCount, count) {

          if (!querySearch) return [];

          if(filesSearchRequest) filesSearchRequest.ac.abort();

          filesSearchRequest = list('files', querySearch, context.gId, undefined, {
            skipCount,
            count: context.count || count,
            // isQuickSearch: true,
            searchOnlyName: fileFilter?.searchOnlyName,
            isSearchPageQuery: true
          })

          const result = await filesSearchRequest.r as any as TFilesListResponse;

          if (result.error_code !== 0) throw result

          afterGetDataCallback?.(result)

          return result.data.map((item) => ({
            ...item,
            url: `/files/${item.id}`
          }))
        }, [querySearch, fileFilter], {
          selectableSingle: middlewareParams.selectableSingle
        }, true)

        useDidUpdateEffect(() => {
          afterStateChangedCallback?.(state);
        }, [state]);

        return {
          middlewareParams,
          state,
          defaultSelected,
          middleware: defaultMiddleware,
          search: querySearch,
          onChangeTab,
          changeSearch
        }
      }}
    />
  )
}

export const List = connect<TListStateProps, TMapDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(ListFilesPresenter);