import { put, takeLeading, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  call,
  select,
} from 'utils/src/saga.effects';
import { LOCATION_CHANGE, RouterAction, LocationChangeAction } from 'connected-react-router';
import { TITLES_SET, TITLES_SET_ITEM, titlesSet, titlesSetItem } from './actions';
import { ITitlesActions } from './actions.interfaces';
import { getTitles } from './selectors';
import { ITitlesReducer } from './interfaces'
import { i18n, Translate } from 'localization';
import { getCompanyName } from './selectors';
import { CacheHelper } from 'utils/src/CacheHelper';
import usersActions from 'utils/src/CommonRedux/users/actionsTypes';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import groupsActions from '../../actionsTypes/Groups';
import { getGroupByPKID } from '../Groups/selectors';
import actionsLC from 'utils/src/CommonRedux/LifeCycol/actionsTypes'
import { basename } from 'utils/src';

const cacheKey = 'admin/companyName';

function* changeTitle(action: LocationChangeAction ) {
  try {
    const title = [];
    const companyNameFromCache = yield* call(CacheHelper.get, "admin", cacheKey);
    if (companyNameFromCache?.fromCache) title[0] = companyNameFromCache.companyName

    const companyName = yield* select(getCompanyName);
    title[0] = companyName;
    
    let pathname = action.payload?.location.pathname || decodeURI(window.location.pathname.replace(basename, ''));
    const pn = pathname.substr(1).split('/');

    let count = pn.length <= 2 ? pn.length : 2
    let moduleName = "";
    for (let i = 0; i < count; i++) {
      if (i === 0) {
        moduleName = pn[i];

        title[i + 1] = i18n.t(`pryaniky.title.${moduleName.toLowerCase()}`);

      } else {


        switch (moduleName) {
          case 'user':
            let user: any;
            if (pn[i]) user = yield* select(getUserById(pn[i]));

            if (!user) {
              yield put(titlesSet(title))
              yield take(usersActions.ADD_USER);
              if (pn[i]) user = yield* select(getUserById(pn[i]));
            }
            title[i + 1] = user?.baseData?.displayName || '';

            title[0] = yield* select(getCompanyName);
            // title[1] = i18n.t(`pryaniky.title.${pn[0].toLowerCase()}`);
            break;
          case 'group':
            let group: any;
            if (pn[i]) group = yield* select(getGroupByPKID(pn[i]));
            if (!group) {
              yield put(titlesSet(title))
              yield take(groupsActions.ADD_GROUP);
              if (pn[i]) group = yield* select(getGroupByPKID(pn[i]));
            }
            title[i + 1] = group?.name || '';
            title[0] = yield* select(getCompanyName);
            // title[1] = i18n.t(`pryaniky.title.${pn[0].toLowerCase()}`);

            break;
          case 'pages': {
            yield take('PRN_WIDGETS_SET_DATA')
            const page = yield* select((store: any) => store.widgets.pages[store.widgets.structures[pathname.slice(1)]]);
            title[i + 1] = page?.title || '';
            title[0] = yield* select(getCompanyName);
            break;
          }

          case 'shop':
          case 'quests': {
            title[i + 1] = i18n.t(`pryaniky.title.${moduleName.toLowerCase()}.${pn[i].toLowerCase()}`)
            break
          }

          default:
            break;
        }
      }
    }

    yield put(titlesSet(title));
    return;
  } catch (e) {
    console.error('LOCATION_CHANGE change title error', e);
  }
}

function* changeSystemTitle(action: any) {
  try {
    const companyNameFromCache = yield* call(CacheHelper.get, 'admin', cacheKey);

    // company name
    if (action.payload.propPath !== '' || !action.payload.value.appSettings) return 0;
    const cn: string = action.payload.value.appSettings.title;
    if (!companyNameFromCache) yield* call(CacheHelper.set, 'admin', cacheKey, { companyName: cn });
    yield put(titlesSetItem({ position: 0, value: cn }));
    return;
  } catch (e) {
    console.error('PRN_STORE_CHANGE_STATE change title error', e);
  }
}

function* setTitle(action: ITitlesActions) {
  try {
    const value: ITitlesReducer = yield* select(getTitles);

    document.title = [...value].reverse().join(' - ').replace(/( - ){2,}/g, ' - ');
    // value.reduce((a, c, idx) => `${a}${idx === 0 && c ? '' : ' - '}${i18n.t(c)}`, '');
  } catch (e) {
    console.error('set title error', e);
  }
}

const Titles = function* Titles() {
  yield takeEvery([LOCATION_CHANGE, actionsLC.INIT_COMPLETED], changeTitle);
  yield takeEvery('PRN_STORE_CHANGE_STATE', changeSystemTitle);
  yield takeLatest([TITLES_SET, TITLES_SET_ITEM], setTitle);
}

export default Titles; 