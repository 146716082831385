import React, { useState } from 'react';
import { ISelectFilterColorDialogProps, mapStateToProps, mapDispatchToProps } from './SelectFilterColor.index';
import { connect, useSelector } from 'react-redux';
// import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import './SelectFilterColor.scss';
// import {
//   // Dialog,
//   // DialogTitle,
//   Textarea,
//   DialogText,
//   // DialogActions,
//   // DialogContent,
//   Button,
//   cnDialog,
// } from 'uielements/src';

import { SketchPicker } from 'react-color';
import { i18n, Translate } from 'localization';
// import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
// import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
// import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'
import { Button } from 'muicomponents/src/Button/Button'
import { useCurrentUserBase } from 'utils/src/CommonRedux/users/hooks';
import { Stack } from '@mui/material';

const presetColors = ['#ad1457', '#f4511e', '#0a8044', '#3f51b5', '#8e24aa', '#009688', '#7986cb', '#d50000', '#7cb343', '#039be5', '#b39ddb', '#616161', '#e67c73', '#f6bf26', '#33b679', '3f3f44'];

const SelectFilterColorPresenter = ({
  itemData,
  handleAccept,
  handleClose,
  setCalendarCalFilterColor,
}: ISelectFilterColorDialogProps) => {
  // const textEditorColors = useSelector((state: any) => state.store?.appDesign?.colors?.textEditorColors as string[] | undefined)
  // console.log('textEditorColors', textEditorColors)
  const isAdmin = useCurrentUserBase().isAdmin

  const [state, setState] = useState({ ...itemData })
  const onConfirm = () => {
    setCalendarCalFilterColor({ calendarId: state.id, color: state.color })
    handleClose()
  }

  const onSetForAll = () => {
    setCalendarCalFilterColor({ calendarId: state.id, color: state.color, commonColor: true })
    handleClose()
  }

  const onSetDefault = () => {
    setCalendarCalFilterColor({ calendarId: state.id })
    handleClose()
  }

  return <DialogBody
    header={<Translate i18nKey={'pryaniky.calendar.dialog.title'} values={{ calendar: state.title }} />}
    acceptText={i18n.t('pryaniky.calendar.dialog.change')}
    closeText={i18n.t('pryaniky.calendar.dialog.cancel')}
    onAccept={onConfirm}
    onClose={handleClose}
    leftActions={
      <Stack spacing={1} direction={'column'}>
        {isAdmin && <Button variant='contained' onClick={onSetForAll}>{i18n.t('pryaniky.calendar.dialog.setForAll'/*Применить для всех*/)}</Button>}
        <Button variant='contained' onClick={onSetDefault}>{i18n.t('pryaniky.calendar.dialog.setDefault'/*Цвет по умолчанию*/)}</Button>
      </Stack>}
  >

    <div className='SelectFilterColor' style={{ background: state.color }}>
      <SketchPicker presetColors={presetColors} onChangeComplete={(color: any) => setState({ ...itemData, color: color.hex })} disableAlpha={true} color={state.color} />
      {/* <SketchPicker presetColors={presetColors} onChangeComplete={(color: any) => modalChangeData({ id: id || '', data: { color: color.hex } })} disableAlpha={true} color={data.color} /> */}
    </div>

  </DialogBody>
}

export const SelectFilterColor = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectFilterColorPresenter)

export const DialogSelectFilterColor = createModal(SelectFilterColor, {
  maxWidth: 'sm',
  fullWidth: true,
  TransitionComponent,
  scroll: 'body'
});