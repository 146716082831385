import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import { i18n, Translate } from 'localization';
import { setContext } from 'redux/actions/Widgets'
import { pageNames } from 'redux/pageNames'

interface IPollsProps extends IActionsDispatch, IDispatchProps { }
interface IActionsDispatch {
  setContext: any
}

export const PollsPresenter: React.FC<IPollsProps> = props => {
  // useEffect(() => {
  //   props.setContext({
  //     paths: ['common'],
  //     innerPath: '',
  //     value: {
  //       newsType: 'polls',
  //       oneNewsType: 'polls'
  //     },
  //   })
  //   return () => {
  //     props.setContext({
  //       paths: ['common'],
  //       innerPath: '',
  //       value: {
  //         newsType: 'all',
  //         oneNewsType: undefined
  //       },
  //     })
  //   }
  // }, [])

  return <Page page={{ name: pageNames.polls, title: i18n.t('polls'), }} />
}

export const Polls = connect(
  null,
  mapDispatchToProps({ setContext })
)(PollsPresenter)

