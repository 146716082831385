import { IEditorProps, mapThanksEditorStateToProps } from './Editor.index'
import React, { memo } from 'react'
import './Editor.scss';
import { getReasons } from '../Thanks.sides';
import { i18n, Translate } from 'localization';
import { IThanksReason } from 'utils/src/requests/models/api.system';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { ReasonThanksSelector } from 'blocks/PryanikyForms/ReasonThanksSelector/ReasonThanksSelector'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
import { connect } from 'react-redux';

const EditorPresenter: React.FC<IEditorProps> = (
    {
        children,
        reasons = [],
        excludeUsers = [],
        onChangeReason,
        onChangeText,
        onChangeThanks,
        onChangeUsers,
        text = '',
        // thanksCount = 0,
        users = [],
        groups = [],
        validation = [],
        maxUsers = -1,
        // maxThanksValue = 0,
        maxThanksArgumentsCount = 0,
        // thanksForAll = 0,
        isAdmin,
        disabledVariants = [],

        ////
        disableDown = false,
        disableUp = false,
        thanksCount = 0,
        available = 0,
        fullCount = 0,
        fullCurrency = 0,
        maxPerUser = 0,
        maxThanks = 0,
    }
) => {
    // перемешинание типов идет и не понятно что из чего выплывает
    const [reason, setReasons] = React.useState<IThanksReason[]>([])

    React.useEffect(() => {
        if (reason.length < 1) {
            getReasons((data) => {
                /** если происходит первая загрузка ценностей и ценность всего одна то сразу ее выбираем */
                onChangeReason && data.length === 1 && (data[0] as any).options.length === 1 && onChangeReason((data[0] as any).options);
                setReasons(data);
            });
        }
    }, []);

    React.useEffect(() => {
        // если запушили благодарность, выбранные ценности обнулились и ценность всего одна то сразу ее выбираем
        if (onChangeReason && reasons.length === 0 && reason.length === 1 && (reason[0] as any).options.length === 1) onChangeReason((reason[0] as any).options);
    }, [reasons]);

    let membersCount = users.length;
    membersCount += groups.reduce((acc: number, cur: any) => {
        return acc + cur.membersCount
    }, 0)

    // const maxThanks = membersCount === 0 ? thanksForAll : (membersCount * maxThanksValue) > thanksForAll ? thanksForAll : (membersCount * maxThanksValue);

    const validMessagesReasons = validation.filter(value => value.field === 'rewardReasons')
    const validMessagesThanks = validation.filter(value => value.field === 'thanksCount')
    const validMsgRTS = [...validMessagesReasons, ...validMessagesThanks]

    const textValidation = validation.find(val => val.field === 'text' && val.method === 'STR_MIN')

    const minLengthData = textValidation && text ? {
        need: textValidation.value,
        current: textValidation.value - text.getCurrentContent().getPlainText().trim().length
    } : {}

    return <div className={'Editor EditorThanks'}>
        {
            onChangeUsers &&
            <PrnUserInput
                maxSelect={fullCurrency === 0 ? 99 : maxUsers}
                excludeMe={true}
                placeholder={i18n.t('pryaniky.createPost.thanks.whom')}
                excludeUsers={excludeUsers}
                disabledVariants={disabledVariants}
                onChange={onChangeUsers}
                value={[...users, ...groups]}
                type={isAdmin ? 'usersgroups' : 'users'}
            />
        }

        {
            onChangeText &&
            <PryanikyEditor
                onChange={onChangeText}
                value={text}
                disableToolbar={true}
                placeholder={i18n.t('pryaniky.createPost.thanks.text')}
            />
        }

        {textValidation && text &&
            <div className={'EditorThanks-MinLength'}>
                {i18n.t('pryaniky.createPost.thanks.MinLength', minLengthData)}
            </div>
        }


        {
            (onChangeReason && onChangeThanks) &&
            <ReasonThanksSelector {...{
                // maxThanksValue,
                maxThanksArgumentsCount,
                // thanksForAll,
                validMsg: validMsgRTS,
                reason,
                selectedReasons: reasons,
                onChangeReason,
                onChangeThanks,
                // maxThanks,
                minThanks: membersCount,
                // thanksCount,
                usersCount: membersCount,

                disableDown,
                disableUp,
                thanksCount,
                available,
                fullCount,
                fullCurrency,
                maxThanks,
                maxPerUser
            }} />
        }

        {children}
    </div>
}

export const Editor = connect(mapThanksEditorStateToProps)(memo(EditorPresenter));