import React, { FC } from 'react'
import {
    cnCurrencyTooltip,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './CurrencyTooltip.index'
import { connect } from 'react-redux';
import './CurrencyTooltip.scss'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip'
import Item from './Item'
import { i18n, Translate } from 'localization';

export const Presenter: FC<IPresenterProps> = ({
    className,
    thanksForAll,
    wallet,
    forSpend,
    children,
    thanksForAllByCollection,
    summaryRender,
    thanksForSubordinate = 0
}) => {
    const keys = Object.keys(thanksForAllByCollection || {})
    const summary: number = thanksForAll + thanksForSubordinate + keys.reduce((accumulator: number, currentValue: string) => {
        return accumulator + thanksForAllByCollection[currentValue].value
    }, 0);
    return <HelpTooltip
        className={cnCurrencyTooltip({}, [className])}
        help={<div className={cnCurrencyTooltip('Layout')}>
            {/* <div className={cnCurrencyTooltip('Title')}>Мои финансы</div> */}
            <div className={cnCurrencyTooltip('List')}>
                <Item
                    className={cnCurrencyTooltip('Item')}
                    title={forSpend ? i18n.t('pryaniky.widgets.virtcurrency.forSpend') :
                        i18n.t('pryaniky.widgets.virtcurrency.forGive')}
                    value={forSpend ? wallet : thanksForAll}
                    icon={forSpend ? 'wallet' :
                        'give'} />

                {!!thanksForSubordinate && <Item
                    className={cnCurrencyTooltip('Item')}
                    title={i18n.t('pryaniky.widgets.virtcurrency.thanksForSubordinate')}
                    value={thanksForSubordinate}
                    icon={'give'} />}

                {!forSpend && keys.map(key => <Item
                    className={cnCurrencyTooltip('Item')}
                    key={key}
                    title={thanksForAllByCollection[key].name}
                    value={thanksForAllByCollection[key].value}
                    icon={'gem'} />)}
            </div>

        </div>}
    >
        {summaryRender ? summaryRender(summary) : children}
    </HelpTooltip >
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
