import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { cnSecretSantaWidget, mapDispatchToProps, mapStateToProps, ISecretSantaWidget } from './SecretSantaWidget.index'
import { Button, Avatar, Icon, Loading } from 'uielements/src'
import { i18n, Translate } from 'localization';
import './SecretSantaWidget.scss'
import { secretSantaDialog } from '../Dialogs/SecretSantaDialog/SecretSantaParticipate';
import MDRender from 'uielements/src/CommonmarkRender';
import { DistributeTimer } from './DistributeTimer/DistributeTimer'
import * as utils from 'utils.project/utils.project'
import BaseRequests from 'utils/src/requests/requests.base';
import { Button as MuiButton } from 'uielements/src/MaterialElements/Button/Button';
import { useDidUpdateEffect } from 'utils/src/hooks'
import { CircularProgress } from '@material-ui/core';
import { SecretSantaWidgetItem } from '../SecretSantaListWidget/Item/SecretSantaWidgetItem'

let interval: NodeJS.Timeout;

const SecretSantaWidgetPresenter = (props: ISecretSantaWidget) => {
  const { tag: Tag = 'div',
    getSecretSantaWidgetSettingsAction,
    isUserParticipateAction,
    secretSantaJoinAction,
    getUserToGivePresent,
    isPresentSent,
    presentSentAction,
    setUserDistributedToggleAction,
    setIsPresentSentAction,
    secretSantaUnJoinAction,
    mainSecretSantaSettingsAction,
    isPresentSendData,
    isParticipate,
    data,
    edit,
    userForPresent,
    uIsAdmin,
    isDistributLoading,
    settings
  } = props

  const [updateData, setUpdateData] = useState<boolean>(false)
  const [showUser, setShowUser] = useState<null | boolean>(false)
  const [loading, setLoading] = useState<null | boolean>(false)
  const [partitipants, setPartitipants] = React.useState<string>("")

  useEffect(() => {
    !data && mainSecretSantaSettingsAction(settings?.type?.id)

  }, [data])

  useDidUpdateEffect(() => {
    if (updateData === false) {
      return;
    }

    setTimeout(() => {

      mainSecretSantaSettingsAction(settings?.type?.id);
 
      setUpdateData(false)

    }, 6000);

  }, [updateData])

  useEffect(() => {

    data && getRightFormOfWord(data.participantsCount)
  }, [data])

  useEffect(() => {

    clearInterval(interval);

  }, [isDistributLoading === false])

  useEffect(() => {
    if (data) {
      // это проверка на то что юзер не пришел ранее, если изначально его не было, то запускаем запрос юзера
      if (!userForPresent && isParticipate) {
        interval = setInterval(() => getUserToGivePresent({
          queryString: {
            drawId: data.id,

          }
        }), 60000);
      }

      (isParticipate && data.state === 'Distributed' && data.id) && isPresentSent({
        queryString: {
          drawId: data.id,

        }
      })
    }

  }, [(data && data.state === 'Distributed') && isParticipate])



  // const isDistribute = useCallback(() => {
  //   if (!data) return;

  //   var today = new Date().valueOf()

  //   var distribution = new Date(data.distributionDate).valueOf()
 
  //   var teaserEnd = new Date(data.startRegistrationDate).valueOf()
  //   var registrationEnd = new Date(data.endRegistrationDate).valueOf()

  //   if ((teaserEnd < today) && data.state === "Teaser") {
  //     setUpdateData(true)
  
  //   };
  //   if ((registrationEnd < today) && data.state === "Participation") {
  //     setUpdateData(true)
    
  //   };

  //   if ((distribution < today) && data.state === "WaitingDistribution") {
   
  //     !userForPresent && setUserDistributedToggleAction({ data: { variable: "isDistributLoading", value: true }, id: settings?.type?.id })
  //     setUpdateData(true)

  //   };

  // }, [data]);

  const getRightFormOfWord = (people: number) => {

    if (people > 21) {
      const wordLength = people.toString().length
      const getLastNumber = Number(people.toString()[wordLength - 1])
      if (getLastNumber > 4 || getLastNumber === 0 || getLastNumber === 1) {
        return setPartitipants("plural")
      } else if (getLastNumber > 1 && getLastNumber < 5) {
        return setPartitipants("akk")
      }
    } else if ((people > 4 && people < 21) || people === 0 || people === 1) {
      return setPartitipants("plural")
    } else if (people > 1 && people < 5) {
      return setPartitipants("akk")
    }

  }
  // IRMA TO DO переименовать переменные getParticipate в isParticipate||donerrorBoundery
  if (!edit && (!data)) return null;


  if ((edit && !data) ||
    (edit && isPresentSendData) ||
    (data && !data.isActive && (data.state === 'Teaser' || data.state === 'Participation')) ||
    (edit && (data && data.state === 'Distributed') && isParticipate === false) ||
    (data && data.state === 'Closed')) return <Tag {...props} className={cnSecretSantaWidget({}, [props.className])}></Tag>;


  if (isParticipate === null ||
    (data && !data.isActive && (data.state === 'Teaser' || data.state === 'Participation')) ||
    ((data && data.state === 'Distributed') && isParticipate === false) ||
    ((data && data.state === 'Distributed') && isPresentSendData) ||
    (data && data.state === 'Closed')
  ) return null;




  return (
    data && isPresentSendData === false ? <Tag {...props}>
      <SecretSantaWidgetItem  settings={settings} />
    </Tag> : null
  )
}

export const SecretSantaWidget = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecretSantaWidgetPresenter)

