import React, { FC, memo } from 'react';
import { ICreativetasksPropsType, ICreativetasksStateType, mapDispatchToProps, mapStateToProps, TStateProps } from '../Creativetasks.index'
import { cnSliderCreativetaskView } from './index'
import './SliderViewCreativetask.scss'
import { withNewsContext } from 'News/contexts/news'
import { connect } from 'react-redux';
import MiniHeader from 'News/containers/MiniHeader'
import { LinkFilter } from 'News/utils/utils'
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import IconMUI from '@material-ui/core/Icon';
// import Creativetask from './Creativetask.svg';
import { i18n, Translate } from 'localization';
import MDRender from 'uielements/src/CommonmarkRender'
import { Button } from 'uielements/src';


const SliderViewCreativetaskPresenter: FC<ICreativetasksPropsType & TStateProps> = ({
    creativeTask,
text,
    newsId
}) => {
    const {
        endDateTime,
        fund,
        title,
    } = creativeTask;
    // const { currentStatus } = creativeTask.idea;

  
    async function open() {
        try {
            const result = openNews({ newsId })
        } catch (e) { }
    }
    const lheader = title
    return <div>
        <LinkFilter action={open}>
            <MiniHeader authorOnly onClick={open} className={cnSliderCreativetaskView('Author')} disableTextCut />
            <div className={cnSliderCreativetaskView('Header')}>
                <div className={cnSliderCreativetaskView('HeaderBox')}>
                    {/* <IconMUI> <img src={IdeaIcon} alt="" /> </IconMUI> */}
                    {/* <div className={cnSliderCreativetaskView('HeaderBox-Img')}>  </div> */}
                    <div className={cnSliderCreativetaskView('HeaderBox-TextBox')}>
                        <span className={cnSliderCreativetaskView('HeaderBox-TextBox-Text')}><Translate i18nKey={"pryaniky.news.view.slider.ideas.fond"} /></span>
                        <div className={cnSliderCreativetaskView('HeaderBox-TextBox-Currency')}>
                            <span>{fund}</span>
                        </div>
                        <span className={cnSliderCreativetaskView('HeaderBox-TextBox-Text')}> пряников </span>
                    </div>


                </div>
            </div>
            <div className={cnSliderCreativetaskView('Content')}>
                {lheader.trim() !== '' && <div className={cnSliderCreativetaskView('Content-Title')}>
                    <MDRender
                        // disableEmojiOne
                        className={cnSliderCreativetaskView('Content-TitleRender')}
                        type={'inline'}
                        disableShowMoreBtn
                        textCut={false}
                        // maxCharCount={200}
                        source={lheader}
                    />
                </div>}
                <div className={cnSliderCreativetaskView('Content-NewsText')}>
                <MDRender
                    disableEmojiOne
                    className={cnSliderCreativetaskView('Content-Render')}
                    type={'textOnly'}
                    disableShowMoreBtn
                    textCut={true}
                    maxCharCount={350}
                    source={text}
                />
                </div>
                {/* {currentStatus && <div className={cnSliderCreativetaskView('Content-Status')}>
                    <div><span ><Translate i18nKey={"pryaniky.timeline.news.status"} /></span> {currentStatus.name}</div>
                    <div><span><Translate i18nKey={"pryaniky.timeline.news.changeDate"} /></span>{currentStatus.statusChangeDate}</div>
                </div>} */}
                <div className={cnSliderCreativetaskView('Content-Footer')}>
                    <Button
                        theme={'unstyled'}
                        className={cnSliderCreativetaskView('OpenBtn', ['primaryColor3-text'])}
                        onClick={open}><Translate i18nKey={"pryaniky.timeline.news.readfull"} /></Button>
                </div>
            </div>

        </LinkFilter>

    </div>
}
export const SliderViewCreativetask = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(SliderViewCreativetaskPresenter)));