import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

export const widgets_v2 = (): IWidget<IColumn[]> => {

  return generateWidget({
    // type: widgets.types.layout + '/horizontal',
    type: widgets.types.layout,
    version: '2',
    data: [
      generateColumn({
        styles: {
          width: '50%'
        },
        items: [
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
        ]
      }),
      generateColumn({
        styles: {
          width: '25%'
        },
        items: [
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
        ]
      }),
      generateColumn({
        styles: {
          width: '25%'
        },
        items: [
          generateWidget({
            type: widgets.types.info,
            settings: {
              type: 'badges'
            }
          }),
          generateWidget({
            type: widgets.types.badgesList,
          }),
        ]
      })
      // generateColumn({
      //   items: [
      //     generateWidget({
      //       type: widgets.types.info,
      //       settings: {
      //         type: 'badges'
      //       }
      //     }),
      //     generateWidget({
      //       type: widgets.types.info,
      //       settings: {
      //         type: 'badges'
      //       }
      //     }),
      //   ]
      // }),
    ],
  });
}