import React, { createRef, FC, Fragment, PropsWithRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalStoreForWidget, useWidget, useWidgetDrag } from 'Widgets_v2/hooks';
import { cnWidgetv2 as cnWidget, NWidget } from 'Widgets_v2/Widget/Widget.index';
import {
  updateContext,
  removeWidget,
  updateWidget,
  addWidget,
  dragWidget,
  setStructureFromWidget,
  addTabToWidget,
  changeWidgetSettings,
  changeWidgetsViewType,
  setDraggingElem
} from 'redux/actions/Widgets';
import { Widget } from '../Widget/Widget';
// import { WidgetsAdd } from 'blocks/Dialogs/Widgets/Add/WidgetsAdd';
import { IClassNameProps } from '@bem-react/core';
import { i18n, Translate } from 'localization';
import { DeleteForever, Add, PlaylistAdd } from 'muicomponents/src/Icons';
import ReactSortable from 'react-sortablejs';
import * as utils from 'utils/src/utils';
import ReactSwipe from 'react-swipe';
import { SwipeMenu } from '../SwipeMenu';
import { BoxColumn, BoxLayout, ResizeHandlerBox, ResizeHandler, ColumnActions, ActionButton } from './WidgetLayout.styled';
import { NColumn, NSwipeLayout } from './WidgetLayout.index';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { toPairs, fromPairs, Dictionary, keyBy, cloneDeep } from 'lodash';
import { confirmProise } from 'utils.project/utils.project';
import { DialogWidgetAdd } from 'blocks/Dialogs/Widgets/AddNew/WidgetsAddNew';
import { actionsBlockDataId } from 'Widgets_v2/constants';
import { TColumn, TWidgetLayout } from 'utils/src/BaseTypes/widgets.types';
import { generateColumn } from 'Widgets_v2/utils';
import { TSimplifiedWidgetByType } from 'Widgets_v2/types';

const maxColumns = 3;

const columnMinWidth = 25;

function getColumnStyle (width: string, edit?: boolean) {
    return {
        flexBasis: width,
        minWidth: parseFloat(width) + '%',
        minHeight: edit ? `150px` : undefined
    }
};

function columnStyleLikeCssString (data: ReturnType<typeof getColumnStyle>) {
    // const styleObj = JSON.stringify(getColumnStyle((parseFloat(width) + movePrecent).toString(), true)).replace(/[\s"]/g, '').replace(/,/g, ';');
    const likeCss = fromPairs(toPairs(data).map(([key, value]) => {
        switch(key) {
            case 'flexBasis': {
                return ['flex-basis', value]
            }
            case 'minWidth': {
                return ['min-width', value]
            }
            case 'minHeight': {
                return ['min-height', value]
            }
            default: {
                return [key, value]
            }
        }
    }));
    const cssString = JSON.stringify(likeCss).replace(/["]/g, '').replace(/,/g, ';');
    return cssString.substring(1, cssString.length - 1);
};

export const Column: FC<PropsWithRef<NColumn.Props>> = ({
    ref,
    column,
    children,
    className,
    componentProps,
    ...props
}) => {

    const {
        edit
    } = useGlobalStoreForWidget();

    const { id, styles } = column;

    const isMobile = utils.isMobile();

    const style = useMemo(() => {
        return isMobile ? {} : getColumnStyle((styles?.width || styles?.minWidth) + '' || '0', edit);
    }, [isMobile, styles?.width, edit]);

    return (
        <BoxColumn
            id={id}
            {...props}
            ref={ref}
            style={style}
            {...componentProps}
            className={`Column${className ? ` ${className}`: ''}`}
        >
            {children}
        </BoxColumn>
    )
};

/**
 * to render on mobile phones
 * @param param0 
 * @returns 
 */
const SwipeLayout: FC<NSwipeLayout.Props> = ({
    columns
}) => {

    const [activeColumn, setActiveColumn] = useState(0);

    const swipeRef = createRef();

    const columnsRefs = useRef<{[key: string]: HTMLElement | null}>({});

    const swipeVisualize = useCallback((item: HTMLElement, count: number) => {
      new Array(count).fill(0).forEachTimed(350, (_, idx) => {
        if(!(idx % 2)) {
          item?.classList.add('SwipeTranslate')
        } else {
          item?.classList.remove('SwipeTranslate');
        }
      });
    }, []);

    useEffect(() => {
        if(swipeRef.current && columns?.length > 1) {
          const htmlCollumnsItems = columns?.map((el) => columnsRefs.current[el.id]).filter((e) => e);
          const jerkingItems = htmlCollumnsItems.slice(0, 2);
          jerkingItems.forEach(element => {
            element && swipeVisualize(element, 4);
          });
        }
    }, []);

    return (
        <BoxLayout sx={{
            flexDirection: 'column'
        }}>
            <SwipeMenu className={cnWidget('Swipe-Menu')} active={activeColumn} count={columns.length || 0} />
            {
                columns &&
                <ReactSwipe
                    className={cnWidget('Swipe')}
                    swipeOptions={{
                        speed: 300,
                        continuous: false,
                        stopPropagation: true,
                        callback: (idx: number) => {setActiveColumn(idx)}
                    }}
                    childCount={columns.length}
                    ref={swipeRef}
                >
                    {
                        columns.map((column, idx) => {
                            return (
                                <Column
                                    ref={(el) => columnsRefs.current[column.id] = el}
                                    key={column.id}
                                    column={column}
                                    >
                                    <div className={cnWidget('Column-SwipeWrapper')}>
                                        {
                                            column.items.map(key => <Widget key={key} id={key} />)
                                        }
                                    </div>
                                </Column>
                            )
                        })
                    }
                </ReactSwipe>
            }
        </BoxLayout>
    )
}

/**
 * base layout
 * @param param0 
 * @returns 
 */
export const WidgetLayout: FC<NWidget.Props> = ({
    id,
    ...props
}) => {

    const sortableRef = useRef<any>({});

    const resizeHandler = useRef<string | null>(null);

    const layoutRef = createRef<HTMLDivElement>();

    const dispatch = useDispatch();

    const {
        edit
    } = useGlobalStoreForWidget();

    const {
        widget,
        changeWidget
    } = useWidget<TSimplifiedWidgetByType<'layout'>>(id);

    const {
        onDragStart,
        onDragEnd,
        onDragged,
        onWidgetAdd
    } = useWidgetDrag(id);

    const columnsSizes = useRef<Dictionary<number>>(fromPairs(widget.data?.map(el => [el.id, parseFloat((el.styles?.width || el.styles?.minWidth) + '')])));

    useDidUpdateEffect(() => {
        columnsSizes.current = fromPairs(widget.data?.map(el => [el.id, parseFloat(el.styles?.width + '')]));
    }, [widget.data?.length, edit]);
    
    /**
     * add widget to column
     * @param newWidget 
     */
    const addWidgetHandler = (newWidget: { [s: string]: any }) => {
        newWidget.colId = newWidget.columnId;
        newWidget.relations = [id, newWidget.id];
        dispatch(addWidget(newWidget));
    };
    

    const onDraged = (columnId: string, items: string[]) => {
        const newWidget = cloneDeep(widget);
        if (newWidget.data) newWidget.data.forEach((colunm) => {
            if (colunm.id === columnId) colunm.items = items;
        });
        dispatch(dragWidget(newWidget as any));
    };
    
    const isMobile = utils.isMobile();

    useDidUpdateEffect(() => {
        if(!sortableRef.current) return;
        // change disabled option at sortablejs to init DND
        Object.keys(sortableRef.current).map((key: any) => {
            if(sortableRef.current[key]) sortableRef.current[key].option('disabled', !edit);
        });
    }, [edit]);

    const onResize = (e: MouseEvent) => {
        const columnId = resizeHandler.current;
        if(!layoutRef.current || !resizeHandler.current) return ;
        const resizeColumnIdx = (widget.data || []).findIndex(e => e.id === columnId);
        if(resizeColumnIdx && resizeColumnIdx === -1) return ;
        const layoutSize = layoutRef.current.clientWidth;
        const layoutRect = layoutRef.current.getBoundingClientRect();
        let cursorPositionByLayout = e.clientX - layoutRect.left;
        if(cursorPositionByLayout < 0) cursorPositionByLayout = 0;
        if(cursorPositionByLayout > layoutSize) cursorPositionByLayout = layoutSize;
        const cursorPercentsPositionByLayout = (cursorPositionByLayout * 100) / layoutSize;
        let previousPercentsWidth = 0;
        let stopResize = false;
        const maxSize = (widget.data || []).map(el => parseFloat(el.styles?.width + ''))
            .filter((_, idx) => idx === resizeColumnIdx || idx === resizeColumnIdx + 1)
            .reduce((a, c) => a + c, 0) - columnMinWidth;
        widget.data?.forEach((column, idx, arr) => {
            const HTMLElement = document.getElementById(column.id);
            if(HTMLElement && !stopResize) {
                const currentWidth = columnsSizes.current[column.id];
                if(idx < resizeColumnIdx){
                    previousPercentsWidth += currentWidth;
                } else
                if(idx === resizeColumnIdx) {
                    let size = cursorPercentsPositionByLayout - previousPercentsWidth;
                    if(size <= columnMinWidth) {
                        size = columnMinWidth;
                        stopResize = true
                    } else
                    if(size >= maxSize) {
                        size = maxSize;
                        stopResize = true
                    }
                    columnsSizes.current[column.id] = Number(size.toFixed(1));
                    HTMLElement.setAttribute('style', columnStyleLikeCssString(getColumnStyle(Number(size.toFixed(1)) + '%', true)));
                    previousPercentsWidth += currentWidth;
                    // if(stopResize) {
                        const nextId = arr[idx + 1].id;
                        const NextHTMLElement = document.getElementById(nextId);
                        if(NextHTMLElement) {
                            const currentWidth2 = columnsSizes.current[nextId];
                            let size2 = currentWidth2 + (previousPercentsWidth - cursorPercentsPositionByLayout);
                            if(size2 <= columnMinWidth) {
                                size2 = columnMinWidth;
                                stopResize = true
                            } else
                            if(size2 >= maxSize) {
                                size2 = maxSize;
                                stopResize = true
                            }
                            columnsSizes.current[nextId] = Number(size2.toFixed(1));
                            NextHTMLElement.setAttribute('style', columnStyleLikeCssString(getColumnStyle(Number(size2.toFixed(1)) + '%', true)));
                        }
                    // }
                }
            }
        });
    };
  
    const mouseUp = (ev: any) => {
        ev.stopPropagation();
        ev.preventDefault();
        resizeHandler.current = null;
        changeWidget({ data: widget.data?.map(column => ({
            ...column,
            styles: {
                ...column.styles,
                width: columnsSizes.current[column.id] + '%'
            }
        })) });
        // changeWidget({ data: resizedColumns });

        document.removeEventListener('mousemove', onResize, false);
        document.removeEventListener('mouseup', mouseUp, false);
    };

    const mouseDown = (ev: React.MouseEvent) => {
        ev.stopPropagation();
        ev.preventDefault();
        resizeHandler.current = ev.currentTarget.getAttribute('handler-id');
        
        document.addEventListener('mousemove', onResize, false);
        document.addEventListener('mouseup', mouseUp, false);
    };

    const removeColumn = (columnId: string) => {
        confirmProise({
            content: <Translate i18nKey='pryaniky.widget.column.remove.comfirm' />
        }).then((action) => {
            if(action === 'confirm') {
                const columnForRemove = widget.data?.find(el => el.id === columnId);
                if(!columnForRemove) return;
                const columnSize = parseFloat(columnForRemove.styles?.width + '');
                const newColumns = widget.data?.filter(column => column.id !== columnId) || [];
                const sizeToEachColumns = columnSize / newColumns.length;
                changeWidget({
                    data: newColumns.map(el => ({
                        ...el,
                        styles: {
                            width: `${parseFloat(el.styles?.width + '') + sizeToEachColumns}%`
                        }
                    })) 
                });
            }
        })
    };

    const addNextColumn = (columnId: string) => {
        const currentData = widget.data || [];
        if(currentData.length === maxColumns) return ;
        let sizeToChange = columnMinWidth; 
        const newColumn = generateColumn({
            styles: {
              width: sizeToChange + '%'
            },
            items: []
        });
        const notSortedArray = (widget.data || []).map(el => el.id);
        const columnsWithNewSizes = keyBy(cloneDeep(widget.data || []).sort((a, b) => {
            const aWidth = parseFloat(a.styles?.width + '');
            const bWidth = parseFloat(b.styles?.width + '');
            return aWidth > bWidth ? 1 : aWidth < bWidth ? -1 : 0
        }).map((el, idx, arr) => {
            const currentSize = parseFloat(el.styles?.width + '');
            // const maxChangeByColumn = columnMinWidth;
            if(currentSize === columnMinWidth || sizeToChange === 0) return el;
            const changeFor = idx === 0 ? sizeToChange / 2 : sizeToChange;
            if(currentSize - changeFor < columnMinWidth) {
                const chageSize = currentSize - columnMinWidth;
                const newSize = currentSize - chageSize;
                sizeToChange -= chageSize;
                return {
                    ...el,
                    styles: {
                        width: `${newSize}%`
                    }
                }
            } else {
                const newSize = currentSize - changeFor;
                sizeToChange -= changeFor;
                return {
                    ...el,
                    styles: {
                        width: `${newSize}%`
                    }
                }
            }
        }), 'id');

        columnsWithNewSizes[newColumn.id] = newColumn as any;

        const indexToAdd = notSortedArray.findIndex(el => el === columnId);
        if(indexToAdd === -1) return ;
        notSortedArray.splice(indexToAdd + 1, 0, newColumn.id);
        
        changeWidget({
            data: notSortedArray.map(id => columnsWithNewSizes[id])
        });
    };

    if(isMobile) return <SwipeLayout columns={widget.data || []} />;

    return (
        <>
            <BoxLayout
                ref={layoutRef}
                sx={{
                    paddingTop: edit ? '36px' : undefined
                }}
            >
                {
                    widget.data &&
                    widget.data.map((column, idx, arr) => {
                        const items = (column.items as string[]).map(key => <Widget key={key} id={key} />)
                        return (
                            <>
                                <Column
                                    key={`column-${column.id}`}
                                    column={column}
                                    component={ReactSortable}
                                    className={cnWidget('Sortable')}
                                    componentProps={{
                                        ref: (ref: any) => {
                                            // define ref to change desabled state because in option on props change not work
                                            sortableRef.current[column.id] = ref?.sortable;
                                        },
                                        options: {
                                            group: 'shared',
                                            onStart: onDragStart,
                                            onEnd: onDragEnd,
                                            onAdd: onWidgetAdd,
                                            onMove: (e: any) => e.related.className.indexOf(cnWidget('Column-Actions')) === -1,
                                            filter: `.${cnWidget('Column-Actions')}`,
                                            disabled: !edit
                                        },
                                        onChange: onDragged(column.id)
                                    }}
                                    >
                                    {
                                        edit &&
                                        <ColumnActions
                                            className={cnWidget('Column-Actions')}
                                            variant={'outlined'}
                                            data-id={actionsBlockDataId}
                                        >
                                            <Translate i18nKey={'title:pryaniky.widget.add'}>
                                                <ActionButton
                                                    fullWidth
                                                    name={column.id}
                                                    onClick={() => DialogWidgetAdd({ columnId: column.id,  widgetId: widget.id }).then(addWidgetHandler)}
                                                    className={cnWidget('Column-AddWidget')}
                                                >
                                                    <Add />
                                                </ActionButton>
                                            </Translate>
                                            {
                                                arr.length < maxColumns && 
                                                <Translate i18nKey={'title:pryaniky.widget.columnю.add'}>
                                                    <ActionButton
                                                        name={column.id}
                                                        onClick={() => addNextColumn(column.id)}
                                                        className={cnWidget('Column-RemoveWidget')}
                                                    >
                                                        <PlaylistAdd sx={{ transform: 'rotateZ(-90deg)' }} />
                                                    </ActionButton>
                                                </Translate>
                                            }
                                            <Translate i18nKey={'title:pryaniky.widget.column.remove'}>
                                                <ActionButton
                                                    name={column.id}
                                                    onClick={() => removeColumn(column.id)}
                                                    className={cnWidget('Column-RemoveWidget')}
                                                >
                                                    <DeleteForever />
                                                </ActionButton>
                                            </Translate>
                                        </ColumnActions>
                                    }
                                    {
                                        items
                                    }
                                </Column>
                                {
                                    edit && idx !== arr.length - 1 &&
                                    <ResizeHandlerBox key={`column-handler-${column.id}`}>
                                        <ResizeHandler onMouseDown={mouseDown} handler-id={column.id} />
                                    </ResizeHandlerBox>
                                }
                            </>
                        )
                    })
                }                
            </BoxLayout>
        </>
    )
}