import { generateColumn, generateWidget, IColumn, IWidget, widgets } from '../i.widgets';
import { i18n, Translate } from 'localization';

export const forbidden2005 = (type?: string): IWidget<IColumn[]> => {
    return generateWidget({
        type: widgets.types.layout,
        isEditable: false,
        data: [
            generateColumn({
                items: [
                    generateWidget({
                        type: widgets.types.wiki,
                        isEditable: false,
                        settings: {
                            className: 'forbidden2005',
                        },
                        data: {
                            blocks: [
                                {
                                    key: 'fdsde',
                                    text: i18n.t('forbidden2005'),
                                    type: 'header-two',
                                    depth: 0,
                                    inlineStyleRanges: [
                                        { offset: 0, length: i18n.t('forbidden2005').length, style: 'BOLD' },
                                        { offset: 0, length: i18n.t('forbidden2005').length, style: 'center' },
                                    ],
                                    entityRanges: [],
                                    data: {},
                                },
                            ],
                            entityMap: {},
                        },
                    }),
                ],
                styles: {
                    width: '100%',
                },
            }),
        ],
    });
};
