import * as React from 'react';
import * as utils from 'utils/src/utils';
import { Input } from 'uielements/src/Input/Input';
import { CheckboxInput } from 'uielements/src';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { withBemMod } from '@bem-react/core';
import { TextareaMentions } from 'blocks/TextareaMentions/TextareaMentions';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypePollsProps } from './NewsEdit-Body_type_polls.index';
import './NewsEdit-Body_type_polls.scss';
import { IBodyProps } from '../NewsEdit-Body.index';
import { EditorTypeEdit } from '../../../NewsTypes/Polls/Editor/containers/edit/edit'
export const withBodyTypePolls = withBemMod<IBodyTypePollsProps, IBodyProps>(
  cnBody(),
  { type: 'polls' },
  (Presenter) => (
    (props) => {
      const newPost: any = utils.cloneObject(props.post);
      /*const addNewAnswer = () => {
        newPost.poll.answers.push({ text: '' });
        props.onChangePost(newPost);
      };
      const changeAnswerText = (index: number, value: any) => {
        newPost.poll.answers[index].text = value.target.value;
        props.onChangePost(newPost);
      }
      const removeAnswer = (index: number) => {
        newPost.poll.answers.splice(index, 1);
        props.onChangePost(newPost);
      }
      const onChangeFreeAnswers = (e: any) => {
        newPost.poll.freeAnswer = !newPost.poll.freeAnswer;
        props.onChangePost(newPost);
      }
      const onChangeSecondAnswers = (e: any) => {
        newPost.poll.secondAnswers = !newPost.poll.secondAnswers;
        newPost.poll.answerVariants = newPost.poll.answerVariants === 100 ? 1 : 100;
        props.onChangePost(newPost);
      }

      const onChangeShowParticipants = () => {
        newPost.poll.showParticipants = !newPost.poll.showParticipants;
        props.onChangePost(newPost);
      }
      /*const expiresChange = (date: any) => {
        newPost.poll.expires = date;
        props.onChangePost(newPost);
      }*/
      return (
        <Presenter {...props} className='NewsPost-Part'>
          {({ text, onChangeText, post }) => <EditorTypeEdit data={newPost} onChange={props.onChangePost} />
            /*<React.Fragment>
              <TextareaMentions
                placeholder={i18n.t('placeholders.textarea.polls')}
                // getClassRef={this.getTextareaMentions} 
                users={true}
                tags={true}
                onChange={onChangeText}
                className={props.className}
                icon='edit'
                value={text} />
              {
                post.poll.answers.map((answer: any, i: number) => {
                  const icon = (index: number) => <span className='Input-Icon'>{index + 1}.</span>
                  return <Input key={i}
                    placeholder={i18n.t('text_answer')}
                    className={'NewsEdit-Body_type_polls-Poll-Answer-Input'}
                    onChange={(e: any) => changeAnswerText(i, e)} icon={icon(i)} value={answer.text}>
                      <Button
                        className={'Button_removeAnswer'}
                        onClick={() => removeAnswer(i)}
                        theme={'unstyled'}
                        children={
                          <Icon icon='times' />
                        }
                      />
                  </Input>
                })
              }
              <Button
                className={props.className + ' Button_addNewAnswer'}
                onClick={addNewAnswer}
                theme={'unstyled'}
                children={
                  <React.Fragment>
                    <Icon icon='plus' />
                    {i18n.t('add_anwer')}
                  </React.Fragment>
                } />
              <CheckboxInput
                onChange={onChangeFreeAnswers}
                checked={post.poll.freeAnswer}
                text={i18n.t('poll_free_answers')} />
              <CheckboxInput
                disabled={post.poll.maxCount !== 0}
                onChange={onChangeSecondAnswers}
                checked={post.poll.answerVariants === 100}
                text={i18n.t('poll_more_answers')} />
              <CheckboxInput
                onChange={onChangeShowParticipants}
                checked={post.poll.showParticipants}
                text={i18n.t('poll_show_participants')} />

              {/*<CheckboxInput
                // className={this.state.enableExpires ? 'enabledExpires' : ''} 
                // onChange={this.onChangeCheckBox.bind(this, 'enableExpires')} 
                checked={false}
                text={"this.t('checkboxes.end_date') + (this.state.enableExpires ? ':' : '')"} />
              {
                post.poll.expires &&
                <Datepicker
                onChange={expiresChange}
                  // className={this.state.enableExpires ? 'enabledExpires' : ''}
                  locale={i18n.language}
                  value={post.poll.expires} />
              }*}
            </React.Fragment>*/
          }
        </Presenter>
      )
    }
  )
);