import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

interface IDash {
  timeline: string;
  filters: string;
}

export const dash = (): IWidget<IColumn[]> => {
  const ids = guids<IDash>(['timeline', 'filters']);
  return generateWidget<IColumn[]>({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget<IColumn[]>({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.timeline,
                    id: ids.timeline,
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
              generateColumn({
                items: [
                  generateWidget({
                    id: ids.filters,
                    type: widgets.types.filters + '/timeline',
                  }),
                  generateWidget({
                    type: widgets.types.coursesMy,
                  }),
                  generateWidget({
                    type: widgets.types.mobileApp,
                  }),
                  generateWidget({
                    type: widgets.types.virtcurrency,
                  }),
                  generateWidget({
                    type: widgets.types.birthdays,
                  }),
                  generateWidget({
                    type: widgets.types.quests,
                  }),
                  generateWidget({
                    type: widgets.types.ratings,
                  }),
                  generateWidget({
                    type: widgets.types.shop,
                  }),
                ],
                styles: {
                  width: '28%',
                },
                mobileHidden: true,
              }),
            ],
          })
        ],
        styles: {
          width: '100%',
        },
      })
    ]
  });
}