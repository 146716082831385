import React from 'react';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { TimePicker2 } from 'blocks/TimePicker2/TimePicker2';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

export const time = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <TimePicker2
    onChange={(newValue: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => newValue))}
    value={props.data[get_variable_name(props.uischema.scope)]} />
}
// end TimeControl