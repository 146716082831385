import React, { FC, useState, useEffect, memo, useRef, useCallback } from 'react';
import { IAlbumViewProps, mapDispatchToProps, mapStateToProps } from './AlbumView.index';
import { connect } from 'react-redux';
import { Photo } from '../Photo/Photo'
// import { AddPhoto } from '../AddPhoto/AddPhoto'
// import { PhotoView } from '../PhotoView/PhotoView'
import './AlbumView.scss'
import { Dropzone } from 'uielements/src/Dropzone/Dropzone';
import { Button, Icon, CheckboxInput, Spinner } from 'uielements/src'
import * as utilsProj from 'utils.project/utils.project';
import * as utils from 'utils/src/utils'
import { i18n, Translate } from 'localization';
import { useDebounce, useRefedCallback, NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'

const LoadBtn = withLoadSpin(Button)

export const AlbumView_: FC<IAlbumViewProps> = ({
    aid = 'default',
    pids,
    gid,
    count,
    getPhoto,
    photoView,
    getAlbum,
    selected,
    deletePhotoMultiple,
    selectAllPhoto,
    isLoading,
    isFinished,
    uploadPhotos,
    showControls
}) => {

    const ref = useRef(null)
    const uploaderReference: any = useRef(null);

    const more = useRefedCallback((depsRef) => () => {
        const { isLoading, isFinished, photoView, aid, gid, count } = depsRef.current
        if (isLoading) return;
        if (isFinished) return;

        photoView ? getPhoto(aid, gid, count) : getAlbum(aid, gid, count)
    }, { isLoading, isFinished, photoView, aid, gid, count })

    const loadMore = useDebounce(more, 500)

    const moreRef = useInfiniteScroll({ isLoading, loadMore, isFinished })

    const setUploaderReference = useCallback((ref: any) => uploaderReference.current = ref, [uploaderReference]);

    useEffect(() => {
        photoView ? getPhoto(aid, gid, count) : getAlbum(aid, gid, count);
    }, [aid, gid, photoView])

    const onOpen = (id: string) => () => {
        // openId = id;
        // setOpen(true);
    }
    // const onClose = () => setOpen(false);

    const deleteSelected = () => {
        utilsProj.confirm({
            text: i18n.t('pryaniky.photoalbums.album.view.multipledelete',
                { oldData: selected.length.toString() }),
            onConfirm: () => deletePhotoMultiple(aid, selected)
        })

    }
    const selectAll = () => selectAllPhoto(aid)
    const selectedAll = selected.length === count;

    const openFileWindow = () => uploaderReference.current.open()

    const onFilesDrop = (acceptedFiles: any) => uploadPhotos(aid, gid, acceptedFiles);




    return <div ref={ref} className={'AlbumView'}>
        <div className={'AlbumView-Actions'}>
            {/* <h2>{name}</h2> */}

            {showControls && <>
                <div className={'AlbumView-SelectsBox'}>
                    <CheckboxInput checked={selectedAll} onChange={selectAll}>{i18n.t('pryaniky.photoalbums.album.view.selectall')}</CheckboxInput>
                    {selected.length !== 0 && <>
                        <div className={'AlbumView-SelectCount'}>{i18n.t('pryaniky.photoalbums.album.view.selectcount', { oldData: selected.length.toString() })}</div>
                        <Button theme={'unstyled_center'} onClick={deleteSelected}>{i18n.t('pryaniky.photoalbums.album.view.delete')}</Button>
                    </>}
                </div>

                <div className={'AlbumView-CreateBox'}>
                    <Button theme={'unstyled_center'} onClick={openFileWindow}><Icon icon={'plus'} /></Button>
                </div>
            </>}
        </div>

        <div className={'AlbumView-List'}>
            {pids.map((fid: string) => <Photo showControls={showControls} key={fid} onClick={onOpen(fid)} gid={gid} aid={aid} fid={fid} />)}
        </div>
        {ref.current && <Dropzone
            reference={setUploaderReference}
            onDrop={onFilesDrop}
            hoverElem={ref.current}
        />}
        {/* <PhotoView gid={gid} aid={aid} id={openId} onClose={onClose} open={open} /> */}

        <div className={'AlbumView-MoreBox'}>
            {(!isFinished && count !== 0) && <LoadBtn
                forwardedRef={moreRef} onClick={more}
                isLoading={isLoading}
            >
                {i18n.t('pryaniky.photoalbums.album.view.more')}
            </LoadBtn>}
            {/* {isLoading ? <div><Spinner /></div> : ((!isFinished && count !== 0) && <Button forwardedRef={moreRef} onClick={more}></Button>)} */}
        </div>
    </div>
}

export const AlbumView = connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(AlbumView_))
