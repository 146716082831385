import React, { ComponentProps, createContext, FC, useCallback, useRef, useState } from "react";

import { ItemsList } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { defaultMiddleware, useDefaultStaticStates } from 'muicomponents/src/ItemsListDialog/defaultListState';
import { useDidUpdateEffect, useReffedState, useScrollListState, useSearchParams } from 'utils/src/hooks';
import { NothingFoundText } from "blocks/ListMui/_type/NotingFoundText";
import { tasksListClasses, TasksListProps } from "./TasksList.index";
import { i18n, Translate } from 'localization';
import { checkResponseStatus, expireColumnName, TaskFilterStatus, TaskModel, TaskModelColumnType, useMyTasksCountManagerUpdate } from "utils/src";
import { Button } from "muicomponents/src";
import { HeaderRootBox, SearchActionsBox, SearchActionsSelect } from "./TasksList.styled";
import { getTasks } from "utils/src/requests/requests.tasks";
import { useSelector } from "react-redux";
import { getAuthUser } from "utils/src/CommonRedux/base/actions";
import { TasksListItem } from "./Item";
import { UpdateTask } from "../Dialog/UpdateTask";
import { taskModelSimpleToTaskModel } from "utils/src/BaseConverters/tasks.converters";
import { TasksBody } from "./Body";
import { TasksListComponent } from "./List";
import { TasksContext, tasksContextDefaultValue, useTasksListContext } from "./TasksList.context";

const SearchAction: FC<{
    selectedFilter: TaskFilterStatus;
    onFilterChange: (newFilter: TaskFilterStatus) => void;
    addNewTask: (newTaks: TaskModel) => void;
}> = ({
    selectedFilter,
    onFilterChange,
    addNewTask
}) => {

    const context = useTasksListContext();

    const updateCount = useMyTasksCountManagerUpdate(context.userId);
    const updateCountRef = useRef(updateCount);
    updateCountRef.current = updateCount;
    
    return (
        <SearchActionsBox
            className={tasksListClasses.searchActions}
        >
            <SearchActionsSelect
                size="small"
                value={selectedFilter}
                onChange={(e) => {
                    onFilterChange(e.target.value as TaskFilterStatus);
                }}
                options={Object.typedKeys(TaskFilterStatus).map(key => {
                    return {
                        title: <Translate i18nKey={`pryaniky.tasks.filter.${key}`} />,
                        value: TaskFilterStatus[key]
                    }
                })}
            />
            <Button
                className={tasksListClasses.headerButton}
                variant="contained"
                onClick={() => {
                    UpdateTask({
                        userId: context.userId
                    })
                    .then((newData) => {
                        addNewTask(taskModelSimpleToTaskModel(newData));
                        updateCountRef.current?.(1);
                    })
                    .catch(console.warn)
                }}
            >
                <Translate i18nKey={'createWith'} values={{
                    text: Translate.t({ i18nKey: 'pryaniky.tasks.name.create' }).toLowerCase()
                }} />
            </Button>
        </SearchActionsBox>
    );
};


export const TasksList: FC<TasksListProps> = ({
    context
}) => {

    const authUser = useSelector(getAuthUser);
    const userId = context.uId || context.userId || authUser?.baseData.id;
    const userIdRef = useRef<string>(userId);
    userIdRef.current = userId;

    const [contextState, setContextState, contextStateRef] = useReffedState({
        ...tasksContextDefaultValue,
        userId
    });
    useDidUpdateEffect(() => {
        setContextState({
            ...contextStateRef.current,
            userId
        })
    }, [userId]);

    const [activeFilter, setActiveFilter] = useState<TaskFilterStatus>(TaskFilterStatus.active);

    const listStateHook: ComponentProps<typeof ItemsList>['listStateHook'] = ({ defaultSelected }, middlewareParams) => {
        const {
            search,
            debouncedSearch,
            changeSearch,
            onChangeTab
        } = useDefaultStaticStates(middlewareParams.tabsValue || middlewareParams.tabs?.[0]?.id)// состояние для поиска и табов

        // получаем список тэгов
        const state = useScrollListState(async function (skipCount, count) {

            const response = await getTasks({ userId: userIdRef.current, skipCount, count, filter: activeFilter, search: debouncedSearch });
        
            if (!checkResponseStatus(response)) throw response;

            const columns: typeof response.data.columns = [];

            response.data.columns.forEach(col => {
                columns.push(col);
                if(col.name === 'dueDate') {
                    columns.push({
                        name: expireColumnName,
                        type: TaskModelColumnType.string,
                        isVisible: true
                    });
                }
            });

            setContextState({
                ...contextStateRef.current,
                allowAdd: response.data.allowAdd,
                columns
            });

            return response.data.rows;
        }, [debouncedSearch, activeFilter], {}, true);

        const addNewTask = useCallback<ComponentProps<typeof SearchAction>['addNewTask']>((newTaks) => {
            state.actions.prependItems([newTaks]);
        }, [state.actions.prependItems]);

        return {
            middlewareParams: {
                ...middlewareParams,
                listHeaderProps: {
                    ...middlewareParams.listHeaderProps,
                    SearchActions: <SearchAction
                        selectedFilter={activeFilter}
                        onFilterChange={setActiveFilter}
                        addNewTask={addNewTask}
                    />
                }
            },
            state,
            defaultSelected,
            middleware: defaultMiddleware,
            search,
            debouncedSearch,
            onChangeTab,
            changeSearch
        }
    };

    return (
        <TasksContext.Provider value={contextState}>
            {/* <ItemsList<TaskModel> */}
            <ItemsList<any>
                BodyComponent={TasksBody}
                ListComponent={TasksListComponent}
                ItemComponent={TasksListItem}
                listHeaderProps={{
                    SearchInputProps: {
                        sx: {
                            marginBottom: 0
                        }
                    },
                    rootBoxProps: {
                        component: HeaderRootBox,
                    }
                }}
                // listBodyProps={{ joinBody: true, disableBorder: true, disablePadding: true }}
                listComponentProps={{
                    textEmpty: <NothingFoundText/>,
                }}
                listStateHook={listStateHook}
            />
        </TasksContext.Provider>
    );
};