/**
 * @packageDocumentation
 * @module Filter_type_groups
 */
import React from 'react';
import { FiltersPresenter } from '../Filters';
import { i18n, Translate } from 'localization';
import { IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, mapStateToProps, mapDispatchToProps, cnFilters } from '../Filters.index';
import { IStateType as IState } from 'redux/store';
import { connect } from 'react-redux';
import { Selectize } from 'uielements/src';
import { withRouter } from 'react-router';

/**
 * dv - default values of this filters if dv[key] === 'undefined' default is 'all'
 */
const dv: Record<string, string> = {
    catFilter: 'waiting'
}

/**
 * h - filters header object
 */
const h: Record<string, string> = {
    catFilter: i18n.t('pryaniky.filter.quests.moderate.title')
}

/**
 * f - data to this filters
 */
const f: any[][] = [ // all filters
    [
        { id: 'all', title: i18n.t('pryaniky.filter.quests.all'), type: 'catFilter', value: 'all' },
        {
            id: 'toapprove',
            title: i18n.t('pryaniky.filter.quests.toapprove'),
            type: 'catFilter',
            value: 'waiting'
        },
        {
            id: 'approved',
            title: i18n.t('pryaniky.filter.quests.approved'),
            type: 'catFilter',
            value: 'approved'
        },
        {
            id: 'deleted',
            title: i18n.t('pryaniky.filter.quests.deleted'),
            type: 'catFilter',
            value: 'deleted'
        },
    ]
]

class FiltersTypeQuestModeratePresenter extends FiltersPresenter {

    public defaulValues = {
        catFilter: 'waiting'
    }

    /**
     * prepare filters to render data like {}[][]
     */
    public data = f.map(fl => fl.map(el => ({
        id: el.id,
        type: el.type || 'catFilter',
        title: el.title,
        value: el.value,
        showProps: el.showProps
    })));

    /**
     * render - it is render)
     */
    public render() {
        const { tag: Tag = 'div', context, className, itemsView } = this.props;
        return (
            <Tag tag='div' {...this.props} className={cnFilters({}, [className])} >
                {
                    this.data.map((fl, idx) => {
                        const type = fl[0] && fl[0].type;
                        // let active = this.getActive(idx);
                        // if (active === 'all' && dv[type] !== undefined) active = dv[type];
                        fl = fl.filter(i => Object.keys(i.showProps || {}).reduce((a, k, idx) => {
                            if (i.showProps[k] !== itemsView[k as keyof typeof itemsView]) return false
                            return a;
                        }, true as boolean));
                        return <div className={cnFilters('Block')} >
                            {type && h[type] && <h4 className={cnFilters('Subtitle')} children={`${h[type]}:`} />}
                            <Selectize onClick={this.handleChange} elements={fl} active={this.getActive(idx)} />
                        </div>
                    })
                }
            </Tag>
        )
    }
}


/**
 * connected to redux
 */
export const FiltersTypeQuestModerate = withRouter(
    connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(FiltersTypeQuestModeratePresenter)
);