import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { i18n, Translate } from 'localization';
import { FormControlLabel } from 'muicomponents/src';
import { Button } from 'muicomponents/src/Button/Button';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox';
import { useNodeMediaQuery } from 'muicomponents/src/ContainerResizeWatcher/ContainerResizeWatcher';
import { Divider } from 'muicomponents/src/Divider/Divider';
import { Grid } from 'muicomponents/src/Grid';
import { CleaningServices } from 'muicomponents/src/Icons';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
import { TOption } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/ListParamsSelector.index';
import { styled } from 'muicomponents/src/mui/system';
import { Tooltip } from 'muicomponents/src/Tooltip';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCurrentPage, getPageType, getPageTypeSettings } from 'redux/actions/Widgets';
import widgetsActions from 'redux/actionsTypes/Widgets';
import { checkResponseStatus, CustomSettings, PageTypes } from 'utils/src';
import { list } from 'utils/src/requests/requests.tags';
import { IRequest } from 'utils/src/requests/service';
import { UsersSuggester } from '../HeaderWikiWithFilters/HeaderWikiWithFilters.styled';
import { StyledTabs } from '../List_type_wiki.styled';
import { preapreCollectionAliasByContext } from '../List_type_wiki.utils';
import { cnPreListFilters, IPreListFiltersProps } from './PreListFilters.index';
import './PreListFilters.scss';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

const StyledFormControlLabel = styled(FormControlLabel)({
    marginRight: 0,
}) as typeof FormControlLabel;

const ClearGridItem = styled(Grid)({
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'end',
}) as typeof Grid;

const ClearButton = styled(Button)({
    width: 'auto',
}) as typeof Button;

const activeFilters = [
    { id: 'active', title: i18n.t('pryaniky.modal.wikipage.filter.archived.false'), value: 'active', type: 'active' },
    {
        id: 'archive',
        title: i18n.t('pryaniky.modal.wikipage.filter.archived.true'),
        value: 'archived',
        type: 'archived',
    },
    { id: 'draft', title: i18n.t('pryaniky.modal.wikipage.filter.draft.true'), value: 'draft', type: 'draft' },
];
let request: IRequest;
const sortOption: TOption[] = [
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.abc_asc' }),
        value: 'abc_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.abc_desc' }),
        value: 'abc_desc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.creationdate_asc' }),
        value: 'creationdate_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.creationdate_desc' }),
        value: 'creationdate_desc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.changedate_asc' }),
        value: 'changedate_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.changedate_desc' }),
        value: 'changedate_desc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.position_asc' }),
        value: 'position_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.position_desc' }),
        value: 'position_desc',
    },
];
export const PreListFilters: FC<IPreListFiltersProps> = ({
    context,
    widget,
    isAdmin,
    hasRights,
    isCollection,
    contentTypes,
}) => {
    const history = useHistory();

    const currentPage = useSelector(getCurrentPage);
    const pageType = getPageType(currentPage);
    const pageTypeSettings = getPageTypeSettings(currentPage);

    const [categoriesState, setCategoriesState] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [ref, point700] = useNodeMediaQuery({ minWidth: 665 });
    const [showCategoriesTabs, setShowCategoriesTabs] = useState<boolean>(!currentPage?.showCategoryAsSelector);

    useEffect(() => {
        if (point700 && !currentPage?.showCategoryAsSelector) {
            setShowCategoriesTabs(true);
        } else {
            setShowCategoriesTabs(false);
        }
    }, [point700, currentPage?.showCategoryAsSelector]);
    // -------------------фильтр по категориям начало

    const getCategories = useCallback((search: string) => {
        if (request) request.ac.abort();
        request = list(0, 20, {
            search: search,
            url: preapreCollectionAliasByContext(context),
        });
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                if (d.data.length === 0) return setCategoriesState(d.data);
                const dataArr = [
                    { id: 'all', label: i18n.t('pryaniky.filter.wikilist.all'), type: 'catFilter', value: '' },
                    ...d.data.map((el: any) => ({
                        ...el,
                        label: Translate.t({ i18nKey: el.displayName }),
                        value: el.id,
                    })),
                ];
                setLoading(false);
                return setCategoriesState(dataArr);
            } else {
                setLoading(false);
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, []);

    useEffect(() => {
        getCategories('');
    }, [context.currentPageId]);

    const categoriesComponent = useMemo(() => {
        return (
            <>
                <CheckButtonSelector
                    value={context.catFilter as any}
                    onChange={(value) => {
                        const selectedId = categoriesState.find((el) => el.value === value).value;
                        history.replace(
                            history.location.pathname +
                                (selectedId ? `?${queryString.stringify({ catFilter: selectedId })}` : '')
                        );
                        dispatch({
                            type: widgetsActions.UPDATE_CONTEXTS,
                            payload: {
                                [widget.id]: {
                                    ['catFilter']: selectedId,
                                },
                            },
                        });
                    }}
                    options={categoriesState as any}
                    defaultText={Translate.t({ i18nKey: 'pryaniky.modal.wikipage.categories.filter' })}
                />
            </>
        );
    }, [categoriesState, context]);

    const tabAll = { label: Translate.t({ i18nKey: 'pryaniky.filter.wikilist.all' }), id: 'all' };

    let tabs = useMemo(() => {
        if (categoriesState && categoriesState?.length > 0) {
            const artclesTabs = [...categoriesState]?.map((el: any) => ({
                label: (
                    <Tooltip title={(el?.displayName || el?.label)?.replaceAll('_', ' ')} overflowOnly>
                        <div>{(el?.displayName || el?.label)?.replaceAll('_', ' ')}</div>
                    </Tooltip>
                ),
                id: el?.id,
            }));
            return artclesTabs;
        } else {
            return [tabAll];
        }
    }, [categoriesState]);

    const categoriesTabsComponent = useMemo(() => {
        if (loading) {
            return (
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            );
        } else {
            return (
                <StyledTabs
                    tabs={tabs}
                    value={context.catFilter || 'all'}
                    variant="scrollable"
                    scrollButtons
                    onChange={(event: React.SyntheticEvent<Element, Event>, tabName: string) => {
                        history.replace(
                            history.location.pathname +
                                (tabName && tabName !== 'all'
                                    ? `?${queryString.stringify({ catFilter: tabName })}`
                                    : '')
                        );
                        dispatch({
                            type: widgetsActions.UPDATE_CONTEXTS,
                            payload: {
                                [widget.id]: {
                                    ['catFilter']: tabName,
                                },
                            },
                        });
                    }}
                />
            );
        }
    }, [categoriesState, context, isCollection, loading]);

    // -------------------фильтр по категориям конец

    // ---------------- сортировка компонент и обработка начало ------------------
    // const globalSorts = context.sort
    // const chosenFilter = context.order
    // const chosenSort = Object.keys(globalSorts).find(v => globalSorts[v as keyof typeof globalSorts] !== '')

    const setSort = (item: string) => {
        const [key, value] = item.split('_');
        dispatch({
            type: widgetsActions.UPDATE_CONTEXTS,
            payload: {
                [widget.id]: {
                    ['order']: key as any,
                    ['sort']: value as any,
                },
            },
        });
    };

    // const sortValue = globalSorts[(chosenSort || '') as keyof typeof globalSorts]
    // const sortKey = chosenSort + (sortValue ? `_${sortValue}` : '')
    const sortForceText = context?.order?.startsWith('abc')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.abc' })
        : context?.order?.startsWith('creationdate')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.creationdate' })
        : context?.order?.startsWith('changedate')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.changedate' })
        : context?.order?.startsWith('position')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.position3' })
        : undefined;

    const sortComponent = useMemo(() => {
        return (
            <Box className={cnPreListFilters('SortFiltersSelector')}>
                <CheckButtonSelector
                    icon={context?.sort === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    value={`${context?.order}_${context?.sort}`}
                    onChange={setSort}
                    forceText={sortForceText}
                    options={sortOption}
                    defaultText={Translate.t({ i18nKey: `pryaniky.shop.sort` })}
                />
            </Box>
        );
    }, [context]);

    // ---------------- сортировка компонент и обработка конец ------------------

    // ---------------- фильтр активные, черновик, архивные начало ------------------

    const selectedActiveFilterText = useMemo(() => {
        const selectedType = activeFilters.find((el) =>
            context?.draft ? el.value === 'draft' : context?.archived ? el.value === 'archived' : el.value === 'active'
        )?.title;
        // el.value === (context.draft || 'all')
        return selectedType;
    }, [context.archived, context.draft]);

    const activeFilter = useMemo(() => {
        if (isAdmin || context.isGroupAdmin || hasRights)
            return (
                <Grid item xs={point700 ? 4 : 12}>
                    <CheckButtonSelector
                        // icon={context.sort === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        // value={context.daft? context.archived}
                        onChange={(selected) => {
                            console.log('selected', selected);
                            dispatch({
                                type: widgetsActions.UPDATE_CONTEXTS,
                                payload: {
                                    [widget.id]: {
                                        ['archived']: selected === 'archived' ? true : false,
                                        ['draft']: selected === 'draft' ? true : false,
                                    },
                                },
                            });
                        }}
                        forceText={selectedActiveFilterText}
                        options={activeFilters.map((el) => {
                            return {
                                label: el.title,
                                value: el.value,
                            };
                        })}
                    />
                </Grid>
            );
    }, [context, isAdmin, context.isGroupAdmin, hasRights, point700]);

    // ---------------- фильтр активные, черновик, архивные конец ------------------

    // ---------------- типы обработка начало ------------------

    const contentTypeFilterOptions = useMemo(() => {
        return [
            { label: i18n.t('pryaniky.wikilist.item.all'), value: 'all' },
            ...contentTypes.map((el) => {
                return {
                    label: i18n.t(`pryaniky.wikilist.item.${el.id}s`),
                    value: el.id,
                };
            }),
        ];
    }, [contentTypes]);

    const dispatch = useDispatch();

    const useGalleryFilter = useMemo(() => {
        return pageType === PageTypes.imageGallery || pageType === PageTypes.videoGallery;
    }, [pageType]);

    return (
        <Box
            ref={(el) => {
                (ref as any).current = el;
            }}
        >
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <StyledFormControlLabel
                    sx={{ paddingBottom: point700 ? '12px' : '0', marginRight: '16px' }}
                    control={
                        <Checkbox
                            checked={context.searchOnlyTitle}
                            onChange={() => {
                                dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS,
                                    payload: {
                                        [widget.id]: {
                                            searchOnlyTitle: !context.searchOnlyTitle,
                                        },
                                    },
                                });
                            }}
                        />
                    }
                    label={i18n.t('pryaniky.list.wiki.search.only.by.name')}
                />

                {/* {   
                    https://tracker.yandex.ru/COMMON-12913
                    !useGalleryFilter &&
                    <StyledFormControlLabel
                        sx={{ paddingBottom: point700 ? "12px" : "0", marginRight: "16px" }}
                        control={
                            <Checkbox
                                checked={context.coll}
                                onChange={() => {
                                    dispatch({
                                        type: widgetsActions.UPDATE_CONTEXTS, payload: {
                                            [widget.id]: {
                                                coll: !context.coll
                                            }
                                        }
                                    })
                                }}
                            />
                        }
                        label={i18n.t("pryaniky.list.wiki.search.in.collection")}
                    />
                } */}

                {useFavoriteWikiItems && (
                    <StyledFormControlLabel
                        sx={{ paddingBottom: point700 ? '12px' : '0' }}
                        control={
                            <Checkbox
                                checked={context.showFavorites}
                                // sx={{ padding: "0" }}

                                onChange={() => {
                                    dispatch({
                                        type: widgetsActions.UPDATE_CONTEXTS,
                                        payload: {
                                            [widget.id]: {
                                                showFavorites: !context.showFavorites,
                                            },
                                        },
                                    });
                                }}
                            />
                        }
                        label={i18n.t('pryaniky.list.wiki.search.in.showFavorites')}
                    />
                )}
            </Box>

            {isCollection && showCategoriesTabs && categoriesState.length > 0 && !loading && categoriesTabsComponent}

            {isCollection && showCategoriesTabs && categoriesState && <Divider sx={{ marginBottom: '12px' }} />}

            <Box
                className={cnPreListFilters('BulitsForSearch')}
                sx={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    flexGrow: 1,
                    paddingTop: !point700 ? '12px' : '0',
                    position: 'relative',
                }}
            >
                <Grid container spacing={1}>
                    {(!isCollection || !showCategoriesTabs) && categoriesState.length > 0 && (
                        <Grid item xs={point700 ? 4 : 12}>
                            {categoriesComponent}
                        </Grid>
                    )}
                    {!useGalleryFilter && (
                        <Grid item xs={point700 ? 4 : 12} className={cnPreListFilters('BulitsForSearch-SortFilters')}>
                            <CheckButtonSelector
                                value={context.type}
                                ActionButtonProps={{
                                    variant: 'outlined',
                                    className: 'TypeSelector',
                                    // textColor: undefined,
                                    sx: {
                                        padding: '0 0 0 8px',
                                        fontSize: '16px',
                                    },
                                }}
                                options={contentTypeFilterOptions}
                                onChange={(selected) => {
                                    dispatch({
                                        type: widgetsActions.UPDATE_CONTEXTS,
                                        payload: {
                                            [widget.id]: {
                                                type: selected || 'all',
                                            },
                                        },
                                    });
                                }}
                                defaultText={Translate.t({ i18nKey: 'pryaniky.LMS.tracks.trackStats.materialType' })}
                            />
                        </Grid>
                    )}

                    <Grid item xs={point700 ? 4 : 12}>
                        {sortComponent}
                    </Grid>
                    {/* {(isAdmin || context.isGroupAdmin || hasRights) && <Grid item xs={showCategoriesTabs ? 4 : 12} > */}
                    {activeFilter}
                    {/* </Grid>} */}
                    <Grid item xs={point700 ? 4 : 12}>
                        <UsersSuggester
                            TextFieldProps={{
                                label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.author' }),
                            }}
                            size={'small'}
                            sx={{
                                width: '100%',
                                '.MuiAutocomplete-tag': {
                                    margin: 0,
                                },
                                '.MuiAutocomplete-tag ': {
                                    margin: '5px',
                                },
                                '.MuiChip-root.MuiChip-outlined': {
                                    height: '24px',
                                    '.MuiChip-avatar': {
                                        width: '18px',
                                        height: '18px',
                                    },
                                },
                            }}
                            value={context.author}
                            onChange={(event, value: any) =>
                                dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS,
                                    payload: {
                                        [widget.id]: {
                                            author: value,
                                        },
                                    },
                                })
                            }
                            requestAdditionalParams={{ excludeMe: false }}
                            noAdornment={true}
                        />
                    </Grid>

                    {point700 && !isAdmin && !context.isGroupAdmin && !hasRights && (
                        <Grid item xs={point700 ? 4 : 12} sx={{ height: '48px' }}></Grid>
                    )}

                    <ClearGridItem item xs={point700 ? 2 : 6}>
                        <ClearButton
                            size="small"
                            variant="outlined"
                            className={cnPreListFilters('CleanButton')}
                            startIcon={<CleaningServices />}
                            onClick={() =>
                                dispatch({
                                    type: widgetsActions.UPDATE_CONTEXTS,
                                    payload: {
                                        [widget.id]: {
                                            catFilter: '',
                                            archived: false,
                                            draft: false,
                                            author: null,
                                            order: 'position',
                                            sort: 'asc',
                                            type: 'all',
                                            types: [],
                                        },
                                    },
                                })
                            }
                        >
                            <Translate i18nKey="search.filterd.clear" />
                        </ClearButton>
                    </ClearGridItem>
                </Grid>
            </Box>
        </Box>
    );
};
