import React, { FC, useEffect, memo } from 'react'
import {
    cnClassName,
    Skills
} from './interfaces'
import './style.scss'
import Item from './Item'
import { i18n, Translate } from 'localization';

type IPresenterProps = Skills.IPresenterProps

export const Presenter: FC<IPresenterProps> = ({
    className,
    skills,
    confirmSkill,
    userid,
    deleteSkill,
    showControls,
    setShow,
    isFinished,
    isLoading,
    create
}) => {
    return  <table className={cnClassName({}, [className])}>
            <colgroup>
                <col span={1} className={cnClassName('ColIndex')} />
                <col span={1} className={cnClassName('ColName')} />
                <col span={1} className={cnClassName('ColUsers')} />
                <col span={1} className={cnClassName('ColActions')}/>
            </colgroup>
            <tbody>
                {(!isLoading && !skills.length) ?
                    <div className={cnClassName('Empty')}>
                        {i18n.t("pryaniky.skills.placeholder.empty.title.1")}
                    </div> :
                    skills.map((skill, i) => <Item
                    setShow={setShow}
                    showControls={showControls}
                    deleteSkill={deleteSkill}
                    key={skill.tag.id}
                    index={i + 1}
                    skill={skill}
                    confirmSkill={confirmSkill}
                    userid={userid}
                />)}
            </tbody>
        </table>

}

export default memo(Presenter);
