import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { cnMatrix, IMatrixProps, IMatrixState } from './Matrix.index';

import './Matrix.scss';
import { Button } from 'uielements/src/Button/Button';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export class MatrixPresenter extends React.Component<IMatrixProps, IMatrixState> {
  public el: HTMLElement | null;

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'table', className = '', data, errorMessage, invalid } = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnMatrix({}, [className, customClassNames])}>
        <thead className={cnMatrix('Header')}>
          <tr>
            <td />
            {
              data.answers.map((answer: { [s: string]: any }, i: number) => {
                return <td key={i} children={answer.text} />
              })
            }
          </tr>
        </thead>
        <tbody className={cnMatrix('Body')}>
          {
            data.rows.map((row: { [s: string]: any }, i: number) => {
              return <tr key={i} className={cnMatrix('Row')}>
                <td children={row.name} />
                {
                  data.answers.map((answer: { [s: string]: any }, index: number) => {
                    return <td key={index} className={cnMatrix('Cell')} children={
                      <Button onClick={this.onChange.bind(this, row.id, answer.id)} theme="unstyled">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={cnMatrix('Radio')}>
                          <path fill="#fff" stroke="#000" d="M8 .5A7.5 7.5 0 1 1 .5 8 7.5 7.5 0 0 1 8 .5z" />
                          {row.selectedAnswer === answer.id && <circle
                            className="radioDot"
                            cx="8"
                            cy="8"
                            r="7"   // the radius of the inner radio dot
                            fill="black"   // color of the radio dot
                          />}
                          {/* {row.selectedAnswer === answer.id &&<path d="M8 5a3 3 0 1 1-3 3 3 3 0 0 1 3-3z" />} */}
                        </svg>
                      </Button>
                    } />
                  })
                }
              </tr>
            })
          }
        </tbody>
        {errorMessage && invalid && <Tooltip
          className={cnMatrix('HelpTooltip')}
          title={errorMessage}
        >
          <Icon icon="exclamation-circle" className={cnMatrix('ErrorIcon')} />
        </Tooltip>}

        {this.props.children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  private onChange(rId: string, aId: string) {
    if (this.props.onChange) this.props.onChange(rId, aId);
  }

}


export const Matrix = MatrixPresenter;
  //   compose(
  //     // withBemMod(cnMatrix(), {})
  //     )(
  //       MatrixPresenter
  //   )
  // )
