import React, { FC, useState } from 'react';
import { IRejectAchivmentProps, IRejectAchivmentState } from './RejectAchivment.index';
import { Textarea, Input, Button } from 'uielements/src';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import { i18n, Translate } from 'localization';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { connect } from "react-redux";
import { TextField } from 'muicomponents/src/TextField/TextField'
import { FormControlLabel } from 'muicomponents/src/FormControl';
import { styled } from 'muicomponents/src/mui/system';
import { Checkbox } from "muicomponents/src/Checkbox/Checkbox"


const StyledFormControlLabel = styled(FormControlLabel)({
  // margin: "24px",
  marginLeft: "0",
  // marginTop: "16px",
  // width: '100%',
  // ".MuiTypography-root": {
  //   fontSize: '14px'
  // }

});
const RejectAchivmentPresenter: FC<IRejectAchivmentProps> = ({
  handleAccept,
  handleClose,

}) => {
  const [comData, setComData] = useState({ comment: "" })
  const [sendNotification, setSendNotification] = useState(false)


  // const stringValueChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => setState({ [e.currentTarget.name]: e.currentTarget.value });

  const onChangeTarget = (e: any) => setComData({ comment: e.currentTarget.value });


  return (
    <React.Fragment>
      <DialogTitle onClose={handleClose}>
        <Translate i18nKey='pryaniky.dialogs.RejectAchivment.title' />
      </DialogTitle>
      <DialogContent>
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={sendNotification}
              onChange={(e) => setSendNotification(!sendNotification)}
              size={"small"}
              sx={{ padding: "0 4px 0 0" }} />
          }
          label={<Translate i18nKey='pryaniky.dialogs.RejectAchivment.sendNotification' />}
        />
        {/* <Textarea name={'comment'} value={comData.comment || ''} onChange={onChangeTarget} placeholder={i18n.t('pryaniky.comment')} /> */}
        {sendNotification && <TextField
          fullWidth
          value={comData.comment}
          // disabled={action?.includes("shopAccessibility") && sendNotify === false}
          onChange={(e) => setComData({ comment: e.target.value })}
          label={<Translate i18nKey={'pryaniky.comment'} />}
          sx={{ marginTop: "9px" }}
        />}
      </DialogContent>
      <DialogActions
        acceptText={i18n.t('pryaniky.RejectAchivment.dialog.item.confirm')}
        closeText={i18n.t('pryaniky.cancel.dialog')}
        // disableAccept={strengthPass.score === 0 || newPassword !== conPassword}
        onAccept={() => handleAccept({comment:comData.comment, needSendNotification: sendNotification})}
        onClose={handleClose} />
    </React.Fragment>
  );
}



export const DialogRejectAchivment = createModal(RejectAchivmentPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});
