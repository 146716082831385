import React, { useState } from "react";
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { i18n, Translate } from 'localization';
import './MobileAppWidget.scss';
// import {  Button } from "uielements/src";
import { DialogMobileApp } from "blocks/Dialogs/MobileDialog/DialogWithQRMui";
import { DialogMobileAppLogin } from "blocks/Dialogs/MobileDialog/QRLogin/QRLoginMui";
import { Link } from 'muicomponents/src/Link/Link'
import { Button } from 'muicomponents/src/Button/Button'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import MobileImage from './MobileApp.svg';
const cnMobileAppWidget = cn('MobileAppWidget');

interface IMobileAppWidgetProps extends IClassNameProps {
  tag: any;
  settings: {
    usersCount: number;
    img: IObject;
    showImg: boolean;
  },
}
export interface IObject {
  aspect: number,
  elements: IImageObject[]
}
export interface IImageObject {
  imgId: string,
  imgUrl: string,
  href: string,
  imgName: string,

}
export const MobileAppWidget: React.FC<IMobileAppWidgetProps> = ({ tag: TagName = 'div', settings, ...props }) => {
  // const [showDownloadDialog, setShowDownloadDialog] = React.useState(false);
  // const [showLoginDialog, setShowLoginDialog] = React.useState(false);

  // const onCloseDownloadDialog = () => setShowDownloadDialog(false);
  // const openDownloadDialog = () => setShowDownloadDialog(true);
  // const onCloseLoginDialog = () => setShowLoginDialog(false);
  // const openLoginDialog = () => setShowLoginDialog(true);
  return (
    <TagName {...props} className={cnMobileAppWidget({}, [props.className])}>

      <h4 className={cnMobileAppWidget("Header-Title")}>{i18n.t("pryaniky.widgets.mobileApp.title")}</h4>
      <div className={cnMobileAppWidget('Header-Image')}>
        {settings?.showImg && settings.img.elements[0].imgUrl && <Avatar
          precentage={100}
          shape={'rectangle'}
          imgUrl={settings.img.elements[0].imgUrl ? settings.img.elements[0].imgUrl : MobileImage} />}
      </div>
      <ul className={cnMobileAppWidget("Advantages")}>
        <li className={cnMobileAppWidget("Advantage")}>{i18n.t("pryaniky.widgets.mobileApp.notifications")}</li>
        <li className={cnMobileAppWidget("Advantage")}>{i18n.t("pryaniky.widgets.mobileApp.handbook")}</li>
        <li className={cnMobileAppWidget("Advantage")}>{i18n.t("pryaniky.widgets.mobileApp.thanks")}</li>
      </ul>

      <div className={cnMobileAppWidget("OpenQRButton")}>
        {/* {i18n.t("pryaniky.widgets.mobileApp.or")} */}
        <Button variant={'contained'} color={'primary'} sx={{ margin: '5px 0', fontWeight: 500, fontSize: "13px", lineHeight: "22px", letterSpacing: "0.46px", padding: "4px 10px" }} className={cnMobileAppWidget("OpenQRButton-Submit")} children={i18n.t('pryaniky.widgets.mobileApp.download1')} onClick={() => DialogMobileApp({}).then(() => { }).catch(() => { })} />
        <Button variant={'contained'} color={'primary'} sx={{ margin: '5px 0', fontWeight: 500, fontSize: "13px", lineHeight: "22px", letterSpacing: "0.46px", padding: "4px 10px" }} className={cnMobileAppWidget("OpenQRButton-Submit")} children={i18n.t('pryaniky.widgets.mobileApp.logIn.mob')} onClick={() => DialogMobileAppLogin({}).then(() => { }).catch(() => { })} />
      </div>
    </TagName>
  );
};
