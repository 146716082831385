import * as React from 'react';
import { connect } from 'react-redux';
import { IDispatchProps, mapDispatchToProps } from 'redux/connector';

import { IListProps, IListState, IListStateProps, mapActionsToProps, mapStateToProps } from '../../List.index';
import { IListTypeQuestsProps, avalibleFiltersQuests, cnQuestsList } from './List_type_quests.index';

import { IStateType as IState } from 'redux/store';
import { ListLite } from 'uielements/src';
import { QuestItem } from '../../../QuestsWidget/QuestItem/QuestItem';
import { AbstractList } from '../../List';
import './List_type_quests.scss';
export class ListQuestsPresenter extends AbstractList<IListTypeQuestsProps, IListState> {
    public static defaultProps = {
        requestOptions: {
            skipCount: 0,
            count: 20,
        },
    };

    public filter = (filter: string) => {
        const { toggle } = this.props;
        this.props.requestOptions.filter = filter;
        toggle({
            id: this.listId,
            data: {
                hideData: true,
                clearData: true,
                isFinished: false,
                isLoading: false,
            },
        });
        setTimeout(this.getData, 100);
    };

    public getData = () => {
        const { requestOptions, context = {}, edit } = this.props;
        const { clearData, isLoading } = this.props;
        const { clearDataFromList, getQuestsList, toggle } = this.props;
        const id = this.listId;
        if (edit || isLoading) return;
        const opts = Object.assign({}, requestOptions);
        if (this.searchString && this.searchString !== '') opts.search = this.searchString;

        opts['filter'] = 'active';

        Object.keys(context).forEach((cKey: string) => {
            if (avalibleFiltersQuests[cKey]) opts[avalibleFiltersQuests[cKey]] = context[cKey];
        });

        toggle({
            id: this.listId,
            data: {
                isLoading: true,
                isFinished: false,
            },
        });
        if (clearData) clearDataFromList({ id, data: null });

        getQuestsList.call(this, id, opts);
    };

    public componentDidUpdate(pp: IListTypeQuestsProps) {
        const { id, props } = this;
        const { context: c = {}, toggle, isLoading } = props;
        const { context: pc = {} } = pp;
        if (Object.keys(avalibleFiltersQuests).filter((fk) => (c[fk] !== pc[fk] ? true : false)).length) {
            toggle({
                id,
                data: {
                    hideData: true,
                    clearData: true,
                    isFinished: false,
                },
            });
            setTimeout(() => this.getData(), 10);
        }
    }

    public renderChildren = () => {
        const { data, isFinished, isLoading, context } = this.props;

        if (isFinished && !data.length) return null;
        // const filter = Object.keys(context).reduce((acc: string | undefined, cKey: string) => avalibleFiltersQuests[cKey] && context[cKey], undefined);
        return (
            <React.Fragment>
                {
                    <ListLite
                        skeleton="post"
                        skeletonCount={20}
                        isFinished={isFinished}
                        isLoading={isLoading}
                        className="List-Wrap"
                        loadMore={this.getData}
                    >
                        {data.map((el) => (
                            <div className={cnQuestsList('Item', ['Widget'])}>
                                <QuestItem
                                    key={el}
                                    data={this.props.getListItem(this.listId, el)}
                                    listId={this.listId}
                                    standart
                                />
                            </div>
                        ))}
                    </ListLite>
                }
            </React.Fragment>
        );
    };
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
    mapStateToProps,
    mapDispatchToProps(mapActionsToProps)
)(ListQuestsPresenter);
