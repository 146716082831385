import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';

import { cnProjectLogo, IProjectLogoProps, mapStateToProps } from './ProjectLogo.index';

import './ProjectLogo.scss';

export const ProjectLogoPresenter: React.FC<IProjectLogoProps> = ({
  className = '',
  logoUrl,
  companyName,
  children,
}) => {
  return (
    <div className={cnProjectLogo({ border: logoUrl !== '/Content/img/none.png' }, [className])}>
      {companyName && <h1 className={cnProjectLogo('Name')} children={companyName} />}
      {children}
    </div>
  );
};

export const ProjectLogo = connect(mapStateToProps)(ProjectLogoPresenter);
//   compose(
//     withBemMod(cnProjectLogo(), {})
//     )(
//       ProjectLogoPresenter
//   )
// )
