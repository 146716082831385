import React from 'react';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';

export const checkbox = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <Checkbox
    className='CheckBox'
    onChange={(ev: any) => { 
      if (props.uischema.relations) props.uischema.relations.forEach((relation: string) => props.dispatch(Actions.update((relation as string).substr(2).split('/')[(relation as string).substr(2).split('/').length - 1], (oldData) => false)));
      props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => ev))
    }}
    value={props.data[get_variable_name(props.uischema.scope)]}
    label={props.uischema.label} />
}
// end CheckboxControl