import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { cnDistributeTimer, mapDispatchToProps, mapStateToProps, IDistributeTimer } from './DistributeTimer.index'
import { Button, Avatar, Icon, Loading } from 'uielements/src'
import { i18n, Translate } from 'localization';
import './DistributeTimer.scss'
import { CircularProgress } from '@material-ui/core';
// let interval: NodeJS.Timeout;

let loading: boolean = true;

const DistributeTimerPresenter = ({
    tag: Tag = 'div',
    distibDate,
    shouldByDistribute,
    ...props
}: IDistributeTimer) => {
    // const { tag: Tag = 'div',
    //     distibDate
    // } = props

    const interval = useRef<NodeJS.Timeout>();

    const [isLoading, setIsLoading] = React.useState(loading)
    const [totalState, setTotalState] = React.useState<number | string>(0)
    const [daysState, setDaysState] = React.useState<number | string>(0)
    const [hourState, setHourState] = React.useState<number | string>(0)
    const [minutesState, setMinutesState] = React.useState<number | string>(0)
    const [secondsState, setSecondsState] = React.useState<number | string>(0)

    const [minutesWord, setminutesWord] = React.useState("111")
    const [hoursWord, setHoursWord] = React.useState("111")
    const [daysWord, setDaysWord] = React.useState("111")

    const getRightMinutesWord = (count: string) => {
        const wordLengthMinuts = count.toString().length
        const getLastMinats = count.toString()[wordLengthMinuts - 1]
        const countToNum = Number(count)
        if (countToNum > 4 && countToNum < 21 || countToNum === 0) {
            return setminutesWord("plural")
        } else if (countToNum > 21) {
            if (Number(getLastMinats) > 1 && Number(getLastMinats) < 5) {
                return setminutesWord("Nomenativ")
            } else if (Number(getLastMinats) === 1) {
                return setminutesWord("Akkusativ")
            } else if (Number(getLastMinats) === 0 || Number(getLastMinats) > 4 && Number(getLastMinats) <= 9) {
                return setminutesWord("plural")
            }
        } else if (countToNum > 1 && countToNum < 5) {
            return setminutesWord("Nomenativ")
        } else {
            return setminutesWord("Akkusativ")
        }

    }

    const getRightHoursWord = (count: string) => {
        const wordLengthHours = count.toString().length
        const getLastHours = count.toString()[wordLengthHours - 1]

        const countToNum = Number(count)
        if (countToNum > 4 && countToNum < 21 || countToNum === 0) {
            return setHoursWord("plural")
        } else if (countToNum > 21) {
            if (Number(getLastHours) > 1 && Number(getLastHours) < 5) {
                return setHoursWord("Nomenativ")
            } else if (Number(getLastHours) === 1) {
                return setHoursWord("Akkusativ")
            } else if (Number(getLastHours) === 0 || Number(getLastHours) > 4 && Number(getLastHours) <= 9) {
                return setHoursWord("plural")
            }
        } else if (countToNum > 1 && countToNum < 5) {
            return setHoursWord("Nomenativ")
        } else {
            return setHoursWord("Akkusativ")
        }

    }

    const getRightDaysWord = (count: string) => {
        const wordLengthDays = count.toString().length
        const getLastDays = count.toString()[wordLengthDays - 1]
        const countToNum = Number(count)
        if (countToNum > 4 && countToNum < 21 || countToNum === 0) {
            return setDaysWord("plural")
        } else if (countToNum > 21) {
            if (Number(getLastDays) > 1 && Number(getLastDays) < 5) {
                return setDaysWord("Nomenativ")
            } else if (Number(getLastDays) === 1) {
                return setDaysWord("Akkusativ")
            } else if (Number(getLastDays) === 0 || Number(getLastDays) > 4 && Number(getLastDays) <= 9) {
                return setDaysWord("plural")
            }
        } else if (countToNum > 1 && countToNum < 5) {
            return setDaysWord("Nomenativ")
        } else {
            return setDaysWord("Akkusativ")
        }
    }



    const getTimeRemaining = (endtime: string) => {
        shouldByDistribute && shouldByDistribute()
        let today = new Date().toString();
        let t = Date.parse(endtime) - Date.parse(today);
        if (t < 0) {
            // setTotalState("");
            // setDaysState("");
            // setHourState("");
            // setMinutesState("");

            // getRightDaysWord("");
            // getRightHoursWord("");
            // getRightMinutesWord("");
            setIsLoading(false);
            return () => { interval.current && clearInterval(interval.current); }
        }

        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        let days = Math.floor(t / (1000 * 60 * 60 * 24));
        let seconds = Math.floor((t / 1000) % 60);

        setTotalState(t.toString())
        setDaysState(days.toString())
        setHourState(hours.toString())
        setMinutesState(minutes.toString())
        setSecondsState(seconds.toString())

        getRightDaysWord(days.toString());
        getRightHoursWord(hours.toString());
        getRightMinutesWord(minutes.toString());
        setIsLoading(false)

    }

    const getUTCDateString = (dateString: string): string => {
        if (dateString === null) return '';
        if (dateString[dateString.length - 1] === 'z' || dateString[dateString.length - 1] === 'Z') return dateString;
        return dateString += 'z';
    }
    let deadline = new Date(getUTCDateString(distibDate)).toString();

    useEffect(() => {

        interval.current = setInterval(() => getTimeRemaining(deadline), 1000);
        return () => {
            interval.current && clearInterval(interval.current);
        };

    }, [ shouldByDistribute ])





    return (
        <Tag {...props} className={cnDistributeTimer()}>
            {isLoading ?
                <CircularProgress size={15}/> : <>


                    {daysState >= 1 && <div className={cnDistributeTimer("TimeCounter")}>
                        <span className={cnDistributeTimer("TimeCounter-Days")}>{daysState}</span>
                        <span className={cnDistributeTimer("TimeCounter-Text")}>
                            {daysWord === "plural" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.days' />}
                            {daysWord === "Nomenativ" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.days.nom' />}
                            {daysWord === "Akkusativ" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.days.akk' />}
                        </span>
                    </div>}
                    {daysState < 1 && hourState > 0 && <div className={cnDistributeTimer("TimeCounter")}>
                        <span className={cnDistributeTimer("TimeCounter-Hours")}>{hourState}</span>
                        <span className={cnDistributeTimer("TimeCounter-Text")}>
                            {hoursWord === "plural" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.hours' />}
                            {hoursWord === "Nomenativ" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.hours.nom' />}
                            {hoursWord === "Akkusativ" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.hours.akk' />}
                        </span>
                    </div>}
                    {daysState < 1 && hourState < 2 && minutesState > 0 && <div className={cnDistributeTimer("TimeCounter")}>
                        <span className={cnDistributeTimer("TimeCounter-Minutes")}>{minutesState}</span>
                        <span className={cnDistributeTimer("TimeCounter-Text")}>
                            {minutesWord === "plural" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.minutes' />}
                            {minutesWord === "Nomenativ" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.minutes.nom' />}
                            {minutesWord === "Akkusativ" && <Translate i18nKey='pryaniky.secret.santa.widget.timer.minutes.akk' />}
                        </span>
                    </div>}
                    {(Number(minutesState) === 0 && Number(hourState) === 0 && Number(daysState) === 0) && Number(secondsState) > 0 && <div
                        className={cnDistributeTimer("TimeCounter")}>

                        <span className={cnDistributeTimer("TimeCounter-Minutes")}>
                            {secondsState}
                        </span>

                    </div>}
                </>
            }
        </Tag>
    )
}

export const DistributeTimer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DistributeTimerPresenter)