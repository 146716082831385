import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from '../../redux/connector';
import { User } from './User/InfoBlock-User';
import { UserEdit } from './User/_edit/Edit'
import { ProfileEdit } from './UserNew/_edit/ProfileEdit'
// import { Group } from './Group/InfoBlock-Group';

import { Group } from './Group/_InfoBlock-Group';
import { Group as GroupEdit } from './Group/_edit/Edit';
import { Tag } from './Tag/InfoBlock-Tag';
import { TagBlock } from 'blocks/TagBlock/TagBlock';
import { Rating } from './Rating/infoBlock-Rating';
import { DefaultInfoBlock } from './Default/InfoBlock-Default';
import {
  mapInfoBlockStateToProps,
  IInfoBlockStateProps,
  cnInfoBlock,
  IInfoBlockProps,
  IInfoBlockState,
} from './InfoBlock.index';
import './InfoBlock.scss';
// import { Button } from 'uielements/src/Button/Button';
import { Badge } from './Badge/InfoBlock-Badge';
import { Badges } from './Badges/InfoBlock-Badges';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { AvatarBlock } from './UserNew/Avatar';
import urls from 'routes/urls';

export const InfoBlockPresenter: React.FC<IInfoBlockProps> = (props) => {
  /* constructor(props: IInfoBlockProps) {
    super(props);
    // const requestParams = queryString.parse(props.location.search)
    this.state = {
      edit: false,
    };
  } */
  const { tag: TagName = 'div', children, className = '', context, widget = {}, settings, authUser } = props;

  const [edit, setEdit] = React.useState(false);

  const allowEdit = (id: string) => {
    if (props.authUser.baseData.isAdmin) return true;
    return (id === props.authUser.baseData.id);
  }

  const toggleEdit = () => {
    setEdit(true);
    // this.props.dispatch({
    //   type: 'UPDATE_CONTEXT',
    //   payload: { id: this.props['data-id'], context: { edit: true } },
    // });
  };

  const editEnd = (callback?: () => void) => {
    setEdit(false);
    if (callback) callback();

    // this.props.dispatch({
    //   type: 'UPDATE_CONTEXT',
    //   payload: { id: this.props['data-id'], context: { edit: false } },
    // });
  };

  const customClassNames = '';
  const requestParams = queryString.parse(props.location.search)
  if (!widget.data) widget.data = {};
  let content = <DefaultInfoBlock imgSrc={widget.data.imgSrc} imgSize={widget.data.imgSize} title={widget.data.title} text={widget.data.text} />;
  if (context.uId || settings?.type === 'users') {
    const uId = context.uId || authUser?.baseData?.id;
    content = props.location.pathname === urls.welcome ? <ProfileEdit welcomeUserId={uId} /> : <AvatarBlock context={context} />;
  } else if (context.tId) {
    content = (
      <Tag
        tId={context.tId}
        id={props['data-id']}
        type={'state'}
        togleEdit={toggleEdit}
        editEnd={editEnd}
        edit={edit.toString()}
      />
    );
  } else if (context.gId) {
    content = (
      requestParams['groupEdit'] === 'true' ?
        <GroupEdit groupId={context.gId} /> :
        <Group
          groupId={context.gId}
        // id={this.props['data-id']}
        // type={'state'}
        // togleEdit={this.toggleEdit}
        // editEnd={this.editEnd}
        // edit={this.state.edit.toString()}
        />
    );
  } else if (context.bId) {
    content = (
      <Badge
        badgeId={context.bId}
        id={props['data-id']}
        type={'state'}
        togleEdit={toggleEdit}
        editEnd={editEnd}
        edit={edit.toString()}
      />
    );
  } else if (context.rId) {
    content = (
      <Rating
        rId={context.rId}
        id={props['data-id']}
        type={'state'}
        togleEdit={toggleEdit}
        editEnd={editEnd}
        edit={edit.toString()}
      />
    );
  } else if (settings?.type === 'badges') {
    content = (
      <Badges
        type={'state'}
      />
    );
  }

  // const tagProps = {...this.props}
  return (
    <TagName {...props} className={cnInfoBlock({}, [customClassNames, className])}>
      {content}
      {children}
    </TagName>
  );
}



export const InfoBlock = withRouter(
  connect<IInfoBlockStateProps, IDispatchProps, IInfoBlockProps>(
    mapInfoBlockStateToProps,
    mapDispatchToProps({})
  )(InfoBlockPresenter)
);
// compose(
//   withBemMod(cnInfoBlock(), {})
// )(InfoBlockPresenter))
