import { Translate } from "localization";
import { Button, Checkbox, FormControlLabel } from "muicomponents/src";
import { CleaningServices } from "muicomponents/src/Icons";
import { Tooltip } from "muicomponents/src/Tooltip";
import React, { FC, useMemo } from "react";
import { useUsersEventsContext } from "../UsersEvents.context";
import { cnUsersEvents } from "../UsersEvents.index";
import { UsersEventesHeadCheckboxesProps } from "./UsersEventesHeadCheckboxes.index";
import { BlocksBox, ResetBox, ResetButton } from "./UsersEventesHeadCheckboxes.styled";

export const UsersEventesHeadCheckboxes: FC<UsersEventesHeadCheckboxesProps> = ({
    onChange,
    onDrop
}) => {

    const blockContext = useUsersEventsContext();

    const lastText = useMemo(() => {
        return <Translate i18nKey={'pryaniky.usersevents.settings.block.title.empty'} />;
    }, []);

    return (
        <BlocksBox>
            {
                blockContext.settings.headBlocks.map((block) => {
                    const isActive = blockContext.activeBlocks.includes(block.id);
                    const blocked = blockContext.activeBlocks.length === 1 && isActive;
                    return (
                        <Tooltip title={blocked ? <Translate i18nKey={'pryaniky.usersevents.lastActiveBlock.text'} /> : ''}>
                            <span>
                                <FormControlLabel
                                    className={cnUsersEvents(`Checkbox-${block.id}`)}
                                    control={
                                        <Checkbox
                                            checked={isActive}
                                            onChange={(e, value) => {
                                                onChange(isActive ? blockContext.activeBlocks.filter((el) => el !== block.id) : [...blockContext.activeBlocks, block.id]);
                                            }}
                                            disabled={blocked}
                                        />
                                    }
                                    label={block.title || <Translate i18nKey={'pryaniky.usersevents.settings.block.title.empty'} />}
                                />
                            </span>
                        </Tooltip>
                    );
                })
            }
            <ResetBox>
                <ResetButton
                    variant="outlined"
                    // size={'small'}
                    startIcon={<CleaningServices />}
                    onClick={onDrop}
                >
                    <Translate i18nKey="reset" />
                </ResetButton>
            </ResetBox>
        </BlocksBox>
    );
};