import React, { useState } from 'react';
import { Dialog, Input, Button, CheckboxInput } from 'uielements/src';
import { cn } from "@bem-react/classname";
import { i18n, Translate } from 'localization';
import { API } from 'utils/src/utils';
import { toast } from 'react-toastify';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { Textarea } from "uielements/src/Textarea/Textarea";
import { cnAdditionalFieldsRender } from "blocks/NewsTypes/Ideas/AdditionalFieldsRender/AdditionalFieldsRender.index";
import { CollapseBox } from "uielements/src/CollapseBox/CollapseBox";
import { Icon } from "uielements/src/Icon/Icon";
import "./UserInviteDialog.scss";
import { ImageLoader } from 'blocks/ImageLoader/ImageLoader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Suggest from 'uielements/src/MaterialElements/Autocomplete'

// import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
// import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
// import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'

import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody/DialogBody'

import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { IBodyProps } from 'utils/src/DialogCreator';
interface IUserInviteProps extends IBodyProps {
  isShown?: boolean;
  onClose?: () => void;
  className?: string;

}
export const cnUserInviteDialog = cn("UserInviteDialog");

export const UserInviteDialog: React.FC<IUserInviteProps> = ({ isShown, onClose, handleAccept, handleClose, }) => {
  const [email, setEmail] = React.useState({ value: '', valid: true, error: '' });

  const [data, setData] = React.useState<{ [s: string]: any }>({ IsMan: "null" })

  const changeSex = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!/@.+\./.test(value)) setEmail({ value, valid: false, error: i18n.t('pryaniky.modal.inviteUser.error') });
    else setEmail({ value, valid: true, error: '' });
  };

  const imgLoaded = (img: { [s: string]: any }) => {
    setData({ ...data, imgId: img.id });
  }
  const changeDivision = (division: { [s: string]: any }[]) => {
    setData({ ...data, divisionTags: division });

  }
  const changePosition = (position: { [s: string]: any }[]) => {
    setData({ ...data, positionTags: position });

  }
  const inputValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setData({ ...data, [name]: value });
  }

  const sendInvite = () => {
    handleClose()
    API.users.invite({ UserName: email.value, ...data, IsMan: data.IsMan === "null" ? null : data.IsMan === "m" ? true : false }).r.then((response: IBasicResponse) => {
      if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
      else if (response.error_code === 0) {
        toast.success(i18n.t('pryaniky.toast.success.inviteUser'));
        setEmail({ value: '', valid: true, error: '' });
      }
      else if (response.error_text && response.error_code !== 0) toast.error(response.error_text);
    });
  };

  const handleCancel = () => {
    setEmail({ value: '', valid: true, error: '' });
    handleClose()

  };

  let disableSend = !email.valid || !email.value
  disableSend = email.value === '' ? (!data.login || data.login === '') : disableSend
  return <DialogBody
    acceptText={i18n.t("pryaniky.modal.inviteUser.send")}
    closeText={i18n.t("pryaniky.modal.cancel")}
    disableAccept={disableSend}
    onAccept={sendInvite}
    onClose={handleCancel}
    header={<>
      {i18n.t("pryaniky.modal.inviteUser.title")}
      <p className={cnUserInviteDialog("HelpTitle")}>{i18n.t("pryaniky.modal.inviteUser.text")}</p>
    </>}
  >
    <Input
      icon="at-sign"
      type="email"
      name="email"
      value={email.value}
      invalid={!email.valid}
      errorMessage={email.error}
      onChange={handleChange}
    />
    <CollapseBox
      className={cnUserInviteDialog("CollapseBox")}
      headerRender={(open: boolean) => (
        <div className={cnUserInviteDialog("CollapseBox-Toggle")}>
          <h4 className={cnUserInviteDialog("CollapseBox-Toggle-Text")}> Дополнительные параметры</h4>
          <Icon
            className={cnUserInviteDialog("CollapseBox-Toggle-Icon")}
            icon={open ? "chevron-down" : "chevron-left"}
          />
        </div>
      )}
    >
      <span className={cnUserInviteDialog("notImportant")}> Поля необязательные для заполнения </span>
      <Input
        name="login"
        type="login"
        value={data.login}
        className={cnUserInviteDialog("Input")}
        placeholder={"Логин (если используется вместо почты) "}
        autoFocus
        onChange={inputValues}
      />
      <Input
        name="firstname"
        type="firstname"
        value={data.firstname}
        className={cnUserInviteDialog("Input")}
        placeholder={"Имя "}
        autoFocus
        onChange={inputValues} />
      <Input
        name="middlename"
        type="middlename"
        value={data.middlename}
        className={cnUserInviteDialog("Input")}
        placeholder={"Отчество"}
        autoFocus
        onChange={inputValues} />
      <Input
        name="lastname"
        type="lastname"
        value={data.lastname}
        className={cnUserInviteDialog("Input")}
        placeholder={"Фамилия"}
        autoFocus
        onChange={inputValues} />
      <Textarea
        name="comment"
        type="comment"
        value={data.comment}
        className={cnUserInviteDialog("Input")}
        placeholder={"Комментарий"}
        onChange={inputValues} />

      <FormControl className={cnUserInviteDialog("Radio")}>
        <InputLabel htmlFor="sex">{i18n.t('sex')}</InputLabel>
        <Select
          className={cnUserInviteDialog("Sex")}
          value={data.IsMan}
          onChange={changeSex}
          inputProps={{
            name: 'IsMan',
            id: 'IsMan',
          }}>
          <MenuItem key={0} value={'f'}>
            {i18n.t('female')}
          </MenuItem>
          <MenuItem key={1} value={'m'}>
            {i18n.t('male')}
          </MenuItem>
          <MenuItem key={1} value={'null'}>
            {i18n.t('notChoosed')}
          </MenuItem>
        </Select>
      </FormControl>

      <ImageLoader
        // cropper ZENKIN TODO DIALOGS Z_INDEX
        onLoaded={imgLoaded}
        title={i18n.t("pryaniky.modal.inviteUser.avatar")}
        description={i18n.t("pryaniky.modal.inviteUser.avatar.text")}
        dialog
      />

      <div className={cnUserInviteDialog("Suggest")} >
        <Suggest
          type='division'
          multiple={true}

          label={i18n.t('pryaniky.profile.division')}
          value={data.divisionTags || []}
          allowCreate={true}
          onChange={(division: any) => {
            changeDivision(division)
          }}
        />
      </div>

      <div className={cnUserInviteDialog("Suggest")} >

        <Suggest
          type='position'
          multiple={true}
          className={cnUserInviteDialog("Input")}
          label={i18n.t('pryaniky.profile.position')}
          value={data.positionTags || []}
          allowCreate={true}
          onChange={(position: any) => {
            changePosition(position)
          }}
        />
      </div>

    </CollapseBox>
  </DialogBody>
  // return (
  //   <>
  //     <DialogTitle onClose={handleClose}>
  //       {i18n.t("pryaniky.modal.inviteUser.title")}
  //       <p className={cnUserInviteDialog("HelpTitle")}>{i18n.t("pryaniky.modal.inviteUser.text")}</p>
  //     </DialogTitle>
  //     <DialogContent className={cnUserInviteDialog("")}>
  //       <Input
  //         icon="at-sign"
  //         type="email"
  //         name="email"
  //         value={email.value}
  //         invalid={!email.valid}
  //         errorMessage={email.error}
  //         onChange={handleChange}
  //       />
  //       <CollapseBox
  //         className={cnUserInviteDialog("CollapseBox")}
  //         headerRender={(open: boolean) => (
  //           <div className={cnUserInviteDialog("CollapseBox-Toggle")}>
  //             <h4 className={cnUserInviteDialog("CollapseBox-Toggle-Text")}> Дополнительные параметры</h4>
  //             <Icon
  //               className={cnUserInviteDialog("CollapseBox-Toggle-Icon")}
  //               icon={open ? "chevron-down" : "chevron-left"}
  //             />
  //           </div>
  //         )}
  //       >
  //         <span className={cnUserInviteDialog("notImportant")}> Поля необязательные для заполнения </span>
  //         <Input
  //           name="login"
  //           type="login"
  //           value={data.login}
  //           className={cnUserInviteDialog("Input")}
  //           placeholder={"Логин (если используется вместо почты) "}
  //           autoFocus
  //           onChange={inputValues}
  //         />
  //         <Input
  //           name="firstname"
  //           type="firstname"
  //           value={data.firstname}
  //           className={cnUserInviteDialog("Input")}
  //           placeholder={"Имя "}
  //           autoFocus
  //           onChange={inputValues} />
  //         <Input
  //           name="middlename"
  //           type="middlename"
  //           value={data.middlename}
  //           className={cnUserInviteDialog("Input")}
  //           placeholder={"Отчество"}
  //           autoFocus
  //           onChange={inputValues} />
  //         <Input
  //           name="lastname"
  //           type="lastname"
  //           value={data.lastname}
  //           className={cnUserInviteDialog("Input")}
  //           placeholder={"Фамилия"}
  //           autoFocus
  //           onChange={inputValues} />
  //         <Textarea
  //           name="comment"
  //           type="comment"
  //           value={data.comment}
  //           className={cnUserInviteDialog("Input")}
  //           placeholder={"Комментарий"}
  //           onChange={inputValues} />

  //         <FormControl className={cnUserInviteDialog("Radio")}>
  //           <InputLabel htmlFor="sex">{i18n.t('sex')}</InputLabel>
  //           <Select
  //             className={cnUserInviteDialog("Sex")}
  //             value={data.IsMan}
  //             onChange={changeSex}
  //             inputProps={{
  //               name: 'IsMan',
  //               id: 'IsMan',
  //             }}>
  //             <MenuItem key={0} value={'f'}>
  //               {i18n.t('female')}
  //             </MenuItem>
  //             <MenuItem key={1} value={'m'}>
  //               {i18n.t('male')}
  //             </MenuItem>
  //             <MenuItem key={1} value={'null'}>
  //               {i18n.t('notChoosed')}
  //             </MenuItem>
  //           </Select>
  //         </FormControl>

  //         <ImageLoader
  //           // cropper ZENKIN TODO DIALOGS Z_INDEX
  //           onLoaded={imgLoaded}
  //           title={i18n.t("pryaniky.modal.inviteUser.avatar")}
  //           description={i18n.t("pryaniky.modal.inviteUser.avatar.text")}
  //           dialog
  //         />

  //         <div className={cnUserInviteDialog("Suggest")} >
  //           <Suggest
  //             type='division'
  //             multiple={true}

  //             label={i18n.t('pryaniky.profile.division')}
  //             value={data.divisionTags || []}
  //             allowCreate={true}
  //             onChange={(division: any) => {
  //               changeDivision(division)
  //             }}
  //           />
  //         </div>

  //         <div className={cnUserInviteDialog("Suggest")} >

  //           <Suggest
  //             type='position'
  //             multiple={true}
  //             className={cnUserInviteDialog("Input")}
  //             label={i18n.t('pryaniky.profile.position')}
  //             value={data.positionTags || []}
  //             allowCreate={true}
  //             onChange={(position: any) => {
  //               changePosition(position)
  //             }}
  //           />
  //         </div>

  //       </CollapseBox>
  //     </DialogContent>

  //     <DialogActions
  //       acceptText={i18n.t("pryaniky.modal.inviteUser.send")}
  //       closeText={i18n.t("pryaniky.modal.cancel")}
  //       disableAccept={disableSend}
  //       onAccept={sendInvite}
  //       onClose={handleCancel} />
  //   </>
  // );
};


export const dialogUserInvite = createModal(UserInviteDialog, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});