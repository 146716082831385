import { ServiceActionsReply } from 'blocks/NewsView/ServiceActions/Actions';
import { i18n } from 'localization';
import React, { FC, memo, useCallback } from 'react';
import { connect } from 'react-redux';
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu';
import { withReplyContext } from '../../contexts/reply';
import { cnClassName, mapDispatchToProps, mapStateToProps, News } from './interfaces';
import './style.scss';

type IPresenterProps = News.ReplyService.IPresenterProps;
type IOwnProps = News.ReplyService.IOwnProps;
type IDispatchProps = News.ReplyService.IDispatchProps;
type IStateProps = News.ReplyService.IStateProps;
type IState = News.ReplyService.IState;

export const Presenter: FC<IPresenterProps> = ({
    className,
    replyId,
    newsId,
    reply,
    deleteReply,
    setEdit,
    setEditableAfterLoadFullReply,
    replyEdit,
}) => {
    const { actions, type } = reply;
    const disabledActions: string[] = ['newslink'];
    const replyAction = useCallback(
        (action: string) => {
            const SAW = new ServiceActionsReply({
                enableEdit: function () {
                    setEditableAfterLoadFullReply?.();
                },
                id: replyId,
                actions: actions,
                newsID: newsId,
                removeReply: (id) => deleteReply({ newsId, replyId: id }),
                type: type,
            });
            SAW.callAction(action);
        },
        [actions]
    );
    const actionsWithContent = actions
        .filter((action) => !disabledActions.includes(action))
        .map((action) => ({
            content: i18n.t('pryaniky.post.actions.' + action),
            action,
        }));
    return <ContextMenuComponent className={cnClassName({}, [])} actions={actionsWithContent} onClick={replyAction} />;
};

export default withReplyContext(
    connect<IStateProps, IDispatchProps, IOwnProps, IState>(mapStateToProps, mapDispatchToProps)(memo(Presenter))
);
