/**
 * @packageDocumentation
 * @module ListHeader
 */
import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";

import { mapStateToProps, IHeaderStateProps, cnHeader, IHeaderProps, IHeaderState } from './List-Header.index';
import './List-Header.scss';
// import { withHeaderTypeEmpty } from './_type/List-Header_type_empty';
import { withHeaderTypeCommon } from './_type/List-Header_type_common';
// import { withHeaderTypeCustom } from './_type/List-Header_type_custom';
// import { IHeaderTypeCommonProps } from './_type/List-Header_type_common.index';
// import { IHeaderTypeCustomProps } from './_type/List-Header_type_custom.index';


export class HeaderPresenter extends React.Component<IHeaderProps, IHeaderState> {
  // public utils: any = utils;
  public el: Element | Text | null;

  constructor(props: IHeaderProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '', search, actions, counter } = this.props;
    return (
      <TagName className={cnHeader({}, [customClassNames, className])}>
        {children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Header = compose<IHeaderProps>(
  withHeaderTypeCommon,
)(HeaderPresenter)
// connect<IHeaderStateProps, any, IHeaderProps>(
//   mapStateToProps,
//   {}
//   // mapDispatchToProps({})
// )(compose(
//   withHeaderTypeEmpty,
//   // withHeaderTypeCustom
// )(HeaderPresenter))