import { IIdeaFieldProps } from '../FieldProps';
import React from 'react';
import { MultiSelect } from '../../../../MultiSelect/MultiSelect'
import { IOption } from '../../../../MultiSelect/MultiSelect.index'
import { cn } from "@bem-react/classname";
import './Multi.scss';
import { i18n, Translate } from 'localization';

export const cnIdeaField = cn("IdeaField");
export const Multi: React.FC<IIdeaFieldProps> = ({ field, onChange = () => { } }) => {
    const [text, setText] = React.useState({  valid: true, error: i18n.t('') });

    const onCheckedCreator = (selected: string[]) => {
        field.values = selected
        if (field.field.isMandatory && field.values.length === 0) setText({  valid: false, error: i18n.t('поле не должно быть пустым') });
        else setText({  valid: true, error: '' });
        // const rowIndex = field.field.answers.findIndex(item => {
        //     return item.id === id;
        // });
        // field.field.answers[rowIndex].selected = !field.field.answers[rowIndex].selected;
        onChange(field);
    }
    const options: IOption[] = field.field.answers.map(answer => ({ value: answer.id, title: answer.text }));
    return <MultiSelect className={cnIdeaField("Multi")} invalid={!text.valid} errorMessage={text.error} required={field.field.isMandatory} placeholder={field.field.name || field.field.description}selected={field.values} options={options} onChange={onCheckedCreator} />;
}