import { i18n, Translate } from 'localization';
import { WikilistIcons } from "muicomponents/src";
import { Tooltip } from "muicomponents/src/Tooltip";
import React, { ComponentProps, useMemo } from "react";
import { PageModelList, PageTypes } from "utils/src";
import { defaultIconStyle } from "./style";
import wikilist from './DefaultImages/wikilist.svg'
import wikilistImageGallery from './DefaultImages/wikilist.imageGallery.svg'
import wikilistVideoGalley from './DefaultImages/wikilist.videoGallery.svg'
import page from './DefaultImages/page.svg'
import link from './DefaultImages/link.svg'
import { getPageBaseType } from "../utils/Wikilist.utils";
import { getProfileFileIcon } from "./Items.utils";


export const useWikilistItemFileTypeIcon = (pageType: PageTypes, title: PageModelList['title'], icon: PageModelList['icon']) => {
    return useMemo(() => {
        const pageBaseType = getPageBaseType(pageType);
        if(pageBaseType !== PageTypes.file) return null;
        const fileType = title?.split(".");
        return getProfileFileIcon(fileType[fileType.length - 1] || icon);
    }, [pageType, title, icon]);
}

export const useWikilistItemBaseImageByType = (pageType: PageTypes) => {
    return useMemo(() => {
        switch (pageType) {
            case PageTypes.wikilist:
                return wikilist;
            case PageTypes.imageGallery:
                return wikilistImageGallery;
            case PageTypes.videoGallery:
                return wikilistVideoGalley
            case PageTypes.url:
                return link;
            default:
                return page;
        };
    }, [pageType]);
}

export const useWikilistItemIconByType = (pageType: PageTypes) => {
    
    return useMemo(() => {
        let IconComponent = WikilistIcons[pageType] || WikilistIcons.page;
        let IconProps: ComponentProps<typeof IconComponent> = {
            sx: { ...defaultIconStyle, marginLeft: "-3px" }
        };
        switch(pageType) {
            case PageTypes.page:
                break;
            case PageTypes.file:
                IconProps = {
                    sx: { ...defaultIconStyle, marginLeft: "-4px" }
                };
                break;
            case PageTypes.wikilist:
            case PageTypes.imageGallery:
            case PageTypes.videoGallery:
            case PageTypes.url:
                IconProps = {
                    sx: { ...defaultIconStyle, marginLeft: "-1px" }
                };
                break;
        }
        return (
            <Tooltip title={<Translate i18nKey={`pryaniky.wikilist.item.${pageType}`} defaults={Translate.t({ i18nKey: 'pryaniky.wikilist.item.page' })} />}>
                <IconComponent {...IconProps} />
            </Tooltip>
        )
    }, [pageType]);
}