import React from 'react';
import { i18n, Translate } from 'localization';
import * as UIElements from 'uielements/src';
import { Actions } from '@jsonforms/core';
import { ImageLoader } from '../../ImageLoader/ImageLoader';
import { get_variable_name } from './utils';
import { emptyItem, IVirtualUserItem } from "../../WSettings/structures/virtualuser/uischema";
import { v1 as uuid } from 'uuid';

// VirtualUserControl

export const virtualUser = (props: any) => {
  if (!props.visible) return null;
  const data = props.data[get_variable_name(props.uischema.scope)];
  return (
    <div className={'VirtualUser'}>
      <ImageLoader
        squarePreview
        cropper
        cropperProps={{ aspect: false }}
        startPreview={data.imgUrl}
        onLoaded={(file: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          oldData.imgId = file.id;
          oldData.imgUrl = file.originalUrl;
          return { ...oldData };
        }))}
        children={
          <>
            <UIElements.Input onChange={(ev: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
              oldData.startText = ev.currentTarget.value
              return { ...oldData };
            }))} value={data.startText} placeholder={i18n.t('pryaniky.virtualuser.startText')} />

            <UIElements.CheckboxInput
              className={'VirtualUser-Checkbox'}
              onChange={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                oldData.isShuffle = !data.isShuffle
                return { ...oldData };
              }))}
              text={i18n.t('pryaniky.virtualuser.isShuffle')}
              checked={data.isShuffle}
              style={{ margin: 0 }}
            />
          </>
        } />
      {
        data.elements.map((el: IVirtualUserItem, idx: number) => {
          if(el.deleted) return null;
          return <div className={'VirtualUserItem'}>
    
            <UIElements.Input onChange={(ev: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
              oldData.elements[idx].text = ev.currentTarget.value;
              return { ...oldData };
            }))} value={el.text} placeholder={i18n.t('pryaniky.virtualuser.text')} />

            <UIElements.Button
              className={'VirtualUserItem-Button_remove'}
              onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                oldData.elements[idx].deleted = true;
                return { ...oldData }
              }))}
              children={
                <UIElements.Icon icon={'times'} />
              } />
          </div>
          })
      }
      <UIElements.Button 
        className={'VirtualUserItem-Button_add'}
        onClick={() => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
          oldData.elements.push({ ...emptyItem, id: uuid()});
          return { ...oldData }
        }))}
        children={
          i18n.t('pryaniky.virtualuser.add')
        }/>
    </div>
  )
}
// end VirtualUserControl