// tslint:disable:max-classes-per-file variable-name
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { toast } from 'react-toastify';
import { i18n, Translate } from 'localization';
import { store } from '../../../redux/store'
// import { actionMoveToGroup } from 'redux/actions/News'
import { BaseNews } from 'blocks/NewsTypes/BaseType'
import {
  addToAnnouncement,
  moveToGroup
} from 'News/redux/actions'
// const disabledActions = ['translate', 'invest'];

interface IServiceActionsWorkerProps {
  actions: string[];
  allowReply: boolean;
  isNeedApprove: boolean;
  post: BaseNews;//{ [s: string]: any };
  id: string;
  pathname: string;
  newData(data: { [s: string]: any }): void;
  removeNews(id: string): void;
  hideNews(): void;
  enableEdit(): void;
}

export class ServiceActionsNews {
  protected props: IServiceActionsWorkerProps;

  constructor(props: IServiceActionsWorkerProps) {
    this.props = props;
  }

  public callAction = (action: string) => {
    const forAction: any = this;
    if (action.includes('addToFavorites')) {
      this.action_addToFavorites(action)
    } else forAction['action_' + action] ? forAction['action_' + action]() : console.warn('unknown news action:', action);
  };

  // tslint:disable-next-line
  public action_showFeedbackContact = (action: string) => {
    utilsDep.modal({
      type: 'info',
      header: i18n.t('pryaniky.post.actions.confirm.showFeedbackContact'),
      okBtnAction: () => { },
      okBtnText: i18n.t('ok'),
      children: (this.props.post.feedback && (`${this.props.post.feedback.senderName}: ${this.props.post.feedback.senderContact}`)) || '',
      noFooter: true
    });
  }

  // tslint:disable-next-line
  public action_addToFavorites = (action: string) => {
    const fav = () => {
      // this.props.post.isFavorite = !this.props.post.isFavorite;
      this.props.post.actions = this.props.post.actions.map((a: string) => {
        if (a.includes('addToFavorites'))
          return a === 'addToFavorites.on' ? 'addToFavorites.off' : 'addToFavorites.on'
        return a
      });
      this.props.newData(this.props.post);
      this.action(action, '').r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.' + action),
      onConfirm: fav,
    });

  };

  // tslint:disable-next-line
  public action_delete = () => {
    const deleteNews = () => {
      this.props.removeNews(this.props.id);
      /*this.action(1, true)
                .r
                .then((d: any) => {
                    if (d.error_code === 0) {
                        toast.success(d.error_text);
                    } else toast.error(d.error_text);
                });*/
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.delete'),
      onConfirm: deleteNews,
    });

  };
  // tslint:disable-next-line
  public action_unsubscribe = () => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.unsubscribe'),
      onConfirm: () => {
        this.subscribe(false);
      },
    });

  };
  // tslint:disable-next-line
  public action_subscribe = () => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.subscribe'),
      onConfirm: () => {
        this.subscribe(true);
      },
    });

  };
  // tslint:disable-next-line
  public action_disapprove = () => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.unpublish'),
      onConfirm: () => this.approve(false),
    });

  };
  // tslint:disable-next-line
  public action_approve = () => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.publish'),
      onConfirm: () => this.approve(true),
    });

  };
  // tslint:disable-next-line
  public action_disableComments = (enable: string) => {
    const disableComments = () => {
      this.props.post.allowReply = !this.props.post.allowReply;
      this.props.post.actions = this.props.post.actions.map((a: string) => {
        if (a.includes('disableComments')) {
          let wo = a.replace('disableComments', '')
          return 'disableComments' + (wo.includes('on') ? wo.replace('on', 'off') : wo.replace('off', 'on'))
        }
        return a
      }),
        this.props.newData(this.props.post);
      this.action(23, !this.props.post.allowReply).r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.disableComments.' + enable),
      onConfirm: disableComments,
    });

  };

  public 'action_disableComments.on' = () => this.action_disableComments('on')
  public 'action_disableComments.off' = () => this.action_disableComments('off')

  // tslint:disable-next-line
  public action_reportSpam = () => {
    const reportSpam = () => {
      this.action(2, '').r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.reportSpam'),
      onConfirm: reportSpam,
    });

  };
  // tslint:disable-next-line
  public action_moveToGroup = () => {
    const _moveToGroup = (data: any) => {
      this.props.post.group = data.selectedGroup;
      this.props.newData(this.props.post);
      /*
      this.action(8, data.selectedGroup.pkid).r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(i18n.t('pryaniky.post.actions.confirm.compliteMoveToGroup'));
        } else toast.error(d.error_text);
      });*/
      store.dispatch(moveToGroup({
        newsId: this.props.id,
        group: data.selectedGroup
      }))
      // actionMoveToGroup(this.props.id, data.selectedGroup)(store.dispatch)
    };
    utilsDep.modal({
      type: 'groupList',
      header: i18n.t('pryaniky.post.actions.confirm.moveToGroup'),
      okBtnAction: _moveToGroup,
      okBtnText: i18n.t('move'),
      cancelBtnText: i18n.t('pryaniky.birthdays.modal.cancel')
    });

  };
  // tslint:disable-next-line
  public action_edit = () => {
    this.props.enableEdit();

  };

  // tslint:disable-next-line
  public action_translate = () => {
    const language = this.props.post.translatedTo ? null : 'en'
    // this.action('translate');
    utils.API.news.byId(this.props.id, { language })
      .r
      .then(d => {
        if (utils.checkResponseStatus(d)) {
          this.props.newData({ id: this.props.id, text: (d.data[0] || { text: '' }).text, translatedTo: language });
          // updateNews({ id, text: (d.data[0] || { text: '' }).text });
        } else {
          toast.error('pryaniky.translate.error');
        }
      })

  };

  // tslint:disable-next-line
  public action_hide = () => {
    const hide = () => {
      // this.props.removeNews(this.props.id);
      // this.action(0, '').r.then((d: any) => {
      //   if (d.error_code === 0) {
      //     toast.success(d.error_text);
      this.props.hideNews();
      // } else toast.error(d.error_text);
      // });
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.hide'),
      onConfirm: hide,
    });

  };
  // tslint:disable-next-line
  public action_cancelThanks = () => {
    const hide = () => {
      this.props.removeNews(this.props.id);
      this.action(14, '').r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.cancelThanks'),
      onConfirm: hide,
    });

  };
  // tslint:disable-next-line
  public action_downloadParticipantList = () => {
    const downloadWindow = window.open(utils.API.news.getParticipantsListURL(this.props.id));
    if (!downloadWindow) return;
    downloadWindow.onload = () => {
      if (!downloadWindow.closed) downloadWindow.close();
    };

  };
  // tslint:disable-next-line
  public action_AddToAnnouncement = () => {
    /*const action = () => {
          this.action(2, '').r.then((d: any) => {
            if (d.error_code === 0) {
              toast.success(d.error_text);
            } else toast.error(d.error_text);
          });
        }
        utilsDep.modal({
          type: 'confirm',
          header: i18n.t('post_actions_modal.AddToAnnouncement'),
          okBtnAction: action,
        });
        this.toggleOpen();*/
  };
  // tslint:disable-next-line
  public action_addToAnnouncement = (state: string) => {
    const action = () => {
      /*this.action(3, '').r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });*/
      store.dispatch(addToAnnouncement({
        actionId: 'addToAnnouncement',
        id: this.props.id
      }))
      // actionAddToAnnouncement(this.props.id, 'addToAnnouncement')(store.dispatch)
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.addToAnnouncement.' + state),
      onConfirm: action,
    });

  };
  public 'action_addToAnnouncement.on' = () => this.action_addToAnnouncement('on')
  public 'action_addToAnnouncement.off' = () => this.action_addToAnnouncement('off')
  public 'action_addToGroupAnnouncement.on' = () => this.action_addToGroupAnnouncement('on')
  public 'action_addToGroupAnnouncement.off' = () => this.action_addToGroupAnnouncement('off')

  public action_addToGroupAnnouncement = (state: string) => {
    const action = () => {
      /*this.action(3, '').r.then((d: any) => {
        if (d.error_code === 0) {
          toast.success(d.error_text);
        } else toast.error(d.error_text);
      });*/
      store.dispatch(addToAnnouncement({
        actionId: 'addToAnnouncement',
        id: this.props.id
      }))
      // actionAddToAnnouncement(this.props.id, 'addToGroupAnnouncement')(store.dispatch)
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.post.actions.confirm.addToGroupAnnouncement.' + state),
      onConfirm: action,
    });

  }

  // tslint:disable:variable-name
  public action_copylink = () => {
    const dummy = document.createElement('input');
    const location = window.location.href;
    dummy.value = location.substr(0, location.indexOf(this.props.pathname)) + '/news/' + this.props.id;
    document.body.appendChild(dummy);
    dummy.select();
    document.execCommand('copy');
    dummy.remove();
    toast.success(i18n.t('pryaniky.toast.success.copyLink'));
  }

  protected action(action: number | string, data?: any) {
    return utils.API.news.action(this.props.id, action, data);
  }

  private toggleText = (action: string) => {
    switch (action) {
      case 'disapprove':
        return i18n.t('pryaniky.post.actions.unpublish');
      default:
        return i18n.t('pryaniky.post.actions.' + action);
    }
  };

  private subscribe(subscribe: boolean) {
    const action = subscribe ? 9 : 10;
    this.props.post.actions = this.props.post.actions.map((a: string) => {
      if (a === 'unsubscribe') a = 'subscribe';
      else if (a === 'subscribe') a = 'unsubscribe';
      return a;
    });
    this.props.newData(this.props.post);
    this.action(action, '').r.then((d: any) => {
      if (d.error_code === 0) {
        toast.success(d.error_text);
      } else toast.error(d.error_text);
    });

  }

  private approve(approve: boolean) {
    const action = approve ? 5 : 7;
    const actions = this.props.post.actions.map((a: string) => {
      if (a === 'approve') a = 'disapprove';
      else if (a === 'disapprove') a = 'approve';
      return a;
    });
    const modifedPost = { ...this.props.post, actions, isNeedApprove: !approve }
    this.props.newData(modifedPost);
    this.action(action, '').r.then((d: any) => {
      if (d.error_code === 0) {
        toast.success(d.error_text);
      } else toast.error(d.error_text);
    });

  }
  // tslint:disable-next-line
  private linkToNews = () => {
    utilsDep.modal({
      type: 'linkToNews',
      header: i18n.t('pryaniky.post.actions.confirm.link'),
      url: '/news/' + this.props.id,
      // okBtnAction: ()=>{},
    });

  };
}

interface IServiceActionsReplyProps {
  actions: string[];
  id: string;
  newsID: string;
  changeVm?: (path: string, value: any) => void;
  removeReply(id: string): void;
  enableEdit(): void;
  type: string;
}

export class ServiceActionsReply {
  protected props: IServiceActionsReplyProps;

  constructor(props: IServiceActionsReplyProps) {
    this.props = props;
  }

  public callAction = (action: string) => {
    const forAction: any = this;
    forAction['action_' + action]();
  };

  public action_delete = () => {
    const deleteNews = () => {
      this.props.removeReply(this.props.id);
    };
    utilsProj.confirm({
      text: this.props.type === 'default' ? i18n.t('pryaniky.comment.actions.confirm.delete') : i18n.t('pryaniky.comment.actions.confirm.thank.delete'),
      onConfirm: deleteNews,
    });
  };

  public action_edit = () => {
    this.props.enableEdit();
  };

  public action_reportSpam = () => {
    const reportSpam = () => {
      this.action(2, '').r.then((d: any) => {
        if (!d.error_code) {
          toast.success(i18n.t('pryaniky.toast.success.report'));
        } else toast.error(d.error_text);
      });
    };
    utilsProj.confirm({
      text: i18n.t('pryaniky.comment.actions.confirm.report'),
      onConfirm: reportSpam,
    });
  };

  protected action(action: number, data: any) {
    return utils.API.reply.action(this.props.newsID, this.props.id, action, data);
  }

  private linkToNews = () => {
    utilsDep.modal({
      type: 'linkToNews',
      header: i18n.t('pryaniky.comment.actions.confirm.link'),
      url: 'news/' + this.props.id,
      // okBtnAction: ()=>{},
    });
  };
}
