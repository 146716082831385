// import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapStateToProps, IUsersWidgetStateProps } from './../UsersWidget.index';
import { mapDispatchToProps, IDispatchProps } from "redux/connector";
import { i18n, Translate } from 'localization';
// import { withBemMod } from '@bem-react/core';

// import { cnUsersWidget } from '../UsersWidget.index';
// import { IUsersWidgetTypeSubscribersProps } from './UsersWidget_type_subscribers.index';
import './UsersWidget_type_subscribers.scss';
import { UsersWidgetPresenter } from '../UsersWidget';
import { IUsersWidgetProps } from '../UsersWidget.index';

export class UsersSubscribersWidgetPresenter extends UsersWidgetPresenter {

  public getData = (props: IUsersWidgetProps) => {

    const { uid, wcontext, } = props;
    utils.API.users.subscribers(wcontext.uId ? wcontext.uId : uid)
    .r
    .then((d: any) => {
      if (!this.mounted) return false;
      if(utils.checkResponseStatus(d)) {
        const items = d.data.map((user: {[s: string]: any}) => {
          user.title = user.displayName;
          user.url = '/user/' + user.id;
          /**
           * userPhotoUrl is old parameter 
           */
          if(!utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl || user.imgUrl;
          return user;
        });
        this.setState({
          items,
          noData: items.length === 0,
          title: i18n.t('subscribers')
        });
      }
    });
  }

}

export const UsersSubscribersWidget = connect<IUsersWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(UsersSubscribersWidgetPresenter)

// export const withUsersWidgetTypeSubscribers = withBemMod<IUsersWidgetTypeSubscribersProps>(
//   cnUsersWidget(),
//   { type: 'subscribers' }, 
//   (Presenter) => (
//     (props) => (
//       <Presenter {...props}/>
//     )
//   )
// );