import { connect } from 'react-redux';
import { compose, withBemMod } from '@bem-react/core';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
import { mapStateToProps, INotificationsSettingsStateProps, cnNotificationsSettings, INotificationsSettingsProps, INotificationsSettingsState } from './NotificationsSettings.index';
import { Redirect } from 'react-router-dom';
  import * as React from 'react';
// import { v1 as uuid } from 'uuid';
// // import * as utils from 'src/utils/utils';

// import { StickyTable } from 'uielements/src/StickyTable/StickyTable';

// import './NotificationsSettings.scss';
// import { API, queryStringFromObj, getCookie } from 'utils/src/utils';
// import { Button, Avatar, Tooltip, CheckboxInput } from 'uielements/src'
// import { toast } from 'react-toastify'
// import queryString from 'query-string';
// import NotificationAvatar from './NotificationAvatar.svg';
// import { Tabs } from '../Tabs/Tabs';
// import { any } from 'prop-types';
// import { generateColumn, generateWidget, widgets } from 'i.widgets';
// import { store } from 'redux/store';
// import { addWidget, updateContext } from 'redux/actions/Widgets';
// import NotificationsSettingsCommon from './_type/NotificationsSettings_Common';
// import NotificationsSettingsGroup from './_type/NotificationsSettings_Group';
// import NotificationsSettingsNetworkGroup from './_type/NotificationsSettings_NetworkGroup'

// interface IGroupButtonTooltipProps {
//   // action: () => void;
//   name: string;
//   active: boolean;
//   imgUrl?: string;
//   href: string
// }

// const GroupButtonTooltip: React.FC<IGroupButtonTooltipProps> = ({ active, imgUrl, name, href }) => {
//   const anchor = React.useRef<HTMLElement>(null);
//   const [showTooltip, setShowTooltip] = React.useState(false);
//   return <React.Fragment>
//     <Button
//       onMouseEnter={() => setShowTooltip(true)}
//       onMouseLeave={() => setShowTooltip(false)}
//       theme={"unstyled"}
//       // onClick={action}
//       type={'rlink'}
//       href={href}
//       className={'NotificationsSettings-GroupsItem' + (active ? ' NotificationsSettings-GroupsItem__active' : '')}>
//       <Avatar
//         ref={anchor}
//         imgUrl={imgUrl}
//         name={name}
//         size={56}
//         className='NotificationsSettings-GroupAvatar'
//       />
//     </Button>
//     {showTooltip && <Tooltip anchor={anchor}>
//       {name}
//     </Tooltip>}
//   </React.Fragment>
// }
// export class NotificationsSettingsPresenter extends React.Component<
//   INotificationsSettingsProps,
//   INotificationsSettingsState
//   > {
//   public el: Element | Text | null;

//   private NotificationsSettings: string | null = null;
//   private widgetId = uuid();
//   private widgetIdTab1 = uuid();
//   private widgetIdTab2 = uuid();
//   private widgetTab1: any = null;
//   private widgetTab2: any = null;
//   private widget: any = null;
//   constructor(props: INotificationsSettingsProps) {
//     super(props);

//     this.state = {
//       groups: [],
//       section: "common",
//       currentGroup: {},
//       settings: {
//         g: [],
//         destinations: [],
//         denyMailMe: false,
//       }
//     };
//     this.widgetTab1 = generateWidget({
//       id: this.widgetIdTab1,
//       type: widgets.types.render,
//       data: <NotificationsSettingsCommon />,
//       relations: [this.widgetId, this.widgetIdTab1]
//     });
//     this.widgetTab2 = generateWidget({
//       id: this.widgetIdTab2,
//       type: widgets.types.render,
//       data: <NotificationsSettingsNetworkGroup />,//NotificationsSettingsGroup
//       relations: [this.widgetId, this.widgetIdTab2]
//     });
//     this.widget = generateWidget({
//       id: this.widgetId,
//       type: widgets.types.tabs,
//       relations: ["common", this.widgetId],
//       data: [
//         generateColumn({
//           title: i18n.t("pryaniky.tabs.common"),
//           name: "common",
//           to: "?" + queryString.stringify({ section: "common" }),
//           items: this.widgetTab1 ? [this.widgetTab1.id] : []
//         }),
//         generateColumn({
//           title: this.props.store.authUser.baseData.networks.length > 1 ? i18n.t('pryaniky.tabs.networks&groups') : i18n.t("pryaniky.tabs.groups"),
//           name: "groups",
//           to: "?" + queryString.stringify({ section: "groups" }) || "?" + queryString.stringify({ section: "networks" }),
//           items: [this.widgetTab2.id]
//           //  items: ["1"]
//         })
//       ]
//     });

//     store.dispatch(addWidget(this.widget));
//     store.dispatch(addWidget(this.widgetTab1));
//     store.dispatch(addWidget(this.widgetTab2));
//     store.dispatch(updateContext(this.widgetId, { tab: queryString.parse(this.props.location.search).section || "common" }));
//   }

//   // public componentDidMount() {
//   //   this.loadSettings();
//   // }

//   public render = () => {
//     const customClassNames = "";
//     const { tag: TagName = "div", children, className = "" } = this.props;
//     const { settings, groups, currentGroup } = this.state;
//     const section = queryString.parse(this.props.location.search).section || "common";
//     const currentGid = queryString.parse(this.props.location.search).gid || "-1";
//     const key = section === "groups" ? "nu" : "g";
//     const tdata = !settings[key] ? [] : settings[key];

//     if (section !== this.state.section) this.selectSection(section as any)();
//     // if (currentGid !== "-1" && currentGroup.pkid !== currentGid && section === "groups") {
//     //   const ng = groups.find((val: any) => val.pkid === Number.parseInt(currentGid as any));
//     //   this.selectGroup(ng)();
//     // }

//     const Tabs2 = widgets.components.tabs;

//     return (
//       <TagName ref={el => (this.el = el)} className={cnNotificationsSettings({}, [customClassNames, className])}>
//         {children}
//         <div className={cnNotificationsSettings("layout systemBorderColorGray systemBorder")}>
//           <div className={cnNotificationsSettings("Top")}>
//             <div className={cnNotificationsSettings("Avatar")}>
//               <img src={NotificationAvatar} alt="avatar" />
//             </div>
//             <div className={cnNotificationsSettings("Top_Right")}>
//               <h1>{i18n.t("notifications settings")}</h1>
//               <p className={cnNotificationsSettings("Text")}>{i18n.t("notifications.settings.text")}</p>
              
//               <CheckboxInput
//                 text={'Не отправлять мне никаких оповещений'}
//                 checked={this.state.settings.denyMailMe}
//                 className={cnNotificationsSettings("Top_Right-Checkbox")}
//                 onClick={() => this.saveSettings()}
//               />
//             </div>
//           </div>
//         </div>
//         <Tabs2 data-id={this.widget.id} className={cnNotificationsSettings("Tabs")} />
//       </TagName>
//     );
//   }

//   private selectSection = (section = "common") => () => {
//     // this.setState({ section, currentGroup: {}, settings: { g: [], destinations: [] } }, () => {
//     //   const id = this.props.match.params.id
//     //     ? this.props.match.params.id
//     //     : this.props.store.authUser.profileData.userId;
//     //   section === "groups" ? this.loadGroups(id) : this.loadSettings();
//     // });
//     this.setState({section})
//   };

//   // private selectGroup = (ng: any) => () => {
//   //   if (!ng || ng.pkid === this.state.currentGroup.pkid) return;
//   //   this.setState(
//   //     (s: INotificationsSettingsState) => {
//   //       s.currentGroup = ng;
//   //       s.settings = { g: [], destinations: [] };
//   //       return s;
//   //     },
//   //     () => {
//   //       this.loadSettings(this.state.currentGroup.pkid);
//   //     }
//   //   );
//   // };

//   // private loadGroups(id: string) {
//   //   API.groups.byUser(id, "my").r.then(d => {
//   //     this.setState({ groups: d.data }, () => {
//   //     });
//   //   });
//   // }

//   // private loadSettings = (gid = 0) => {
//   //   const id = this.props.match.params.id
//   //     ? this.props.match.params.id
//   //     : this.props.store.authUser.profileData.userId;
//   //   API.notifications.settings.get(id, gid, this.props.store.authUser.baseData.baseNetwork.id).r.then(d => {
//   //     this.setState({ settings: d.data, }, () => {

//   //     });
//   //   });
//   // };

//   // private loadSettings = (gid = 0) => { }
//   // private transformDataToOldGroup(data: any, uid: string) {
//   //   return {
//   //     uid: uid,
//   //     groupId: data.groupId,
//   //     g1: {
//   //       groupId: data.groupId,
//   //       displayName: data.displayName,
//   //       selectedTemplateName: "custom", // data.selectedTemplateName,//"custom",
//   //       nu: data.nu
//   //     },
//   //     auth: data.auth
//   //   };
//   // }

//   private saveSettings = () => {
//     const { settings } = this.state;
//     // USERID
//     const id = getCookie('authUid');

//     this.setState((state) => {
//       return {
//         settings: {
//           ...settings,
//           denyMailMe: !state.settings.denyMailMe
//         }
//       }
//     }, )
//     API.notifications.settings.set(id, { g: settings.g, destinations: settings.destinations, denyMailMe: !settings.denyMailMe}).r.then((d: any) => {
//       if (d.error_code === 0) {
//         toast.success(i18n.t("success"));
//       } else {
//         toast.error(i18n.t("server error"));
//       }
//     });
//   };
// }


// export const NotificationsSettings = connect<INotificationsSettingsStateProps, IDispatchProps>(
//   mapStateToProps,
//   mapDispatchToProps({})
// )(compose(
//   // withBemMod(cnNotificationsSettings(), {})
// )(NotificationsSettingsPresenter))



//REDIRECT

const NotificationsSettingsPresenter = (props: any) => {
  return <Redirect to={`/notifiSettings/${props.authUser.baseData.id}/common`} />
}

export const NotificationsSettings = connect<INotificationsSettingsStateProps, IDispatchProps>(
  mapStateToProps as any,
  mapDispatchToProps({})
)(compose(
  // withBemMod(cnNotificationsSettings(), {})
)(NotificationsSettingsPresenter))