import { Box, styled } from "muicomponents/src";
import { CellComponent } from "./Cells/Cell";
import { HeaderComponent } from "./Headers/Header";

export const WrapperBox = styled(Box)({
    boxSizing: 'border-box',
    // border: '1px solid #0000001F',
}) as typeof Box;

export const BorderedBox = styled(Box)({
    borderBottom: '1px solid #0000001F',
    borderRight: '1px solid #0000001F',
    boxSizing: 'border-box',
    minHeight: '100%'
}) as typeof Box;

export const TopBorderedBox = styled(Box)({
    borderTop: '1px solid #0000001F',
    boxSizing: 'border-box',
    minHeight: '100%'
}) as typeof Box;

export const BottomBorderedBox = styled(Box)({
    borderBottom: '1px solid #0000001F',
    boxSizing: 'border-box',
    minHeight: '100%'
}) as typeof Box;