import React, { FC, useState } from 'react';
import { wrapDialogToRedux } from '../DialogWrapper';
import { IHistoryCommentProps, IHistoryCommentState } from './HistoryComment.index';
import { TextField } from 'muicomponents/src/TextField/TextField';
import { store } from '../../../redux/store';
import { modalChangeDate } from '../../../redux/actions/Modals';
import { i18n, Translate } from 'localization';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle';
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent';
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { connect } from "react-redux";

const HistoryCommentPresenter: FC<IHistoryCommentProps> = ({
    handleAccept,
    handleClose,
    data
}) => {
    const [comData, setComData] = useState({ comment: data.comment || "" })


    // const stringValueChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => setState({ [e.currentTarget.name]: e.currentTarget.value });

    const onChangeTarget = (e: any) => setComData({ comment: e.currentTarget.value });


    return (
        <React.Fragment>
            <DialogTitle onClose={handleClose}>
                <Translate i18nKey='pryaniky.modal.wikipage.history.dialog.title' />
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ marginTop: "5px", width:"100%" }}
                    label={<Translate i18nKey={'pryaniky.modal.wikipage.history.dialog.comment'} />}
                    value={comData.comment}
                    helperText={<Translate i18nKey={'pryaniky.modal.wikipage.history.dialog.comment.help'} />}
                    onChange={(e) => setComData({ comment: e.currentTarget.value })}
                />
            </DialogContent>
            <DialogActions
                acceptText={i18n.t('pryaniky.modal.wikipage.history.dialog.save')}
                // closeText={i18n.t('pryaniky.cancel.dialog')}
                onAccept={() => handleAccept(comData)}
                // onClose={handleClose} 
                />
        </React.Fragment>
    );
}

export const DialogHistoryComment = createModal(HistoryCommentPresenter, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        }
    },
    TransitionComponent,
    scroll: 'body'
});
