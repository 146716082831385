import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
// import * as utils from 'src/utils/utils';
// import { NewBody } from './Modal-Body_type_selectUsers'
// import { IBodyProps } from '../Modal-Body.index';
// import { List } from 'src/blocks/List/List';
import { withBemMod } from '@bem-react/core';
// import { Item as UserItem } from 'src/blocks/Users/Item/Users-Item';
import { cnBody } from '../Modal-Body.index';
import { IBodyTypePollAnswerParticipantsProps } from './Modal-Body_type_pollAnswerParticipantsProps.index';
// import { List } from 'blocks/List/_type/users/List_type_pollAnswerParticipants';
import { List as ListParticipants } from 'blocks/List/_type/users/List_type_users_answerParticipants';
import './Modal-Body_type_pollAnswerParticipantsProps.scss';
// import { IListData } from 'src/blocks/List/List.index';
const anyObj: any = {}
export const withBodyTypePollAnswerParticipants = withBemMod<any>(
  cnBody(),
  { type: 'answerParticipants' },
  (Presenter) =>
    class extends React.Component<IBodyTypePollAnswerParticipantsProps, any> {
      public el: HTMLElement | null;

      public render() {
        const customClassNames = '';
        const TagName = this.props.tag ? this.props.tag : 'div';
        return (
          <TagName ref={el => this.el = el} className={cnBody({ admin: this.props.parent.state.data.isAdmin }, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
            <Router>
              <ListParticipants context={{
                nId: this.props.parent.state.data.nId,
                aId: this.props.parent.state.data.aId
              }}
                {...anyObj} />
            </Router>
          </TagName>
        )
      }
    }

);