import React, { ComponentProps, FC, useMemo } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps, IListItemProps, cnEventsItem, IListItemStateProps, IListItemOwnProps } from './ListItem.index'
import { EventItem } from '../EventItem/EventItem'
import Box from '@mui/material/Box'
import { CalendarIcon } from '../CalendarIcon/CalendarIcon'
import { i18n, Translate } from 'localization';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useOpenEventCreator, isShowCreateButton } from '../utils'
import "./ListItem.scss"
import { useCalenderNewsCreatorOptions, useCanCalendarCreateEvent } from '../Calendar.hooks'
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton'
import { calendarNewsCreatorId } from '../Calendar.constants'
import moment from 'moment'
import { baseModels } from 'News/creator/Creator/Creator.hooks'

const ListItemPresenter = ({
  groupId,
  workflowId,
  ...props
}: any) => {
  const openCreator = useOpenEventCreator()
  const items = (props.data && props.data[0] !== 'null') ? props.data : []
  const showCreateButton = isShowCreateButton(props.el)

  const canCreateEvent = useCanCalendarCreateEvent({ gId: groupId, workflowId });

  const creatorOptions = useCalenderNewsCreatorOptions(props.el);

  if (items.length === 0) return <></>
  return (
    <Box sx={{ marginBottom: "12px", display: 'flex' }} className={cnEventsItem({}, [])} >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CalendarIcon element={props.el} />
        {canCreateEvent
        && showCreateButton
        && <NewsCreatorButton
          variant='contained'
          newsType={creatorOptions.workflowId || 'events'}
          creatorId={calendarNewsCreatorId}
          creatorOptions={creatorOptions.options}
          groupId={groupId}
          workflowId={workflowId}
          startIcon={<AddCircleOutlineIcon />} 
          sx={{ marginTop: '8px' }}
        >
          <Translate i18nKey={'pryaniky.calendar.eventList.addEvent'} />
        </NewsCreatorButton>
        }
      </Box>
      <Box sx={{ flex: '1', marginLeft: '12px' }} className={cnEventsItem('Events')}>

        {items.map((id: string, idx: number) => <EventItem
          className={cnEventsItem('Events-SingleItem')}
          key={idx}
          id={id}
        // el={props.el} 
        />)}

      </Box>
    </Box>
  )
}


export const ListItem = connect(
  mapStateToProps,
  null
)(ListItemPresenter);