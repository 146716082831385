import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import FormControl from '@material-ui/core/FormControl';
import { TextField, Link } from '@material-ui/core';
import { i18n, Translate } from 'localization';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
    })
);


export const String: FC<any> = ({ field, fieldId, value, onChangeFields, fieldsSet }) => {
    const classes = useStyles();

    return (
        field && field.isReadOnly ?
            <HelpTooltip help={i18n.t('pryaniky.profile.only.Admin.helptooltip')} className={classes.noEditblField}>
                <span className={classes.noEditblFieldSpan}>
                    <Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />
                </span>
                <p className={classes.noEditblFieldP}>
                    {field.url?<Link target="_blank" href={field.url}>{value}</Link>:value}
                    </p>
            </HelpTooltip> : <FormControl key={fieldId} id={fieldId} component="fieldset" className={classes.formControl}>
                <TextField
                    disabled={field.isReadOnly}
                    fullWidth
                    label={<Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />}
                    value={value}
                    onChange={e => onChangeFields(fieldId, 'value', fieldsSet)(e.currentTarget.value)}
                />
            </FormControl>
    );

}

export default String