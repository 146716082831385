import React from 'react';
import { i18n, Translate } from 'localization';
import { Selectize } from 'uielements/src';
import { ISelectizeItem } from "uielements/src/Selectize/Selectize.index";
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, cnFilters, IFiltersProps } from '../Filters.index';
import { IFiltersQuestsProps, IFiltersQuestsState, cnFilter } from './Filters_type_quests.index';
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import './Filters_type_quests.scss'
class FiltersTypeQuestsPresenter extends React.Component<IFiltersQuestsProps, IFiltersQuestsState> {

  private filters = ['all', 'active', 'notActive'];
  private preparedFilters = () => this.filters.map(el => ({
    id: el,
    type: 'filter',
    title: i18n.t(`pryaniky.filter.quests.${el}`),
    value: el
  }))

  private change = (selected: ISelectizeItem) => {
    const { context } = this.props;
    const same = context.filter && context.filter.id === selected.id;
    this.props.changeContextv3(this.props.relations.reduce((acc: any, conID: any) => ({
      ...acc,
      [conID]: {
        filter: !same ? selected : 'all',
        [selected.type]: !same ? selected.value : 'all'
      }
    }), {} as { [s: string]: any }))
  }

  public render() {
    const { tag: Tag = 'div', context, className } = this.props;

    return (
      // @ts-ignore 
      <Tag data={this.preparedFilters()} tag='div' context={'quests'} relations={[]} {...this.props} className={cnFilters({}, [className])} >
        <h3 className={cnFilter('Title')} children={`${i18n.t('pryaniky.filter.quests.title')}:`} />
        <Selectize onClick={this.change} elements={this.preparedFilters()} active={String(context.filter || 'all')} />
      </Tag>
    )
  }
}

export const FiltersTypeQuests = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersQuestsProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersTypeQuestsPresenter)