import {
    IRewardActionPresenterProps,
    IStateProps,
    IState,
    cnClassName,
    IDispatchProps,
    IRewardActionOwnProps,
    mapDispatchToProps,
    mapStateToProps
} from './RewardAction.index'
import React, { useRef, memo } from 'react'
import './RewardAction.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import { Bable } from '../../../Bable/Bable';
import { i18n, Translate } from 'localization';
import { ButtonPopover } from 'blocks/NewsTypes/common/ButtonPopover/ButtonPopover';
import { withReplyContext } from 'News/contexts/reply'
import { connect } from 'react-redux';
import CountSlider from './CountSlider'

const buttonRender = (count: number) => (open: any, openSupport: any, label: any) => {
    return <Button theme='unstyled' onClick={openSupport}>
        <Icon icon={'give'} />{i18n.t('pryaniky.post.creativetasks.rewardReply')}
    </Button>
}

const _RewardAction: React.FC<IRewardActionPresenterProps> = ({
    replyId,
    reply,
    newsId,
    fundIsZero,
    rewardReply,
    reward,
    newsAuthor
}) => {
    const [value, setValue] = React.useState(0);

    const ref = useRef<HTMLDivElement>(null)

    const count = reward

    if (reply.user.id === newsAuthor.id) return <></>

    const setRewardReply = () => {
        rewardReply({
            newsId,
            replyId,
            value
        })
    }

    const cancleReward = () => rewardReply({
        newsId,
        replyId,
        value: 0
    })

    const plusOne = () => rewardReply({
        newsId,
        replyId,
        value: count + 1
    })

    return <>
        <div ref={ref} className={'Action Action-Reward'}>
            {fundIsZero && (count === 0) ?
                <Button theme='unstyled'>
                    <Icon icon={'give'} />
                    {i18n.t('pryaniky.post.creativetasks.rewardNoMoney')}
                </Button> :
                <ButtonPopover anchor={ref} label={i18n.t('support')} accepted={setRewardReply} button={buttonRender(count)}>
                    {(close, accepted) =>
                        <CountSlider accepted={accepted} setValue={setValue} count={count} value={value} close={close} />
                    }
                </ButtonPopover>}

        </div>

        {!fundIsZero && <div className={'Action'} style={{ marginLeft: -25 }}>
            <Button theme='unstyled' title={'+1'} onClick={plusOne}>
                <Bable className={'Reward-Count'}>
                    +1
                </Bable>
            </Button>
        </div>}

        {!(fundIsZero && count === 0) && <div className={'Action'} style={{ marginLeft: -25 }}>
            <Button theme='unstyled'>
                <Bable className={'Reward-Count'}>{count}</Bable>
            </Button>
        </div>}

        {(count !== 0) && <div className={'Action'} style={{ marginLeft: -25 }}>
            <Button theme='unstyled' title={i18n.t('pryaniky.post.creativetasks.cencle_reward')} onClick={cancleReward}>
                <Bable className={'Reward-Count'}>
                    <Icon style={{ marginRight: 0 }} icon={'times'} />
                </Bable>
            </Button>
        </div>}
    </>
}


export const RewardAction = withReplyContext(connect<IStateProps, IDispatchProps, IRewardActionOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(_RewardAction)));