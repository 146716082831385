import React, { FC } from "react";
import { TranslateContextPresenter } from 'localization';
import { useSelector } from "react-redux";
import { mapI18nReducerKeyToTranslateProps } from "utils/src/CommonRedux/i18n/reduxModule";

export const I18nReducerToContextProvider: FC<{}> = ({
    children
}) => {

    const reducerFileds = useSelector(mapI18nReducerKeyToTranslateProps);

    return (
        <TranslateContextPresenter namespace={reducerFileds.namespace} language={reducerFileds.language} rerender={reducerFileds.rerender} >
            {children}
        </TranslateContextPresenter>
    );
};