import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'redux/connector';

import { mapActionsToProps, mapStateToProps } from '../../List.index';
// import { mapStateToProps } from './List_type_groups.index';

import './List_type_groups_selector.scss';
import { ListLite, Button, Icon, Loading } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Header } from '../../Header/List-Header';
import { Item } from '../../Item/List-Item';
import { ListGroupsPresenter } from './List_type_groups';
import { toast } from 'react-toastify';
import { IButtonProps } from 'uielements/src/Button/Button.index';
import { DialogGroupCreate } from 'blocks/Dialogs/Group/Create/GroupCreate';
import { DialogIntercompanyGroupRequests } from 'blocks/Dialogs/Intercompany/Groups/Requests/IntercompanyGroupRequests';
import { withTranslation } from 'react-i18next';

const TYPES_TO_MASS_ACTIONS = ['archive', 'delete'];

export class ListGroupsSelectorPresenter extends ListGroupsPresenter {
    public prepareData(el: any) {
        super.prepareData(el);
        el.checked = false;
        el.actions_data = {
            options: el.actions.map((action: string) => {
                let title: string = i18n.t(`pryaniky.list.groups.actions.${action}`);
                if (action === 'request' || action === 'join') {
                    title =
                        el.visibilityType === 0 || this.props.uIsAdmin
                            ? i18n.t(`pryaniky.list.groups.actions.join`)
                            : i18n.t(`pryaniky.list.groups.actions.request`);
                }
                const buttonProps: IButtonProps = {};
                if (action === 'edit') {
                    buttonProps.type = 'rlink';
                    buttonProps.href = '/group/' + el.pkid + '?groupEdit=true';
                } else {
                    buttonProps.onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                        if (action !== 'request' && action !== 'leave') {
                            e.stopPropagation();
                        }
                        this.action(action, el);
                    };
                }
                if (el.isArchived && action === 'archive') {
                    return {
                        title: i18n.t('pryaniky.list.groups.actions.dearchive'),
                        type: undefined,
                        buttonProps,
                    };
                }
                return {
                    title,
                    type: action === 'edit' ? 'rlink' : undefined,
                    buttonProps,
                };
            }),
        };
    }

    public action = (action: string, group: any) => {
        if (action === 'request' || action === 'leave') return this.joinAction(group);
        let confirmText = i18n.t('pryaniky.list.groups.confirm.' + action, { count: 1 });
        if (action === 'archive' && group.isArchived) {
            confirmText = i18n.t(`pryaniky.list.groups.confirm.dearchive`, { count: 1 });
        }
        utilsProj.confirm({
            text: confirmText,
            onConfirm: () => {
                let request;
                if (action === 'archive') request = utils.API.groups.archive(group.pkid, !group.isArchived);
                if (action === 'delete') request = utils.API.groups.remove(group.pkid);
                if (!request) return;
                request.r.then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code) toast.error(response.error_text);
                    else {
                        if (action === 'delete') this.props.removeDataFromList({ id: this.id, data: [group.pkid] });
                        if (action === 'archive') {
                            this.props.removeDataFromList({ id: this.id, data: [group.pkid] });
                            if (group.isArchived)
                                toast.success(i18n.t(`pryaniky.list.groups.actions.dearchiveSuccess`, { count: 1 }));
                            else toast.success(i18n.t(`pryaniky.list.groups.actions.${action}Success`, { count: 1 }));
                        } else toast.success(i18n.t(`pryaniky.list.groups.actions.${action}Success`, { count: 1 }));

                        // this.setState(prevState => ({ data: prevState.data.filter(item => item.pkid !== group.pkid) }));
                    }
                });
            },
        });
    };

    public batchAction = (action: 'delete' | 'archive' | 'dearchive' | string, ids: number[]) => {
        utilsProj.confirm({
            text: i18n.t('pryaniky.list.groups.confirm.' + action, { count: ids.length }),
            onConfirm: () => {
                let request;
                if (action === 'archive' || action === 'dearchive')
                    request = utils.API.groups.archive(ids, action === 'archive' ? true : false);
                if (action === 'delete') request = utils.API.groups.remove(ids);
                if (!request) return;
                request.r.then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code) toast.error(response.error_text);
                    else {
                        this.props.clearSelectedListItems({ id: this.id, data: null });
                        if (action === 'archive' || action === 'dearchive')
                            this.props.removeDataFromList({ id: this.id, data: ids.map((el) => el.toString()) });
                        toast.success(i18n.t(`pryaniky.list.groups.actions.${action}Success`, { count: ids.length }));
                        this.setState((prevState) => ({
                            data: prevState.data.filter((group) => !ids.includes(group.pkid)),
                        }));
                    }
                });
            },
        });
    };

    public prepareMassActions = (ids: string[]) => {
        const elems = ids.map((id) => this.props.getListItem(this.id, id));
        const actions: string[] = [];
        elems.forEach((group) =>
            group.actions.forEach((action: string) => {
                if (TYPES_TO_MASS_ACTIONS.includes(action)) {
                    if (action === 'archive' && group.isArchived) action = `de${action}`;
                    if (!actions.includes(action)) actions.push(action);
                }
            })
        );
        return actions.map((action: string) => ({
            title: i18n.t(`pryaniky.list.groups.actions.${action}`, { count: ids.length }),
            buttonProps: {
                onClick: this.batchAction.bind(
                    this,
                    action,
                    ids.map((el: string) => Number(el))
                ),
            },
        }));
        // [
        //   {
        //     title: i18n.t('pryaniky.list.groups.actions.archive', { count: ids.length }),
        //     buttonProps: {
        //       onClick: this.batchAction.bind(this, 'archive', ids.map((el: string) => Number(el))),
        //     },
        //   },
        //   {
        //     title: i18n.t('pryaniky.list.groups.actions.delete', { count: ids.length }),
        //     buttonProps: {
        //       onClick: this.batchAction.bind(this, 'delete', ids.map((el: string) => Number(el))),
        //     },
        //   },
        // ]
    };

    public prepareOptions = () => {
        const { allowToGroupTransfer, uIsAdmin, authUser } = this.props;
        const options: any[] = [
            {
                title: i18n.t('pryaniky.list.groups.actions.create'),
                buttonProps: {
                    onClick: this.toggleGroupCreateDialog,
                },
            },
        ];

        if (allowToGroupTransfer && uIsAdmin && authUser.baseData.networks && authUser.baseData.networks.length > 1)
            options.push({
                title: i18n.t('pryaniky.subnetworks.list.groups.intercompanyRequests'),
                buttonProps: {
                    onClick: () =>
                        DialogIntercompanyGroupRequests({})
                            .then(() => {})
                            .catch(() => {}),
                },
            });
        return options;
    };

    public renderChildren = () => {
        // const { isFinished, isLoading, selected } = this.state;
        // const { data } = this.props;
        const { data, isFinished, isLoading, selected, hideData, uIsAdmin, authUser, allowToGroupTransfer } =
            this.props;
        // const data: any[] = this.state[this.dataVariable];
        // const selectedIds: number[] = selected.map((el: string) => Number(el));
        return (
            <React.Fragment>
                {/* <GroupCreate
          data={{}}
          isShown={this.state.createDialog}
          onClose={this.toggleGroupCreateDialog}
        {/* {
          uIsAdmin && authUser.baseData.networks && authUser.baseData.networks.length > 1 && allowToGroupTransfer &&
          <IntercompanyGroupRequests data={{}} isShown={this.state.intercompanyDialog} onClose={() => this.setState({intercompanyDialog: false})} />
        } */}
                <Header
                    className={'rounded-top'}
                    type={'common'}
                    search={{
                        placeholder: i18n.t('pryaniky.list.groups.search'),
                        onChange: this.search,
                    }}
                    actions={{
                        text: <div children={i18n.t('pryaniky.lists.actions')} />,
                        options: this.prepareOptions(),
                    }}
                    counter={{
                        all: {
                            title: i18n.t('pryaniky.list.groups.title'),
                            count:
                                !this.props.additionalParams.count || this.props.additionalParams.count <= 0
                                    ? data.length
                                    : this.props.additionalParams.count,
                            action: () => this.props.checkAllListItem({ id: this.listId, data: null }),
                        },
                        selected: {
                            title: i18n.t('pryaniky.list.selected', { count: selected.length }),
                            count: selected.length,
                            action: {
                                options: this.prepareMassActions(selected),
                            },
                        },
                    }}
                />
                <ListLite
                    className={'rounded-bottom'}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    loadMore={this.getData}
                >
                    {data.map((el) => (
                        <Item lId={this.listId} iId={el} type={'common'} key={el} data_key={el} />
                    ))}
                </ListLite>
            </React.Fragment>
        );
    };

    public toggleIntercompanyDialog = () => this.setState({ intercompanyDialog: true });
}

export const List = connect<any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps(mapActionsToProps)
)(ListGroupsSelectorPresenter);
