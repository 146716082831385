import { IParticipantsBoxProps, mapDispatchToProps, mapStateToProps, cnParticipantsBox } from './ParticipantsBox.index'
import React, { useState, useEffect, memo } from 'react'
import './ParticipantsBox.scss';
import { UserList } from 'uielements/src/UserList/UserList';
import { AdditionalBox } from '../../common/AdditionalBox/AdditionalBox';
import { Tabs } from 'uielements/src'
import { } from '../EventsSides';
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';
import openParticipants from 'blocks/Dialogs/News/Participants/Participants';
import { isEqual } from 'lodash';

export const ParticipantsBoxPresenter: React.FC<IParticipantsBoxProps & any> = ({
    eventParticipants = [],
    eventNotGoers = [],
    eventMaybePar = [],
    eventWaitList = [],
    currentUser,
    eventId,
    eventUserAuthorId,
    eventParticipantsCount,
    changeParticipants,
    eventMaybePartCount,
    eventWaitListCount,
    eventNotGoersCount,
    eventStatus,
    loadParticipant,
    ...props }) => {

    const [tab, setTab] = useState<string>(eventStatus === '' ? 'going' : eventStatus);
    // const [show, setShow] = useState<boolean>(false);

    const values: any = { 'going': eventParticipantsCount, 'waitlist': eventWaitListCount, 'maybe': eventMaybePartCount, 'no': eventNotGoersCount }
    const usersValues: any = { 'going': eventParticipants, 'waitlist': eventWaitList, 'maybe': eventMaybePar, 'no': eventNotGoers }
    const count = values[tab]
    const users = usersValues[tab]
    const showCount = 10

    useEffect(() => {
        let listLength = 0;
        switch (tab) {
            case 'going':
                listLength = eventParticipants.length
                break;
            case 'maybe':
                listLength = eventMaybePar.length
                break;
            case 'waitlist':
                listLength = eventWaitList.length
                break;
            case 'no':
                listLength = eventNotGoers.length
                break;
        }
        // check by array length because at modal news view action loadParticipant call cycling rerender
        tab !== 'going' && !listLength && loadParticipant({ status: tab, id: eventId});
    }, [tab]);

    const tabsList = []
    if (eventParticipants.length > 0 || eventParticipantsCount > 0) tabsList.push({
        title: i18n.t('pryaniky.post.event.participants.going', { count: eventParticipantsCount }),
        value: 'going'
    })
    if ((currentUser.id == eventUserAuthorId || currentUser.isModerator) && (eventWaitList.length > 0 || eventWaitListCount > 0)) tabsList.push({
        title: i18n.t('pryaniky.post.event.participants.waitlist', { count: eventWaitListCount }),
        value: 'waitlist'
    })
    if (eventMaybePar.length > 0 || eventMaybePartCount > 0) tabsList.push({
        title: i18n.t('pryaniky.post.event.participants.maybe', { count: eventMaybePartCount }),
        value: 'maybe'
    })
    if (eventNotGoers.length > 0 || eventNotGoersCount > 0) tabsList.push({
        title: i18n.t('pryaniky.post.event.participants.no', { count: eventNotGoersCount }),
        value: 'no'
    })
    if (tabsList.length !== 0 && !tabsList.find((v) => v.value === tab)) {
        setTab(tabsList[0].value);
    }

    return <div className={cnParticipantsBox({}, [])} {...props}>
        <AdditionalBox
            headerText={<Tabs tabsList={tabsList} onChange={setTab} selected={tab} />}
        >
            <UserList
                cut={false}
                action={count > showCount}
                actionBtnContent={<span className="AnswersValue">+{count - showCount}</span>}
                data={users} actionCallback={() => openParticipants({
                    newsId: eventId,
                    status: tab
                })} />
        </AdditionalBox>
        {/* <Participants
            nId={eventId}
            data={{}}
            isShown={show}
            status={tab}
            onClose={() => setShow(false)}
        /> */}
    </div>
}

export const ParticipantsBox = connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(ParticipantsBoxPresenter));
