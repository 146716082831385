import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
// import './Competitions.scss';
import { Page } from 'blocks/Page/Page';
import { i18n, Translate } from 'localization';
import { setContext } from 'redux/actions/Widgets';
import { pageNames } from 'redux/pageNames';

interface ICompetitionsProps extends IActionsDispatch, IDispatchProps {}

interface IActionsDispatch {
  setContext: any;
}

export const CompetitionsPresenter: React.FC<ICompetitionsProps> = props => {
  // useEffect(() => {
  //   props.setContext({
  //     paths: ['common'],
  //     innerPath: '',
  //     value: {
  //       newsType: 'creativetasks',
  //       postOff: true,
  //     },
  //   });
  // }, []);

  return (
    <Page
      className="Competitions"
      page={{
        name: pageNames.competitions,
        title: i18n.t('competitions'),
      }}
    />
  );
};

export const Competitions = connect(
  null,
  mapDispatchToProps({ setContext })
)(CompetitionsPresenter);
