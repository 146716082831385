import moment from "moment";
import { DateRangePicker } from "muicomponents/src/DatePicker";
import { ComponentProps } from "react";
import { UsersEventsSettings } from "./Settings/UsersEventsTableSettings.index";

export const getDatesFromTo = (start: moment.Moment, end: moment.Moment) => {
    const dates: moment.Moment[] = [];
    let date = start.clone();
    while(date.get('D') < end.get('D')) {
        dates.push(date);
        date = date.clone().set('D', date.get('D') + 1);
    }
    dates.push(end.clone());
    return dates;
};

export const getDefaultFilters = (settings?: UsersEventsSettings) => {
    return {
        dates: [moment().toISOString(), moment().add(1, 'month').toISOString()] as ComponentProps<typeof DateRangePicker>['value'],
        user: null,
        activeBlocks: settings?.headBlocks.map((block) => block.id) || []
    };
};