import { put, takeLeading, takeEvery } from 'redux-saga/effects';

import {
    call,
    select,
} from 'utils/src/saga.effects';
import { i18n, Translate } from 'localization';
import { checkResponseStatus, getErrorText } from 'utils/src/utils'

import {
    JOURNAL_GET_JOURNAL_DATA, 
    setJournalDataToRedux,
    journalToggle
} from './actions'

import {
    IGetJournalDataFromServerAction,
} from "./actions.interfaces"

import {
    IJournalDataResponse,
    IJournalData,
} from 'utils/src/requests/models/admin.journal'

import {
    getJournal,
} from 'utils/src/requests/requests.journal'

import { getJournalData as getJournalDataSelector } from './selectors'

import { sub, format } from 'date-fns';
import { toast } from 'react-toastify';

const pageCount = 10;

function* getJournalDataFromServer(action: IGetJournalDataFromServerAction) {
    try {
        const listData = yield* select(getJournalDataSelector);
        // yield put(journalToggle('isFinished', false));
        yield put(journalToggle('isLoading', true));
        const data: IJournalDataResponse = yield* call(getJournal, action.payload.objectId, listData.length, pageCount, { ...action.payload.data })
        if ( checkResponseStatus(data)) {
            yield put(setJournalDataToRedux(data.data))
            yield put(journalToggle('isFinished', data.isFinished));
        }else{
            toast.error(i18n.t('pryaniky.list.journal.error.text'));  
            yield put(journalToggle('isFinished', true))
        }
        yield put(journalToggle('isLoading', false));

        // yield put(journalToggle('isFinished', data.data.length < pageCount));
        
    } catch (e) {
        console.warn('get journalData error', e);
    }
}


const Journal = function* Journal() {
    yield takeLeading(JOURNAL_GET_JOURNAL_DATA, getJournalDataFromServer)

}

export default Journal; 