import React from 'react';
import { ITabsControlProps, cnTabsControl, mapTabsControlActionToProps, mapTabsControlStateToProps } from './TabsControl.index';
import { Tabs } from '../Tabs/Tabs';
import { Button, Icon } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import './TabsControl.scss';

let lastIdx: number;

const requiredKeys = ['license']

export const TabsControlPresenter: React.FC<ITabsControlProps> = ({ className, edit, tag: Tag = 'div', updateContext, changeIsFirstLogonOnServer, ...props }) => {

  const { pathname } = useLocation();
  const history = useHistory();

  const getActiveIdx = () => {
    const { widget, context } = props;
    if (!Array.isArray(widget.data)) return -1;
    let tabIdx = widget.data.findIndex((el) => el.name === context.tab || el.id === context.tab);
    if (tabIdx === -1) tabIdx = 0;
    return tabIdx;
  }

  const isLastItem = () => getActiveIdx() === (props.widget.data as any[] || []).length - 1;

  const isActiveNext = () =>
    requiredKeys.reduce((rez: boolean, rKey: string) => {
      if(!rez) return rez;
      return Object.keys(props.context).reduce((rezKey: boolean, key: string) => {
        if (!rezKey) return rezKey;
        return key.indexOf(rKey) === 0 ? props.context[key] : true
      }, true);
    }, true);

  const setTab = (to: 'next' | 'previous') => () => {
    if (to === 'next' && !edit && !isActiveNext()) return ;
    const { widget } = props;
    if (!Array.isArray(widget.data)) return;
    let tabIdx = getActiveIdx();
    switch(to) {
      case 'next': {
        tabIdx += 1;
        break;
      }
      case 'previous': {
        tabIdx -= 1;
        break;
      }
    }
    if (tabIdx < 0) tabIdx = 0;
    if (tabIdx >= (widget.data || []).length) tabIdx = (widget.data || []).length - 1;
    lastIdx = tabIdx;
    updateContext(widget.id, {
      tab: (widget.data || [])[tabIdx] && (widget.data || [])[tabIdx].id || undefined
    });
  }

  const finishWelcome = () => {
    if (!isActiveNext()) return;
    if (pathname.includes('/welcome')) changeIsFirstLogonOnServer(() => history.push('/dash'));
  }

  return (
    <Tag {...props} className={cnTabsControl({}, [ className ])}>
      <Tabs context={props.context} widget={props.widget} tabs={Array.isArray(props.widget.data) && props.widget.data || []} disableNavigate />
      <div className={cnTabsControl('Controls', ['Widget'])}>
        <div className={cnTabsControl('Controls_before')}>
          {
            getActiveIdx() > 0 && <Button onClick={setTab('previous')}>
              <Icon icon={'chevron-left'} />
              <Translate i18nKey={'previous'} />
            </Button>
          }
        </div>
        <div className={cnTabsControl('Controls_after')}>
          <Button disabled={!edit && !isActiveNext()} onClick={isLastItem() ? finishWelcome : setTab('next')}>
            <Translate i18nKey={isLastItem() ? 'done' : 'next'} />
            <Icon icon={'chevron-right'} />
          </Button>
        </div>
      </div>
    </Tag>
  )
}

/**
 * @deprecated
 */
export const TabsControl = connect(
  mapTabsControlStateToProps,
  mapTabsControlActionToProps
)(TabsControlPresenter);