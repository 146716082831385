import React, { useState, useEffect, useRef, useMemo, FC } from 'react';
import { i18n, Translate } from 'localization';
import { mapStateToProps, mapDispatchToProps, IFiltersStateProps, IFiltersDispatchProps, cnFilters, IFiltersProps } from '../Filters.index';
import { IFiltersTimelineProps, IFiltersTimelineState, IFilterItem } from './Filters_type_timeline.index';
import { connect } from 'react-redux';
import { IStateType as IState } from 'redux/store';
import './Filters_type_timeline.scss'
import RadioGroup from 'uielements/src/MaterialElements/RadioGroup';
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
import Button from 'uielements/src/MaterialElements/Button/Button';
import Chip from '@material-ui/core/Chip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { IItemsViewDat, ITimlineFilterData, onlyCat, useTimelineFilter } from './useTimelineFilter'

// QUESTION в идеале типизировать
// подготавливаем filtr  для  всех компонентов 
const preparedFilters = (data: ITimlineFilterData[], enabledModules: { [s: string]: boolean }, itemsView: IItemsViewDat) => data.filter((el: ITimlineFilterData) => {
  if (enabledModules[el.id] !== undefined && !enabledModules[el.id]) return false;
  if (el.adminOnly) return itemsView.isAdmin
  return true;
}).map((el: ITimlineFilterData) => ({
  id: el.id,
  type: el.type,
  title: <Translate i18nKey={el.title} />,
  value: el.value
}));

const FiltersTypeTimelinePresenter: FC<IFiltersTimelineProps> = ({
  context,
  tag: Tag = 'div',
  enabledModules,
  itemsView,
  ...props
}) => {
  const {
    handleDelete,
    setTypesListShown,
    typesListShown,
    defaultTimelineData,
    change,
    others,
    timlineData,
    setTimlineData,
    setOthers,
    categoriesArr,
    useMultyValues
  } = useTimelineFilter({
    context,
    // props,
    changeContextv3: props.changeContextv3,
    relations: props.relations
  })
  return (
    <>
      {/* // @ts-ignore  */}
      <Tag {...props} className={cnFilters({ type: 'timeline' }, [props.className])} >

        <h4 className={cnFilters('Title')}  ><Translate i18nKey={'pryaniky.filter.feed.title'} /></h4>
        <div className={cnFilters('ActiveTypes')}>
          {context?.filter && context?.filter[0]?.id === "all" ?
            <span className={cnFilters('ActiveAll')}>

              <Translate i18nKey={'pryaniky.filter.feed.what.is.shown'} />
              <span className={cnFilters('ActiveAll-Name')}>
                <Translate i18nKey={context.filter[0].title} />
              </span>
            </span> :
            <>
              {
                Array.isArray(context?.filter) && context?.filter.map((chip) => <Chip
                  label={<Translate i18nKey={chip.title} />}
                  className={cnFilters('ActiveChip')}
                  variant="outlined"
                  onDelete={() => handleDelete(chip.id)}
                />)
              }
            </>
          }
        </div>
        {typesListShown && <div className={cnFilters('RadioGroup')}>
          <p className={cnFilters('Subtitle')}>

            <Translate i18nKey={'pryaniky.filter.feed.show'} />

          </p>

          <RadioGroup
            items={preparedFilters(defaultTimelineData, enabledModules, itemsView)}
            onChange={(selectedEl: ITimlineFilterData[]) => {
              let finded = defaultTimelineData.find((el: any) => el.id === selectedEl)
              if (finded) change([finded]);
            }}
            // className={cnFilters('SingleNewsTypes CustomStyle primaryColor3-text')}
            value={others[0]?.id === "categories" ? others[0]?.id : Array.isArray(context.filter) && context.filter[0] ? context.filter[0].id : context?.filter?.id || undefined}
            size='small'
          />

          <div className={cnFilters('Categiries')}>
            {timlineData.map((el, index) => <Checkbox
              value={el.isActive}
              onChange={(value: boolean) => {
                setTimlineData((prevState) => prevState.map((item) => item.id === el.id ? { ...item, isActive: value } : item))
                setOthers([onlyCat])
              }}
              label={<Translate i18nKey={el.title} />}
            />)}
          </div>
          <Button
            className={cnFilters('UseFilterBtn')}
            variant={'contained'}
            disable={categoriesArr.length < 1}
            color={'primary'}
            size={'small'}
            onClick={() => useMultyValues()}>

            <Translate i18nKey={i18n.t('pryaniky.filter.feed.use')} />

          </Button>
        </div>}
        <div className={cnFilters('showMore')}>
          <Button
            className={cnFilters('UseFilterBtn CustomStyle primaryColor3-text')}
            variant="text"
            color={'primary'}
            size={'small'}
            onClick={() => setTypesListShown(!typesListShown)}>

            <Translate i18nKey={'pryaniky.filter.feed.list'} />

            {/* <IconButton> */}
            {typesListShown ? <ExpandLess className={cnFilters('Icon CustomStyle primaryColor3-text')} /> : <ExpandMore className={cnFilters('Icon CustomStyle primaryColor3-text')} />}
            {/* </IconButton> */}
          </Button>
        </div>


      </Tag>
    </>
  )
}

export const FiltersTypeTimeline = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersTimelineProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(FiltersTypeTimelinePresenter)