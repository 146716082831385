import moment from "moment";
import React, { ComponentProps, createContext, FC, useContext, useEffect, useMemo } from "react";
import { MonthHeader } from "./MonthHeader";

type UsersEventsTableContextType = {
    dateStart: string;
    dateEnd: string;
    monthesArray: string[];
    monthesDictionary: {
        [month: UsersEventsTableContextType['monthesArray'][number]]: Pick<ComponentProps<typeof MonthHeader>, 'dateFrom' | 'dateTo'>
    };
    monthesDatesDictionary: {
        [month: UsersEventsTableContextType['monthesArray'][number]]: moment.Moment[];
    }
}

const UsersEventsTableContext = createContext<UsersEventsTableContextType>({
    dateStart: '',
    dateEnd: '',
    monthesArray: [],
    monthesDictionary: {},
    monthesDatesDictionary: {},
});

export const getMonthKey = (date: moment.Moment | Date) => {
    return moment(date).format('MM-YYYY');
};

export const useUsersEventsTableContext = () => {
    return useContext(UsersEventsTableContext);
};

export const UsersEventsTableContextProvider: FC<{
    dateStart: string,
    dateEnd: string,
}> = ({
    dateStart,
    dateEnd,
    children
}) => {
    
    const contextValue = useMemo(() => {
        if(!dateStart || !dateEnd) return {
            dateStart,
            dateEnd,
            monthesArray: [],
            monthesDatesDictionary: {},
            monthesDictionary: {}
        };
        const [start, end] = [moment(dateStart), moment(dateEnd)];
        const monthesArray: UsersEventsTableContextType['monthesArray'] = [
        ];
        let lastMonth = monthesArray[monthesArray.length - 1];
        const monthesDictionary: UsersEventsTableContextType['monthesDictionary'] = {
        };
        const monthesDatesDictionary: UsersEventsTableContextType['monthesDatesDictionary'] = {
        };
        let date = start.clone();
        while(date.startOf('day') < end.startOf('day')) {
            const key = getMonthKey(date);
            if(!lastMonth || lastMonth !== getMonthKey(date)) {
                monthesArray.push(key);
                monthesDictionary[key] = {
                    dateFrom: date.toDate()
                };
                // monthesDatesDictionary[key] = [
                //     date
                // ];
            } else {
                monthesDictionary[key].dateTo = date.toDate();
                // monthesDatesDictionary[key].push(date);
            }
            date = date.clone().set('D', date.get('D') + 1);
            lastMonth = monthesArray[monthesArray.length - 1];
        }
        monthesDictionary[lastMonth].dateTo = date.toDate();
        // monthesDatesDictionary[lastMonth].push(date);
        return {
            dateStart,
            dateEnd,
            monthesArray,
            monthesDictionary,
            monthesDatesDictionary
        };
    }, [dateStart, dateEnd]);

    return (
        <UsersEventsTableContext.Provider value={contextValue}>
            {children}
        </UsersEventsTableContext.Provider>
    )
};