import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

export const tag = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            // id: ids.info,
            type: widgets.types.info,
          }),
          generateWidget({
            type: widgets.types.tabs,
            data: [
              generateColumn({
                name: 'publications',
                items: [
                  generateWidget({
                    type: widgets.types.layout,
                    data: [
                      generateColumn({
                        items: [
                          generateWidget({
                            type: widgets.types.timeline,
                          }),
                        ],
                        styles: {
                          width: '100%',
                        },
                      }),
                    ],
                  }),
                ],
              }),
              generateColumn({
                name: 'people',
                items: [
                  generateWidget({
                    type: widgets.types.layout,
                    data: [
                      generateColumn({
                        items: [
                          generateWidget({
                            // id: ids.users,
                            type: widgets.types.list + '/users',
                          }),
                        ],
                        styles: {
                          width: '100%',
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
        styles: {
          width: '72%',
        },
      }),
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.filters + '/subnetworks',
            relations: [],
            data: [],
          }),
        ],
        styles: {
          width: '28%',
        },
      }),
    ],
  });
}