import React from 'react';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { ImageLoader } from 'blocks/ImageLoader/ImageLoader';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';

// SliderControl

export const sliderItem = (props: any) => {
  if (!props.visible) return null;
  // const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  
    return <>
      {
        // props.data.map((el: any, idx:number) =>
        //   <ImageLoader
        //     squarePreview
        //     onLoaded={(img: any) => {
              
        //       props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => ([{
        //         ...props.data[get_variable_name(props.uischema.scope)][idx],
        //         imgUrl: img.originalUrl,
        //         imgName: img.name,
        //       }])))
        //     }}

        //     children={
        //       <UIElements.Input
        //         type='text'
        //         value={props.data[get_variable_name(props.uischema.scope)].href}
        //         placeholder={ 'Введите ссылку' || 'schema.placeholder'}
        //         onChange={(e: any) => {
        //           props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => ({
        //             ...props.data[get_variable_name(props.uischema.scope)][idx],
        //             href: e.currentTarget.value
        //           })))
        //         }} />

        //     }
        //   />
        // )
      }
    </>
  
  // <ImageLoader squarePreview onLoaded={() => {}} />
  // return <SliderLinkItem
  //   edit={true}
  //   img={props.data[get_variable_name(props.uischema.scope)]}
  // // onChange={(selected: any) => {
  // //   props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected))
  // // }} 
  // />
}
// end SliderControl