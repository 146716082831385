import React, { createRef } from 'react';
import { i18n, Translate } from 'localization';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { ImageLoader } from '../../ImageLoader/ImageLoader';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { emptyItem, ISliderLinkItem } from "blocks/WSettings/structures/sliderlink/uischema";
import Checkbox from 'uielements/src/MaterialElements/Checkbox/Checkbox';
// SliderControl
// import i18n from 'localization/src/i18n';
import { cn } from "@bem-react/classname";
export const cnImageLoaderControl = cn('ImageLoaderControl');


export const widgetIcon = (props: any) => {
    if (!props.visible) return null;
    const aspect = ((props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements as ISliderLinkItem[]).filter((el) => !el.deleted).reduce((acc, cur, idx) => idx === 0 ? cur.imgId : acc, GUID_EMPTY) !== GUID_EMPTY && props.data[get_variable_name(props.uischema.scope)].aspect || 0;
    const width = ((props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements as ISliderLinkItem[]).filter((el) => !el.deleted).reduce((acc, cur, idx) => idx === 0 ? cur.imgId : acc, GUID_EMPTY) !== GUID_EMPTY && props.data[get_variable_name(props.uischema.scope)].width || false;
    const height = !width ? 50 : undefined;
    return (
        <>
            {
                (props.data[get_variable_name(props.uischema.scope)] || { elements: [] }).elements.map((el: ISliderLinkItem, idx: number) => {
                    if (el.deleted) return null;
                    return <div className={'SliderItem'}>
                        <ImageLoader
                            squarePreview
                            cropper
                            cropperProps={{
                                aspect:16/10,
                                // aspect: aspect || false,
                                width: 240,
                                height: 150
                                // width: width || 50,
                                // height
                                // heigth: heigth,
                            }}
                            startPreview={el.imgUrl}
                            onLoadStart={(file: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                                oldData.aspect = file.aspect || 0;
                                oldData.width = file.width || 0;
                                // oldData.heigth = file.heigth || 0;
                                return { ...oldData };
                            }))}
                            onLoaded={(rez: any) => props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => {
                                oldData.elements[idx].imgName = rez.name;
                                oldData.elements[idx].imgId = rez.id;
                                oldData.elements[idx].imgUrl = rez.originalUrl;
                                return { ...oldData };
                            }))}
                            title={i18n.t('pryaniky.widget.image.loader.title')}
                            description={i18n.t('pryaniky.widget.image.loader.description')}
                        />
                    </div>
                })
            }
        </>
    )
}
// end SliderControl