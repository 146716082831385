import React from 'react';
import { i18n, Translate } from 'localization';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { InputMention } from '../../InputMention/InputMention';
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { GroupsSuggester, IconsSuggester } from 'muicomponents/src/Suggester';
import { ISelectableObject } from 'utils/src/requests/models/api.base';
// GroupsInputControl
export const groupsInput = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  return <GroupsSuggester
    TextFieldProps={{
      fullWidth: true,
      label: schema.placeholder || i18n.t('pryaniky.placeholders.select'),
      // helperText: schema.placeholder || i18n.t('pryaniky.placeholders.select')
    }}
    multiple={false}
    // TODO check types
    value={props.data[get_variable_name(props.uischema.scope)]}
    // TODO check types
    onChange={(e, value) => {
      props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => value))
    }}
  />}
//   <InputMention
//     type='groups'
//     value={props.data[get_variable_name(props.uischema.scope)]}
//     placeholder={schema.placeholder || i18n.t('pryaniky.placeholders.select')}
//     noMulti={true}
//     onChange={(selected: any) => {
//       props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected))
//     }}
//     {...(schema.props || {})} />
// }
// end GroupsInputControl