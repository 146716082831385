import AccessAlarms from '@mui/icons-material/AccessAlarms';
import { styled } from '@mui/material/styles';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { FC } from 'react';
import { CustomEventTitle } from '../../CustomEvent.styled';
// workflows.getUserStatuses
const Icon: any = styled(AccessAlarms)({
    color: '#C344BE',
});

export const WorkflowsEvent: FC<any> = ({ event }) => {
    return (
        <>
            <AccessAlarms
                sx={{
                    color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`,
                    marginRight: '8px',
                }}
            />

            <Tooltip overflowOnly title={`${event.title} - ${event.user.displayName}`}>
                <CustomEventTitle>
                    {event.title} - {event.user.displayName}
                </CustomEventTitle>
            </Tooltip>
        </>
    );
};
