import React, { FC } from 'react';
import { cnSurveyStart, SurveyStartProps } from './SurveyStart.index';
import { SurveyStartBox } from './SurveyStart.styled';
import { LoadingButton } from 'muicomponents/src';
import { i18n, Translate } from 'localization';
import { useSurveyStart } from './SurveyStart.hooks';

export const SurveyStart: FC<SurveyStartProps> = ({
  preview
}) => {

  const {
    loading,
    startSurvey
  } = useSurveyStart({preview});

  return (
    <SurveyStartBox className={cnSurveyStart()}>
      <LoadingButton
        className={cnSurveyStart('SurveyStartBTN')}
        variant={'contained'}
        loading={loading}
        onClick={startSurvey}
      >
        <Translate i18nKey={'pryaniky.survey.startSurvey'} />
      </LoadingButton>
    </SurveyStartBox>
  );
};