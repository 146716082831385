import React, { FC, useEffect, memo, useCallback, ComponentProps, useMemo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    News
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import { Icon } from 'uielements/src/Icon/Icon';
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { withRouter } from 'react-router-dom'
import NewsItem from '../NewsItem'
import queryString from 'query-string';
import ShortView from '../ShortView'
import { PostSkeleton } from 'blocks/Skeletons/Posts/Post-Skeleton';
import { i18n, Translate } from 'localization';
import { useIsLoading, useIsError } from 'utils/src/CommonRedux/LoadIndicator'
import actTypes from '../../redux/actionsTypes/News'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import { TViewType } from 'News/redux/reducer/types';
import MiniView from 'News/containers/MiniView';
import ContentView from 'News/containers/ContentView';
import HeadView from 'News/containers/HeadView';
import { keys } from 'lodash';
import { useTimelineLoader } from 'News/utils/hooks'

type IPresenterProps = News.NewsTimeline.IPresenterProps
type IOwnProps = News.NewsTimeline.IOwnProps
type IDispatchProps = News.NewsTimeline.IDispatchProps
type IStateProps = News.NewsTimeline.IStateProps
type IState = News.NewsTimeline.IState

const LoadButton = withLoadSpin(Button)

let enableVirtual = window.location.hostname.includes('azurewebsites.net')
enableVirtual = enableVirtual ? enableVirtual : window.location.hostname.includes('localhost')
enableVirtual = false

const viewTypes: { [s in TViewType]?: React.ComponentType<{}> } = {
    short: ShortView,
    mini: MiniView,
    content: ContentView,
    head: HeadView
}

export const viewTypesExluded = ['full', 'short', 'head'] as const;

export const Presenter: FC<IPresenterProps> = ({
    className,
    count,
    loadNewsList,
    isFinished,
    keys,
    params = { newsTypes: 'all' },
    viewType,
    sending,
    settings,
    isSingle = false,
    // active,
    setActiveNews,
    changeStatusField
}) => {

    if (settings?.viewType) viewType = settings?.viewType;
    const { isFailed, isLoading, loadMore } = useTimelineLoader(params, keys, params.count)

    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished
    }, { rootMargin: (window.innerHeight * 1) + 'px', threshold: 0 });

return <div className={cnClassName({}, [className])}>
        <ListPlaceholder
            className={cnClassName('Placeholder')}
            isFinished={isFinished}
            isLoading={isLoading}
            isError={isFailed}
            itemsCount={keys.length}
            loadNode={<div>{(new Array(settings?.once ? 1 : 10)).fill(<PostSkeleton />)}</div>}
            titleEmpty={i18n.t('pryaniky.timeline.notFound.title')}
            textEmpty={i18n.t('pryaniky.timeline.notFound.text')}
            titleError={i18n.t('pryaniky.timeline.error.title')}
            textError={<Button onClick={loadMore} disabled={isLoading}>{i18n.t('pryaniky.timeline.error.reload.button')}</Button>}
            textLoad={'load'}
        >
            <div className={cnClassName('List')}>
                {sending && <PostSkeleton />}
                {
                    keys.map(id =>
                        <NewsItem
                            className={cnClassName('Item')}
                            key={id}
                            id={id}
                            virtualize={enableVirtual}
                            // advancedNodeKey={!viewTypesExluded.includes(viewType as any) ? viewType as ComponentProps<typeof NewsItem>['advancedNodeKey']: undefined}
                            ViewComponent={viewTypes[viewType]}
                        />
                    )
                }
            </div>

            {!isSingle &&
                <div className={cnClassName('More')}>
                    {isFailed && <>
                        <ErrorMsg type="error" className={cnClassName('ErrorMsg')}>
                            {i18n.t('pryaniky.timeline.error.message')}
                        </ErrorMsg>
                        <Button className={cnClassName('Trigger')} onClick={loadMore} disabled={isLoading}>
                            {i18n.t('pryaniky.timeline.error.reload.button')}
                        </Button>
                    </>}
                    {(!isFinished && !isFailed) && (
                        <LoadButton
                            forwardedRef={triggerRef}
                            isLoading={isLoading}
                            className={cnClassName('Trigger')}
                            onClick={isLoading ? void 0 : loadMore}>
                            {i18n.t('more')}
                        </LoadButton>
                    )}
                </div>
            }

        </ListPlaceholder>
    </div>;
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(Presenter)));