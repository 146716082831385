/**
 * @packageDocumentation
 * @module List_type_wiki
 */
import React, { FC, useState, useMemo, useLayoutEffect, useRef } from 'react';
import * as utils from 'utils/src/utils';
import { IHeaderWikiWithFiltersProps, cnHeaderWikiWithFilters } from './HeaderWikiWithFilters.index';
import './HeaderWikiWithFilters.scss';
import { i18n, Translate } from 'localization';
import { DialogWikiCreateNew } from 'blocks/Dialogs/WikiCreateNew/Create/WikiCreateNew';
import widgetsActions from 'redux/actionsTypes/Widgets';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from 'muicomponents/src/Button/Button'
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { useDebouncedQueryState, useDidUpdateEffect } from 'utils/src/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { PreListFilters } from '../PreListFilters/PreListFilters'
import { SplitButton } from 'muicomponents/src/SplitButton/SplitButton';
import { Breadcrumbs } from 'muicomponents/src/Breadcrumbs/Breadcrumbs';
import { Typography } from 'muicomponents/src/Typography/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Avatar, AvatarSize } from 'muicomponents/src/Avatar/Avatar'
import { ItemsListHeader } from 'muicomponents/src/ItemsListDialog/ItemsListHeader/ItemsListHeader'
import { SearchWithFiltersBox } from './HeaderWikiWithFilters.styled'
import { toast } from 'react-toastify';
import {
    Box,
} from 'muicomponents/src';
import { formatDateSTD } from 'utils/src/utils.dates';
import { getPagesTypes } from 'utils/src/requests/requests.wikilist';
import { PageTypeShort, PageTypes, useWikilistCreateTypesManager } from 'utils/src';
import { getCurrentPageType } from 'redux/actions/Widgets';
import { preapreCollectionAliasByContext } from '../List_type_wiki.utils';
import { useFileUploader } from 'utils/src/hooks'
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { NewsAttachV3WithPage } from 'utils/src';
import { getByIdSkipLayout } from 'utils/src/requests/requests.pages'
import { useLazyQueryEx } from 'utils/src/hooks'
import { useWikilistItemBaseImageByType } from '../Items/Items..hooks';
import { openDndDialog } from 'blocks/WIKIList/DropUploader/DropUploader';


async function getPage(id: string, collectionAlias?: string) {
    const response = await getByIdSkipLayout(id, { collection: collectionAlias ? collectionAlias : "dash" })
    return await response.r
}

const newContentTypes = Object.values(PageTypes);
// ['page', 'wikilist', 'url', 'file', 'wikilist.imageGallery', 'wikilist.videoGallery'];

const newContentCreateKey = (type: (typeof newContentTypes)[number], defaultValue?: string) => {
    const typeText = i18n.t(`pryaniky.wikilist.item.${type}.accusative`, { defaultValue });
    if (typeText === defaultValue) return defaultValue;
    return i18n.t(`pryaniky.wikilist.${type === "file" ? "upload" : "create"}.type`, { type: typeText.toLowerCase() });
}

const newContentCreate = (type: (typeof newContentTypes)[number], defaultValue?: string) => ({
    id: type,
    content: newContentCreateKey(type, defaultValue)
});

export const HeaderWikiWithFilters: FC<IHeaderWikiWithFiltersProps> = ({ context, hasRights, widget, isAdmin, layoutWikilist, dashFolderInfo, prependItems }) => {

    let collectionAlias = preapreCollectionAliasByContext(context);

    const pageType = useSelector(getCurrentPageType);
    const pageTypeRef = useRef(pageType);
    pageTypeRef.current = pageType;

    const userCanCreateNewContent = useMemo(() => {
        return isAdmin || context.isGroupAdmin || hasRights;
    }, [isAdmin, context.isGroupAdmin, hasRights]);

    const contentTypesRef = useRef<PageTypeShort[]>([]);

    const typesManagerParams = useMemo(() => ({ collectionAlias }), [collectionAlias]);
    const {
        types,
        loading: createDropdownOptionsLoading
    } = useWikilistCreateTypesManager(typesManagerParams);

    contentTypesRef.current = types || [];

    const createDropdownOptions = useMemo(() => {
        if (!types) return [];
        let options = types.map(el => newContentCreate(el.type, el.displayName));
        if (pageType === PageTypes.imageGallery || pageType === PageTypes.videoGallery) {
            options = options.filter(el => el.id === 'file');
        }
        return options;
    }, [types, pageType]);

    const filterContentType = useMemo(() => {
        return types?.map(el => ({ id: el.type })) || []
    }, [types]);

    const { isLoading: loadingPage, send: loadPage, result: pageData } = useLazyQueryEx(getPage)

    const [search, searchQuery, setSearch] = useDebouncedQueryState('search')
    const [searchValue, setSearchValue] = useState(searchQuery?.toString() || '');

    useDidUpdateEffect(() => {
        return setSearch(searchValue)
    }, [searchValue]);

    const dispatch = useDispatch()

    // ---------------------- Изменения типа оторажения списка----------------------------->

    const setWikiviewType = (el: any) => {
        window.localStorage.setItem('prn_local_state_wiki-view-type', JSON.stringify(el));
        dispatch({
            type: widgetsActions.UPDATE_CONTEXTS, payload: {
                [widget.id]: {
                    viewType: el
                }
            }
        });
    }

    let allowFileTypes: Parameters<typeof useFileUploader>['0']['allowTypes'];

    const resultRef = useRef<any[]>([])

    const {
        openSelectFile
    } = useFileUploader({
        allowTypes: (pageType as any) === PageTypes.imageGallery
            ? ['image/*']
            : (pageType as any) === PageTypes.videoGallery
                ? ['video/*']
                : allowFileTypes,
        getParams: {
            collectionAlias: collectionAlias || 'dash'
        },
        uploadOnAdd: true,
        maxFilesCount: 10,
        callbacks: {
            loadFinish: async function (item, resp) {
                const response = resp as any as IResponseWithData<NewsAttachV3WithPage[]>;
                resultRef.current.push({
                    ...response.data[0].page,
                    id: response.data[0].page.file!.id,
                    description: '',
                    collectionAlias,
                    viewers: pageData?.viewers
                });
                if (response?.error_code === 3001) {
                    toast.error(i18n.t(`pryaniky.file.upload.error.3001`));
                } else if (response?.error_code !== 0) {
                    toast.error(response?.error_text);
                } else {
                    toast.success(i18n.t(`pryaniky.file.upload.success`));
                    prependItems([{
                        ...response.data[0].page,
                        id: response.data[0].page.file!.id,
                        description: '',
                        collectionAlias,
                        viewers: pageData?.viewers
                    }])
                }

            },

        }
    })
    const onSelectAction = (action: string) => {

        const createType = contentTypesRef.current.find(el => el.type === action);
        // let allowFileTypes: Parameters<typeof DialogWikiCreateNew>['0']['allowFileTypes'];
        // if ((pageType as any) === PageTypes.imageGallery) {
        //     allowFileTypes = ['image/*']
        // }
        // if ((pageType as any) === PageTypes.videoGallery) {
        //     allowFileTypes = ['video/*']
        // }
        if (action === "file") {
            // openSelectFile()
            openDndDialog({
                openFileSelectOnMount: true,
                collectionAlias,
                allowTypes: (pageType as any) === PageTypes.imageGallery
                    ? ['image/*']
                    : (pageType as any) === PageTypes.videoGallery
                        ? ['video/*']
                        : allowFileTypes,
                // currentPageId
            }).then(({ items }) => {
                // console.log('items in header', items)
                prependItems(items)
            }).catch(() => {

            })
        } else {
            DialogWikiCreateNew({
                type: action,
                isAdmin: isAdmin,
                allowFileTypes,
                data: {
                    collectionAlias,
                    collections: (dashFolderInfo && dashFolderInfo?.length > 0) ? dashFolderInfo : [{
                        alias: collectionAlias,
                        defaultViewType: layoutWikilist?.defaultViewType,
                        description: layoutWikilist?.description,
                        id: layoutWikilist?.id,
                        imageId: layoutWikilist?.titleImageId,
                        imageUrl: layoutWikilist?.titleImageUrl,
                        name: layoutWikilist?.title ? layoutWikilist?.title : "Нет названия",
                    }],
                    pageType: createType
                },
                parentFolderViewers: layoutWikilist?.viewers || [],
                parentFolderModerators: layoutWikilist?.moderators || []
            }).then((data: any) => {
                data.type === "url" && dispatch({
                    type: widgetsActions.UPDATE_CONTEXTS, payload: {
                        [context.__parent || widget.id]: {
                            "order": "creationdate",
                            "sort": "desc"
                        }
                    }

                })
            }).catch(() => { })
        }
    }

    const isCollection = layoutWikilist && layoutWikilist.collections && layoutWikilist.collections.length > 0 || false;

    const BreadCrumbsComponent = useMemo(() => {
        if (!layoutWikilist?.breadCrumbs || !layoutWikilist.breadCrumbs.length) return null;
        return <Breadcrumbs
            maxItems={5}
            separator={<ChevronRight fontSize="small" />}
            sx={{ marginBottom: "20px", }}
            aria-label="breadcrumb">

            {layoutWikilist?.breadCrumbs?.map((item: any, idx: number) => <MuiButton
                sx={{ textTransform: "inherit", fontSize: "16px" }}
                disabled={idx === layoutWikilist.breadCrumbs.length - 1}
                href={item.alias}>
                {/* href={item.alias === "dash" ? "/wikilist" : "/wikilist/" + item.alias}> */}
                {item.alias === "/wikilist/dash" && item.name === "dash" ? i18n.t("pryaniky.list.wiki.dash") : item.name?.length > 0 ? item.name : layoutWikilist?.title}
            </MuiButton>)}

        </Breadcrumbs>
    }, [layoutWikilist])


    const headerActions = useMemo(() => {
        return <Box sx={{ display: "flex", gap: "24px" }}>
            <IconButton
                style={{ padding: "5px" }}
                onClick={() => setWikiviewType(0)}
                color={context.viewType === 0 ? "primary" : "default"}
            >
                <GridViewIcon style={{ fontSize: "30px" }} />
            </IconButton>

            <IconButton
                style={{ padding: "5px" }}
                onClick={() => setWikiviewType(1)}
                color={context.viewType === 1 ? "primary" : "default"}
            >
                <ListIcon style={{ fontSize: "30px" }} />
            </IconButton>
            {
                userCanCreateNewContent && <SplitButton
                    disabled={!createDropdownOptions[0]}
                    loading={createDropdownOptionsLoading}
                    buttonProps={{
                        onClick: () => {
                            onSelectAction(createDropdownOptions[0]?.id)
                        },
                        children: createDropdownOptions[0]?.content
                    }}
                    variant={"outlined"}
                    options={createDropdownOptions.length === 1 ? [] : createDropdownOptions}
                    handleMenuItemClick={(e, action) => onSelectAction(action.id)}
                />
            }
        </Box>

    }, [userCanCreateNewContent, createDropdownOptionsLoading, createDropdownOptions[0], context.viewType])

    const pageBaseImage = useWikilistItemBaseImageByType(layoutWikilist?.type || PageTypes.wikilist);

    return (<div className={cnHeaderWikiWithFilters("")}>
        {isCollection && BreadCrumbsComponent}
        {isCollection && !!layoutWikilist?.title && <Box sx={{ display: "flex", borderBottom: "1px solid #E0E0E0", paddingBottom: "20px", marginBottom: "20px" }}>
            <Avatar
                size={AvatarSize.xl}
                className={cnHeaderWikiWithFilters(`${layoutWikilist.titleImageId ? "WithAvatar" : "NoAvatar"}`)}
                src={layoutWikilist.titleImageId ? layoutWikilist.titleImageUrl : pageBaseImage}
                sx={{ marginRight: "16px" }}
            />
            <Box>
                <Typography variant="h5">{layoutWikilist.title}</Typography>
                <Typography variant={"body2"} color="rgba(0, 0, 0, 0.38)">
                    <Translate i18nKey='pryaniky.wikilist.item.created.new' values={{
                        creationDate: formatDateSTD(layoutWikilist?.creationDate, false),
                        changeDate: formatDateSTD(layoutWikilist?.changeDate, false)
                    }} />
                </Typography>
            </Box>
        </Box>}
        <SearchWithFiltersBox className={cnHeaderWikiWithFilters("Header")}>
            <ItemsListHeader
                searchQuery={searchValue || ""}
                changeSearchQuery={(value) => setSearchValue(value)}
                SearchActions={headerActions}
                bottomContent={
                    <PreListFilters
                        isCollection={isCollection}
                        hasRights={hasRights || layoutWikilist?.isEditable || false}
                        isAdmin={isAdmin || hasRights}
                        context={context}
                        contentTypes={filterContentType}
                        widget={widget}
                    />
                }
            />
        </SearchWithFiltersBox>
    </div>
    )
}

