import React, { FC, useState, useEffect } from "react";
import { i18n, Translate } from 'localization';
import { connect } from "react-redux";
import { cn } from "@bem-react/classname";
import {
  IWithQRLoginDialogDispatchProps,
  IWithQRLoginDialogStateProps,
  IWithQRLoginDialogOwnProps,
  IWithQRLoginDialogProps,
  mapWithQRLoginDialogDispatchToProps,
  withQRLogiMmapStateToProps
} from "./QRLogin.index";
import "./QRLogin.scss";
import * as utils from "utils/src/utils";
import { DialogMobileApp } from '../DialogWithQRMui'
import { Timer } from "./Timer/Timer";
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Button } from 'muicomponents/src/Button/Button'
export const cnQRLogin = cn("QRLogin");

const QRLoginPresenter:FC<IWithQRLoginDialogProps> = ({handleAccept, handleClose}) => {
  const [comment, setComment] = useState<string>('');
  const [utilsApi, setUtilsApi] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [isShownDownloadApp, setIsShownDownloadApp] = useState<boolean>(false);

  const getImageUrl = () => "/Img/QR/Icon/200x200/" + `${encodeURIComponent(utilsApi)}` + ".png";
  
  const newCode = () => {
    let promise = utils.API.mobile.pin();
    promise.r.then((d: any) => {
      if (!utils.checkResponseStatus(d)) {
        console.log('error newCode');
      } else {
        setUtilsApi(d.data);
      }
    })
  }

  useEffect(() => {
    newCode();
  }, []);

  const openModal = () => setIsShownDownloadApp(true);
  const closeModal = () => {
    setIsShownDownloadApp(false);
    handleClose();
  }
  
  return (
    <>
      <DialogTitle onClose={handleClose}>
        {i18n.t('pryaniky.user.qrcode.scan')}
      </DialogTitle>
      <DialogContent className={cnQRLogin("")}>
        <div className={cnQRLogin("Content")}>
          <div className={cnQRLogin("QrCode")}>
            <img src={getImageUrl()} alt="QR-code for" />
            <h1 className={cnQRLogin("Code")}>{utilsApi}</h1>
          </div>
          <div className={cnQRLogin("How-To")}>
            {i18n.t("pryaniky.widgets.mobileApp.login.text")}
            <Timer onClick={newCode} />
          </div>

          <div className={cnQRLogin("Warning")}>{i18n.t("pryaniky.widgets.mobileApp.login.warning")}</div>

          <Button sx={{ margin: '0 auto 15px' }} className={cnQRLogin("Submit")} onClick={() => {
            DialogMobileApp({}).then(() => { }).catch(() => { });
            handleClose()
          }}>
            {i18n.t("pryaniky.widgets.mobileApp.forget.to.download")}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export const QRLogin = connect<IWithQRLoginDialogStateProps, IWithQRLoginDialogOwnProps, IWithQRLoginDialogOwnProps>(
  withQRLogiMmapStateToProps,
  mapWithQRLoginDialogDispatchToProps
)(QRLoginPresenter)

export const DialogMobileAppLogin = createModal(QRLogin, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});