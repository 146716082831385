import { Box, Button, styled } from "muicomponents/src";

export const BlocksBox = styled(Box)({
    display: 'flex',
    gap: 16,
    gridGap: 16,
    marginTop: -6,
    marginBottom: -9,
}) as typeof Box;

export const ResetBox = styled('div')({
    marginLeft: 'auto',
});

export const ResetButton = styled(Button)({
    textTransform: 'none'
}) as typeof Button;