import React from 'react';
import { cnChangePassword, IChangePasswordData, IChangePasswordProps } from './ChangePassword.index';
import {
  // DialogTitle,
  // DialogActions,
  // DialogContent,
  ErrorMsg,
  Spinner
} from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import Input from 'uielements/src/MaterialElements/Input/Input';
import { InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import HelpOutline from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import './ChangePassword.scss';
import { toast } from 'react-toastify';
import { API, getCookie } from 'utils/src/utils';
import { validatePassword } from 'utils/src/passwords';
import { Progress } from '../../../LMSModule/components/Progress/Progress';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'

export const ChangePasswordPresenter: React.FC<IChangePasswordProps> = ({
  currentUser,
  userId,
  handleAccept,
  handleClose
}) => {

  let isReset = currentUser.baseData.isAdmin ? (userId !== currentUser.baseData.id) : false;

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [conPassword, setConPassword] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [request, setRequest] = React.useState(false);
  const [strengthPass, setStrengthPass] = React.useState(validatePassword(''));

  const [showPass, setShowPass] = React.useState<(keyof IChangePasswordData)[]>([]);

  const changeShowPass = (type: keyof IChangePasswordData) => showPass.includes(type) ? setShowPass(showPass.filter((e) => e !== type)) : setShowPass([...showPass, type]);

  const handleMouseDownPassword: React.MouseEventHandler<HTMLButtonElement> = (e) => e.preventDefault();

  const changePwd = () => {
    setRequest(true);
    API.system.pwdChange({ oldPassword, newPassword, auth: getCookie('authAt') }).r.then((d: any) => {
      if (d.error_code === 0) {
        toast.success(Translate.t({ i18nKey: 'pryaniky.changepassword.error.' + d.error_code }));
        handleClose();
      } else {
        setErrorMsg(Translate.t({ i18nKey: 'pryaniky.changepassword.error.' + d.error_code }));
      }
      setRequest(false);
    })
  }

  const resetPwd = () => {
    setRequest(true);
    API.system.pwdReset({ actUserId: userId, newPassword, auth: getCookie('authAt') }).r.then((d: any) => {
      if (d.error_code === 0) {
        toast.success(Translate.t({ i18nKey: 'pryaniky.changepassword.error.' + d.error_code }));
        handleClose();
      } else {
        setErrorMsg(Translate.t({ i18nKey: 'pryaniky.changepassword.error.' + d.error_code }));
      }
      setRequest(false);
    })
  }

  const save = () => {
    isReset ? resetPwd() : changePwd();
  }

  const strength = strengthPass.score === 0 ? 'null' : strengthPass.score <= 2 ? 'min' : strengthPass.score <= 4 ? 'medium' : 'max'

  return (
    <div>
      <DialogTitle onClose={handleClose}>
        {/* <h4 className="my-1"> */}
          <Translate i18nKey={`pryaniky.changepassword`} />
        {/* </h4> */}
      </DialogTitle>
      <DialogContent className={cnChangePassword('')}>
        <div className={cnChangePassword('Body')}>
          {request && <Spinner fontSize={40} />}
          {errorMsg !== '' && <ErrorMsg className={cnChangePassword('Msg')}>{errorMsg}</ErrorMsg>}
          <form>
            {
              !isReset &&
              <Input
                type={showPass.includes('oldPassword') ? 'text' : 'password'}
                value={oldPassword}
                onChange={(oldPassword) => setOldPassword(oldPassword)}
                placeholder={Translate.t({ i18nKey: 'pryaniky.dialog.changepassword.oldPassword' })}
                InputProps={{
                  endAdornment: <InputAdornment position="end" >
                    <IconButton
                      onClick={() => changeShowPass('oldPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPass.includes('oldPassword') ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            }
            <Input
              type={showPass.includes('newPassword') ? 'text' : 'password'}
              value={newPassword}
              // autoComplete='off'
              onChange={(newPassword) => {
                setNewPassword(newPassword)
                setStrengthPass(validatePassword(newPassword));
              }}
              placeholder={Translate.t({ i18nKey: 'pryaniky.dialog.changepassword.newPassword' })}
              InputProps={{
                endAdornment: <InputAdornment position="end" >
                  <IconButton
                    onClick={() => changeShowPass('newPassword')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPass.includes('newPassword') ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
              inputProps={{
                autocomplete: "off",
              }}
            />
            <div className={cnChangePassword('Strength', { view: newPassword.length !== 0 })}>
              <Progress className={cnChangePassword('StrengthProgress', { strength })} currentStep={strengthPass.score} totalSteps={strengthPass.max} />
              <div className={cnChangePassword('Strength-Info')}>
                <Translate i18nKey={`pryaniky.dialog.changepassword.strength.${strength}`} />
                &nbsp;
                <Tooltip className={'InfoIcon'} placement="top" title={<Translate i18nKey={'pryaniky.dialog.changepassword.validate.info'} />}>
                  <HelpOutline />
                </Tooltip>
              </div>
            </div>
            <Input
              type={showPass.includes('conPassword') ? 'text' : 'password'}
              value={conPassword}
              // autoComplete='off'
              onChange={(conPassword) => setConPassword(conPassword)}
              placeholder={Translate.t({ i18nKey: 'pryaniky.dialog.changepassword.newPassword.confirm' })}
              InputProps={{
                endAdornment: <InputAdornment position="end" >
                  <IconButton
                    onClick={() => changeShowPass('conPassword')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPass.includes('conPassword') ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }}
              inputProps={{
                autocomplete: "off",
              }}
            />
          </form>
        </div>
      </DialogContent>
      <DialogActions
        acceptText={i18n.t('save')}
        closeText={i18n.t('pryaniky.shop.cancel')}
        disableAccept={strengthPass.score === 0 || newPassword !== conPassword}
        onAccept={save}
        onClose={handleClose} />
      {/* <DialogActions className={cnChangePassword('Footer')}>
        <Button disable={strengthPass.score === 0 || newPassword !== conPassword} color='primary' variant={'contained'} onClick={save}>
          <Translate i18nKey='save' />
        </Button>
        <Button color='primary' onClick={handleClose}>
          <Translate i18nKey='cancel' />
        </Button>
      </DialogActions> */}
    </div>
  )
}

export const changePasswordDialog = createModal(ChangePasswordPresenter, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: "#fff",
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});