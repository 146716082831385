import { IEventInfoBoxProps } from './EventInfo.index'
import React, { memo } from 'react'
import './EventInfo.scss';
import { i18n, Translate } from 'localization';
import { makeDate, makeDuration } from '../Utils'

export const EventInfo: React.FC<IEventInfoBoxProps> = memo(({
    startDateTime,
    eventLocation,
    duration,
    avaliablePlaces,
    ...props
}) => {

    // return <div className={'EventInfo'} {...props}>
    return <div className="EventInfo">
        <div className="EventInfo-InfoItem">
            <span className="EventInfo-InfoTitle">{i18n.t('date')}:&nbsp;</span>
            <span>{makeDate.date(startDateTime)}</span>
        </div>
        {duration !== 1440 && <div className="EventInfo-InfoItem">
            <span className="EventInfo-InfoTitle">{i18n.t('time')}:&nbsp;</span>
            <span>{makeDate.time(startDateTime)}</span>
        </div>}
        {eventLocation && <div className="EventInfo-InfoItem">
            <span className="EventInfo-InfoTitle">{i18n.t('location')}:&nbsp;</span>
            <span>{eventLocation}</span>
        </div>}
        <div className="EventInfo-InfoItem">
            <span className="EventInfo-InfoTitle">{i18n.t('duration')}:&nbsp;</span>
            <span>{makeDuration(duration)}</span>
        </div>
        {
            typeof avaliablePlaces === 'number' &&
            <div className="EventInfo-InfoItem">
                <span className="EventInfo-InfoTitle">{i18n.t('avaliablePlaces')}:&nbsp;</span>
                <span>{avaliablePlaces}</span>
            </div>
        }
    </div>
    // </div>
})