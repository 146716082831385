import { connect } from 'react-redux';
import { stateToProps, mapDispatchToProps } from 'redux/connector';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';
import { cnLogin, ILoginProps, ILoginState } from './Login.index';
import { Input } from 'uielements/src/Input/Input';
import { Button, CheckboxInput } from 'uielements/src';

import './Login.scss';

import { Spinner } from 'uielements/src';

const st = '/sites/'; // sites in url

const chatIntegrationUrl = 'ChatIntegration';

export class LoginPresenter extends React.Component<ILoginProps, ILoginState> {
  public utils = utils;
  public el: HTMLElement | null;
  public companyNameInUrl: boolean = false;

  constructor(props: ILoginProps) {
    super(props);
    const data = this.utils.searchObject(this.props.location.search);
    let companyName = '';
    let login = '';
    if (data.companyName) companyName = (data.companyName as string).trim();
    if (data.login) login = (data.login).trim();

    const stIdx = window.location.pathname.indexOf(st); // find sites in url

    if (stIdx >= 0) {
      this.companyNameInUrl = true;
      companyName = window.location.pathname.substring(stIdx + st.length, window.location.pathname.indexOf('/', stIdx + st.length));
      this.props.changeVm('appDesign.backgroundUrl', utils.API.background.getOld().replace('.app', ''));
    } else
    // if (utils.getCookie('companyName')) {
    //   this.companyNameInUrl = true;
    //   companyName = utils.getCookie('companyName');
    //   this.props.changeVm('appDesign.backgroundUrl', utils.API.background.getOld().replace('.app', ''));
    // } else
    if (window.location.hostname.split('.').length === 4 || window.location.hostname.search(/\d\.\d\.\d\.\d/) !== -1) {
      this.companyNameInUrl = true;
      companyName = window.location.hostname.split('.')[0];
      this.props.changeVm('appDesign.backgroundUrl', utils.API.background.getOld().replace('.app', ''));
    }
    if (window.location.hostname.indexOf('app.space.dns-shop') !== -1) companyName = 'dns';
    if (window.location.hostname.startsWith('192.168')) {
      companyName = '';
      this.companyNameInUrl = false;
      // utils.setCookie({ name: 'companyName', value: companyName});
      // document.cookie = `companyName=${companyName};path=/;`;
    }
    this.state = {
      request: false,
      rememberMe: false,
      companyName,
      login,
      password: '',
      errorMessage: '',
    };
  }

  public componentDidMount() {
    if (!utils.isTestServer() && utils.isAuthInQueryString()) {
      const data = window.location.search.split('&');
      const [companyName, authUid, authAt] = data[0].split('=')[1].split(';');
      const expires = this.expirationDate;
      // utils.setCookie({ name: 'companyName', value: companyName, props: { domain: `${companyName}.app.pryaniky.com`, expires } });
      utils.setCookie({ name: 'authUid', value: authUid, props: { domain: `${companyName}.app.pryaniky.com`, expires } });
      utils.setCookie({ name: 'authAt', value: authAt, props: { domain: `${companyName}.app.pryaniky.com`, expires } });
      utils.setCookie({ name: data[1].split('=')[0], value: data[1].split('=')[1], props: { domain: `${companyName}.app.pryaniky.com`, expires } });

      // document.cookie = `companyName=${companyName};domain=${companyName}.app.pryaniky.com;path=/;${expires}`;
      // document.cookie = `authUid=${authUid};domain=${companyName}.app.pryaniky.com;path=/;${expires}`;
      // document.cookie = `authAt=${authAt};domain=${companyName}.app.pryaniky.com;path=/;${expires}`;
      // document.cookie = `${data[1]};domain=${companyName}.app.pryaniky.com;path=/;${expires}`;
      window.location.assign('//' + companyName + '.app.pryaniky.com');
    }
  }

  public onBlur = () => {
    if (this.state.companyName !== '') { 
      utils.setCookie({ name: 'DEV_companyName', value: this.state.companyName });
      // document.cookie = `DEV_companyName=${this.state.companyName};path=/;`;
      fetch(utils.API.background.getOld().replace('.app', ''))
        .then(r => r.blob())
        .then(d => {
          const { size } = d;
          if (size > 500) {
            this.props.changeVm('appDesign.backgroundUrl', URL.createObjectURL(d));
          }
        });
    }    
  }

  public login = () => {
    this.setState({ request: true, errorMessage: '' });
    const expires = this.expirationDate;
    // document.cookie = 'companyName=' + this.state.companyName + '; path=/;';
    if (
      window.location.host.indexOf('localhost') !== -1 ||
      window.location.host.indexOf('192.168') !== -1 ||
      window.location.hostname.indexOf('azurewebsites.net') === -1
      ) utils.setCookie({ name: 'DEV_companyName', value: this.state.companyName, props: { expires } });
      // document.cookie = 'DEV_companyName=' + this.state.companyName + '; path=/;' + expires;
    document.body.classList.add(this.state.companyName.toLowerCase());
    const data = {
      siteName: this.state.companyName,
      userName: this.state.login,
      password: this.state.password,
    };
    // this.request(true);s
    if (window.location.pathname.indexOf(chatIntegrationUrl) !== -1) {
      this.utils.API.system.login(data).r.then((d: any) => {
        if (d && d.error_code === 0 && d.token !== null && d.userInfo !== null) {
          utils.API.chat.getToken({}).r.then((chatToken: any) => {
            if (chatToken.error_code === 0) {
              window.top?.postMessage({
                event: 'login-with-token',
                loginToken: chatToken.data
              }, '*');
            }
          });
        }
      });
    } else {
      this.utils.API.system.login(data).r.then((d: any) => {
        if (d && d.error_code === 0 && d.token !== null && d.userInfo !== null) {
          utils.setCookie({ name: 'prn_realuser', value: d.token.user_id, props: { expires } });
          // document.cookie = 'prn_realuser=' + d.token.user_id + '; path=/;' + expires;

          const { authAt, authUid } = utils.getAuthCookies();
          if (!authAt) utils.setCookie({ name: 'authAt', value: d.token.access_token, props: { domain: window.location.host, expires } });
          // document.cookie = 'authAt=' + d.token.access_token + '; domain=' + window.location.host + '; path=/;' + expires;
          if (!authUid) utils.setCookie({ name: 'authUid', value: d.token.user_id, props: { domain: window.location.host, expires } });
          // document.cookie = 'authUid=' + d.token.user_id + '; domain=' + window.location.host + '; path=/;' + expires;
          
          // document.cookie = `${d.token.cookieName}=${d.token.cookieToken};path=/;${expires}`;
          // document.cookie = `${d.token.cookieName}=${d.token.cookieToken}; domain=${d.userInfo.baseNetwork.name}.pryaniky.com ;path=/;${expires}`;
          // document.cookie = `${d.token.cookieName}=${d.token.cookieToken}; domain=${d.userInfo.baseNetwork.name}.app.pryaniky.com ;path=/;${expires}`;
          utils.setCookie({ name: 'authDisplayName', value: d.userInfo.displayName, props: { expires } });
          utils.setCookie({ name: 'authImgId', value: d.userInfo.imgId, props: { expires } });
          // document.cookie = 'authDisplayName=' + d.userInfo.displayName + '; path=/;' + expires;
          // document.cookie = 'authImgId=' + d.userInfo.imgId + '; path=/;' + expires;
          // if (!utils.isTestServer() && window.location.hostname !== this.state.companyName + '.app.pryaniky.com') {
          //   utils.unsetCookies();
          //   document.cookie = d.token.cookieName + '=; domain=' + this.state.companyName + '.app.pryaniky.com; path=/;';
          // }
          window.location.reload();
          // this.props.setBaseData();
        } else {
          // document.cookie = 'companyName=; path=/; expires=' + new Date(0);
          this.setState({ request: false, errorMessage: i18n.t('pryaniky.login.error') });
        }
      });
    }
  };

  public render() {
    const { companyNameInUrl, props } = this;
    const { className = '' } = props;
    return (
      <div ref={el => (this.el = el)} className={cnLogin({}, [className])}>
        <div className={cnLogin('Wrapper')}>
          {this.state.request && <Spinner className={cnLogin('Spinner')} fontSize={40} />}
          <form className={cnLogin('Form')} onSubmit={this.handleSubmit}>
            <div className={cnLogin('Form-Text')} id={'LOGINWELCOMETEXT'}>
              <b>{i18n.t('pryaniky.login.welcome')}</b>
              <br />
              {i18n.t('to log in, please enter you credentials')}
              {this.state.errorMessage && <div className={cnLogin('ErrorMessage')}>{this.state.errorMessage}</div>}
            </div>
            {
              !companyNameInUrl &&
              <Input
                id={'LOGINCOMPANYNAME'}
                icon="passport"
                onChange={this.handleChange}
                name="companyName"
                value={this.state.companyName}
                placeholder={i18n.t('pryaniky.login.companyName')}
                onBlur={this.onBlur}
              />
            }
            <Input
              id={'LOGINUSERNAME'}
              icon="user"
              onChange={this.handleChange}
              name="login"
              value={this.state.login}
              placeholder={i18n.t('pryaniky.login.userName')}
            />
            <Input
              id={'LOGINPASSWORD'}
              icon="key"
              onChange={this.handleChange}
              type="password"
              name="password"
              value={this.state.password}
              placeholder={i18n.t('pryaniky.login.password')}
            />
            <CheckboxInput
              id={'LOGINREMEMBERME'}
              onChange={this.handleChange}
              name="rememberMe"
              checked={this.state.rememberMe}
              text={i18n.t('remember me')}
            />
            <div className={cnLogin('Form-Actions')}>
              <Button id={'LOGINENTERBUTTON'} main children={i18n.t('pryaniky.login.login')} />
              <Button
                id={'LOGINRECOVERYPASSWORD '}
                type="rlink"
                href="/forgot"
                noBorder
                noBackground
                children={i18n.t('pryaniky.login.restorePassword')}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  private get expirationDate(): string {
    if (!this.state.rememberMe) return '';
    const date = new Date();
    date.setFullYear(date.getFullYear() + 2);
    return date.toUTCString();
  }

  private get allFieldsFilled(): boolean {
    const { companyName, login, password } = this.state;
    return companyName.length > 0 && login.length > 0 && password.length > 0;
  }

  private handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (this.allFieldsFilled) {
      this.login();
    } else {
      this.setState({ errorMessage: i18n.t('pryaniky.login.notFilled') });
    }
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    if (type === 'checkbox') {
      const state: { [s: string]: any } = {};
      state[name] = checked;
      this.setState(state);
    } else {
      const state: { [s: string]: any } = {};
      state[name] = value.trim();
      this.setState(state);
    }
  };
}

export const Login = connect(
  stateToProps, 
  mapDispatchToProps({})
  )(LoginPresenter);

// compose(withBemMod(cnLogin(), {}))(LoginPresenter));
