import {
  EditorState,
  RawDraftContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { draftToMarkdown } from "uielements/src/PryanikyEditorV1/converter/draft-to-markdown";
import { rawToMd } from "blocks/PryanikyEditor/convertorConfigs";
import React, {
  FC,
  useEffect,
  useMemo,
  useState,
  memo,
  useCallback,
  useRef,
} from "react";
import { findMentions } from "uielements/src/PryanikyEditorV1/MentionSelectPlugin/modifiers/findMentions";
import { addMultiMention } from "uielements/src/PryanikyEditorV1/MentionPlugin/modifiers/addMention";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
import FieldRender, {
  IAdditionalField,
  prepareFromServer,
  prepareToServer,
  IField,
  changeField,
  getFieldById,
} from "uielements/src/FieldRender";
import { IBaseNews, ICreateBaseNews } from "../types/baseNews";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  sendNews,
  pathCreatorNews,
  loadNewsById,
  loadNewsList,
  changeStatusField,
  pathCreator,
} from "../redux/actions";
import {
  getCreatorParams,
  getCreatorNewsParams,
  getNewsById,
  getReadingState,
} from "../redux/saga/selectors";
import actTypes from "../redux/actionsTypes/News";
import uuid from "uuid/v1";
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { changeUsersGroups, getFieldsValues } from "./utils";
import { Validator, Rule, TRules } from "../validation";
import { useIsLoading, useIsError } from "utils/src/CommonRedux/LoadIndicator";
import queryString from "query-string";
export { useDraftState } from "uielements/src/PryanikyEditorV1/hooks/useDraftState";
import { INewsListRequestOpts } from "utils/src/requests/models/api.news";
import { omit } from "lodash";

import { useDraftState } from "muicomponents/src/DraftComponents/utils/useDraftState";
import { useDebounce, useDidUpdateEffect } from "utils/src/hooks";

export const useTimelineLoader = (
  params: INewsListRequestOpts,
  keys: string[],
  customCount?: number,
  disableSetGroup?: boolean,
  isSearchPage?: boolean
) => {
  const dispatch = useDispatch();
  const group = omit(params, "count"); //useMemo(() => queryString.stringify(omit(params, 'count')), [params])

  const pid = actTypes.LOAD_NEWS + "-" + queryString.stringify(group);

  const { count } = useSelector(getReadingState(group), shallowEqual);
  // console.log('useTimelineLoader', disableSetGroup, pid)
  useEffect(() => {
    if (customCount && customCount !== count)
      dispatch(
        changeStatusField({ group, field: "count", value: customCount })
      );
    // dispatch(changeStatusField({ group, field: 'isFinished', value: false }))
  }, [customCount, pid]);

  const currentCount =
    customCount && customCount !== count ? customCount : count;

  const isLoading = useIsLoading([pid]);

  const { isFailed } = useIsError([pid]);

  const loadMore = useCallback(
    () =>
      dispatch(
        loadNewsList({
          params,
          count: currentCount,
          skipCount: keys.length,
          disableSetGroup,
          isSearchPage: isSearchPage,
        })
      ),
    [params, count, keys.length, isLoading, isSearchPage]
  );

  useEffect(() => {
    dispatch(
      loadNewsList({
        params,
        count: currentCount,
        skipCount: 0,
        isSearchPage: isSearchPage,
        disableSetGroup,
      })
    );
    // dispatch(loadNewsList({ params, count: currentCount, skipCount: 0, disableSetGroup }));
  }, [pid]);

  return {
    isLoading,
    isFailed,
    loadMore,
  };
};

export const useAddMention = (
  state: EditorState,
  setState: (state: EditorState) => void
) => {
  const stateRef = useRef(state);
  const setStateRef = useRef(setState);
  stateRef.current = state;
  setStateRef.current = setState;

  const onMention = useCallback((user: any) => {
    if (user) {
      setStateRef.current(
        addMultiMention(
          stateRef.current,
          {
            name: user?.displayName,
            link: "/user/" + user.id,
            avatar: user.imgUrl || user.userPhotoUrl,
          },
          "",
          "",
          "SEGMENTED"
        )
      );
    }
  }, []);
  return [onMention] as const;
};

export const useFindMention = (
  state: EditorState,
  setState: (state: EditorState) => void
) => {
  const [search, setSearch] = useState(false);
  const stateRef = useRef(state);
  const searchRef = useRef(search);
  const setStateRef = useRef(setState);
  stateRef.current = state;
  searchRef.current = search;
  setStateRef.current = setState;
  const onFind = useCallback(() => {
    if (searchRef.current) return;
    setSearch(true);

    findMentions(stateRef.current, (state) => {
      setStateRef.current(state);
      setSearch(false);
    });
  }, []);
  return [onFind, search] as const;
};

export function useFieldFindMention(
  fields: IAdditionalField[],
  onChange: (fields: IAdditionalField[]) => void
) {
  const [search, setSearch] = useState(false);
  const searchRef = useRef(search);
  searchRef.current = search;
  const onFind = useCallback(
    (f: IField<EditorState>) => () => {
      if (searchRef.current) return;
      setSearch(true);
      const field = getFieldById<EditorState>(fields, f.id);
      if (!field) return;
      findMentions(field.data, (data) => {
        onChange(changeField(fields, { ...field, data }));
        setSearch(false);
      });
    },
    []
  );
  return [onFind, search] as const;
}

// export const useDraftState = (rawState?: RawDraftContentState) => {
//     const [state, setState] = useState<EditorState>(() => rawState ? EditorState.createWithContent(convertFromRaw(rawState)) : EditorState.createEmpty())
//     const stateRef = useRef(state)
//     stateRef.current = state
//     return [
//         state,
//         setState,
//         stateRef
//     ] as const
// }

export function useChangeField<T extends IBaseNews = IBaseNews>(
  news: T,
  changed?: (news: T) => void
) {
  const dispatch = useDispatch();
  const newsRef = useRef(news);
  newsRef.current = news;

  let data = {
    ...news,
  };

  const dataRef = useRef(data);
  dataRef.current = data;

  const onChange = useCallback(
    (fields: IAdditionalField[]) => {
      dataRef.current = {
        ...newsRef.current,
        additionalFields: {
          ...newsRef.current.additionalFields,
          additionalFieldsValues: fields,
        },
      };
      dispatch(pathCreatorNews(dataRef.current));
      changed && changed(dataRef.current);
    },
    [changed]
  );

  return [onChange, dataRef] as const;
}

export function useSubmit<
  T extends IBaseNews & ICreateBaseNews = IBaseNews & ICreateBaseNews
>(news: T, filter: (news: T) => T = (news) => news) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser, shallowEqual);
  const newsRef = useRef(news);
  newsRef.current = news;
  const onSubmit = useCallback(() => {
    const text = draftToMarkdown(
      convertToRaw(newsRef.current.draftState.getCurrentContent()),
      rawToMd
    );
    const data = filter({
      ...newsRef.current,
      id: uuid(),
      text,
      additionalFields: {
        ...news.additionalFields,
        additionalFieldsValues: prepareToServer(
          newsRef.current.additionalFields?.additionalFieldsValues || []
        ),
      },
      user: {
        ...currentUser.baseData,
      },
    });
    dispatch(sendNews(data));
  }, []);

  return [onSubmit] as const;
}

export const useEditDraft = () => {
  const dispatch = useDispatch();
  const { draftState } = useSelector(
    getCreatorNewsParams<IBaseNews & ICreateBaseNews>("draftState"),
    shallowEqual
  );
  const onChange = useCallback((state: EditorState) => {
    dispatch(
      pathCreatorNews({
        draftState: state,
      })
    );
  }, []);
  return [onChange, draftState] as const;
};

export function useCreateModel<T extends IBaseNews = IBaseNews>(
  defaultModel: T,
  postType: any
) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      pathCreatorNews({
        ...defaultModel,
        additionalFields: {
          ...defaultModel.additionalFields,
          additionalFieldsValues: prepareFromServer(postType.additionalFields),
        },
        draftState: EditorState.createEmpty(),
      })
    );
  }, [postType]);
}

export type TEditOption<T> = {
  filter?: (news: T & ICreateBaseNews) => T & ICreateBaseNews;
  fieldChanged?: (news: T & ICreateBaseNews) => void;
  validationRules?: TRules;
};
export function useBaseEdits<T extends IBaseNews = IBaseNews>(
  defaultModel: T,
  { filter, fieldChanged, validationRules = {} }: TEditOption<T> = {}
) {
  const postType = useSelector(
    getAllowPostTypeById(defaultModel.newstype),
    shallowEqual
  );

  useCreateModel(defaultModel, postType);

  const { currentNews } = useSelector(
    getCreatorParams<T>("currentType", "currentNews"),
    shallowEqual
  );

  const [setDraft, draftState] = useEditDraft();
  const [onMention] = useAddMention(draftState, setDraft);
  const [onFind] = useFindMention(draftState, setDraft);
  const [onSubmit] = useSubmit(currentNews, filter);
  const [onChangeFields] = useChangeField(currentNews, fieldChanged);

  const [isSuccess, errors] = useValidation(currentNews, validationRules);

  const fieldsValues = getFieldsValues(currentNews);
  return {
    setDraft,
    draftState,
    onMention,
    onFind,
    onChangeFields,
    fieldsValues,
    onSubmit,
    currentNews,
    validation: {
      isSuccess,
      errors,
    },
  } as const;
}

export function useChangeUsersGroups<
  T extends IBaseNews & ICreateBaseNews = IBaseNews & ICreateBaseNews
>(news: T) {
  const dispatch = useDispatch();
  const newsRef = useRef(news);
  newsRef.current = news;
  const onChange = useCallback((selected: any[]) => {
    newsRef.current = changeUsersGroups(selected, newsRef.current);
    dispatch(pathCreatorNews(newsRef.current));
  }, []);
  const usersgroups = useMemo(
    () => [...(newsRef.current.users || []), ...(newsRef.current.groups || [])],
    [newsRef.current.groups, newsRef.current.users]
  );
  return [usersgroups, onChange] as const;
}

export function useValidation<
  T extends IBaseNews & ICreateBaseNews = IBaseNews & ICreateBaseNews
>(news: T, rules: TRules) {
  const validator = useMemo(() => {
    const validator = new Validator<T>();
    validator.setRules(rules);
    return validator;
  }, [rules]);

  validator.addData(news);
  const errors = validator.validation();
  return [errors.length === 0, errors] as const;
}

export function useLazyLoadNewsById<T extends IBaseNews = IBaseNews>(
  id: string = "0",
  network?: string
) {
  const dispatch = useDispatch();
  const news = useSelector<any, T>(getNewsById(id), shallowEqual);
  const action = loadNewsById({ id, network });
  const isLoading = useIsLoading([action.type]);
  const { errors, isFailed } = useIsError([action.type]);

  const onLoad = () => {
    dispatch(action);
  };

  return [news, isLoading, onLoad, { errors, isFailed }] as const;
}

export function useLoadNewsById<T extends IBaseNews = IBaseNews>(
  id: string = "0",
  network?: string
) {
  const [news, isLoading, onLoad, { errors, isFailed }] =
    useLazyLoadNewsById<T>(id, network);

  useEffect(() => {
    if (id && id !== "0") onLoad();
  }, [id]);

  return [news, isLoading, { errors, isFailed }] as const;
}

/**
 * ignoring first count times change in inputs
 * @deprecated
 * @param fn
 * @param inputs
 * @param count
 * @returns
 */
export const useOnTextChange = <T extends any[]>(
  fn: (...args: T) => void,
  inputs: any[],
  count: number = 1
) => {
  const ref = useRef(0);
  useEffect(() => {
    if (ref.current < count) {
      ref.current++;
    }
  }, inputs);
  if (ref.current === count) return fn;
};
