import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { i18n, Translate } from 'localization';
import { IShopFilter } from 'utils/src/requests/models/api.shop';
import { mapStateToProps, mapDispatchToProps, cnHeader, IShopHeaderProps, IShopFilterPresent } from './Shop-Header.index';
import './Shop-Header.scss';
import { SearchInput, Avatar } from 'uielements/src';
import { ShopPurse } from '../ShopPurse.svg';
import { CheckboxInput } from 'uielements/src';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import RadioGroup from 'uielements/src/MaterialElements/RadioGroup';
import { useDidUpdateEffect } from 'utils/src/hooks';

const all = {
  id: -1,
  count: -1,
  name: Translate.t({ i18nKey: 'pryaniky.filter.shop.categories.all' })
}

const preparedFilters = (data: IShopFilter[]) => data.map((el) => ({
  title: el.count !== -1 ? el.name + " (" + el.count + ")" : el.name,
  value: el.id.toString()
}));

export function HeaderPresenter({
  tag: TagName = 'div',
  className = '',
  searchFunction,
  placeholder,
  myThanksCount,
  filters,
  onFilter,
  match,
  location,
  history


  // changeContextv3
}: IShopHeaderProps) {

  const [activeFilter, setActiveFilter] = useState<number>(all.id);


  useEffect(() => {
    const filter = queryString.parse(location.search)?.filter
    if (typeof filter === 'string' || typeof filter === 'number') setActiveFilter(parseInt(filter));
  }, [])

  useDidUpdateEffect(() => {    
      activeFilter !== -1 ? onFilter(activeFilter) : onFilter("")
  }, [activeFilter])



  return (
    <TagName className={cnHeader({}, [className])}>
      <div className={cnHeader('Search', [!filters?.length && 'rounded'])}>
        <SearchInput onChange={searchFunction} placeholder={placeholder} />
        <div className={cnHeader('Search-Currency')}>
          <ShopPurse size={30} padding={6} className={cnHeader('Search-Wallet')} />
          <div className={cnHeader('Search-CurrencyText')}>
            {i18n.t('pryaniky.list.shop.header.available')}
            <b className={cnHeader('Search-CurrencyValue')}>{myThanksCount}</b>
          </div>
        </div>
      </div>
      {filters && !!filters.length &&
        <div className={cnHeader('Categories')}>
          {/* <span>{i18n.t('pryaniky.list.shop.header.catigories')}</span> */}
          <RadioGroup
            children={<span className={cnHeader('Categories-Title')}>{i18n.t('pryaniky.list.shop.header.catigories')}</span>}
            // label={i18n.t('pryaniky.list.shop.header.catigories')}
            items={preparedFilters([all, ...filters])}
            onChange={(selectedEl: any) => setActiveFilter(Number(selectedEl))}
            value={activeFilter.toString()}
            size='small'
          />
        </div>}

      {/* {filters && !!filters.length &&
        <div className={cnHeader('Categories')}> 
        <span>{i18n.t('pryaniky.list.shop.header.catigories')}</span>
        {filters.map((filter) => {
          return (
            <CheckboxInput
              checked={activeFilter === filter.id}
              text={`${filter.name} (${filter.count !== 0 && filter.count})`}
              onChange={() => filter.id !== activeFilter ? setActiveFilter(filter.id) : setActiveFilter(undefined)}
            />
          )
        })}
      </div>
      } */}

    </TagName>
  );
}

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderPresenter));
