/**
 * @packageDocumentation
 * @module List_type_users_search
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from '../../../../redux/connector';

import { mapStateToProps, IListStateProps, IListProps, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListTypeUsersSearchProps, IDefaultRequestOption, IListUsersSearchDefaultRequestProps, avalibleFilters } from './List_type_users_search.index';

import './List_type_users.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { ListLite, Avatar, Button, Loading } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Item } from 'blocks/List/Item/List-Item';
import { IStateType as IState } from 'redux/store';
import { TypedListPlaceholder } from 'uielements/src';

export class ListUsersSearchPresenter extends AbstractList<IListTypeUsersSearchProps, IListState> {
  public static defaultProps = {
    requestOptions: {
      rid: '',
      search: '',
      catFilter: '',
      extended: true,
      count: 20,
      skipCount: 0,
      network: '',
    },
  };

  // public type = 'users';
  public componentDidMount() {
    this.mounted = true;
    this.getData();
  }

  constructor(props: IListTypeUsersSearchProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }

  public search = (text: string) => {
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false
      }
    });
    this.getData();
  };

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getUsers, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading || !this.mounted) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
    });

    if (this.searchString) opts.search = this.searchString;
    if (clearData) {
      clearDataFromList({ id, data: null });
    }
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    getUsers.call(this, id, opts);
  };

  public componentDidUpdate(pp: IListTypeUsersSearchProps) {
    const { context: c, toggle, isLoading } = this.props;
    const { context: pc } = pp;
    if (
      Object.keys(avalibleFilters).filter((fk) => c[fk] !== pc[fk] ? true : false).length
    ) {
      toggle({
        id: this.listId,
        data: {
          hideData: true,
          clearData: true,
          isFinished: false,
        }
      });
      setTimeout(() => this.getData(), 10);
    }
  }

  public prepareData(el: any) {
    el.view_data = {
      avatar: <Button theme='unstyled' type="rlink" href={'/user/' + el.id}>
        <Avatar imgUrl={el.imgUrl} imgId={el.imgId} size={56} name={el.displayName} />
      </Button>,
      info: [
        [
          <Button
            className="UserName"
            key={0}
            noBorder
            noPadding
            noBackground
            type="rlink"
            href={'/user/' + el.id}
            children={el.displayName}
          />,
          el.position,
          el.division,
        ],
        [
          el.eMail
            ? [
              <Button
                key={1}
                noBorder
                noPadding
                noBackground
                type="link"
                href={'mailto:' + el.eMail}
                children={el.eMail}
              />,
            ]
            : null,
          el.phone
            ? [
              <Button
                key={1}
                noBorder
                noPadding
                noBackground
                type="link"
                href={'tel:' + el.phone}
                children={el.phone}
              />,
            ]
            : null,
        ],
      ],
    };
  }

  public renderChildren = () => {
    // const { isFinished, isLoading, hideData } = this.state;
    const { data, isFinished, isLoading, hideData } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    return (
      <>
        {
          data.length !== 0 && <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData} noSkeletons={isFinished}>
            {data.map(el => (
              <Item
                lId={this.listId}
                iId={el}
                type={'common'}
                key={el}
                data_key={el}
              />
            ))}
          </ListLite>
        }
        {(!isLoading && data.length === 0) && <TypedListPlaceholder type='search' />}
      </>
    );
  };
}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListUsersSearchPresenter);
