import React, { useCallback, useEffect, useState, useRef, ComponentProps } from "react";
import { useDispatch, useSelector } from "react-redux"
import { DSChangeItem, DSSetItem, DSSetItems } from "redux/dataStorage/actions";
import { getDSStoreByName, getDSStoreItemByNameAndId } from "redux/dataStorage/selectors";
import { LeftMenuItemChange } from './Item/Change/LeftMenuItemChange';
import { sagas as leftMenuAPI } from 'utils/src/requests/requests.menu';
import { getBarItems, getLeftMenuReducerField } from "./redux/selectors";
import { leftMenuSetBarItems, leftMenuSetBars, leftMenuSetEdit, leftMenuSetReducerField, leftMenuRemoveItem, leftMenuToggleFavourite, leftMenuSortFavourite } from "./redux/actions";
import { checkResponseStatus } from "utils/src";
import { NLeftMenuReducer } from "./redux/interfaces";
import { v1 as uuid } from 'uuid';
import { baseLeftMenuItem, childrenFixIconBlocks, leftMenuStorageName, mainBarId } from "./redux/constants";
import { useDidUpdateEffect } from "utils/src/hooks";
import { toast } from "react-toastify";
import { i18n, Translate } from 'localization';
import { CustomSettings } from 'utils/src';
import { ItemRenderType } from "utils/src/requests/models/api.menu";
import { StyledSettingsButton } from "./Item/LeftMenuItem.styled";

export const serviceElementId = 'service';

export const isOldIcon = (icon: string) => icon[0] !== icon[0].toUpperCase();

export const useLeftMenuStore = () => {

    const dispatch = useDispatch();

    const edit = useSelector(getLeftMenuReducerField('edit'));

    const setEdit: typeof leftMenuSetEdit = (value) => dispatch(leftMenuSetEdit(value));

    const opened = useSelector(getLeftMenuReducerField('opened'));

    const setOpened = (value: Extract<ReturnType<typeof leftMenuSetReducerField>['payload'], { field: 'opened' }>['value']) => {
        if (!value) dispatch(leftMenuSetReducerField({ field: 'bars', value: [mainBarId] }));
        dispatch(leftMenuSetReducerField({ field: 'opened', value }));
    };

    const save = useSelector(getLeftMenuReducerField('save'));

    const setSave = (value: Extract<ReturnType<typeof leftMenuSetReducerField>['payload'], { field: 'save' }>['value']) => {
        dispatch(leftMenuSetReducerField({ field: 'save', value }));
    }

    const hide = useSelector(getLeftMenuReducerField('hide'));

    const setHide = (value: Extract<ReturnType<typeof leftMenuSetReducerField>['payload'], { field: 'hide' }>['value']) => {
        if (!value) dispatch(leftMenuSetReducerField({ field: 'bars', value: [mainBarId] }));
        dispatch(leftMenuSetReducerField({ field: 'hide', value }));
    };

    const favouriteItemId = useSelector(getLeftMenuReducerField('favouriteItemId'));

    return {
        edit,
        setEdit,
        opened,
        setOpened,
        save,
        setSave,
        hide,
        setHide,
        favouriteItemId
    }

};

export const useLeftMenuItem = (id: string) => {

    const dispatch = useDispatch();

    const item = useSelector(getDSStoreItemByNameAndId(leftMenuStorageName, id)) || baseLeftMenuItem;

    const activeItems = useSelector(getLeftMenuReducerField('activeItems'));

    const isActive = activeItems.includes(id);

    const notificationsItems = useSelector(getLeftMenuReducerField('notificationsItems'));

    const showNotification = notificationsItems.includes(id) || item.notViewedCount > 0;

    const {
        edit,
        opened,
        favouriteItemId,
        setOpened,
        setHide,
    } = useLeftMenuStore();

    const changeItem = () => {
        LeftMenuItemChange({ type: 'change', data: item })
            .then(data => {
                if (!data.id) {
                    dispatch(leftMenuRemoveItem({
                        fromBar: data.parentId === '00000000-0000-0000-0000-000000000000' ? mainBarId : data.parentId,
                        item: id
                    }));
                } else {
                    dispatch(DSChangeItem({
                        storage: leftMenuStorageName,
                        id,
                        item: data
                    }));
                    if(data.blockType === 'favs') {
                        dispatch(leftMenuSetReducerField({ field: 'favouriteItemId', value: data.id }));
                    }
                }
            })
            .catch();
    };

    const removeItem = () => {
        dispatch(leftMenuRemoveItem({
            fromBar: item.parentId === '00000000-0000-0000-0000-000000000000' ? mainBarId : item.parentId,
            item: id
        }));
        if(item.blockType === 'favs') {
            dispatch(leftMenuSetReducerField({ field: 'favouriteItemId', value: '' }));
        }
    }

    const toggleFavourite = useCallback<NonNullable<ComponentProps<typeof StyledSettingsButton>['onClick']>>((e) => {
        e.stopPropagation();
        dispatch(leftMenuToggleFavourite(id));
    }, []);

    // To nspk render user block like link
    if (item.blockType === 'user' && CustomSettings.leftMenuLinkedUserBlock()) {
        item.url.includes('/user') ? item.url : item.url === `/user`;
        item.renderType = ItemRenderType.link;
    }
    return {
        item,
        isActive,
        edit,
        opened,
        showNotification,
        setOpened,
        changeItem,
        removeItem,
        setHide,
        menuHasFavouriteItem: !!favouriteItemId,
        toggleFavourite
    };
};

/**
 * get bar data by id
 * @param id 
 * @returns 
 */
export const useLeftMenuBar = (id: string) => {

    const dispatch = useDispatch();

    const item = useSelector(getDSStoreItemByNameAndId(leftMenuStorageName, id));
    const itemRef = useRef(item);
    itemRef.current = item;

    const items = useSelector(getBarItems(id)) || [];

    const {
        edit,
        opened,
    } = useLeftMenuStore();

    /**
     * use on DND items
     */
    const changeItems = useCallback((newItems: typeof items) => {
        switch(itemRef.current?.blockType) {
            case 'favs':
                dispatch(leftMenuSortFavourite({ bar: id, items: newItems.filter(el => el !== serviceElementId) }));
            default:
                dispatch(leftMenuSetBarItems({ bar: id, items: newItems.filter(el => el !== serviceElementId) }));
        }
    }, [id]);

    const addItem = useCallback(() => {
        LeftMenuItemChange({
            type: 'add', data: {
                ...baseLeftMenuItem,
                id: uuid(),
                parentId: id === mainBarId ? '00000000-0000-0000-0000-000000000000' : id
            }
        })
            .then(data => {
                dispatch(DSChangeItem({
                    storage: leftMenuStorageName,
                    id: data.id,
                    item: data
                }));
                dispatch(leftMenuSetBarItems({ bar: id, items: [...items, data.id] }));
                switch(data.renderType) {
                    case 'dropdown':
                        dispatch(leftMenuSetBarItems({ bar: data.id, items: [] }));
                }
                switch(data.blockType) {
                    case 'favs':
                        dispatch(leftMenuSetReducerField({ field: 'favouriteItemId', value: data.id }));
                }
            })
            .catch();
    }, [items]);

    return {
        item,
        items,
        edit,
        opened,
        changeItems,
        addItem
    };
};

/**
 * get all menu bars and function to set them
 * @returns 
 */
export const useLeftMenuBars = () => {

    const dispatch = useDispatch();

    const bars = useSelector(getLeftMenuReducerField('bars'));

    const setBars = (...args: Parameters<typeof leftMenuSetBars>) => {
        dispatch(leftMenuSetBars(...args));
    };

    return {
        bars,
        setBars
    };
};

const prepareDataToReducers = (data: Awaited<ReturnType<typeof leftMenuAPI.get>>['data']) => {
    const itemsToDataStore: NLeftMenuReducer.Item[] = [];
    const barsItems: Parameters<typeof leftMenuSetBarItems>['0'] = [];
    let favouriteItemId: NLeftMenuReducer.Reducer['favouriteItemId'] = ''; 
    const mapItemsFromArray = (items: Awaited<ReturnType<typeof leftMenuAPI.get>>['data'], parent?: string) => {
        const ids = items.map(el => el.id);
        barsItems.push({ bar: parent || mainBarId, items: ids });
        items.forEach(item => {
            itemsToDataStore.push(item);
            if (item.blockType === 'favs') {
                favouriteItemId = item.id;
            }
            if (item.renderType === 'dropdown') {
                // if item blockType with constans icon to childrens then fill childrens icons to parent icon
                if (childrenFixIconBlocks.includes(item.blockType)) {
                    item.children = (item.children || []).map(el => ({ ...el, icon: item.icon }));
                }
                if (item.children) {
                    mapItemsFromArray(item.children, item.id);
                }
            }
        });
    }
    mapItemsFromArray(data);
    return {
        items: itemsToDataStore,
        favouriteItemId,
        barsItems
    };
};

export const useLeftMenu = () => {

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const leftMenuBars = useLeftMenuBars();

    const {
        edit,
        save,
        opened,
        hide,
        setOpened,
        setSave,
        setHide
    } = useLeftMenuStore();

    // TODO check types
    const showMenuOpened = useSelector((state: any) => state.store.appDesign?.showMenuOpened);

    const unsavedData = useRef<string | null>(null);

    useEffect(() => {
        setLoading(true);
        dispatch(DSSetItem({
            storage: leftMenuStorageName,
            id: serviceElementId,
            item: {
                ...baseLeftMenuItem,
                id: serviceElementId,
                isEditable: false,
                isDraggable: false,
                icon: 'AddBox',
                title: ''
            }
        }));
        leftMenuAPI.get().then(d => {
            if (checkResponseStatus(d)) {
                const { items, barsItems, favouriteItemId } = prepareDataToReducers(d.data);
                dispatch(DSSetItems({ storage: leftMenuStorageName, items: items.map(item => ({ id: item.id, item })) }));
                dispatch(leftMenuSetBarItems(barsItems));
                dispatch(leftMenuSetReducerField({ field: 'favouriteItemId', value: favouriteItemId }));
                dispatch(leftMenuSetBars([mainBarId]));
                setError(false);
                unsavedData.current = JSON.stringify(d.data);
            } else {
                setError(true);
            }
            setLoading(false);
        });
    }, []);

    const barsItems = useSelector(getLeftMenuReducerField('barsItems'));
    const items = useSelector(getDSStoreByName('leftMenu'));

    useDidUpdateEffect(() => {
        if (!unsavedData.current || save === undefined) return;
        if (save === null) {
            // if didn't save then reroll item to unsave data from ref
            const { items, barsItems } = prepareDataToReducers(JSON.parse(unsavedData.current) as Awaited<ReturnType<typeof leftMenuAPI.get>>['data']);
            dispatch(DSSetItems({ storage: leftMenuStorageName, items: items.map(item => ({ id: item.id, item })) }));
            dispatch(leftMenuSetBarItems(barsItems));
            setSave(undefined);
        } else {
            // if save then update ref to new data
            if (!barsItems || !items) return;
            const getItemsToBar = (id: string) => {
                const item = items[id];
                if (item && item.renderType === 'dropdown') {
                    const itemBar = barsItems[id] || [];
                    item.children = itemBar.map(getItemsToBar);
                }
                return item as NonNullable<typeof item>;
            };
            const mainBar = barsItems[mainBarId] || [];
            const newMenu = mainBar.map(getItemsToBar);
            leftMenuAPI.set(newMenu).then((d) => {
                if (checkResponseStatus(d)) {
                    toast.success(<Translate i18nKey='pryaniky.leftMenu.save.success' />);
                    unsavedData.current = JSON.stringify(newMenu);
                } else {
                    toast.error(<Translate i18nKey='pryaniky.leftMenu.save.error' />);
                }
            });
        }
    }, [save]);

    return {
        ...leftMenuBars,
        edit,
        opened,
        setOpened,
        loading,
        error,
        showMenuOpened,
        hide,
        setHide
    };
};