import React, { FC, useState, useEffect } from 'react'
import { ISearchSelectizeProps } from 'uielements/src/SearchSelectize/SearchSelectize.index';
import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index';
import CourseTabs, { ITabType } from '../../../../LMSModule/components/Tabs'
import * as utils from 'utils/src/utils';
import { useDebounce } from 'utils/src/hooks'
import { cn } from '@bem-react/classname';
import './style.scss'
import { i18n, Translate } from 'localization';

const cnSessionSelectize = cn('SessionSelectize');

interface IWithSessionProps {
    onClick: (items: any[]) => void
    courseId: string;
    cut?: number;
    id?: string;
    defaultElements?: ISelectizeItem[]
    defaultSelected?: ISelectizeItem[]
    sortSelected?: 'top' | 'bottom';
    name?: string | string[];
}
const noopArray: ISelectizeItem[] = []
const withSessionSuggester = (Component: React.ComponentType<ISearchSelectizeProps>): FC<IWithSessionProps> =>
    ({ onClick, courseId, cut, id, name, sortSelected = 'top', defaultElements = noopArray, defaultSelected }) => {
        const resetElement = {
            value: 'All',
            title: i18n.t(`pryaniky.LMS.utils.sessions.all.1`),
            id: 'All'
        };
        const TABS = [
            { value: 'active', content: i18n.t(`pryaniky.LMS.utils.sessions.tabs.active.1`) },
            { value: 'archive', content: i18n.t(`pryaniky.LMS.utils.sessions.tabs.archive.1`) }
        ]
        const [query, setQuery] = useState<string>('')
        const [elements, setElements] = useState<ISelectizeItem[]>(defaultElements)
        const [active, setActive] = useState<ISelectizeItem[]>(defaultSelected ? defaultSelected : [resetElement])
        const [loading, setLoading] = useState<boolean>(false)
        const [tab, setTab] = useState<ITabType>(TABS[0])

        useEffect(() => {
            elements.length === 0 && setElements(defaultElements)
        }, [defaultElements])

        const tabValue = tab.value;
        const sendRequest = (value: string, tab: string = tabValue) => {
            setQuery(value)
            // if (value === '') return;
            setLoading(true)
            const request = utils.API.lms.sessionSuggester(courseId, value, tab === 'archive');
            request.r.then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    setElements(d.data.map((item: any) => ({
                        value: item.id,
                        title: item.displayName,
                        id: item.id
                    })));
                }
                setLoading(false)
            });
        }

        const changeQuery = useDebounce(sendRequest, 1000, [tabValue, courseId])
        const tabChange = (tab: ITabType) => {
            setTab(tab)
            sendRequest('', tab.value)
        }

        const onClickItem = (items: ISelectizeItem[]) => {
            const withoutReset = items.filter(val => val.id !== resetElement.id)
            setActive(items)
            onClick(withoutReset)
        }

        return <Component
            loading={loading}
            query={query}
            active={active}
            changeQuery={changeQuery}
            onClick={onClickItem}
            resetElement={resetElement}
            elements={elements}

            header={
                <div className={cnSessionSelectize({})}>
                    <h4 className={cnSessionSelectize('Header')}>{name}</h4>
                    <CourseTabs className={cnSessionSelectize('Tabs')} onChange={tabChange} selected={tab} tabs={TABS} />
                </div>
            }
            sortSelected={sortSelected}
            searchThrottle={-1}
            cut={cut}
            id={id}
        />
    }

export default withSessionSuggester