import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";

interface IQuestsModerate {
    questsmoderate: string;
    filters: string;
}

export const questsmoderate = (): IWidget<IColumn[]> => {
    const ids = guids<IQuestsModerate>(['filters', 'questsmoderate']);
    return generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
            generateColumn({
                items: [
                    generateWidget({
                        id: ids.questsmoderate,
                        type: widgets.types.list + '/questsmoderate',
                        // relations: [],
                    }),
                ],
                styles: {
                    width: '72%',
                },
            }),
            generateColumn({
                items: [
                    generateWidget({
                        id: ids.filters,
                        type: widgets.types.filters + '/questsmoderate',
                        relations: [
                            // ids.users
                        ],
                        data: [],
                    }),
                ],
                styles: {
                    width: '28%',
                },
                mobileHidden: true,
            }),
        ],
    });
}