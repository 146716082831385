import React, { useEffect } from 'react';
import {
  IVoteItem,
  cnVoteItem, mapVoteItemStateToProps, mapVoteItemActionsToProps
} from './VoteItem.index';
import { connect } from 'react-redux';
import './VoteItem.scss';
import { Avatar } from 'uielements';
import { ViewComponent/*, IAdditionalField, prepareFromServer, preparePhotoToVoteView, prepareToServer*/ } from 'uielements/src/FieldRender'
import { UserMention } from 'uielements/src/UserMention/UserMention';
import NewsActions from '../../NewsActions';
import { Button } from 'uielements/src/MaterialElements/Button/Button';
import { i18n, Translate } from 'localization';
import { /*voteFullView,*/ ConnectedVoteFullView, dialogParams } from '../../../../blocks/Dialogs/News/VoteView/VoteFullView';
import ContextMenu from '../../ContextMenu'
import { withNewsContext } from '../../../contexts/news'
import openNewsDialog from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import { usePreperedPhoto, useCutTextarea } from '../hooks'



const VoteItemPresenter: React.FC<IVoteItem.Props> = ({
  newsId,
  workflowId,
  user,
  additionalFields,
  className
}) => {
  

  const [imageField, fieldsWithOutPhoto] = usePreperedPhoto(additionalFields?.additionalFieldsValues)
  const fields = useCutTextarea(fieldsWithOutPhoto)

  const showFull = () => {
    openNewsDialog({
      newsId,
      RenderComponent: ConnectedVoteFullView
    }, dialogParams);
  }

  return (
    <div className={cnVoteItem({}, [className])} id={newsId}>
      <div className={cnVoteItem('Header')}>
        {/* as any сделано для того чтоб ы при сборке не ругалось на never */}
        <Avatar size={112}
          className={cnVoteItem('Avatar')}
          imgId={imageField?.data?.id || user.imgId}
          imgUrl={imageField?.data?.previewUrl || user.imgUrl}
          name={user.displayName} />
        <UserMention id={user.id} name={user.displayName} className={cnVoteItem('UserName')} />
        <div className={cnVoteItem('Position')}>
          {user.position}
        </div>
        <div className={cnVoteItem('Division')}>
          {user.division}
        </div>
        <ContextMenu showAlways className={cnVoteItem('ContextMenu')} />
      </div>
      <hr />
      <div className={cnVoteItem('Body')}>
        <ViewComponent className={cnVoteItem('Fields')} fields={fields} />
        <div className={cnVoteItem('Actions')}>
          <Button className={'ShowFull Button_type_link'} onClick={showFull}>
            <Translate i18nKey={'showFull'} />
          </Button>
          <NewsActions />
        </div>
      </div>
    </div>
  )
};

export const VoteItem = withNewsContext(connect<
  IVoteItem.ImapVoteItemStateToProps,
  IVoteItem.ImapVoteItemActionsToProps,
  IVoteItem.OwnProps,
  IVoteItem.Props
>(
  mapVoteItemStateToProps,
  mapVoteItemActionsToProps
)(VoteItemPresenter));