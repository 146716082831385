import React, { FC, useState, useEffect, useMemo } from 'react';
import * as utils from 'utils/src/utils';
import {
  IWidgetTypeBirthdaysProps,
  cnBirthdaysWidget,
  mapStateToProps,
} from './BirthdaysWidget.index';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { connect } from 'react-redux';
import './BirthdaysWidget.scss';
import { i18n, Translate } from 'localization';
import { Button } from 'muicomponents/src/Button/Button'
import { Link } from 'muicomponents/src/Link/Link'
import Image from './BirthdayIcon.svg'
import noDataSvg from 'assets/svg/NoData.svg'
import { sendThanksDialog, convertFullUserToShort } from '../InfoBlock/UserNew/Avatar/ThankForm/ThankForm'
import { useInitNewsCreator, baseModels } from 'News/creator/Creator/Creator.hooks';
import { IUserBirthday } from 'utils/src/requests/models/api.users';
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton'
import { formatDateNumbers } from 'utils/src/utils.dates';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'muicomponents/src/Icons'
import { formatDateSTD } from 'utils/src/utils.dates';
import { MiniPagination } from 'muicomponents/src/MiniPagination/MiniPagination'
import { Box } from 'muicomponents/src/Box/Box'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment';
import { sub, format } from 'date-fns';
import { CustomSettings } from 'utils/src';

const customBirthdayLink = CustomSettings.birthdaysLink();

const userIdVariants = ['uId', 'userId'];

const Item: FC<{
  value: any
  currencyNames: any
  currentUser: any
  enabledThanks?: boolean
}> = ({
  value,
  currencyNames,
  enabledThanks,
  currentUser
}) => {

    const creatorOptions = useMemo(() => {
      return {
        models: {
          thanks: {
            ...baseModels.thanks, users: [value].map((v: any) => ({ ...convertFullUserToShort(v), type: 'user' }))
          }
        }
      }
    }, [value]);

    const prepairedCustomBirthdayLink = useMemo(() => {
      if(!customBirthdayLink) return undefined;
      if(userIdVariants.reduce((a, variant) => a || customBirthdayLink.includes(`{${variant}}`), false)) {
        return userIdVariants.reduce((link, variant) => link.replace(`{${variant}}`, value.id), customBirthdayLink);
      }
      return customBirthdayLink;
    }, [value]);

    return <div key={value.id} className={cnBirthdaysWidget('User')}>

      <Avatar imgUrl={value.imgUrl} imgId={value.imgId} name={value.displayName} className={cnBirthdaysWidget('User-Avatar')} size={40} />
      <div className={cnBirthdaysWidget('User-Info')}>
        <div>
          <UserMention id={value.id} name={value.displayName} className={cnBirthdaysWidget('User-Link')} />
        </div>
        {enabledThanks && (value.id !== currentUser.baseData.id) &&
          <NewsCreatorButton
            className={cnBirthdaysWidget('User-Gift')}
            newsType={"thanks"}
            variant="contained"
            sx={{ marginTop: '8px' }}
            creatorOptions={creatorOptions}
            component={prepairedCustomBirthdayLink ? Link : ''}
            href={prepairedCustomBirthdayLink}
          >
            <Translate i18nKey={'pryaniky.birthdays.give'} values={{ text: currencyNames.currencyNameAccusativeSingular }} />
          </NewsCreatorButton>
          // <Button className={cnBirthdaysWidget('User-Gift')} sx={{ marginTop: '8px' }} color={"primary"} onClick={openDialog(value)} variant={'outlined'}>
          //   <Translate i18nKey={'pryaniky.birthdays.give'} values={{ text: currencyNames.currencyNameAccusativeSingular }} />
          // </Button>
        }
      </div>
    </div>
  }
const BirthdaysWidgetPresenter: FC<IWidgetTypeBirthdaysProps> = ({
  tag: Tag = 'div',
  currencyNames,
  currentUser,
  enabledThanks,
  settings,
  className,
  contexts,
  children,
  ...props
}) => {
  const [birthdays, setBirthdays] = useState<IUserBirthday[]>([])
  const [totalUserCount, setTotalUserCount] = useState(0)
  const [watchDate, setWatchDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [loading, setLoading] = useState(false)
  const noData = birthdays.length === 0


  // const today = new Date();



  const paginationComponent = useMemo(() => {
    const value = formatDateSTD(watchDate, false)
    return <MiniPagination
      sx={{
        marginLeft: '12px',
        ".MuiButtonBase-root.MuiIconButton-root": {
          padding: 0
        },
        ".MuiButtonBase-root.MuiIconButton-root:hover": {
          backgroundColor: "initial"
        },
        ".MuiTypography-caption": {
          textTransform: "capitalize",
          width: "64px",
          textAlign: "center",
        }

      }}
      variant={"caption"}
      next={() => {
        const parsedDate = new Date(watchDate);
        parsedDate.setDate(parsedDate.getDate() + 1)
        setWatchDate(format(new Date(parsedDate), 'yyyy-MM-dd'))
      }}
      previous={() => {
        const parsedDate = new Date(watchDate);
        parsedDate.setDate(parsedDate.getDate() - 1)
        setWatchDate(format(new Date(parsedDate), 'yyyy-MM-dd'))
      }}
      value={value}
    />
  }, [watchDate])

  const count = settings?.usersCount || 3

  const itemsComponent = useMemo(() => {
    if (loading) return <CircularProgress />;
    if (!loading) {
      return birthdays
        .slice(0, count)
        .map((value: any) => (
          <Item key={value.id} value={value} currentUser={currentUser} currencyNames={currencyNames} enabledThanks={enabledThanks} />
        ))
    }
  }, [birthdays, loading])

  useEffect(() => {
    setLoading(true)
    utils.API.users.birthdaysByDateFetch("day", watchDate, { count: count > 15 ? count : undefined }).r.then(d => {
      if (!utils.checkResponseStatus(d)) {
        setLoading(false)
        return;
      } else {
        setLoading(false)

        setBirthdays(d.data);
        setTotalUserCount((d as any)?.totalUserCount);
      }
    });
  }, [watchDate])

  if (settings?.hideIfNoData && !birthdays.length) return <Tag className={cnBirthdaysWidget({}, [className])} {...{ ...props, isHidden: noData }} />;
  // if (!settings?.hideIfNoData && !birthdays.length) return <Tag {...props} className={cnBirthdaysWidget({}, [className])}>
  //   <Box sx={{ display: "flex", alignItems: "center" }}>
  //     <h4 className={cnBirthdaysWidget('Header-Title')}>
  //       <Translate
  //         i18nKey={'pryaniky.birthdays.congratulation.new'}
  //       // values={{ text: formatDateNumbers(watchDate) }} 
  //       />
  //     </h4>

  //     {paginationComponent}
  //   </Box>
  //   <Avatar precentage={100} shape={'rectangle'}
  //     imgUrl={noDataSvg}
  //   />
  //   <span className={cnBirthdaysWidget('Header-Title-Text')}>  <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} /></span>
  // </Tag>;





  return (
    <Tag className={cnBirthdaysWidget({}, [className])} {...props}>
      <div className={cnBirthdaysWidget('Header')}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: settings?.hideIfNoData ? "start" : "space-between" }}>
          <h4 className={cnBirthdaysWidget('Header-Title')}>
            <Translate
              i18nKey={'pryaniky.birthdays.congratulation.new'}
            // values={{ text: formatDateNumbers(watchDate) }} 
            />
          </h4>

          {!settings?.hideIfNoData && paginationComponent}
        </Box>



        {settings?.text && <div className={cnBirthdaysWidget('Header-Description')}>
          <Translate i18nKey={'pryaniky.birthdays.description'} values={{ text: settings.text }} />
        </div>}
        <div className={cnBirthdaysWidget('Header-Image')}>
          {settings?.showImg && <img
            src={settings.banner?.elements[0].imgUrl ? settings.banner?.elements[0].imgUrl : Image}
          />
          }

        </div>
        <div className={cnBirthdaysWidget('Header-Total')}>
          {birthdays.length === 0 ? <Translate i18nKey={'pryaniky.birthdays.no.people'} /> : <Translate i18nKey={'pryaniky.birthdays.total'} values={{ text: totalUserCount }} />}
        </div>

      </div>

      {itemsComponent}

      <div className={cnBirthdaysWidget('Buttons')}>
        <Link
          sx={{ textTransform: "uppercase" }}
          to={`/birthdays/${new Date(watchDate).getMonth() + 1}`}
          className={cnBirthdaysWidget('Button-OtherDays')}>
          <Translate i18nKey={'pryaniky.birthdays.showOthers'} />
        </Link>
        <Link
          to={`/birthdays/day/${watchDate}`}
          className={cnBirthdaysWidget('Button-ShowMore')}
        >
          {totalUserCount > count &&
            <>
              <Translate i18nKey={'pryaniky.birthdays.show.all.Others'} />
            </>
          }
        </Link>
      </div>
    </Tag >
  );
}

export const BirthdaysWidget = connect(mapStateToProps)(BirthdaysWidgetPresenter);
