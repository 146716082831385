import React, { FC, useState } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'

import { Button as B } from 'uielements/src'
import withConfirm from '../../LMSTeacherOffice/HOCs/buttonWithConfirm'
import { isCompleteMondatory } from 'LMSModule/utils/utils.lms'
import { getMondatryTests, getTestCount } from 'LMSModule/utils/result.utils'
import { tS } from 'LMSModule/utils/i18n.adaptor'
const Button = withConfirm(B)

export const Presenter: FC<IPresenterProps> = ({ className, logs, units, fullUnits, closeSession, sid, cid, absoluteComplete }) => {
    if (absoluteComplete) return null;
    const mondatoryCompleted = isCompleteMondatory(logs, units)
    const mondatryTests = getMondatryTests(units)
    const testCount = getTestCount(units)
    if (!mondatoryCompleted) return null
    if (mondatryTests.length === testCount) return null
    return <div className={cnClassName({}, [className])}>
        <div className={cnClassName('Content')}>
            {tS('mondatoryCompleted.1')}
        </div>
        <Button
            main
            className={cnClassName('Btn')}
            onConfirm={() => closeSession(sid, cid)}
            confirmTitle={tS('confirmTitle.1')}
            confirmText={tS('confirmText.1')}
        >{tS('complete.1')}</Button>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
