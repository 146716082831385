import { IIdeaSupportProps, mapDispatchToProps } from './ThanksSupport.index';
import * as React from 'react'
import './ThanksSupport.scss'
import { CountSlider } from '../../../CountSlider/CountSlider';
import { ButtonPopover } from '../ButtonPopover/ButtonPopover';
// import { addInvest } from '../../NewsSides';
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';

export const ThanksSupport_: React.FC<IIdeaSupportProps> = ({ valueChange, anchor, newsId, addInvest }) => {
    const [value, changeValue] = React.useState(1);
    const onSave = () => {
        addInvest({ id: newsId, value });
        valueChange(value);
        changeValue(1)
    }
    return <ButtonPopover anchor={anchor} label={i18n.t('support')} accepted={onSave}>
        {(close, accepted) =>
            <CountSlider
                onChange={changeValue}
                icon={'give'}
                minValue={1}
                value={value} type='modal'
                className='NewsThanksSupport'
                closeBtn={close}
                saveBtn={accepted}
            />
        }
    </ButtonPopover>;
}

export const ThanksSupport = connect(
    null,
    mapDispatchToProps
)(ThanksSupport_);