import { IIdeasPropsType, IIdeasStateType, mapStateToProps, TStateProps } from '../Ideas.index'
import { Status } from '../IdeaType'
import * as React from 'react';
import { ChangeStateRender } from '../ChangeStateRender/ChangeStateRender'
import { ThanksSupport } from '../../common/ThanksSupport/ThanksSupport';
import { AddExpert } from '../AddExpert/AddExpert'
import { IdeaEvaluation } from '../IdeaEvaluation/IdeaEvaluation'
import { ExpertEvalutionResults } from '../ExpertEvalutionResults/ExpertEvalutionResults'
import { cloneObject } from 'utils/src/utils';
import { connect } from 'react-redux';
import { Popover } from '../../common/Popover/Popover'
import { i18n, Translate } from 'localization';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import { IdeaNews } from '../IdeaType'
import '../Ideas.scss'
import { ViewComponent } from 'uielements/src/FieldRender';
import { Button } from 'uielements/src';

export class ShortIdeasPresenter extends React.Component<IIdeasPropsType & TStateProps, IIdeasStateType>  {

    private stageContentRef = React.createRef<HTMLElement>();

    constructor(props: IIdeasPropsType & TStateProps) {
        super(props)

        const data = props.news as IdeaNews

        this.state = {
            controlComponent: undefined,
            actionComponent: undefined,
            data: cloneObject(data)
        }
    }

    public componentDidMount = () => {
        const data = this.props.news as IdeaNews
        if(data.idea.currentStatus) this.setState({ controlComponent: this.actionRender(data.idea.currentStatus) });
    }

    public render() {
        const { data } = this.state;
        const { idea, id } = data;
        
        let fields: any[] = [];
        const commonCategory = [{ id: GUID_EMPTY, description: '', isCollapsed: 'false', title: '', fields }];

        return (
            <NewsView className={'type-21'}>

                <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", margin: '24px' }}>
                    <span style={{ color: "#999", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Описание: </span>
                    <TextRender />
                </div>

                {!!fields.length && <div style={{ padding: '0 24px 24px 24px' }}>
                    <ViewComponent
                    fields={commonCategory as any}
                    />
                </div>}

                <Button style={{ marginLeft: '24px' }} type="rlink" target="_blank" noBackground padding="md" href={"/news/" + id}>{i18n.t('pryaniky.ideas.more.btn')}</Button>
            </NewsView>
        )
    }
    private changeResponsibleForStatusUser = (array: any[]) => {
        this.setState((s: IIdeasStateType) => {
            s.data.idea.responsibleForStatusUser = array;
            return s;
        })
    }
    private changeHeads = (array: any[]) => {
        this.setState((s: IIdeasStateType) => {
            s.data.idea.ideaHeads = array;
            return s;
        })
    }
    private changeParticipants = (array: any[]) => {
        this.setState((s: IIdeasStateType) => {
            s.data.idea.ideaParticipants = array;
            return s;
        })
    }

    private isExpert = () => {
        const data = this.props.news as IdeaNews
        return (data.idea.ideaExperts || []).find((uid: any) =>
            uid === this.props.userData.baseData.id);

    }

    private thanksCountChange = (value: number) => {
        this.setState((s: IIdeasStateType) => {
            s.data.thanksCount += value;
            return s;
        })
    }

    private expertsSelected = (experts: string[]) => { }

    private actionRender = (status: Status): React.ReactNode => {
        const { data } = this.state;
        const { isExpertEvaluation } = status;
        const { id, idea } = data;
        const { showExpertiseResultButton, ideaExperts, isIdeaModerator } = idea;
        return (
            <React.Fragment>
                {status.isAllowInvestment && <ThanksSupport newsId={id} anchor={this.stageContentRef} valueChange={this.thanksCountChange} />}

                {(isExpertEvaluation && this.isExpert()) && <IdeaEvaluation anchor={this.stageContentRef} ideaId={id} statusID={status.id} onComplite={() => { }} />}

                {(isExpertEvaluation && isIdeaModerator) && <AddExpert
                    anchor={this.stageContentRef}
                    experts={ideaExperts || []}
                    onComplite={this.expertsSelected}
                    ideaId={id}
                    statusID={status.id}
                />}

                {showExpertiseResultButton && <ExpertEvalutionResults anchor={this.stageContentRef} ideaId={id} statusID={status.id} showDeleteExpers={isIdeaModerator} />}
            </React.Fragment>
        )
    }

    private statusSaved = (status: Status) => {
        this.setState((s: IIdeasStateType) => {
            s.actionComponent = undefined;
            status.isExpertEvaluation = true;
            s.data.idea.showExpertiseResultButton = true;
            s.controlComponent = this.actionRender(status);
            status.statusChangeDate = new Date().toISOString();
            s.data.idea.currentStatus = status;
            return s;
        });
    }
    private cancleChangeStateRender = () =>
        this.state.data.idea.currentStatus && this.setState({ actionComponent: undefined, controlComponent: this.actionRender(this.state.data.idea.currentStatus) });

    private stageChange = (status: Status) => {
        if (this.state.data.idea.currentStatus && this.state.data.idea.currentStatus.id == status.id) return this.cancleChangeStateRender();
        this.setState({
            controlComponent: undefined,
            actionComponent: <Popover anchor={this.stageContentRef.current}>
                <ChangeStateRender distributeMultiplicator={status.distributeMultiplicator} fund={this.state.data.thanksCount} ideaId={this.state.data.id} status={status} save={this.statusSaved} cancle={this.cancleChangeStateRender} />
            </Popover>
        });
    }

}

export const ShortIdeas = withNewsContext(connect(
    mapStateToProps
)(ShortIdeasPresenter));

export const extendIdeas = () => {
    // ComponentInjector.getInstance().extendNode('news_types', <ShortIdeas />, 'ideas', 'mini');
    // ComponentInjector.getInstance().extendNode('news_types', <ShortIdeas />, 'ideas', 'content');
}