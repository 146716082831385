/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";
import { i18n, Translate } from 'localization';
import { store } from "redux/store";
import { Actions } from '@jsonforms/core';
import defaultData from './data'
import uischema from './uischema'

const { types } = JFdictionary



const schema = {
  type: types.object,
  properties: {
    type: {
      type: types.string,
      placeholder: 'select type',
      opts: [
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.user'),
          value: 'users'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.group'),
          value: 'groups'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.rating'),
          value: 'ratings'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.badge'),
          value: 'badges'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.ideas'),
          value: 'ideas'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.creativetasks'),
          value: 'creativetasks'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.notices'),
          value: 'notices'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.events'),
          value: 'events'
        },
        {
          title: i18n.t('pryaniky.widgets.settings.info.type.polls'),
          value: 'polls'
        }
      
      ]
    },
    selectedUsers: {
      type: types.object
    },
    selectedGroups: {
      type: types.object
    },
    selectedBadges: {
      type: types.object
    },
    selectedRatings: {
      type: types.object
    },
    
  }
}




export default schema