import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';

import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeLayoutGridProps, IBodyTypeLayoutGridState } from './Modal-Body_type_layoutGrid.index';
import { IColumn } from '../../../../widgetsUtils';
import './Modal-Body_type_layoutGrid.scss';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
// import { Icon } from 'uielements/src/Icon/Icon';
import { cn } from '@bem-react/classname';
import { IWidget, generateWidget, generateColumn } from 'i.widgets';

const cnLayoutSelector = cn('LayoutSelector');

class NewBody extends React.Component<IBodyTypeLayoutGridProps, IBodyTypeLayoutGridState> {
  public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IBodyTypeLayoutGridProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
      selected: this.props.parent.state.data.selected
    };
  }

  // public componentDidMount() {}

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    const { children } = this.props;
    const types: IWidget[] = [
      generateWidget({
        type: 'pryaniky/layout',
        title: i18n.t('pryaniky.modal.layoutGrid.column', { count: 1 }),
        data: [
          generateColumn({})
        ],
      }),
      generateWidget({
        type: 'pryaniky/layout',
        title: i18n.t('pryaniky.modal.layoutGrid.columnsWithCount', { count: 2 }),
        data: [
          generateColumn({}), 
          generateColumn({})
        ],
      }),
      generateWidget({
        type: 'pryaniky/layout',
        title: i18n.t('pryaniky.modal.layoutGrid.columnsWithCount', { count: 3 }),
        data: [
          generateColumn({}), 
          generateColumn({}), 
          generateColumn({})
        ],
      }),
    ];
    let selectedId = '';
    if(this.state.selected && this.state.selected.data) {
      const len = this.state.selected.data.length;
      types.forEach(type => {
        if(type.data && type.data.length === len) selectedId = type.id;
      })
    }
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        {
          types.map((type, i) => {
            let selected = type.id === selectedId ? this.state.selected : undefined;
            return <Button key={i} onClick={this.selectGrid.bind(this, type)} className={cnLayoutSelector({selected: !!selected})}>
              <div className={cnLayoutSelector('Info')}>
                <Icon className={'Selected'} icon='check-square' />
                {type.title}
              </div>
              {type.data && type.data.map((el: IColumn, index: number) => {
                return <div key={index} className={cnLayoutSelector('Column')} />
              })}
            </Button>
          })
        }
        {children}
      </TagName>
    )
  }

  private selectGrid = (selected?: IWidget) => {
    if(selected) {
      if(selected.data) {
        selected.data.forEach((col: IColumn, index: number, mass: IColumn[]) => {
          if(!col.styles) col.styles = {};
          if(!col.styles.width) col.styles.width = mass.length === 3 && index == 1
            ? (33.34).toFixed(2) + '%'
            : (100 / mass.length).toFixed(2) + '%';
          
        });
        if(selected.data.length > 1) {
          const okBtnAction = (data: {[s: string]: any}) => {
            this.applySelectedGrid(data.layout);
          }
          utilsDep.modal({
            header: i18n.t('pryaniky.modal.layoutGrid.title'),
            type: 'layoutWidth',
            data: {
              layout: selected
            },
            okBtnAction
          });
        } else {
          this.applySelectedGrid(selected);
        }
      }
    }
  }

  private applySelectedGrid(selected: IWidget) {
    this.setState({selected}, () => {
      this.props.parent.state.data.selected = this.state.selected;
      // this.props.parent.validateData();
      this.props.parent.okBtnAction();
    });
  }

}

export const withBodyTypeLayoutGrid = withBemMod<any>(
  cnBody(),
  { type: 'layoutGrid' }, 
  (Presenter) => (
    (props: IBodyTypeLayoutGridProps) => (
      <NewBody {...props}/>
    )
  )
);