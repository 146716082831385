import { IStateSelectorProps } from './StateSelector.index';
import * as React from 'react'
import './StateSelector.scss'
import { i18n, Translate } from 'localization';
import { Select } from 'uielements/src/Select/Select';
import { IOption } from 'uielements/src/Select/Select.index';

export const StateSelector: React.FC<IStateSelectorProps> = ({ currentStatus, statusList, onChange }) => {
    const onStatusChange = (selected: IOption) => {
        if (!selected) return;
        const selectedStatus = statusList.find(value => value.id === selected.value)
        selectedStatus && onChange(selectedStatus);
    }
    const options: IOption[] = statusList.filter(status => !status.isParallelProcessingStatus && status.userCanMoveToStatus === true).map(status => ({ value: status.id, title: status.name }));
    const optionsAllStatuses: IOption[] = statusList.filter(status => !status.isParallelProcessingStatus).map(status => ({ value: status.id, title: status.name }));
    const selected: IOption | undefined = optionsAllStatuses.find(o => o.value === currentStatus.id);
    return <Select className={"StateSelector"} options={options} onChange={onStatusChange} selected={selected} height="auto" noDefault children={i18n.t('pryaniky.post.ideas.step')}></Select>
}