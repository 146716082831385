 import { compose } from '@bem-react/core';
import * as React from 'react';

import { cnItemTabsPage, IItemTabsPageProps, IItemTabsPageState } from './ItemTabsPage.index';
// import { PriorityNav } from '../PriorityNav/PriorityNav';
import { IPriorityNavTab } from "../PriorityNav/PriorityNav.index";

import './ItemTabsPage.scss';

export class ItemTabsPagePresenter extends React.Component<IItemTabsPageProps, IItemTabsPageState> {
  public el: HTMLElement | null;

  constructor(props: IItemTabsPageProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    const navElements = this.props.tabs.elements.map((tab, i, mass) => {
      const item: IPriorityNavTab = {
        id: '',
        title: ''
      };
      for (const name in tab) {
        if(name === 'title') {
          if(i === 0) {
            item.title = [<div key='leftGhost' className={cnItemTabsPage('Tabs-Ghost', {left: true})} />, tab.title];
          } else
          if(i === mass.length - 1) {
            item.title = [tab.title, <div key='rightGhost' className={cnItemTabsPage('Tabs-Ghost', {right: true})} />];
          } else {
            item.title = tab.title;
          }
        } else {
          item[name] = tab[name];
        }
      }
      return item;
    });
    return (
      <TagName ref={el => this.el = el} className={cnItemTabsPage({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <div className={cnItemTabsPage('Header-Wrapper')}>
        {
          this.props.headerElem && 
          <div className={cnItemTabsPage('Header-Data-Wrapper')}>
            <this.props.headerElem.component {...this.props.headerElem.props} className={(this.props.headerElem.props.className ? this.props.headerElem.props.className + ' ' : '') + cnItemTabsPage('Header')} />
          </div>
          // this.props.headerElem({
          //   className: cnItemTabsPage('Header')
          // })
        }
          {/* <PriorityNav className={cnItemTabsPage('Tabs')} onChange={this.props.tabs.onChange} activeElem={this.props.tabs.active} elements={navElements} /> */}
        </div>
        
        {/* {
          this.props.tabs.elements && this.props.tabs.elements.length > 0 &&
          <PriorityNav className={cnItemTabsPage('Tabs')} onChange={this.props.tabs.onChange} activeElem={this.props.tabs.active} elements={this.props.tabs.elements} />
        } */}
        {
          <div className={cnItemTabsPage('Content')}>
            {this.props.children}
          </div>
        }
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const ItemTabsPage = compose(
  // withBemMod(cnItemTabsPage(), {})
  )(
    ItemTabsPagePresenter
)