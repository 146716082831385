import { i18n, Translate } from 'localization';
import { TDialogProps } from 'utils/src/DialogCreator'
import { IBaseUser } from 'uielements/src/UsersList/UsersList.index'
import { getPollAnswerParticipants } from 'utils/src/requests/requests.news'
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { dialog } from 'muicomponents/src/ItemsListDialog/ItemsListDialog'
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem'

const createPollParticipantsDialog = () => {
  return ({
    newsId,
    answerId,
    answerText
  }: {
    newsId: string;
    answerId: string;
    answerText: string
  }, containerProps?: TDialogProps) =>
    dialog({
      method: async function (skipCount,  { count, search }) {
        const response = await getPollAnswerParticipants({
          nId: newsId,
          aId: answerId,
          skipCount,
          count,
          search
        })
        const result = await response.r as { data: IBaseUser[] } & IBasicResponse
        if (result.error_code !== 0) throw result
        return result.data.map((item) => ({ ...item, url: `/user/${item.id}` }))
      },

      ItemComponent: UsersListItem,
      listBodyProps: {
        header: i18n.t('pryaniky.post.polls.dialog.voteas', { answer: answerText })
      }
    }, containerProps)
}

export default createPollParticipantsDialog()