
import { IReplyEditorPropsType, IReplyEditorStateType, mapStateToProps, mapDispatchToProps } from './ReplyEditor.index'
import * as React from 'react';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
import './ReplyEditor.scss'
import { ReplyType, createDefaultReply } from '../NewsTypes/Reply/Reply.type'
import { Button } from 'uielements/src/Button/Button'
import { Icon } from 'uielements/src/Icon/Icon'
import { v1 as uuid } from 'uuid';
import { EditorWithNewsContext as EditorForm } from '../EditorForm/EditorForm';
import { EditorState, convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { rawToMd } from '../PryanikyEditor/convertorConfigs'
import { Rule } from '../NewsTypes/Base.validate'
import { i18n, Translate } from 'localization';
import { EditorTypeNews as ReplyForm } from '../NewsTypes/Reply/Editor/containers/news/news'
import { EditorTypeNews as ThanksReplyForm } from '../NewsTypes/ThanksReply/Editor/containers/news/news'
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

export class ReplyEditorPresenter extends React.PureComponent<IReplyEditorPropsType, IReplyEditorStateType>  {

    public containerRef = React.createRef<HTMLDivElement>();

    constructor(props: IReplyEditorPropsType) {
        super(props)
        this.state = {
            isValid: false,
            validFile: true,
            validationErrors: [],
            postErrorText: '',
            postErrorCode: 0,
        }
    }

    public componentDidMount = () => {

    }

    public componentDidUpdate = (pp: IReplyEditorPropsType) => {
        if (
            // if change type of comment to thanks then scroll into view
            (this.props.isThanks && this.props.isThanks !== pp.isThanks) ||
            // if change selection or lastchange type and last change type is insert-mention then scroll into view
            (
                (pp.commentModel.text.getSelection().getEndOffset() !== this.props.commentModel.text.getSelection().getEndOffset() ||
                pp.commentModel.text.getLastChangeType() !== this.props.commentModel.text.getLastChangeType())
                && this.props.commentModel.text.getLastChangeType() === 'insert-mention')
        ) {
            setTimeout(() => this.containerRef.current && (this.containerRef.current as any).scrollIntoViewIfNeeded(), 1);
        }
    }

    private getDisableSend = () => {
        const { isValid, validFile } = this.state;
        let disableSend = !isValid;
        return validFile ? disableSend : !validFile;
    }

    public render() {
        const { validationErrors, postErrorText, postErrorCode } = this.state;
        const { isThanks, newsAuthor, avatarSize = 40, denyMIME, maxFileSize } = this.props;
        const data = this.props.commentModel;
        const disableSend = this.getDisableSend()
        return (
            <div ref={this.containerRef} className={'ReplyEditor'}>
                {isThanks && <div className={('ReplyEditor-Thanks')}>
                    <div className={'ReplyEditor-ThanksTitle'}>{i18n.t('pryaniky.reply.thanks.title')}</div>

                  
                    <Button theme={'unstyled'} className={('ReplyEditor-CloseThank')} onClick={() => this.closeThanks()}>
                        <Tooltip
                            className={'InfoIcon'}
                            title={i18n.t('pryaniky.reply.thanks.close')}
                        >
                            <span><Icon icon='times' /></span>
                        </Tooltip>
                    </Button>

                </div>}
                <EditorForm
                    autoFocus={isThanks}
                    hideActions={true}
                    files={data.attachments || []}
                    onChangeFile={this.onChangeFile}
                    validationErrors={(disableSend && isThanks) ? [...validationErrors, {
                        type: 'info',
                        method: 'no_use',
                        field: 'no_use',
                        value: 0,
                        message: i18n.t('pryaniky.reply.thanks.sendHelp'),
                    }] : validationErrors}
                    isValid={disableSend}
                    save={this.sendNews}
                    denyMIME={denyMIME}
                    maxFileSize={maxFileSize}
                    postErrorText={postErrorText}
                    postErrorCode={postErrorCode}
                >
                    {isThanks ?
                        <ThanksReplyForm
                            send={() => !this.getDisableSend() ? this.sendNews : () => { }}
                            newsAuthor={newsAuthor}
                            onChange={this.onChange}
                            data={data} /> :
                        <ReplyForm
                            send={() => !this.getDisableSend() ? this.sendNews : () => { }}
                            avatarSize={avatarSize}
                            onChange={this.onChange}
                            data={data} />}
                </EditorForm>
            </div>
        )
    }

    private closeThanks = () => {
        this.props.changeReplyForm({
            newsId: this.props.newsId,
            reply: {
                ...utils.cloneObject({
                    ...createDefaultReply(),
                    user: this.props.userData.baseData,
                    id: uuid()
                }),
                text: EditorState.createEmpty(),
                thanksInfo: undefined,
                users: undefined
            }
        })
        this.props.toggleThank({ id: this.props.newsId, value: false })
    }

    private onChangeFile = (files: any[], validate: boolean) => {
        this.setState((s: IReplyEditorStateType) => {
            s.validFile = validate;
            return s;
        })
        this.props.changeReplyForm({
            newsId: this.props.newsId, reply: {
                attachments: files
            }
        })
    }

    private onChange = (data: ReplyType, validate: boolean, errors?: Rule[]) => {
        this.props.changeReplyForm({
            newsId: this.props.newsId, reply: {
                ...data
            }
        })
        this.setState({
            validationErrors: errors || [],
            postErrorCode: 0,
            postErrorText: ''
        })
        this.state.isValid !== validate && this.setState({ isValid: validate })
    }

    private sendNews = () => {
        const disableSend = this.getDisableSend();
        if (disableSend) return;
        this.setState((s: IReplyEditorStateType) => {
            s.isValid = false;
            return s;
        }, () => {

            const text = draftToMarkdown(convertToRaw(this.props.commentModel.text.getCurrentContent()), rawToMd);

            this.props.sendReply({
                newsId: this.props.newsId,
                reply: { ...this.props.commentModel, text, id: uuid() }
            })
        })

    }

}

export const ReplyEditor = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReplyEditorPresenter);
