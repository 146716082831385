// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';

import { cnPwdReset, IPwdResetProps, IPwdResetState } from './PwdReset.index';
import './PwdReset.scss';

import { Redirect } from 'react-router';
import { Input } from 'uielements/src/Input/Input';
import { Button } from 'uielements/src/Button/Button';
import { toast } from 'react-toastify';
import { Spinner } from 'uielements/src';
import { IBasicResponse } from 'utils/src/requests/models/api.base';

export class PwdResetPresenter extends React.Component<IPwdResetProps, IPwdResetState> {
  public utils = utils;
  public el: HTMLElement | null;
  public redirect: string = '';

  constructor(props: IPwdResetProps) {
    super(props);
    this.state = {
      request: false,
      password: this.props.match.params.password !== 'false' ? this.props.match.params.password : '',
      newPassword: '',
      confirmNewPassword: '',
      error: '',
    };
  }

  public render() {
    const { children, className = '' } = this.props;
    return (
      <div ref={el => (this.el = el)} className={cnPwdReset({}, [className])}>
        {this.redirect && <Redirect to={this.redirectTo()} />}
        <div className={cnPwdReset('Wrapper')}>
          {this.state.request && <Spinner className={cnPwdReset('Spinner')} fontSize={40} />}
          <form className={cnPwdReset('Form')} onSubmit={this.handleSubmit}>
            <div className={cnPwdReset('Form-Text')}>
              <h4 className="my-0">{i18n.t('pryaniky.reset.title')}</h4>
              {i18n.t('pryaniky.reset.text')}
              {this.state.error && <div className={cnPwdReset('ErrorMessage')}>{i18n.t('pryaniky.reset.error')}</div>}
            </div>
            {this.props.match.params.password === 'false' && (
              <Input
                icon="key"
                type="password"
                onChange={this.handleChange}
                name="password"
                value={this.state.password}
                placeholder={i18n.t('pryaniky.reset.old')}
              />
            )}
            <Input
              icon="key"
              type="password"
              onChange={this.handleChange}
              name="newPassword"
              value={this.state.newPassword}
              placeholder={i18n.t('pryaniky.reset.new')}
              invalid={!!this.state.confirmNewPassword && this.state.newPassword !== this.state.confirmNewPassword}
            />
            <Input
              icon="key"
              type="password"
              onChange={this.handleChange}
              name="confirmNewPassword"
              value={this.state.confirmNewPassword}
              placeholder={i18n.t('pryaniky.reset.newConfirm')}
              invalid={!!this.state.newPassword && this.state.newPassword !== this.state.confirmNewPassword}
            />
            <div className={cnPwdReset('Form-Actions')}>
              <Button main={true} children={i18n.t('pryaniky.reset.submit')} />
              <Button
                type="rlink"
                href="/login"
                noBorder={true}
                noBackground={true}
                children={i18n.t('pryaniky.reset.cancel')}
              />
            </div>
          </form>
        </div>
        {children}
      </div>
    );
  }

  private redirectTo = () => {
    const url = this.redirect;
    this.redirect = '';
    return url;
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  private handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    const error = this.getInputError();
    if (!error) {
      this.reset();
    }
    this.setState({ error });
  };

  private getInputError = (): string => {
    if (this.state.newPassword !== this.state.confirmNewPassword) return i18n.t('pryaniky.remind.error.noMatch');
    if (this.state.newPassword.length < 6) return i18n.t('prayniky.reset.error.toShort');
    return '';
  };

  private reset = () => {
    const urlData = this.utils.searchObject(this.props.location.search);
    let companyName = urlData.companyName;
    if (companyName.startsWith('http')) companyName = companyName.match(/\/\/(\w+)\./)[1];
    // utils.setCookie({ name: 'companyName', value: companyName });
    // document.cookie = 'companyName=' + companyName + '; path=/;';
    this.utils.API.system
      .pwdSet({
        companyName,
        userid: this.props.match.params.uId,
        oldPassword: this.state.password,
        newPassword: this.state.newPassword,
      })
      .r.then((d: IBasicResponse) => {
        if (this.utils.checkResponseStatus(d)) {
          toast.success(i18n.t('pryaniky.toast.success.resetPassword'));
          this.redirect = '/login';
          this.setState({});
        } else {
          this.setState({ error: d.error_text });
        }
        // utils.setCookie({ name: 'companyName', value: '', props: { expires: new Date(0).toUTCString() } });
        // document.cookie = 'companyName=; path=/; expires=' + new Date(0).toUTCString();
      });
  };
}

export const PwdReset = PwdResetPresenter;
