import * as React from 'react';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'redux/connector';

import { cnList, mapStateToProps, mapActionsToProps } from '../../List.index';
// import { mapStateToProps } from './List_type_groups.index';

import './List_type_groups_selector.scss';
import { Header } from '../../Header/List-Header';
import { ListLite, Button, Icon, Loading } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Item } from '../../Item/List-Item';
import { ListGroupsPresenter } from './List_type_groups';
import { toast } from 'react-toastify';
import { IButtonProps } from 'uielements/src/Button/Button.index';
import { IListGroupsDefaultProps } from './List_type_groups.index';
import { DialogGroupCreate } from 'blocks/Dialogs/Group/Create/GroupCreate';
export class ListGroupsSelectorPresenter extends ListGroupsPresenter {

  public prepareData(el: any) {
    super.prepareData(el);
    let buttonText = i18n.t('pryaniky.list.groups.actions.join');
    if (el.visibilityType === 1)
      buttonText = el.isRequestAccessSent
        ? i18n.t('pryaniky.list.groups.actions.abortRequest')
        : i18n.t('pryaniky.list.groups.actions.sendRequest');
    if (el.isMeInGroup) buttonText = i18n.t('pryaniky.list.groups.actions.leave');
    // el.view_data.info[0].push(
    //   el.isOfficialGroup ? (
    //     <div className="Group-Label">
    //       {i18n.t('pryaniky.list.groups.official')}
    //       <Icon icon={'achievement'} />
    //     </div>
    //   ) : (
    //     <Button
    //       className={'List-Item_group-Button_action'}
    //       onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
    //         e.stopPropagation();
    //         this.joinAction(el);
    //       }}
    //       noPadding
    //       children={buttonText}
    //     />
    //   )
    // );
    /*el.checked = false;
    el.actions_data = {
      options: el.actions.map((action: string) => {
        const buttonProps: IButtonProps = {};
        if (action === 'edit') {
          buttonProps.type = 'rlink';
          buttonProps.href = '/group/' + el.pkid;
        } else {
          buttonProps.onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            // e.stopPropagation();
            this.action(action, el);
          };
        }
        return {
          title: i18n.t('pryaniky.list.groups.actions.' + action),
          type: action === 'edit' ? 'rlink' : undefined,
          buttonProps,
        };
      }),
    };*/
  }

  public action = (action: string, group: any) => {
    if (action === 'request') return this.joinAction(group);
    utilsProj.confirm({
      text: i18n.t('pryaniky.list.groups.confirm.' + action, { count: 1 }),
      onConfirm: () => {
        let request
        if (action === 'archive') request = utils.API.groups.archive(group.pkid, !group.isArchived);
        if (action === 'delete') request = utils.API.groups.remove(group.pkid);
        if (!request) return;
        request.r.then(response => {
          if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
          else if (response.error_code) toast.error(response.error_text);
          else {
            toast.success(i18n.t(`pryaniky.list.groups.actions.${action}Success`, { count: 1 }));
            this.setState(prevState => ({ data: prevState.data.filter(item => item.pkid !== group.pkid) }));
          }
        });
      },
    });
  };

  public checkAll = () => {
    const checked = this.state.selected.length !== this.state[this.dataVariable].length;
    this.setState(prevState => ({
      selected: checked ? prevState.data.slice() : [],
      [this.dataVariable]: prevState[this.dataVariable].map((el: any) => Object.assign(el, { checked })),
    }));
  };

  public checkItem = (pkid: string) => {
    this.setState(prevState => {
      const newData = prevState[this.dataVariable].map((el: any) => {
        if (el.pkid === pkid) el.checked = !el.checked;
        return el;
      });
      return {
        selected: newData.filter((el: any) => el.checked),
        [this.dataVariable]: newData,
      };
    });
  };

  public joinAction = (el: any) => {
    if (el.isMeInGroup) {
      utilsProj.confirm({
        text: i18n.t('pryaniky.list.groups.actions.confirm.leave', { name: el.name }),
        onConfirm: () => this.joinOrLeave(el, false),
      });
    } else this.joinOrLeave(el, true);
  };

  public joinOrLeave = (el: { [s: string]: any }, join: boolean) =>
    utils.API.groups.join(el.id, this.props.uid, join).r.then((d: any) => {
      if (!d) toast.error(i18n.t('pryaniky.toast.error.server'));
      else if (d.error_code) toast.error(d.error_Message);
      else {
        if (el.visibilityType === 1) {
          el.isRequestAccessSent = !el.isRequestAccessSent;
          el.isMeInGroup = el.isRequestAccessSent;
        } else {
          el.isMeInGroup = !el.isMeInGroup;
        }
        this.prepareData(el);
        this.props.changeListItem({
          id: this.listId,
          data: {
            item: el.id,
            data: el,
          }
        });
      }
    });

  public createGroup() {
    const createGroup = (data: { [s: string]: any }) => {
      utils.API.groups.create({ data }).r.then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          this.redirectUrl = '/group/' + d.data.pkid;
          this.setState({});
        } else {
          toast.error('group creation failed');
        }
      });
    };

    // () => DialogGroupCreate({}).then(() => {
    //   createGroup
    // })
    // utilsDep.modal({
    //   type: 'createGroup',
    //   header: 'create group',
    //   okBtnAction: createGroup,
    //   okBtnValidate: (data: any) => {
    //     return data.name !== '';
    //   },
    // });
  }

  public batchAction = (action: 'delete' | 'archive', ids: number[]) => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.list.groups.confirm.' + action, { count: ids.length }),
      onConfirm: () => {
        let request
        if (action === 'archive') request = utils.API.groups.archive(ids, true);
        if (action === 'delete') request = utils.API.groups.remove(ids);
        if (!request) return;
        request.r.then(response => {
          if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
          else if (response.error_code) toast.error(response.error_text);
          else {
            toast.success(i18n.t(`pryaniky.list.groups.actions.${action}Success`, { count: ids.length }));
            this.setState(prevState => ({ data: prevState.data.filter(group => !ids.includes(group.pkid)) }));
          }
        });
      },
    });
  };

  public renderChildren = () => {
    const { data, isFinished, isLoading, selected, hideData } = this.props;
    return (
      <React.Fragment>
        {/* <GroupCreate
          data={{}}
          isShown={this.state.createDialog}
          onClose={this.toggleGroupCreateDialog}
        /> */}
        <Header
          className={'rounded-top'}
          type={'common'}
          search={{
            placeholder: i18n.t('pryaniky.list.groups.search'),
            onChange: this.search,
          }}
          actions={{
            options: [
              {
                title: i18n.t('pryaniky.list.groups.actions.create'),
                buttonProps: {
                  onClick: this.toggleGroupCreateDialog,
                  // onClick: () => DialogGroupCreate({}).then(() => { }),
                },
              },
            ],
          }}
        />
        {/* isFinished: true */}
        <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData}>
          {data.map(el => (
            <Item
              lId={this.listId}
              iId={el}
              type={'common'}
              key={el}
              data_key={el}
            // checked={el.checked}
            // data={el.view_data}
            // onClick={this.checkItem}
            // actions={el.actions_data}
            />
          ))}
        </ListLite>
      </React.Fragment>
    );
  };
}

export const List = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListGroupsSelectorPresenter);
