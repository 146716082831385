import React from 'react';
import { connect } from 'react-redux'
import { IUnitEditProps, mapUnitEditStateToProps, mapUnitEditDispatchToProps, cnUnitEdit } from './UnitEdit.index'
import {
    Icon,
    Button,
    HelpTooltip,
    // DialogTitle,
    DialogActions,
    DialogContent,
} from 'uielements/src';
import './UnitEdit.scss'
import { i18n, Translate } from 'localization';
import { CostomNodePosition } from '../CustomNode/CostomNodePosition/CostomNodePosition'
import { EditPositions } from './EditPositions/EditPositions'
import { createModal, LeftDirectionTransitionComponent } from 'utils/src/DialogCreator';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import FormControl from '@material-ui/core/FormControl';
import InputMaterial from 'uielements/src/MaterialElements/Input/Input';
import buttonAdd from 'assets/svg/buttonAdd.svg'
import Suggest from 'uielements/src/MaterialElements/Autocomplete'
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle';
import * as utils from 'utils.project/utils.project'

const UnitEditPresenter: React.FC<IUnitEditProps> = ({
    unitData,
    isShown,
    handleClose,
    handleAccept,
    parentUnitData,
    addHeadOpen,
    addSubOpen,
    addNewUnit,
    editUnit,
    addVacancy,
    addNewUnitFromHeader,
    changeStructureDataOrgchart,
    saveStructureDataOrgchart,
    newUnitDataKill,
    getUnitByTagId,
    newPositionDataKill,
   
}) => {
    const [showPositionAdd, setShowPositionAdd] = React.useState(addSubOpen || addNewUnit ? true : false)
    const [showLeaderPositionAdd, setShowLeaderPositionAdd] = React.useState(addHeadOpen || addNewUnit ? true : false)
    const [showPositionEdit, setShowPositionEdit] = React.useState({ open: false, id: "" })
    const [showLeaderPositionEdit, setShowLeaderPositionEdit] = React.useState({ open: false, id: "" })
    const [prentModel, setParentModel] = React.useState<any>(parentUnitData ? {
        displayName: parentUnitData.displayName,
        id: parentUnitData.id,
        imgId: parentUnitData.imgId,
        imgUrl: parentUnitData.imgUrl,
        type: parentUnitData.type,
        users: []
    } : null)
    const [merge, setMerge] = React.useState<boolean>(false)

    const handlePositionAddOpen = (action: string) => {
        if (action === "Subuser") {
            setShowPositionAdd(true)
        }
        if (action === "Leader") {
            setShowLeaderPositionAdd(true)
        }
    };

    const closeDialog = (action: string) => {
        if (action === "Subuser") {
            setShowPositionAdd(false);
            setShowPositionEdit({ id: "", open: false })

        }
        if (action === "Leader") {
            setShowLeaderPositionAdd(false)
            setShowLeaderPositionEdit({ id: "", open: false })

        }

    }

    React.useEffect(() => {
        if (unitData && unitData.id && prentModel && prentModel.id) {
            changeStructureDataOrgchart({ ...unitData, id: unitData.id, parent: prentModel.id })
        }
       
    }, [prentModel && prentModel.id])

    const closeWithoutSave = () => {
        newUnitDataKill()
        newPositionDataKill()
        handleClose()
    }
    return ( 
        unitData ? <div>

            <DialogTitle onClose={closeWithoutSave}
            >
                {/* <h4 className="my-1"> */}
                    {addNewUnit ? i18n.t("pryaniky.orgchart.unit.add") : i18n.t("pryaniky.orgchart.unit.edit")}
                    {/* </h4> */}
            </DialogTitle>

            <DialogContent className={cnUnitEdit('Body')}>
                {(editUnit || addNewUnit) && <FormControl className={cnUnitEdit('NameSaggest')} fullWidth>
                    <Suggest
                        type='division'
                        multiple={false}
                        label={<Translate i18nKey={'pryaniky.orgchart.unit.name'} />}
                        value={unitData.displayName === "" ? undefined : { displayName: unitData.displayName, id: "", type: "division" }}
                        allowCreate={true}
                        onChange={(position: any) => {
                            changeStructureDataOrgchart({ ...unitData, id: unitData.id, displayName: position ? position.displayName : "" })                            
                            if(editUnit && position.id) {
                                utils.confirm({
                                    text: i18n.t('pryaniky.orgchart.unit.merge.text') ,
                                    title:  i18n.t('pryaniky.orgchart.unit.merge.title') ,
                                    onConfirm: () => {
                                        getUnitByTagId({tagId:position.id, unitForEdit: unitData})
                                        setMerge(true)
                                    },
                                    onCancel:()=> {
                                        changeStructureDataOrgchart({ ...unitData, id: unitData.id, displayName: position ? position.displayName : "" })
                                    }
                                })
                               
                            }else if(!editUnit && position.id ){
                                getUnitByTagId({ tagId:position.id });
                            }
                           
                        }
                        }
                    />
                </FormControl>}

                {addNewUnitFromHeader && <FormControl fullWidth>
                    <Autocomplete
                        multiple={false}
                        type={'units'}
                        label={i18n.t("pryaniky.orgchart.choose.parent.unit")}
                        value={prentModel}
                        onChange={(item: any) => {
                            setParentModel(item);
                        }}
                    />
                </FormControl>}

                {/* кнопки для добавления и редактирование руководителей */}

                {!addSubOpen && <div className={cnUnitEdit("Positions")}>
                    <div className={cnUnitEdit("Positions-Title")}><Translate i18nKey='pryaniky.orgchart.positions.leaders' /></div>

                    <div className={cnUnitEdit('Positions-Users')}>
                        {unitData?.rootPositionIds?.map((el: string) => <CostomNodePosition onClickEdit={() => setShowLeaderPositionEdit({ open: true, id: el ? el : "" })} editPositions={true} unitId={unitData.id} id={el} />)}
                        {!showLeaderPositionAdd && <Button theme="unstyled" className={cnUnitEdit('VacancyButton')} onClick={() => handlePositionAddOpen("Leader")}>
                            {/* <Icon icon='plus' /> */}
                            <img src={buttonAdd} />
                        </Button>}
                    </div>
                    {(showLeaderPositionAdd || showLeaderPositionEdit.open) &&
                        <EditPositions
                            leaderAddVacancy={addVacancy}
                            className={cnUnitEdit(addHeadOpen ? "active" : "")}
                            posId={showLeaderPositionEdit.id}
                            addLeader={true}
                            isShown={showPositionAdd}
                            onClose={() => closeDialog("Leader")}
                            unitId={unitData.id} />}

                </div>}


                {/* кнопки для добавления и редактирование сотрудников */}
                {!addHeadOpen && <div className={cnUnitEdit("Positions")}>
                    <div className={cnUnitEdit("Positions-Title")}><Translate i18nKey='pryaniky.orgchart.positions.employee' /></div>

                    <div className={cnUnitEdit('Positions-Users')}>
                        {unitData?.positionIds?.map((el: string) =>
                            <CostomNodePosition
                                onClickEdit={() => setShowPositionEdit({ open: true, id: el ? el : "" })}
                                unitId={unitData.id}
                                editPositions={true} id={el}
                            />

                        )}
                        {!showPositionAdd && <Button theme="unstyled" className={cnUnitEdit('VacancyButton')} onClick={() => handlePositionAddOpen("Subuser")}>
                            <img src={buttonAdd} />
                        </Button>}
                    </div>
                    {(showPositionAdd || showPositionEdit.open) &&
                        <EditPositions
                            addLeader={false}
                            leaderAddVacancy={addVacancy}
                            isShown={showPositionAdd}
                            onClose={() => closeDialog("Subuser")}
                            posId={showPositionEdit.id}
                            unitId={unitData.id} />}

                </div>}

            </DialogContent>
            <DialogActions >
                <div className={cnUnitEdit("Actions")}>
                    <Button className={cnUnitEdit("Actions-CancelBTN")} onClick={closeWithoutSave} noBorder noBackground color={"#0066CC"} padding="lg">
                        {i18n.t("cancel")}
                    </Button>
                    <Button main className={cnUnitEdit("Save")} onClick={() => {
                        handleAccept(merge)
                    }} padding="md" >
                        {i18n.t("pryaniky.saveChanges")}
                    </Button>
                </div>
            </DialogActions>
        </div> : null

    )
}



export const UnitEdit = connect(
    mapUnitEditStateToProps,
    mapUnitEditDispatchToProps
)(UnitEditPresenter)

export const editUnitModal = createModal(UnitEdit, {
    maxWidth: 'md',
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            marginLeft: "70%",
            width: "calc(100% - 70%)",
            marginRight: "0",
            maxHeight: "none",
            marginTop: "0",
            marginBottom: "0",
            height: "100vh"
        },

    },
    TransitionComponent: LeftDirectionTransitionComponent,
    className: 'Pane',
    scroll: 'body'
});