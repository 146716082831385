/**
 * @packageDocumentation
 * @module List_type_users_likers
 */
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from "../../../../redux/connector";

import { mapStateToProps, IListStateProps, IListProps, cnList, mapActionsToProps } from './../../List.index';
import { avalibleFilters } from './List_type_users.index';

import { ListLite, Button, Loading } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Header } from '../../Header/List-Header';
import { Item } from '../../Item/List-Item';
import { ListUsersPresenter } from './List_type_users';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { IStateType as IState } from 'redux/store';

export class ListUsersLikersPresenter extends ListUsersPresenter {
  public static defaultProps: any = {
    requestOptions: {
      nId: '',
      rId: '',
      search: '',
      skipCount: 0,
      count: 20,
    }
  };

  public classMods: any = {
    likers: true
  }

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getLikers, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading || !this.mounted) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFilters[cKey]) opts[avalibleFilters[cKey]] = context[cKey];
    });

    // opts.nId = context.nId;
    // opts.rId = context.cId;
    
    if (this.searchString && this.searchString !== '') opts.search = this.searchString;
    toggle({
      id,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });
    getLikers.call(this, id, opts);
  }

  public renderChildren = () => {
    // const { isFinished, isLoading, selected } = this.state;
    const { data, isFinished, isLoading, hideData } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    return (
      <React.Fragment>
        <Header
          type={'common'}
          search={{
            placeholder: i18n.t('pryaniky.list.users.search'),
            onChange: this.search
          }}
        />
        <ListLite skeletonCount={3} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData}>
          {data.map(el => 
            <Item
              lId={this.listId}
              iId={el} 
              type={'common'}
              key={el} 
              data_key={el} 
            />
          )}
        </ListLite>
      </React.Fragment>
    )
  }

}

export const List = connect<IListStateProps, IDispatchProps, IListProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(ListUsersLikersPresenter)