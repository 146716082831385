import React, { memo } from 'react';
import './Banners.scss';
import { IBannersProps, cnBanners, cnClassNameNoData, mapBannersMapStateToProps } from './Banners.index';
import { BannerWithoutRedux } from '../NewsTypes/Banner/component';
import { IBanner } from '../NewsTypes/Banner/interfaces';
import { connect } from 'react-redux';
import { getBannersForWidget } from 'utils/src/requests/requests.banners';
import { checkResponseStatus } from 'utils/src/utils';
import noDataImage from 'assets/svg/NoData.svg'
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { i18n, Translate } from 'localization';

export const BannersPresenter: React.FC<IBannersProps> = ({
  widgetId,
  closed,
  settings,
...props
}) => {

  const [banners, setBanners] = React.useState<IBanner[]>([]);
  const [count, setCount] = React.useState<number>(1);
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    getBannersForWidget(widgetId).then(d => {
      if (checkResponseStatus(d) && d.data) {
        setBanners(d.data);
      }
    })
  }, []);

  React.useEffect(() => {
    if (ref.current) {
      const refWidth = ref.current.clientWidth;
      const itemWidth = ref.current.querySelector('.NewsBannerItem')?.clientWidth || refWidth;
      const newCount = Math.floor(refWidth / itemWidth) || 1;
      setCount(newCount);
    }
  }, [ref]);

  if (settings?.hideIfNoData && !banners.length) return null;

  if (!settings?.hideIfNoData && !banners.length) return <div {...props} className={cnClassNameNoData({}, [props.className])}>
    <div className={cnClassNameNoData('Header-Title')} children={i18n.t('pryaniky.banner.widget.title')} />
    <div className={cnClassNameNoData('Header-noData')}> <Avatar precentage={100} shape={'rectangle'}
      imgUrl={noDataImage}
    />
    </div>
    <span className={cnClassNameNoData('Header-Title-Text')}> {i18n.t('pryaniky.banner.widget.text')} </span>
  </div>;
  return (
    <div ref={ref} className={cnBanners()}>
      <BannerWithoutRedux id={"anyID"} banners={banners} closed={closed} count={count} />
    </div>
  )
}

export const Banners = connect(
  mapBannersMapStateToProps
)(memo(BannersPresenter));