import React, { FC, memo, useEffect, useMemo } from 'react'
import { i18n, Translate } from 'localization';
import { CatTypography } from '../styled';
import { converAdditionalFieldsValuesV1ToAdditionalFieldsValues } from 'News/creator/types/Ideas/Ideas.sides';
import { FieldsEditor } from 'muicomponents/src/FieldRender';


const FieldsRender: FC<any> = ({ 
    userId, 
    user, 
    fieldsState, 
    setFieldsState, 
    isAuthUserAdmin, 
    setFieldsErrors,
    fieldsErrors,
    disablePastForDatePickers,
     }) => {

    const fieldsCategoryes = useMemo(() => !user.profileData ? {}
        :
        (user.profileData.userInfoFields.result as string[]).reduce<any>((acc: any, fieldId: string) => {
            const cat = user.profileData.userInfoFields.entities.fields[fieldId].field.category // cat просто строка - название категории
            acc[cat] = [...(acc[cat] || []), fieldId]
            return acc
        }, {}), [userId, user])
    const categoriesKeys = Object.keys(fieldsCategoryes).sort().filter((cat: string) => cat !== 'default.fields');

    const FieldsEditorArray = useMemo(() => {
        return categoriesKeys.map((catName, i) => {
            const thisCatFieldsIds: string[] = fieldsCategoryes[catName]
            const fieldsByCategory = thisCatFieldsIds.map(fieldId => user.profileData.userInfoFields.entities.fields[fieldId] )
            const normalisedFields = fieldsByCategory.map((f: any) => { // это чтобы админ мог изменять readOnly поля
                const fWithCorrectReadOnly = isAuthUserAdmin ? 
                {...f, field: {...f.field, isReadOnly: false, isHidden: false}} : f
                return converAdditionalFieldsValuesV1ToAdditionalFieldsValues(fWithCorrectReadOnly)
            } ).filter(c=>c.isHidden === false)
            const obj: any = {
                id: i.toString(),
                title: catName,
                fields: normalisedFields,
                description: '',
                isCollapsed: false,
                isExpansion: false,
            }
            return obj
        })
    }, [userId, user])


    useEffect(() => {
        setFieldsState(FieldsEditorArray)
    }, [userId, user])

    if (!fieldsState) return null

    return <>

        <CatTypography>
            {i18n.t('pryaniky.profile.additionalInfo')}:
        </CatTypography>

        <FieldsEditor
            fields={fieldsState}
            errors={fieldsErrors}
            onChange={(fields, errors) => {
                setFieldsState(fields)
                setFieldsErrors(errors)
            }}
            validate
            globalTagsCollection={'profile'}
            disablePastForDatePickers={disablePastForDatePickers}
            
        />

    </>
}

export default memo(FieldsRender)