import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'uielements/src';
import {
    cnSecretSantaWidgetList,
    mapDispatchToProps,
    mapStateToProps,
    ISecretSantaWidgetList,
} from './SecretSantaListWidget.index';
import { SecretSantaWidgetItem } from './Item/SecretSantaWidgetItem';
import { i18n, Translate } from 'localization';
import './SecretSantaListWidget.scss';
import noData from 'assets/svg/NoData.svg';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { CircularProgress } from '@material-ui/core';
import { useWidgetContext } from 'Widgets_v2/context';
import { getSecreteSantaListNewFetch } from 'utils/src/requests/admin.secreteSanta';
import { useScrollListState, useDebouncedQueryState } from 'utils/src/hooks';
// import TimelineHeader from 'main/src/News/containers/Slider/component';
import LoadButton from 'uielements/src/MaterialElements/ProgressButton';
import { styled } from 'muicomponents/src/mui/system';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'muicomponents/src/Icons';

import { Box, Button as MuiButton } from 'muicomponents/src';
import { appGeneratePath } from 'utils/src/utils.path';
import { mainUrls } from 'utils/src';
import { Link } from 'muicomponents/src/Link';
export const HeaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 12px 0',
    // margin: '24px 24px 0 24px',
});
export const NavigationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
export const TitleBox = styled(Box)({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '116%',
    padding: '6.5px 12px 6.5px 24px',
});
export const LoadButtonStyled = styled(LoadButton)({
    height: '100%',
    minWidth: '32px',
    textAlign: 'center',
    borderRadius: '0',
    cursor: 'pointer',
    fontSize: '25px',
    padding: '0',
});
async function getSecretSantaListPromise(skipCount: number, count: number) {
    const response = await getSecreteSantaListNewFetch(skipCount, count, { IsAdminRequest: false });
    return await response.r;
}

const method = (skipCount: number, count: number) =>
    async function () {
        return (await getSecretSantaListPromise(skipCount, count)).data;
    };

const SecretSantaWidgetListPresenter = (props: ISecretSantaWidgetList) => {
    const { tag: Tag = 'div', settings, dataLength } = props;
    const count = 10;

    const {
        isFinished,
        isLoading,
        loadMore,
        triggerRef,
        values,
        error: { withError },
        actions: { updateItem, removeItems },
    } = useScrollListState(method(dataLength ? dataLength : 0, count), []);

    const valuesLength = values?.length;
    const [active, setActive] = useState<number>(1);

    const findStateClosedData = values.find((data: any) => data.state === 'Closed');

    if (values && findStateClosedData !== undefined) {
        removeItems([findStateClosedData]);
    }
    if (settings?.hideIfNoData && !valuesLength)
        return <Tag className={cnSecretSantaWidgetList({})} {...{ ...props, isHidden: !valuesLength }} />;
    if (!settings?.hideIfNoData && !valuesLength && !isLoading)
        return (
            <Tag {...props} className={cnSecretSantaWidgetList({}, [props.className])}>
                <Box
                    sx={{
                        textTransform: 'inherit',
                        margin: '6.5px 0',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#000',
                        lineHeight: '116%',
                    }}
                    className={cnSecretSantaWidgetList('Header-Title')}
                >
                    <Translate i18nKey={'pryaniky.administration.secretSanta.title'} />
                </Box>
                <Avatar precentage={100} shape={'rectangle'} imgUrl={noData} />
                <span className={cnSecretSantaWidgetList('Header-Title-Text')}>
                    {' '}
                    <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
                </span>
            </Tag>
        );

    const keys = values?.map((el, ind) => ind + 1) || [];

    let disableNavigation = isFinished && keys.length === 0;
    disableNavigation = (isLoading && keys.length === 0) || disableNavigation;
    disableNavigation = (isFinished && keys.length === 1) || disableNavigation;

    const next = () => {
        if (!isFinished && active === valuesLength) {
            loadMore();
        } else {
            const idx = (keys.findIndex((e) => e == active) + 1) % keys.length;
            setActive(keys[idx]);
        }
    };

    const previous = () => {
        let idx = keys.findIndex((e) => e == active) - 1;
        if (idx < 0) idx = keys.length - 1;
        setActive(keys[idx]);
    };

    return (
        <Tag {...props} className={cnSecretSantaWidgetList({}, [props.className])}>
            <HeaderBox className={cnSecretSantaWidgetList('Header')}>
                <Box className={cnSecretSantaWidgetList('Header-Title')}>
                    <Link
                        sx={{
                            textTransform: 'inherit',
                            margin: '6.5px 0',
                            fontSize: '16px',
                            fontWeight: '700',
                            color: '#000',
                            lineHeight: '116%',
                            width: 'fit-content',
                            marginY: '0',
                            '&:hover': {
                                background: '#fff',
                            },
                        }}
                        href={appGeneratePath(mainUrls.secretsanta.id, { id: values[active - 1]?.id || 'empty' })}
                        target="_blank"
                    >
                        {active && values?.length > 0 && values[active - 1]?.displayName}
                    </Link>
                </Box>
                {!disableNavigation && (
                    <NavigationBox className={cnSecretSantaWidgetList('Navigation')}>
                        <LoadButtonStyled className={cnSecretSantaWidgetList('Button')} onClick={previous}>
                            <KeyboardArrowLeft
                                color="primary"
                                className={cnSecretSantaWidgetList('Icon CustomStyle primaryColor3-text')}
                            />
                        </LoadButtonStyled>
                        <Box
                            sx={{
                                fontSize: '16px',
                                display: 'flex',
                                lineHeight: '19.92px',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                            className={cnSecretSantaWidgetList('Indicator')}
                        >
                            {active}
                            <span style={{ color: '#ccc' }}>
                                {'/'}
                                {valuesLength}
                            </span>
                        </Box>
                        <LoadButtonStyled
                            isLoading={isLoading}
                            className={cnSecretSantaWidgetList('Button')}
                            onClick={next}
                        >
                            {/* <Icon icon={'chevron-right'} /> */}
                            <KeyboardArrowRight
                                color="primary"
                                className={cnSecretSantaWidgetList('Icon CustomStyle primaryColor3-text')}
                            />
                        </LoadButtonStyled>
                    </NavigationBox>
                )}
            </HeaderBox>
            <Box className={cnSecretSantaWidgetList('Santa')}>
                {isLoading ? (
                    <Box className={cnSecretSantaWidgetList('Santa-Loading')}>
                        {' '}
                        <CircularProgress />
                    </Box>
                ) : (
                    active &&
                    values?.length > 0 &&
                    values[active - 1] && (
                        <SecretSantaWidgetItem
                            isListWidget={true}
                            removeItemIfPresentSend={removeItems}
                            settings={{ ...settings, type: { id: values[active - 1]?.id } }}
                        />
                    )
                )}
            </Box>
        </Tag>
    );
};

export const SecretSantaWidgetList = connect(mapStateToProps, mapDispatchToProps)(SecretSantaWidgetListPresenter);
