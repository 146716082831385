import * as React from 'react';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import {
  Avatar,
  // DialogTitle,
  // DialogActions,
  Button,
} from 'uielements/src';
import { cnImageCropper, IImageCropperProps, IImageCropperState, IImageCropperOwnProps } from './ImageCropper.index';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
import './ImageCropper.scss';
import { wrapDialogToRedux } from '../DialogWrapper';

class ImageCropperPresenter extends React.Component<IImageCropperProps, IImageCropperState> {
  public static file: boolean = true;
  public el?: HTMLElement | null;
  public imageRef?: HTMLElement | null;
  private croppedData?: BlobPart;
  public fileRealSize: {
    width: number;
    height: number;
  } = {
      width: 0,
      height: 0
    }
  public fileUrl: any;

  constructor(props: IImageCropperProps) {
    super(props);
    this.state = {
      file: props.data.file,
      crop: {
        x: 0,
        y: 0,
        aspect: props.data.aspect !== undefined ? props.data.aspect : 1 / 1,
        width: props.data.width !== undefined ? props.data.width : 200,
        height: props.data.height !== undefined ? props.data.height : undefined,
      }
    }
  }

  public componentDidMount() {
    this.props.data.file && this.readFile(this.props.data.file);
  }

  public componentDidUpdate(pp: IImageCropperProps) {
    if(!utils.compareData(this.props.data.file, pp.data.file)) {
      this.readFile(this.props.data.file);
    }
    if (
      !utils.compareData(this.props.data.aspect, pp.data.aspect) ||
      !utils.compareData(this.props.data.width, pp.data.width)
    ) {
      const { aspect, width } = this.props.data;
      this.setState((ps) => {
        ps.crop.aspect = aspect !== undefined ? aspect : 1 / 1;
        ps.crop.width = width !== undefined ? width : 200;
        return ps;
      });
    }
  }

  public render() {
    const { state, props, onConfirm} = this;
    const { data, onClose } = props;
    const { fileData, crop, file } = state;
    return (
      <>
        <DialogTitle onClose={onClose}>{i18n.t('pryaniky.modal.imageCropper.title')}</DialogTitle>
        {
          !file &&
          <input className={cnImageCropper('Input')} type='file' onChange={this.onSelectFile} />
        }
        {
          // fileData &&
          <div className={cnImageCropper('ReactCrop')}>
            <ReactCrop
              src={fileData}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </div>
        }
        {
          this.state.cropUrl &&
          <div className={cnImageCropper('Preview')}>
            {
              !data.previewType &&
              <>
                <img className={cnImageCropper('Preview-Item', { base: true })} src={this.state.cropUrl} />
              </>
            }
            {
              data.previewType === 'avatar' && 
              <>
                <Avatar
                  className={cnImageCropper('Preview-Item', { profile: true })}
                  imgUrl={this.state.cropUrl}
                  noBorderRadius={true}
                  size={180}
                />
                <Avatar
                  className={cnImageCropper('Preview-Item', { author: true })}
                  imgUrl={this.state.cropUrl}
                  size={56}
                />
                <Avatar
                  className={cnImageCropper('Preview-Item', { reply: true })}
                  imgUrl={this.state.cropUrl}
                  size={40}
                />
              </>
            }
          </div>
        }
        <DialogActions
        acceptText={i18n.t('save')}
        closeText={i18n.t('pryaniky.cancel.dialog')}
        onAccept={onConfirm}
        onClose={onClose}
        />
          {/* <Button main onClick={onConfirm} padding="lg">
            {i18n.t('ok')}
          </Button>
          {/* TODO select full image */}
          {/* <Button main onClick={onConfirm} padding="lg">
            {i18n.t('pryaniky.dialogs.cropper.selectFullImage')}
          </Button> */}
          {/* <Button onClick={onClose} padding="lg">
            {i18n.t('cancel')}
          </Button>
        // </DialogActions> */}
      </>
    )
  }

  private readFile = (file: File) => {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      this.setState({ fileData: reader.result })
    );
    reader.readAsDataURL(file);
  }

  private onConfirm = () => {
    if (this.props.onConfirm) {
      let fileName = 'newImg.png';
      if (this.state.file) {
        const lastDot = this.state.file.name.lastIndexOf('.');
        fileName = this.state.file.name.substring(0, lastDot) + '-CROPED.png';
      }
      this.props.onConfirm(
        new File([this.croppedData || ''], fileName), 
        { 
          aspect: this.state.crop.width / (this.state.crop.height || this.state.crop.width),
          width: this.state.crop.width,
          height: this.state.crop.height
        }
        );
    }
  }

  private onImageLoaded = (imageHTMLElem: any) => {
    const classes: any[] = [];
    for (const className of imageHTMLElem.classList) {
      classes.push(className);
    }
    imageHTMLElem.className = '';
    this.fileRealSize.height = imageHTMLElem.clientHeight;
    this.fileRealSize.width = imageHTMLElem.clientWidth;
    classes.forEach(className => imageHTMLElem.classList.add(className));
    this.imageRef = imageHTMLElem;
  }

  private onCropChange = (crop: any) => this.setState({ crop });

  private getCroppedImg = (image: any, crop: any, fileName: string) => {
    const canvas = document.createElement('canvas');
    const realCrop: { [s: string]: any } = {
      x: crop.x,
      y: crop.y,
      aspect: crop.aspect,
      width: crop.width,
      height: crop.height
    }

    if (image.clientHeight !== this.fileRealSize.height && image.clientWidth !== this.fileRealSize.width) {
      const height = (crop.height * 100) / image.clientHeight;
      realCrop.height = (height * this.fileRealSize.height) / 100;
      const width = (crop.width * 100) / image.clientWidth;
      realCrop.width = (width * this.fileRealSize.width) / 100;
      const x = (crop.x * 100) / (image.clientWidth);
      realCrop.x = (x * this.fileRealSize.width) / 100;
      const y = (crop.y * 100) / (image.clientHeight);
      realCrop.y = (y * this.fileRealSize.height) / 100;
    }

    canvas.width = realCrop.width;
    canvas.height = realCrop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        realCrop.x,
        realCrop.y,
        realCrop.width,
        realCrop.height,
        0,
        0,
        realCrop.width,
        realCrop.height,
      );
    }

    return new Promise((resolve: any, reject: any) => {
      canvas.toBlob(blob => {
        if(!blob) return ;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ blob, fileUrl: this.fileUrl });
      }, 'image/png');
    });
  }

  private onCropComplete = (crop: any) => {
    if (crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png',
      );
      croppedImageUrl.then((d: any) => {
        this.setState({ cropUrl: d.fileUrl }, () => {
          let fileName = 'newImg.png';
          if (this.state.file) {
            const lastDot = this.state.file.name.lastIndexOf('.');
            fileName = this.state.file.name.substring(0, lastDot) + '-CROPED.png';
          }
          // this.changeStore({ croppedData: d.blob});
          this.croppedData = d.blob;
          // this.props.parent.state.data.cropedFile = new File([d.blob], fileName);
          // this.props.parent.setMargin();
        });
      })
    }
  }

  private onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      this.readFile(e.target.files[0]);
    }
  }

}

export const ImageCropper = wrapDialogToRedux<IImageCropperOwnProps>(ImageCropperPresenter)