import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets'
import { guids } from "./index"
import ideasLogo from 'assets/png/Ideas.png'
import { pageHeader } from './pageHeaders'

export const polls = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.info,
            data: pageHeader.polls
          }),
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.timeline,
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
              // generateColumn({
              //   items: [
              //     generateWidget({
              //       // id: ids.filters,
              //       type: widgets.types.filters + '/polls',
              //       relations: [],
              //       data: [],
              //     }),
              //   ],
              //   styles: {
              //     width: '28%',
              //   },
              // }),
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  })
}