import './InfoBlock-Tag.scss';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { ITagProps, cnTag, ITagStateProps, mapStateToProps, } from './InfoBlock-Tag.index';
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
import { compose } from '@bem-react/core';
import * as utils from 'utils/src/utils'
import { i18n, Translate } from 'localization';
import MDRender from 'uielements/src/CommonmarkRender';
import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from 'muicomponents/src/Icons';
import { DraftInput, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { styled } from 'muicomponents/src';
import { editCreateTag } from 'utils/src/requests/admin.tags';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

const StyledEditBox = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  border: '1px solid green'
});

export const TagInfoBlock: React.FC<ITagProps> = ({ tId }) => {

  const [tag, setTag] = useState<any>(null);

  useEffect(() => {
    utils.API.suggesters.search('tags', tId).r.then((d) => {
      if (utils.checkResponseStatus(d)) {
        if (d.data[0]) setTag(d.data[0])
      }
    })
  }, []);

  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);

  const isEditable = useSelector((state: any) => state.store?.isEditable as boolean);
  const isAdmin = useSelector(getCurrentUserIsAdmin);

  const {
    state,
    draftState,
    setDraftState
  } = useDraftEdit(tag?.description || '', !edit, [edit, tag?.description])

  return (
    <div className={cnTag('Inner')}>
      <div className={cnTag('Info')}>
        {
          (isAdmin || isEditable) &&
          <StyledEditBox>
            {
              !edit
                ?
                <IconButton
                  onClick={() => setEdit(true)}
                  size={'small'}
                >
                  <EditIcon fontSize={'small'} />
                </IconButton>
                :
                <>
                  <IconButton
                    size={'small'}
                    onClick={async function () {
                      setSaving(true);
                      const newTag = {
                        ...tag,
                        description: state.text
                      };
                      const response = await editCreateTag(newTag);
                      if (utils.checkResponseStatus(response)) {
                        setTag(newTag);
                        setEdit(false)
                      } else {
                        toast.error(<Translate i18nKey='error' />);
                      }
                      setSaving(false);
                    }}
                  >
                    {
                      saving
                        ? <CircularProgress size={20} />
                        : <CheckIcon fontSize={'small'} />
                    }
                  </IconButton>
                  <IconButton
                    size={'small'}
                    onClick={() => setEdit(false)}
                  >
                    <CloseIcon fontSize={'small'} />
                  </IconButton>
                </>
            }
          </StyledEditBox>
        }
        <h5 className={cnTag('Title')}>{i18n.t('pryaniky.tags.search.foundBy')}</h5>
        <h3 className={cnTag('TagName')}>{tag && tag.displayName}</h3>
        {
          tag &&
          (
            edit
              ? <DraftInput label={<Translate i18nKey='description' />} value={draftState} onChange={setDraftState} />
              : (tag.description && tag.description !== '0') && <MDRender textCut={true} maxCharCount={250} source={tag.description} />
          )
        }
      </div>
    </div>
  );
};

export const Tag = connect<ITagStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(

)(TagInfoBlock))