import * as React from 'react';

import { cnFooter, IFooterProps, IFooterState } from './Modal-Footer.index';
import { Button } from 'uielements/src/Button/Button';

import './Modal-Footer.scss';
import { i18n, Translate } from 'localization';

export class FooterPresenter extends React.Component<IFooterProps, IFooterState> {
  public el: HTMLElement | null;

  public render() {
    return (
      <div ref={el => this.el = el} className={cnFooter({border: this.props.border}, [this.props.className])}>
        <Button disabled={!this.props.dataIsValid} onClick={this.props.okBtnAction} className={cnFooter('Button')} main children={this.props.okBtnText || i18n.t('ok')} />
        <Button onClick={this.props.cancelBtnAction} className={cnFooter('Button')} noBackground noBorder children={this.props.cancelBtnText || i18n.t('cancel')} />
      </div>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Footer = FooterPresenter;