import { connect } from 'react-redux';
import React, { useEffect, useState, FC } from 'react';
import { i18n, Translate } from 'localization';
import { Avatar, SearchInput, Button } from 'uielements/src'
// import { Admin } from '../Parts/Admin/Admin';
// import { Thread } from '../Parts/Thread/Thread'
// import { UnitsList } from '../Parts/UnitsList/UnitsList'
// import { CourseHeader } from '../Parts/CourseHeader/CourseHeader'
import { mapStateToProps, ICommentsProps, cnComments, mapDispatchToProps } from './Comments.index';
import './Comments.scss';
import { CommentsLayout } from 'blocks/NewsTypes/common/CommentsLayout/CommentsLayout'
import { ReplyEditor } from 'blocks/ReplyEditor/ReplyEditor'
import { Reply } from 'blocks/Reply/Reply'
import { ReplySkeleton } from 'blocks/Skeletons/Replys/Reply-Skeleton'
import { withPreloader } from '../Preloader/Preloader'
const zeroId = "00000000-0000-0000-0000-000000000000";

export const CommentsPresenter: FC<ICommentsProps & any> = ({ className, news, nid, loadComments, avatarSize = 40 }) => {
    // useEffect(() => { !news && getNewsByIdRequest(nid) }, [nid]);

    // const [tab, setTab] = useState<string>('tasks');

    // if (!news) return <div>no</div>


    const loadAllComments = () => {
        loadComments({ id: nid, commentsCount: news.commentsCount })
    }

    return (
        <div className={cnComments({}, [className])}>
            <CommentsLayout
                more={
                    (news.comments && (news.comments.length < news.commentsCount)) && (
                        <Button className={cnComments('MoreButton')} theme={'unstyled_center'} onClick={loadAllComments}>
                            {i18n.t('pryaniky.post.comments.reveal', { count: news.commentsCount - news.comments.length })}
                        </Button>)
                }
                form={
                    <ReplyEditor
                        // key={newComment.id}
                        // newComment={newComment}
                        withRating={news.contentRating?.enable}
                        avatarSize={avatarSize}
                        newsAuthor={{}}
                        toggleThank={() => { }}
                        isThanks={false}
                        users={[]}
                        newsId={nid}
                    />
                }
            >

                {news.comments && news.comments.map((commentId: string) => commentId === 'skelet' ? <ReplySkeleton /> : <Reply
                    avatarSize={avatarSize}
                    // additionalReplyActions={additionalReplyActions}
                    fund={0}
                    className=''
                    setRewardComment={() => { }}
                    deleteRewardComment={() => { }}
                    allowReply={true}
                    rewards={false}
                    key={commentId}
                    id={commentId}
                    newsID={nid} />)}
            </CommentsLayout>
        </div>
    );
};

const WithPreloader = withPreloader(CommentsPresenter, ['news'], [])

const Loader: FC<any> = (props) => {
    const { nid, news, loadNewsById } = props

    useEffect(() => { !news && loadNewsById({ id: nid }) }, [nid]);

    return <WithPreloader {...props} />
}
export const Comments = connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);
