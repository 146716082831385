import React, { FC, useState, useEffect } from "react";
import { i18n, Translate } from 'localization';
import { connect } from "react-redux";
import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
import { cn } from "@bem-react/classname";
import {
    IHandGoalDialogStateProps,
    IHandGoalDialogProps,
    IHandGoalDialogOwnProps,
    mapStateToProps
} from "./HandGoalDialog.index";
import "./HandGoalDialog.scss";
import { getDeclinedNumeral } from "utils/src/utils";
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogBody } from 'muicomponents/src/DialogParts/DialogBody'
import MDRender from 'muicomponents/src/CommonmarkRender'
import { Box } from 'muicomponents/src/Box/Box'
import { TextField } from 'muicomponents/src/TextField/TextField'
import { FilesUploader } from 'muicomponents/src/FilesUploader/FilesUploader'
import { useFileUploader } from 'utils/src/hooks'
import { Divider } from '@mui/material';

export const cnHandGoalDialog = cn("HandGoalDialog");


export const HandGoalDialogPresenter: FC<IHandGoalDialogProps> = ({
    data, 
    goal, 
    attachments,
    description,
    text,
    handleAccept,
    handleClose
    // onClose, 
    // onConfirm 
}) => {

    const [goalData, setGoalData] = useState({ ...goal })
    const bonusWord = getDeclinedNumeral(goal.bonus, new Array(3).fill('').map((_: any, idx: number) => i18n.t(`pryaniky.widgets.quests.bonus.${idx}`)));

    const { files, onFileChange, uploadDisabled, getAttachmentsList, removeFile } = useFileUploader({
        uploadOnAdd: true,
        defaultAttachments: goalData.attachments
    })
    useEffect(() => {
        setGoalData({ ...goalData, attachments: getAttachmentsList() })
    }, [files])

    const onCommentChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setGoalData({ ...goalData, comment: ev.target.value })
    }

    let disableAccept = !(!goal.pushed/* && !goal.isAutoApprove*/ && !goal.isCompleteByMe && goal.type === 11)
    disableAccept = uploadDisabled ? true : disableAccept;
    return <DialogBody
        acceptText={i18n.t("pryaniky.widgets.HandGoal.button")}
        closeText={i18n.t('pryaniky.cancel.dialog')}
        onAccept={() => handleAccept(goalData)}
        onClose={handleClose}
        disableAccept={disableAccept}
        header={<>{goal.name} {goal.bonus > 0 ? ` - ${goal.bonus} ${bonusWord}` : ''}</>}
    >
        <Box className={cnHandGoalDialog("Content")}>

            {goal.type !== 11 && <Box className={cnHandGoalDialog("Text")}>
                {i18n.t('pryaniky.widgets.HandGoal.auto')}
            </Box>}

            {text && <Box className={cnHandGoalDialog("Text")}>
                {text}
            </Box>}

            {description &&
                <MDRender type='full' source={description || ''} />
            }

            {(!goal.pushed && !goal.isCompleteByMe && goal.type === 11) &&
                <TextField
                    sx={{ marginTop: '12px', marginBottom: '12px' }}
                    className={cnHandGoalDialog("Input")}
                    label={i18n.t("pryaniky.widgets.HandGoal.placeholder")}
                    // autoFocus
                    multiline
                    rows={5}
                    fullWidth
                    onChange={onCommentChange}
                    value={goalData.comment} />}

            {(!goal.pushed && !goal.isCompleteByMe && goal.type === 11) &&
                <>
                    <Divider sx={{marginBottom: '12px'}} />

                    <FilesUploader
                        files={files}
                        removeFile={removeFile}
                        disabled={uploadDisabled}
                        onFileChange={onFileChange}
                        selectButtonProps={{
                            variant: 'outlined',
                            children: i18n.t('pryaniky.widgets.HandGoal.add.attachments')
                        }}
                    />
                </>

            }

        </Box>
    </DialogBody>
};

export const HandGoalDialog = connect<IHandGoalDialogStateProps, IDispatchProps, IHandGoalDialogOwnProps>(
    mapStateToProps,
    mapDispatchToProps({})
)(HandGoalDialogPresenter)

export const DialogHandGoal = createModal(HandGoalDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});



