import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import shopPlaceholder from 'assets/svg/shopPlaceholder.svg';
import { openShopMakeOrderWithMultiOrder } from 'blocks/Dialogs/Shop/ShopMakeOrder/ShopMakeOrder';
import { Location } from 'history';
import { i18n, Translate } from 'localization';
import { Box, Button, Divider, FavoriteIcon, GiftChip, MultiOrderChip, Paper, Typography } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton';
import { Link } from 'muicomponents/src/Link/Link';
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton';
import { ILoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton.index';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { CommentsRender } from 'News/containers/CommentsRender';
import { contextAction } from 'News/redux/actions';
import { ComponentProps, FC, forwardRef, default as React, useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router';
import { SHOP_CHECK_CHARACTERISTICS, SHOP_GET_SHOP_PRODUCT } from 'redux/shop/actions';
import { Icon, ListPlaceholder } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender';
import DialogBody from 'uielements/src/MaterialElements/DialogParts/DialogBody';
import { SkeletonProductCard } from 'uielements/src/Skeleton';
import * as utilsProj from 'utils.project/utils.project';
import { mainUrls } from 'utils/src';
import { ILoadingProps, withIndicator } from 'utils/src/CommonRedux/LoadIndicator';
import { createModal, IBodyProps } from 'utils/src/DialogCreator';
import { Characteristics as CharacteristicsTypes, IShopItem } from 'utils/src/requests/models/api.shop';
import Characteristics from '../Characteristics/Characteristics.index';
import { CountSelector } from '../CountSelector';
import { CurrentCategory } from '../CurrentCategory/CurrentCategory';
import { PriceElem } from '../Item/PriceElem';
import { TotalElem } from '../Item/TotalElem';
import PhotoViewerO from '../PhotoViewer/PhotoViewer.index';
import { ShopListContextProvider, useShopListContext } from '../ShopList/ShopListMui.context';
import { countMissingThanks } from '../utils';
import { orderIsDenied } from '../validation';
import { cnShopProduct, IShopCardProps, mapDispatchToProps, mapStateToProps } from './ProductCardMui.index';
import { InfoBox, InfoDelivery, InfoOrder, IsGiftContainer, ProductContainer } from './ProductCardMui.styled';
import { RatingShopMui } from './RatingShopMui/RatingShopMui';

const LayoutPaperStyle = {
    padding: '24px',
    maxWidth: '1094px',
    margin: '24px',
};

const SpinBtn = withIndicator<ILoadingButton & ILoadingProps>(
    forwardRef(({ isLoading, withError, ...props }, ref) => {
        return <LoadingButton {...props} loading={isLoading} ref={ref} />;
    }),
    SHOP_CHECK_CHARACTERISTICS
)();
const PhotoViewer = withIndicator(PhotoViewerO, SHOP_CHECK_CHARACTERISTICS)();

const prepareRatingValue = (rating?: number) => {
    if (!rating) return 0;
    if (rating === -1) return 0;
    return rating;
};

const isAnyCharacteristicChosen = (characteristics: CharacteristicsTypes.IItem[]) => {
    if (characteristics.length === 0) return true;
    const selectedArr = characteristics.map((characteristic) => {
        return characteristic.tags.some((tag) => tag.isSelected);
    });
    return selectedArr.some((item) => item);
};

type IsFavouriteType = 'addToFavorites.on' | 'addToFavorites.off';

const DescriptionDialog: FC<{ disclaimer: string } & IBodyProps> = ({ handleAccept, handleClose, disclaimer }) => {
    return (
        <DialogBody
            header={i18n.t('pryaniky.shop.product.dsc.dialog.header')}
            contentPadding={'normal'}
            acceptText={i18n.t('pryaniky.shop.product.dsc.dialog.accept')}
            closeText={i18n.t('pryaniky.shop.product.dsc.dialog.close')}
            onClose={handleClose}
        >
            <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
        </DialogBody>
    );
};
const openDiscription = createModal(DescriptionDialog);

const ProductCardLayout: FC = ({ children }) => {
    const history = useHistory<{ from: Location }>();

    const onClickHandler: ComponentProps<typeof Button>['onClick'] = (e) => {
        if (history.location.state?.from && history.location.state.from.pathname === mainUrls.shop) {
            e.preventDefault();
            history.goBack();
        }
    };

    return (
        <>
            <Paper sx={LayoutPaperStyle}>
                <Button
                    to={mainUrls.shop}
                    variant="outlined"
                    sx={{ textTransform: 'lowercase' }}
                    size="small"
                    onClick={onClickHandler}
                >
                    <ArrowBackIcon sx={{ mr: 1, width: '20px' }} />
                    {i18n.t('pryaniky.shop.product.backToShop')}
                </Button>
            </Paper>
            <Paper sx={LayoutPaperStyle}>{children}</Paper>
        </>
    );
};

const ProductCardComments: React.FC<any> = ({ data }) => {
    const allowFeedback = data.news && data.news.allowReply && data.allowComments;
    return (
        <>
            {data.news && data.allowComments && (
                <CommentsRender id={data.news.id} className={cnShopProduct('Comments', { allowFeedback })} />
            )}

            {!allowFeedback && data.allowComments && (
                <div className={cnShopProduct('CommentsDisabled')}>
                    {i18n.t('pryaniky.shop.product.comments.disabled')}
                </div>
            )}
        </>
    );
};

const ProductPreloader: React.FC<IShopCardProps> = (props) => {
    const { data, ...p } = props;
    // Загрузка товара
    useEffect(() => {
        if (`${data?.id}` !== p.match.params.id) p.getShopProduct(p.match.params.id);
    }, [p.match.params.id]);

    if (props.isLoading) {
        return (
            <ProductCardLayout>
                <SkeletonProductCard />
            </ProductCardLayout>
        );
    }
    if (!data) {
        return (
            <ProductCardLayout>
                <SkeletonProductCard />
            </ProductCardLayout>
        );
    }
    return (
        <ShopListContextProvider>
            <ProductCardPresenter {...p} data={data} />
        </ShopListContextProvider>
    );
};

const ProductCardPresenter: React.FC<IShopCardProps & { data: IShopItem }> = ({
    data,
    setShopProduct,
    isHidePricesAndButtonBuy,
    additionalInformationBeforeOrder,
    maxOrdersCount,
    maxOrdersPeriod,
    ordersCount,
    userThanksCount,
    currencyFormats,
    contentRating,
    currentImgUrl,
    selectedCharsCount,
    checkCharacteristics,
    allowOrder,
    errorMessage,
    currentImage,
    news,
    isHideButtonBuy,
    ...props
}) => {
    const dispatch = useDispatch();

    const context = useShopListContext();

    const { characteristics = [], thanksPrice, id, minVirtCurrencyPayment, availableCount, isUnlimitedProduct } = data;
    const onChangeChar = useCallback(
        (value: CharacteristicsTypes.IItem[]) => {
            checkCharacteristics(id, value);
        },
        [id]
    );

    const [countToOrder, setCountToOrder] = useState<number | undefined>(1);

    // для расчета, хватает ли юзеру пряников, если цена товара завивит от характеристик:
    // пока характеристики не выбраны юзером, сравниваем userThanksCount с минимальной ценой на товар, если выбраны характеристики, то уже с ценой на конкретный товар
    const priceDependsOnChars = characteristics.some((char) => char.usedForPrice) && minVirtCurrencyPayment > -1;
    const areCharsTouched = isAnyCharacteristicChosen(characteristics);
    const priceForUserMoneyCount =
        !priceDependsOnChars || areCharsTouched || minVirtCurrencyPayment === -1 ? thanksPrice : minVirtCurrencyPayment;
    const priceByCount = !isNaN(priceForUserMoneyCount)
        ? (countToOrder || 0) * priceForUserMoneyCount
        : priceForUserMoneyCount;
    const missingThanks = countMissingThanks(priceByCount, userThanksCount);

    const isAmountValid = isUnlimitedProduct || availableCount;
    const isNotCanBuy = orderIsDenied({
        characteristics,
        maxOrdersCount,
        maxOrdersPeriod,
        ordersCount,
        allowOrder,
        errorMessage,
    });
    const cannotBuyWhileChars = isNotCanBuy === 'Товар с выбранными параметрами закончился';

    async function openDescription() {
        if (data.disclaimer) {
            try {
                const result = await openDiscription({ disclaimer: data.disclaimer });
            } catch (error) {
                console.warn(error);
            }
        }
    }

    const isFavouriteFromNew: IsFavouriteType = news?.actions.includes('addToFavorites.on')
        ? 'addToFavorites.on'
        : 'addToFavorites.off';
    const isFavourite = news ? isFavouriteFromNew === 'addToFavorites.off' : false;
    async function addToFavourites() {
        if (!news?.id || !news) {
            console.warn('No news data');
            return;
        }

        dispatch(
            contextAction({
                action: isFavouriteFromNew,
                id: news.id,
                disableConfirm: true,
                disableToast: true,
            })
        );
    }

    const useCountSelector = useMemo(() => {
        return data.externalServiceType !== 'Giftery' && data.maxItemsInOrder > 1;
    }, [data.externalServiceType]);

    const maxForCountSelector = useMemo(() => {
        return Math.min(data.maxItemsInOrder, !isUnlimitedProduct ? selectedCharsCount || availableCount : 9999);
    }, [isUnlimitedProduct, selectedCharsCount, availableCount]);

    return (
        <>
            <ProductCardLayout>
                {data.isActive ? (
                    <ProductContainer className={cnShopProduct('Main')}>
                        <PhotoViewer
                            images={data.images}
                            currentImage={currentImage}
                            currentImgUrl={currentImgUrl}
                            imgUrls={data.images.map((el) => el.previewUrl)}
                            onChange={(currentImgUrl, currentImage) => setShopProduct({ currentImgUrl, currentImage })}
                            className={cnShopProduct('Photos')}
                        />
                        {(data.isGift || data.multiOrderItem) && (
                            <IsGiftContainer>
                                {data.isGift && <GiftChip className={cnShopProduct('CanBeGiftedMark')} />}
                                {data.multiOrderItem && (
                                    <MultiOrderChip className={cnShopProduct('CanBeMultiOrderMark')} />
                                )}
                            </IsGiftContainer>
                        )}

                        <InfoBox className={cnShopProduct('Info')} sx={{ marginLeft: '24px', width: '60%' }}>
                            <Box className={cnShopProduct('Info-Header')} sx={{ order: 0 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" fontWeight="600">
                                        {data.name}
                                    </Typography>
                                    <Tooltip
                                        title={
                                            isFavourite
                                                ? i18n.t('pryaniky.post.actions.addToFavorites.off')
                                                : i18n.t('pryaniky.post.actions.addToFavorites.on')
                                        }
                                    >
                                        <IconButton size="large" color="primary" onClick={addToFavourites}>
                                            <FavoriteIcon on={isFavourite} sx={{ height: '34px', width: '34px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <MDRender
                                    className={cnShopProduct('MDRender')}
                                    disableEmojiOne
                                    disableShowMoreBtn
                                    textCut={false}
                                    source={data.desc}
                                />

                                {data.disclaimer && (
                                    <Box>
                                        <Link className={cnShopProduct('Info-DescMore')} onClick={openDescription}>
                                            {i18n.t('pryaniky.shop.product.dsc.more')}
                                            <Icon icon={'question-circle'} />
                                        </Link>
                                    </Box>
                                )}
                            </Box>

                            {data.delivery && (
                                <>
                                    <Divider sx={{ order: 1 }} />
                                    <InfoDelivery className={cnShopProduct('Info-Delivery')}>
                                        <Typography variant="subtitle2" fontSize="13px" fontWeight="600">
                                            {i18n.t('pryaniky.shop.product.delivery')}
                                        </Typography>

                                        <Typography variant="subtitle2" fontSize="13px">
                                            {data.delivery}
                                        </Typography>
                                    </InfoDelivery>
                                </>
                            )}

                            {contentRating?.enable && data.news && (
                                <>
                                    <Divider sx={{ order: 3 }} />
                                    <Box className={cnShopProduct('Info-Rating')} sx={{ order: 4 }}>
                                        <RatingShopMui
                                            className={cnShopProduct('Info-Stars')}
                                            readOnly
                                            value={prepareRatingValue(contentRating?.rating)}
                                            rating={contentRating?.rating}
                                            ratingCount={contentRating.count}
                                        />
                                    </Box>
                                </>
                            )}

                            <Divider sx={{ order: 5 }} />
                            <Box className={cnShopProduct('Info-CharacteristicsBox')} sx={{ order: 6 }}>
                                <Characteristics characteristics={data.characteristics} onChange={onChangeChar} />
                            </Box>

                            <Box
                                className={cnShopProduct('Info-Avaliable')}
                                display="flex"
                                alignItems="center"
                                sx={{ order: 7 }}
                            >
                                <Typography variant="subtitle2" fontSize="13px" fontWeight="600">
                                    {i18n.t('pryaniky.uielements.materialelements.TransferList.availableTitle')}: &nbsp;{' '}
                                </Typography>
                                <Typography variant="subtitle2" color="gray" fontSize="13px">
                                    {data.isUnlimitedProduct
                                        ? i18n.t('pryaniky.shop.unlimited')
                                        : availableCount + i18n.t('pryaniky.shop.pc')}
                                </Typography>
                            </Box>
                            {!isHidePricesAndButtonBuy && (
                                <>
                                    <Divider sx={{ order: 8 }} />
                                    <InfoOrder className={cnShopProduct('Info-Order')}>
                                        <PriceElem
                                            fromPriceText={
                                                priceDependsOnChars && !areCharsTouched ? i18n.t('from.price') : null
                                            }
                                            price={priceForUserMoneyCount}
                                            priceCurrencyText={utilsProj.getFormattedCurrencyName(
                                                currencyFormats,
                                                priceForUserMoneyCount,
                                                !priceDependsOnChars || areCharsTouched ? 'nominative' : 'genitive'
                                            )}
                                        />
                                        {useCountSelector && (
                                            <>
                                                <Divider flexItem orientation={'vertical'} />
                                                <CountSelector
                                                    className={cnShopProduct('Info-OrderCount')}
                                                    size={'small'}
                                                    value={countToOrder}
                                                    onChange={(e) => {
                                                        setCountToOrder(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        max: maxForCountSelector,
                                                    }}
                                                />
                                                <Divider flexItem orientation={'vertical'} />
                                                <TotalElem
                                                    price={priceByCount}
                                                    priceCurrencyText={utilsProj.getFormattedCurrencyName(
                                                        currencyFormats,
                                                        priceByCount,
                                                        'nominative'
                                                    )}
                                                />
                                            </>
                                        )}
                                    </InfoOrder>
                                </>
                            )}
                            <Divider sx={{ order: 10 }} />

                            <Box className={cnShopProduct('Info-SelectedAvaliable')} sx={{ order: 11 }}>
                                {isUnlimitedProduct ? (
                                    <Translate i18nKey={'pryaniky.shop.unlimit'} />
                                ) : selectedCharsCount !== undefined ? (
                                    <>
                                        <span>
                                            <Translate i18nKey={'pryaniky.shop.avaliableCharsCount'} />
                                            :&nbsp;
                                        </span>
                                        <Typography
                                            fontSize="14px"
                                            color={!selectedCharsCount ? 'error' : undefined}
                                            component={'b'}
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {selectedCharsCount}
                                            <Translate i18nKey={'pryaniky.shop.pc'} />
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <span>
                                            <Translate i18nKey={'pryaniky.shop.avaliableCount'} />
                                            :&nbsp;
                                            <Typography fontSize="14px" component={'b'} sx={{ fontWeight: 'bold' }}>
                                                {availableCount}
                                                <Translate i18nKey={'pryaniky.shop.pc'} />
                                            </Typography>
                                        </span>
                                    </>
                                )}
                            </Box>

                            {!isHideButtonBuy && (
                                <Box
                                    className={cnShopProduct('Info-Actions')}
                                    sx={{
                                        order: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {isAmountValid ? (
                                        <>
                                            {missingThanks > 0 && !data.multiOrderItem ? (
                                                <SpinBtn
                                                    variant="outlined"
                                                    size="large"
                                                    disabled
                                                    className={cnShopProduct('Info-Missing')}
                                                >
                                                    <Typography color="error">
                                                        {i18n.t('missing')} {missingThanks}{' '}
                                                        {utilsProj.getFormattedCurrencyName(
                                                            currencyFormats,
                                                            missingThanks,
                                                            'genitive'
                                                        )}
                                                    </Typography>
                                                </SpinBtn>
                                            ) : (
                                                <>
                                                    <Tooltip className={'InfoIcon'} title={isNotCanBuy || ''}>
                                                        <span>
                                                            <SpinBtn
                                                                variant={
                                                                    Boolean(isNotCanBuy) || !countToOrder
                                                                        ? 'outlined'
                                                                        : cannotBuyWhileChars
                                                                        ? 'outlined'
                                                                        : 'contained'
                                                                }
                                                                className={cnShopProduct('Info-Order')}
                                                                size="large"
                                                                disabled={
                                                                    Boolean(isNotCanBuy) ||
                                                                    !countToOrder ||
                                                                    maxOrdersCount < 1
                                                                }
                                                                onClick={() => {
                                                                    openShopMakeOrderWithMultiOrder(dispatch, {
                                                                        data,
                                                                        preselectedUsersId: context.userId,
                                                                        withCount: useCountSelector,
                                                                        selectedCount: countToOrder,
                                                                        maxCount: maxForCountSelector,
                                                                        additionalInformationBeforeOrder,
                                                                        multiOrderItem: data.multiOrderItem,
                                                                    });
                                                                }}
                                                            >
                                                                <Typography>
                                                                    {cannotBuyWhileChars
                                                                        ? i18n.t('pryaniky.shop.product.isOutOfStock')
                                                                        : i18n.t('pryaniky.shop.product.order')}
                                                                </Typography>
                                                            </SpinBtn>
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <SpinBtn
                                            disabled
                                            variant="outlined"
                                            size="large"
                                            className={cnShopProduct('NotAvailable')}
                                        >
                                            {i18n.t('pryaniky.shop.product.isOutOfStock')}
                                        </SpinBtn>
                                    )}
                                </Box>
                            )}
                        </InfoBox>
                    </ProductContainer>
                ) : (
                    <ListPlaceholder
                        type="shopClosed"
                        imageSrc={shopPlaceholder}
                        title={i18n.t('pryaniky.shop.product.notActive.title')}
                        text=""
                    />
                )}

                <ProductCardComments data={data} />
            </ProductCardLayout>

            {!!data.categories.length && (
                <CurrentCategory id={id} categories={data.categories} myThanksCount={userThanksCount} />
            )}
        </>
    );
};

export const ProductCardMui = withIndicator(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(withRouter(ProductPreloader))),
    SHOP_GET_SHOP_PRODUCT
)();
