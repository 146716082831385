import { connect } from 'react-redux';
import * as React from 'react';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
import { i18n, Translate } from 'localization';

import { mapStateToProps, IUsersWidgetStateProps, cnUsersWidget, IUsersWidgetProps, IUsersWidgetState } from './UsersWidget.index';
import './UsersWidget.scss';
import noDataImage from 'assets/svg/NoData.svg'
//import { Avatar } from 'uielements/src/Avatar/Avatar';
import { AvatarsTooltipList } from 'muicomponents/src/AvatarsTooltipList/AvatarsTooltipList';
import { Typography } from 'muicomponents/src';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { Avatar } from 'muicomponents/src/Avatar';
import { Button } from 'muicomponents/src/Button/Button'

export class UsersWidgetPresenter extends React.Component<IUsersWidgetProps, IUsersWidgetState> {
  public el: HTMLElement | null;
  protected mounted = false;

  constructor(props: IUsersWidgetProps) {
    super(props);
    this.state = {
      items: [],
      title: 'No data....'
    };
  }

  public componentDidMount() {
    this.mounted = true;
    this.getData(this.props);
  }
  // public componentWillUnmount() {}
  public componentWillReceiveProps(np: IUsersWidgetProps) {
    if (
      (this.props.wcontext.uId !== np.wcontext.uId) ||
      (this.props.wcontext.gId !== np.wcontext.gId)
    ) {
      this.getData(np);
    }
  }

  public componentWillUnmount = () => this.mounted = false;

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, widget, className, settings } = this.props;
    const { items, noData, title } = this.state;
    const additionalProps: { [s: string]: any } = {
      hide: noData
    }

    // if (!widget.type.includes("subscriptions") && this.props.authUser.profileData.userId !== this.props.wcontext.uId) return null;

    if (this.props.edit && !items.length) return <TagName {...this.props} ></TagName>;
    if (!settings?.hideIfNoData &&
      // подписки на коллег
      (widget.type.includes("subscriptions")) &&
      items.length === 0) return <TagName {...this.props} className={cnUsersWidget({}, [customClassNames, className])}>
        <Typography fontWeight='700' mb='8px' > {title} </Typography>
        {/* <Typography fontWeight='700' mb='8px' color='primary'> {title} </Typography> */}
        {/* <Avatar srcSet={noDataImage} variant='square' sx={{ width: 'auto', height: '100%' }} /> */}
        <Typography>{this.props.authUser.profileData.userId === this.props.wcontext.uId ?
          <Translate i18nKey={'pryaniky.widgets.settings.subscriptions.text'} /> : <Translate i18nKey={'pryaniky.widgets.settings.subscribers.text'} />}
        </Typography>
      </TagName>;
    if (!settings?.hideIfNoData &&
      // подписчики
      widget.type.includes("subscribers") &&
      items.length === 0) return <TagName {...this.props} className={cnUsersWidget({}, [customClassNames, className])}>
        {/* <Typography fontWeight='700' mb='8px' color='primary'>{title}</Typography> */}
        <Typography fontWeight='700' mb='8px'>{title}</Typography>
        <Typography>{this.props.authUser.profileData.userId !== this.props.wcontext.uId ?
          <Translate i18nKey={'pryaniky.widgets.settings.subscriptions.text'} /> : <Translate i18nKey={'pryaniky.widgets.settings.subscribers.text'} />}
        </Typography>

      </TagName>;

    if (!widget.type.includes("subscriptions") && !widget.type.includes("subscribers") && items.length === 0) return null;

    return (
      <TagName {...this.props} {...additionalProps} className={cnUsersWidget({}, [customClassNames, className])}>
        <Typography fontWeight='700' mb='8px' >{title}</Typography>
        {/* <Typography fontWeight='700' mb='8px' color='primary'>{title}</Typography> */}
        {/* <ListRound items={items} cut={true} size={40} /> */}
        <AvatarsTooltipList items={items} size={40} />
        {children}
      </TagName>
    )
  }

  protected getData = (props: IUsersWidgetProps) => {
    // implementation in derived classes
  }
}


export const UsersWidget = connect<IUsersWidgetStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(UsersWidgetPresenter)