import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { fromPairs } from 'lodash';
import { v1 as uuid } from 'uuid';

export const pageGrid = (): IWidget<IColumn[]> => {

  const line1 = generateColumn({
    items: [
      generateWidget({
        type: widgets.types.wiki,
      })
    ]
  });

  const sections: {
    [key: string]: {
      id: string;
      widgetId: string | null;
      size: number;
    }[]
  } = {};

  [ line1 ].forEach(line => {
    sections[line.id] = line.items?.map((el: string | IWidget, _ ,arr) => {
      return {
        id: uuid(),
        widgetId: typeof el === 'object' ? el.id : el,
        size: 12 / arr.length
      }
    });
  });

  return generateWidget({
    type: widgets.types.layout + '/horizontal',
    version: '2',
    settings: {
      sections
    },
    data: [
      line1,
    ],
  });
}