import React from 'react';
import { createModal, LeftDirectionTransitionComponent } from 'utils/src/DialogCreator';
import { cnWorkflowsKanbanCreate, IWorkflowsKanbanCreateProps } from './Create.index';
// import {
//   DialogTitle,
//   DialogActions,
//   DialogContent,
// } from 'uielements/src';
// import { Button } from 'uielements/src/MaterialElements/Button/Button';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle';
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent';
import { i18n, Translate } from 'localization';
import './Create.scss';
import { NewsCreateForm } from 'blocks/NewsCreate/Form/NewsCreateForm';

const WorkflowsKanbanCreatePresenter: React.FC<IWorkflowsKanbanCreateProps> = ({ oneNewsType, handleClose }) => {

  return <div className={cnWorkflowsKanbanCreate({}, ['CustomStyle'])}>
    <DialogTitle onClose={handleClose} className={cnWorkflowsKanbanCreate('Header')}>
      {/* <h4 className="my-1"> */}
        <Translate i18nKey={`pryaniky.post.workflows.create`} />
      {/* </h4> */}
    </DialogTitle>

    <DialogContent  className={cnWorkflowsKanbanCreate('Body')}>
      <NewsCreateForm
        activePostId={oneNewsType as any}
        className={cnWorkflowsKanbanCreate('NewsCreate')}
        key={'NewsCreate'}
        workflowId={oneNewsType}
        oneNewsType={oneNewsType}
        cancleEdit={handleClose}
        onSendComplete={(s: boolean) => s && handleClose()}
      />
    {/* <NewsCreate
      className={cnWorkflowsKanbanCreate('NewsCreate')}
      key={'NewsCreate'}
      workflowId={oneNewsType}
      oneNewsType={oneNewsType}
      cancleEdit={handleClose}
      onSendComplete={(s: boolean) => s && handleClose()} /> */}
    </DialogContent>

    {/* <DialogActions>
      <Button color='primary' onClick={handleClose}>
        <Translate i18nKey='cancel' />
      </Button>
    </DialogActions> */}
  </div>
}

/**
 * @deprecated
 * use createNewsModal from blocks/Dialogs/News/Create/Create
 */
export const createNewsModal = createModal(WorkflowsKanbanCreatePresenter, {
  maxWidth: 'md',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
    }
  },
  TransitionComponent: LeftDirectionTransitionComponent,
  className: 'Pane BackdropOff Right',
  scroll: 'body'
});