import * as React from 'react';
import * as utils from 'utils/src/utils';

// import { withBemMod } from '@bem-react/core';

import { cnWidget } from '../Widget.index';
import { IWidgetTypeGroupsProps } from './Widget_type_groups.index';
import './Widget_type_groups.scss';
// import { ListRound } from 'blocks/ListRound/ListRound';

import { widgets } from "i.widgets";
import { default as WidgetPresenter } from '../Widget';

const types = {
  common: require('blocks/GroupsWidget/GroupsWidget').GroupsWidget,
  // subscribers: require('blocks/UsersWidget/_type/UsersWidget_type_subscribers').UsersSubscribersWidget,
  // subscriptions: require('blocks/UsersWidget/_type/UsersWidget_type_subscriptions').UsersSubscriptionsWidget
}

const type = 'groups';

export default class WidgetTypeUsersPresenter extends WidgetPresenter<IWidgetTypeGroupsProps> {

  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    const Component = this.getComponent(types);
    return <Component {...this.props} className={cnWidget({ type, subtype })} context={this.props.wcontext} contexts={this.relations.concat(this.props.widget.id)} tag={widgets.components.common} />
  }

}
