import React, { FC, useContext, useRef, memo, ComponentProps, useMemo } from 'react';
import { connect } from 'react-redux';
import { cNNoticesTypeNews } from './Notices.index';
import './Notices.scss';
import { TextField as TextFieldC } from 'muicomponents/src';
import { DraftInput as DraftInputC, useDraftEdit } from 'muicomponents/src/DraftInput/DraftInput';
import { i18n, Translate } from 'localization';
import { ComponentInjector } from 'utils/src/ComponentInjector';

import { useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';
import { useNoticesEdit } from './Notices.hooks';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { memoizedComponent } from 'News/creator/utils.sender';
import { isEqual } from 'lodash'

const prepareI18nKey = (key: string) => `pryaniky.news.create.notices.${key}`;

const TextField = memoizedComponent(TextFieldC);
const DraftInput = memoizedComponent(DraftInputC);

const NewsTypeNoticesPresenter: FC<{}> = ({
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        data,
        renderParams,
        errors,
        clearEditor,
        changeNews
    } = useNoticesEdit(creatorId);

    const {
        header,
        text: currentNewsText,
        draftRawState
    } = data;

    const textRef = useRef(currentNewsText);
    textRef.current = currentNewsText;

    const {
        state,
        draftState,
        setDraftState
    } = useDraftEdit(draftRawState, clearEditor);

    useDidUpdateEffect(() => {
        // because text editor push additional state change when state is empty externally
        if(!textRef.current && !state.text) return;
        changeNews({
            text: state.text,
            draftRawState: state.raw
        });
    }, [state]);

    const draftCommonProps = useDraftEditorStyle();

    const TextFieldProps = useMemo<Partial<ComponentProps<typeof TextField>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('header')} />,
        onChange: (e) => {
            changeNews({ header: e.target.value });
        }
    }), []);

    const DraftInputProps = useMemo<Partial<ComponentProps<typeof DraftInput>>>(() => ({
        label: <Translate i18nKey={prepareI18nKey('text')} />
    }), []);

    return (
        <>
            <TextField
                test-id={'timeline-create-notice-header'}
                {...TextFieldProps}
                fullWidth
                value={header}
                error={
                    Boolean(errors.header)
                }
                helperText={
                    Boolean(errors.header) && PrepareErrorText(errors.header)
                }
            />
            <DraftInput
                test-id={'timeline-create-notice-text'}
                {...DraftInputProps}
                {...draftCommonProps}
                // toolbar allow instanlty
                // disableToolbar={!renderParams?.allowRichEdit}
                value={draftState}
                onChange={setDraftState}
                error={
                    Boolean(errors.text)
                }
                helperText={
                    // TODO check types in BaseNews.text
                    Boolean(errors.text) && PrepareErrorText(errors.text as any)
                }
                clearValue={clearEditor}
            />
        </>
    )
}

export const NewsTypeNotices = NewsTypeNoticesPresenter;

export const initNewsNoticesCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <NewsTypeNotices />, 'notices');