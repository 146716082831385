import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { pageHeader } from './pageHeaders'

export const achievements = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.info,
            data: pageHeader.achievements
          }),
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.timeline
                  })
                ],
                styles: {
                  width: '72%'
                },
              }),
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.filters + '/achievements',
                  })
                ],
                styles: {
                  width: '28%'
                },
              })
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  });
}