import React, { FC, useEffect, SyntheticEvent, useMemo, useRef, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n, Translate } from 'localization';
import { Box, Typography } from 'muicomponents/src';
import { styled } from '@mui/system';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { IStateType } from 'redux/store';
import { Characteristics } from 'utils/src/requests/models/api.shop';
import { IChosenCharacteristic } from 'redux/shop/interfaces';
import { useDidUpdateEffect, useReffedState } from 'utils/src/hooks';
import { xor, fromPairs, toPairs } from 'lodash';
import { Button, Chip, TextField, TextFieldSelect } from 'muicomponents/src';
import ColorCharacteristic from 'blocks/ShopMui/Characteristics/valueTypes/Color/Color';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox } from 'muicomponents/src/Checkbox';
import { TValue } from 'utils/src';
import PriceFilter from './PriceFilter';
import Skeleton from '@mui/material/Skeleton';
import { getShopCharasteristics } from 'redux/shop/actions';

export const CharItemBox = styled(Box)({
    paddingTop: '12px',
    '&:not(:first-child)': {

    },
}) as typeof Box
export const SearchMenuBox = styled(Box)({
    margin: '8px',
}) as typeof Box
export const TagsBox = styled(Box)({
    maxHeight: '272px',
    overflowY: 'auto',
    width: '560px',
}) as typeof Box

interface ICharItemProps {
    char: Characteristics.IItem
    chooseChar: (arg: IChosenCharacteristic) => void
    isChosen: boolean
    initialTags?: { id: string, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number }[]
}

export const CharItem: FC<ICharItemProps> = memo(({ char, chooseChar, isChosen, initialTags }: ICharItemProps) => {

    const initialTagsRef = useRef(initialTags);
    initialTagsRef.current = initialTags;

    const [selectOpen, setSelectOpen] = useState<boolean>()
    const [checkedTags, setCheckedTags, checkedTagsRef] = useReffedState<{ id: string, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number }[]>(() => {
        const selectedTags = initialTags?.map(el => el.id);
        return char.tags.filter(tag => {
            return selectedTags?.includes(tag.id) || false;
        })
    });
    useDidUpdateEffect(() => {
        if (!!xor(initialTags?.map(el => el.id), checkedTagsRef.current.map(el => el.id)).length) {
            setCheckedTags(initialTags || []);
        }
    }, [initialTags]);
    const handleSetTags = (tagId: string, checked: boolean, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number) => {
        if (!checked) {
            const tagIsSelected = checkedTags.map(el => el.id).includes(tagId);
            if (tagIsSelected) {
                setCheckedTags(checkedTags.filter(tag => tag.id !== tagId));
            } else {
                setCheckedTags([...checkedTags, { id: tagId, displayName, color, isDisabled, productsUsedCount }]);
            }
        } else {
            const newArr = checkedTags.filter(tag => tag.id !== tagId);
            setCheckedTags(newArr);
            chooseChar({ id: char.id, tags: newArr });
        }
    }

    const enableApplyButton = useMemo(() => {
        const checkedIds = checkedTags.map(el => el.id);
        const initialIds = initialTagsRef.current?.map(el => el.id) || [];
        return checkedTags.length !== initialIds.length
            || !!checkedIds.filter(id => !initialIds.includes(id)).length
    }, [selectOpen, checkedTags]);

    useDidUpdateEffect(() => {
        const checkedIds = checkedTags.map(el => el.id);
        const initialIds = initialTagsRef.current?.map(el => el.id) || [];
        if (checkedTags.length !== initialIds.length
            || !!checkedIds.filter(id => !initialIds.includes(id)).length)
            setCheckedTags(initialTagsRef.current || []);
    }, [selectOpen]);

    const [searchTagValue, setSearchTagValue] = useState('')
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTagValue(e.currentTarget.value)
    }

    const handleClose = (e: SyntheticEvent) => {
        e.stopPropagation()
        chooseChar({ id: char.id, tags: checkedTags });
        setSelectOpen(false)
    }

    const searchedTags = useMemo(() => {
        let filteredTags = []
        if (!searchTagValue) return char.tags
        else {
            filteredTags = char.tags.filter(tag => tag.displayName.toLowerCase().indexOf(searchTagValue.toLowerCase()) !== -1)
        }
        return filteredTags
    }, [searchTagValue, char.tags]);

    const isColor = useMemo(() => {
        return char.type === 'ShopCharacteristicsColor';
    }, [char]);

    // if (!char.tags.length) return null

    return <CharItemBox>

        <TextField
            fullWidth
            select
            onClick={() => setSelectOpen(!selectOpen)}
            SelectProps={{
                SelectDisplayProps: {
                    style: { width: '110%', position: 'relative', left: '-20px', /* border: '2px solid green' */ }
                },
                MenuProps: {
                    open: selectOpen,
                    onClose: () => setSelectOpen(false),
                }
            }}
            label={char.displayName}
            focused={selectOpen}
            suggesterValue={
                !!checkedTags.length
                    ?
                    <Box sx={{ position: 'absolute', zIndex: 2, }}>
                        {
                            checkedTags.map(tag => (
                                <Chip key={tag.id}
                                    sx={{ marginRight: '8px', }}
                                    label={
                                        isColor
                                            ? <ColorCharacteristic value={tag} size={'small'} />
                                            : tag.displayName
                                    }
                                    onDelete={() => handleSetTags(tag.id, true, tag.displayName, tag.color, tag.isDisabled, tag.productsUsedCount)}
                                />
                            ))
                        }
                    </Box>
                    : undefined
            }
        >
            {char.tags.length > 8 &&
                <SearchMenuBox onClick={(e) => e.stopPropagation()}>
                    <SearchInput value={searchTagValue} onChange={onSearchChange} />
                </SearchMenuBox>
            }

            <TagsBox>
                {searchedTags.map((tag) => {
                    const isChecked = Object.values(checkedTags).findIndex(elem => elem.id === tag.id) !== -1
                    return <MenuItem key={tag.id} sx={{ padding: 0 }}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleSetTags(tag.id, false, tag.displayName, tag.color, tag.isDisabled, tag.productsUsedCount)
                        }
                        }>
                        <Checkbox checked={isChecked} />
                        {
                            isColor
                                ? <ColorCharacteristic value={tag} size={'small'} />
                                : tag.displayName
                        }
                        {
                            !!tag.productsUsedCount &&
                            <>
                                &nbsp;
                                <Typography color='GrayText'>{tag.productsUsedCount}</Typography>
                            </>
                        }
                    </MenuItem>
                })}
            </TagsBox>
            <MenuItem>
                <Button variant='contained' size='small' onClick={handleClose} disabled={!enableApplyButton} >
                    {i18n.t('pryaniky.filter.feed.use')}
                </Button>
            </MenuItem>
        </TextField>

    </CharItemBox>
})

export const CharsBox = styled(Box)({
    overflowY: 'auto',
    maxHeight: '272px',
}) as typeof Box

export const FilterBtnSkeleton = () => {
    return <Button size='small' sx={{ mr: 1, padding: 0 }} >
        <Skeleton variant='rectangular'
            sx={{ width: '100%', height: '31px', borderRadius: '4px', margin: 0, padding: 0, }}
        />
    </Button>

}

export const Filter: FC<{
    onChange?: (value: any[]) => void
}> = ({ onChange }) => {
    const dispatch = useDispatch()
    const chars = useSelector((s: IStateType) => s.shop.categoryProducts?.shopCharacteristics)
    const showCharacteristics = !!chars?.length;

    useEffect(() => {
        !chars && dispatch(getShopCharasteristics())
    }, [dispatch])

    const chosen = useSelector((s: IStateType) => s.shop.chosenFilters)
    const chosenRef = useRef(chosen);
    chosenRef.current = chosen;

    const [localChosenChars, setLocalChosenChars, localChosenCharsRef] = useReffedState<{
        [key: string]:
        { id: string, displayName: string, color: string, isDisabled: boolean, productsUsedCount?: number }[]
    }>(chosen.characteristics)

    const [MinPrice, setMinPrice, MinPriceRef] = useReffedState<number | undefined>(chosen.MinPrice)
    const [MaxPrice, setMaxPrice, MaxPriceRef] = useReffedState<number | undefined>(chosen.MaxPrice)
    const [localAllowToMeByPrice, setAllowToMeByPrice, localAllowToMeByPriceRef] = useReffedState(chosen.AllowToMeByPrice)

    const chooseChar = (char: IChosenCharacteristic) => {
        const newObj = { ...localChosenChars, [char.id]: char.tags }
        setLocalChosenChars(newObj)
    }
    const handleChangeCharFilters = useCallback(() => {
        onChange && onChange([
            {
                key: 'characteristics',
                value: localChosenChars
            },
            {
                key: 'MaxPrice',
                value: MaxPrice
            },
            {
                key: 'MinPrice',
                value: MinPrice
            },
            {
                key: 'AllowToMeByPrice',
                value: localAllowToMeByPrice
            }
        ])
    }, [dispatch, localChosenChars, MaxPrice, MinPrice, localAllowToMeByPrice])

    const handleClearCharFilters = useCallback(() => {
        setMinPrice(undefined);
        setMaxPrice(undefined);
        setAllowToMeByPrice(false);
        setLocalChosenChars({
            ...fromPairs(toPairs(localChosenCharsRef.current).map(([key, _]) => [key, []]))
        });
    }, []);

    const enableApplyButton = useMemo(() => {
        const chosen = chosenRef.current.characteristics || {};
        const choosenMap = new Map<keyof typeof chosen, TValue<typeof chosen>>();
        Object.typedKeys(chosen).forEach(key => {
            choosenMap.set(key, chosen[key]);
        });
        // if chaged MaxPrice
        return chosenRef.current.MaxPrice !== MaxPrice
            // if chaged MinPrice
            || chosenRef.current.MinPrice !== MinPrice
            // if chaged AllowToMeByPrice
            || chosenRef.current.AllowToMeByPrice !== localAllowToMeByPrice
            // if chaged change selected tags
            || Object.typedKeys(localChosenChars).reduce((a, key) => {
                // pass true if found differece
                if (a) return a;
                const localChosenCharsItemIds = localChosenChars[key].map(el2 => el2.id);
                if (
                    !choosenMap.has(key)
                    || choosenMap.get(key)?.length !== localChosenChars[key].length
                    || !!choosenMap.get(key)?.filter(el => !localChosenCharsItemIds.includes(el.id)).length
                ) return true;
                return a;
            }, false);
    }, [open, localChosenChars, MaxPrice, MinPrice, localAllowToMeByPrice]);

    const allowToMeByPriceCount = useSelector((s: IStateType) => s.shop.categoryProducts?.allowToMeByPriceCount)

    // if (!chars) return <FilterBtnSkeleton />

    return <Box sx={{ overflow: 'hidden', padding: '16px', width: '600px' }}>
        <Box>

            <PriceFilter minPrice={MinPrice} setMinPrice={setMinPrice}
                maxPrice={MaxPrice} setMaxPrice={setMaxPrice}
                AllowToMeByPrice={localAllowToMeByPrice} setAllowToMeByPrice={setAllowToMeByPrice}
                allowToMeByPriceCount={allowToMeByPriceCount}
            />

            {
                showCharacteristics
                &&
                <>
                    <Typography variant='subtitle1'>{i18n.t('pryaniky.shop.tab.characteristics')}:</Typography>
                    <CharsBox>
                        {chars.map((char, i) => {
                            const isInChosen = char.id in localChosenChars
                            return <CharItem key={i} char={char}
                                isChosen={isInChosen}
                                chooseChar={chooseChar}
                                initialTags={isInChosen ? localChosenChars[char.id] : undefined}
                            />
                        })}
                    </CharsBox>
                </>
            }
        </Box>

        <Box sx={{ padding: 0, marginTop: '16px', display: 'flex', gap: '16px' }}>
            <Button
                variant='outlined'
                size='small'
                onClick={handleClearCharFilters}
                startIcon={<CleaningServicesIcon fontSize={'small'} />}
            >
                <Translate i18nKey={'pryaniky.filter.feed.clear'} />
            </Button>
            <Button variant='contained'
                disabled={!enableApplyButton}
                // {!Object.keys(localChosenChars).length && !localAllowToMeByPrice && MinPrice === undefined && MaxPrice === undefined }
                size='small'
                onClick={handleChangeCharFilters} >
                {i18n.t('pryaniky.filter.feed.use')}
            </Button>
        </Box>
    </Box>
}