import { Reply as ReplyRender } from 'blocks/NewsTypes/Reply/Reply';
import { Edit } from 'blocks/Reply/Edit/Reply-Edit';
import { i18n } from 'localization';
import { CircularProgress } from 'muicomponents/src';
import { loadFullReplt } from 'News/redux/actions';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar } from 'uielements/src';
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Button } from 'uielements/src/Button/Button';
import MDRender from 'uielements/src/CommonmarkRender';
import { Preview } from 'uielements/src/Preview/Preview';
import { UserMention } from 'uielements/src/UserMention/UserMention';
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator';
import { formatDateSTD } from 'utils/src/utils.dates';
import ReplyLayout from '../../components/ReplyLayout';
import { withReplyContext } from '../../contexts/reply';
import ReplyActions from '../ReplyActions';
import ReplyRating from '../ReplyRating';
import ReplyService from '../ReplyService';
import { LoaderBox } from './component.styled';
import { cnClassName, mapDispatchToProps, mapStateToProps, News } from './interfaces';
import './style.scss';

type IPresenterProps = News.ReplyView.IPresenterProps;
type IOwnProps = News.ReplyView.IOwnProps;
type IDispatchProps = News.ReplyView.IDispatchProps;
type IStateProps = News.ReplyView.IStateProps;
type IState = News.ReplyView.IState;

const noopFn = () => {};
const noopArr: any[] = [];

export const Presenter: FC<IPresenterProps> = ({
    className,
    reply,
    newsId,
    replyId,
    avatarSize,
    replyEdit,
    setEdit,
    withRating,
}) => {
    const { user, changeDate, date } = reply;
    const attachments = reply.attachments || noopArr;
    const dateStr =
        changeDate.toString() !== date.toString()
            ? 'отредактировано ' + formatDateSTD(changeDate.toString(), true)
            : formatDateSTD(date.toString(), true);

    const [disableCut, setDisableCut] = useState(reply.showLoadMore);

    const loadAction = useMemo(() => loadFullReplt({ newsUid: newsId, replyUid: replyId }), [newsId, replyId]);

    const { dispatchAction, dispatchActionParam, isLoading } = useLazyExecutSaga(
        () => loadAction,
        [loadAction],
        [loadAction.type + '-' + loadAction.payload.replyUid]
    );

    const offEdit = useCallback(() => setEdit(false), []);
    let showRating = withRating;
    showRating = replyEdit ? false : showRating;
    const loadFullReply = () => {
        setDisableCut(true);
        dispatchAction();
    };

    const setEditableAfterLoadFullReply = () => {
        if (reply.showLoadMore) {
            setDisableCut(true);
            dispatchActionParam({ ...loadAction, payload: { ...loadAction.payload, cb: () => setEdit(true) } });
        } else {
            setEdit(true);
        }
    };

    return (
        <ReplyLayout
            className={cnClassName({}, [className])}
            actions={<ReplyActions />}
            avatar={
                user.isDeleted ? (
                    <Avatar name={user.displayName} size={avatarSize} />
                ) : (
                    <Avatar
                        imgUrl={user.imgUrl || user.userPhotoUrl}
                        imgId={user.imgId}
                        name={user.displayName}
                        size={avatarSize}
                    />
                )
            }
            service={<ReplyService setEditableAfterLoadFullReply={setEditableAfterLoadFullReply} />}
            title={
                <>
                    {user.isDeleted ? (
                        <span>{user.displayName}</span>
                    ) : (
                        <>
                            <UserMention id={user.id} name={user.displayName} className={cnClassName('UserName')} />
                            {reply.type === 'thank' && (
                                <MDRender
                                    className={cnClassName('ThankHeader')}
                                    textCut={false}
                                    type={'inline'}
                                    source={reply.header}
                                />
                            )}
                            <div className={cnClassName('DateTime', { block: reply.type === 'thank' })}>{dateStr}</div>
                        </>
                    )}
                </>
            }
            additional={showRating && <ReplyRating />}
        >
            {isLoading ? (
                <LoaderBox>
                    <CircularProgress />
                </LoaderBox>
            ) : replyEdit ? (
                <Edit newsId={newsId} comment={reply as any} disableEdit={offEdit} withRating={withRating} />
            ) : (
                <ReplyRender
                    disableCut={disableCut}
                    id={replyId}
                    header={reply.header}
                    text={reply.text}
                    type={reply.type}
                />
            )}

            {reply.showLoadMore && !isLoading && (
                <Button theme="unstyled" className={cnClassName('MoreBtn')} onClick={loadFullReply}>
                    {i18n.t('pryaniky.common.read_more')}
                </Button>
            )}
            {/* {isLoading && 'ГРУЖУ КОММЕНТАРИЙ'} */}
            {!replyEdit && (
                <>
                    <Preview newsid={replyId} files={attachments} />

                    <Attachments files={attachments} onDelete={noopFn} state="view" />
                </>
            )}
        </ReplyLayout>
    );
};

export default withReplyContext(
    connect<IStateProps, IDispatchProps, IOwnProps, IState>(mapStateToProps, mapDispatchToProps)(memo(Presenter))
);
