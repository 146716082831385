import { IIdeaEvaluationtProps, cnIdeasEvalution } from './IdeaEvaluationNew.index';
import * as React from 'react'
import './IdeaEvaluationNew.scss'
import { AdditionalFieldsRender } from '../AdditionalFieldsRender/AdditionalFieldsRender';
// import { loadFields } from '../IdeasSides';
import { i18n, Translate } from 'localization';
// import { saveIdeaEvaluation } from '../IdeasSides';
import { ActionButtonsNew } from '../../../ActionButtonsNew/ActionButtonsNew';
import { IdeaFields } from '../Fields/Types'
import { IdeasValidator } from '../Ideas.validate';
import { denormalizeData } from '../Utils';
import { useLazyQuery } from 'utils/src/hooks'
import {
    API
} from 'utils/src/utils'
import { denormalize, normalize, schema } from 'normalizr';
import { statusFieldsExtractorNew } from '../Utils'
import { useDidUpdateEffect } from 'utils/src/hooks';
import { PrepareErrorText } from 'News/utils/utils.validate';
// import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'

import { TextField } from 'muicomponents/src/TextField';
import { FieldsEditor } from 'muicomponents/src/FieldRender';
import { NIdeas } from 'News/creator/types/Ideas/Ideas.interface';
import { differenceWith, isEqual, clone } from 'lodash';
import { converAdditionalValuesToAdditionalValuesV1, converAdditionalValuesV1ToAdditionalValuesClosed, converAdditionalValuesV1ToAdditionalValues } from 'News/creator/types/Ideas/Ideas.sides';
import { NewsCreateContext } from 'News/creator/Creator/Creator.constants';
import { useIdeasEdit } from 'News/creator/types/Ideas/Ideas.hooks';
import { useDraftEditorStyle } from 'News/creator/hooks';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { toast } from 'react-toastify';
import * as utils from 'utils/src/utils';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle'
import DialogContent from 'uielements/src/MaterialElements/DialogParts/DialogContent'
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions'
// const vb = new IdeasValidator();
// type IdeasAdditionalFieldsValues = NonNullable<NIdeas.Create['idea']['additionalFieldsValues']>;
import { createModal, useDialogContext, TransitionComponent } from 'utils/src/DialogCreator';
import { CircularProgress } from '@material-ui/core';
const IdeaEvaluationPresenter: React.FC<IIdeaEvaluationtProps> = ({ ideaId, statusID, anchor, handleAccept, handleClose, ...props }) => {
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const { open } = useDialogContext();

    React.useEffect(() => {
        if (open) openAction()

    }, []);

    const [
        openAction,
        isLoading,
        value,
        isError,
        count,
        setValue,
    ] = useLazyQuery(async function () {
        return await (await API.news.ideas.setStatus(ideaId, statusID)).r

    }, statusFieldsExtractorNew)

    const [additionalFields, setAdditionalFields] = React.useState({
        fields: [] as any,
        errors: {}
    });
    const [additionalFieldsValues, setAdditionalFieldsValues] = React.useState<any>([]);


    const onChange: React.ComponentProps<typeof FieldsEditor>['onChange'] = (fields, errors) => {
        setAdditionalFields({ fields, errors });
    };

    React.useEffect(() => {
        setAdditionalFields({
            fields: converAdditionalValuesV1ToAdditionalValuesClosed(clone(value || [])),
            errors: {}
        });
        setAdditionalFieldsValues({ fields: value })
    }, [value])

    const {
        fields,
        errors
    } = additionalFields
    const [
        save,
        isSaving
    ] = useLazyQuery(async function (fields: any) {
        return await (await API.news.ideas.setIdeaExpertise(ideaId, statusID, { fields })).r
    })

    const onSave = () => {
        if (additionalFields?.fields) {
            const fieldsV1 = converAdditionalValuesToAdditionalValuesV1(additionalFields?.fields);
            save(fieldsV1);
            handleClose();
        }
    }

    return (<>
        <DialogTitle onClose={handleClose}>
            {i18n.t('pryaniky.idea.evaluation')}
        </DialogTitle>

        <DialogContent className={cnIdeasEvalution('Content')}>

            {isLoading &&
                <div className={cnIdeasEvalution('Content-Loading')}><CircularProgress /></div>
            }
            {additionalFields?.fields ? <FieldsEditor
                fields={additionalFields.fields}
                onChange={(fields, newErrors) => {
                    onChange(fields, newErrors);
                }}

                globalTagsCollection={'ideas'}
            /> : <div>{isError && 'error loading fields'}</div>}




        </DialogContent>
        <DialogActions
            acceptText={i18n.t("pryaniky.saveChanges")}
            closeText={i18n.t("cancel")}
            onAccept={onSave}
            onClose={handleClose}
        // className={cnIdeasEvalution('SaveCancel')} saveDisabled={!isValid} save={onSave} cancle={close} 
        />
    </>
    );
}

export const IdeaEvaluationNew = IdeaEvaluationPresenter;

// export const initNewsIdeasCreator = () => ComponentInjector.getInstance().addNode('news_creator_mui5', <IdeaEvaluation  />, 'ideas')


export const DialogIdeaEvaluationNew = createModal(IdeaEvaluationNew, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});