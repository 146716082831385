import React, { FC, memo } from 'react'
import {
    cnClassName,
    News
} from './interfaces'
import './style.scss'
import PurseImg from 'assets/svg/purse.svg'
import { getCurrencyNameCase } from 'utils/src/utils';
import { connect } from 'react-redux';
import {
    mapStateToProps,
    mapDispatchToProps
} from './interfaces';
import { i18n, Translate } from 'localization';

type IPresenterProps = News.TimelineInfo.IPresenterProps
type IStateProps = News.TimelineInfo.IStateProps
type IDispatchProps = News.TimelineInfo.IDispatchProps
type IOwnProps = News.TimelineInfo.IOwnProps
type IState = News.TimelineInfo.IState

export const Presenter: FC<IPresenterProps> = ({
    className,
    timelineInfo,
    // currencyNames,
    currencyNameAccusativeSingular,
    currencyNameGenitivePlural,
    currencyNameGenitiveSingular,
    userSex
}) => {
    const { value, transactionType, isDeleted } = timelineInfo
    const currencyNames: { [s: string]: string } = {
        currencyNameAccusativeSingular,
        currencyNameGenitivePlural,
        currencyNameGenitiveSingular
    }

    return <div className={cnClassName({}, [className])}>
        <span>{transactionType === 'Earned' ? <Translate i18nKey={`pryaniky.timeline.earned.${userSex}`} /> : <Translate i18nKey={`pryaniky.timeline.given.${userSex}`} />}</span>
        <div className={cnClassName('Value', [isDeleted ? 'Deleted' : transactionType])}>
            <img src={PurseImg} />
            <span>{value}</span>
        </div>
        <span>{currencyNames[getCurrencyNameCase(value, true)]}</span>
    </div >
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(mapStateToProps, mapDispatchToProps)(memo(Presenter))
