import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";
// import { Button } from 'uielements/src/Button/Button';
import { mapStateToProps, INewsEditStateProps, cnNewsEdit, INewsEditProps, INewsEditState } from './NewsEdit.index';
import './NewsEdit.scss';
// import { Body } from './Body/NewsEdit-Body'
// import { Attachments } from '../Attachments/Attachments';
// import { Icon } from 'uielements/src/Icon/Icon';
// import { Dropzone } from 'uielements/src/Dropzone/Dropzone';
// import { v1 as uuid } from 'uuid'
import { NewsEdit as NewsEditNew } from '../NewsEdit_new/NewsEdit'

export class NewsEditPresenter extends React.Component<INewsEditProps, INewsEditState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  private dropzone: any;

  private filesToRemove: any[] = [];

  constructor(props: INewsEditProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {
      post: { ...props.post }
    };
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'div', children, className = '',editeComplite,disableEdit } = this.props;
    const { post } = this.state;
    return (
      <TagName ref={el => this.el = el} className={cnNewsEdit({}, [customClassNames, className])}>
        <NewsEditNew cancleEdit={disableEdit} compliteEdit={editeComplite} data={this.props.post} type={this.props.post.newsTypeMain}/>

{/*
        <Body post={post} onChangeText={this.onChangeText} onChangePost={this.onChangePost} type={post.newsTypeMain} />

        {post.attachments && <Attachments files={post.attachments} onDelete={this.onFileDelete} />}

        <div className={cnNewsEdit('Action') + ' horizontalMargin'}>
          <div className={cnNewsEdit('LeftSection')}>
            <Button onClick={this.openDropzone} theme={'unstyled'}><Icon icon='clip' /></Button>
          </div>

          <div className={cnNewsEdit('RightSection')}>
            <Button main={true} onClick={this.sendEdited} className={cnNewsEdit('SaveBtn')}>{i18n.t('save')}</Button>
            <Button onClick={this.cancleEdit} className={cnNewsEdit('CancleBtn')}>{i18n.t('cancel')}</Button>
          </div>
        </div>
        <Dropzone
          reference={this.addDZtoref}
          onDrop={this.onFilesDrop}
          hoverElem={this}
        />
*/}
        {children}
      </TagName>
    )
  }

  /*private onFilesDrop = (acceptedFiles: any) => {
    acceptedFiles.forEach((attach: any) => {
      attach.id = uuid();
      attach.progressId = 'progress-' + attach.id;
      attach.title = attach.name;

      const loadStart = (event: any, file: any) => {
        file.downloadStatus = 'processing';
      }

      const loadProgress = (event: any, file: any) => {
        const width = Math.ceil((event.loaded * 100) / event.total);
        const progress: HTMLDivElement | null = document.body.querySelector('#' + file.progressId);
        if (progress) progress.style.width = width + '%';
      }

      const loadFinish = (response: any, file: any) => {
        const progress: HTMLDivElement | null = document.body.querySelector('#' + file.progressId);
        if (utils.checkResponseStatus(response)) {
          if (progress) progress.classList.add('success');
          const attachments = this.state.post.attachments.map((el: any) => {
            if (el.id === file.id) {
              el = response.data[0];
              el.title = el.name;
              el.progressId = file.progressId;
              el.downloadStatus = 'finish';
            }
            return el;
          });
          this.setState((s: INewsEditState) => {
            s.post.attachments = attachments;
            return s;
          })
        } else {
          if (progress) progress.classList.add('error');
        }
      }
      utils.API.files.upload(attach, { loadStart, loadProgress, loadFinish });
    });
    this.setState((s: INewsEditState) => {
      if (s.post.attachments) s.post.attachments = [...s.post.attachments, ...acceptedFiles];
      else s.post.attachments = [...acceptedFiles];
      return s;
    });
  }

  private addDZtoref = (el: any) => {
    this.dropzone = el;
  }

  private openDropzone = () => {
    if (this.dropzone) this.dropzone.open();
  }*/

  private onFileDelete = (elem: any) => {
    this.setState((s: INewsEditState) => {
      s.post.attachments = s.post.attachments.filter((e: any) => {
        if (elem.id !== e.id) {
          return true;
        } else {
          this.filesToRemove.push(elem);
          return false;
        }
      })
      return s;
    })
    // utils.API.files.remove()
  }

  /*private onChangePost = (post: any) => {
    this.setState({ post });
  }*/

  /*private onChangeText = (text: string) => {
    this.setState((s: INewsEditState) => {
      s.post = { ...s.post, text }
      return s;
    })
  }*/

  private cancleEdit = () => {
    this.props.disableEdit();
  }

  /*private sendEdited = () => {
    /** FIXME! event markdown header! >>>>>> 
    const state = this.state;
    if (this.state.post.newsTypeMain === 'events') {
      state.post.header = this.state.post.uevent.title;
    }
    if (this.state.post.newsTypeMain === 'competitions') {
      state.post.header = this.state.post.creativeTask.title;
    }
    /** <<<<<<<< FIXME! event markdown header! 
    utils.API.news.post(this.state.post, true);

    this.filesToRemove.forEach((e: any) => {
      utils.API.files.remove(e.id);
    })

    this.props.editeComplite(this.state.post)
}*/

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const NewsEdit = connect<INewsEditStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps({})
)(compose(
  // withBemMod(cnNewsEdit(), {})
)(NewsEditPresenter))