import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { i18n, Translate } from 'localization';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as utils from 'utils/src/utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        formControlPhone: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
    })
);


export const String: FC<any> = ({ field, fieldId, value, onChangeFields, fieldsSet }) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState({ value: value, valid: true, error: '' });    
    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        // if (value === "") setEmail({ value, valid: true, error: '' });
        if (!utils.isEmail(value)) setEmail({ value, valid: false, error: i18n.t('pryaniky.modal.inviteUser.error') });
        if (value === "" || utils.isEmail(value)) {
            setEmail({ value, valid: true, error: '' });
            onChangeFields(fieldId, 'value', fieldsSet)(e.currentTarget.value)
        }
    };
    return (
        <FormControl className={classes.formControlPhone} id={fieldId}>
            <TextField
                disabled={field.isReadOnly}
                error={email.error !== ''}
                id="standard-error-helper-text"
                helperText={email.error !== '' && i18n.t('pryaniky.modal.inviteUser.error')}
                label={<Translate i18nKey={`pryaniky.user.fields.${fieldId}`} defaults={field.name} />}
                value={email.value}
                onChange={handleChangeEmail}
            />
        </FormControl>
    );

}

export default String