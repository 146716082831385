export const a = 'a'

// import * as React from 'react';
// import { StickyTable } from '../../StickyTable/StickyTable';
// import { connect } from 'react-redux';
// import { mapDispatchToProps, IDispatchProps } from "../../../redux/connector";
// import { Button, Avatar, Tooltip, Checkbox } from 'uielements/src';
// import queryString from 'query-string';
// import { API, queryStringFromObj } from 'utils/src/utils';
// import { toast } from 'react-toastify';
// import './NotificationsSettings_Group.scss';
// import { mapStateToProps, INotificationsSettingsGroupStateProps, cnNotificationsSettingsGroup, INotificationsSettingsGroupProps, INotificationsSettingsGroupState } from './NotificationsSettings_Group.index';
// import * as utils from 'utils/src/utils';
// import {withRouter} from 'react-router-dom'

// interface IGroupButtonTooltipProps {
//   // action: () => void;
//   name: string;
//   active: boolean;
//   imgUrl?: string;
//   href: string
// }

// const GroupButtonTooltip: React.FC<IGroupButtonTooltipProps> = ({ active, imgUrl, name, href }) => {
//   const anchor = React.useRef<HTMLElement>(null);
//   const [showTooltip, setShowTooltip] = React.useState(false);
//   return <React.Fragment>
//     <Button
//       onMouseEnter={() => setShowTooltip(true)}
//       onMouseLeave={() => setShowTooltip(false)}
//       theme={"unstyled"}

//       type={'rlink'}
//       href={href}
//       className={'NotificationsSettings-GroupsItem' + (active ? ' NotificationsSettings-GroupsItem__active' : '')}>
//       <Avatar
//         ref={anchor}
//         imgUrl={imgUrl}
//         name={name}
//         size={56}
//         className='NotificationsSettings-GroupAvatar'
//       />
//     </Button>
//     {showTooltip && <Tooltip anchor={anchor}>
//       {name}
//     </Tooltip>}
//   </React.Fragment>
// }
// export class NotificationsSettingsGroup extends React.Component<
//   INotificationsSettingsGroupProps,
//   INotificationsSettingsGroupState
//   > {

//   constructor(props: INotificationsSettingsGroupProps) {
//     super(props);
//     this.state = {
//       groups: [],
//       section: "common",
//       currentGroup: {},
//       settings: {
//         g: [],
//         destinations: []
//       }
//     }
//   }
//   public componentDidMount() {
//     this.loadSettings();
//   }
//   public render() {
//     const customClassNames = "";
//     const { tag: TagName = "div", children, className = "" } = this.props;
//     const { settings, groups, currentGroup } = this.state;
//     const section = queryString.parse(this.props.location.search).section || "common";
//     const currentGid = queryString.parse(this.props.location.search).gid || "-1";
//     const key = section === "groups" ? "nu" : "g";
//     const tdata = !settings[key] ? [] : settings[key];

//     if (section !== this.state.section) this.selectSection(section as any)();
//     if (currentGid !== "-1" && currentGroup.pkid !== currentGid && section === "groups") {
//       const ng = groups.find((val: any) => val.pkid === Number.parseInt(currentGid as any));
//       this.selectGroup(ng)();
//     }
//     return <div className="NotificationsBox">
//       <div className="NotificationsSettings-layout systemBorderColorGray systemBorder">
//         <h2 className={cnNotificationsSettingsGroup("Title")}>
//           {currentGroup.pkid ? (
//             <>
//               {i18n.t("pryaniky.notifications.settings.settings.for.group")}
//               <span className={cnNotificationsSettingsGroup("GroupName")}>{currentGroup.name}</span>
//             </>
//           ) : (
//               <>{i18n.t("select group")}</>
//             )}
//         </h2>
//         <div style={{ display: "flex", flexWrap: "wrap" }}>
//           {groups.map((item: any, i: number) => (
//             <GroupButtonTooltip
//               key={item.pkid}
//               // action={this.selectGroup(i)}
//               href={"?" + queryString.stringify({ section: "groups", gid: item.pkid })}
//               active={item.pkid === currentGroup.pkid}
//               name={item.name}
//               imgUrl={
//                 item.imgId198x198 !== "00000000-0000-0000-0000-000000000000" ? item.imgUrl198x198 : undefined
//               }
//             />
//           ))}
//         </div>
//       </div>
//       {tdata.map((item: any, index: number) => (
//         <div className="NotificationsSettings-layout systemBorderColorGray systemBorder " key={index}>
//           <h2>{item.displayName}</h2>
//           <div className="NotificationsSettings-wrapper">
//             <StickyTable
//               columnsHeaders={settings.destinations.map((v: any) => v.value)}
//               rowsHeaders={item.n.map((v: any) => v.displayName)}
//               rows={this.prepareRows(item)}
//             />
//             <Button className={cnNotificationsSettingsGroup("saveButton")} onClick={this.saveSettings}>
//               {i18n.t("save")}
//             </Button>
//           </div>
//         </div>
//       ))}
//     </div>
//   }
//   private selectSection = (section = "common") => () => {
//     this.setState({ section, currentGroup: {}, settings: { g: [], destinations: [] } }, () => {
//       const id = this.props.userId || utils.getCookie('authUid');
//       section === "groups" ? this.loadGroups(id) : this.loadSettings();
//     });
//   };
//   private loadGroups(id: string) {
//     API.groups.byUser(id, "my").r.then(d => {
//       this.setState({ groups: d.data }, () => {
//       });
//     });
//   }

//   private selectGroup = (ng: any) => () => {
//     if (!ng || ng.pkid === this.state.currentGroup.pkid) return;
//     this.setState(
//       (s: INotificationsSettingsGroupState) => {
//         s.currentGroup = ng; 
//         s.settings = { g: [], destinations: [] };
//         return s;
//       },
//       () => {
//         this.loadSettings(this.state.currentGroup.pkid);
//       }
//     );
//   };

//   private loadSettings = (gid = 0) => {
//     const id = this.props.userId || utils.getCookie('authUid');
//     API.notifications.settings.get(id, gid).r.then(d => {
//       this.setState({ settings: d.data }, () => {
//         //  this.widgetTab1 = generateWidget({
//       });
//     });
//   };
//   private prepareRows = (item: any) => {
//     return item.n.map((v: any) => ({
//       cells: v.values.map((s: any, i: number) => (
//         <Checkbox
//           key={i}
//           checked={s.value}
//           onClick={() => {
//             s.value = !s.value;
//             this.setState({});
//           }}
//         />
//       ))
//     }));
//   };
//   private transformDataToOldGroup(data: any, uid: string) {
//     return {
//       uid: uid,
//       groupId: data.groupId,
//       g1: {
//         groupId: data.groupId,
//         displayName: data.displayName,
//         selectedTemplateName: "custom", // data.selectedTemplateName,//"custom",
//         nu: data.nu
//       },
//       auth: data.auth
//     };
//   }
//   private saveSettings = () => {
//     const { currentGroup, section, settings } = this.state;
//     const id = this.props.userId || utils.getCookie('authUid');
//     // this.props.store.authUser.profileData.userId;
//     const resultPromiss =
//       section === "groups"
//         ? API.notifications.settings.setGroup(currentGroup.pkid, id, this.transformDataToOldGroup(settings, id)) //this.state.data) :
//         : API.notifications.settings.set(id, settings);

//     resultPromiss.r.then((d: any) => {
//       if (d.error_code === 0) {
//         toast.success(i18n.t("success"));
//       } else {
//         toast.error(i18n.t("server error"));
//       }
//     });
//   };
// }
// export default withRouter(NotificationsSettingsGroup)
// // export default NotificationsSettingsGroup;