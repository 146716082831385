import { i18n, Translate } from 'localization';
import { CommentDialog, Typography } from 'muicomponents/src';
import { UsersListItem } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import React, { ComponentProps, FC, useCallback, useMemo, useRef } from 'react';
import { generatePath } from 'react-router';
import { toast } from 'react-toastify';
import { useDSItem } from 'redux/dataStorage/hooks';
import { checkResponseStatus, mainUrls, ResponseError } from 'utils/src';
import { UserVacancyStatus, Vacancy } from 'utils/src/BaseTypes/vacancy';
import { vacancyUserRequestAction } from 'utils/src/requests/admin.vacancies';
import { MatchBlock } from '../../MatchBlock';

const Text: FC<{
    textKey: string;
}> = ({ textKey, children }) => {
    return (
        <Typography sx={{ fontSize: '14px' }}>
            <Translate i18nKey={textKey} />
            :&nbsp;
            {children}
        </Typography>
    );
};

const allowedActions: (keyof typeof UserVacancyStatus)[] = ['InvitedToInterview', 'Cancelled'];

export const VacancySuggestedUsersListItem: FC<{
    vacancyId: Vacancy['id'];
    id: Vacancy['requests'][number]['id'];
}> = ({ vacancyId, id }) => {
    const { item: data } = useDSItem('vacanciesSuggests', id);
    const dataRef = useRef(data);
    dataRef.current = data;

    // const comments = useMemo(() => {
    //     if(!data) return null;
    //     return (
    //         <Box>
    //             {
    //                 data.comment
    //                 &&
    //                 <Text textKey="pryaniky.vacancy.request.comment">
    //                     {data.comment}
    //                 </Text>
    //             }
    //             {
    //                 data.hrNote
    //                 &&
    //                 <Text textKey="pryaniky.vacancy.request.hrNote">
    //                     {data.hrNote}
    //                 </Text>
    //             }
    //         </Box>
    //     );
    // }, [data?.comment, data?.hrNote]);

    const options = useMemo<ComponentProps<typeof UsersListItem>['options']>(() => {
        if (!data) return undefined;
        return allowedActions.map((el) => {
            return {
                content: <Translate i18nKey={`pryaniky.vacancy.user.action.${el}`} />,
                id: el,
            };
        });
    }, []);

    const sendAction = useCallback(
        async function (action: NonNullable<ComponentProps<typeof UsersListItem>['options']>[number]) {
            if (!dataRef.current) return;
            try {
                const { comment } = await CommentDialog({
                    header: <Translate i18nKey={'pryaniky.vacancy.request.comment.header'} />,
                    acceptButtonText: <Translate i18nKey={'send'} />,
                });
                const response = await vacancyUserRequestAction({
                    id: vacancyId,
                    action: action.id,
                    model: {
                        ...dataRef.current,
                        status: action.id,
                        hrNote: comment,
                    },
                });
                if (checkResponseStatus(response)) {
                    toast.success(<Translate i18nKey={'pryaniky.vacancy.request.changestatus.success'} />);
                } else {
                    throw new ResponseError('ChangeStatusError');
                }
            } catch (e) {
                if (e instanceof ResponseError) {
                    toast.error(<Translate i18nKey={'pryaniky.vacancy.request.changestatus.error'} />);
                } else {
                    console.error('change status error', e);
                }
            } finally {
            }
        },
        [vacancyId]
    );

    if (!data) return null;
    return (
        <UsersListItem<any>
            item={{
                ...data,
                url: generatePath(mainUrls.user.id, { id: data.id }),
            }}
            onClickAction={(action) => {
                sendAction(action);
            }}
            options={options}
            actions={{
                appendItems: () => {},
                findItem: () => {},
                prependItems: () => {},
                removeItems: () => {},
                updateItem: () => {},
            }}
        >
            <MatchBlock matchPercent={data.matchPercentage} skills={data.skillMatchTags || []} />
        </UsersListItem>
    );
};
