import React, { useEffect, useState } from 'react'
import { Avatar, Dropdown, Button } from 'uielements/src'
import * as utils from 'utils/src/utils';
import { IGroupItem, cnGroupItem } from './NotificationsSettings_GroupItemSettings.index'
import './NotificationsSettings_GroupItemSettings.scss'
import { toast } from 'react-toastify'
import { i18n, Translate } from 'localization';
import queryString from 'query-string';

export const GroupItem = ({ imgUrl, item, networkId, userId, notificationsTemplate }: IGroupItem) => {

  
  

  const [notificationTemplateData, setNotificationTemplateData] = useState([])
  const [currentNotificationsTemplate, setCurrentNotificationsTemplate] = useState<any[]>([])

  const nitifiAction = (template: "all" | "common" | "none" | "custom") => () => {

    const currentTemplate = notificationsTemplate.map((item: any) => {
      return {
        title: item.displayName,
        buttonProps: { onClick: nitifiAction(item.name) },
        ...item
      }
    }).filter((item: any) => {
      return item.name === template
    } )
      
    setCurrentNotificationsTemplate(currentTemplate)

    utils.API.notifications.settings.setGroupTemplate(item.pkid, userId, template).r.then((d: any) => {
      toast.success(i18n.t('pryaniky.notifications.settings.template.toast.' + template))
    })
  }
  
  useEffect(() => {
    if (notificationsTemplate.length) {
      if (!notificationTemplateData.length) {

        setNotificationTemplateData(notificationsTemplate.map((item: any) => {
          return {
            title: item.displayName,
            buttonProps: { onClick: nitifiAction(item.name) },
            ...item
          }
        })
        )   
      }

      setCurrentNotificationsTemplate(notificationTemplateData.filter((template: any) => item.selectedNotifyTemplate === template.name))
    }

  }, [notificationTemplateData, notificationsTemplate.length])
  

  return (
    <div className={cnGroupItem()}>
      <div className={cnGroupItem('Title')}>
        <Avatar
          name={item.name}
          imgUrl={imgUrl}
          size={56}
        />
        <span>{item.name}</span>
      </div>
      <div className={cnGroupItem('Settings')}>
        <Dropdown
          className={cnGroupItem('Settings-Dropdown')} 
          text={currentNotificationsTemplate[0] && currentNotificationsTemplate[0].title || 'Пользовательские'}
          buttonProps={{ main: true }}
          withCaret
          options={notificationTemplateData}
        />
        <Button type='rlink' theme='unstyled' href={"?" + queryString.stringify({ section: "groups", gid: item.pkid, network: networkId })}>Посмотреть детали</Button>
      </div>
    </div>
  )
}