import CakeIcon from '@mui/icons-material/Cake';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { FC } from 'react';
import { CustomEventTitle } from '../../CustomEvent.styled';

// const Icon: any = styled(CakeIcon)({
//     color: '#C344BE'
// })
export const BirthdayEvent: FC<any> = ({ event }) => {
    return (
        <>
            <CakeIcon
                sx={{ color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`, marginRight: '8px' }}
            />
            <Tooltip overflowOnly title={`${event.user.displayName}`}>
                <CustomEventTitle>{event.user.displayName}</CustomEventTitle>
            </Tooltip>
        </>
    );
};
