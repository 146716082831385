import React, { FC, useEffect, memo, useState } from 'react'
import {
    cnClassName,
    SkillsHeaderInterface
} from './SkillsHeader.interface'

import { i18n, Translate } from 'localization';
import { Button } from 'muicomponents/src/Button/Button'
import { LoadingButton } from 'muicomponents/src/LoadingButton/LoadingButton'
import ButtonGroup from '@mui/material/ButtonGroup';
import { Box, Typography } from 'muicomponents/src'
import { DialogCreate } from '../Dialogs/CreateDialog/CreateDialog'
import { DialogEdit } from '../Dialogs/EditDialog/EditDialog'
import { AddCircleOutline } from 'muicomponents/src/Icons';
import { ISkill } from 'redux/sagas/Skills/types'
import * as utils from 'utils.project/utils.project'
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';

type IPresenterProps = SkillsHeaderInterface.IPresenterProps

export const SkillsHeader: FC<IPresenterProps> = ({
    className,
    onComplete,
    onDelete,
    showControls,
    showCreate,
    withError,
    currentUser,
    skills
}) => {
    useEffect(() => {
    }, [])

    // const onCreate = () => setCreate(!create)



    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "16px" }} className={cnClassName({}, [className])} >
        <Box className={cnClassName('Title')}>
            <Typography className={cnClassName("IconUp")} sx={{ fontSize: "16px", fontWeight: "700", lineHeight: "18.56px", letterSpacing: "0.15px" }}>{i18n.t("pryaniky.skills.header.1")}</Typography>
            {skills.length > 0 && (showControls ? <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "20.02px", letterSpacing: "0.15px" }}>{i18n.t("pryaniky.skills.subheader.1")}</Typography> :
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "20.02px", letterSpacing: "0.15px" }}>{i18n.t("pryaniky.skills.subheader.2")}</Typography>)}
        </Box>
        {(showControls && showCreate) ? <Box className={cnClassName('CreateBox')}>
            <Button size={'small'} variant={'outlined'} className={cnClassName('CreateBtn')} onClick={() => DialogEdit({
                currentUser,
                onComplete,
                withError,
                skills
            }).then((value: {
                deleteArr: ISkill[];
                createArr: ISkill[]
            }) => {
                // utils.confirm({
                //     text: i18n.t('pryaniky.skills.edit.confirm.text'),
                //     title: i18n.t('pryaniky.skills.edit.confirm.title'),
                //     onConfirm: () => {
                onComplete(undefined, value.createArr)
                value.deleteArr.length > 0 && onDelete(value.deleteArr)
                //     }
                // })

            }).catch(() => { })}>
                {/* }) => onComplete(undefined, value.createArr)).catch(() => { })}> */}
                {/* <AddCircleOutline  className={cnClassName("IconUp CustomStyle primaryColor3-text")} color='primary' sx={{ marginRight: "5px" }} /> */}
                {i18n.t("pryaniky.skills.edit")}
            </Button>
        </Box> :
            <Box className={cnClassName('CreateBox')}>
                <LoadingButton
                    size={'small'}
                    variant={'outlined'}
                    className={cnClassName('CreateBtn')}
                    loading={!showCreate}
                    onClick={() => DialogCreate({
                        currentUser,
                        onComplete,
                        withError
                    }).then((value: { title: SelectableObject | null }) => onComplete(value.title?.displayName, undefined)).catch(() => { })}>
                    <AddCircleOutline className={cnClassName("IconUp CustomStyle primaryColor3-text")} color={!showCreate ? "disabled" : 'primary'} sx={{ marginRight: "5px" }} />
                    {i18n.t("pryaniky.skills.add.1")}
                </LoadingButton>
            </Box>
        }
    </Box>
}

export default memo(SkillsHeader);
