/**
 * @packageDocumentation
 * @module Widget_Settings_users_group
 */
import { JFdictionary } from "../dictionary";
import { i18n, Translate } from 'localization';

const { types } = JFdictionary

const subtypes = [
  'timeline',
  'timelineH',
  'calendar',
  'creativetasks',
  'events',
  'ideas',
  'leaderboard',
  'polls',
  'shop',
  'subnetworks',
  'wikilist',
  'groups',
  'users',
  'after35',
  'questsmoderate',
  'quests',
  'tagcat',
  'workflows',
  'achievements',
  'socorgchartunits'
]

export const prepareOption = (value: string) => ({
  title: i18n.t(`pryaniky.widgets.settings.filters.type.${value}`),
  value
})

export default {
  type: types.object,
  properties: {
    type: {
      type: types.string,
      require: true,
      placeholder: 'select type',
      opts: subtypes.map(el => prepareOption(el))
    }
  }
}
