import * as React from 'react'
import { Button } from 'uielements/src/Button/Button';
import './DistributeUserList.scss';
import { IDistributeUserListProps } from './DistributeUserList.index'
// import { getInvestors, searchInvestors } from '../IdeasSides'
import { UserSelector } from '../UserSelector/UserSelector';
import { Avatar } from 'uielements/src/Avatar/Avatar';
import { i18n, Translate } from 'localization';

interface IListItemProps {
    user: any;
    addi: React.ReactNode;
}
const ListItem: React.FC<IListItemProps> = ({ user, addi }) => {
    return (
        <div className={'List-Item List-Item_type_common'}>
            <Avatar
                imgUrl={user.userPhotoUrl}
                size={56}
                name={user.displayName}
            />
            <div className={'List-Item-DataPart'}>
                <div>{user.displayName}</div>
                <div>{user.position}</div>
                <div>{user.division}</div>
            </div>
            {addi}
        </div>
    )
}


export const DistributeUserList: React.FC<IDistributeUserListProps> = ({ data, search, onSearch, addInvestor, removeInvestor, changeAward }) => {
    return (
        <div className={'DistributeUserList'}>
            <UserSelector onChange={onSearch} />
            <div className={'List-Layout List-Layout_type_common'}>
                {search.map((user: any) =>
                    <ListItem key={user.id} user={user} addi={
                        <React.Fragment>
                            <div className={'_List-Item-DataPart'} style={{ flex: '1 1', width: 'auto' }}>

                            </div>
                            <div className={'_List-Item-DataPart'}>
                                <Button
                                    onClick={addInvestor(user)}
                                >{i18n.t('add')}</Button>
                            </div>
                        </React.Fragment>
                    } />
                )}
                {data.map((user: any) =>
                    <ListItem key={user.id} user={user} addi={
                        <React.Fragment>
                            <div className={'_List-Item-DataPart'} style={{ flex: '1 1', width: 'auto' }}>
                                <div>{i18n.t('pryaniky.idea.user.invest')}: <span>{user.invested}</span></div>
                                <div>{i18n.t('pryaniky.idea.reward')}: 
                                    <input
                                        type='number'
                                        value={user.awards ? user.awards.toString(): 0}
                                        onChange={changeAward(user)}
                                    />
                                </div>
                            </div>
                            <div className={'_List-Item-DataPart'} >
                                <Button style={{ position: 'relative', left: "12px" }}
                                    onClick={removeInvestor(user)}
                                >{i18n.t('delete')}</Button>
                            </div>
                        </React.Fragment>
                    } />
                )}
            </div>
        </div>)
}


