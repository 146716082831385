import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { IParams, IWorkflowsProps, mapDispatchToProps } from './Workflows.index';
import { Page } from 'blocks/Page/Page';
import { useParams } from 'react-router';
import { pageNames } from 'redux/pageNames';

export const WorkflowPresenter: React.FC<IWorkflowsProps> = ({ reset }) => {

  const { type } = useParams<IParams>();

  useEffect(() => {
    reset('currentGroup');
    reset('groupedKeys');
  }, [type])

  return <Page
    page={{
      name: pageNames.workflows + '.' + type,
      params: { id: type,  }
    }} />
}

export const Workflows = connect(
  null,
  mapDispatchToProps
)(WorkflowPresenter)

