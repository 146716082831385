import React, {FC} from 'react'
import { connect } from 'react-redux'
import { cnSearch, ISearchProps, mapDispatchToProps, mapStateToProps } from './Search.index'
import { List as UsersSearchList } from 'blocks/List/_type/users/List_type_users_search'
import { List as GroupsSearchList } from 'blocks/List/_type/groups/List_type_groups_search'
import { List as WikilistSearchList } from 'blocks/List/_type/wiki/List_type_wiki'
import Timeline from 'News/containers/Timeline';
import { i18n, Translate } from 'localization';
import { FilterSubnetworks } from 'blocks/Filters/_type/Filters_type_subnetworks'

import { Tabs } from 'uielements/src'

import './Search.scss'

const SearchPresenter:FC<ISearchProps> = ({ query, activeTab, setActiveTab, tabsR, context, networksLength }: ISearchProps, props: any) => {

  const listContext = {
    search: query,
    network: context.network,
    viewType: 1
  }

  const cls = [cnSearch()]
  if (networksLength > 1) {
    cls.push(cnSearch('WithFilter'))
  }

  return (
    <div className={cnSearch('Wrapper')}>
      <div className={cls.join(' ')}>
        <div className={` ${cnSearch('Header')} Widget`}>
          <div className={cnSearch('RequestTitle')}>
            <span className={cnSearch('RequestTitle-Text')}>{query.length === 0 ? i18n.t('pryaniky.search.advancedSearch') : i18n.t('pryaniky.search.foundByRequest')}</span>
            <span className={cnSearch('RequestTitle-Query')}>{query}</span>
          </div>
          <div className={cnSearch('Tabs')}>
            <Tabs tabsList={tabsR.map((e: any) => ({ ...e, title: <Translate i18nKey={e.title} /> }))} onChange={setActiveTab} selected={activeTab} />
          </div>
        </div>

        <div className={cnSearch('Results')}>
          {activeTab === 'users' && <UsersSearchList className={cnSearch('ListUsers')} context={listContext} id={"userlist"}{...props} />}
          {activeTab === 'groups' && <GroupsSearchList className={cnSearch('ListGroups')} context={listContext} id={"grouplist"} {...props} />}
          {activeTab === 'pages' && <WikilistSearchList className={cnSearch('ListWiki')} context={listContext} id={"wikilist"} searchPage={true}  {...props} />}
          {activeTab === 'news' && <Timeline params={{ searchText: listContext.search, ...listContext }} className={cnSearch('ListNews')} context={{ postOff: true, searchText: listContext.search , ...listContext }} noNewsCreate {...props} />}
          {/* {activeTab === 'wikilist' && <Timeline params={{ searchText: listContext.search, ...listContext }} className={cnSearch('ListNews')} context={{ postOff: true, searchText: listContext.search , ...listContext }} noNewsCreate {...props} />} */}
          {/* {activeTab === 'rewards' && <Timeline context={{ postOff: true, oneNewsType: true, newsType: 'reward', ...listContext }} noNewsCreate {...props} />} */}
          {/* {activeTab === 'badges' && <BadgesList context={listContext} {...props} />} */}
        </div>
      </div>
      {networksLength > 1 &&
        <div className={`${cnSearch('Filter')} Widget`}>
          <FilterSubnetworks {...props} context={{ ...context, page: 'search', activeTab }} relations={['common']} />
        </div>
      }
    </div>
  )
}

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchPresenter)