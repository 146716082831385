import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from "redux/connector";

import { mapStateToProps as mapStateToPropsList, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListGroupsSearchDefaultProps, IListTypeGroupsSearchProps, avalibleFiltersGroupsSearch } from './List_type_groups_search.index';

import './List_type_groups.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { ListLite, Avatar, Button, Icon, Loading } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { Item } from '../..//Item/List-Item';
import { updateContext } from 'redux/actions/Widgets';
import { toast } from 'react-toastify';
import { GroupCreate } from 'blocks/Dialogs/Group/Create/GroupCreate';
import { TypedListPlaceholder } from 'uielements/src';

export class ListGroupsSearchPresenter extends AbstractList<IListTypeGroupsSearchProps, IListState> {
  public static defaultProps: IListGroupsSearchDefaultProps = {
    requestOptions: {
      search: '',
      extended: true,
      groupListFilter: 'all',
      uid: '',
      network: ''
    },
  };

  public type = 'groups';

  constructor(props: IListTypeGroupsSearchProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;
    this.getData();
  }

  public search = (text: string) => {
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false
      }
    });
    this.getData();
  };

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getGroups, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFiltersGroupsSearch[cKey]) opts[avalibleFiltersGroupsSearch[cKey]] = context[cKey];
    });

    // if (context.filter) {
    //   if (context.filter.type === 'type') opts.groupListFilter = context.filter.value;
    // }
    // if (context.uId) {
    //   opts.uid = context.uId;
    //   opts.groupListFilter = 'my';
    // }

    if (this.searchString && this.searchString !== '') opts.search = this.searchString;
    toggle({
      id: this.listId,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });
    getGroups.call(this as any, id, opts);
  };

  public componentDidUpdate(pp: IListTypeGroupsSearchProps) {
    const { context: c, toggle, isLoading } = this.props;
    const { context: pc } = pp;
    if (
      Object.keys(avalibleFiltersGroupsSearch).filter((fk) => c[fk] !== pc[fk] ? true : false).length
    ) {
      toggle({
        id: this.listId,
        data: {
          hideData: true,
          clearData: true,
          isFinished: false,
        }
      });
      // this.getData()
      setTimeout(() => this.getData(), 10);
    }
  }

  public prepareData(el: any) {
    el.id = el.pkid;
    let groupBadge;
    if (el.isHidden) {
      groupBadge = {
        style: { backgroundColor: '#91c1d7', color: 'white', fontSize: '8px' },
        text: <Icon icon="eye" style={{ position: 'relative', left: -2.5 }} />,
      };
    } else if (el.visibilityType) {
      groupBadge = {
        style: { color: 'white', backgroundColor: 'var(--danger-color)', fontSize: '8px' },
        text: <Icon icon="lock-alt" />,
      };
    }
    el.view_data = {
      avatar: (
        <Button theme='unstyled' type="rlink" href={'/group/' + el.pkid} >
          <Avatar
            key={el.imgId48x48}
            imgId={el.imgId48x48}
            imgUrl={el.imgUrl48x48}
            size={56}
            name={el.name}
            badge={groupBadge}
          />
          {
            el.showGroupInSubnetworks &&
            <div className={('Subsystem-Badge')}>
              <Icon icon="chart-network" />
            </div>
          }
        </Button>
      ),
      info: [
        [
          <Button className="groupeList" key={0} noBorder noPadding noBackground type="rlink" href={'/group/' + el.pkid} children={el.name} />,
          <Button key={1} theme='unstyled' type="rlink" href={'/group/' + el.pkid} children={el.description} />,
        ],
      ],
    };
    let buttonText = i18n.t('pryaniky.group.actions.join');
    if (!this.props.uIsAdmin && el.visibilityType === 1)
      buttonText = i18n.t(
        'pryaniky.group.actions.' + (el.isRequestAccessSent ? 'requestSent' : 'sendRequest')
      );
    if (el.isMeInGroup) buttonText = i18n.t('pryaniky.group.actions.leave');
    el.view_data.info[0].push(
      el.isOfficialGroup ? (
        <span className="Group-Label">
          {i18n.t('pryaniky.list.groups.official')}
          <Icon icon={'achievement'} />
        </span>
      ) : (
          !el.disableChangeJoinStatus ?
            <Button
              className={'List-Item_group-Button_action'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                this.joinAction(el);
              }}
              noPadding
              children={buttonText}
            /> :
            null
        )
    );
  }

  public joinAction = (el: any) => {
    if (el.isMeInGroup) {
      utilsProj.confirm({
        text: i18n.t('pryaniky.list.groups.actions.confirm.leave', { name: el.name }),
        onConfirm: () => this.joinOrLeave(el, false),
      });
    } else this.joinOrLeave(el, true);
  };

  public joinOrLeave = (el: { [s: string]: any }, _join: boolean) => {
    let join = false;
    if (el.visibilityType === 1) {
      el.isRequestAccessSent = !el.isRequestAccessSent;
      join = el.isRequestAccessSent;
      if (this.props.uIsAdmin) {
        el.isMeInGroup = el.isRequestAccessSent;
        join = el.isMeInGroup;
      }
    } else {
      el.isMeInGroup = !el.isMeInGroup;
      join = el.isMeInGroup;
    }
    utils.API.groups.join(el.id, this.props.uid, join).r.then((d: any) => {
      if (!d) toast.error(i18n.t('pryaniky.toast.error.server'));
      else if (d.error_code) toast.error(d.error_Message);
      else {

        this.prepareData(el);
        this.props.changeListItem({
          id: this.listId,
          data: {
            item: el.id,
            data: el,
          }
        });
      }
    });
  }

  // public renderChildren = () => {
  //   // const { isFinished, isLoading, hideData } = this.state;
  //   const { data, isFinished, isLoading, hideData } = this.props;
  //   // const data: any[] = this.state[this.dataVariable];
  //   return (
  //     <>
  //       {
  //         <ListLite
  //           className={'rounded-bottom'}
  //           isFinished={isFinished}
  //           isLoading={isLoading}
  //           loadMore={!hideData ? this.getData : utils.noop}
  //         >
  //           {
  //             data.length !== 0 && <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData} noSkeletons={isFinished}>
  //               {data.map(el => (
  //                 <Item
  //                   lId={this.listId}
  //                   iId={el}
  //                   type={'common'}
  //                   key={el}
  //                   data_key={el}
  //                 />
  //               ))}
  //             </ListLite>
  //           }
  //           {(!isLoading && data.length === 0) && <TypedListPlaceholder type='search' />}
  //         </ListLite>
  //       }
  //     </>
  //   );
  // };

  public renderChildren = () => {
    // const { isFinished, isLoading, hideData } = this.state;
    const { data, isFinished, isLoading, hideData } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    return (
      <>
            {
              data.length !== 0 && <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData} noSkeletons={isFinished}>
                {data.map(el => (
                  <Item
                    lId={this.listId}
                    iId={el}
                    type={'common'}
                    key={el}
                    data_key={el}
                  />
                ))}
              </ListLite>
            }
            {(!isLoading && data.length === 0) && <TypedListPlaceholder type='search' />}
      </>
    );
  };

  
  public toggleGroupCreateDialog = () => this.setState({ createDialog: !this.state.createDialog });
}

export const List = connect<any, any, any, any>(
  mapStateToPropsList,
  mapDispatchToProps(mapActionsToProps)
)(ListGroupsSearchPresenter);
