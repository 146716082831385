/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { JFdictionary } from '../dictionary';
import { i18n, Translate } from 'localization';

const { renderers } = JFdictionary;

export default {
    type: renderers.layout,
    elements: [
        {
            type: renderers.input,
            scope: '#/properties/name',
        },
        {
            type: renderers.tagsInput,
            scope: '#/properties/selectedTags',
        },
        {
            type: renderers.select,
            scope: '#/properties/selectedNews',
        },
        {
            type: renderers.number,
            scope: '#/properties/count',
        },
        {
            type: renderers.checkbox,
            label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
            scope: '#/properties/hideIfNoData',
        },
    ],
};
