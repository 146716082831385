import { IActionButtonsProps } from './UserSelector.index'
import * as React from 'react'
import { i18n, Translate } from 'localization';
import './UserSelector.scss';

export const UserSelector: React.FC<IActionButtonsProps> = ({ onChange }) => {
    const [query, setQuery] = React.useState('');

    const changeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        onChange(event.target.value);
    }
    const clean = () => {
        setQuery('');
        onChange('');
    }
    return (
        <div className={'DistributeSearchInput'}>
            <input className={'SearchInput-Input'} type="text" placeholder={i18n.t('pryaniky.idea.input.colleague')} value={query} onChange={changeQuery} />
            {/*<Button onClick={clean}>Clean</Button>*/}
        </div>
    );
}