import React, { FC, useEffect, SyntheticEvent, useMemo, useRef, memo, useCallback, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n, Translate } from 'localization';
import { Box, Typography } from 'muicomponents/src';
import { ActionButton } from 'muicomponents/src/ItemsListDialog/ActionButton/ActionButton'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import { CheckboxSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckboxForm'
import { RadioSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/RadioForm'
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm'
import { ListParamsMenu } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/ListParamsSelector'
import { ParamsPanel } from 'muicomponents/src/ItemsListDialog/ParamsPanel/ParamsPanel'
import { IStateType } from 'redux/store';
import { TOption } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/ListParamsSelector.index';
import { setShopFilters, resetFilters, getShopCharasteristics } from 'redux/shop/actions';
import { IShopFilter } from 'utils/src/requests/models/api.shop';
import { IShopSortings } from 'redux/shop/interfaces';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Filter } from './Filter'
import Skeleton from '@mui/material/Skeleton';

const optionMapper = (v: IShopFilter) => ({ label: v.name, value: v.id.toString(), bold: !!v.additionalFields?.isBold, count: v.count })
const initialSorts: IShopSortings = {
    OrderByPrice: '',
    // OrderByRating: '', убираем сортировку по рейтингу по тикету COMMON-11020
    OrderByPopular: '',
    OrderByCreateAt: '',
}
const sortOption: TOption[] = [
    {
        label: i18n.t(`pryaniky.shop.sort.default`),
        value: 'default_asc'
    },
    {
        label: i18n.t(`pryaniky.shop.sort.OrderByPrice_asc`),
        value: 'OrderByPrice_asc'
    },
    {
        label: i18n.t(`pryaniky.shop.sort.OrderByPrice_desc`),
        value: 'OrderByPrice_desc'
    },
    {
        label: i18n.t(`pryaniky.shop.sort.OrderByPopular`),
        value: 'OrderByPopular_desc'
    },
    {
        label: i18n.t(`pryaniky.shop.sort.OrderByCreateAt`),
        value: 'OrderByCreateAt_desc'
    },
]
export const FiltersTypeShopSkeleton = () => {
    return <Box sx={{ m: 0, p: 2, pt: 0, mb: 2 }}>
        <Box display='flex' alignItems='center'>
            <Skeleton variant="text" height={70} width={'80%'} sx={{ m: 0, p: 0, mr: 3 }} />
            <Skeleton variant="circular" height={40} width={40} sx={{}} />
        </Box>
        <Box display='flex' alignItems='center'>
            <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
            <Skeleton variant="text" height={60} sx={{ flex: 1, mr: 1 }} />
        </Box>
    </Box>
}


export const HeaderFilters: FC<{}> = ({ }) => {
    const dispatch = useDispatch()
    const categories = useSelector((s: IStateType) => s.shop.categoryProducts.filters)
    const chosenCatFilters = useSelector((s: IStateType) => s.shop.chosenFilters.catFilters)
    const areFavouritesChosen = useSelector((s: IStateType) => s.shop.chosenFilters.MyFavorite)
    // const myFavouritesCount = useSelector((s: IStateType) => s.shop.categoryProducts.myFavoritesCount)
    const globalSorts = useSelector((s: IStateType) => s.shop.chosenFilters.sorting)
    const chosenFilter = useSelector((s: IStateType) => s.shop.chosenFilters)

    const isClosed = useSelector((s: IStateType) => s.shop.categoryProducts.isShopClosed)

    const chosenSort = Object.keys(globalSorts).find(v => globalSorts[v as keyof typeof globalSorts] !== '')

    const setSort = (item: string) => {
        if ('default_asc' === item) {
            dispatch(setShopFilters({
                key: 'sorting',
                value: { ...initialSorts }
            }))
            return;
        }
        const [key, value] = item.split('_')
        dispatch(setShopFilters({
            key: 'sorting',
            value: { ...initialSorts, [key]: value }
        }))
    }
    const sortValue = globalSorts[(chosenSort || '') as keyof typeof globalSorts]
    const sortKey = chosenSort + (sortValue ? `_${sortValue}` : '')


    const chosenAmount = useMemo(() => {
        let res = Object.keys(chosenFilter.characteristics).reduce((acc, el) => acc + chosenFilter.characteristics[el].length, 0)
        if (chosenFilter.MinPrice !== undefined || chosenFilter.MaxPrice !== undefined) res++
        if (chosenFilter.AllowToMeByPrice) res++
        return res
    }, [chosenFilter])


    const handleChangeFavourite = () => {
        dispatch(setShopFilters({
            key: 'MyFavorite',
            value: !areFavouritesChosen
        }))
    }



    const catOptions: TOption[] = categories ? categories.map(optionMapper) : []
    const catSelected = categories ? categories.filter(v => chosenCatFilters.includes(v.id)).map(optionMapper) : []

    const sortForceText = sortKey.startsWith('OrderByPrice') ? i18n.t(`pryaniky.shop.sort.OrderByPrice`) : undefined

    const handleChangeFilters = (value: TOption[]) => {
        dispatch(setShopFilters({
            key: 'catFilters',
            value: value.map(v => parseInt(v.value))
        }))
    }
    const handleChangeChar = (value: any[]) => {
        dispatch(setShopFilters(value))
    }
    const handleReset = () => {
        dispatch(resetFilters())
    }


    const withFilters = areFavouritesChosen || !!catSelected.length || !!chosenSort || !!chosenAmount
    if (isClosed) return <FiltersTypeShopSkeleton />
    return <ParamsPanel>
        <ActionButton
            onClick={handleChangeFavourite}
            badgeContent={' '}
            textColor={'gray'}
            invisible={!areFavouritesChosen}
            startIcon={areFavouritesChosen ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        >{i18n.t("pryaniky.filter.feed.favorites")}</ActionButton>

        <CheckboxSelector
            onChange={handleChangeFilters}
            enableSearch
            value={catSelected}
            icon={<GridViewIcon />}
            text={i18n.t("pryaniky.list.shop.header.categories")}
            options={catOptions}
        />

        <CheckButtonSelector
            icon={{
                "OrderByPrice_asc": <ArrowUpwardIcon />,
                "OrderByPrice_desc": <ArrowDownwardIcon />
            }[sortKey]}
            value={sortKey}
            onChange={setSort}
            forceText={sortForceText}
            options={sortOption}
            defaultText={i18n.t(`pryaniky.shop.sort`)}//{i18n.t(`pryaniky.shop.sort${chosenSort ? `.${chosenSort}` : ''}`)}
        />

        <ListParamsMenu
            badgeContent={chosenAmount}
            icon={<FilterAltIcon />}
            ParamsSelector={Filter}
            onChange={handleChangeChar}
            text={i18n.t("filters")}
        />

        <ActionButton
            disabled={!withFilters}
            onClick={handleReset}
            startIcon={<CleaningServicesIcon />}
        >{i18n.t('reset')}</ActionButton>
    </ParamsPanel>
}