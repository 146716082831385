import {
    takeEvery,
    put,
    takeLeading,
    takeLatest,
    throttle,
    fork
} from 'redux-saga/effects';

import {
    call,
    select,
} from 'utils/src/saga.effects';

import { routerActions } from 'connected-react-router';

import { toast } from 'react-toastify';

import {
    API, checkResponseStatus, getErrorText,
} from 'utils/src/utils'

import { i18n, Translate } from 'localization';

import {
    getGroupByPKID,
    getSettingsByPKID
} from './selectors'

import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

import { defaultBadge } from 'blocks/NewsTypes/Badges/Badges.type'

import actions from 'redux/actionsTypes/Groups';

import {
    loadGroupActionType,
    changeMyInGroupActionType,
    addGroup,
    addSettings,
    loadGroup,
    updateGroup,
    removeGroup,
    removeSettings,
    setNotificationTemplateActionType,
    loadSettingsActionType,
    applyTemplateToAllActionType,
    testAutoIncludeActionType,
    updateSettings,
    setAutoIncludeActionType,
    sendIntercompanyRequestActionType,
    cancleIntercompanyRequestActionType,
    cancleEditActionType,
    applyEditActionType,
    loadRequestsActionType,
    approveRequestsActionType,
    uploadImportActionType,
    selectTagsAutoActionType,
    setProcessing,
    joinUserToGroupActionType,
    joinUserToGroup
} from 'redux/actions/Groups'

import {
    loadAllowPostTypes
} from 'redux/actions/AppSettings'

import {
    changeMeInGroup,
    convertDescriptionToDraft,
    convertDescriptionToMd
} from './utils'

import { CacheHelper } from 'utils/src/CacheHelper'
import { withSagaIndicator } from 'utils/src/CommonRedux/LoadIndicator'

type defaultActionType = { type: string, payload: any }


/**
 * загружает группу по указаному ид
 * @param action 
 */
const handleLoadGroup = function* handleLoadGroup(action: loadGroupActionType): any {
    try {
        const { groupPKID } = action.payload

        try {
            const fromCache = yield call(CacheHelper.get, 'groups', `${groupPKID}`)
            if (fromCache) yield put(addGroup(groupPKID, { ...fromCache.data, fromCache: true }))
        } catch (error) {
            console.warn('groups read cache', error)
        }

        const request = yield call(API.groups.byId, groupPKID)
        const result = yield request.r
        if (result.error_code === 0) {
            try {
                yield call(CacheHelper.set, 'groups', `${groupPKID}`, result)
            } catch (error) {
                console.warn('groups write cache', error)
            }

            return yield put(addGroup(groupPKID, result.data))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

const handleSendJoinGroup = function* ({ payload }: joinUserToGroupActionType): any {
    const { groupPKID, join, userId } = payload
    const request: any = yield call(API.groups.join, groupPKID, userId, join)
    const result = yield request.r
    if (result.error_code === 0) {

    } else {
        throw new Error("RequestError");
    }
}

const handleJoinGroup = function* handleJoinGroup(action: joinUserToGroupActionType): any {
    try {
        const { groupPKID, join, userId, goto } = action.payload

        yield call(withSagaIndicator(handleSendJoinGroup, action.type, `${action.type}_${groupPKID}_${userId}`), action)
        if (goto) {
            yield put(routerActions.push(goto))
        }
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * изменяет состояние текущего пользователя в группе и отправляет запрос на сервер
 * @param action 
 */
const handleChangeMyInGroup = function* handleChangeMyInGroup(action: changeMyInGroupActionType): any {
    try {
        const { groupPKID } = action.payload
        const group = yield select(getGroupByPKID(groupPKID))
        const authUser = yield select(getAuthUser)
        let cloneData: { [key: string]: any } = {};

        const join = changeMeInGroup(group.visibilityType, group.isRequestAccessSent, group.isMeInGroup, authUser.baseData.isAdmin)
        cloneData = { ...cloneData, ...join }

        const request = yield call(API.groups.join, groupPKID, authUser.baseData.id, join.join)
        const result = yield request.r
        if (result.error_code === 0) {
            yield put(updateGroup(groupPKID, cloneData))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * устанавливает текущему пользователю указаный шаблон уведомлений для указаной группы
 * @param action 
 */
const handleSetTemplate = function* handleSetTemplate(action: setNotificationTemplateActionType): any {
    try {
        const { groupPKID, template } = action.payload
        // const group = yield select(getGroupByPKID(groupPKID))
        const authUser = yield select(getAuthUser)

        const cloneData: { [key: string]: any } = {};

        cloneData.selectedNotifyTemplate = template;

        const request = yield call(API.notifications.settings.setGroupTemplate, groupPKID, authUser.baseData.id, template)
        const result = yield request.r
        if (result.error_code === 0) {
            yield put(updateGroup(groupPKID, cloneData))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * загружает настройки группы по указаному ид группы
 * @param action 
 */
const handleLoadSettings = function* handleLoadSettings(action: loadSettingsActionType): any {
    try {
        const { groupPKID } = action.payload
        const settingsRequest = yield call(API.groups.settings, groupPKID)
        const settingsResult = yield settingsRequest.r
        if (settingsResult.error_code !== 0) {
            yield;
            return 0;
        }
        const authUser = yield select(getAuthUser)
        const isAdmin = authUser.baseData.isAdmin
        const badgeRequest = yield call(API.badges.getBadgeList, (isAdmin ? undefined : 'mymanual'))
        const badgesResult = yield badgeRequest.r
        if (badgesResult.error_code !== 0) {
            yield;
            return 0;
        }
        settingsResult.data.descriptionDraft = convertDescriptionToDraft(settingsResult.data.description)
        return yield put(addSettings(groupPKID, { data: settingsResult.data, badges: [{ defaultBadge, displayName: 'Удалить бейдж' }, ...badgesResult.data] }))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * применяет шаблон уведомлений ко всем участникам находящимся в группе
 * @param action 
 */
const handleApplyTemplateToAll = function* handleApplyTemplateToAll(action: applyTemplateToAllActionType): any {
    try {
        const { groupPKID } = action.payload
        const settings = yield select(getSettingsByPKID(groupPKID))
        const request = yield call(API.groups.applyNotificationsTemplate, groupPKID, settings.data.defaultNotifyTemplate)
        const result = yield request.r
        if (result.error_code === 0) {
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получает с сервера список пользователей по указаному выражению
 * @param action 
 */
const handleTestAutoInclude = function* handleTestAutoInclude(action: testAutoIncludeActionType): any {
    try {
        const { groupPKID } = action.payload
        yield put(updateSettings(groupPKID, { testAutoIncludeProgress: true }));

        const settings = yield select(getSettingsByPKID(groupPKID))

        const request = yield call(API.users.list, { checkexpression: true, autoinclude: settings.data.peopleAutoIncludeExpression, gid: groupPKID })
        const result = yield request.r
        if (result.error_code === 0) {
            yield put(updateSettings(groupPKID, { testAutoInclude: result.data }));
        }
        yield put(updateSettings(groupPKID, { testAutoIncludeProgress: false }));
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * получает с сервера список пользователей по указаному выражению
 * @param action 
 */
const handleSetAutoInclude = function* handleSetAutoInclude(action: setAutoIncludeActionType): any {
    try {
        const { groupPKID } = action.payload
        yield put(updateSettings(groupPKID, { setAutoIncludeProgress: true }));
        const settings = yield select(getSettingsByPKID(groupPKID))

        const request = yield call(API.users.list, { checkexpression: false, autoinclude: settings.data.peopleAutoIncludeExpression, gid: groupPKID })
        const result = yield request.r
        if (result.error_code === 0) {
            toast.success(Translate.t({i18nKey: 'pryaniky.group.settings.autoInclude.add.success' }))
        } else {
            toast.error(Translate.t({i18nKey: 'pryaniky.group.settings.autoInclude.add.success' }))
        }
        yield put(updateSettings(groupPKID, { setAutoIncludeProgress: false }));
        yield;
        return 0;
    } catch (error) {
        toast.error(Translate.t({i18nKey: 'pryaniky.group.settings.autoInclude.add.success' }))
        console.warn(error)
    }
}

/**
 * отправляет заявку на перевод указаной шруппы в формат межбанковской
 * @param action 
 */
const handleSendIntercompanyRequest = function* handleSendIntercompanyRequest(action: sendIntercompanyRequestActionType): any {
    try {
        const { groupPKID, data } = action.payload
        const { attendance, content, createTarget } = data
        const paramsString = `${createTarget}%COMMENTSPLITTER%${content}%COMMENTSPLITTER%${attendance}`
        const request = yield call(API.groups.makeGroupIntercompany, groupPKID, paramsString)
        const result = yield request.r
        if (result.error_code === 0) {
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * отправляет заявку на перевод указаной шруппы в формат межбанковской
 * @param action 
 */
const handleCancleIntercompanyRequest = function* handleCancleIntercompanyRequest(action: cancleIntercompanyRequestActionType): any {
    try {
        const { groupPKID, comment } = action.payload
        const request = yield call(API.groups.cancelRequestGroupIntercompany, groupPKID, comment)
        const result = yield request.r
        if (result.error_code === 0) {
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * отменияет редактирование группы
 * @param action 
 */
const handleCancleEdit = function* handleCancleEdit(action: cancleEditActionType): any {
    try {
        const { groupPKID } = action.payload
        yield put(routerActions.push(`?groupEdit=false`))
        return yield put(removeSettings(groupPKID))
    } catch (error) {
        console.warn(error)
    }
}

/**
 * отравляет изменения на сервер
 * @param action 
 */
const handleApplyEdit = function* handleApplyEdit(action: applyEditActionType): any {
    try {
        const { groupPKID } = action.payload
        const settings = yield select(getSettingsByPKID(groupPKID))
        yield put(setProcessing(groupPKID, 'APPLY_EDIT', 'process'))
        settings.data.description = convertDescriptionToMd(settings.data.descriptionDraft)
        const request = yield call(API.groups.saveSettings, groupPKID, { ...settings.data, descriptionDraft: undefined })
        const result = yield request.r
        if (result.error_code === 0) {
            yield call(handleLoadGroup, loadGroup(groupPKID))
            yield put(loadAllowPostTypes(groupPKID))
            yield put(routerActions.push(`?groupEdit=false`))
            yield put(setProcessing(groupPKID, 'APPLY_EDIT', 'success'))
            return yield put(removeSettings(groupPKID))
        } else {
            toast.error(i18n.t('pryaniky.group.settings.save.error'));
            yield put(setProcessing(groupPKID, 'APPLY_EDIT', 'failed'))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * отправляет запрос для получения списка заявок на вступление в группу
 * @param action 
 */
const handleLoadRequests = function* handleLoadRequests(action: loadRequestsActionType): any {
    try {
        const { groupPKID } = action.payload
        const request = yield call(API.groups.getRequestsList, groupPKID)
        const result = yield request.r
        if (result.error_code === 0) {
            return yield put(updateGroup(groupPKID, { requestList: result.data }))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * отправяет запрос к серверу на подтверждение или откланение заявок на вступление в группу
 * @param action 
 */
const handleApproveRequests = function* handleApproveRequests(action: approveRequestsActionType): any {
    try {
        const { groupPKID, join, usersId } = action.payload
        const group = yield select(getGroupByPKID(groupPKID))
        const request = yield call(API.groups.setRequestApprove, groupPKID, usersId, join)
        const result = yield request.r
        if (result.error_code === 0) {
            const filtredList = group.requestList.filter((user: any) => !usersId.includes(user.id))
            return yield put(updateGroup(groupPKID, { requestList: filtredList }))
        }
        yield;
        return 0;
    } catch (error) {
        console.warn(error)
    }
}

/**
 * загрузить файл со списком пользователей для добавления в группу
 * @param action 
 */
const handleUploadImport = function* handleUploadImport(action: uploadImportActionType) {
    try {
        yield;
        return 0;
    } catch (error) {
        console.error(error)
    }
}

const handleSelectTagsAuto = function* handleSelectTagsAuto(action: selectTagsAutoActionType): any {
    try {
        const { groupPKID } = action.payload;
        const groupSettings = yield select(getSettingsByPKID(groupPKID));
        const currentTags = groupSettings?.data?.analyticTags || [];

        const response = yield call(API.groups.getGroupTagsAuto, groupPKID);
        const newTags: any[] = response.data;

        if (checkResponseStatus(response) && newTags.length) {
            yield put(updateSettings(groupPKID, { analyticTags: [...currentTags, ...newTags] }))
        } else if (newTags.length === 0) {
            toast.warn(i18n.t('pryaniky.group.settings.autotags.notfound'));
        }
        else toast.error(getErrorText(response));
        yield;
        return 0;
    } catch (error) {
        console.error(error)
    }
}

/**
 * module root saga
 */
const root = function* root() {
    yield takeLatest(actions.LOAD_SETTINGS, handleLoadSettings)
    yield takeLatest(actions.LOAD_GROUP, handleLoadGroup)

    yield takeLeading(actions.CHAGE_MY_IN_GROUP, handleChangeMyInGroup)
    yield takeLeading(actions.SET_NOTIFICATE_TEMPLATE, handleSetTemplate)
    yield takeLeading(actions.APPLY_NOTIFICATIONS_TEMPLATE, handleApplyTemplateToAll)
    yield takeLeading(actions.TEST_AUTO_INCLUDE, handleTestAutoInclude)
    yield takeLeading(actions.SET_AUTO_INCLUDE, handleSetAutoInclude)
    yield takeLeading(actions.SEND_INTERCOMPANY_REQUEST, handleSendIntercompanyRequest)
    yield takeLeading(actions.CANCLE_INTERCOMPANY_REQUEST, handleCancleIntercompanyRequest)
    yield takeLeading(actions.CANCLE_EDIT, handleCancleEdit)
    yield takeLeading(actions.APPLY_EDIT, handleApplyEdit)
    yield takeLeading(actions.LOAD_GROUP_REQUESTS, handleLoadRequests)
    yield takeLeading(actions.APPROVE_REQUESTS, handleApproveRequests)
    yield takeLeading(actions.UPLOAD_IMPORT_USERS_FILE, handleUploadImport)
    yield takeLeading(actions.JOIN_USER_TO_GROUP, handleJoinGroup)
    yield takeLeading(actions.SELECT_TAGS_AUTO, handleSelectTagsAuto)
};

/**
 * export root saga
 */
export default root;