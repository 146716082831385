export const a = 0
// import { connect } from 'react-redux'
// import React, { useState } from 'react'
// import { Filters } from '../Filters'
// import { Selectize, Button } from 'uielements/src'
// import { ISelectizeItem } from 'uielements/src/Selectize/Selectize.index'
// import { InputMention } from 'blocks/InputMention/InputMention'
// import './Filters_type_polls.scss'

// import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter } from './Filters_type_polls.index'
// import { Toggle } from 'uielements/src/Toggle/Toggle'

// const FiltersPollsPresenter: React.FC<IFiltersProps> = (props) => {

//   const [data, setData] = useState([
//     {
//       name: 'Опросы',
//       elements: [
//         {
//           id: '1',
//           title: 'Все',
//           type: 'statusIds',
//           value: 'all',
//         },
//         {
//           id: '2',
//           title: 'Глосование',
//           type: 'statusIds',
//           value: 'active',
//         },
//         {
//           id: '3',
//           title: 'Реализация',
//           type: 'statusIds',
//           value: 'expires',
//         },
//         {
//           id: '4',
//           title: 'Выполнено',
//           type: 'statusIds',
//           value: 'finished',
//         },
//         {
//           id: '5',
//           title: 'Архив',
//           type: 'statusIds',
//           value: 'canceled',
//         },
//       ],
//     },
//   ])

//   const Tag = props.tag || 'div'

//   const contextVariables = props.relations || ['common']

//   const handleChange = (selected: ISelectizeItem) => {
//     const { filter } = props.context
//     const same = filter && filter.id === selected.id;
//     props.changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
//       if (!same) acc[conID] = { filter: selected, [selected.type]: selected.value }
//       return acc
//     }, {} as { [s: string]: any }))
//   }

//   const [isModeration, setIsModeration] = useState(false)

//   const moderationHandler = () => setIsModeration(!isModeration)

//   return (
//     <Tag data={data} tag='div' context={'polls'} relations={[]} {...props} >
//       <h3 className={cnFilter('Title', ['BottomLine'])}>Фильтры:</h3>
//       {
//         data &&
//         data.map((filter, i) => {
//           const { elements } = filter
//           const filterChildren: any[] = []

//           return <Selectize key={i} onClick={handleChange} active={props.context && props.context.filter ? props.context.filter.id : undefined} name={filter.name} elements={elements || []} children={filterChildren} />
//         })
//       }

//       <h4 className={cnFilter('Subtitle')}>Теги:</h4>
//       <InputMention className='BottomLine' type='tags' placeholder={'Теги'} noMulti={false} />

//       <h4 className={cnFilter('Subtitle')}>Ответственный за этап:</h4>
//       <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={'Введите имя коллеги'} noMulti={false} />

//       <h4 className={cnFilter('Subtitle')}>Автор опроса:</h4>
//       <InputMention className='BottomLine' type='users' icon={'user-group'} placeholder={'Введите имя коллеги'} noMulti={false} />

//       <div className={cnFilter('Toggle')}>
//         <div className={cnFilter('Inner')}>

        
//         <h4>На модерации</h4>
//         <Toggle name='moderaton' checked={isModeration} onChange={moderationHandler} />
//       </div>
//       </div>

//       <div className={cnFilter('Bottom')}>
//         <Button type='button' padding='lg' className={cnFilter('Button')}>
//           Применить фильтр
//         </Button>
//       </div>
//     </Tag>
//   )
// }

// export const FiltersPolls = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
//   mapStateToProps,
//   mapDispatchToProps
// )(FiltersPollsPresenter)