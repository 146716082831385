import { IEventActionProps, mapDispatchToProps, cnClassName } from './EventAction.index'
import React, { memo, useMemo } from 'react'
import { connect } from 'react-redux';
import './EventAction.scss';
import { Button } from 'uielements/src/Button/Button'
import withLoadSpin from 'uielements/src/HOCs/Button/withLoadSpin'
import { ButtonBox, Icon } from 'uielements/src';
import { i18n, Translate } from 'localization';
import { SplitButton } from 'uielements/src/SplitButton/SplitButton'
import { stringify } from 'query-string'
import { createStringFromMask } from 'utils/src/utils.dates'
import { useLazyExecutSaga } from 'utils/src/CommonRedux/LoadIndicator'
import { participantAction } from '../redux/actions'

const LoadButton = withLoadSpin(Button)

function createDateAsUTC(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

function convertDateToUTC(date: Date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

type TGoogleDeepLinkParams = {
    text: string, // (required) – Title
    details?: string, // - Description. Basic HTML is supported.
    location?: string, // – Location.
    // dates: string, //(required) – Start and end dates / times in UTC format(YYYYMMDDThhmmssZ), separated by /.Omit the times for all - day events.All dates are in GMT by default. Omit the trailing Z to use the user's local timezone, or use the ctz parameter to specify a custom timezone.
    ctz?: string, // – Custom timezone from the tz database, for example: America / New_York
    recur?: string, // – Specify a recurring event with an RFC - 5545 RRULE string.Example: recur = RRULE: FREQ = DAILY; INTERVAL = 3. There's also an online generator to make those strings.
    crm?: string, //– Show as available / busy.Possible values are AVAILABLE, BUSY, and BLOCKING.
    add?: string, // – Semicolon - separated list of email adresses to add as event guests.If you set this parameter, it'll also add the user clicking the button as an event organiser.
}
const generateGoogleDeepLink = (startDate: Date, endDate: Date, params: TGoogleDeepLinkParams) => {
    const gmtStart = convertDateToUTC(startDate)
    const gmtEnd = convertDateToUTC(endDate)

    const paramsString = stringify({
        ...params,
        dates: `${createStringFromMask(gmtStart, "yyyyMMdd'T'HHmmss'Z'")}/${createStringFromMask(gmtEnd, "yyyyMMdd'T'HHmmss'Z'")}`,
        action: 'TEMPLATE'
    })
    return `https://calendar.google.com/calendar/render?${paramsString}`
}


type TMicrosoftDeepLinkParams = {
    subject?: string, // (required) – Title
    body?: string, // – Description of the event
    location?: string, // – Location
    // startdt?: string, // (required) – Start date/time in ISO 8601 format (YYYY-MM-DDTHH:mm:SSZ). Omit the time for all-day events. All dates are in UTC by default. Omit the trailing Z to use the user's local timezone. To specify all-day events use the YYYY-MM-DD format.
    // enddt?: string, // (required) – End date/time in ISO 8601 format (YYYY-MM-DDTHH:mm:SSZ). Omit the time for all-day events.
    allday?: boolean, // – Is this an all-day event? boolean (true/false)
    to?: string, // – Comma-separated list of emails of required attendees.
    cc?: string, // – Comma-separated list of emails of optional attendees
}

const generateMicrosoftDeepLink = (startDate: Date, endDate: Date, params: TMicrosoftDeepLinkParams) => {
    return stringify({
        ...params,
        startdt: startDate.toISOString(),
        enddt: endDate.toISOString(),
        rru: 'addevent'
    })
}

const generateOutlookDeepLink = (startDate: Date, endDate: Date, params: TMicrosoftDeepLinkParams) => {
    const paramsString = generateMicrosoftDeepLink(startDate, endDate, params)
    return `https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&${paramsString}`
}
const generate365DeepLink = (startDate: Date, endDate: Date, params: TMicrosoftDeepLinkParams) => {
    const paramsString = generateMicrosoftDeepLink(startDate, endDate, params)
    return `https://outlook.office.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&${paramsString}`
}


export const EventAction_: React.FC<IEventActionProps> = ({
    eventStatus,
    setMeeting,
    eventId,
    meeting,
    eventState = 'Completed',
    // participantAction,
    onChanged,
    eventTitle,
    duration,
    startDateTime,
    disallowParticipate = false,
    enableWaitList = false,
    eventLocation,
    eventText,
    allPlacesIsFull,
    ...props
}) => {

    const { dispatchActionParam, isLoading } = useLazyExecutSaga(() => participantAction({ status: 'going', id: eventId }), [eventId])

    const isMeeting = !!meeting
    const currentDate = new Date()
    const startDate = startDateTime ? new Date(startDateTime) : new Date(currentDate)
    const endDate = new Date(startDate)
    endDate.setMinutes(endDate.getMinutes() + (duration || 0))

    const timer = Math.abs((startDate as any) - (currentDate as any))
    const timerHours = Math.floor(timer / 1000 / 60 / 60)
    const timerMinutes = Math.floor(timer / 1000 / 60) - (timerHours * 60)

    const hoursStr = timerHours.toString().padStart(2, '0')
    const minutesStr = timerMinutes.toString().padStart(2, '0')

    const eventStarted = startDateTime && (currentDate >= startDate)
    const eventEnded = duration && (endDate < currentDate)

    const meetAllow = !eventEnded && eventStarted


    const isActiveStatus = (status: string) => status === eventStatus ? 'EventAction-Btn_active' : 'EventAction-Btn';

    const action = (status: 'going' | 'maybe' | 'no') => () => {
        onChanged && onChanged(status)
        dispatchActionParam({ payload: { status, id: eventId } });
    }

    const openMeeting = () => setMeeting({
        meeting: {
            ...meeting,
            eventTitle,
            duration,
            startDateTime
        }
    })
    const allday = 1440 === duration

    const googleLink = generateGoogleDeepLink(startDate, endDate, {
        text: (eventTitle || ''),
        location: (eventLocation || ''),
        details: (eventText || ''),
    })
    const outlookLink = generateOutlookDeepLink(startDate, endDate, {
        body: (eventText || ''),
        subject: (eventTitle || ''),
        location: (eventLocation || ''),
        allday
    })
    const link365 = generate365DeepLink(startDate, endDate, {
        body: (eventText || ''),
        subject: (eventTitle || ''),
        location: (eventLocation || ''),
        allday
    })

    const goingText = useMemo(() => {
        if (allPlacesIsFull && !enableWaitList) return <Translate i18nKey={'pryaniky.post.event.allPlacesIsFull'} />;
        if (allPlacesIsFull && enableWaitList && eventStatus!=='going'&& eventStatus!=='waitlist') return <Translate i18nKey={'pryaniky.post.event.applyToWaitList'} />;
        if (enableWaitList && eventStatus==='waitlist') return <Translate i18nKey={'pryaniky.post.event.status.waitlist'} />;
        return <Translate i18nKey={'pryaniky.filter.calendar.going'} />;
    }, [allPlacesIsFull]);

    return <div className={cnClassName({ isMeeting: !eventEnded && isMeeting }, [])} {...props}>
        <div className={cnClassName('Base')}>
            {!disallowParticipate && <>
                {eventState !== 'Completed'
                    ? <ButtonBox size={'large'}>
                        <LoadButton isLoading={isLoading} disabled={allPlacesIsFull && !enableWaitList} theme='unstyled' onClick={action('going')} className={isActiveStatus(eventStatus==='waitlist'?'waitlist':'going')}>
                            {goingText}
                        </LoadButton>
                        {!enableWaitList &&<LoadButton isLoading={isLoading} theme='unstyled' onClick={action('maybe')} className={isActiveStatus('maybe')}>
                            <Translate i18nKey={'pryaniky.filter.calendar.maybe'} />
                        </LoadButton>}
                        <LoadButton isLoading={isLoading} theme='unstyled' onClick={action('no')} className={isActiveStatus('no')}>
                            <Translate i18nKey={'pryaniky.filter.calendar.no'} />
                        </LoadButton>
                    </ButtonBox>
                    : <ButtonBox size={'large'}>
                        <Button disabled={true} theme='unstyled'>
                            <Translate i18nKey={'event_end'} />
                        </Button>
                    </ButtonBox>
                }
            </>}

            <SplitButton className={cnClassName('GetCalendarVariants')} options={[
                // {
                //     title: 'Google Calendar',
                //     buttonProps: {
                //         target: '_blank',
                //         type: 'link',
                //         href: googleLink
                //     }
                // },
                // {
                //     title: 'Outlook Live',
                //     buttonProps: {
                //         target: '_blank',
                //         type: 'link',
                //         href: outlookLink
                //     }
                // },
                // {
                //     title: 'Office 365',
                //     buttonProps: {
                //         target: '_blank',
                //         type: 'link',
                //         href: link365
                //     }
                // }
            ]} button={<Button
                href={`/ru/Data/v3/news/action/download/${eventId}.ics`}
                target={'_blank'}
                type={'link'}
                className={cnClassName('GetCalendarData')}>
                <Translate i18nKey={'pryaniky.post.event.addToMyCalendar'} />
            </Button>} />


        </div>



        {(meeting && meeting.online) && <div className={cnClassName('Meeting')}>
            {meetAllow ? <ButtonBox size={'large'}>
                <Button main type={'link'} theme='unstyled' onClick={openMeeting}>
                    <Translate i18nKey={'pryaniky.post.event.connect'} />
                </Button>
            </ButtonBox> : (eventEnded ? null : <div className={cnClassName('MeetingTimer')}>
                <div className={cnClassName('MeetingTimerLeft')}>
                    <div className={cnClassName('MeetingTimerDo')}>
                        <Translate i18nKey={'pryaniky.post.event.toStart'} />
                    </div>
                    <div className={cnClassName('MeetingTimerTime')}>{hoursStr}:{minutesStr}</div>
                </div>
                <ButtonBox size={'large'}>
                    <Button type={'link'} className={cnClassName('WaitBtn')} theme='unstyled' disabled={true}><Icon icon={'video'} /></Button>
                </ButtonBox>
            </div>)}
        </div>}
    </div>
}

export const EventAction = connect(
    null,
    mapDispatchToProps
)(memo(EventAction_));