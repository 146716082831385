/**
 * @packageDocumentation
 * @module Widget_Settings_shop
 */
import { JFdictionary } from "../dictionary";
import { i18n, Translate } from 'localization';

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type. shop.miniview'),
      scope: "#/properties/mini"
    },
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
      scope: "#/properties/hideIfNoData"
    },
    // {
    //   type: renderers.usersInput,
    //   scope: "#/properties/selectedUsers"
    // }
  ]
}