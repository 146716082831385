import React, { FC, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    LerningTrack
} from './interfaces'
import { connect, useSelector } from 'react-redux';
import './style.scss'
import { Button } from 'uielements/src';
// import { HelpTooltip } from 'blocks/HelpTooltip/HelpTooltip'
import Item from './Item'
// import Item from './Item'
import ListPlaceholder from '../../components/ListPlaceholder'
import withLoadSpin from 'blocks/HOCs/Button/withLoadSpin'
import { NEW__useInfiniteScroll as useInfiniteScroll } from 'utils/src/hooks';
import { tTr } from 'LMSModule/utils/i18n.adaptor'
import { TracksHeader } from 'LMSModule/components/TracksHeader/TracksHeader'
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'

type IPresenterProps = LerningTrack.TrackList.IPresenterProps
type IOwnProps = LerningTrack.TrackList.IOwnProps
type IDispatchProps = LerningTrack.TrackList.IDispatchProps
type IStateProps = LerningTrack.TrackList.IStateProps
type IState = LerningTrack.TrackList.IState

const LoadButton = withLoadSpin(Button)

export const Presenter: FC<IPresenterProps> = ({
    className,
    loadTracksList,
    count,
    data,
    isFinished,
    isLoading,
    keys
}) => {
    const currentUser = useSelector(getCurrentUser)
    useEffect(() => {
        loadTracksList()
    }, [])
    const loadMore = () => loadTracksList({ count, skipCount: keys.length })
    const triggerRef = useInfiniteScroll({
        loadMore,
        isLoading,
        isFinished,
    });
    return <div className={cnClassName({ }, ['Page', className])}>
        <TracksHeader className={cnClassName('Header')} currentUrl={'LearningTracks'} showSwitch={currentUser.baseData.isAdmin} />
        {/* <div className={cnClassName('Aside')}>
            aside
        </div> */}

        <div className={cnClassName('Section')}>
            <div className={cnClassName('SearchBox')}>
                {/* <Search search={''} /> */}
            </div>

            <div className={cnClassName('List')}>
                <ListPlaceholder
                    className={cnClassName('Placeholder')}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={keys.length}
                    titleEmpty={tTr('track_list.empty.title')}
                    textEmpty={tTr('track_list.empty.text')}
                    textLoad={tTr('track_list.load.text')}
                >
                    {keys.map(id => <Item data={data[id]} />)}
                    {!isFinished && (
                        <LoadButton
                            forwardedRef={triggerRef}
                            isLoading={isLoading}
                            className={cnClassName('Trigger')}
                            onClick={isLoading ? void 0 : loadMore}>
                            {tTr('track_list.more.text')}
                        </LoadButton>
                    )}
                </ListPlaceholder>
            </div>
        </div>
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
