import * as React from 'react';
import { cnCode, ICodeProps, ICodeState, ICodeOwnProps, IActionsCode, mapActionsToProps, mapStateToProps, ICodeStateProps } from "./Code.index";
// import MonacoEditor, { ChangeHandler, EditorDidMount } from 'react-monaco-editor';
import { i18n, Translate } from 'localization';
// import { editor } from 'monaco-editor';
import { Suspense } from 'react'
import './Code.scss';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from 'redux/connector';
import { IStateType as IState } from 'redux/store';
import actions from 'utils/src/CommonRedux/base/actionsTypes';
import { Button } from 'uielements/src';
// import { isIE } from '../../polyfills';

// import * as monaco from 'monaco-editor';

/**
 * TODO: разобраться с lazy импортом типов
 */
/**
 * lazy для не реакт модулей
 */
// import(/* webpackChunkName: "monaco-editor" */ 'monaco-editor').then((monaco) => {
//   console.log(monaco)
//   return monaco;
// }).catch(error => 'An error occurred while loading the component');
/****************/

const MonacoEditor = React.lazy(() => import('react-monaco-editor'));


const PROPS_TO_WIDGET = ['data-id', 'context', 'relations'];

class CodeJSPresenter extends React.Component<ICodeProps, ICodeState> {
  private ref = React.createRef<HTMLDivElement>();
  // private editor: monaco.editor.IStandaloneCodeEditor;

  constructor(props: ICodeProps) {
    super(props);
    this.state = {
      code: ''
    }
  }

  public componentDidMount() {
    // if (this.ref.current) this.editor = monaco.editor.create(this.ref.current, {
    //   value: [
    //     'function x() {',
    //     '\tconsole.log("Hello world!");',
    //     '}'
    //   ].join('\n'),
    //   language: 'javascript'
    // });
  }

  public render() {
    const { tag: Tag = 'div' } = this.props;
    const props2 = Object.keys(this.props).reduce((acc, cur) => (PROPS_TO_WIDGET.includes(cur) ? { ...acc, [cur]: (this.props as any)[cur] } : acc), {});
    return (
      <Tag {...props2} className={cnCode()}>
        <div ref={this.ref} style={{ height: 600 }} />
      </Tag>
    )
  }
}

// const editor: any;
class CodePresenter extends React.Component<ICodeProps, ICodeState> {
  private language: string | 'html' | 'css' | 'javascript' | 'typescript' = 'javascript';
  private options: any/*editor.IEditorConstructionOptions*/ = {
    selectOnLineNumbers: true,
    automaticLayout: true
  };
  private theme: string | 'vs' | 'vs-dark' | 'hc-dark' = 'vs-dark';

  constructor(props: ICodeProps) {
    super(props);

    // import(/* webpackChunkName: "monaco-editor" */ 'monaco-editor').then((monaco) => {
    //   console.log(monaco)
    //   return monaco;

    // }).catch(error => 'An error occurred while loading the component');

    this.state = {
      code: this.props.data || ``,
    }
    if (props.language) this.language = props.language;
    if (props.theme) this.theme = props.theme;
  }

  private editorDidMount: /*EditorDidMount*/any = (editor: any, monaco: any) => this.addClassNameToEditor(editor);

  private addClassNameToEditor = (editor: any) => {
    let editorNode;
    if (editor) editorNode = editor.getDomNode();
    if (editorNode && this.props.rounded) {
      editorNode.classList.add('rounded');
      Array.from(editorNode.children).forEach((el: any) => el.classList.add('rounded'));
    }
  }

  public render() {
    const { state, onChange, language, options, theme, props } = this;
    const { code } = state;
    const { tag: Tag = 'div', value, variable } = props;
    const props2 = Object.keys(this.props).reduce((acc, cur) => (PROPS_TO_WIDGET.includes(cur) ? { ...acc, [cur]: (this.props as any)[cur] } : acc), {});
    // return null;
    return (
      <Tag {...props2} className={cnCode()}>
        <h4 className={cnCode('Header')} children={i18n.t(`write ${language} code to this area`)} />
        <Button onClick={this.props.saveDesign} children={'save'} />
        <Suspense fallback={<div>Загрузка...</div>}>
          <MonacoEditor
            height="600"
            language={language}
            theme={theme}
            value={variable ? value : code}
            options={options}
            onChange={onChange}
            editorDidMount={this.editorDidMount}
          />
        </Suspense>
      </Tag>
    )
  }

  private onChange: /*ChangeHandler*/any = (code: any, e: any) => {
    if (this.props.variable) {
      this.props.dispatch({ type: actions.CHANGE_DESIGN, payload: { variable: this.props.variable, value: code } })
    } else {
      this.setState({ code });
    }
  };
}

export const Code = connect<ICodeStateProps, IDispatchProps, ICodeOwnProps, IState>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
)(CodePresenter);