import React, { FC, useState } from 'react';
import { NAdventCalendarReducer } from '../redux/types';
import { createModal, TransitionComponent, IBodyProps } from 'utils/src/DialogCreator';
import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { styled } from 'muicomponents/src/mui/system';
import { i18n, Translate } from 'localization';
import { cn } from '@bem-react/classname';
import { getBaseCalendar } from '../redux/constants';
import { AdventCalendar } from 'utils/src/BaseTypes/adventCalendar.types';
import { TextField } from 'muicomponents/src/TextField';
import { DatePicker } from 'muicomponents/src/DatePicker';
import { validate_v2, ValidateRulesObject, ValidateResponse } from 'utils/src/validate_v2';
import { PrepareErrorText } from 'News/utils/utils.validate';
import { pick, toPairs } from 'lodash';
import moment from 'moment';

const AdventCalendarValidateRules: ValidateRulesObject<{
    dateFrom: Date,
    dateTo: Date
}> = {
    dateFrom: {
        valid: {
            value: true
        },
        notNull: {
            value: true
        }
    },
    dateTo: {
        valid: {
            value: true
        },
        notNull: {
            value: true
        },
    }
};

const cnCalendarSettings = cn('CalendarSettings');

type CalendarSettingsRezult = {
    editedCalendar: AdventCalendar;
};

type CalendarSettingsProps = IBodyProps<CalendarSettingsRezult> & {
    calendar: AdventCalendar | undefined
};

const StyledDialogContent = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    gap: 16
});

export const CalendarSettingsPr: FC<CalendarSettingsProps> = ({
    calendar,
    handleAccept,
    handleClose
}) => {

    const [ state, setState ] = useState(calendar || getBaseCalendar());

    const [ errors, setErrors ] = useState<ValidateResponse<NonNullable<CalendarSettingsProps['calendar']>>>({});

    return (
        <div className={cnCalendarSettings()}>
            <DialogTitle
                onClose={handleClose}
                sx={{
                    width: '100%'
                }}
            >
                <Translate i18nKey={'pryaniky.adventcalendar.calendar.settings.title'} />
            </DialogTitle>
            <StyledDialogContent className={cnCalendarSettings('Content')}>
                <TextField
                    label={<Translate i18nKey='pryaniky.adventcalendar.calendar.name' />}
                    value={state.name}
                    onChange={(e) => {
                        setState({
                            ...state,
                            name: e.target.value
                        });
                    }}
                />
                <DatePicker
                    type={'date'}
                    label={<Translate i18nKey='pryaniky.adventcalendar.calendar.dateFrom' />}
                    value={state.dateFrom || null}
                    onChange={(iso, date) => {
                        setState({
                            ...state,
                            dateFrom: iso || ''
                        });
                        setErrors({
                            ...errors,
                            ...validate_v2({
                                dateFrom: date || new Date('s')
                            }, pick(AdventCalendarValidateRules, 'dateFrom'))
                        });
                    }}
                    TextFieldProps={{
                        error: Boolean(errors.dateFrom),
                        helperText: Boolean(errors.dateFrom) && PrepareErrorText(errors.dateFrom)
                    }}
                    
                />
                <DatePicker
                    type={'date'}
                    label={<Translate i18nKey='pryaniky.adventcalendar.calendar.dateTo' />}
                    value={state.dateTo || null}
                    onChange={(iso, date) => {
                        setState({
                            ...state,
                            dateTo: iso || ''
                        });
                        setErrors({
                            ...errors,
                            ...validate_v2({ dateTo: date || new Date('s') }, pick(AdventCalendarValidateRules, 'dateTo'))
                        });
                    }}
                    disabled={!state.dateFrom}
                    minDate={state.dateFrom ? moment(state.dateFrom) : undefined}
                    TextFieldProps={{
                        error: Boolean(errors.dateTo),
                        helperText: Boolean(errors.dateTo) && PrepareErrorText(errors.dateTo)
                    }}
                />

            </StyledDialogContent>
            <DialogActions
                className={cnCalendarSettings('Actions')}
                onAccept={() => {
                    const errors = validate_v2(
                        {
                            dateFrom: !state.dateFrom ? null : new Date(state.dateFrom),
                            dateTo: !state.dateTo ? null : new Date(state.dateTo)
                        },
                        AdventCalendarValidateRules
                    );
                    if(Boolean(Object.typedKeys(errors).filter(key => errors[key]).length)) {
                        setErrors(errors);
                    } else {
                        handleAccept({ 
                            editedCalendar: state
                        });
                    }
                }}
                disableAccept={Boolean(Object.typedKeys(errors).filter(key => errors[key]).length)}
                acceptText={<Translate i18nKey={`save`} />}
                onClose={handleClose}
                closeText={<Translate i18nKey={`cancel`} />}
            >
            </DialogActions>

            </div>
    );
};

export const CalendarSettings = createModal(CalendarSettingsPr, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: 'transparent',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body'
});