import React, { FC, useEffect, useState, useRef } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IPresenterProps,
    IOwnProps,
    IDispatchProps,
    IStateProps,
    IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import uuid from 'uuid';
import * as utils from 'utils/src/utils';
import { Button } from 'uielements/src'
import { AttachmentUploader } from 'uielements/src/AttachmentUploader/AttachmentUploader'
import { Attachments } from 'uielements/src/Attachments/Attachments'
import { i18n, Translate } from 'localization';

export const Presenter: FC<IPresenterProps> = ({
    className,
    files,
    onChange,
    name
}) => {
    const containerRef = useRef(null);
    const loader = useRef<any>(null);
    const onDelete = (elem: any) => {
        const withOutCurrent = files.filter((f: any) => f.id !== elem.id)
        onChange(withOutCurrent)
    }
    const openLoader = () => {
        loader.current && loader.current.open()
    }
    return <div ref={containerRef} className={cnClassName({}, [className])}>
        <div className={cnClassName('Header')}>
            <h4 className={cnClassName('Title')}>{name}</h4>
            <Button className={cnClassName('UploadBtn')} main onClick={openLoader}>
                <Translate i18nKey={'pryaniky.user.about.upload.UploadBtn'} />
            </Button>
        </div>
        {files.length === 0 && <div className={cnClassName('Noop')} onClick={openLoader}>
            <Translate i18nKey={'pryaniky.user.about.upload.Noop'} />
        </div>}
        <Attachments className={cnClassName('Attachments')} files={files} onDelete={onDelete} />
        {containerRef.current && <AttachmentUploader
            // className={cnClassName('Uploader')}
            elem={containerRef.current}
            attachments={files}
            reference={(e: any) => loader.current = e}
            onChange={onChange} />}
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(Presenter);
