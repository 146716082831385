import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
import { TextareaMentionsEditor as TextareaMentions } from '../../../TextareaMentions/TextareaMentions'
import { i18n, Translate } from 'localization';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'

export const Editor: React.FC<IEditorProps> = ({ children, data, onChangeText }) => {

    return <div className={'Editor'}>
        <PryanikyEditor
            // getClassRef={refGetter}
            //ref={forwardRef as any}
            // users={true}
            // tags={true}
            onChange={onChangeText}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.competition.endText')} />
        {/*<TextareaMentions
            // getClassRef={this.getTextareaMentions}
            users={true}
            tags={true}
            onChange={onChangeText}
            className={'NewsEdit-Body_type_competitions-Input-Text'}
            icon={'edit'}
            value={data.text}
        placeholder={i18n.t('pryaniky.createPost.competition.endText')} />*/}
        {children}
    </div>
}
