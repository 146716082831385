import { INewAnswerFormProps } from './NewAnswerForm.index'
import React, { memo, useCallback } from 'react'
import './NewAnswerForm.scss';
import { Answer } from '../Polls.types'
import { Button } from 'uielements/src/Button/Button'
import { Icon } from 'uielements/src/Icon/Icon'
import uuid from 'uuid/v1';

export const NewAnswerForm: React.FC<INewAnswerFormProps> = memo(({ onCancle, onAccepted }) => {
    const [value, setValue] = React.useState('');
    const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (value === '') return onCancle();

        const newAnswer: Answer = {
            answeredByMe: false,
            count: 0,
            participants: [],
            text: value,
            position: 0,
            id: uuid()
        };
        onAccepted(newAnswer)
        onCancle()
    }, [value, onCancle, onAccepted])

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value), [])


    return <form className="NewAnswerForm" onSubmit={onSubmit}>
        <Button tag='a' onClick={onCancle} theme="unstyled" className="NewAnswerForm-AnswerTimes">
            <Icon icon='times' />
        </Button>

        <input type="text" className="NewAnswerForm-AnswerAddInput" value={value} onChange={onChange} />
        <Button theme='unstyled' className="NewAnswerForm-AnswerSubmit">
            <Icon icon='location-arrow' />
        </Button>
    </form>
})