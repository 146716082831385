/**
 * @packageDocumentation
 * @module Widget_Settings_info
 */
import { JFdictionary } from "../dictionary";
import { i18n, Translate } from 'localization';

const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    okText: {
      type: types.string,
      placeholder: i18n.t('pryaniky.widgets.settings.license.okTextPlaceholder')
    },
    isRequired: {
      type: types.boolean
    },
    mobileLicense: {
      type: types.boolean
    }
  }
}
