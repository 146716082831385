/**
 * @packageDocumentation
 * @module Widget_Settings_hierarchy
 */
import { JFdictionary } from '../dictionary';
import { i18n, Translate } from 'localization';

const { renderers } = JFdictionary;

export default {
    type: renderers.layout,
    elements: [
        {
            type: renderers.select,
            scope: '#/properties/hierarchy',
        },
        {
            type: renderers.layout,
            direction: 'horizontal',
            elements: [
                {
                    type: renderers.radio,
                    label: 'Руководители',
                    name: 'headusers',
                    scope: '#/properties/headusers',
                },
                {
                    type: renderers.radio,
                    label: 'Подчиненные',
                    name: 'subusers',
                    scope: '#/properties/subusers',
                },
            ],
        },
        {
            type: renderers.checkbox,
            label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
            scope: '#/properties/hideIfNoData',
        },
        {
            type: renderers.checkbox,
            label: i18n.t('pryaniky.widgets.settings.type.hierarchy.list.view'),
            scope: '#/properties/hierarcheListView',
        },
        {
            type: renderers.number,
            scope: '#/properties/itemCountView',
            rule: {
                effect: 'SHOW',
                condition: {
                    type: 'LEAF',
                    scope: '#/properties/hierarcheListView',
                    expectedValue: true,
                },
            },
        },
    ],
};
