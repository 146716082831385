import { i18n, Translate } from 'localization';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SearchInput, Loading } from 'uielements';
import RadioMUI from 'uielements/src/MaterialElements/Radio';
import { checkResponseStatus } from 'utils/src';
import { getBadgesList } from 'utils/src/requests/requests.badges';
import { NBadgesList, cnBadgesList } from './Badges.index';
import './Badges.scss';
import { BadgesListItem } from './Item/BadgesItem';

const allCategoryId = 'all';

const getI18nKey = (key: string) => `pryaniky.badges.list.${key}`;

const BadgesPr: FC<NBadgesList.Props> = ({
    filter
}) => {

    const [ loading, setLoading ] = useState(true);
    const [ list, setList ] = useState<NBadgesList.State['list']>([]);
    const [ categories, setCategories ] = useState<Omit<NBadgesList.State['list'][number], 'items'>[]>([]);
    const [ selectedCategory, setSelectedCategory ] = useState<typeof categories[number]['id']>(allCategoryId);
    const [ search, setSearch ] = useState('');

    const selectCategory = (id: typeof selectedCategory) => setSelectedCategory(id);

    useEffect(() => {
        getBadgesList(search, { filter }).then(d => {
            if(checkResponseStatus(d)) {
                const data = d.data.map(e => {
                    return {
                        ...e,
                        displayName: e.displayName || ''
                    };
                });
                setCategories(data.map(e => ({
                    id: e.id,
                    displayName: e.displayName
                })));
                setList(data);
            } else {
                toast.error(<Translate i18nKey={getI18nKey('error.data')} />);
            }
            setLoading(false);
        }).catch(e => {
            toast.error(<Translate i18nKey={getI18nKey('error.response')} />);
            setLoading(false);
        });
    }, [filter]);

    return (
        <div className={cnBadgesList()}>
            <div className={cnBadgesList('Header', [ 'Widget' ] )}>
                <div>
                    <Translate i18nKey={`placeholder:${getI18nKey('search')}`}>
                        <SearchInput value={search} onChange={(value) => setSearch(value)} />
                    </Translate>
                </div>
                {
                    !!categories.length && categories.length > 1 &&
                    <div className={cnBadgesList('Filters')}>
                        <h4>
                            <Translate i18nKey={getI18nKey('categories')} /> :
                        </h4>
                        <Translate i18nKey={`label:${getI18nKey(`categories.${allCategoryId}`)}`}>
                            <RadioMUI className={cnBadgesList('Radio')} size='small' checked={selectedCategory === allCategoryId} onChange={() => selectCategory(allCategoryId)} label={''} />
                        </Translate>
                        {
                            categories.filter(e => e.displayName).map(category => (
                                <RadioMUI className={cnBadgesList('Radio')} size='small' checked={selectedCategory === category.id} onChange={() => selectCategory(category.id)} label={category.displayName} />
                            ))
                        }
                    </div>
                }
            </div>
            <div className={cnBadgesList('List')}>
                {
                    loading
                    ?
                    <div className={cnBadgesList('Loading', ['Widget'])}>
                        <Loading />
                    </div>
                    :
                    list
                    .filter(el => el.id === selectedCategory || selectedCategory === allCategoryId)
                    .map(({ displayName, items }) => {
                        const filteredItems = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
                        if(!filteredItems.length) return null;
                        return (
                            <>
                                {
                                    displayName &&
                                    <div className={cnBadgesList('Category')}>
                                        {displayName}
                                    </div>
                                }
                                <div className={cnBadgesList('Badges')}>
                                    {
                                        filteredItems.map(badge => <BadgesListItem data={badge} />)
                                    }
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const Badges = BadgesPr;