import React from 'react'
import { connect, useSelector } from 'react-redux'
import {
    ICustomNodeProps,
    mapCustomNodeDispatchToProps,
    mapCustomNodeStateToProps,
    cnCustomNode
} from './CustomNode.index';
import { UserList } from 'uielements/src/UserList/UserList';
import './CustomNode.scss'
import { Button, Icon } from 'uielements/src';
import { PopupMenu } from 'uielements/src/PopupMenu/PopupMenu';
import { withGroupEditTrue } from 'blocks/InfoBlock/Group/_edit/InfoBlock-Group_edit_true';
import { UnitEdit } from '../UnitEdit/UnitEdit'
import { CostomNodePosition } from './CostomNodePosition/CostomNodePosition'
import * as utils from 'utils.project/utils.project'
import { i18n, Translate } from 'localization';
import { Sortable } from 'uielements/src/Sortable/Sortable'
import { editUnitModal } from '../UnitEdit/UnitEdit'
import { newUnit } from 'redux/sagas/orgchart/reducer'
import { v1 as uuid } from 'uuid'
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { forLibPrefix } from 'redux/sagas/orgchart/constants';
import { dragNodeService } from '@dabeng/react-orgchart'
import { debounce } from 'lodash'
import { getCurrentUserIsAdmin } from 'utils/src/CommonRedux/base/selectors'
import { HelpTooltip } from 'uielements/src/index';
import DropdownWithText from 'uielements/src/MaterialElements/DropdownWithText/DropdownWithText';



// const actions = ["AddHead", "AddSub", "AddHeadVacancy", "AddSubVacancy", "AddSubUnit", "deleteUnit", "edit"]

// для того что бы не пушить дополнительные события при перетаскивании юнита
let lastXPosition: number | null = null;
let lastPosition: number | null = null;




export const setOverFlag = (dt: boolean) => {
    // overFlag = dt;
}

const CustomNodePresenter: React.FC<ICustomNodeProps> = ({
    data,
    nodeData,
    draggingUnit,
    editable,
    positions,
    filterShowOnlyOpenVacancy,
    deleteStructureDataOrgchart,
    orgchartDragPosition,
    saveStructureDataOrgchart,
    addSubUnit,
    orgchartSaveUsersInUnitAfterDrag,
    orgchartSaveAfterUnitPositionDrag,
    setOrgchartDraggingUnit,
    setOrgchartDraggingUnitToPosition,
    editSubUnit
}) => {
    const isAdmin = useSelector(getCurrentUserIsAdmin)
    const [showDialog, setShowDialog] = React.useState(false);
    const [addNewUnit, setAddNewUnit] = React.useState({ ...newUnit, id: uuid(), parent: data.id })
    const [action, setAction] = React.useState("")

    const [cut, setCut] = React.useState(7);
    let actions = [
        { title: i18n.t('pryaniky.orgchart.service.actions.AddHead'), value: 'AddHead', },
        { title: i18n.t('pryaniky.orgchart.service.actions.AddSub'), value: 'AddSub', },
        { title: i18n.t('pryaniky.orgchart.service.actions.AddHeadVacancy'), value: 'AddHeadVacancy', },
        { title: i18n.t('pryaniky.orgchart.service.actions.AddSubVacancy'), value: 'AddSubVacancy', },
        { title: i18n.t('pryaniky.orgchart.service.actions.AddSubUnit'), value: 'AddSubUnit', },
        { title: i18n.t('pryaniky.orgchart.service.actions.deleteUnit'), value: 'deleteUnit', },
        { title: i18n.t('pryaniky.orgchart.service.actions.edit'), value: 'edit', },
    ]

    // const actions = ["AddHead", "AddSub", "AddHeadVacancy", "AddSubVacancy", "AddSubUnit", "deleteUnit", "edit"]
    const clickAction = (action: string) => {
        if (action === 'edit') {
            editSubUnit({ id: data.id })
            editUnitModal({
                parentUnitId: data.parent,
                editUnit: true,
                addNewUnitFromHeader: true
            }).then((value: boolean) => {

                saveStructureDataOrgchart({ data, thisIsMerge: value })
            })
        }
        if (action === "deleteUnit") {
            utils.confirm({
                text: i18n.t('pryaniky.orgchart.confirm.delete.title.name'),
                onConfirm: () =>
                    deleteStructureDataOrgchart(data.id)
            });

        }
        if (action === "AddHead") {
            editSubUnit({ id: data.id })
            editUnitModal({
                addHeadOpen: true,
                parentUnitId: data.parent,
            }).then(() => {
                saveStructureDataOrgchart({ data, thisIsMerge: false })
            })
        }
        if (action === "AddSub") {
            editSubUnit({ id: data.id })
            editUnitModal({
                addSubOpen: true,
                parentUnitId: data.parent,
            }).then(() => {
                saveStructureDataOrgchart({ data, thisIsMerge: false })
            })
        }
        if (action === "AddHeadVacancy") {
            editSubUnit({ id: data.id })
            editUnitModal({
                addHeadOpen: true,
                addVacancy: true,
                parentUnitId: data.parent,
            }).then(() => {
                saveStructureDataOrgchart({ data, thisIsMerge: false })
            })
        }
        if (action === "AddSubVacancy") {
            editSubUnit({ id: data.id })
            editUnitModal({
                addSubOpen: true,
                addVacancy: true,
                parentUnitId: data.parent,
            }).then(() => {
                saveStructureDataOrgchart({ data, thisIsMerge: false })
            })
        }
        if (action === "AddSubUnit") {
            addSubUnit({ parent: data.id })

            editUnitModal({
                addNewUnit: true,
            }).then(() => {
                saveStructureDataOrgchart({ data, thisIsMerge: false })
            })
        }
        else {
            console.log(action);
        }

    }

    const closeDialog = () => setShowDialog(false);

    const cleardrop = () => {
        const els = Array.from(document.getElementsByClassName('oc-node'));
        els.forEach(el => {
            el.classList.remove('allowedDrop');
        });
    }

    // при старте драга у позишнов
    const denyDragNodeOnDragElInNode = () => {
        cleardrop();
    }

    // дропалка для позишнов
    const onDropEl: (type: keyof Pick<typeof data, 'positionIds' | 'rootPositionIds'>) => React.ComponentProps<typeof Sortable>['onDrop'] = (type) => (positions, positionData) => {
        orgchartDragPosition({
            id: data.id,
            [type]: positions.map(el => el.id),
            positionData
        })
        orgchartSaveUsersInUnitAfterDrag({ toRootPosition: type === 'rootPositionIds' ? true : false, positionId: positionData.id })
    }

    // дропалка для юнитов
    const onDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
        if (!draggingUnit || !draggingUnit.parent) return;
        e.stopPropagation(); // сделано специально для того чтобы так или иначе не срабатывала логика сброса у либы
        const { id } = JSON.parse(e.dataTransfer.getData('text/plain'));
        const transferedId = id.replace(forLibPrefix, '');
        const toId = forLibPrefix + data.id;
        const element = document.getElementById(toId);
        if (!element || !element.classList.contains('allowedDrop')) return;
        orgchartSaveAfterUnitPositionDrag({ id: transferedId, parent: data.id, position: nodeData.position });
        /**
         * transferedId - id элмента который перетаскиваем
         * data.id - id элмента под который перетаскиваем
         */
    }

    const onDragStart: React.DragEventHandler<HTMLDivElement> = (e) => {
        const target = e.target as HTMLElement;
        if (!target.closest('.Sortable')) {
            // e.stopPropagation();
            setOrgchartDraggingUnit({ id: data.id, parent: data.parent });
            // // пока используем логику библиотеки для этого
            // const els = Array.from(document.getElementsByClassName('poc-node'));
            // els.forEach(el => {
            //     if (!el.closest(`#${forLibPrefix}${data.id}`)) el.classList.add('allowDrop');
            // });
        }
    }

    const onDragEnd: React.DragEventHandler<HTMLDivElement> = (e) => {
        if (!draggingUnit || !draggingUnit.parent) return;
        // e.stopPropagation();
        setOrgchartDraggingUnit(null);
        // пока используем логику библиотеки для этого
        // const els = Array.from(document.getElementsByClassName('poc-node'));
        // els.forEach(el => {
        //     el.classList.remove('allowDrop');
        // });
    }

    const onDragEnter: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (!draggingUnit || !draggingUnit.parent || draggingUnit.id === data.id) return;
    }

    const onDragLeave: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (!draggingUnit || !draggingUnit.parent || draggingUnit.id === data.id) return;
        lastXPosition = null;
        lastPosition = null;
    }

    const onDragOver: React.MouseEventHandler<HTMLDivElement> = (e) => {
        // not start function if x position not changed or draggingUnit, draggingUnit.parent is undefined or unit dragger to himself
        if (!draggingUnit || !draggingUnit.parent || draggingUnit.id === data.id || lastXPosition === e.screenX) return;
        lastXPosition = e.screenX;
        const toId = forLibPrefix + data.id;
        const element = document.getElementById(toId);
        const orgchart = document.getElementsByClassName('OrcChart')[0] as HTMLDivElement;
        const currentZoom = orgchart ? Number((orgchart.style as CSSStyleDeclaration & { zoom: string }).zoom || 1) : 1;
        if (!element || !element.classList.contains('allowedDrop')) return;
        const { clientX: mouseX } = e;
        let { x: cTargetX, width: cTargetWidth } = e.currentTarget.getBoundingClientRect();
        cTargetX = cTargetX * currentZoom;
        cTargetWidth = cTargetWidth * currentZoom;
        let step = 0;
        const childrenLength = nodeData.children?.map((e: any) => e.id.replace(forLibPrefix, '')).filter((e: any) => e !== draggingUnit.id).length;
        if (childrenLength) {
            let len = childrenLength + 1;
            // if (data.parent === draggingUnit?.parent) len = childrenLength;
            step = cTargetWidth / len;
        }
        var position: number | undefined;
        position = Math.floor((mouseX - cTargetX) / step);
        // not pushing change structure if position not changed
        if (lastPosition === position) return;
        lastPosition = position;
        setOrgchartDraggingUnitToPosition({
            fromId: draggingUnit.parent,
            id: draggingUnit.id,
            position,
            toId: data.id
        });
    }

    const [itemDraggable, setItemDraggeble] = React.useState(editable);

    React.useEffect(() => {
        setItemDraggeble(editable);
    }, [editable]);



    const vacancyPositionsInUnitLength = Object.values(positions).filter(item => data.positionIds.includes(item.id)).filter(item => item.isVacancyOpen).length;
    const vacancyRootPositionsInUnitLength = Object.values(positions).filter(item => data.rootPositionIds.includes(item.id)).filter(item => item.isVacancyOpen).length;

    const onlyOpenVacancies = filterShowOnlyOpenVacancy ? vacancyRootPositionsInUnitLength === 0 && vacancyPositionsInUnitLength === 0 : data.rootPositionIds.length === 0 && data.positionIds.length === 0;
    const onlyOpenVacanciesshowContent = filterShowOnlyOpenVacancy ? vacancyRootPositionsInUnitLength > 0 || vacancyPositionsInUnitLength > 0 : data.rootPositionIds.length > 0 || data.positionIds.length > 0;

    return (
        <div className={cnCustomNode({ dragging: draggingUnit?.id === data.id }, ['poc-node'])}
            onDrop={onDrop}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            draggable={itemDraggable}
        >
            {
                draggingUnit &&
                // добавлен для того что бы избежать дополнительных событий для onDragEnter и onDragLeave
                <div
                    className={cnCustomNode('Dragger')}
                    onDragLeave={onDragLeave}
                    onDragEnter={onDragEnter}
                    onDragOver={onDragOver}
                />
            }
            <div className={cnCustomNode(onlyOpenVacancies ? "HeaderWithoutUsers" : "Header")}>
                <div className={cnCustomNode("Header-Title")}> {data.displayName}</div>
                {isAdmin && <div className={cnCustomNode("Header-Title-Actions")}>
                    <DropdownWithText
                        align-items={"flex-start"}
                        options={actions}
                        onClick={(action) => {
                            clickAction(action.value)
                        }}

                    >
                        <Icon icon={'cogs'} className={cnCustomNode('Icon')} />
                    </DropdownWithText>
                </div>}
            </div>
            {
                // data.rootPositionIds.length > 0 && data.positionIds.length > 0 сделано на классах специально для утравления по DOM
                <div className={cnCustomNode("Content", { show: onlyOpenVacanciesshowContent })}>
                    {
                        // data.rootPositionIds.length > 0 сделано на классах специально для утравления по DOM
                        <div className={cnCustomNode("Heads", { show: filterShowOnlyOpenVacancy ? vacancyRootPositionsInUnitLength > 0 : data.rootPositionIds.length > 0 })}>
                            <span className={"Span"}> <Translate i18nKey={`pryaniky.orgchart.heads`} />:</span>
                            {
                                data.rootPositionIds &&
                                <Sortable
                                    readonly={!editable}
                                    onMouseOver={() => {
                                        editable && setItemDraggeble(false);
                                    }}
                                    onMouseOut={() => {
                                        setItemDraggeble(editable);
                                    }}
                                    onDragStart={() => {
                                        denyDragNodeOnDragElInNode();
                                    }}
                                    items={data.rootPositionIds.map((el: string) => ({ id: el, title: "" }))}
                                    CustomNode={(el) => <CostomNodePosition unitId={data.id} getLast={el.last} howManyLeft={data.rootPositionIds.length - cut} showMore={(el) => setCut(el)} id={el.item.id} />}
                                    onDrop={onDropEl('rootPositionIds')}
                                    cutLength={cut}
                                    horizontal
                                    collection={"positions"}
                                >

                                </Sortable>
                            }

                        </div>
                    }

                    {
                        // data.positionIds.length > 0 сделано на классах специально для утравления по DOM
                        <div className={cnCustomNode("SubUsers", { show: filterShowOnlyOpenVacancy ? vacancyPositionsInUnitLength > 0 : data.positionIds.length > 0 })}>
                            <span className={"Span"}><Translate i18nKey={`pryaniky.orgchart.subs`} />:</span>
                            {
                                data.positionIds &&
                                <Sortable
                                    readonly={!editable}
                                    onMouseOver={() => {
                                        editable && setItemDraggeble(false);
                                    }}
                                    onMouseOut={() => {
                                        setItemDraggeble(editable);
                                    }}
                                    onDragStart={() => {
                                        denyDragNodeOnDragElInNode()
                                    }}
                                    items={data.positionIds.map((el: string) => ({ id: el, title: "" }))}
                                    CustomNode={(el) => <CostomNodePosition unitId={data.id} getLast={el.last} howManyLeft={data.positionIds.length - cut} showMore={(el) => setCut(el)} id={el.item.id} />}
                                    onDrop={onDropEl('positionIds')}
                                    horizontal
                                    cutLength={cut}
                                    collection={"positions"}
                                >
                                    {/* {data.postionIds.map((el) => <CostomNodePosition id={el} key={el} />)} */}
                                </Sortable>
                            }

                        </div>
                    }
                </div>
            }
            {/* {showDialog === true ? <UnitEdit isShown={showDialog} onClose={closeDialog} id={data.id} /> : undefined} */}
            {/* {addNewUnit.show ? <UnitEdit isShown={showDialog} newUnitId={addNewUnit.id} onClose={closeDialog} id={data.id} /> : undefined} */}
        </div>
    )
}

export const CustomNode = connect(
    mapCustomNodeStateToProps,
    mapCustomNodeDispatchToProps
)(CustomNodePresenter)