import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
// import * as utils from 'utils/src/utils';

import { cnPreview, IPreviewProps, IPreviewState } from './ImgLoader-Preview.index';
import { Icon } from 'uielements/src/Icon/Icon';

import './ImgLoader-Preview.scss';

export class PreviewPresenter extends React.Component<IPreviewProps, IPreviewState> {
  // public utils: any = utils;
  public el: HTMLElement | null;

  constructor(props: IPreviewProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    // const {} = this.props;
    return (
      <TagName ref={el => this.el = el} className={cnPreview({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
      {
        !this.props.imgUrl 
        ?
        <Icon icon={'image'} className={cnPreview('Icon')} />
        :
        <img className={cnPreview('Img')} src={this.props.imgUrl} alt='img' />
      }
        {this.props.children}
      </TagName>
    )
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const Preview = PreviewPresenter;
  //   compose(
  //     withBemMod(cnPreview(), {})
  //     )(
  //       PreviewPresenter
  //   )
  // )
