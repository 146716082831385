import React, { FC } from 'react'
import { 
    LMSTO,
    mapDispatchToProps,
    mapStateToProps,
    cnClassName 
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import { Selectize } from 'uielements/src';
import { tT, Tt } from 'LMSModule/utils/i18n.adaptor'

type IPresenterProps = LMSTO.CommentsLent.UnitsFilter.IPresenterProps
type IStateProps = LMSTO.CommentsLent.UnitsFilter.IStateProps
type IDispatchProps = LMSTO.CommentsLent.UnitsFilter.IDispatchProps
type IOwnProps = LMSTO.CommentsLent.UnitsFilter.IOwnProps
type IState = LMSTO.CommentsLent.UnitsFilter.IState

export const Presenter: FC<IPresenterProps> = ({ className, units, currentData = [], setData }) => {

    const elements = units.filter((unit: any) => unit.unitType === 'wiki').map((unit: any) => {
        return {
            value: unit.id,
            title: unit.name,
            id: unit.id
        }
    })
    return <div className={cnClassName({}, [className])}>
        <Selectize
            onClick={(value) => setData(currentData.includes(value.value as string) ? currentData.filter((val: any) => val !== value.value) : [...currentData, value.value] ) }
            active={currentData}
            name={tT('comments.filters.lessons.1')}
            elements={elements}
            multiple={true}
            children={[]} />
    </div>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
        mapStateToProps,
        mapDispatchToProps
    )(Presenter);
