import React, { FC, memo } from 'react';
import { IPollsPropsType, mapDispatchToProps, mapStateToProps, TStateProps } from '../Polls.index'
import { cnSliderPollsView } from './index'
import './SliderViewPolls.scss'
import { withNewsContext } from 'News/contexts/news'
import { connect } from 'react-redux';
import MiniHeader from 'News/containers/MiniHeader'
import { LinkFilter } from 'News/utils/utils'
import openNews from 'blocks/Dialogs/News/DialogNewsView/DialogNewsView'
import IconMUI from '@material-ui/core/Icon';
import IdeaIcon from './IdeaIcon.svg';
import { i18n, Translate } from 'localization';
import MDRender from 'uielements/src/CommonmarkRender'
import { Button } from 'uielements/src';

const SliderViewPollsPresenter: FC<IPollsPropsType & TStateProps> = ({
    // poll,
    news,
    newsId,
    ...props
}) => {
    const {
        header,
        text
    } = news;
    // const { currentStatus } = news.idea;

    async function open() {
        try {
            const result = openNews({ newsId })
        } catch (e) { }
    };
    const lheader = header
    return <div>
        <LinkFilter action={open}>
            <MiniHeader authorOnly onClick={open} className={cnSliderPollsView('Author')} disableTextCut />

            <div className={cnSliderPollsView('Content')}>
                {lheader.trim() !== '' && <div className={cnSliderPollsView('Content-Title')}>
                    <MDRender
                        // disableEmojiOne
                        className={cnSliderPollsView('Content-TitleRender')}
                        type={'inline'}
                        disableShowMoreBtn
                        textCut={false}
                        // maxCharCount={200}
                        source={lheader}
                    />
                </div>}
                <div className={cnSliderPollsView('Content-NewsText')}>
                    <MDRender
                        disableEmojiOne
                        className={cnSliderPollsView('Content-Render')}
                        type={'textOnly'}
                        disableShowMoreBtn
                        textCut={true}
                        maxCharCount={350}
                        source={text}
                    />
                </div>

                <div className={cnSliderPollsView('Content-Footer')}>
                    <Button
                        theme={'unstyled'}
                        className={cnSliderPollsView('OpenBtn', ['primaryColor3-text'])}
                        onClick={open}><Translate i18nKey={"pryaniky.timeline.news.readfull"} /></Button>
                </div>
            </div>

        </LinkFilter>

    </div>
}
export const SliderViewPolls = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(SliderViewPollsPresenter)));