import * as React from 'react';
import * as utils from 'utils/src/utils';
import { IBodyProps } from '../NewsEdit-Body.index';
import { withBemMod } from '@bem-react/core';
import { TextareaMentions } from 'blocks/TextareaMentions/TextareaMentions';
import { cnBody } from '../NewsEdit-Body.index';
import { IBodyTypeIdeasProps } from './NewsEdit-Body_type_ideas.index';
import './NewsEdit-Body_type_ideas.scss';
import { Input } from 'uielements/src/Input/Input';
import { AdditionalFieldsRender } from '../../../NewsTypes/Ideas/AdditionalFieldsRender/AdditionalFieldsRender';
import { denormalize, normalize, schema } from 'normalizr';
import { EditorTypeEdit } from '../../../NewsTypes/Ideas/Editor/containers/edit/edit'
/*
const indeaFieldsSchema = () => {
  const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
  const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

  return [categorys];
}

const normalizeData = (data: any) => {
  const normalizedData = normalize(data, indeaFieldsSchema());
  return normalizedData;
}

const denormalizeData = (fields: any) => {
  const denormalizedData = denormalize(fields.result, indeaFieldsSchema(), fields.entities);
  return denormalizedData;
}
*/
export const withBodyTypeIdeas = withBemMod<IBodyTypeIdeasProps, IBodyProps>(
  cnBody(),
  { type: 'ideas' },
  (Presenter) => (
    (props) => {
      const newPost: any = utils.cloneObject(props.post);
      /*const onChangeTitle = (e: any) => {
        newPost.header = e.target.value;
        props.onChangePost(newPost);
      }

      const normalFields = normalizeData(props.post.idea.additionalFieldsValues);

      const onChangeFields = (field: any) => {
        normalFields.entities.fields[field.fieldId] = field;
        newPost.idea.additionalFieldsValues = denormalizeData(normalFields);
        props.onChangePost(newPost);
      }*/
      return (
        <Presenter {...props}>
          {({ text, onChangeText }) => <EditorTypeEdit data={newPost} onChange={props.onChangePost} />
            /*<React.Fragment>
              
              <Input
                onChange={onChangeTitle}
                className={'IdeaEdit-Input-Title'} 
                value={newPost.header}
                icon='bell'
                placeholder={i18n.t('placeholders.input.name')} />

              <TextareaMentions
                // getClassRef={this.getTextareaMentions} 
                users={true}
                tags={true}
                onChange={onChangeText}
                className={'IdeaEdit-TextareaMentions'} 
                icon={'edit'}
                value={text}
                placeholder={i18n.t('placeholders.textarea.news')} />

              <AdditionalFieldsRender data={normalFields} onChange={onChangeFields}/>
  
            </React.Fragment>*/
          }
        </Presenter>
      )
    }
  )
);