import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { createModal, IBodyProps } from 'utils/src/DialogCreator';
import { DialogActions, DialogTitle } from 'muicomponents/src/DialogParts';
import { i18n, Translate } from 'localization';
import { GraphicEditor, TEditor } from 'muicomponents/src/GraphicEditor';
import { fromPairs, toPairs } from "lodash";
import { useSelector } from "react-redux";
import { LoadingButton } from "muicomponents/src/LoadingButton/LoadingButton";
import baseRequests from "utils/src/requests/requests.base";
import { getUserByIdOrAlias } from "utils/src/CommonRedux/users/selectors";
import { checkResponseStatus, getCookie, isGuid } from "utils/src";
import moment from "moment";
import { getBusinessCardsList, useBusinessCard } from 'utils/src/requests/requests.businesscards';
import { Loading } from "uielements";
import { styled, Box, Typography } from 'muicomponents/src';
import { DialogContentStyled } from "./BusinessCardDialog.styled";
import { BusinessCardShort } from 'utils/src/BaseTypes/businesscards.types';

const LoaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 5
});

type BudinessCardDialogProps = IBodyProps<{}> & {
    id: string;
};

type BusinessCardsInfoFields = string[] | undefined;
type BusinessCardsInfoTextsFields = {
    [key: string]: string;
} | undefined;

const download = (file: File) => {
    const href = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.setAttribute('href', href)
    link.setAttribute('download', file.name);
    link.style.display = 'none';
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const BusinessCardDialogPr: FC<BudinessCardDialogProps> = ({
    id,
    handleAccept,
    handleClose
}) => {

    const user = useSelector(getUserByIdOrAlias(id));
    const userRef = useRef(user);
    userRef.current = user;

    const ref = useRef<TEditor | null>(null);
    const cardsRef = useRef<BusinessCardShort[]>([]);

    const [loading, setLoading] = useState(false);

    const [haventTemplates, setHaventTemplates] = useState(false);

    useEffect(() => {
        ref.current?.extendTemplates(async function() {
            try {
                setLoading(true);
                const response = await getBusinessCardsList({ skipCount: 0, count: 40 });
                if(checkResponseStatus(response)) {
                    if(!response.data.length) {
                        setHaventTemplates(true);
                        setLoading(false);
                        return [];
                    }
                    const prepairedTemplates = response.data.map(el => {
                        if(!cardsRef.current.map(el => el.id).includes(el.id)) {
                            cardsRef.current.push({
                                auditories: el.auditories,
                                date: el.date,
                                id: el.id,
                                name: el.name,
                                previewUrl: el.previewUrl
                            })
                        }
                        return el.template;
                    }).map(el => {
                        el.items = fromPairs(toPairs(el.items).map(([itemId, item]: [string, any]) => {
                            (item as any).blocked = true;
                            switch(item.type) {
                                case 'rect':
                                case 'image': {
                                    if(item.name === 'userImg') {
                                        item.type = 'image';
                                        let url = userRef.current?.baseData.imgUrl as string;
                                        if(url.includes('?')) {
                                            url += `&auth=${getCookie('authAt')}`;
                                        } else {
                                            url += `?auth=${getCookie('authAt')}`;
                                        }
                                        (item.props as any).href = url || '';
                                    }
                                    if(item.name === 'qrcode') {
                                        item.type = 'image';
                                        (item.props as any).href = baseRequests.getRequestUrl(`/ru/Data/v3/users/info/${id}/vcard.png`, { auth: getCookie('authAt') })
                                    }
                                    break;
                                }
                                case 'logo': {
                                    delete (item as any).blocked;
                                    (item as any).lock = true;
                                    break;
                                }
                                case 'text': {
                                    let str = '';
                                    if(item.name === 'displayName') {
                                        (item.props as any).text = userRef.current?.baseData.displayName || (item.props as any).text;
                                    }
                                    if(item.name === 'positiondivision') {
                                        if(userRef.current?.baseData.position) str += userRef.current?.baseData.position + '\n';
                                        if(userRef.current?.baseData.division) str += userRef.current?.baseData.division;
                                        (item.props as any).text = str || (item.props as any).text;
                                    }
                                    if(item.name === 'position') {
                                        let str = '';
                                        if(userRef.current?.baseData.position) str += userRef.current?.baseData.position;
                                        (item.props as any).text = str || (item.props as any).text;
                                    }
                                    if(item.name === 'division') {
                                        let str = '';
                                        if(userRef.current?.baseData.division) str += userRef.current?.baseData.division;
                                        (item.props as any).text = str || (item.props as any).text;
                                    }
                                    if(item.name === 'info') {
                                        let str: string = item.props.text || '';
                                        const fields = userRef.current?.profileData.userInfoFields.entities.fields || fromPairs(userRef.current?.profileData.userInfoFieldsOriginal.map((el: any) => [el.fieldId, el]));
                                        const texts = str.split('\n').map(el => {
                                            const reg = new RegExp('{{[.\\S]*}}', 'g');
                                            const variableTemplates = el.match(reg);
                                            if(!variableTemplates) return el;
                                            let rez = el;
                                            for(let variableTemplate of variableTemplates) {
                                                const variable = variableTemplate?.replace('{{', '').replace('}}', '');
                                                const hasOtherData = el !== variableTemplate;
                                                if(isGuid(variable)) {
                                                    const field = fields[variable];
                                                    let additionalString = '';
                                                    if(field) {
                                                        const fieldName = hasOtherData ? '' : field.field.name + ': ';
                                                        additionalString += fieldName;
                                                        switch(field.field.fieldType) {
                                                            case 'Interests':
                                                            case 'Tags': {
                                                                additionalString += field.tags?.reduce((a: string, c: any) => `${a ? `${a}, ` : a}${c.displayName}`, '') || '';
                                                                break;
                                                            }
                                                            case 'String':
                                                            case 'Textarea': {
                                                                additionalString += field.value || '';
                                                                break;
                                                            }
                                                            case 'Date': {
                                                                try {
                                                                    additionalString += moment(field.value).format('DD.MM.yyyy') || '';
                                                                } catch (e) {
                                                                    console.error('date field value format error')
                                                                }
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    if(!additionalString.trim()) rez = rez.replace(variableTemplate, '');
                                                    if(hasOtherData) {
                                                        rez = rez.replace(variableTemplate, additionalString);
                                                    } else {
                                                        rez = additionalString;
                                                    }
                                                } else {
                                                    const fieldValue = userRef.current?.baseData[variable];
                                                    if(!fieldValue) rez = rez.replace(variableTemplate, '');
                                                    if(hasOtherData) {
                                                        rez = rez.replace(variableTemplate, fieldValue);
                                                    } else {
                                                        rez = `${Translate.t({ i18nKey: `pryaniky.profile.${el.toLowerCase()}` })}: ${fieldValue}`
                                                    }
                                                }
                                            }
                                            if(!rez.trim().endsWith(':')) {
                                                return rez.replace(/\s+/g, ' ').trim();
                                            } else {
                                                return undefined;
                                            }
                                        }).filter(Boolean);
                                        (item.props as any).text = texts.join('\n');
                                    }
                                    break;
                                }
                            }
                            return [itemId, item];
                        })) as any;
                        return el;
                    });
                    setLoading(false);
                    return prepairedTemplates;
                } else {
                    console.error(response);
                    throw new Error('Get teplate response error');
                }
            } catch (e) {
                console.error(e);
                setLoading(false);
                return [];
            }
        })
    }, []);

    const saveImage = useCallback(async function() {
        try{
            setLoading(true);
            const file = await ref.current?.getImage();
            const currentTemplateId = await ref.current?.getSelectedTemplateId();
            const currentTemplate = cardsRef.current.find(el => el.id === currentTemplateId);
            const response = await useBusinessCard(currentTemplate!, {  });
            if(checkResponseStatus(response)) {
                file && download(file);
            }
        } catch(e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <>
            <DialogTitle
                className={'BusinessCard-DialogHeader'}
                onClose={handleClose}
            >
                <Translate i18nKey={'businesscard.title'} />
            </DialogTitle>
            <DialogContentStyled
                className={'BusinessCard-DialogBody'}
            >
                {
                    loading &&
                    <LoaderBox>
                        <Loading />
                    </LoaderBox>
                }
                {
                    haventTemplates
                    ? <Typography
                        className={'BusinessCard-DialogBody-Info BusinessCard-DialogBody-Info_no-templates'}
                    >
                        <Translate i18nKey={"businesscards.haventTemplates"} />
                    </Typography>
                    : <>
                        <Typography
                            className={'BusinessCard-DialogBody-Info BusinessCard-DialogBody-Info_no-templates'}
                        >
                            <Translate i18nKey={"businesscard.info"} />
                        </Typography>
                        <GraphicEditor
                            ref={ref}
                            avaliableElements={[]}
                            initViewport
                            initViewportOffset={10}
                            mode={'view'}
                            fixed
                            singleTemplate
                            templatesMenuIcon={'Badge'}
                            onSaveImage={(file) => {}}
                        />
                    </>
                }
            </DialogContentStyled>
            <DialogActions
                className={'BusinessCard-DialogActions'}
                closeText={<Translate i18nKey={'cancel'} />}
                onClose={handleClose}
            >
                {
                    !haventTemplates &&
                    <LoadingButton
                        loading={loading}
                        onClick={saveImage}
                    >
                        <Translate i18nKey={'pryaniky.saveasfile'} />
                    </LoadingButton>
                }
            </DialogActions>
        </>
    )
};

export const BusinessCardDialog = createModal(BusinessCardDialogPr);