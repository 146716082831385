import OrganizationChart from "@dabeng/react-orgchart";
import FormControl from "@material-ui/core/FormControl";
import StubListPlaceholder from "assets/svg/listPlaceholder.svg";
import html2canvas from "html2canvas";
import { i18n, Translate } from "localization";
import { Tooltip } from "muicomponents/src/Tooltip";
import { Box } from "muicomponents/src/Box/Box";
import { isIE } from "polyfills";
import React, { FC } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "uielements/src";
import Autocomplete from "uielements/src/MaterialElements/Autocomplete";
import { Button as ButtonMIU } from "uielements/src/MaterialElements/Button/Button";
import ButtonBox from "uielements/src/MaterialElements/ButtonBox/ButtonBox";
import Checkbox from "uielements/src/MaterialElements/Checkbox/Checkbox";
import Dropdown from "uielements/src/MaterialElements/Dropdown/Dropdown";
import { ISelectableObject } from "utils/src/requests/models/api.base";
import { v1 as uuid } from "uuid";
import { OrgchartSkeleton } from "../Skeletons/Orgchart/Orgchart-Skeleton";
import { defaultContext, OrgContext } from "./context";
import { CustomNode } from "./CustomNode/CustomNode";
import {
  cnOrgchart,
  IOrgchartProps,
  IOrgchartViewTypes,
  mapDispatchToProps,
  mapStateToProps,
} from "./Orgchart.index";
import "./Orgchart.scss";
import { editUnitModal } from "./UnitEdit/UnitEdit";

import IconButton from "@mui/material/IconButton";
import LanIcon from "@mui/icons-material/Lan";
import ListIcon from "@mui/icons-material/List";
import { Stack } from "@mui/material";
import { TreeView } from "./TreeView/TreeView";
import { TreeThree } from "muicomponents/src/Icons";
import { CustomSettings } from "utils/src";

let mouseMoveData: {
  diff: null | [number, number];
  lastX: number | null;
  lastY: number | null;
  lastViewportPrecents: null | [number, number];
} = {
  diff: null,
  lastX: null,
  lastY: null,
  lastViewportPrecents: null,
};

let zoomStep = 0.1;
const minZoom = 0.005;

// асинхронщина вынесена отдельно, не использует реакт
async function downloadPng(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  onStart?: () => void,
  onFinish?: () => void
) {
  // рисуем канвас по частям, так как можно упереться в лимиты браузера...
  const chartWrapper = Array.from(
    document.getElementsByClassName(cnOrgchart("ChartWrapper"))
  )[0];
  const loader = document.createElement("div"),
    i = document.createElement("i");
  if (chartWrapper) {
    loader.classList.add(cnOrgchart("FileProgress"));
    i.classList.add("Icon");
    i.classList.add("Icon_spinner");
    loader.appendChild(i);
    chartWrapper.appendChild(loader);
  }

  // завернуто специально что бы успел отрендериться лоадер
  setTimeout(async function () {
    const el = document.getElementsByClassName("OrcChart")[0] as HTMLDivElement,
      container = document.getElementsByClassName(
        "OrcContainer"
      )[0] as HTMLDivElement,
      els = Array.from(document.getElementsByClassName("CustomNode"));
    if (!el || !container || !els.length) return;
    // сохраняем текущее значение зума и в конце его возвращаем
    const zoom =
      getFloatFromScale(
        (el.style as CSSStyleDeclaration & { zoom: string }).transform
      ) || "1";
    (el.style as CSSStyleDeclaration & { zoom: string }).transform = `scale(1)`; //.zoom = '1';
    if (onStart) onStart();
    // html2canvas не умеет с тенями, в конце их вернем
    els.forEach((el) => {
      (el as any).style.boxShadow = "none";
    });
    // шаг для частичного рендера
    const pixelMax = 10000,
      // массив частичных канвасов
      canvases: {
        size: [number, number, number, number];
        canvas: HTMLCanvasElement;
      }[] = [];
    // все ли сгенерены
    let all = false,
      // текушая позиция х
      x = 0,
      // текушая позиция у
      y = 0;
    while (!all) {
      // заполняем обект с настровкаим для рендера
      const option: any = {
        x,
        y,
        height: y + pixelMax > el.clientHeight ? el.clientHeight - y : pixelMax,
        width: x + pixelMax > el.clientWidth ? el.clientWidth - x : pixelMax,
      };
      // подготавливем канвас
      const canvas = await html2canvas(el, {
        ...option,
        scale: 1,
      });
      canvases.push({
        size: [x, y, option.width, option.height],
        canvas,
      });
      // плюсуем х если надо
      if (x < el.clientWidth) x += pixelMax;
      // если х перерос то сбрасываем и идем на следующую строку
      if (x >= el.clientWidth) {
        x = 0;
        y += pixelMax;
      }
      // если превысили все строки то считаем счто все канвасы собраны
      if (y >= el.clientHeight) all = true;
    }
    // возвращаем тени
    els.forEach((el) => {
      (el as any).style.boxShadow = "";
    });
    // результирубщй канвас
    const cnv = document.createElement("canvas") as HTMLCanvasElement;
    // его ширина и высота должан быть равна всей структуре
    cnv.width = el.clientWidth;
    cnv.height = el.clientHeight;
    // результирубщий контекст
    var ctx = cnv.getContext("2d");
    // собираем все канвасы в результирующий
    canvases.forEach((el) => {
      const context = el.canvas.getContext("2d");
      if (context)
        ctx?.putImageData(
          context.getImageData(0, 0, el.size[2], el.size[3]),
          el.size[0],
          el.size[1]
        );
    });

    // этот кусок честно сдут из либы, ие отключен подальше...
    var isWebkit = "WebkitAppearance" in document.documentElement.style;
    var isFf = !!(window as any).sidebar;
    var isEdge =
      navigator.appName === "Microsoft Internet Explorer" ||
      (navigator.appName === "Netscape" &&
        navigator.appVersion.indexOf("Edge") > -1);

    if ((!isWebkit && !isFf) || isEdge) {
      (window.navigator as any).msSaveBlob(
        (cnv as any).msToBlob(),
        "OrgStructure.png"
      );
    } else {
      const a = document.createElement("a");
      a.href = cnv.toDataURL();
      a.style.display = "none";
      a.download = "OrgStructure.png";
      //   setDataURL(cnv.toDataURL());
      //   setDownload("OrgStructure.png");
      container.appendChild(a);
      a.click();
    }
    // возвращаем зум
    (
      el.style as CSSStyleDeclaration & { zoom: string }
    ).transform = `scale(${zoom})`;
    if (onFinish) onFinish();
    // убираем лоадер
    if (loader) loader.remove();
  }, 10);
}

const getFloatFromScale = (str: string) =>
  parseFloat(str.replace("scale", "").replace("(", "").replace(")", ""));

const OrgChartHeader: FC<{
  prentModel: any;
  setParentModel: any;
  changeSelect: any;
  filterShowOnlyOpenVacancy: any;
  showOnlyOpenVacancyAction: any;
  uIsAdmin: any;
  AddNewUnit: any;
  setViewType: any;
  viewType: "LIST" | "THREE";
}> = ({
  prentModel,
  setParentModel,
  changeSelect,
  filterShowOnlyOpenVacancy,
  showOnlyOpenVacancyAction,
  uIsAdmin,
  AddNewUnit,
  setViewType,
  viewType,
}) => {
  return (
    <Box className={cnOrgchart("Header")}>
      <Stack spacing={"40px"} direction="row" alignItems={"center"}>
        <Box flex={"1"} className={cnOrgchart("Header-Search")}>
          <FormControl fullWidth>
            <Autocomplete
              multiple={false}
              type={"units"}
              label={i18n.t("pryaniky.orgchart.unit.search")}
              value={prentModel}
              onChange={(item: ISelectableObject) => {
                setParentModel(item);
                changeSelect(item);
                // getOrgchartDataFromServer(value);
                // letSearch(item && item.displayName ? item.displayName: "" )
              }}
            />
          </FormControl>
        </Box>
        <Stack spacing={"8px"} direction="row" alignItems={"center"}>
          <IconButton
            style={{ padding: "5px" }}
            onClick={() => setViewType("LIST")}
            color={viewType === "LIST" ? "primary" : "default"}
          >
            <ListIcon style={{ fontSize: "30px" }} />
          </IconButton>

          <IconButton
            style={{ padding: "5px" }}
            onClick={() => setViewType("THREE")}
            color={viewType === "THREE" ? "primary" : "default"}
          >
            <LanIcon style={{ fontSize: "30px" }} />
            {/* <TreeThree /> */}
          </IconButton>
        </Stack>

        <Box className={cnOrgchart("Header-BTnAddUnit")}>
          {uIsAdmin && (
            <ButtonMIU
              variant="contained"
              color="primary"
              onClick={() => AddNewUnit()}
            >
              {i18n.t("pryaniky.orgchart.unit.add")}
            </ButtonMIU>
          )}{" "}
        </Box>
      </Stack>
      {viewType === "LIST" && (
        <Box>
          <Checkbox
            className={cnOrgchart("AddPosition-VacancyCheckbox")}
            value={filterShowOnlyOpenVacancy}
            onChange={() =>
              showOnlyOpenVacancyAction(!filterShowOnlyOpenVacancy)
            }
            label={i18n.t("pryaniky.orgchart.filter.Show.Only.Open.Vacancy")}
          />
        </Box>
      )}
    </Box>
  );
};

const OrgChartTreePresenter: React.FC<IOrgchartProps> = ({
  data,
  uIsAdmin,
  filterShowOnlyOpenVacancy,
  editable,
  isFinished,
  isLoading,
  showOnlyOpenVacancyAction,
  addSubUnit,
  getOrgchartDataFromServer,
  saveStructureDataOrgchart,
  changeSelect,
  orgchartState,
}) => {
  const [search, setSearch] = React.useState("");
  const [prentModel, setParentModel] = React.useState<any>(null);
  const [fileGenerating, setFileGenerating] = React.useState<boolean>(false);
  const [view, setView] = React.useState<IOrgchartViewTypes>("vertical");
  const [expand, setExpand] = React.useState(false);
  const [context, setContext] = React.useState(defaultContext);

  const [viewType, setViewType] = React.useState<"LIST" | "THREE">(
    CustomSettings.defaultOrgChartView() || "THREE"
  );

  let chartRef: any = null;

  React.useEffect(() => {
    const layout = document.getElementById("Layout");
    if (!layout) return;
    Array.from(layout.children).find((el) => {
      if (el.classList.contains("Layout-Inner")) {
        el.classList.add("FullWidth");
      }
    });
    return () => {
      Array.from(layout.children).find((el) => {
        if (
          el.classList.contains("Layout-Inner") &&
          el.classList.contains("FullWidth")
        ) {
          el.classList.remove("FullWidth");
        }
      });
    };
  }, []);

  React.useEffect(() => {
    if (viewType !== "LIST") {
      !data && getOrgchartDataFromServer(search);
    }
  }, [viewType]);

  React.useEffect(() => {
    var mainElement = document.getElementById(data?.id);
    if (mainElement) {
      const orgchartElement = document.getElementsByClassName(
        "orgchart"
      )[0] as HTMLDivElement;
      (
        orgchartElement.style as CSSStyleDeclaration & { zoom: string }
      ).transform = `scale(0.75)`; //'0.75'
      if (isIE) {
        mainElement.scrollIntoView();
      } else {
        mainElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
      }
    }
  }, [data?.id, view]);
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    // ref.current?.addEventListener("wheel", (event) => event.preventDefault());
  }, [ref]);

  const AddNewUnit = () => {
    const newUnitId = uuid();
    addSubUnit();

    editUnitModal({
      // id: newUnitId,
      addNewUnit: true,
      addNewUnitFromHeader: true,
    }).then((value: boolean) => {
      saveStructureDataOrgchart({ data, thisIsMerge: value });
    });
  };

  const letSearch = (value: string) => {
    getOrgchartDataFromServer(value);
  };

  const onMountOrgchart = () => {};

  const handleMouseMove = (ev: MouseEvent) => {
    if (!mouseMoveData.diff) mouseMoveData.diff = [0, 0];
    if (mouseMoveData.lastX !== null) {
      mouseMoveData.diff[0] = mouseMoveData.lastX - ev.x;
    }
    mouseMoveData.lastX = ev.x;
    if (mouseMoveData.lastY !== null) {
      mouseMoveData.diff[1] = mouseMoveData.lastY - ev.y;
    }
    mouseMoveData.lastY = ev.y;
    const el = document.getElementsByClassName("OrcContainer")[0];
    if (!el) return;
    el.scrollTo(
      el.scrollLeft + mouseMoveData.diff[0],
      el.scrollTop + mouseMoveData.diff[1]
    );
    // el.scrollLeft = el.scrollLeft + mouseMoveData.diff[0];
    // el.scrollTop = el.scrollTop + mouseMoveData.diff[1];
  };

  const handleMouseUp = (e: any) => {
    e.stopPropagation();
    document.removeEventListener("mousemove", handleMouseMove);
    mouseMoveData.diff = null;
    mouseMoveData.lastX = null;
    mouseMoveData.lastY = null;

    const el = document.getElementsByClassName("OrcContainer")[0];
    if (!el) return;
    el.classList.remove("grabbing");
    // document.removeEventListener('mouseup', this.handleMouseUp);

    // // document.removeEventListener('touchmove', this.handleMouseMove, { passive: false });
    // document.removeEventListener('touchmove', this.handleMouseMove);
    // document.removeEventListener('touchend', this.handleMouseUp);
    // document.removeEventListener('touchcancel', this.handleMouseUp);
  };

  const handleMouseDown = (e: any) => {
    e.stopPropagation();
    if (
      fileGenerating ||
      (editable && e.target.closest(".oc-node")) ||
      e.target.closest(".ZoomHandler")
    )
      return;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    const el = document.getElementsByClassName("OrcContainer")[0];
    if (!el) return;
    el.classList.add("grabbing");

    //   document.addEventListener('touchmove', this.handleMouseMove, { passive: false });
    //   document.addEventListener('touchend', this.handleMouseUp);
    //   document.addEventListener('touchcancel', this.handleMouseUp);
  };

  const fillViewPortPrecents = (
    el: HTMLDivElement,
    container: HTMLDivElement,
    zoom: number
  ) => {
    mouseMoveData.lastViewportPrecents = [100, 100];
    const newWidth = (container.clientWidth * 100) / (el.offsetWidth * zoom);
    mouseMoveData.lastViewportPrecents[0] = newWidth < 100 ? newWidth : 100;
    const newHeight = (container.clientHeight * 100) / (el.offsetHeight * zoom);
    mouseMoveData.lastViewportPrecents[1] = newHeight < 100 ? newHeight : 100;
  };

  const getZoomAndScrollData = (dir: "+" | "-") => {
    const el = document.getElementsByClassName("OrcChart")[0] as HTMLDivElement;
    const container = document.getElementsByClassName(
      "OrcContainer"
    )[0] as HTMLDivElement;
    if (!el || !container) return;
    let zoom = getFloatFromScale(
      (el.style as CSSStyleDeclaration & { zoom: string }).transform
    );
    if (!(el.style as CSSStyleDeclaration & { zoom: string }).transform)
      zoom = 1;
    if (zoom <= minZoom) return;
    if (!mouseMoveData.lastViewportPrecents) {
      fillViewPortPrecents(el, container, zoom);
    }
    const oldChartWidth = el.offsetWidth * zoom;
    const oldChartHeight = el.offsetHeight * zoom;
    const scrollPrecentXY = [
      (container.scrollLeft * 100) / oldChartWidth,
      (container.scrollTop * 100) / oldChartHeight,
    ];
    if (zoom >= 0.1 && zoomStep === 0.005) {
      zoomStep = 0.1;
    } else if (zoom <= 0.1 && zoomStep === 0.1) {
      zoomStep = 0.005;
    }
    if (dir === "+") {
      zoom += zoomStep;
    } else if (dir === "-") {
      zoom -= zoomStep;
    }
    zoom = Math.round(zoom * 10000) / 10000;
    if (zoom < minZoom) zoom = minZoom;
    const [prevViewportPecentX, prevViewportPecentY] =
      mouseMoveData.lastViewportPrecents || [100, 100];
    fillViewPortPrecents(el, container, zoom);
    const [precentX, precentY] = mouseMoveData.lastViewportPrecents || [
      100, 100,
    ];
    const [precentDiffX, precentDiffY] = [
      prevViewportPecentX - precentX,
      prevViewportPecentY - precentY,
    ];
    const newChartWidth = el.offsetWidth * zoom;
    const newChartHeight = el.offsetHeight * zoom;
    let newScroll: [number, number] = [
      (newChartWidth * scrollPrecentXY[0]) / 100 +
        (newChartWidth * (precentDiffX / 2)) / 100,
      (newChartHeight * scrollPrecentXY[1]) / 100 +
        (newChartHeight * (precentDiffY / 2)) / 100,
    ];
    return {
      zoom,
      newScroll,
    };
  };

  const applyZoomAndScrollData = (
    data: ReturnType<typeof getZoomAndScrollData>
  ) => {
    const el = document.getElementsByClassName("OrcChart")[0] as HTMLDivElement;
    const container = document.getElementsByClassName(
      "OrcContainer"
    )[0] as HTMLDivElement;
    if (!el || !container || !data) return;
    const { zoom, newScroll } = data;
    setContext({ zoom });
    (
      el.style as CSSStyleDeclaration & { zoom: string }
    ).transform = `scale(${zoom}`;
    if (isIE) {
      container.scrollLeft = newScroll[0];
      container.scrollTop = newScroll[1];
    } else {
      container.scrollTo(...newScroll);
    }
  };

  const zoonHandler: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: "+" | "-"
  ) => void = (e, dir) => {
    if (fileGenerating) return;
    applyZoomAndScrollData(getZoomAndScrollData(dir));
  };

  const expandStructure = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const el = document.getElementsByClassName("OrcChart")[0] as HTMLDivElement;
    const container = document.getElementsByClassName(
      "OrcContainer"
    )[0] as HTMLDivElement;
    let zoomAndScrollData = getZoomAndScrollData("-");
    if (!el || !container || !zoomAndScrollData) return;
    let xView = mouseMoveData.lastViewportPrecents
      ? mouseMoveData.lastViewportPrecents[0]
      : 0;
    let yView = mouseMoveData.lastViewportPrecents
      ? mouseMoveData.lastViewportPrecents[1]
      : 0;
    // while ((zoomAndScrollData?.newScroll.reduce((a, c) => a + c, 0) || 0) !== 0) {
    if (xView === 100 && yView == 100) return;
    let zoom = getFloatFromScale(
      (el.style as CSSStyleDeclaration & { zoom: string }).transform
    );
    if (!(el.style as CSSStyleDeclaration & { zoom: string }).transform)
      zoom = 1;
    while (xView < 100 || yView < 100) {
      // zoonHandler(e, '-');
      if (zoom - zoomStep >= 0.12) {
        zoomStep += 0.1;
      } else if (zoom - zoomStep <= 0.12) {
        zoomStep += 0.005;
      }
      // zoomStep += .1;
      const newData = getZoomAndScrollData("-");
      // applyZoomAndScrollData(zoomAndScrollData);
      xView = mouseMoveData.lastViewportPrecents
        ? mouseMoveData.lastViewportPrecents[0]
        : 0;
      yView = mouseMoveData.lastViewportPrecents
        ? mouseMoveData.lastViewportPrecents[1]
        : 0;
      if (zoom < minZoom) zoom = minZoom;
      if (!newData || zoom === minZoom) {
        xView = 100;
        yView = 100;
      } else {
        zoomAndScrollData = newData;
      }
    }
    applyZoomAndScrollData(zoomAndScrollData);
    zoomStep = 0.1;
  };

  if (!data && !uIsAdmin) return null;
  return (
    <OrgContext.Provider value={context}>
      <div ref={ref} className={cnOrgchart("Orgchart", { view })}>
        <OrgChartHeader
          setViewType={setViewType}
          viewType={viewType}
          prentModel={prentModel}
          setParentModel={setParentModel}
          changeSelect={changeSelect}
          filterShowOnlyOpenVacancy={filterShowOnlyOpenVacancy}
          showOnlyOpenVacancyAction={showOnlyOpenVacancyAction}
          uIsAdmin={uIsAdmin}
          AddNewUnit={AddNewUnit}
        />
        {viewType === "LIST" ? (
          <div
            className={cnOrgchart("ChartWrapper")}
            onMouseDown={handleMouseDown}
          >
            <TreeView prentModel={prentModel} />
          </div>
        ) : (
          <>
            {isLoading ? (
              <div className={cnOrgchart("ChartWrapper")}>
                <OrgchartSkeleton />
              </div>
            ) : !data ||
              data.id === "orgchart-00000000-0000-0000-0000-000000000000" ? (
              <div className={cnOrgchart("Stub")}>
                <img src={StubListPlaceholder} alt="" />
                <h1> {i18n.t("pryaniky.orgchart.empty.title")}</h1>
                <p>{i18n.t("pryaniky.orgchart.empty.text")}</p>
              </div>
            ) : (
              <div
                className={cnOrgchart("ChartWrapper")}
                onMouseDown={handleMouseDown}
              >
                <div className={cnOrgchart("ChartMenu")}>
                  {!isIE && (
                    <ButtonMIU
                      variant="outlined"
                      onClick={(e) => downloadPng(e)}
                    >
                      <Tooltip
                        className={"InfoIcon"}
                        title={i18n.t("pryaniky.orgchart.scrin.help")}
                      >
                        <span>
                          {" "}
                          <Icon icon="file-image" />
                        </span>
                      </Tooltip>
                    </ButtonMIU>
                  )}
                  <ButtonMIU
                    variant="outlined"
                    onClick={() => {
                      chartRef && chartRef.expandAllNodes();
                      Array.from(
                        document.querySelectorAll(
                          `.${cnOrgchart(
                            "ChartWrapper"
                          )} .oc-hierarchy > ul.hidden`
                        )
                      ).forEach((el) => el.classList.remove("hidden"));
                    }}
                  >
                    <Tooltip
                      className={"InfoIcon"}
                      title={i18n.t("pryaniky.orgchart.expand.help")}
                    >
                      <span>
                        <Icon icon="expand-arrows" />
                      </span>
                    </Tooltip>
                  </ButtonMIU>

                  <ButtonMIU variant="outlined" onClick={expandStructure}>
                    <Tooltip
                      className={"InfoIcon"}
                      title={i18n.t("pryaniky.orgchart.expandAll.help")}
                    >
                      <span>
                        <Icon icon="expand" />
                      </span>
                    </Tooltip>
                  </ButtonMIU>

                  <Dropdown
                    className={cnOrgchart("ViewSelector", { view })}
                    variant={"outlined"}
                    value={view}
                    items={[
                      {
                        title: (
                          <Tooltip
                            className="InfoIcon"
                            title={
                              <Translate
                                values={{
                                  text: Translate.t({
                                    i18nKey:
                                      "pryaniky.orgchart.displayType.vertical",
                                  }),
                                }}
                                i18nKey="pryaniky.orgchart.displayType.text"
                              />
                            }
                            placement="left"
                          >
                            <span>
                              <Icon icon="sitemap" />
                            </span>
                          </Tooltip>
                        ),
                        value: "vertical",
                      },
                      {
                        title: (
                          <Tooltip
                            className="InfoIcon"
                            title={
                              <Translate
                                values={{
                                  text: Translate.t({
                                    i18nKey:
                                      "pryaniky.orgchart.displayType.horisontal",
                                  }),
                                }}
                                i18nKey="pryaniky.orgchart.displayType.text"
                              />
                            }
                            placement="left"
                          >
                            <span>
                              <Icon
                                icon="sitemap"
                                className={cnOrgchart("ViewIcon", {
                                  horizontal: true,
                                })}
                              />
                            </span>
                          </Tooltip>
                        ),
                        value: "horizontal",
                      },
                      {
                        title: (
                          <Tooltip
                            className="InfoIcon"
                            title={
                              <Translate
                                values={{
                                  text: Translate.t({
                                    i18nKey:
                                      "pryaniky.orgchart.displayType.tree",
                                  }),
                                }}
                                i18nKey="pryaniky.orgchart.displayType.text"
                              />
                            }
                            placement="left"
                          >
                            <span>
                              <Icon icon="folder-tree" />
                            </span>
                          </Tooltip>
                        ),
                        value: "tree",
                      },
                    ]}
                    onChange={(value: IOrgchartViewTypes) => setView(value)}
                  />

                  <ButtonBox
                    className="ZoomHandler"
                    active=""
                    items={[
                      {
                        title: (
                          <Tooltip
                            className={"InfoIcon"}
                            title={i18n.t("pryaniky.orgchart.zoomOut.help")}
                          >
                            <span>-</span>
                          </Tooltip>
                        ),
                        value: "-",
                      },
                      {
                        title: (
                          <Tooltip
                            className={"InfoIcon"}
                            title={i18n.t("pryaniky.orgchart.zoomIn.help")}
                          >
                            <span>+</span>
                          </Tooltip>
                        ),
                        value: "+",
                      },
                    ]}
                    onClick={(val, e) => {
                      e.stopPropagation();
                      if (val)
                        zoonHandler(
                          e,
                          val as Parameters<typeof zoonHandler>["1"]
                        );
                    }}
                  />
                </div>

                <OrganizationChart
                  ref={(chart: any) => (chartRef = chart)}
                  containerClass={"OrcContainer"}
                  chartClass={"OrcChart"}
                  NodeTemplate={CustomNode}
                  datasource={data}
                  // pan={expand}
                  // zoom={editable? false : true}
                  draggable={false}
                />
              </div>
            )}
          </>
        )}
      </div>
    </OrgContext.Provider>
  );
};

export const OrgChartTree = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgChartTreePresenter);
