import { INewsCreatePropsType, INewsCreateStateType, mapStateToProps, mapDispatchToProps, cnCreateNews } from './NewsCreate.index'
import * as React from 'react';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';
import './NewsCreate.scss'
import { BaseNews } from '../NewsTypes/BaseType'
import { v1 as uuid } from 'uuid';
import { defaultBaseNews, defaultGroup } from '../NewsTypes/BaseType'
import { EditorForm } from '../EditorForm/EditorForm';
import { PriorityNav } from '../PriorityNav/PriorityNav';
import { EditorState, convertToRaw } from 'draft-js';
// import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
// import { rawToMd } from '../PryanikyEditor/convertorConfigs'

import { withComponentEnjector, IComponentAcceptor } from 'utils/src/ComponentInjector'

// import { Rule } from '../NewsTypes/Base.validate'

// import { EditorTypeNews as Thanks } from '../NewsTypes/Thanks/Editor/containers/news/news'
// import { EditorTypeNews as Polls } from '../NewsTypes/Polls/Editor/containers/news/news'
// import { EditorTypeNews as Ideas } from '../NewsTypes/Ideas/Editor/containers/news/news'
// import { EditorTypeNews as Uevent } from '../NewsTypes/Events/Editor/containers/news/news'
// import { EditorTypeNews as CreativeTasks } from '../NewsTypes/Creativetasks/Editor/containers/news/news'
// import { EditorTypeNews as Badges } from '../NewsTypes/Badges/Editor/containers/news/news'
// import { EditorTypeNews as Notices } from '../NewsTypes/Notices/Editor/containers/news/news'
// import { EditorTypeNews as Achievements } from '../NewsTypes/Achievements/Editor/containers/news/news'
// import { EditorTypeNews as News } from '../NewsTypes/News/Editor/containers/news/news'
// import { EditorTypeNews as Tasks } from '../NewsTypes/Tasks/Editor/containers/news/news'
// import { EditorTypeNews as Repost } from '../NewsTypes/Repost/Editor/containers/news/news'
// import { EditorTypeNews as Longread } from '../NewsTypes/Longread/Editor/containers/news/news'
// import { EditorTypeNews as Feedback } from '../NewsTypes/Feedback/Editor/containers/news/news'
// import { EditorTypeNews as Workflows } from '../NewsTypes/Workflows/Editor/containers/news/news'
import { isNumber } from 'lodash'
// import {
//     prepareToServer
// } from 'uielements/src/FieldRender'
import { CustomSettings } from 'utils/src/CustomSettings';
import { Tabs, Tab } from "@material-ui/core";

import { NewsCreateForm } from './Form/NewsCreateForm';
import { createNewsModal } from 'blocks/Dialogs/News/Create/Create';

import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

import { newsTypesIcons } from './newsTypesIcons';
import { toast } from 'react-toastify';

// ++
// const editorAlias: { [s: string]: React.FunctionComponent<any> } = {
//     news: News,
//     thanks: Thanks,
//     polls: Polls,
//     creativetasks: CreativeTasks,
//     badges: Badges,
//     achievements: Achievements,
//     notices: Notices,
//     ideas: Ideas,
//     events: Uevent,
//     tasks: Tasks,
//     repost: Repost,
//     feedback: Feedback,
//     longread: Longread,
//     expense: Longread,
//     test: Workflows,
//     workflow: Workflows,
// }

// ++
// const defaultError = {
//     error_code: 0,
//     error_text: '',
//     id: ''
// }



const StyledTab = withStyles({
    root: {
        padding: '12px 8px 12px',
        minWidth: '136px',
        minHeight: '144px',
        maxWidth: 'unset',
        flex: 1,
        '&:focus': {
            opacity: 1,
        },
        '&:first-child': {
            paddingLeft: '16px',
            minWidth: '144px'
        },
        '&:last-child': {
            paddingRight: '16px',
            minWidth: '144px'
        }
    },
    wrapper: {
        textTransform: 'none',
        border: '1px solid #D3D9E0',
        borderRadius: '8px',
        boxSizing: 'border-box',
        height: '100%',
        padding: '10px',
        justifyContent: 'flex-start',
        lineHeight: '16px'
    }
},
    {
        name: 'NewsCreateTab'
    }
)((props: React.ComponentProps<typeof Tab>) => {
    return <Tab disableRipple {...props} />
});



/**
 * @deprecated
 * use NewsCreator from src/News/types
 */
export class NewsCreatePresenter extends React.Component<INewsCreatePropsType & IComponentAcceptor, INewsCreateStateType>  {

    public static defaultProps = {
        gId: -1,
    }

    // ++
    constructor(props: INewsCreatePropsType & IComponentAcceptor) {
        super(props);

        this.state = {
            activePostId: undefined,
            validationErrors: [],
            gId: props.gId,
            workflowId: props.workflowId,
            group: null
        }
    }

    // ++
    // private onChangeGroup = (group: any) => {
    //     this.setState({ gId: group?.pkid || this.props.gId, group: group })
    // }

    public getPropsValues() {
        const { activePostId: active } = this.state;
        const {
            oneNewsType,
            cancleEdit
        } = this.props;

        let { postTypes } = this.props;
        postTypes = postTypes
            .filter((el: any) => !oneNewsType ? true : oneNewsType === el.id)
            .filter((el: any) => el.enabled)
        // .map((el: any) => ({
        //     ...el,
        //     displayName: i18n.t(`pryaniky.post.${el.idNumber}.title`, { ns: i18nNamespace })
        // }))
        const activePostId = isNumber(active) ? active : (postTypes.length !== 0 ? postTypes[0].idNumber : undefined)

        // if (activePostId === undefined) return null;

        return {
            activePostId,
            postTypes,
            cancleEdit
        }
    }

    public modalRender() {

        const props = this.getPropsValues();

        if (!props) return null;

        const {
            activePostId,
            cancleEdit,
            postTypes
        } = props;

        return (
            <div className={cnCreateNews({ modalRender: true }, [this.props.className])}>
                <Tabs
                    // classes={{ root: classes.root, scroller: classes.scroller }}
                    // value={activeIndex}
                    // onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {
                        postTypes.map((postType: any) => {
                            const icon = newsTypesIcons[postType.componentRenderName as keyof typeof newsTypesIcons] || newsTypesIcons.news;
                            return (
                                <StyledTab
                                    id={postType.id}
                                    className={cnCreateNews('ModalButton')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        createNewsModal({
                                            oneNewsType: postType.id,
                                            gId: this.props.gId,
                                            workflowId: this.props.workflowId
                                        })
                                            .then(({ success }) => {
                                                if (success) toast.success(<Translate i18nKey={'pryaniky.post.success'} />)
                                            })
                                            .catch(() => { })
                                    }}
                                    icon={<img src={icon} alt='postImage' />}
                                    label={
                                        <span className={cnCreateNews('PostName')}>
                                            {postType.displayName}
                                        </span>
                                    }
                                />
                            )
                        })
                    }
                </Tabs>
            </div>
        )

    }


    public render() {
        // const { activePostId: active, validationErrors, group } = this.state;
        const {
            //     children,
            //     denyMIME,
            //     maxFileSize,
            //     i18nNamespace,
            oneNewsType,
            //     sendError = defaultError,
            //     currentNews = { ...utils.cloneObject(defaultBaseNews), text: EditorState.createEmpty() },
            //     isValid,
            //     validFile,
            //     userData,
            //     cancleEdit
            showPostFormInDialog,
            postError
        } = this.props;

        // const { error_text, error_code } = sendError

        // let { postTypes } = this.props;
        // postTypes = postTypes
        //     .filter((el: any) => !oneNewsType ? true : oneNewsType === el.id)
        //     .filter((el: any) => el.enabled)
        // // .map((el: any) => ({
        // //     ...el,
        // //     displayName: i18n.t(`pryaniky.post.${el.idNumber}.title`, { ns: i18nNamespace })
        // // }))
        // const activePostId = isNumber(active) ? active : (postTypes.length !== 0 ? postTypes[0].idNumber : undefined)

        // const currentTypeItem = postTypes.find((el: any) => el.idNumber === activePostId)
        // const currentRender = currentTypeItem.componentRenderName

        // const CurrentType = editorAlias[currentRender] ? editorAlias[currentRender] : editorAlias['news'];

        // let disableSend = !isValid;
        // disableSend = validFile ? disableSend : !validFile;

        if (showPostFormInDialog) return this.modalRender();


        const props = this.getPropsValues();

        if (!props) return null;

        const {
            activePostId,
            cancleEdit,
            postTypes
        } = props;

        const isError = postError && postError.groupPKID === this.props.gId && postError.workflowId === this.props.workflowId
        return (
            <div className={cnCreateNews({}, [this.props.className])}>

                {/* <NewsTypeThanks /> */}
                {
                    activePostId !== undefined && <PriorityNav elements={postTypes} activeElemId={activePostId} onChange={this.onChangeActiveElem} />
                }
                {isError && <div className={cnCreateNews('Error')}>{postError.error.error_text}</div>}
                <NewsCreateForm
                    activePostId={activePostId}
                    gId={this.props.gId}
                    cancleEdit={cancleEdit}
                    onSendComplete={this.props.onSendComplete}
                    oneNewsType={oneNewsType}
                    workflowId={this.props.workflowId}
                />
            </div>
        )
    }


    // ++
    // оставлен пока только для отправки через модальное окно
    public componentDidMount = () => {
        this.props.showPostFormInDialog && this.props.loadAllowPostTypes(this.props.gId, this.props.workflowId)
    }

    // ++
    // оставлен пока только для отправки через модальное окно
    public componentDidUpdate = (prevProps: INewsCreatePropsType & IComponentAcceptor) => {
        if (this.props.showPostFormInDialog && this.props.workflowId !== prevProps.workflowId)
            this.props.loadAllowPostTypes(this.props.gId, this.props.workflowId)
    }

    private onChangeActiveElem = (el: any) => {
        const data: any = this.state.activePostId !== el.idNumber ? { data: { ...utils.cloneObject(defaultBaseNews), text: EditorState.createEmpty() } } : {};
        this.setState({
            activePostId: el.idNumber,
            isValid: false,
            ...data
        });
    }

    // ++
    // private onChangePublishAt = (date: string | null) => {
    //     this.props.pathCreator({
    //         currentNews: {
    //             ...this.props.currentNews,
    //             publishAt: date
    //         }
    //     })
    // }

    // ++
    // private onChangeFile = (files: any[], validate: boolean) => {
    //     const allCompleted = files.every((value) => value.downloadStatus === 'finish')
    //     this.props.pathCreator({
    //         currentNews: {
    //             ...this.props.currentNews,
    //             attachments: files
    //         },
    //         validFile: validate && allCompleted
    //     })
    // }

    // ++
    // private onChange = (data: BaseNews, validate: boolean, errors: Rule[]) => {
    //     this.setState({
    //         validationErrors: errors,
    //     })
    //     this.props.pathCreator({
    //         currentNews: data,
    //         isValid: validate,
    //         sendError: {
    //             error_code: 0,
    //             error_text: ''
    //         }
    //     })
    // }

    // ++
    // private sendNews = () => {
    //     const currentNews = {
    //         ...this.props!.currentNews,
    //         user: utils.userToSelectableObbject(this.props.userData.baseData),
    //         id: uuid(),
    //         group: { ...defaultGroup, pkid: this.state.gId },
    //     }
    //     this.props.pathCreator({
    //         currentNews
    //     })


    //     const text = currentNews.text && draftToMarkdown(convertToRaw(currentNews.text.getCurrentContent()), rawToMd);
    //     const additionalFieldsValues = prepareToServer(currentNews.additionalFields?.additionalFieldsValues || [])
    //     this.props.sendNews({
    //         ...currentNews,
    //         additionalFields: {
    //             ...currentNews.additionalFields,
    //             additionalFieldsValues,
    //         },
    //         text
    //     } as any, (success) => {
    //         if(success) {
    //             this.setState({ gId: this.props.gId, group: null })
    //         }
    //         this.props.onSendComplete && this.props.onSendComplete(success)
    //     })

    // }

}


export const NewsCreate = connect(
    mapStateToProps,
    mapDispatchToProps
)(withComponentEnjector<INewsCreatePropsType & IComponentAcceptor>(NewsCreatePresenter, ['news_creator']));
