import React, { FC, useState } from 'react'
import { ICreateAlbumProps, mapDispatchToProps, mapStateToProps } from './CreateAlbum.index'
import { connect } from 'react-redux';
import { Button, Icon } from 'uielements/src'
import { TextInput } from 'blocks/PryanikyForms/TextInput/TextInput'
import { i18n, Translate } from 'localization';
import './CreateAlbum.scss'

export const CreateAlbum_: FC<ICreateAlbumProps> = ({ gid, createAlbum }) => {
    const [value, setValue] = useState<string>('')

    const send = (e: any) => {
        e.preventDefault();
        if(value === '') return;
        createAlbum(gid, value)
        setValue('')
    }
    return <form className={'CreateAlbum'} onSubmit={send}>
        <TextInput
            placeholder={i18n.t('pryaniky.photoalbums.album.create')}
            value={value}
            onChange={setValue}
            action={<Button disabled={value === ''} theme={'unstyled_center'}><Icon icon={'plus'} /></Button>}
            // icon={<Icon icon={'plus'} />}
        />
    </form>
}

export const CreateAlbum = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateAlbum_)