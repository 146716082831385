
import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { NewsAttachV3 } from 'utils/src';
import { IBodyProps } from 'utils/src/DialogCreator'


export interface ISettingsItem {
    text?: string
    url?: string
    file?: NewsAttachV3
    enabled?: boolean
    user?: any
    id: string
    title?: string
    info?: string
}

export interface IWidgetSettingsProps extends IClassNameProps, IBodyProps<any> {
    settings?: {
        title?: string
        items?: ISettingsItem[] 
    }
    onChange: (value: { [key: string]: any }) => void;
    setValid: (value: boolean) => void
}

export const cnUsersCardWidgetSetting = cn('UsersCardWidgetSetting');