/**
 * @packageDocumentation
 * @module Widget_Settings_charts
 */

import { JFdictionary } from "../dictionary";

// area: require('blocks/Charts/_type/chart_type_area'),
//   bagel: require('blocks/Charts/_type/chart_type_bagel'),
//     bars: require('blocks/Charts/_type/chart_type_bars'),
//       line: require('blocks/Charts/_type/chart_type_line'),
//         pie: require('blocks/Charts/_type/chart_type_pie'),


const { types } = JFdictionary
export default {
  type: types.object,
  properties: {
    type: {
      type: types.string,
      placeholder: 'selecttype',
      opts: [
        {
          title: 'Линия',
          value: 'line'
        },
        {
          title: 'Линия с областью',
          value: 'area'
        },
        {
          title: 'Бублик',
          value: 'bagel'
        },
        {
          title: 'Торт',
          value: 'pie'
        },
        {
          title: 'Столбики',
          value: 'bars' 
        }        
      ],
      require: true
    },
    link: {
      type: types.string,
      placeholder: 'Введите ссылку на источник данных', 
      value: '',
      require: true
    }
  }
}