// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { i18n, Translate } from 'localization';

import { cnPwdForgot, IPwdForgotProps, IPwdForgotState } from './PwdForgot.index';
import './PwdForgot.scss';

import { Input } from 'uielements/src/Input/Input';
import { Button } from 'uielements/src/Button/Button';
import { toast } from 'react-toastify';
import { Spinner } from 'uielements/src';
import { Redirect } from 'react-router';

export class PwdForgotPresenter extends React.Component<IPwdForgotProps, IPwdForgotState> {
  public utils = utils;
  public el: HTMLElement | null;
  public redirect: string = '';
  public state = {
    request: false,
    error: '',
    companyName: '',
    login: '',
  };

  public render() {
    const { tag: TagName = 'div', children, className = '' } = this.props;
    this.utils.searchObject(this.props.location.search);
    // const {} = this.props;
    return (
      <TagName ref={el => (this.el = el)} className={cnPwdForgot({}, [className])}>
        {this.redirect !== '' && <Redirect to={this.redirectTo()} />}
        <div className={cnPwdForgot('Wrapper')}>
          {this.state.request && <Spinner className={cnPwdForgot('Spinner')} fontSize={40} />}
          <form className={cnPwdForgot('Form')} onSubmit={this.handleSubmit}>
            <div id={'LOGINRECOVERYWELCOME'} className={cnPwdForgot('Form-Text')}>
              <h4 className="my-0">{i18n.t('pryaniky.remind.title')}</h4>
              {i18n.t('pryaniky.remind.text')}
              {this.state.error && <div className={cnPwdForgot('ErrorMessage')}>{this.state.error}</div>}
            </div>
            <Input
              id={'LOGINRECOVERYCOMPANYNAME'}
              icon="passport"
              onChange={this.handleChange}
              name="companyName"
              value={this.state.companyName}
              placeholder={i18n.t('pryaniky.login.companyName')}
            />
            <Input
              id={'LOGINRECOVERYUSERNAME'}
              icon="user"
              onChange={this.handleChange}
              name="login"
              value={this.state.login}
              placeholder={i18n.t('pryaniky.login.userName')}
            />
            <div className={cnPwdForgot('Form-Actions')}>
              <Button
                id={'LOGINRECOVERYOK'}
                main={true}
                children={i18n.t('pryaniky.remind.send')}
                disabled={!this.state.login || !this.state.companyName}
              />
              <Button
                id={'LOGINRECOVERYCANCEL'}
                type="rlink"
                href="/login"
                noBorder
                noBackground
                children={i18n.t('pryaniky.remind.cancel')}
              />
            </div>
          </form>
        </div>
        {children}
      </TagName>
    );
  }

  private redirectTo = () => {
    const url = this.redirect;
    this.redirect = '';
    return url;
  };

  private remind = () => {
    this.setState({ request: true, error: '' });
    // document.body.classList.add(this.state.companyName.toLowerCase());
    // utils.setCookie({ name: 'companyName', value: this.state.companyName });
    // document.cookie = 'companyName=' + this.state.companyName + '; path=/;';
    this.utils.API.system
      .pwdRemind({
        siteName: this.state.companyName,
        userName: this.state.login,
      })
      .r.then((d: { [s: string]: any }) => {
        if (!d) {
          this.setState({ request: false, error: i18n.t('pryaniky.toast.error.server') });
        } else if (!d.error_code) {
          this.redirect = '/login?login=' + this.state.login + '&companyName=' + this.state.companyName;
          this.setState({});
          toast.info(d.error_text);
        } else {
          this.setState({ error: i18n.t('pryaniky.remind.error'), request: false });
        }
        // utils.setCookie({ name: 'companyName', value: '', props: { expires: new Date(0).toUTCString() } });
        // document.cookie = 'companyName=; path=/; expires=' + new Date(0).toUTCString();
      });
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    if (type === 'checkbox') this.setState({ [name]: checked });
    else this.setState({ [name]: value });
  };

  private handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    // if (this.allFieldsValid) {
    this.remind();
    // } else {
    //   this.setState({error: true})
    // }
  };
}

export const PwdForgot = PwdForgotPresenter;
//   compose(
//     withBemMod(cnPwdForgot(), {})
//     )(
//       PwdForgotPresenter
//   )
// )
