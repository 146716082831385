import AccessAlarms from '@mui/icons-material/AccessAlarms';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import React, { FC } from 'react';
import { CustomEventTitle } from '../../CustomEvent.styled';

export const CommonEvent: FC<any> = ({ event }) => {
    return (
        <>
            <AccessAlarms
                sx={{
                    color: `color-mix(in srgb, ${event.eventColor} 25%, white) !important`,
                    marginRight: '8px',
                }}
            />

            <Tooltip overflowOnly title={`${event.title}`}>
                <CustomEventTitle>{event.title}</CustomEventTitle>
            </Tooltip>
        </>
    );
};
