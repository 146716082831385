/**
 * @packageDocumentation
 * @module Widget_Settings_quests
 */
import { JFdictionary } from "../dictionary";
import { i18n, Translate } from 'localization';

const { renderers } = JFdictionary;

export default {
  type: renderers.layout,
  elements: [
    {
      type: renderers.input,
      scope: "#/properties/text"
    },
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type.show.birthday.icon'),
      scope: "#/properties/showImg"
    },
    {
      type: renderers.checkbox,
      label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
      scope: "#/properties/hideIfNoData"
    },
    {
      type: renderers.number,
      scope: "#/properties/usersCount"
    },
    {
      type: renderers.widgetIcon,
      scope: "#/properties/banner"
    },
    // widgetIcon
  ]
}