import * as React from 'react';
import { withBemMod } from '@bem-react/core';

import { cnBody } from '../Modal-Body.index';
import { IBodyTypeMenuItemChangeProps, IBodyTypeMenuItemChangeState } from './Modal-Body_type_menuItemChange.index';
import { Input } from 'uielements/src/Input/Input';
import { CheckboxInput } from 'uielements/src';

import './Modal-Body_type_menuItemChange.scss';
import { i18n, Translate } from 'localization';

class NewBody extends React.Component<IBodyTypeMenuItemChangeProps, IBodyTypeMenuItemChangeState> {
  public el: HTMLElement | null;

  constructor(props: IBodyTypeMenuItemChangeProps) {
    super(props);

    this.state = this.props.parent.state.data;
  }

  public render() {
    const customClassNames = '';
    const TagName = this.props.tag ? this.props.tag : 'div';
    return (
      <TagName ref={el => this.el = el} className={cnBody({}, (this.props.className ? this.props.className.split(' ') : []).concat(customClassNames.split(' ')))}>
        <Input icon={'font'} value={this.state.title} placeholder={i18n.t('pryaniky.menu.modal.change.itemName')} name='title' onChange={this.onChange} />
        {
          this.state.renderType === "link" &&
          <Input icon={'link'} value={this.state.url} placeholder={i18n.t('prayniky.menu.modal.changel.link')} name='url' onChange={this.onChange} />
        }
        <CheckboxInput checked={this.state.showOnMobile} onChange={this.onChange} name='showOnMobile' text={i18n.t('pryaniky.menu.modal.change.showOnMobile')} />
        <CheckboxInput checked={this.state.openInNewTab} onChange={this.onChange} name='openInNewTab' text={i18n.t('pryaniky.menu.modal.change.openInNewTab')} />
      </TagName>
    )
  }

  private onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const target = ev.currentTarget;
    const changedState: any = {};
    changedState[target.name] = target.name === 'showOnMobile' ? target.checked : target.value;
    changedState[target.name] = target.name === 'openInNewTab' ? target.checked : target.value;
    this.setState(changedState, () => {
      this.props.parent.state.data = this.state;
    });
  }

}

export const withBodyTypeMenuItemChange = withBemMod<any>(
  cnBody(),
  { type: 'menuItemChange' }, 
  (Presenter) => (
    (props: IBodyTypeMenuItemChangeProps) => (
      <NewBody {...props} />
      // <Presenter {...props}/>
    )
  )
);