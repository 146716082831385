import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { fromPairs } from 'lodash';
import { v1 as uuid } from 'uuid';

export const widgets_horizontal_v2 = (): IWidget<IColumn[]> => {

  const line1 = generateColumn({
    styles: {
      width: '50%'
    },
    items: [
      generateWidget({
        type: widgets.types.info,
        settings: {
          type: 'badges'
        }
      }),
      generateWidget({
        type: widgets.types.info,
        settings: {
          type: 'badges'
        }
      }),
      generateWidget({
        type: widgets.types.virtcurrency,
        settings: {
          type: 'badges'
        }
      }),
      generateWidget({
        type: widgets.types.info,
        settings: {
          type: 'badges'
        }
      }),
    ]
  });
  
  const line2 = generateColumn({
    styles: {
      width: '25%'
    },
    items: [
      generateWidget({
        type: widgets.types.info,
        settings: {
          type: 'badges'
        }
      }),
      generateWidget({
        type: widgets.types.virtcurrency,
        settings: {
          type: 'badges'
        }
      }),
      generateWidget({
        type: widgets.types.info,
        settings: {
          type: 'badges'
        }
      }),
    ]
  });
  
  const line3 = generateColumn({
    styles: {
      width: '25%'
    },
    items: [
      generateWidget({
        type: widgets.types.info,
        settings: {
          type: 'badges'
        }
      }),
      generateWidget({
        type: widgets.types.badgesList,
      }),
    ]
  });

  const sections: {
    [key: string]: {
      id: string;
      widgetId: string | null;
      size: number;
    }[]
  } = {};

  [line1, line2, line3].forEach(line => {
    sections[line.id] = line.items?.map((el: string | IWidget, _ ,arr) => {
      return {
        id: uuid(),
        widgetId: typeof el === 'object' ? el.id : el,
        size: 12 / arr.length
      }
    });
  });

  return generateWidget({
    type: widgets.types.layout + '/horizontal',
    // type: widgets.types.layout,
    version: '2',
    settings: {
      sections
    },
    data: [
      line1,
      line2,
      line3
      // generateColumn({
      //   items: [
      //     generateWidget({
      //       type: widgets.types.info,
      //       settings: {
      //         type: 'badges'
      //       }
      //     }),
      //     generateWidget({
      //       type: widgets.types.info,
      //       settings: {
      //         type: 'badges'
      //       }
      //     }),
      //   ]
      // }),
    ],
  });
}