import React, { ReactText } from 'react';
import { cn } from '@bem-react/classname';
// import { ICourse } from '../../mock';
// import { Button } from 'uielements/src/Button/Button';
import { Button } from 'uielements/src/Button/Button';
// import { Admin } from '../../Parts/Admin/Admin';
import { Deadlines } from '../../../components/Deadlines/Deadlines';
import { Progress } from '../../../components/Progress/Progress';
import { AuthorsList } from '../../../components/AuthorsList/AuthorsList';
import './Courses-Item.scss';
import { LikeAction } from '../../LikeAction/LikeAction'
import { tS } from 'LMSModule/utils/i18n.adaptor'

const cnItem = cn('Courses-Item');
const zeroId = "00000000-0000-0000-0000-000000000000";

export const Item: React.FC<any> = ({ className, course, sendLikeCourse, mini = false }) => {
  // const session = course.currentSession.id//course.courseSessionId === zeroId ? (course.courseSessions[0] ? course.normalSessions[course.courseSessions[0]] : null) : course.normalSessions[course.courseSessionId]
  const unitCount = course.unitsCount//course.courseUnits.length
  return (
    <div className={cnItem({}, [className])}>
      <img src={course.largeImgUrl} className={cnItem('Image')} />
      <div className={cnItem('Info')}>
        <h3 className={cnItem('Title')}>{course.name}</h3>

        <div className={cnItem('Section')}>
          {unitCount !== 0 && <div className={cnItem('UnitCount')}>{tS('units.1', { count: unitCount })}</div>}
          {/* {session && <Deadlines session={session} className={cnItem('Deadlines')} />} */}
        </div>

        {course.unitsCount !== 0 && <Progress className={cnItem('Progress')} currentStep={course.completedUnitsCount} totalSteps={course.unitsCount} />}

        {!mini && <AuthorsList authors={course.authors} className={cnItem('AuthorsList')} limit={5} />}
      </div>

      <div className={cnItem('ActionBox')}>
        <LikeAction {...{ likesCount: course.likesCount, isLiked: course.isLiked }}
          cid={course.id}
          nId={course.newsUid}
          className={cnItem('Like')} />

        <Button className={cnItem('Start')} href={`/LMS/${course.id}`} type="rlink" main padding="md">
          {tS('open.1')}
        </Button>
      </div>
    </div>
  );
};
