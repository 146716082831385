import React from 'react';
import { connect } from 'react-redux';
import { ILayoutLoadingProps, mapLayoutLoadingStateToProps, cnLayoutLoading } from './LayoutLoading.index';
import './LayoutLoading.scss';
import { i18n, Translate } from 'localization';
import { Loading } from 'uielements/src';

// const loader = <svg style={{ margin: 'auto', background: 'trasnparent', display: 'block' }} width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
//   <circle className="LeftMenu" cx="50" cy="50" r="32" stroke-width="8" stroke="#194776" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
//     <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
//   </circle>
//   <circle className="Button_main" cx="50" cy="50" r="23" stroke-width="8" stroke="#0066cc" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">
//     <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
//   </circle>
// </svg>

const LayoutLoadingPresenter: React.FC<ILayoutLoadingProps> = ({ status }) => {
  return (
    <div className={cnLayoutLoading()}>
      <div className={cnLayoutLoading('Data')}>
        {/* {loader} */}
        <div className={cnLayoutLoading('Loader')} >
          <svg className={cnLayoutLoading('Loader-Item', { type: 'first' })} style={{ margin: 'auto', background: 'trasnparent', display: 'block' }} width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle className={cnLayoutLoading('Loader-Item', ['LeftMenu'])} cx="50" cy="50" r="32" strokeWidth="8" stroke="#194776" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round" />
          </svg>
          <svg className={cnLayoutLoading('Loader-Item', { type: 'second' })} style={{ margin: 'auto', background: 'trasnparent', display: 'block' }} width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle className={cnLayoutLoading('Loader-Item', ['Button_main'])} cx="50" cy="50" r="23" strokeWidth="8" stroke="#0066cc" strokeDasharray="36.12831551628262 36.12831551628262" strokeDashoffset="36.12831551628262" fill="none" strokeLinecap="round" />
          </svg>
        </div>
        {
          status ? 
            <Translate i18nKey={`pryaniky.loading.${status}`} />
          :
            <Loading />
        }
        
      </div>
    </div>
  )
}

export const LayoutLoading = connect(
  mapLayoutLoadingStateToProps
)(LayoutLoadingPresenter)