import React from 'react'
import { Avatar, Button, Icon } from 'uielements/src'
import { Bable } from 'blocks/Bable/Bable'
import { UserMention } from 'uielements/src/UserMention/UserMention'
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import './scrollIntoViewIfNeeded_poly.js'
import { i18n, Translate } from 'localization';
import { cn } from '@bem-react/classname';

export const className = cn('UserMention');

const scrollToItem = (focused?: boolean) => (e: any) => {
    (focused && e) && e.scrollIntoViewIfNeeded()
}

export const item = (value: any, onClick: (e: any) => void, focused?: boolean, disabledVariants: any[] = []) => {
    const isDisabled = disabledVariants.find((v: any) => v.id === value.id)
    return <div
        ref={scrollToItem(focused)}
        className={className('Item', { focused_item: (focused && !isDisabled), disabled: Boolean(isDisabled)})}
        // className={'UserMention-Item' + ((focused && !isDisabled) ? ' focused_item' : '')}
        onClick={!isDisabled ? onClick : () => { }} key={value.id}>
        <div className={'MentionItem-Avatar'}>
            <Avatar className={className('Img', { disabled: Boolean(isDisabled) })} imgUrl={value.userLargePhotoUrl || value.imgUrl} imgId={value.imgId} size={40} name={value.displayName} />
        </div>
        <div className={'MentionItem-Data'}>
            <div className='MentionItem-displayName'>{value.displayName}&nbsp;{value.eMail && `(${value.eMail})`}</div>
            {!isDisabled ? <>
                {value.baseNetwork && <div>{value.baseNetwork.title}</div>}
                <div>{value.position}</div>
                <div>{value.division}</div>
            </> :
                // <ErrorMsg type={'error'} className={'MentionItem-DisabledItem'}>{i18n.t('pryaniky.createPost.thanks.disabled_user')}</ErrorMsg>
                <div className={'MentionItem-DisabledItem'}>{i18n.t('pryaniky.createPost.thanks.disabled_user')}</div>
            }
        </div>
    </div>
}

export const items = (values: any, onClick: (item: any) => () => void, focusIndex?: number, selected: any[] = [], disabledVariants: any[] = []) =>
    values.map((value: any, i: number) => item(value, onClick(value), i === focusIndex, disabledVariants))


export const itemChip = (item: any, remove: any) =>
    <Bable tag={'div'} className={'UserMention-Chip'}>
        <Avatar className='UserMention-avatar' imgUrl={item.userPhotoUrl || item.imgUrl || item.imgUrl48x48} imgId={item.imgId} size={24} name={item.displayName || item.name} />
        {(!item.isGroup && item.id) ? <UserMention className={'UserMention-displayName'} name={item.displayName || item.name} id={item.id} noLink={true} />
            : <span className={'UserMention-displayName'}>{item.displayName || item.name}</span>}
        {/*<div className='UserMention-displayName'>{item.displayName}</div>*/}
        <div className='UserMention-times'><Button theme='unstyled' onClick={remove}><Icon icon={'times'} /></Button></div>
    </Bable>