import { i18n, Translate } from 'localization';
import { Box, styled, TagChip, Typography } from "muicomponents/src";
import { Check } from "muicomponents/src/Icons";
import { Tooltip } from "muicomponents/src/Tooltip";
import React, { FC, useMemo } from "react";
import { STagWithSkillMatch } from "utils/src";

const StyledBox = styled(Box)({
}) as typeof Box;

const TagsBox = styled(Box)({
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    flexWrap: 'wrap'
}) as typeof Box;

const TagsTitle = styled('span')({
    display: 'flex',
    gap: 4,
    alignItems: 'center'
});

const MatchLevel: FC<{
    level: STagWithSkillMatch['matchLevel'];
    comment: STagWithSkillMatch['comment'];
}> = ({
    level,
    comment
}) => {

    const levelColor = useMemo(() => {
        switch(level) {
            case 'ActialSkill':
                return 'success';
            case 'ExpiredSkill':
                return 'warning';
            case 'NotConfirmed':
                return 'disabled';
            default:
                return undefined;
        }
    }, [level]);

    if(!levelColor) return null;

    return <Tooltip title={comment || ''}>
        <Check fontSize='small' color={levelColor} />
    </Tooltip>;
};

export const MatchBlock: FC<{
    matchPercent: number;
    skills: STagWithSkillMatch[];
}> = ({
    matchPercent,
    skills
}) => {
    return (
        <StyledBox>
            {
                !!skills.length &&
                <TagsBox>
                    <Typography fontSize={'small'}>
                        <Translate i18nKey='skills' />:&nbsp;
                    </Typography>
                    {
                        skills.map(tag => {
                            return (
                                <TagChip data={tag} size='small' label={
                                    <TagsTitle>
                                        {tag.displayName}
                                        <MatchLevel
                                            comment={tag.comment}
                                            level={tag.matchLevel}
                                        />
                                    </TagsTitle>
                                } />
                            );
                        })
                    }
                </TagsBox>
            }
            <Typography fontSize={'small'}>
                <Translate i18nKey='pryaniky.vacancy.user.matchPercent' />:&nbsp;{matchPercent}%
            </Typography>
        </StyledBox>
    );
};