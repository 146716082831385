import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { cnSurvey, } from './Survey.index';
import { SurveyStart } from './SurveyStart/SurveyStart';
import { SurveyPassing } from './SurveyPassing/SurveyPassing';
import { SurveyFinish } from './SurveyFinish/SurveyFinish';
import { QuizSkeleton } from 'blocks/Skeletons/Quiz/QuizSkeleton';
import { TypedListPlaceholder } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender';
import { i18n, Translate } from 'localization';
import { SurveyBodyBox, SurveyBox, SurveyPassingProgressBox, SurveyHeaderBox, TypographyIsCompetedByMe, TypographyName, TypographyText } from './Survey.styled';
import { useSurvey, processShowBar } from './Survey.hooks';
import { Box } from 'muicomponents/src';
import { Avatar } from 'muicomponents/src/Avatar';
import Alert from '@mui/material/Alert';
import { LinearProgressWithLabel } from 'muicomponents/src/LinearProgress/LinearProgress'
export const SurveyPr: FC<{}> = ({
}) => {

    const { id } = useParams<{ id: string }>();

    const {
        loading,
        finished,
        started,
        isActive,
        iconImgUrl,
        name,
        text,
        isCompletedByMe,
        highlightCorrectness,
        isShowResult,
        showEachQuestionOnOnePage,
        getOpenSurvey
    } = useSurvey(id);

    const value = processShowBar(id);

    const canStart = useMemo(() => !started && !finished, [started, finished]);

    const isPreview = useMemo(() => window.location.href.indexOf("preview=true") !== -1, []);

    useEffect(() => {
        getOpenSurvey({ id: id, context: { preview: isPreview } })
    }, [id, isPreview]);

    const passingProgressComponent = useMemo(() => {
        return <LinearProgressWithLabel
            className={cnSurvey('SurveyPassingProgressBox')}
            variant="determinate"
            value={finished ? 100 : value ? value : 0}
        />
    }, [value, finished])

    if (loading) return <QuizSkeleton />;

    if (isActive) return (
        <Box>
            <SurveyBox className={`Widget ${cnSurvey()}`}>
                <SurveyHeaderBox className={cnSurvey('Header')}>
                    {
                        iconImgUrl &&
                        <Avatar
                            className={cnSurvey('Header-Image')}
                            src={iconImgUrl}
                            alt={'SurveyIconImg'}
                            sx={{
                                width: '64px',
                                height: '64px'
                            }}
                        />
                    }
                    <Box className={cnSurvey('Header-Question')}>
                        <TypographyName className={cnSurvey('Header-Question-Name')}>
                            {name}
                        </TypographyName>
                        <TypographyText className={cnSurvey('Header-Question-Text')} component={'span'}>
                            <MDRender source={text || ''} textCut={false} />
                        </TypographyText>
                        {
                            canStart &&
                            <SurveyStart preview={isPreview} />
                        }
                        {showEachQuestionOnOnePage && <>
                            {(started || finished) && <SurveyPassingProgressBox>
                                <TypographyText
                                    className={cnSurvey('Header-Question-Text')}
                                    component={'span'}
                                >
                                    <Translate i18nKey='pryaniky.survey.process.progress' />
                                </TypographyText>

                                {passingProgressComponent}
                            </SurveyPassingProgressBox>}
                        </>}
                    </Box>


                </SurveyHeaderBox>
            </SurveyBox>
            {/* https://tracker.yandex.ru/COMMON-11400 */}
            {
                isPreview && <Alert
                    className={cnSurvey('AlertPreview primaryColor3-bg')}
                    severity="info"
                    sx={{ display: "flex", alignItems: "center" }} variant="filled" >
                    <Translate i18nKey='pryaniky.survey.prewiev.alert' />
                </Alert>
            }
            {
                started && <SurveyBox className={`Widget ${cnSurvey()}`}>
                    <SurveyBodyBox className={cnSurvey('Body')}>
                        <SurveyPassing preview={isPreview} />
                    </SurveyBodyBox>
                </SurveyBox>
            }
            {
                !started && finished && isShowResult && <SurveyBox className={`Widget ${cnSurvey()}`}>
                    <SurveyFinish preview={isPreview} />
                </SurveyBox>
            }
        </Box >
    );

    if (isCompletedByMe) return (
        <SurveyBox className={`Widget ${cnSurvey()}`}>
            <SurveyHeaderBox className={cnSurvey('Header')}>
                {
                    iconImgUrl &&
                    <Avatar
                        className={cnSurvey('Header-Image')}
                        src={iconImgUrl}
                        alt={'SurveyIconImg'}
                        sx={{
                            width: '64px',
                            height: '64px'
                        }}
                    />
                }
                <Box className={cnSurvey('Header-Question')}>
                    <TypographyName className={cnSurvey('Header-Question-Name')}>
                        {name}
                    </TypographyName>
                    <TypographyText className={cnSurvey('Header-Question-Text')} component={'span'}>
                        <MDRender source={text || ''} textCut={false} />
                    </TypographyText>
                    <TypographyIsCompetedByMe className={cnSurvey('IsCompletedByMe')}>
                        <Translate i18nKey='pryaniky.survey.isCompletedByMe' />
                    </TypographyIsCompetedByMe>
                </Box>
            </SurveyHeaderBox>
        </SurveyBox>
    );

    return (
        <TypedListPlaceholder type='survey' />
    );
};

export const Survey = SurveyPr;