import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { v1 as uuid } from 'uuid';
import { widgetsBaseSettings } from '../widgets/constants';

export const dashboard = (): IWidget<IColumn[]> => {
  return generateWidget({
    type: widgets.types.grid,
    data: [
      generateColumn({
        items: [
        ],
        styles: {
          width: '100%',
        },
      })
    ],
    settings: widgetsBaseSettings.grid
  });
}