import React, { ComponentProps, FC, useMemo, useCallback, Fragment, useState, forwardRef, SyntheticEvent, useRef } from 'react';
import { isOldIcon, useLeftMenuBars, useLeftMenuItem } from '../LeftMenu.hooks';
import { NLeftMenuItem, cnLeftMenuItem, LeftMenuItemType } from './LeftMenuItem.index';
import { Settings } from 'muicomponents/src/Icons/Icons';
import { Box } from 'muicomponents/src/Box/Box';
import { Button } from 'muicomponents/src/Button/Button';
import { Popover } from 'muicomponents/src/Popover';
import { difference } from 'lodash';
import './LeftMenuItem.scss';
import { useCurrentUser } from 'utils/src/CommonRedux/users/hooks';
import { i18n, Translate } from 'localization';
import { Avatar } from 'muicomponents/src/Avatar';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { basename, isMobile, isTablet, useMyTasksCountManager,  } from 'utils/src';
import * as MUIIcons from 'muicomponents/src/Icons/Icons';
import { StyledOldIcon } from '../LeftMenu.styled';
import {
    StyledLink,
    StyledBox,
    StyledListItem,
    StyledSettingsButton,
    TooltipDiv,
    SelectorStroke,
    StyledListItemIcon,
    NotificationDot,
    StyledChevronLeft,
    StyledALink
} from './LeftMenuItem.styled';
import { MENU_HIDE_BREAKPOINT } from '../LeftMenu.index';
import { useDebounce } from 'utils/src/hooks';
import { ItemBlockType } from 'utils/src/requests/models/api.menu';
import { openMenuByHover } from '../LeftMenu.constants';
import { cnLeftMenuBar } from '../Bar/LeftMenuBar.index';
import { mainBarId } from '../redux/constants';
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { FavoriteIcon } from 'muicomponents/src';
import { LeftMenuItemBadge } from './ItemBadge/ItemBadge';
import urls from 'routes/urls';

export const LeftMenuItem: FC<NLeftMenuItem.Props> = ({
    id,
    path,
    className,
    stayOpened,
    inFavouritesBar,
    ...props
}) => {

    const environment = useEnvironmentContex();

    const {
        item,
        isActive,
        edit,
        showNotification,
        changeItem,
        opened,
        setOpened,
        removeItem,
        setHide,
        menuHasFavouriteItem,
        toggleFavourite
    } = useLeftMenuItem(id);

    const {
        isDraggable,
        isEditable,
        isHidden
    } = item;

    const {
        bars,
        setBars,
    } = useLeftMenuBars();

    const authUser = useCurrentUser();

    const dropDownPath = useMemo(() => [ ...path, id ], [ path.toString() ]);

    const isOpenDropDown = difference(dropDownPath, bars).length === 0;
    
    // TODO make trully checks
    const linkCkeck = (item: ReturnType<typeof useLeftMenuItem>['item']) => {
        let href = item.url;
        const props: { [s: string]: any } = {
        };
        const domain = window.location.hostname;
        if (href && href.indexOf(domain) !== -1) href = href.substring(href.indexOf(domain) + domain.length);
        if (!href) {
        } else
        if (href.startsWith('/sites/') && !href.includes(basename)) {
            props.type = 'link';
            props.href = href;
        } else
        // first symbol is / and next text is maillo: then first letter must be removed
        if(href[0] === '/' && href.indexOf('mailto:') === 1) {
            props.type = 'link';
            props.href = href.substring(1);
        } else
        if (href[0] === '/' && href[1] !== '/' && href.indexOf('NewAdmin/') === -1 && href.indexOf('adminonly=true') === -1 && href.indexOf('administration') === -1) {
            props.type = 'rlink';
            if (href === '/user' || href === '/notifications-settings') href = href + '/' + authUser?.baseData?.id;
            props.to = href;
        }
        else {
            props.type = 'link';
            if (href.indexOf('administration') !== -1 && href.indexOf(basename) === -1) href = href.replace('/administration', `${basename}/administration`);
            props.href = href;
        }
        if (item.openInNewTab) props.target = "_blank";
        return props;
    };

    const [focusedFavourites, setFocusedFavourites] = useState(false);
    const dropFocusedFavourites = useRef(false);

    const setFocusFavourites = useDebounce(() => {
        if(dropFocusedFavourites.current) return;
        setFocusedFavourites(true);
    }, 250);

    const onMouseEnter = useCallback<NonNullable<ComponentProps<typeof StyledListItem>['onMouseEnter']>>((...args) => {
        if(isMobile() || isTablet()) {
            props.onMouseEnter?.(...args);
            return;
        }
        if(openMenuByHover && path.length) {
            setBars(item.renderType === 'dropdown'
                ? dropDownPath
                : path
            );
        }
        dropFocusedFavourites.current = false;
        menuHasFavouriteItem && setFocusFavourites();
        props.onMouseEnter?.(...args);
    }, [props.onMouseEnter, menuHasFavouriteItem, path, dropDownPath]);

    const onMouseLeave = useCallback<NonNullable<ComponentProps<typeof StyledListItem>['onMouseLeave']>>((...args) => {
        if(isMobile()) {
            props.onMouseLeave?.(...args);
            return;
        }
        const [event] = args;
        const relatedTarget = event.relatedTarget as HTMLElement;
        try {
            const relatedBarId = relatedTarget?.getAttribute?.('bar-id') || relatedTarget?.closest?.(`.${cnLeftMenuBar('Sortable')}`)?.getAttribute?.('bar-id') || '';
            if(openMenuByHover && relatedBarId && !dropDownPath.includes(relatedBarId)) {
                setBars(path);
            }
        } catch (e) {
            console.error(relatedTarget);
            throw e;
        }
        dropFocusedFavourites.current = true;
        setFocusedFavourites(false);
        props.onMouseLeave?.(...args);
    }, [props.onMouseLeave, path, dropDownPath, id]);

    const onTouchStart = useCallback<NonNullable<ComponentProps<typeof StyledListItem>['onTouchStart']>>((...args) => {
        setFocusedFavourites(true);
        props.onTouchStart?.(...args);
    }, [props.onTouchStart]);

    const onTouchEnd = useCallback<NonNullable<ComponentProps<typeof StyledListItem>['onTouchEnd']>>((...args) => {
        setFocusedFavourites(false);
        props.onTouchEnd?.(...args);
    }, [props.onTouchEnd]);

    const bookmark = useMemo(() => {
        if (focusedFavourites && inFavouritesBar) return 'Admins';
        // not edit and not open
        if (!edit && !isOpenDropDown) return 'Off';
        // not edit or not draggable and open
        if (isOpenDropDown && (!edit || !isDraggable)) return 'On';
        // edit and not open and draggable
        if (edit && !isOpenDropDown && isDraggable) return 'Admins';
        // edit and open
        if (edit && isOpenDropDown) return 'AdminsHover';
        return 'Off';
    }, [isOpenDropDown, edit, isDraggable, inFavouritesBar, focusedFavourites]);

    const title = useMemo(() => {
        if(item.blockType !== 'user' || item.url !== '/shop') Translate.setResorseItem(`pryaniky.custom.menu.item.${item.id}`, item.title);
        return item.blockType === 'user' ?
            authUser?.baseData?.displayName :
            item.url === '/shop' ? 
            item.title :
            (item.title || <Translate i18nKey={item.id === 'service' ? 'pryaniky.leftMenu.service.name' : `pryaniky.custom.menu.item.${item.id}`} defaults={item.title}/>);
    }, [item.title, authUser]);

    const icon = useMemo(() => {
        const MUIIconComponent = MUIIcons[item.icon as keyof typeof MUIIcons];
        return item.blockType === 'user' ?
            <Avatar alt={authUser?.baseData?.displayName} src={authUser?.baseData?.userPhotoUrl} sx={{ marginLeft: '-8px' }} /> :
            !item.icon ? null :
            isOldIcon(item.icon) ?
            <StyledOldIcon className={`Icon Icon_${item.icon}`} /> :
            !MUIIconComponent ? null :
            <MUIIconComponent />
    }, [item.icon]);

    const linkWrapper = useCallback((children: any) => {
        let Component: any = item.renderType === 'link' ? StyledLink : StyledBox;
        if (item.renderType === 'link' && item.url.includes('/administration')) Component = StyledALink;
        const componentProps = item.renderType === 'link' ? linkCkeck(item) : {};
        return (
            <Component
                {...componentProps}
                onClick={(e: SyntheticEvent) => {
                    if(item.id !== 'service' && item.renderType === 'link') {
                        if(edit || (!stayOpened && !opened)) {
                            e.preventDefault();
                        }
                        if(!edit) {
                            // if(stayOpened) {
                            // } else {
                                setBars([mainBarId]);
                                if (document.body.clientWidth < MENU_HIDE_BREAKPOINT || environment.isTablet || environment.isMobileOS) {
                                    setHide(true);
                                }
                                if(opened && !stayOpened) {
                                    e.stopPropagation();
                                    setOpened(false);
                                }
                            // }
                        }
                    }
                }}
                sx={{
                    paddingRight: !opened ? '24px' : undefined,
                    ...componentProps.sx
                }}
            >
                {children}
            </Component>
        );
    }, [item, opened, edit]);

    const [ popoverAnchor, setPopoverAnchor ] = React.useState<{ top: number; left: number } | null>(null);
  
    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverAnchor({ top: rect.top + (rect.height / 2), left: rect.left + (rect.width / 2) });
    };

    const closePopover = () => {
        setPopoverAnchor(null);
        setRemoveShure(false);
    }
  
    const clickPopoverHandle: ComponentProps<typeof Popover>['onClick'] = (event) => {
        event?.stopPropagation();
        closePopover();
    };
  
    const closePopoverHandle: ComponentProps<typeof Popover>['onClose'] = (event) => {
        closePopover();
    };
  
    const openedPopover = Boolean(popoverAnchor);

    const changeItemClick: ComponentProps<typeof Button>['onClick'] = (event) => {
        event.stopPropagation();
        changeItem();
        closePopover();
    }

    const [ removeShure, setRemoveShure ] = useState(false);

    const removeItemClick: ComponentProps<typeof Button>['onClick'] = (event) => {
        event.stopPropagation();
        if(!removeShure) {
            setRemoveShure(true);
        } else {
            removeItem();
            closePopover();
        }
    }

    const showFavorites = useMemo(() => {
        return focusedFavourites && !edit && opened && item.blockType !== ItemBlockType.favourites && item.isAllowAddToFavorites;
    }, [focusedFavourites, edit, opened, item.blockType, item.isAllowAddToFavorites]);

    const openDropdown = useCallback(() => {
        if(item.renderType === 'dropdown') {
            if(bars.includes(id)) {
                setBars(path);
            } else {
                setBars(dropDownPath);
            }
        }
    }, [item.renderType, bars, path, dropDownPath]);

    const itemType = useMemo(() => {
        switch (item.url) {
            case urls.tasks:
                return LeftMenuItemType.tasks
            default:
                return LeftMenuItemType.null
        }
    }, [item]);

    const sxByType = useMemo(() => {
        if(!menuHasFavouriteItem) return null;
        if(!opened) {
            return {
                [`.MuiListItemSecondaryAction-root`]: {
                    minWidth: 'auto'
                }
            }
        }
        switch (itemType) {
            case LeftMenuItemType.tasks:
                return {
                    [`.MuiListItemSecondaryAction-root`]: {
                        minWidth: '60px'
                    }
                };
            default:
                return null;
        }
    }, [itemType, menuHasFavouriteItem, edit, opened]);
    
    if(!edit && isHidden) return null;

    return (
        <StyledListItem
            className={cnLeftMenuItem({ selected: isOpenDropDown, fixed: !isDraggable, isActive }, [className, 'additionalColor1-text'])}
            data-id={id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onClick={(...args) => {
                openDropdown();
                props.onClick?.(...args);
            }}
            sx={sxByType}
            secondaryAction={
                <>
                    <LeftMenuItemBadge
                        item={item}
                        className={cnLeftMenuItem('Badge')}
                    />
                    {
                        showFavorites &&
                        <StyledSettingsButton className={cnLeftMenuItem('Favourite')} color="primary" onClick={toggleFavourite}>
                            {
                                item.isInFavorites
                                ? <FavoriteIcon on />
                                : <FavoriteIcon />
                            }
                        </StyledSettingsButton>
                    }
                    {
                        edit && isEditable && !inFavouritesBar && 
                        <>
                            <StyledSettingsButton className={cnLeftMenuItem('Edit')} color="primary" onClick={openPopover}>
                                <Settings />
                            </StyledSettingsButton>
                            <Popover
                                BackdropComponent={Fragment}
                                open={openedPopover}
                                // anchorEl={popoverAnchor}
                                anchorReference="anchorPosition"
                                anchorPosition={popoverAnchor || { top: 0, left: 0 }}
                                onClick={clickPopoverHandle}
                                onClose={closePopoverHandle}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                PaperProps={{
                                    sx: {
                                        minWidth: 0,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }
                                }}
                            >
                                <Button onClick={changeItemClick}>
                                    <Translate i18nKey={'edit'} />
                                </Button>
                                <Button onClick={removeItemClick} color={removeShure ? 'error' : undefined}>
                                    {
                                        removeShure ? 
                                        <>
                                            <Translate i18nKey={'shure'} />?
                                        </> :
                                        <Translate i18nKey={'remove'} />
                                    }
                                    
                                </Button>
                            </Popover>
                        </>
                    }
                    {
                        item.renderType === 'dropdown' && <StyledChevronLeft sx={{
                            transform: `rotateY(${isOpenDropDown ? 180 : 0}deg)`
                        }} />
                    }
                    {props.secondaryAction}
                </>
            }
        >   
            <SelectorStroke className={cnLeftMenuItem('Handler')} bookmark={bookmark} />
            <TooltipDiv>
            {
                linkWrapper(
                <>
                    {
                        !!icon &&
                        <StyledListItemIcon className={'additionalColor1-text'}>
                            {icon}
                        </StyledListItemIcon>
                    }
                    <Box className={cnLeftMenuItem('Name')} sx={{
                        paddingLeft: !icon ? '12px' : undefined,
                        fontWeight: isActive ? 'bold' : undefined
                    }}>
                        {title}
                    </Box>
                    {
                        showNotification &&
                        <NotificationDot />
                    }
                </>
                )
            }
            </TooltipDiv>
        </StyledListItem>
    )
}