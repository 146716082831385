import * as React from 'react';
import * as utils from 'utils/src/utils';
import {
    IMarkdownWidgetProps,
    cnMarkdownWidget,
    mapStateToProps,
    IMarkdownWidgetState,
    mapDispatchToProps
} from './MarkdownWidget.index';
// import { Editor } from '../Editor/Editor';
import { Button, Icon } from 'uielements/src/index'
import { PostContentRender } from '../PostContentRender/PostContentRender'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { PryanikyEditor } from 'blocks/PryanikyEditor/_PryanikyEditor'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { mdToRaw, rawToMd } from 'blocks/PryanikyEditor/convertorConfigs'
import { draftToMarkdown } from 'uielements/src/PryanikyEditorV1/converter/draft-to-markdown';
import { NodeBulder } from 'uielements/src/CommonmarkRender'

import './MarkdownWidget.scss';

class MarkdownWidgetPresenter extends React.Component<any, IMarkdownWidgetState> {
    public state = {
        editData: false,
        data: EditorState.createWithContent(convertFromRaw((() => {
            const nb = new NodeBulder(this.props.widget.data || '')
            return nb.getDraftRaw()
        })())),
        text: this.props.widget.data
    };

    private mounted = false;

    public componentWillUnmount() {
        this.mounted = false;
    }

    private onChangeText = (data: any) => {
        this.setState({ data })
    }

    private endEdit = () => {
        const text = draftToMarkdown(convertToRaw(this.state.data.getCurrentContent()), rawToMd);
        this.setState((s: IMarkdownWidgetState) => {
            s.editData = false;
            // s.text = text;
            return s;
        })

        this.props.updateWidget({
            ...this.props.widget,
            data: text
        })

        // this.props.changeVm('widgets.' + this.props['data-id']+'.data', this.state.data);
    }

    private toEdit = () => {
        this.setState((s: IMarkdownWidgetState) => {
            s.editData = true;
            return s;
        })
    }

    public render() {
        const { tag: Tag = 'div', className, widget, edit, ...props } = this.props;
        const { editData, data, text } = this.state;
        if (edit && editData) return <Tag className={cnMarkdownWidget({}, [className])} {...props}>
            <Button onClick={this.endEdit}><Icon icon={'edit'} /></Button>
            <PryanikyEditor
                value={data}
                setActionBtn={(v: any) => { }}
                onChange={this.onChangeText}
            />
            {/* <Editor
                onChange={this.onChangeText}
                value={data}
                // className={cnBody('Editor')}
                placeholder={i18n.t('announcement text')} /> */}
        </Tag>
        if (edit) return <Tag className={cnMarkdownWidget({}, [className])} {...props}>
            <Button onClick={this.toEdit}><Icon icon={'edit'} /></Button>
            <PostContentRender data={this.props.widget.data} />
        </Tag>
        return (
            <Tag className={cnMarkdownWidget({}, [className])} {...props}>
                <PostContentRender data={this.props.widget.data} />
            </Tag>
        );
    }
}

export const MarkdownWidget = connect(mapStateToProps, mapDispatchToProps)(MarkdownWidgetPresenter);
