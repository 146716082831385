import React from 'react';
import { cnLeftMenuRemover, ILeftMenuRemoverProps, ILeftMenuRemoverState, IRemoverConfirmTitleProps, mapRemoverConfirmTitleStateToProps, mapRemoverConfirmTitleActionToProps } from './LeftMenuRemover.index';
import './LeftMenuRemover.scss';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { IDraggingItem } from '../Item/LeftMenuItem.index';
import { confirm } from './../../../utils.project/utils.project';
import { i18n, Translate } from 'localization';
import { getI18nKey } from '../Item/label/utils';

const RemoverConfirmTitlePresenter: React.FC<IRemoverConfirmTitleProps> = ({ data, path, cb, menuDeleteItem }) => {
  React.useEffect(() => {
    confirm({
      text: Translate.t({ i18nKey: "pryaniky.menu.modal.delete.title", values: { oldData: Translate.t({ i18nKey: getI18nKey(data), defaults: data.title }) } }),
      onConfirm: () => {
        menuDeleteItem({ path, id: data.id });
        cb && cb();
      },
      onCancel: () => {
        cb && cb();
      }
    });
  }, []);
  return null;
}

const RemoverConfirmTitle = connect(
  mapRemoverConfirmTitleStateToProps,
  mapRemoverConfirmTitleActionToProps
)(RemoverConfirmTitlePresenter);

export const RemoverPresenter: React.FC<ILeftMenuRemoverProps> = () => {
  const [state, setState] = React.useState<ILeftMenuRemoverState | undefined>();

  const [{ isOver }, drop] = useDrop({
    accept: 'menuItem',
    drop: (i, monitor) => {
      const { id, path } = i as IDraggingItem;
      setState({ id, path });
    },
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver({ shallow: true })
      }
    },
  });

  return (
    <div 
      ref={drop}
      className={cnLeftMenuRemover({ isOver })}>
      {state && <RemoverConfirmTitle id={state.id} path={state.path} cb={() => setState(undefined)} />}
      <Translate i18nKey={`pryaniky.menu.left.remove.text${isOver ? '.isOver' : ''}`} />
    </div>
  )
}

export const Remover = connect(
)(RemoverPresenter);