import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import { guids } from "./index";
import { v1 as uuid } from 'uuid';

export const wikiTP = (): IWidget<IColumn[]> => {
  const ids = guids([ 'commonmark' ]);
  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            id: ids.commonmark,
            type: widgets.types.commonmark
          })
        ],
        styles: {
          width: '100%',
        },
      })
    ],
    // settings: {
    //   breakpoints: { xs: 480 },
    //   cols: { xs: 12 },
    //   layouts: {
    //     xs: [
    //       { i: ids.users1, x: 0, y: 0, w: 12, h: 10 },
    //     ]
    //   }
    // }
  });
}