import React, { useEffect } from 'react';
import { INotifiSettingsNeworksProps, mapStateToProps, mapDispatchToProps, cnNotifiSettingsNeworks } from './NotifiSettingsNeworks.index';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { i18n, Translate } from 'localization';
import { connect } from 'react-redux';
import './NotifiSettingsNeworks.scss';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dropdown from 'uielements/src/MaterialElements/DropdownWithText/DropdownWithText';
import { Button } from 'uielements/src';

import { NotifiSettingsGroups } from '../NotifiSettingsGroups/NotifiSettingsGroups';

const useStyles = makeStyles({
  content: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
});

const NotifiSettingsNeworksPresenter: React.FC<INotifiSettingsNeworksProps> = ({
  networks,
  networksIds,
  templates,
  match,
  parentNetwork,
  getNotifiSettings,
  changeNotifiSettingsNetworkData,
  clearNotifiSettings,
  changeNotifiSettingsNetworkTemplate,
}) => {

  const { uid } = match.params;
  const classes = useStyles()
  useEffect(() => {
    if (!networksIds.length) getNotifiSettings({ uid });
    return () => {
      () => clearNotifiSettings();
    }
  }, [])

  if (!networksIds.length) return null;

  const showTemplates = [
    ...templates.map((value) => ({ ...value, title: i18n.t('pryaniky.notifications.settings.template.' + value.value) })),
    { title: i18n.t('pryaniky.notifications.settings.networks.template.custom'), value: 'custom' }];

  return (
    <div className={cnNotifiSettingsNeworks()}>
      {networksIds.length > 1 ? networksIds.map(networkId => {
        const isExpanded = networks[networkId].expanded === networks[networkId].id;
        const isInternetWorkingGroup = parentNetwork?.id === networkId;
        const templateName = !isInternetWorkingGroup && showTemplates.find(template => template.value === networks[networkId].notificationTemplate)?.title;

        return (
          <div className='Widget'>
            <ExpansionPanel expanded={isExpanded} className={cnNotifiSettingsNeworks('Item')} >
              <ExpansionPanelSummary
                className={cnNotifiSettingsNeworks('ExpansionPanel')}
                classes={classes}
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() => changeNotifiSettingsNetworkData({
                      nid: networkId, data: { expanded: !!networks[networkId].expanded ? false : networkId }
                    })}
                  />
                }
              >
                <div className={cnNotifiSettingsNeworks('Item-Summary')}>
                  <div className={cnNotifiSettingsNeworks('ItemTitle')}>
                    {networks[networkId].title}
                  </div>
                  {!isInternetWorkingGroup && <div className={cnNotifiSettingsNeworks('Item-Summary-Actions')}>
                    <Dropdown
                      options={templates.map((value) => ({ ...value, title: i18n.t('pryaniky.notifications.settings.template.' + value.value) }))}
                      alignItems='center'
                      children={templateName}
                      onClick={(data: any) => changeNotifiSettingsNetworkTemplate({ uid, nid: networkId, data })}
                    />
                    <Button
                      href={`/notifiSettings/${uid}/network/${networkId}`}
                      theme='unstyled'
                      type='rlink'
                    >
                      <Translate i18nKey='pryaniky.notifications.settings.networks.checkDetails' />
                    </Button>
                  </div>}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={cnNotifiSettingsNeworks('PanelDetails ')}>
                <NotifiSettingsGroups networkId={networkId} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )
      }) :
        <NotifiSettingsGroups networkId={networksIds[0]} isSingleNetwork={true} />
      }

    </div>
  )
}

export const NotifiSettingsNeworks = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(NotifiSettingsNeworksPresenter)));