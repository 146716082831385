import {
    call,
    select,
    put,
} from 'utils/src/saga.effects';
import * as AT from '../../../actions/types';
import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors'
import * as ACT from '../../../actions';
import * as SEL from '../../selectors'
import { i18n, Translate } from 'localization';
import { toast } from 'react-toastify';
import { confirmProise } from 'uielements/src/Confirm/Confirm'
import { API } from 'utils/src/utils'


const handleContextActionApprove = function* handleContextActionApprove({ payload }: AT.AContextAction) {
    const { action, id } = payload
    if (action === 'approve' || action === 'disapprove') {
        try {
            const answer = yield* call(confirmProise, {
                text: i18n.t('pryaniky.post.actions.confirm.' + action)
            })

            if (answer === 'cancle') {
                yield* put(ACT.contextActionCancle({
                    id,
                    action
                }))
                return;
            }

            const request = yield* call(API.news.action, id, action === 'approve' ? 5 : 7, '')
            // @ts-ignore
            const result = yield request.r
            if (result.error_code === 0) {
                const news = yield* select(SEL.getNewsById(id))

                const actions = news.actions.map((act: string) => {
                    if (act === 'approve') return 'disapprove'
                    if (act === 'disapprove') return 'approve'
                    return act
                })

                yield* put(ACT.pathNews({
                    id,
                    actions
                }))
                toast.success(result.error_text);
            }
            else toast.error(result.error_text);


        } catch (error) {

        }
    }
    yield;

    return 0;
}

export default handleContextActionApprove