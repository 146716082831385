import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import { mapDispatchToProps } from "../../redux/connector";
import { widgets } from "i.widgets";
import * as widgetsUtils from "widgetsUtils";
import { v1 as uuid } from "uuid";

import { cnIntegrate, IIntegrateProps, IIntegrateState, mapStateToProps, mapActionsToPropsPage, IIntegrateOwnProps } from './Integrate.index';
// import { IWidgetProps } from '../Widget/Widget.index';

import './Integrate.scss';
import { IWidget } from "i.widgets";
import { basename } from 'utils/src/utils';
import { map } from 'react-sortable-tree';
// import { Widget } from 'blocks/Widget/Widget';
import { addWidget } from 'redux/actions/Widgets';

// when rendering these pages component wouldn't ask server for widgets
// const ignoredPages = ['groups', 'users', 'badge', 'tag', 'news', 'rating', 'shop', 'orders']

// const pageName = 'widgets';

declare global {
  interface Window {
    pryanikyApi?: any;
  }
}

export class IntegratePresenter extends React.Component<IIntegrateProps, IIntegrateState> {
  public el: HTMLElement | null;
  public id: string = uuid();

  constructor(props: IIntegrateProps) {
    super(props);
    this.state = {};
    // const scriptv2 = document.createElement('script');
    // scriptv2.setAttribute('src', basename + '/integration.js');
    // document.head.append(scriptv2); 
  }

  public render() {
    const { tag: TagName = 'div', children, className = '', widget, data } = this.props;
    return (
      <div>
        <iframe name={this.id} width="100%" id={this.id} src={`//${window.location.host}${window.location.pathname.indexOf('/NewDesign') !== -1 ? '/NewDesign' : ''}/widgets/list/shop?frame_id=${this.id}`}/>
      </div>
    );
  }

}

export const Integrate = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToPropsPage)
)(IntegratePresenter)
