import { put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
    call,
    select,
} from 'utils/src/saga.effects';

import { toast } from 'react-toastify';
import { i18n, Translate } from 'localization';

import {
  QUESTS_GET_AVAILABLE_QUESTS,
  setAvailableQuests,
  QUESTS_APPROVE_QUEST_GOAL,
  changeQuest
} from './actions';

import { changeListItem } from 'redux/actions/Lists';

import {
  IGetAvailableQuestsAction,
  IApproveQuestGoalAction
} from './actions.interfaces';

import {
  IGetAvailableQuestsResponse,
  IQuestData,
  IApproveQuestGoalResponse,
  IQuestGoal
} from 'utils/src/requests/models/api.quests';

import {
  getAvailableQuestsRequest,
  approveQuestGoalRequest,
  approveQuestGoalRequestNew
} from 'utils/src/requests/requests.quests';

import { checkResponseStatus, getErrorText } from 'utils/src/utils';
import { selectQuests, selectListQuests } from './selectors';
import { IAvailableQuests } from './interfaces';


function* getAvailableQuests(action: IGetAvailableQuestsAction) {
  try {
    const { uId, groupId } = action.payload;
    const response: IGetAvailableQuestsResponse = yield* call(getAvailableQuestsRequest, uId, groupId);
    const data: { [s: string]: IQuestData } = {};
    response.data.forEach(quest => {
      data[quest.id] = quest;
    })
    if (checkResponseStatus(response)) {
      yield put(setAvailableQuests(data));
    } else {
      toast.error(getErrorText(response));
    }
  } catch (e) {
    console.error('get quests error', e);
  }
}

function* approveQuestGoal(action: IApproveQuestGoalAction) {
  try {
    const { questId, id, comment,attachments, listId } = action.payload;
    
    const response: IApproveQuestGoalResponse = yield* call(approveQuestGoalRequestNew, id, comment, attachments);
    if (checkResponseStatus(response)) {
      let quests = yield* select(selectQuests);
      if (listId) quests = (yield* select(selectListQuests(listId))) as typeof quests;
      // else quests = yield* select(selectQuests);
      if (!quests) return ;

      const goals = quests[questId].goals;
      const goalIdx = goals.findIndex(goal => goal.id === id);
      
      const pushedGoals: IQuestGoal[] = [...goals.slice(0, goalIdx), { ...goals[goalIdx], pushed: true }, ...goals.slice(goalIdx + 1)];

      if (listId) yield put(changeListItem({ id: listId, data: { item: questId, data: { goals: pushedGoals } } }));
      else yield put(changeQuest({ questId, data: { goals: pushedGoals } }));
      
      toast.success(i18n.t(`pryaniky.list.users.actions.success`));
    } else {
      toast.error(getErrorText(response));
    }

  } catch (e) {
    console.error('approve quest goal error', e);
  }
}


const quests = function* quests() {
  yield takeLeading(QUESTS_GET_AVAILABLE_QUESTS, getAvailableQuests);
  yield takeLeading(QUESTS_APPROVE_QUEST_GOAL, approveQuestGoal);
}

export default quests;
