import moment from 'moment';
import React, { FC, useMemo, forwardRef, ComponentProps } from 'react';
import { Tooltip } from 'muicomponents/src/Tooltip';
import { styled } from 'muicomponents/src/mui/system';
import { Box } from 'muicomponents/src/Box/Box';
import { i18n, Translate } from 'localization';

const MainBox = styled('div')<{color?: string, borderColor?: string}>(({color ='#ff9635', borderColor = '#fff'}) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: 8,
    backgroundColor: color,
    position: 'absolute',
    top: "1px",
    right: "1px",
    padding: '0px 6px',
    fontSize: 12,
    color: '#fff',
    zIndex: 5
}));

export const PhotoModerationBlock: FC<{
    photoOnModeration: boolean
}> = ({
    photoOnModeration
}) => {
  
    
    if(!photoOnModeration) return null;

    return (
        <Tooltip
            title={<Translate i18nKey={'pryaniky.filter.users.photoonmoderation.tooltip'} />}
        >
            <MainBox>
            <Translate i18nKey={'pryaniky.filter.users.photoonmoderation'} />
            </MainBox>
        </Tooltip>
    );
};