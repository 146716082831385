// import { connect } from 'react-redux';
import * as React from 'react';
import { i18n, Translate } from 'localization';
import { cnListRound, IListRoundProps, IListItem } from './ListRound.index';
import './ListRound.scss';
import { Bable } from 'blocks/Bable/Bable'
import { Icon, Skeleton, Button, Avatar } from 'uielements/src';
import { UserMention } from 'uielements/src/UserMention/UserMention'
import withConfirmDialog from 'blocks/HOCs/Button/withConfirmDialog'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import {IconButton} from 'muicomponents/src/IconButton/IconButton';

const ConfirmBtn = withConfirmDialog(Button)

const sizeMap: any = {
  20: 'xs',
  32: 's',
  40: 'md',
  56: 'lg',
  88: 'xl',
  128: 'xxl',
  184: 'xxxl',
};


const ListRoundPresenter: React.FC<IListRoundProps> = ({
  className,
  cut,
  count = 10,
  items,
  size = 40,
  action,
  onDelete
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const [itemsPerRow, setItemsPerRow] = React.useState(count);

  const wrapperRef = React.useRef<HTMLUListElement>(null);

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [count]);

  const visibleCount = cut && !showAll ? Math.min(count - 1, items.length) : items.length;

  const handleResize = () => {
    if (!wrapperRef.current) return;
    const list = wrapperRef.current;
    const listStyle = window.getComputedStyle(list);
    if (!list.firstChild || !(list.firstChild as HTMLElement).classList.contains(cnListRound('Item'))) return;
    const child = list.firstChild as HTMLElement;
    const listInnerWidth =
      list.clientWidth - parseFloat(listStyle.paddingRight || '0') - parseFloat(listStyle.paddingLeft || '0');
    let maxChildrenInRow = 1;
    // 12 here is hardcode margin for items if you want to change it, don't forget to change margins too
    while (listInnerWidth > (child.offsetWidth + 12) * (maxChildrenInRow + 1)) maxChildrenInRow++;
    setItemsPerRow(maxChildrenInRow);
  };

  const handleClick = () => setShowAll(true);

  const onRemove = (item: IListItem) => () => {
    onDelete && onDelete(item)
  }

  return (
    <ul className={cnListRound({}, [className])} ref={wrapperRef}>
      {!items.length &&
        Array.from({ length: visibleCount }, (_, i) => (
          <Skeleton key={i} className={cnListRound('Item')} shape="circle" size={sizeMap[size]} />
        ))}
      {items.slice(0, visibleCount).map((item, i) => {
        const avatar = <Avatar imgUrl={item.imgUrl} name={item.title} className={cnListRound('Avatar')} size={size} />;
        return (
          <li key={item.id} className={cnListRound('Item')}>
            {item.url ? (
              <UserMention
                id={`${item.id}`}
                url={item.url}
                name={item.title}
                avatar={{
                  imgId: item.imgId,
                  name: item.title,
                  imgUrl: item.imgUrl,
                  size
                }}
                className={cnListRound('Link')}
              />
            ) : (
              avatar
            )}
            {onDelete &&
              <ConfirmBtn
                theme={'unstyled'}
                className={cnListRound('Remove')}
                onConfirm={onRemove(item)}
              >
                <Bable className={cnListRound('RemoveBable')}>
                  <Icon icon={'times'} />
                </Bable>
              </ConfirmBtn>
            }
          </li>
        );
      })}
      {!showAll && visibleCount < items.length && (
        <li className={cnListRound('Item')}>
          <IconButton
            onClick={action || handleClick}
            color={'primary'}
            
            // size={size.toString}
            // onMouseLeave={() => setDeleteActive(false)}
            className={cnListRound('More')}
          >
            {/* <Button
            onClick={action || handleClick}
            style={{ width: size, height: size, fontSize: Math.round((size * 1) / 2), lineHeight: size + 'px' }}
            title={i18n.t('Show all')}
            theme="unstyled"
            className={cnListRound('More')}> */}
            <MoreHorizOutlinedIcon />
          </IconButton>
        </li>
      )}
      {Array.from({ length: itemsPerRow - (visibleCount % itemsPerRow) - 1 }, (_, i) => (
        <li key={i} className={cnListRound('Item')} style={{ width: size }} />
      ))}
    </ul>
  );
};

export const ListRound = ListRoundPresenter;
