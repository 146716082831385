import * as React from 'react';
import { Calendar } from "../Events/Calendar/Calendar";
import { ITasksProps, ITasksState, TStateProps } from './Tasks.index';
import { TasksInfo } from './TasksInfo/TasksInfo';
import { TasksIcon } from './TasksIcon/TasksIcon';
import { mapStateToProps } from './Tasks.index';
import { connect } from 'react-redux';
import { CUSTOM_FIELDS_TYPES_NAMES, customFieldsTypesNamesNumbersDiff } from 'utils/src/constants.prn';
import { CheckboxInput } from 'uielements/src';
import { DReadItem } from "./TaskDocumentReadBlock/Item/TaskDocumentReadBlock-Item";
import { i18n, Translate } from 'localization';
import { Attachments } from 'uielements/src/Attachments/Attachments';
import { withNewsContext } from 'News/contexts/news'
import MDRender from 'uielements/src/CommonmarkRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import TextRender from 'News/containers/TextRender'

export class TasksPresenter extends React.Component<ITasksProps & TStateProps, ITasksState> {
  public render() {
    const { forms, isSingle, text, expires = (new Date).toISOString(), header } = this.props;
    return <NewsView className={'type-8'}>
      <div className="Tasks-Header">
        <TasksInfo expires={new Date(expires)} title={header} />
      </div>
      <ContentLayout>
        <TextRender />
      </ContentLayout>
      {
        forms.map(form => {
          switch (form.fieldType) {
            case CUSTOM_FIELDS_TYPES_NAMES.TaskCheckBlock || customFieldsTypesNamesNumbersDiff.TaskCheckBlock: {
              return <CheckboxInput checked={false} text={form.name} />
            }
            case CUSTOM_FIELDS_TYPES_NAMES.DocumentReadBlock || customFieldsTypesNamesNumbersDiff.DocumentReadBlock: {
              return (
                <>
                  <div children={i18n.t('pryaniky.post.tasks.filesToRead')} />
                  <Attachments files={form.attachments ? form.attachments : []} state='view' />
                </>
              )
            }
            default:
              return null
          }
        })
      }
    </NewsView>
  }
}

export const Tasks = withNewsContext(connect(
  mapStateToProps,
  {}
)(TasksPresenter));

export const initTasks = () => ComponentInjector.getInstance().addNode('news_types', <Tasks />, 'tasks')