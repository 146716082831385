import { IEditorProps } from './Editor.index'
import * as React from 'react'
import './Editor.scss';
// import { TextareaMentionsEditor } from '../../../TextareaMentions/TextareaMentions'
import { i18n, Translate } from 'localization';
import { PryanikyEditorContexted as PryanikyEditor } from '../../../PryanikyEditor/_PryanikyEditor'
import { PrnUserInput } from 'blocks/PrnUserInput/PrnUserInput'
import { Select } from 'uielements/src'
import { IOption } from 'uielements/src/Select/Select.index'


export const Editor: React.FC<IEditorProps> = ({ children, data, onChangeText, onChangeGroup, onChangeNetwork, networks, ...props }) => {
    const option: IOption[] = networks.filter((n: any) => n.roles.includes('SiteAdmin')).map<IOption>((network: any) => ({ id: network.id, value: network.id, title: network.title }))
    const selectedNetwork = option.find(o => data.network === o.value)

    if (!selectedNetwork && option.length === 1) {
        onChangeNetwork && onChangeNetwork(option[0])
    }
    return <div className={'Editor EditorNews'}>

        {(option.length > 1 && onChangeNetwork) && <Select
            placeholder={'Выберите сеть'}
            options={option}
            selected={selectedNetwork}
            onChange={onChangeNetwork} />}

        {onChangeGroup && <PrnUserInput
            hideDialog={true}
            maxSelect={1}
            placeholder={'Выберите группу'}
            excludeUsers={[]}
            type={'mygroups'}
            network={selectedNetwork ? selectedNetwork.id : undefined}
            onChange={onChangeGroup}
            value={data.group ? [data.group] : []}
        />}

        <PryanikyEditor
            // getClassRef={refGetter}
            //ref={forwardRef as any}
            // users={true}
            // tags={true}
            onChange={onChangeText}
            // className={''}
            // icon={'edit'}
            value={data.text}
            disableToolbar={true}
            placeholder={i18n.t('pryaniky.createPost.news.text')}
        />
        {children}
    </div>
}