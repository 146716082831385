import React, { FC, useState, createRef } from 'react';
import { InputLayout } from '../InputLayout/InputLayout'
import { LineInput } from '../LineInput/LineInput'
import { IThanksInputProps } from './ThanksInput.index'
// import { Button, Input, Icon, Dialog, DialogTitle, CustomScrollbars, DialogContent, CheckboxInput } from 'uielements/src';
import { i18n, Translate } from 'localization';
import './ThanksInput.scss'
import { Button, Icon } from 'uielements/src'
const inputRef = createRef<HTMLInputElement>();

export const _ThanksInput: FC<any & IThanksInputProps> = ({ icon, onChange, value, min, max, currencyNamePlural = 'Пряники', currencyName = 'Пряник' }, ref) => {
    const [focused, setFocused] = useState(false);

    const changeValue = (value: string | number | undefined) => {
        if (!value) return onChange(0)
        if (value === '') return onChange(0)
        if (value < min) return onChange(min)
        if (value > max) return onChange(max)
        onChange(value)
    }
    const onChangeValue = (e: any) => {
        let count: number | undefined = parseInt(e.target.value)
        count = isNaN(count) ? undefined : count
        changeValue(count)
    }
    const valueUp = () => changeValue(value+1)
    const valueDown = () => changeValue(value-1)

    const width = (value.toString().length * 15) + 10;

    return (
        <InputLayout
            // action={}
            focus={focused}
            ref={ref}
            icon={icon}
        >
            <div className='ThanksSelector-InputBox' onClick={() => inputRef.current!.focus()}>
                <div className='ThanksSelector-Reward'>{i18n.t('reward') + ':'}</div>
                <LineInput
                    className={'ThanksSelector-Input'}
                    ref={inputRef}
                    type='number'
                    style={{ width }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChange={onChangeValue}
                    value={parseInt(value).toString()} />
                <div className={'ThanksSelector-ArrowBox'}>
                    <Button onClick={valueUp} theme={'unstyled_center'} className={'ThanksSelector-ArrowBtn'}><Icon icon={'angle-up'} /></Button>
                    <Button onClick={valueDown} theme={'unstyled_center'} className={'ThanksSelector-ArrowBtn'}><Icon icon={'angle-down'} /></Button>
                </div>
                <div className='ThanksSelector-Desc'>{value !== 1 ? currencyNamePlural : currencyName}</div>
            </div>
        </InputLayout>
    )
}

export const ThanksInput = React.forwardRef(_ThanksInput);