import * as React from 'react';
import * as utils from 'utils/src/utils';

import WidgetPresenter from '../Widget';
import { cnWidget, IWidgetProps } from '../Widget.index';
import { widgets } from "i.widgets";

import { useSelector } from 'react-redux'

import { PageBreadcrumbs } from 'blocks/Pages/PageBreadcrumbs/PageBreadcrumbs'

const type = 'breadcrumbs';

const FunCom = ({ currentPageId, settings }: any) => {
  const s = useSelector((state: any) => state.widgets.pages[currentPageId])
  if(!s) return null;
  return <>
    <PageBreadcrumbs dt={s} isDraftState={false} matchId={s.id} hideDate={settings.hideDate}/>
  </>
}

export default class WidgetTypeBreadcrumbsPresenter extends WidgetPresenter<IWidgetProps> {
  public render() {
    if (!this.props.widget) return null;
    const subtype = utils.widgetSubtype(this.props.widget.type);
    let { tag: TagName = 'div' } = this.props;
    // console.log('Breadcrumbs', this.props)

    if (this.props.edit)
      return (
        <widgets.components.common {...this.props} className={cnWidget({ type })}>
          <FunCom currentPageId={this.props.wcontext.currentPageId} settings={this.props.widget.settings} />
        </widgets.components.common>
      );

    return <TagName className={cnWidget({ type, subtype })}><FunCom currentPageId={this.props.wcontext.currentPageId} settings={this.props.widget.settings} /></TagName>
  }
}