import { IIdeaEvaluationtProps } from './IdeaEvaluation.index';
import * as React from 'react'
import './IdeaEvaluation.scss'
import { AdditionalFieldsRender } from '../AdditionalFieldsRender/AdditionalFieldsRender';
// import { loadFields } from '../IdeasSides';
import { i18n, Translate } from 'localization';
// import { saveIdeaEvaluation } from '../IdeasSides';
import { ButtonPopover } from '../../common/ButtonPopover/ButtonPopover';
import { ActionButtons } from '../../../ActionButtons/ActionButtons';
import { IdeaFields } from '../Fields/Types'
import { IdeasValidator } from '../Ideas.validate';
import { denormalizeData } from '../Utils';
import { useLazyQuery } from 'utils/src/hooks'
import {
    API
} from 'utils/src/utils'
import { denormalize, normalize, schema } from 'normalizr';
import { statusFieldsExtractor } from '../Utils'

const vb = new IdeasValidator();

export const IdeaEvaluation: React.FC<IIdeaEvaluationtProps> = ({ ideaId, statusID, anchor, ...props }) => {
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [
        openAction,
        isLoading,
        value,
        isError,
        count,
        setValue,
    ] = useLazyQuery(async function () {
        return await (await API.news.ideas.setStatus(ideaId, statusID)).r
    }, statusFieldsExtractor)
    const [
        save,
        isSaving
    ] = useLazyQuery(async function (fields: any) {
        return await (await API.news.ideas.setIdeaExpertise(ideaId, statusID, { fields })).r
    })

    const onSave = () => {
        if (value) {
            const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
            const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

            const denormalizedData = denormalize(value.result, [categorys], value.entities);
            save(denormalizedData)
        }
    }

    const onChange = (field: IdeaFields) => {
        setValue((s: any) => {
            s.entities.fields[field.fieldId] = field;
            return { ...s };
        })
    }

    React.useEffect(() => {
        if (value) {
            const isValid = !vb.ideasAdditionalFieldsValuesValidate(denormalizeData(value)).length;
            setIsValid(isValid);
        }
    }, [value]);

    return (
        <ButtonPopover anchor={anchor} label={i18n.t('pryaniky.idea.evaluation')} isLoading={isLoading || isSaving} accepted={onSave} openAction={openAction}>
            {value ? (close, accepted) =>
                <div>
                    <AdditionalFieldsRender data={value ? value : []} onChange={onChange} />
                    <ActionButtons saveDisabled={!isValid} save={accepted} cancle={close} />
                </div>
                : () => <div>{isError && 'error loading fields'}</div>}
        </ButtonPopover>
    );
}