import React, { FC, useState, memo, Suspense, useCallback, useEffect } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    IMeetingViewPresenterProps as IPresenterProps,
    IMeetingViewOwnProps as IOwnProps,
    IMeetingViewDispatchProps as IDispatchProps,
    IMeetingViewStateProps as IStateProps,
    IMeetingViewState as IState
} from './interfaces'
import { connect } from 'react-redux';
import './style.scss'
import FloatBox from 'uielements/src/FloatBox'
import { Button } from 'uielements/src'

// import(/* webpackIgnore: true */ 'https://meet.pryaniky.com/external_api.js' as any).then(module => console.log(module.default));

const Jitsi = React.lazy(() => import('react-jitsi'));

const FULL_SCREEN = {
    width: '100%',
    height: '100%'
}

const SUB_SCREEN = {
    width: '400px',
    height: '300px'
}

const BOX_SUB_SCREEN = {
    width: '400px',
    height: '300px'
}

const BOX_FULL_SCREEN = {
    width: '100%',
    height: '100%'
}

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    meeting,
    currentUser,
    setMeeting
}) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [JitsiMeetAPI, setJitsiMeetAPI] = useState<any>(null)
    useEffect(() => {
        if (JitsiMeetAPI) {
            JitsiMeetAPI.executeCommand('subject', meeting.eventTitle || ' ');
            JitsiMeetAPI.addEventListener('readyToClose', () => {
                // JitsiMeetAPI.removeEventListener('readyToClose');
                setMeeting({meeting: null})
                // setJitsiMeetAPI(null)
            });
        }
        // return () => {
        //     if (JitsiMeetAPI) {
        //         JitsiMeetAPI.removeEventListener('readyToClose');
        //         setJitsiMeetAPI(null)
        //     }
        // }
    }, [JitsiMeetAPI])
    const size = fullScreen ? FULL_SCREEN : SUB_SCREEN
    const boxSize = fullScreen ? BOX_FULL_SCREEN : BOX_SUB_SCREEN
    const handleAPI = useCallback((JitsiAPI: any) => setJitsiMeetAPI(JitsiAPI), [meeting])
    if (!meeting) return null
    return <FloatBox size={boxSize}>
        <div style={boxSize} className={cnClassName({}, [className])}>
            {/*!JitsiMeetAPI && <div className={cnClassName('Controls')}>
                <Button main onClick={() => setMeeting(null)} >{'Закрыть'}</Button>
</div> */}
            <div className={cnClassName('Jitsi')}>
                <Suspense fallback={<div>Загрузка...</div>}>
                    <Jitsi
                        config={{
                            defaultLanguage: 'ru'
                        }}
                        interfaceConfig={{
                            LANG_DETECTION: false,
                            JITSI_WATERMARK_LINK: `//${window.location.host}/news/${meeting.meetId}`,
                            TOOLBAR_BUTTONS: [
                                'fullscreen', 'microphone', 'camera', 'closedcaptions', 'desktop',
                                'fodeviceselection',
                                'livestreaming', 'settings', 'hangup',
                                'videoquality', 'filmstrip', 'invite', 'shortcuts',
                                'mute-everyone'
                            ],
                            SHOW_JITSI_WATERMARK: false,
                            SHOW_WATERMARK_FOR_GUESTS: false,
                            SUPPORT_URL: 'https://pryaniky.com/',
                            VIDEO_QUALITY_LABEL_DISABLED: true,
                            LIVE_STREAMING_HELP_LINK: 'https://pryaniky.com/',
                            DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow',
                            APP_NAME: 'Pryaniky'
                        }}
                        containerStyle={size}
                        domain={meeting.domain}
                        roomName={meeting.meetId}
                        onAPILoad={handleAPI}
                        displayName={currentUser.baseData.displayName} />
                </Suspense>
            </div>
        </div>
    </FloatBox >
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(memo(Presenter));
