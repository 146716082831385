import { channel } from 'redux-saga';
import { all, fork, putResolve, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { call, put, select } from 'utils/src/saga.effects';

import { getCurrentUser } from 'utils/src/CommonRedux/base/selectors';

import { normalizeNews, normalizeReplys, toggleAction } from './utils';

import { changeCurrentThanksCount } from 'utils/src/CommonRedux/users/actions';

import { v1 as uuid } from 'uuid';

import actions from '../actionsTypes/News';

import { EditorState } from 'draft-js';

import { createDefaultNews, IBaseNews } from '../../types/baseNews';
import { createDefaultReply } from '../../types/reply';

import * as ACT from '../actions';

import queryString from 'query-string';

import { getCurrentGroup, getLastNewsId, getNewsById, getReplyFormById } from './selectors';

import * as AT from '../actions/types';

import { API } from 'utils/src/utils';

import { omit } from 'lodash';

import { i18n } from 'localization';

import { toast } from 'react-toastify';

import { addMultiMention } from 'blocks/PryanikyEditor/MentionPlugin/modifiers/addMention';

import creativetasksSaga from 'blocks/NewsTypes/Creativetasks/redux/saga';
import eventSaga from 'blocks/NewsTypes/Events/redux/saga';
import ideasSaga from 'blocks/NewsTypes/Ideas/redux/saga';
import pollsSaga from 'blocks/NewsTypes/Polls/redux/saga';
import thanksSaga from 'blocks/NewsTypes/Thanks/redux/saga';
import workflowsSaga from 'blocks/NewsTypes/Workflows/redux/saga';

// validate rules to news types

// import { NewsValidateRules } from 'News/types/News/News.validate';
// import { ThanksValidateRules } from 'News/types/Thanks/Thanks.validate';
// import { AchievementsValidateRules } from 'News/types/Achievements/Achievements.validate';
// import { BadgesValidateRules } from 'News/types/Badges/Badges.validate';
// import { NoticesValidateRules } from 'News/types/Notices/Notices.validate';
// import { PollsValidateRules } from 'News/types/Polls/Polls.validate';
// import { CreativeTasksValidateRules } from 'News/types/CreativeTasks/CreativeTasks.validate';
// import { EventsValidateRules } from 'News/types/Events/Events.validate';

// import { IdeasValidateRules } from 'News/types/Ideas/Ideas.validate';
// import { converAdditionalFieldsValuesV1ToAdditionalFieldsValues } from 'News/types/Ideas/Ideas.sides';

// end validate rules to news types

import * as SEL from './selectors';

import contextSagas from './ContextActions';

import { withProgressIndicator, withSagaIndicator } from 'utils/src/CommonRedux/LoadIndicator';

import { LOCATION_CHANGE } from 'connected-react-router';

const handleLoadFullReply = function* (action: AT.ALoadFullReply) {
    return yield* withSagaIndicator(function* ({ payload }: AT.ALoadFullReply) {
        try {
            const { newsUid, replyUid } = payload;

            const result: any = yield* call(API.news.getFullReply, newsUid, replyUid);

            if (result.error_code === 0) {
                yield put(ACT.pathReply(result.data));
                payload.cb?.();
            } else {
                console.warn(result);
                throw new Error('requestError');
            }
        } catch (error) {
            console.warn(error);
            throw new Error('unknownError');
        }

        return 0;
    }, actions.LOAD_FULL_REPLY + '-' + action.payload.replyUid)(action);
};

const handleLoadNewsList = function* (action: AT.ALoadNewsList) {
    return yield* withSagaIndicator(function* ({ payload }: AT.ALoadNewsList) {
        try {
            const { count, params, skipCount, disableSetGroup, isSearchPage } = payload;
            // console.log("isSearchPage",isSearchPage)
            const withOutCount = omit(params, 'count');
            const group = queryString.stringify(withOutCount);

            let lastObjectId: ReturnType<ReturnType<typeof getLastNewsId>> = yield* select<
                ReturnType<typeof getLastNewsId>
            >(getLastNewsId(group));
            const request = isSearchPage ? API.news.getSearchNewsList : API.news.getNewsList;
            // const request = params.searchText ? API.news.getSearchNewsList : API.news.getNewsList;
            // if skipCount is zero and lastObjectId is defined then need load new news and need clear keys
            if (skipCount === 0 && lastObjectId) {
                yield put(ACT.setNewsList({ commentsKeys: [], commentsValues: {}, keys: [], values: {}, group }));
                lastObjectId = undefined;
            }
            const result: any = yield* call<typeof request>(request, skipCount, count, { ...params, lastObjectId });

            if (result.error_code === 0) {
                yield put(ACT.changeField({ field: 'quantity_search_result', value: result.quantity_search_result }));

                yield putResolve(ACT.changeStatusField({ group: withOutCount, field: 'count', value: count }));
                if (!disableSetGroup) {
                    yield put(ACT.changeField({ field: 'currentGroup', value: group }));
                }
                yield put(ACT.appendNewsList({ ...normalizeNews(result.data), group }));
            } else {
                console.warn(result);
                throw new Error('requestError');
            }
        } catch (error) {
            console.warn(error);
            throw new Error('unknownError');
        }

        return 0;
    }, actions.LOAD_NEWS + '-' + queryString.stringify(omit(action.payload.params, 'count')))(action);
};

const handleSendLikeNews = function* handleSendLikeNews({ payload }: AT.ASendLikeNews) {
    try {
        const { id, likeCount, liked } = payload;
        yield put(
            ACT.pathNews({
                id,
                likeCount: liked ? likeCount - 1 : likeCount + 1,
                liked: !liked,
            })
        );

        const request = yield* call(API.news.action, id, 22, !liked);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
            yield put(
                ACT.pathNews({
                    id,
                    likeCount: result.data.likeCount,
                    liked: result.data.liked,
                })
            );
        } else {
            yield put(
                ACT.pathNews({
                    id,
                    likeCount: likeCount,
                    liked: liked,
                })
            );
        }
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

export const handleLoadComments = function* handleLoadComments({ payload }: AT.ALoadComments) {
    const { commentsCount, id, all = false } = payload;
    try {
        yield put(ACT.toggleLoadReply({ id }));

        const news = yield* select(getNewsById(id));
        // const skipCount = news.comments.length === 0 ? 0 : news.comments.length - 5
        const request = yield* call(API.reply.getAll, id, !all && { skipCount: news.comments.length, count: 10 });
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
            yield put(
                ACT.prependComments({
                    id,
                    ...normalizeReplys(result.data),
                })
            );
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
    yield put(ACT.toggleLoadReply({ id }));

    return 0;
};

const handleDeleteNews = function* handleDeleteNews({ payload }: AT.ADeleteNews) {
    try {
        const { id } = payload;

        yield put(ACT.unsetNews({ id }));
        yield put(
            ACT.pathNews({
                id,
                newstype: 16001,
                componentRenderName: 'removed_placeholder',
            })
        );

        const request = yield* call(API.news.action, id, 1, true);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleHideNews = function* handleHideNews({ payload }: AT.AHideNews) {
    try {
        const { id } = payload;
        yield put(ACT.unsetNews({ id }));

        const request = yield* call(API.news.action, id, 0, true);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleAddToAnnouncement = function* handleAddToAnnouncement({ payload }: AT.AAddToAnnouncement) {
    try {
        const { id, actionId } = payload;

        const news: IBaseNews = yield* select(getNewsById(id));
        const actions = toggleAction(news.actions, actionId);

        const isShowOnTop = !news.isShowOnTop;

        yield put(
            ACT.pathNews({
                id,
                isShowOnTop,
                actions,
            })
        );
        const request = yield* call(API.news.action, id, actionId);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
        } else {
            yield put(
                ACT.pathNews({
                    id,
                    isShowOnTop: news.isShowOnTop,
                    actions: news.actions,
                })
            );
        }
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleUpdateNews = withProgressIndicator(function* handleUpdateNews({
    payload,
    result: cb,
}: AT.AUpdateNews): any {
    try {
        const { id } = payload;

        const news = yield* select(getNewsById(id));
        const merged = { ...news, ...payload };

        const serverModel = omit(merged, ['comments']);

        const request = yield* call(API.news.post, serverModel, true);
        const result = yield request.r;

        yield put(
            ACT.setUpdateError({
                id,
                error_code: result.error_code,
                error_text: result.error_text || result.error_message,
            })
        );

        let params = {};
        if (news?.achievement) {
            params = { achievement: { ...result.achievement, achievementOnCorrect: false } };
        }

        if (result.error_code === 0) {
            cb(true);
            yield put(ACT.pathNews({ ...merged, text: result.data.text, header: result.data.header, ...params }));

            yield put(ACT.toggleEdit({ id }));
        } else {
            cb(false);
        }
    } catch (error) {
        console.warn(error);
        throw error;
    }
    yield;

    return 0;
});

const handleSendReply = function* handleSendReply({ payload }: AT.ASendReply) {
    const { newsId, reply } = payload;
    try {
        yield put(ACT.toggleSendReply({ id: newsId }));

        const request = yield* call(API.reply.post, newsId, reply);
        // @ts-ignore
        const result = yield request.r;

        if (result.error_code === 0) {
            yield put(ACT.toggleThank({ id: newsId, value: false }));
            yield put(
                ACT.addComments({
                    id: newsId,
                    commentsKeys: [result.data.id],
                    commentsValues: { [result.data.id]: result.data },
                })
            );

            const news = yield* select(getNewsById(newsId));

            yield put(
                ACT.pathNews({
                    id: newsId,
                    commentsCount: news.commentsCount + 1,
                })
            );

            const currentUser = yield* select(getCurrentUser);

            yield put(
                ACT.changeReplyForm({
                    newsId,
                    reply: {
                        ...createDefaultReply(),
                        id: uuid(),
                        user: currentUser.baseData,
                        text: EditorState.createEmpty(),
                        thanksInfo: undefined,
                        users: undefined,
                    },
                })
            );
        }
    } catch (error) {
        console.warn(error);
    }
    yield put(ACT.toggleSendReply({ id: newsId }));

    return 0;
};

const handleLoadNewsById = withProgressIndicator(function* handleLoadNewsById({ payload }: AT.ALoadNewsById): any {
    try {
        const { id, network } = payload;

        const request = yield* call(API.news.byId, id, { network });
        const result = yield request.r;

        switch (result.error_code) {
            case 0:
                yield put(ACT.setNewsList({ ...normalizeNews(result.data), group: 'single' }));
                break;
            case 16001:
                let removedNews = createDefaultNews();
                removedNews.newstype = 16001;
                removedNews.componentRenderName = 'removed_placeholder';
                removedNews.id = id;
                removedNews.text = 'deleted';
                yield put(ACT.setNewsList({ ...normalizeNews([removedNews]), group: 'single' }));

                break;
        }
    } catch (error) {
        console.warn(error);
    }

    yield;

    return 0;
});

const handleDeleteReply = function* handleDeleteReply({ payload }: AT.ADeleteReply) {
    try {
        const { newsId, replyId } = payload;

        yield put(ACT.unsetReply(payload));

        const request = yield* call(API.reply.action, newsId, replyId, 1, true);
        // @ts-ignore
        const result = yield request.r;

        if (result && result.error_code === 0) {
        }
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleSendLikeReply = function* handleSendLikeReply({ payload }: AT.ASendLikeReply) {
    try {
        const { likeCount, replyId, newsId, liked } = payload;

        yield put(
            ACT.pathReply({
                id: replyId,
                likeCount: liked ? likeCount - 1 : likeCount + 1,
                liked: !liked,
            })
        );

        const request = yield* call(API.reply.action, newsId, replyId, 22, !liked);
        // @ts-ignore
        const result = yield request.r;

        if (result.error_code === 0) {
        } else {
            yield put(
                ACT.pathNews({
                    id: replyId,
                    likeCount: likeCount,
                    liked: liked,
                })
            );
        }
    } catch (error) {}
    yield;

    return 0;
};

const handleAddInvest = function* handleAddInvest({ payload }: AT.AAddInvest) {
    try {
        const { id, value } = payload;
        const user = yield* select(getCurrentUser);
        const thanksForAll = user.extData.thanksForAll;
        if (thanksForAll < value) return;

        yield put(changeCurrentThanksCount(value));

        const request = yield* call(API.news.setInvest, id, value);
        // @ts-ignore
        const result = yield request.r;

        if (result.error_code === 0) {
            // toast.success('Валюта отправлена');
        } else {
            yield put(changeCurrentThanksCount(value, true));
            // toast.error('Ошибка! Валюта не отправлена');
        }
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleMoveToGroup = function* handleMoveToGroup({ payload }: AT.AMoveToGroup) {
    try {
        const { group, newsId } = payload;

        yield put(
            ACT.pathNews({
                id: newsId,
                group,
            })
        );

        const request = yield* call(API.news.action, newsId, 8, group.pkid);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
            // toast.success(result.error_text);
        }
        // else toast.error(result.error_text);
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleUpdateReply = function* handleUpdateReply({ payload }: AT.AUpdateReply) {
    try {
        const { newsId, reply } = payload;

        yield put(ACT.pathReply(reply));

        const request = yield* call(API.reply.post, newsId, reply, true);
        // @ts-ignore
        const result = yield request.r;
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

/**
 * @deprecated
 * use handleSendNews below
 * @param param0
 * @returns
 */
const __handleSendNews = function* handleSendNews({ payload, result: cb }: AT.ASendNews) {
    try {
        const currentGroup = yield* select(getCurrentGroup);

        yield put(ACT.changeField({ field: 'sending', value: true }));

        const request = yield* call(API.news.post, payload, false);
        // @ts-ignore
        const result = yield request.r;

        yield put(
            ACT.pathCreator({
                sendError: {
                    error_code: result.error_code || '-2',
                    error_text: result.error_text || 'unknown error',
                    error_message: result.error_message || 'unknown error',
                },
            })
        );

        if (result.error_code === 0) {
            cb(true);
            yield put(
                ACT.pathCreator({
                    clearEditor: true,
                })
            );
            yield put(ACT.reset('newsCreator'));
            yield put(ACT.prependNewsList({ ...normalizeNews(result.news), group: currentGroup }));

            const userCount = (payload.groups || []).reduce(
                (acc, cur) => acc + (cur.usersCount || 0),
                payload.users?.length || 0
            );
            const count = payload.thanksCount * userCount;
            yield put(changeCurrentThanksCount(count));
            // проверка для селигдара по тикету COMMON-8078 "Исчезают публикации пользователя под модерацией после обновления страницы"
            if (result.data.group?.visibilityType === 1 && result.data.isNeedApprove && result.data.group) {
                toast.success(i18n.t('pryaniky.toast.success.post.achivment.in.hidden.group'));
            }
        } else {
            cb(false);
        }
    } catch (error) {
        console.warn(error);
        cb(false);
    }
    yield put(ACT.changeField({ field: 'sending', value: false }));

    return 0;
};

const handleInsertMention = function* handleInsertMention({ payload }: AT.AInsertMention) {
    try {
        const { mention, newsId } = payload;

        const form = yield* select(getReplyFormById(newsId));

        const withMention = addMultiMention(form.text, mention, '', '', 'SEGMENTED');

        yield put(
            ACT.changeReplyForm({
                newsId,
                reply: {
                    text: withMention,
                },
            })
        );
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const getWorkflowStatuses = function* getWorkflowStatuses({ payload }: AT.ALoadWorkflowStatuses) {
    try {
        const { workflowId } = payload;
        const response: any = yield* call(API.workflows.getAllStatuses, workflowId) as any;
        const data = response.data.map((status: any) => ({ id: status.id, title: status.name }));
        yield put(ACT.changeField({ field: 'statuses', value: { [workflowId]: data } }));
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

export function* newsClearReadedBennres() {
    try {
        yield put(ACT.newsBannersClearReaded());
    } catch (error) {
        console.warn(error);
    }
    return 0;
}

///////////

function* takeLoadNewsList() {
    const chan = yield* call(channel);
    const aciveGroups: string[] = [];

    function* handlerLoadNewsList(chan: any) {
        const action: AT.ALoadNewsList = yield take(chan);
        const group = queryString.stringify(omit(action.payload.params, 'count'));
        if (!aciveGroups.includes(group)) {
            aciveGroups.push(group);
            yield* call(handleLoadNewsList, action);
            aciveGroups.splice(aciveGroups.indexOf(group), 1);
        }
    }

    while (true) {
        const action: AT.ALoadNewsList = yield take(actions.LOAD_NEWS);

        yield put(chan, action);

        yield fork(handlerLoadNewsList, chan);
    }
}

const handleRejectAchievement = function* handleRejectAchievement({ payload }: AT.ARejectAchievement) {
    try {
        const { id, comment, needSendNotification } = payload;

        // yield put(ACT.pathNews({
        //   id: newsId,
        //   group
        // }))

        const request = yield* call(API.news.rejectAchievmentRequest, id, comment, needSendNotification);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
            const news = yield* select(SEL.getNewsById(id));

            const actions = news.actions.filter((act: string) => act !== 'rejectAchievement');

            yield* put(
                ACT.pathNews({
                    id,
                    actions,
                })
            );
            toast.success(result.error_text);
        } else toast.error(result.error_text);
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

const handleSubmitForCorrection = function* handleSubmitForCorrection({ payload }: AT.ASubmitForCorrection) {
    try {
        const { id, comment, needSendNotification } = payload;

        const request = yield* call(API.news.toCorrectionAchievmentRequest, id, comment, needSendNotification);
        // @ts-ignore
        const result = yield request.r;
        if (result.error_code === 0) {
            const news = yield* select(SEL.getNewsById(id));
            const actions = news.actions.filter((act: string) => act !== 'submitForCorrection');

            yield* put(
                ACT.pathNews({
                    id,
                    actions,
                    achievement: { ...result.achievement, achievementOnCorrect: true },
                })
            );
            toast.success(result.error_text);
        } else toast.error(result.error_text);
    } catch (error) {
        console.warn(error);
    }
    yield;

    return 0;
};

/**
 * module root saga
 */
const root = function* root() {
    yield takeLeading(actions.LIKE_NEWS, handleSendLikeNews);
    yield takeLeading(actions.LOAD_COMMENTS, handleLoadComments);
    yield takeLeading(actions.DELETE_NEWS, handleDeleteNews);
    yield takeLeading(actions.HIDE_NEWS, handleHideNews);
    yield takeLeading(actions.ADD_TO_ANNOUNCEMENT, handleAddToAnnouncement);
    yield takeLeading(actions.UPDATE_NEWS, handleUpdateNews);
    yield takeLeading(actions.SEND_REPLY, handleSendReply);
    yield takeEvery(actions.LOAD_NEWS_BY_ID, handleLoadNewsById);
    yield takeLeading(actions.DELETE_REPLY, handleDeleteReply);
    yield takeLeading(actions.LIKE_REPLY, handleSendLikeReply);
    yield takeLeading(actions.ADD_INVEST, handleAddInvest);
    yield takeLeading(actions.MOVE_TO_GROUP, handleMoveToGroup);
    yield takeLeading(actions.UPDATE_REPLY, handleUpdateReply);
    yield takeLeading(actions.SEND_NEWS, __handleSendNews);
    yield takeLeading(actions.INSERT_MENTION, handleInsertMention);
    yield takeLeading(actions.LOAD_FULL_REPLY, handleLoadFullReply);
    yield takeEvery(actions.LOAD_WORKFLOW_STATUSES, getWorkflowStatuses);
    yield takeEvery(actions.REJECT_ACHIEVMENT, handleRejectAchievement);
    yield takeEvery(actions.SUBMIT_FOR_CORRECTION, handleSubmitForCorrection);
    yield takeEvery(LOCATION_CHANGE, newsClearReadedBennres);

    yield all([
        takeLoadNewsList(), // как takeLeading но с учетом params

        contextSagas(),

        eventSaga(),
        pollsSaga(),
        creativetasksSaga(),
        thanksSaga(),
        workflowsSaga(),
        ideasSaga(),
    ]);
};

/**
 * export root saga
 */
export default root;
