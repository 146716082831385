import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';

import { mapDispatchToProps } from 'redux/connector';
import { toast } from 'react-toastify';
import { mapStateToProps, mapActionsToProps } from './../../List.index';
import { Header } from '../../Header/List-Header';
import { Item } from '../../Item/List-Item';
import './List_type_groups_tree_selector.scss';
import { ListLite } from 'uielements/src';
import { i18n, Translate } from 'localization';
// import { ListGroupsTreeSelectorPresenter } from './List_type_groups_tree_selector';
import { ListGroupsSelectorPresenter } from './List_type_groups_selector';

export class ListGroupsTreeSelectorInvitePresenter extends ListGroupsSelectorPresenter {
  public invite = (gid: any) => {
    this.props.removeDataFromList({ id: this.listId, data: [gid] })
    utils.API.groups.inviteGroups(this.props.context.groupId, [gid], true)
  }

  public multiInvite = (ids: number[]) => () => {
    utilsProj.confirm({
      text: i18n.t('pryaniky.list.groups.confirm.invite', { count: ids.length }),
      onConfirm: () => {
        utils.API.groups.inviteGroups(this.props.context.groupId, ids, true).r.then((response: any) => {
          if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
          else if (response.error_code) toast.error(response.error_text);
          else {
            this.props.removeDataFromList({ id: this.listId, data: ids.map(v => v.toString()) })
            toast.success(i18n.t(`success`));
            // this.setState(prevState => ({ data: prevState.data.filter(group => !ids.includes(group.pkid)) }));
          }
        });
      },
    });
  };

  public prepareData(el: any) {
    super.prepareData(el);
    el.actions_data = {
      options: [{
        title: i18n.t('pryaniky.list.users.actions.invite'),
        buttonProps: {
          onClick: () => {
            this.invite(el.id);
          },
        },
      }]
    };///[el.description]
  }

  public renderChildren = () => {
    // const { isFinished, isLoading, selected } = this.state;
    // const { data } = this.props;
    const { data, isFinished, isLoading, selected } = this.props;
    // const data: any[] = this.state[this.dataVariable];
    const selectedIds: number[] = selected.map((el: string) => Number(el));
    return (
      <React.Fragment>
        <Header
          className={'rounded-top'}
          type={'common'}
          search={{
            placeholder: i18n.t('pryaniky.list.groups.search'),
            onChange: this.search,
          }}
          /*actions={{
            options: [
              {
                title: i18n.t('pryaniky.list.groups.actions.create'),
                buttonProps: {
                  onClick: this.createGroup,
                },
              },
            ],
          }}*/
          counter={{
            all: {
              title: i18n.t('pryaniky.list.groups.title'),
              count: data.length,
              action: () => this.props.checkAllListItem({ id: this.listId, data: null }),
            },
            selected: {
              title: i18n.t('pryaniky.list.selected', { count: selected.length }),
              count: selected.length,
              action: {
                options: [
                  {
                    title: i18n.t('pryaniky.list.users.actions.invite'),
                    buttonProps: {
                      main: true,
                      onClick: this.multiInvite(selectedIds),
                    },
                  },
                ],
              },
            },
          }}
        />
        <ListLite className={'rounded-bottom'} isFinished={isFinished} isLoading={isLoading} loadMore={this.getData}>
          {data.map(el => (
            <Item
              lId={this.listId}
              iId={el}
              type={'common'}
              key={el}
              data_key={el}
            />
          ))}
        </ListLite>
      </React.Fragment>
    );
  };
}

export const List = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps(mapActionsToProps)
  // { updateContext }
)(ListGroupsTreeSelectorInvitePresenter);
