import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets'
import { guids } from "./index"

export const calendar = (): IWidget<IColumn[]> => {

  return generateWidget({
    type: widgets.types.layout,
    data: [
      generateColumn({
        items: [
          generateWidget({
            type: widgets.types.layout,
            data: [
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.calendar,
                  }),
                ],
                styles: {
                  width: '72%',
                },
              }),
              generateColumn({
                items: [
                  generateWidget({
                    type: widgets.types.filters + "/calendar",
                    relations: [],
                    data: [],
                  }),
                ],
                styles: {
                  width: '28%',
                },
              })
            ],
          }),
        ],
        styles: {
          width: '100%',
        },
      }),
    ],
  })
}