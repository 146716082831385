import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as widgetUtils from 'widgets/utils';
import { mapDispatchToProps, IDispatchProps } from "../../redux/connector";

import { mapStateToProps, IWikiTPStateProps, IWikiTPProps, IWikiTPState } from './WikiTP.index';
import './WikiTP.scss';
import { Page } from '../Page/Page';
import { v1 as uuid } from 'uuid';
// import { generateColumn, generateWidget } from 'i.widgets';
import { Button } from 'uielements/src/Button/Button';

const pageName = 'wikiTP'

export class WikiTPPresenter extends React.Component<IWikiTPProps, IWikiTPState> {
  // public utils: any = utils;
  public el: Element | Text | null;

  constructor(props: IWikiTPProps) {
    super(props);
    // this.functionBind = this.functionBind.bind(this);
    // this.functionBind();
    this.state = {};
  }

  public render() {
    // const customClassNames = '';
    // const { tag: TagName = 'div', children, className = '' } = this.props;
    const { children } = this.props;
    return (
        <React.Fragment>
          {/* <Button onClick={this.savePage} children={'save'} /> */}
          <Page page={{
            name: pageName
          }} />
          {children}
        </React.Fragment>
    )
  }
  
  public savePage = () => {
    widgetUtils.prepareWidgetToRequest(this.props.structures[pageName])
    utils.API.pages.set(this.props.structures[pageName])
  }

  // private t(request: string, upper?: boolean, dataInText?: string) {
  //   return i18n.t(request, upper, dataInText).toString();
  // }

  // private functionBind() {}

}


export const WikiTP = connect<IWikiTPStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps({})
  )(WikiTPPresenter)
//   (compose(
//   // withBemMod(cnWikiTP(), {})
// )(WikiTPPresenter))
