// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { capitalize, debounce } from 'utils/src/utils';
import { i18n, Translate } from 'localization';

import { cnPriorityNav, IPriorityNavProps, IPriorityNavState, IPriorityNavItem } from './PriorityNav.index';
import { Icon, SplitButton } from 'uielements/src'
import './PriorityNav.scss';
import { Button } from 'uielements/src';
import { Dropdown } from 'uielements/src';

export class PriorityNavPresenter extends React.PureComponent<IPriorityNavProps, IPriorityNavState> {
  public el: HTMLDivElement | null;
  private handleResize: () => void;

  constructor(props: IPriorityNavProps) {
    super(props);
    this.state = {
      maxCount: 0,
    };
    this.handleResize = debounce(this.resize, 300);
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize, false);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
  }

  public componentDidUpdate() {
    this.resize()
  }

  private calcMaxCount = (ref: HTMLDivElement | null) => {
    this.el = ref || this.el;
    let fullWidth = 0;
    let maxCount = 0;
    this.props.elements.forEach((el: any, i: number) => {
      fullWidth += 24 + el.displayName.length * 9;
      if ((this.el?.clientWidth || 0) > fullWidth) maxCount++
    })
    if (maxCount !== this.state.maxCount) this.setState({
      maxCount
    })
  }

  public render() {
    const { className, activeElemId } = this.props;
    if (this.props.elements.length === 0) return <div className={cnPriorityNav({}, [className])} />;
    const elements = this.props.elements.map((el: any, i: number) => {
      return {
        ...el,
        title: el.displayName,
        buttonProps: {
          active: el.idNumber === activeElemId,
          onClick: () => this.props.onChange && this.props.onChange(el),
          id: el.idNumber
        }
      }
    })
    return (
      <div className={cnPriorityNav({}, [className])}>
        <div ref={this.calcMaxCount} className={cnPriorityNav('Visible')}>
          {elements.slice(0, this.state.maxCount).map((el: any) => {
            return (
              <Button
                id={el.idNumber}
                type={el.type}
                href={el.type && el.to}
                key={el.idNumber}
                {...el.buttonProps}
                className={cnPriorityNav('Button', { active: activeElemId !== undefined && el.idNumber === activeElemId })}
                theme="unstyled"
                children={el.displayName || el.idNumber}
              />
            );
          })}
        </div>
        {elements.length > this.state.maxCount && (
          <SplitButton
            ButtonBoxProps={{ size: 'small' }}
            className={cnPriorityNav('Dropdown')}
            button={<span className={cnPriorityNav('ddText')}>
              {capitalize(i18n.t('more') + ' ')}
            </span>}
            options={elements.slice(this.state.maxCount)} />
        )}
      </div>
    );
  }

  private resize = () => {
    if (!this.el) return;
    this.calcMaxCount(this.el)
  };
}

export const PriorityNav = PriorityNavPresenter;
//   compose(
//     withBemMod(cnPriorityNav(), {})
//     )(
//       PriorityNavPresenter
//   )
// )
