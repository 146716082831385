import { connect } from 'react-redux'
import React from 'react'
import { mapDispatchToProps, IDispatchProps } from 'redux/connector'
import { Page } from 'blocks/Page/Page'
import { i18n, Translate } from 'localization';
import { setContext } from 'redux/actions/Widgets'
import { pageNames } from 'redux/pageNames'

interface IAchievementsPageProps extends IActionsDispatch, IDispatchProps { }
interface IActionsDispatch {
  setContext: any
}

export const AchievementsPagePresenter: React.FC<IAchievementsPageProps> = props => {

    return <Page
        className="Achievements"   
        page={{
            name: pageNames.achievements,
            title: i18n.t('achievements')
        }} />
}

export const AchievementsPage = connect(
  null,
  mapDispatchToProps({ setContext })
)(AchievementsPagePresenter)

