import { connect } from 'react-redux';
import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import { Avatar } from 'uielements/src/index';
// import * as utils from 'src/utils/utils';
import { i18n, Translate } from 'localization';
import { Bable } from '../Bable/Bable'
import { mapStateToProps, IChatListStateProps, cnChatList, IChatListProps, IChatListState, mapDispatchToProps, IDispatchProps } from './ChatList.index';
import './ChatList.scss';


export class ChatListPresenter extends React.Component<IChatListProps, IChatListState> {


  public componentDidMount() {
    this.props.mute();
  }

  public render() {
    const customClassNames = '';
    const { tag: TagName = 'ul', children, className = '', list, chatUrl, users } = this.props;
    return (
      <TagName className={cnChatList({}, [customClassNames, className])}>
        {list.map((item: any) => <li className={cnChatList('Item', [item.alert ? 'unread':'read'])} key={item.id}>
          <div className={cnChatList('Avatar')} ><Avatar name={item.fname} imgUrl={chatUrl + item.avatar} size={56} /></div>
          <div className={cnChatList('Type')} >
            {item.type === 'd'
              ? ((item.lastMessage && users[item.lastMessage.u._id]) ? users[item.lastMessage.u._id].status : 'offline')
              : item.type
            }
          </div>
          {/*<div>{(item.type === 'd') && (item.lastMessage && users[item.lastMessage.u._id]) ? users[item.lastMessage.u._id].status : 'offline'}</div>*/}
          {/*<div>{item.alert ? 'true': 'false'}</div>*/}
          <div className={cnChatList('Content')}>
            <div className={cnChatList('Title')}>{item.fname}</div>
            <div className={cnChatList('Message')}>{item.lastMessage && item.lastMessage.msg}</div>
          </div>
          <div className={cnChatList('Count')}>{item.unread !== 0 && <Bable>{item.unread}</Bable>}</div>
        </li>)}
        {children}
      </TagName>
    )
  }
}


export const ChatList = connect<IChatListStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(compose(
  // withBemMod(cnChatList(), {})
)(ChatListPresenter))