import React, { FC, memo } from 'react'
import {
    cnClassName,
    IMiniTimelineListProps
} from './MiniTimelineList.index'
import './MiniTimelineList.scss'
import { i18n, Translate } from 'localization';
import { MiniPlaceholder } from '../MiniPlaceholder/MiniPlaceholder';
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg'
import ListPlaceholder from 'uielements/src/ListPlaceholder'
import LoadButton from 'uielements/src/MaterialElements/ProgressButton'
import LinearProgress from '@material-ui/core/LinearProgress';

export const MiniTimelineList: FC<IMiniTimelineListProps> = memo(({
    className,
    children,
    isFinished,
    isLoading,
    isError,
    itemsCount,
    loadMore,
    loadNode
}) => {

    const errorNode = <ErrorMsg className={cnClassName('Error')}>
        <div className={cnClassName('ErrorMessage')}>{i18n.t('pryaniky.timeline.error.title')}</div>
        <LoadButton onClick={loadMore} isLoading={isLoading}>{i18n.t('pryaniky.timeline.error.reload.button')}</LoadButton>
    </ErrorMsg>

    return <ListPlaceholder
        className={cnClassName({}, [className])}
        isFinished={isFinished}
        isLoading={isLoading}
        isError={isError}
        itemsCount={itemsCount}

        loadNode={loadNode || <LinearProgress className={cnClassName('Progress')} />}
        // titleEmpty={i18n.t('pryaniky.timeline.notFound.title')}
        // textEmpty={i18n.t('pryaniky.timeline.notFound.text')}
        placeholderEmpty={<MiniPlaceholder>{i18n.t('pryaniky.timeline.notFound.text')}</MiniPlaceholder>}
        placeholderError={errorNode}

    // titleError={i18n.t('pryaniky.timeline.error.title')}
    // textError={<LoadButton onClick={loadMore} isLoading={isLoading}>{i18n.t('pryaniky.timeline.error.reload.button')}</LoadButton>}
    // textLoad={'load'}
    >
        {isError && errorNode}

        <div className={cnClassName('List')}>
            {children}
        </div>

    </ListPlaceholder>
})

