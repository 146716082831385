import React, { FC, useState, useRef } from 'react';
import { IDTInputProps } from './DateTimeInput.index'
import './DateTimeInput.css'
import { InputLayout } from '../InputLayout/InputLayout'
import { Saggester } from '../Saggester/Saggester'
import { Calendar } from '../Calendar/Calendar'
import { LineInput } from '../LineInput/LineInput'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src';
import { i18n, Translate } from 'localization';
import moment from 'moment';

registerLocale('ru', ru)
registerLocale('en', en)

export const DateTimeInput: FC<IDTInputProps> = ({
    showTimeInput,
    timeInputLabel = '',
    onChange,
    value,
    className = '', icon = () => null, action = () => null, minDate, invalid, required, errorMessage, ...props }) => {
    const [open, setOpen] = useState<boolean | undefined>(undefined)
    const [focused, setFocused] = useState(false);
    // const inputEl = useRef<HTMLInputElement>(null);
    // const defaultDate = new Date('0001-01-01T00:00:00.000Z')
    let date = new Date(value);
    const format = value ? moment(value || undefined).format(showTimeInput ? 'L HH:mm' : 'L') : undefined;
    // if (date === defaultDate ) date = new Date()

    // const yearStr = date.getUTCFullYear().toString()
    // const strWithZero = new Array(4 - yearStr.length).fill('0').join('')

    // const valueStr =
    //     strWithZero + yearStr
    //     + '-'
    //     + (date.getUTCMonth() < 9 ? `0${date.getUTCMonth() + 1}` : date.getUTCMonth() + 1)
    //     + '-'
    //     + (date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate())//2020-03-26

    // const onChangeValue = (e: any) => {
    //     let newValue = e.target.value;
    //     if (minDate) {
    //         newValue = minDate.getTime() > (new Date(newValue)).getTime() ? minDate.toISOString() : newValue;
    //     }
    //     onChange(newValue === '' ? value : (new Date(newValue)))
    // }


    return <div className={`DateInput ${className}`}>
        <InputLayout
            // action={action(!!open, () => setOpen(open ? true : undefined))}
            focus={focused}
            icon={icon(() => setOpen(open ? true : undefined))}
        // suggesterRender={
        //     (anchor) => open && <Saggester anchor={anchor} left={'24px'} width={'255px'} allowClickElement={inputEl} clickOutside={() => setOpen(false)} /*onSelect={onSelectValue}*/ className={'Select-Suggester'}>
        //         {(onClick: (item: any) => () => void) => <Calendar {...{ value, onChange, minDate }} />}
        //     </Saggester>}
        >
            <DatePicker
                className={'LineInput'}
                // open={open}
                locale={i18n.language}
                onFocus={() => { setFocused(true); /*setOpen(true)*/ }}
                onBlur={() => { setFocused(false); /*setOpen(undefined)*/ }}
                // onCalendarClose={() => { setFocused(false); setOpen(undefined) }}
                dateFormat={format}
                selected={date}
                minDate={minDate}
                timeInputLabel={timeInputLabel}
                showTimeInput={showTimeInput}
                onChange={onChange}
            />
            {errorMessage && invalid && <HelpTooltip className={'HelpTooltip'} help={errorMessage}><Icon icon="exclamation-circle" className={'ErrorIcon'} /></HelpTooltip>}
            {/* <LineInput
                ref={inputEl}
                onFocus={() => { setFocused(true); setOpen(true) }}
                onBlur={() => { setFocused(false) }}
                onChange={onChangeValue}
                type={'date'}
                value={valueStr}
                // value= {moment(new Date(value)).format().slice(0, 10)}//slice(0, 10)}
                {...props}
            /> */}
        </InputLayout>
    </div>
}